import { useSelect } from 'downshift'
import React from 'react'

import { ArrowDownIcon, ArrowUpIcon, Button, Container, ErrorLabel, HiddenLabel, ListContainer, ListItem } from './SelectInput.styles'

export const SelectInput = ({
  as,
  name,
  onChange,
  items,
  itemToString,
  errorMessage,
  placeholder,
  required,
  selected,
  style,
  value = '',
  ...props
}) => {
  const { isOpen, selectedItem, getToggleButtonProps, getLabelProps, getMenuProps, highlightedIndex, getItemProps } = useSelect({
    items,
    selectedItem: value,
    onSelectedItemChange: onChange,
  })
  return (
    <Container as={as} style={style}>
      <HiddenLabel {...getLabelProps()}>{placeholder}</HiddenLabel>
      <Button {...getToggleButtonProps()} {...props} $isRequired={required} $isFilled={value !== ''} $isOpen={isOpen} name={name}>
        {selectedItem ? itemToString(selectedItem) : placeholder}
        {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </Button>
      <ListContainer {...getMenuProps()} $isOpen={isOpen}>
        {isOpen &&
          items.map((item, index) => (
            <ListItem key={`item-${index}`} $highlighted={highlightedIndex === index} $selected={selected(item)} {...getItemProps({ item, index })}>
              {itemToString(item)}
            </ListItem>
          ))}
      </ListContainer>
      {errorMessage && <ErrorLabel>{errorMessage}</ErrorLabel>}
    </Container>
  )
}
