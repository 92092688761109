import React, { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDialogState } from 'reakit'

import { Button } from '../../../components/Button/Button'
import { BankedRewardCard } from '../../../components/RewardCard/BankedRewardCard'
import { RedeemableRewardCard } from '../../../components/RewardCard/RedeemableRewardCard'
import { Description } from '../../../components/Text/Text.styles'
import { PREFERENCES } from '../../../constants/preferences'
import { useText } from '../../../content'
import { useConfig } from '../../../contexts/appConfig'
import { useBasket } from '../../../hooks/useBasket'
import { useLoyalty } from '../../../hooks/useLoyalty'
import { useMessaging } from '../../../hooks/useMessaging'
import { RedeemableModal } from '../Rewards/RedeemableModal'
import { RedeemRewardModal } from '../Rewards/RedeemRewardModal'
import {
  CenterContainer,
  CenterPageContent,
  MeterBar,
  MeterCenter,
  MeterContainer,
  MeterContainerSession,
  MeterLegend,
  PointsText,
  RewardsCenterContainer,
  RewardsContainer,
  Section,
  SectionTitle,
  YourPointsLabel,
  YourPointsText,
} from './LpqRewards.styles'

const CIRCUMFERENCE = 2 * Math.PI * 45

export const LpqRewardsContent = () => {
  const history = useHistory()
  const { text } = useText()
  const { getAsset, getPreference } = useConfig()
  const { dispatchMessage } = useMessaging()

  const { basket } = useBasket()
  const { loading, rewards, balance, program, applyRewardLoading, removeRewardLoading, redeemReward } = useLoyalty()

  const brandName = useMemo(() => getPreference(PREFERENCES.GENERAL)?.brandName, [getPreference])
  const meterImage = getAsset('RewardsMeterImage')?.url
  const showMigrationInterstitial = getPreference('RewardsConfiguration')?.ShowMigrationInterstitial

  const barProgress = useMemo(() => {
    // points / pointsNeeded * 100
    const progress = Math.trunc(((balance?.unbankedPoints || 0) / (program?.pointsConversionThreshold || 200)) * 100)
    // limiting the progress so if some floating points error occurs, we still get a valid ui
    return Math.min(progress, 100) / 100
  }, [balance?.unbankedPoints, program?.pointsConversionThreshold])

  const pointToNextReward = useMemo(() => (program?.pointsConversionThreshold || 0) - (balance?.unbankedPoints || 0), [
    balance?.unbankedPoints,
    program?.pointsConversionThreshold,
  ])

  const appliedRewards = {}
  if (basket?.appliedRewards?.length > 0) {
    basket.appliedRewards.forEach(item => {
      appliedRewards[item?.externalReference] = true
    })
  }

  const [currentRedeemable, setCurrentRedeemable] = useState()
  const [currentReward, setCurrentReward] = useState()
  const [redeemLoading, setRedeemLoading] = useState(false)
  const redeemableDialogProps = useDialogState({ animated: true, visible: false })
  const redeemRewardDialogProps = useDialogState({ animated: true, visible: false })

  const handleRedeemClick = useCallback(
    reward => {
      if (reward?.redemption) {
        setCurrentRedeemable(reward.redemption)
        redeemableDialogProps.show()
      } else {
        setCurrentReward(reward)
        redeemRewardDialogProps.show()
      }
    },
    [setCurrentReward, redeemRewardDialogProps],
  )

  const handleRedeemConfirm = useCallback(() => {
    setRedeemLoading(true)
    redeemReward(currentReward.rewardId)
      .then(redeemable => {
        setCurrentRedeemable(redeemable)
        redeemableDialogProps.show()
      })
      .catch(error => dispatchMessage.error(error.message))
      .finally(() => {
        setRedeemLoading(false)
        setCurrentReward(null)
        redeemRewardDialogProps.hide()
      })
  }, [redeemReward, setCurrentRedeemable, redeemableDialogProps])

  const handleRedeemCancel = useCallback(() => {
    setCurrentReward(null)
    redeemRewardDialogProps.hide()
  }, [setCurrentReward, redeemRewardDialogProps])

  const handleRedeemableFinish = useCallback(() => {
    setCurrentRedeemable(null)
    redeemableDialogProps.hide()
  }, [redeemableDialogProps, setCurrentRedeemable])

  const handleMigrate = () => {
    history.push('/migration/verification')
  }

  if (loading) {
    return (
      <CenterPageContent>
        <div>Loading ...</div>
      </CenterPageContent>
    )
  }

  return (
    <>
      <MeterContainerSession>
        <CenterContainer>
          <MeterContainer backgroundImage={meterImage}>
            <MeterBar>
              <svg width="100%" height="100%" viewBox="0 0 100 100">
                <circle stroke="#E9E9E9" strokeWidth="5" fill="#ffffff" r="45" cx="50" cy="50" />
                {balance?.unbankedPoints > 0 && (
                  <g transform="rotate(-90)" transform-origin="center center">
                    <circle
                      stroke="#FDC400"
                      strokeWidth="5"
                      fill="none"
                      r="45"
                      cx="50"
                      cy="50"
                      strokeLinecap="round"
                      strokeDasharray={[CIRCUMFERENCE * barProgress, CIRCUMFERENCE]}
                    />
                  </g>
                )}
              </svg>
            </MeterBar>
            <MeterCenter>
              <PointsText>{balance?.unbankedPoints}</PointsText>
              <YourPointsText>Points</YourPointsText>
              <YourPointsLabel>out of {program?.pointsConversionThreshold}</YourPointsLabel>
            </MeterCenter>
          </MeterContainer>
        </CenterContainer>
      </MeterContainerSession>
      <MeterLegend>
        {text('Profile.Rewards.Points.ToNextReward', {
          amount: pointToNextReward,
          plural: pointToNextReward === 1 ? '' : 's',
        })}
      </MeterLegend>
      <RewardsContainer>
        <RewardsCenterContainer>
          {balance?.bankedCurrency > 0 && (
            <Section>
              <SectionTitle>{text('Profile.Rewards.BankedCurrency.Title')}</SectionTitle>
              <BankedRewardCard balance={balance} />
            </Section>
          )}
          {rewards.length > 0 && (
            <Section>
              <SectionTitle>{text('Profile.Rewards.Rewards.Title')}</SectionTitle>
              {rewards.map(reward => (
                <RedeemableRewardCard
                  key={String(reward.rewardId)}
                  loading={applyRewardLoading || removeRewardLoading}
                  onRedeem={handleRedeemClick}
                  reward={reward}
                />
              ))}
            </Section>
          )}
          {showMigrationInterstitial && (
            <>
              <Description style={{ display: 'flex', textAlign: 'center', margin: 10 }}>
                {text('Profile.Rewards.Migrate.Description', { brandName })}
              </Description>
              <Button variant="link" label="Migrate Rewards" onClick={handleMigrate} />
            </>
          )}
        </RewardsCenterContainer>
      </RewardsContainer>
      <RedeemRewardModal
        dialogProps={redeemRewardDialogProps}
        loading={redeemLoading}
        onCancel={handleRedeemCancel}
        onConfirm={handleRedeemConfirm}
        reward={currentReward}
      />
      <RedeemableModal
        dialogProps={redeemableDialogProps}
        onCancel={handleRedeemableFinish}
        onConfirm={handleRedeemableFinish}
        redeemable={currentRedeemable}
      />
    </>
  )
}
