import './SecureField.css'

import classnames from 'classnames'
import moment from 'moment'
import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react'

import { Button } from '../../../components/Button/Button'
import { SecureField } from './SecureField'
import { SecureFieldIcon } from './SecureFieldIcon'

const getUrl = production =>
  production
    ? 'https://pay.datatrans.com/upp/payment/js/secure-fields-1.0.0.min.js'
    : 'https://pay.sandbox.datatrans.com/upp/payment/js/secure-fields-1.0.0.js'

const initalInputStyle = 'font-size: 100%; border-radius: 0; -webkit-appearance: none; padding: 0'

const initialCssClass = {
  'secure-field': true,
  'secure-field__input ': true,
  'secure-field__base ': true,
  'secure-field__has-actions ': true,
  'secure-field__has-card-icon': true,
  'secure-field__has-error': false,
  'secure-field__is-recognized': false,
}

const SecureFields = ({ config, production, onCardConfirmed, buttonText, disabled }, secureFields) => {
  const [message, setMessage] = useState(null)
  const [cardIcon, setCardIcon] = useState('Empty')
  const [cvvIcon, setCvvIcon] = useState('Empty')
  const [cardContainerClassNames, setCardContainerClassNames] = useState(initialCssClass)
  const [cvvContainerClassNames, setCvvContainerClassNames] = useState(initialCssClass)
  const [expiration, setExpiration] = useState('')

  const expRef = useRef()
  const billingZipcodeRef = useRef()

  const bindSecureFieldsEvents = () => {
    secureFields.current.on('ready', () => {
      // Set styles manually as they're inside an iframe and out of the scope of the parent's stylesheets
      secureFields.current.setStyle('cardNumber', initalInputStyle)
      secureFields.current.setStyle('cvv', initalInputStyle)
      secureFields.current.focus('cardNumber')
    })

    // Set class names and icon when fields change
    secureFields.current.on('change', data => {
      const paymentMethod = data.fields.cardNumber.paymentMethod ? data.fields.cardNumber.paymentMethod : false

      setMessage(null)
      setCardContainerClassNames({
        ...cardContainerClassNames,
        'secure-field__is-recognized': !!paymentMethod,
        'secure-field__has-error': false,
      })

      setCvvContainerClassNames({
        ...cvvContainerClassNames,
        'secure-field__has-error': false,
      })

      setCardIcon(paymentMethod || 'Empty')
      setCvvIcon('Empty')
    })

    // Set error icon and class name on validate failure
    secureFields.current.on('validate', data => {
      if (!data.fields.cardNumber.valid) {
        setCardContainerClassNames({
          ...cardContainerClassNames,
          'secure-field__is-recognized': false,
          'secure-field__has-error': true,
        })

        setCardIcon('Error')
      }

      if (!data.fields.cvv.valid) {
        setCvvContainerClassNames({
          ...cvvContainerClassNames,
          'secure-field__has-error': true,
        })

        setCvvIcon('Error')
      }
    })

    // Show transaction ID on success or transaction error message
    secureFields.current.on('success', data => {
      let transactionMessage = null

      if (data.transactionId) {
        if (expRef.current?.value.trim().length === 0) {
          transactionMessage = 'Expiration date is Required'
        } else if (billingZipcodeRef.current.value.trim().length === 0) {
          transactionMessage = 'Billing zipcode is Required'
        } else if (billingZipcodeRef.current.value.length < 3 || billingZipcodeRef.current.value.length > 5) {
          transactionMessage = 'Billing zipcode is Invalid'
        } else if (expRef.current.value) {
          const [month, year] = expRef.current.value.split('/')

          // card expires in the next month
          // https://wallethub.com/answers/cc/does-a-credit-card-expire-at-the-beginning-or-end-of-the-month-2140663969/#:~:text=A%20credit%20card%20expires%20at,three%20years%20in%20the%20future
          const exp = moment(`${month}-01-${year}`, 'MM-DD-YY')
          const expNextMonth = exp.add(1, 'month')
          const today = moment()

          if (!expNextMonth.isSameOrAfter(today)) {
            transactionMessage = 'Card is Expired'
          }
        }
      } else if (data.error) {
        transactionMessage = data.error
      }

      if (transactionMessage) {
        const errorComponent = <pre className="form-error">{transactionMessage}</pre>
        setMessage(errorComponent)
      } else {
        if (onCardConfirmed) {
          onCardConfirmed({ ...data, expiration: expRef.current.value, billingZipcode: billingZipcodeRef.current.value })
        }
      }
    })
  }

  const initSecureFields = () => {
    secureFields.current = new window.SecureFields()
    secureFields.current.initTokenize(config.merchantID, config.fields, config.options)
    bindSecureFieldsEvents()
  }

  const onExpirationTextChanged = useCallback(event => {
    const value = event.target.value

    if (!/^((\d{0,2}\/?\d{0,2})|(\d+))$/g.test(value)) {
      return
    }

    setExpiration(prev => {
      if (value.length >= 4) {
        const rawValue = value.replace('/', '')
        return `${rawValue.slice(0, 2)}/${rawValue.slice(2, 4)}`
      }

      if (prev.length === 1 && value.length === 2) {
        return `${value}/`
      }

      return value
    })
  }, [])

  useEffect(() => {
    const scriptSource = getUrl(production)

    if (document.querySelector('script[src="' + scriptSource + '"]')) {
      initSecureFields()
    } else {
      const script = document.createElement('script')
      script.src = scriptSource

      script.addEventListener('load', () => {
        initSecureFields()
      })

      document.body.appendChild(script)
    }

    return () => {
      if (secureFields.current) {
        secureFields.current.destroy()
      }
    }
  }, [])

  return (
    <form>
      <div>
        {/* <!-- Card Number markup --> */}
        <SecureField
          fieldType="card"
          label="Card Number"
          customClass={classnames(cardContainerClassNames)}
          callback={() => secureFields.current?.focus('cardNumber')}
        >
          <SecureFieldIcon fieldType="card" iconType={cardIcon} />
        </SecureField>
      </div>

      <div style={{ display: 'flex', marginTop: '24px' }}>
        {/* <!-- CVV markup --> */}
        <div style={{ width: '100%' }}>
          <SecureField
            fieldType="cvv"
            label="CVV"
            customClass={classnames(cvvContainerClassNames)}
            callback={() => secureFields.current?.focus('cvv')}
          >
            <SecureFieldIcon fieldType="cvv" iconType={cvvIcon} />
          </SecureField>
        </div>

        {/* <!-- EXP markup --> */}
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '12px', width: '100%' }}>
          <label htmlFor="exp">
            Exp <span>(MM/YY)</span>
          </label>
          <input className="custom-input" value={expiration} ref={expRef} id="exp" name="exp" type="tel" onChange={onExpirationTextChanged} />
        </div>
      </div>

      <div style={{ flex: 1, marginTop: '24px' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {/* <!-- BillingZipCode markup --> */}
          <label htmlFor="billingZipcode">Billing Zip Code</label>
          <input className="custom-input" ref={billingZipcodeRef} id="billingZipcode" name="billingZipcode" type="tel" maxLength="7" />
        </div>
      </div>

      <div>{message}</div>
    </form>
  )
}

export default forwardRef(SecureFields)
