import React, { useMemo } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'

import { useText } from '../../../content'
import { Button } from '../../Button/Button'
import { Header, Text } from './RealtimeTrackingOrderConfirmation.styles'

export const RealtimeTrackingOrderConfirmation = ({ order }) => {
  const { text } = useText()
  const history = useHistory()

  const shouldDisplayTrackingOption = useMemo(() => ['delivery', 'dispatch'].includes(order.deliverymode || order.deliveryMode), [order])

  return (
    <>
      <Header>{text('OrderConfirmation.Header')}</Header>
      <Text>{text('OrderConfirmation.Line1', { orderId: order?.oloid || order?.oloId })}</Text>
      <Text>{text('OrderConfirmation.Line2')}</Text>

      {shouldDisplayTrackingOption && <TrackButton label="Track Order" onClick={() => history.push(`tracking/${order.id}`)} />}
    </>
  )
}

const TrackButton = styled(Button).attrs(() => ({ variant: 'secondary' }))`
  padding: 10px 16px;
  width: auto;
`
