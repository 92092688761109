import React, { useMemo } from 'react'
import { useTheme } from 'styled-components'

import { HorizontalBrowseMenu } from './HorizontalBrowseMenu/HorizontalBrowseMenu'
import { VerticalBrowseMenu } from './VerticalBrowseMenu/VerticalBrowseMenu'

const componentsFactory = {
  lpq: VerticalBrowseMenu,
}

export const MenuPage = props => {
  const theme = useTheme()
  const Component = useMemo(() => componentsFactory[theme.brand] || HorizontalBrowseMenu, [theme.brand])
  return <Component {...props} />
}
