import React from 'react'

import { ButtonContainer, ButtonContainerWithContent, ButtonContent, ChevronIcon } from './InfoButton.styles'

export const InfoButton = ({ isRequired, label, content, onClick, actionComponent, ...rest }) => {
  if (content) {
    return (
      <ButtonContainerWithContent onClick={onClick} {...rest}>
        <label htmlFor={label}>{label}</label>
        <ButtonContent>{content}</ButtonContent>
        {actionComponent}
      </ButtonContainerWithContent>
    )
  } else {
    return (
      <ButtonContainer isRequired={isRequired && !content} onClick={onClick} {...rest}>
        <label htmlFor={label}>{label}</label>
        <ChevronIcon />
      </ButtonContainer>
    )
  }
}
