import { IoIosHeart } from 'react-icons/io'
import { Button as RButton } from 'reakit'
import styled, { css } from 'styled-components'

export const actionContainer = css`
  flex: 1;
  display: flex;
  height: 44px;
  flex-direction: row;
  border: 1px solid #e9e9e9;
  border-top-width: 0px;

  align-items: center;
  justify-content: center;
  background-color: transparent;

  transition: 300ms all;
`

export const CardContainer = styled.div`
  display: flex;
  padding: 16px;
  border: 1px solid #e9e9e9;
`

export const ActionText = styled.span`
  font-family: ${({ theme }) => theme.font.styles.regular};
  font-size: 14px;
`

export const ActionContainer = styled(RButton)`
  ${actionContainer}
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.colors.primary.toString()};
    border-color: ${({ theme }) => theme.colors.primary.toString()};

    svg {
      fill: white;
    }
  }
`

export const RawActionContainer = styled.div`
  ${actionContainer}
`

export const ItemImage = styled.div`
  background-color: #c4c4c4;
  border-radius: 100px;

  background: ${({ source: { uri } }) => `url(${uri})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

export const Content = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row;
`

export const DescriptionText = styled.span`
  ${({ theme }) => theme.font.styles.body}
  font-size: 14px;
`

export const ItemTitle = styled.span`
  ${({ theme }) => theme.font.styles.h3}
`

export const FavoriteIcon = styled(IoIosHeart).attrs(({ theme }) => ({ color: theme.colors.primary.toString(), size: 14 }))``
