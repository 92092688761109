import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useDialogState } from 'reakit'
import { useTheme } from 'styled-components'

import { HANDOFF_MODES } from '../../constants/handoffModes'
import { useText } from '../../content'
import { useConfig } from '../../contexts/appConfig'
import { useSession } from '../../contexts/session'
import { formatCurrency } from '../../data/utils'
import { useBasket } from '../../hooks/useBasket'
import { useMenuItemNavigation } from '../../hooks/useMenuItemNavigation'
import useUpsellProducts from '../../hooks/useUpsellProducts'
import firebase from '../../services/firebase'
import { Button, CloseButton } from '../Button/Button'
import { DoubleCTA } from '../DoubleCTA/DoubleCTA'
import { ModalWithBackdrop } from '../ModalWithBackdrop/ModalWithBackdrop'
import {
  Body,
  CloseButtonContainer,
  Content,
  CostItem,
  CostsLabel,
  CostsLabelSubtotal,
  CostsNumber,
  CostsNumberSubtotal,
  EmptyStateButtonContainer,
  EmptyStateContainer,
  EmptyStateContent,
  EmptyStateSubTitle,
  EmptyStateTitle,
  FixedBottomActions,
  Header,
  Heading,
  Page,
  SelectedItemsLabel,
  SummaryContainer,
  UpsellCarouselContainer,
  UpsellSection,
  UpsellTitle,
} from './Basket.styles'
import { BasketCard } from './Card/Card'
import { TotalMinimumWarningModal } from './TotalMinimumWarningModal/TotalMinimumWarningModal'
import UpsellProductCard from './UpsellProductCard/UpsellProductCard'

export const BasketPage = ({ onClose }) => {
  const {
    state: { restaurant, handoffMode },
  } = useSession()
  const { basket, products, totalProductQuantity } = useBasket()
  const { resolveNavigationPayload } = useConfig()
  const { goToItem } = useMenuItemNavigation()
  const theme = useTheme()
  const { text } = useText()

  const [upsellProducts, loadingUpsellItems] = useUpsellProducts()
  const history = useHistory()
  const minimumWarningDialogProps = useDialogState({ animated: true })

  const handleNavigateToStartOrder = () => {
    const screen = resolveNavigationPayload('StartOrder')
    history.push(screen)
    onClose()
  }

  const handleNavigateToCheckout = async () => {
    firebase.analytics().logEvent('Check_Out_Selected', {
      numberofDishes: totalProductQuantity,
      amount: basket.total,
    })

    if (
      (handoffMode === HANDOFF_MODES.PICKUP && basket?.total < restaurant?.minimumPickupOrder) ||
      (handoffMode === HANDOFF_MODES.DELIVERY && basket?.total < restaurant?.minimumDeliveryOrder)
    ) {
      // show warning for minimum order total
      minimumWarningDialogProps.show()
    } else {
      firebase.analytics().logEvent('Checkout_Started')
      history.push('/checkout')
      window.scrollTo({ top: 0 })
      onClose()
    }
  }

  const handleAddMore = () => {
    const screen = resolveNavigationPayload('StartOrder')
    history.push(screen)
    onClose()
  }

  const handleAddUpsellProduct = goToItem

  const actionButtons = [
    {
      label: 'ADD MORE',
      onClick: handleAddMore,
      variant: 'secondary',
    },
  ]

  if (totalProductQuantity > 0) {
    actionButtons.push({
      label: 'CHECK OUT',
      onClick: handleNavigateToCheckout,
      variant: 'primary',
      icon: theme?.icons?.checkoutIcon,
    })
  }

  if (!basket || !restaurant) {
    return (
      <EmptyStateContainer>
        <Header>
          <CloseButtonContainer>
            <CloseButton variant={theme.basket.backButtonVariant} onClick={onClose} />
          </CloseButtonContainer>
          <Heading>My Bag</Heading>
        </Header>

        <EmptyStateContent>
          <EmptyStateTitle>Your bag is empty</EmptyStateTitle>
          <EmptyStateSubTitle>Let’s change that.</EmptyStateSubTitle>

          <EmptyStateButtonContainer>
            <Button label="Start Order" variant="primary" onClick={handleNavigateToStartOrder} />
          </EmptyStateButtonContainer>
        </EmptyStateContent>
      </EmptyStateContainer>
    )
  }

  return (
    <Page>
      <Header>
        <CloseButtonContainer>
          <CloseButton variant={theme.basket.backButtonVariant} onClick={onClose} />
        </CloseButtonContainer>
        <Heading>My Bag</Heading>
      </Header>

      <Content>
        <SelectedItemsLabel>Selected Items ({totalProductQuantity || 0})</SelectedItemsLabel>

        <Body>
          {products?.map(p => (
            <BasketCard key={p.id} basketProduct={p} />
          ))}
        </Body>

        <SummaryContainer>
          <CostItem>
            <CostsLabelSubtotal>Subtotal + Tax</CostsLabelSubtotal>
            <CostsNumberSubtotal>
              {formatCurrency(basket?.subtotal)} + {formatCurrency(basket?.salesTax)}
            </CostsNumberSubtotal>
          </CostItem>
          <CostItem>
            <CostsLabelSubtotal>Delivery Fee</CostsLabelSubtotal>
            <CostsNumberSubtotal>{formatCurrency(basket.customerHandoffCharge)}</CostsNumberSubtotal>
          </CostItem>
          {basket.fees.map(fee => {
            return (
              <CostItem key={fee.description}>
                <CostsLabelSubtotal>{fee.description}</CostsLabelSubtotal>
                <CostsNumberSubtotal>{formatCurrency(fee.amount)}</CostsNumberSubtotal>
              </CostItem>
            )
          })}
          <CostItem>
            <CostsLabel>Total</CostsLabel>
            <CostsNumber>{formatCurrency(basket?.total)}</CostsNumber>
          </CostItem>
        </SummaryContainer>

        {!loadingUpsellItems && upsellProducts?.length > 0 && (
          <UpsellSection>
            <UpsellCarouselContainer>
              <UpsellTitle>{text('Basket.Upsell.Headline')}</UpsellTitle>
              {upsellProducts.map(menuItem => (
                <UpsellProductCard key={menuItem.id} menuItem={menuItem} onClick={() => handleAddUpsellProduct(menuItem)} />
              ))}
            </UpsellCarouselContainer>
          </UpsellSection>
        )}
      </Content>
      <ModalWithBackdrop dialogProps={minimumWarningDialogProps} ariaLabel="Total minimum warning">
        <TotalMinimumWarningModal onClose={minimumWarningDialogProps.hide} />
      </ModalWithBackdrop>

      <FixedBottomActions>
        <DoubleCTA buttons={actionButtons} />
      </FixedBottomActions>
    </Page>
  )
}
