import React, { useCallback, useMemo } from 'react'
import { Redirect, useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { useTheme } from 'styled-components'

import { useFeatureFlags } from '../../hooks/useFeatureFlags'
import { DefaultOrderTracking } from './DefaultOrderTracking/DefaultOrderTracking'
import { LPQOrderTrackingPage } from './LPQOrderTracking/LPQOrderTracking'

const componentFactory = {
  lpq: LPQOrderTrackingPage,
}

export const OrderTrackingPage = () => {
  const { RealtimeTracking } = useFeatureFlags()
  const { orderId } = useParams()
  const theme = useTheme()
  const history = useHistory()
  const onClose = useCallback(() => history.goBack(), [history])
  const Component = useMemo(() => componentFactory[theme?.brand] || DefaultOrderTracking, [theme?.brand])
  return RealtimeTracking ? <Component orderId={orderId} onClose={onClose} /> : <Redirect to="/" />
}
