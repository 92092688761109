import { Box } from 'reakit/Box'
import { Button } from 'reakit/Button'
import styled, { css } from 'styled-components'

import { isDesktop, isLargeDesktop, isMobile, isTablet } from '../../../../../components/responsive/Responsive'

export const Container = styled(Box)`
  ${props => props.theme.menuCategoryBar}
  align-items: center;
  display: flex;
  justify-content: center;
  ${isLargeDesktop`
    flex-direction: row;
    flex-wrap: wrap;
    padding: 16px 48px 8px;
  `}
  ${isDesktop`
    flex-direction: row;
    flex-wrap: wrap;
    padding: 24px 48px 12px;
  `}
  ${isTablet`
    flex-direction: row;
    flex-wrap: wrap;
    padding: 16px 48px 8px;
  `}
  ${isMobile`
    flex-direction: column;
  `}
`

export const CategoryButton = styled(Button)`
  ${props => props.theme.font.styles.cta};
  ${props => props.theme.menuCategoryBarButton}

  cursor: pointer;
  text-align: center;
  transition: all 0.3s;

  ${isLargeDesktop`
    font-size: ${props => props.theme.font.sizes.md};
    line-height: ${props => props.theme.font.sizes.xl};
    margin: 0 45px 8px;
  `}
  ${isDesktop`
    font-size: ${props => props.theme.font.sizes.md};
    line-height: ${props => props.theme.font.sizes.xl};
    margin: 0 26px 12px;
  `}
  ${isTablet`
    font-size: ${props => props.theme.font.sizes.xs};
    line-height: ${props => props.theme.font.sizes.sm};
    margin: 0 18px 8px;
  `}
  ${isMobile`
    font-size: ${props => props.theme.font.sizes.xs};
    line-height: ${props => props.theme.font.sizes.sm};
    margin: 0 8px 8px;
  `}

  :first-of-type {
    margin-left: 0;
  }
  :last-of-type {
    margin-right: 0;
  }

  ${props =>
    props.$isSelected &&
    css`
      ${props => props.theme.menuCategoryBarButtonSelected};
    `}
`

export const CategoryNavButton = styled(Button)`
  ${props => props.theme.font.styles.cta};
  ${props => props.theme.menuCategoryBarButton}

  cursor: pointer;
  text-align: center;
  transition: all 0.3s;
  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: ${props => props.theme.font.sizes.xs};
  line-height: ${props => props.theme.font.sizes.sm};
  margin: 8px;
`
