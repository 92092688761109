import { FaExclamationCircle } from 'react-icons/fa'
import { Box } from 'reakit/Box'
import { Button } from 'reakit/Button'
import styled, { css } from 'styled-components'

import { CloseButton } from '../../components/Button/Button'
import { isMobile } from '../../components/responsive/Responsive'
import { H2 } from '../../components/Text/Text.styles'

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
`

/**
 * Structural components
 **/

export const Header = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 52px;
`

export const Body = styled(Box)`
  background: ${props => props.theme.colors.brand.white};
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;

  /* Desktop, Large Desktop and Tablet */
  padding: 32px;
  ${isMobile`
    padding: 24px 16px;
  `}

  > * {
    margin-bottom: 12px;
    ${isMobile`
      margin-bottom: 9px;
    `}
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const Footer = styled(Box)`
  text-align: center;

  box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.1);
  z-index: 1;

  /* Desktop, Large Desktop and Tablet */
  padding: 20px 32px 32px 32px;
  ${isMobile`
    padding: 16px;
    padding-bottom: 12px;
  `}

  ${props => props.theme.menuItems.simpleBuilder?.footerContainerStyle}
`

/** Element components */

export const Title = styled.h1`
  ${({ theme }) => theme.font.styles.h1}
`

export const LoadingView = styled(Box)`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`

export const ImageContainer = styled(Box)`
  position: relative;
  background: ${props => `url(${props.src}) no-repeat center center`};
  background-size: cover;
  min-height: 270px;
  width: 100%;
`

export const TotalText = styled.span`
  ${props => props.theme.menuItems.totalTextStyle};
  color: ${props => props.theme.colors.black.hex()};
  display: block;
  /* margin-bottom: 20px; */
`

export const DietaryInfoContainer = styled(Box)`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 6px;
  margin-bottom: 20px;
`

/** Item components */

export const Item = styled(Box)`
  ${props => props.theme.menuItems.simpleBuilder.infoContainerStyle};
`

export const ItemName = styled(H2)``

export const ItemMeta = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > *:last-child {
    margin-left: 4px;
  }
`

export const ItemPrice = styled.span`
  ${props => props.theme.menuItems.totalMetaStyle};
  letter-spacing: 0.06em;
`

/** Modifiers elements */

export const ComboContainer = styled(Box)`
  display: flex;
  flex-direction: row;
`

export const ComboButton = styled(Button)`
  ${({ theme }) => theme.font.styles.inputDefault};
  background: ${props => props.theme.colors.brand.white};

  border-radius: 3px;
  width: 100%;

  /* button overrides */
  cursor: pointer;
  border: none;

  /* Desktop, Large Desktop and Tablet */
  margin-right: 9px;
  ${isMobile`
    margin-right: 18px;
  `}
  :last-of-type {
    margin-right: 0;
  }

  /* Desktop, Large Desktop and Tablet */
  padding: 28px 0;
  ${isMobile`
    padding: 24px 0;
  `}

  &:hover {
    border: ${props => props.theme.toggles.styles.active.border};
  }

  ${props =>
    props.$isActive
      ? css`
          border: ${props.theme.toggles.active.border};
        `
      : css`
          border: ${props.theme.toggles.inactive.border};
        `}
`

export const SelectorContainer = styled(Box)`
  display: flex;
  flex-direction: column;

  /* Desktop, Large Desktop and Tablet */
  padding: 12px 0;
  ${isMobile`
    padding: 13.5px 0;
  `}

  > * {
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  ${({ theme }) => theme.menuItems.controlContainerStyle}
`

/** Icons */

export const WarningIcon = styled(FaExclamationCircle).attrs(props => ({
  color: props.theme.colors.brand.black,
  size: 12,
}))`
  margin: 0 8px;
  :first-of-type {
    margin-left: 0;
  }
`

export const SpotlightIcon = styled.img.attrs(({ theme }) => ({
  src: theme.menuItems.simpleBuilder?.spotlightIcon,
}))`
  height: 48px;
  width: 48px;

  object-fit: contain;
`

/** Spotlight **/

export const SpotlightButton = styled.button`
  position: absolute;
  right: 16px;
  bottom: -24px;

  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  z-index: 999;
`

export const SpotlightContainer = styled.div`
  background: ${({ theme }) => `url(${theme.menuItems.simpleBuilder?.spotlightBg}) no-repeat center center`};
  background-size: cover;

  padding: 32px;
  width: 400px;

  ${isMobile`
    width: 100%;
  `}
`

export const SpotlightCloseButton = styled(CloseButton)``

export const SpotlightMeta = styled.div``

export const SpotlightTitle = styled.p`
  ${props => props.theme.font.styles.label};
  color: white;
  margin-top: 32px;
`

export const SpotlightHeading = styled.p`
  color: white;
  font-family: ${({ theme }) => theme.font.styles.spotlightHeading};
  font-size: 32px;
  font-weight: 800;
  margin-top: 8px;
`

export const SpotlightDescription = styled.p`
  ${props => props.theme.font.styles.label};
  color: white;
  font-size: 18px;
  font-weight: 400;
  margin-top: 8px;
  text-transform: none;
`
