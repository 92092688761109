import React, { useState } from 'react'
import { FaChevronRight } from 'react-icons/fa'

import { useSession } from '../../../../../contexts/session'
import { CategoryMenuListButton, CategoryMenuListContainer, CategoryMenuListSpacer } from './CategoryMenuList.styles'

export const CategoryMenuList = ({ selectedCategoryId, categories, isVisible, onClick, isMobile, headerHeight }) => {
  const [containerRef, setContainerRef] = useState()
  const { state } = useSession()

  if (!isVisible || isMobile) return null

  return (
    <>
      <CategoryMenuListContainer as="div" ref={setContainerRef} $isAuthenticated={!!state?.user} $headerHeight={headerHeight}>
        {categories?.map(category => (
          <CategoryMenuListButton as="div" key={category.key} onClick={() => onClick(category)} $isSelected={selectedCategoryId === category.id}>
            {category.displayName}
            <FaChevronRight size={12} style={{ marginLeft: '12px' }} />
          </CategoryMenuListButton>
        ))}
      </CategoryMenuListContainer>
      <CategoryMenuListSpacer $isAuthenticated={!!state?.user} style={{ minWidth: containerRef?.clientWidth }} />
    </>
  )
}
