import { InfoBox } from '@react-google-maps/api'
import React, { memo } from 'react'

import { InfoBoxContainer } from './MapMarkerTextLabel.styles'

export const MapMarkerTextLabel = memo(({ position, textLabel }) => (
  <InfoBox position={position} options={{ closeBoxURL: '' }}>
    <InfoBoxContainer>
      <span>{textLabel}</span>
    </InfoBoxContainer>
  </InfoBox>
))
