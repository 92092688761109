import { useMutation } from '@apollo/client'
import parsePhoneNumber from 'libphonenumber-js'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { useHistory, useParams } from 'react-router-dom'

import { Button } from '../../components/Button/Button'
import { Page } from '../../components/Page/Page'
import { DateInput } from '../../components/TextInput/DateInput'
import { TextInput } from '../../components/TextInput/TextInput'
import { useText } from '../../content'
import { useSession } from '../../contexts/session'
import { REGISTER } from '../../data/loyalty/queries'
import { useMessaging } from '../../hooks/useMessaging'
import { useQuery } from '../../hooks/useQuery'
import firebase from '../../services/firebase'
import { Container, Form, HeaderText, Link, Paragraph } from './Auth.styles'
import { BackgroundImageSection } from './components/BackgroundImageSection'
import { ToggleButton } from './components/ToggleButton'

const UPDATE_INTERVAL_MS = 5 * 60 * 1000

const DEFAULT_COUNTRY_CODE = 'US'

export const RegisterPage = () => {
  const history = useHistory()
  const { isMigrating } = useParams()
  const queryParams = useQuery()
  const { text } = useText()
  const { dispatch } = useSession()
  const { dispatchMessage } = useMessaging()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [referralCode, setReferralCode] = useState(queryParams.get('referralCode') || '')
  const [birthday, setBirthday] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [receiveMarketing, setReceiveMarketing] = useState(true)
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [errors, setErrors] = useState({})
  const [minimumBirthday, setMinimumBirthday] = useState(moment().endOf('day'))

  useEffect(() => {
    const interval = setInterval(() => setMinimumBirthday(moment().endOf('day')), UPDATE_INTERVAL_MS)
    return () => clearInterval(interval)
  }, [])

  const [registrationMutation, { loading }] = useMutation(REGISTER, {
    onError: useCallback(err => dispatchMessage.error(err.message || text('Register.Errors.Default'), err), [text, dispatchMessage]),
  })

  const handleSubmit = async event => {
    event.preventDefault()
    firebase.analytics().logEvent('Create_Account_Selected')

    const phoneNumber = parsePhoneNumber(phone, DEFAULT_COUNTRY_CODE)
    const e = {}
    const guest = {
      firstName,
      lastName,
      email,
      phone: phoneNumber?.number,
      birthday,
      password,
      passwordConfirmation,
      acceptTerms,
      inviteCode: referralCode,
      receiveMarketingEmails: receiveMarketing,
    }

    const momentBirthday = moment(guest.birthday).startOf('day')

    if (!guest.firstName) {
      Object.assign(e, { firstName: 'First name is required' })
    }
    if (!guest.lastName) {
      Object.assign(e, { lastName: 'Last name is required' })
    }
    if (!guest.email) {
      Object.assign(e, { email: 'Email is required' })
    }
    if (!phone) {
      Object.assign(e, { phone: 'Phone number is required' })
    } else if (!phoneNumber?.isValid()) {
      Object.assign(e, { phone: 'Phone number is invalid' })
    }

    if (guest.birthday.length > 0 && (!momentBirthday.isValid() || momentBirthday.isAfter(minimumBirthday))) {
      Object.assign(e, { birthday: 'Birthday is invalid' })
    }
    if (!guest.password) {
      Object.assign(e, { password: 'Password is required' })
    }
    if (guest.password?.length < 8) {
      Object.assign(e, { password: 'Your password must be at least 8 characters' })
    }
    if (!guest.passwordConfirmation) {
      Object.assign(e, { passwordConfirmation: 'You must confirm your password' })
    }
    if (guest.password !== guest.passwordConfirmation) {
      Object.assign(e, { passwordConfirmation: "Password confirmation doesn't match your password" })
    }
    if (!guest.acceptTerms) {
      Object.assign(e, { acceptTerms: 'You must agree to join' })
    }
    setErrors(e)

    if (Object.keys(e).length === 0) {
      const response = await registrationMutation({ variables: { guest } })
      if (response?.data?.signUpGuest) {
        const { birthday, gender, zipCode } = response?.data?.signUpGuest

        const momentToday = moment()
        const momentBirthday = moment(birthday, 'YYYY-MM-DD')
        const diff = momentToday.diff(momentBirthday)
        const age = moment.duration(diff).years()
        firebase.analytics().logEvent('Create_Account_Successful', {
          age,
          gender,
          signupDate: moment().format('YYYY-MM-DD'),
          zipCode,
        })

        dispatch({ type: 'SET_USER', payload: response?.data?.signUpGuest })
        if (isMigrating) {
          history.push('/migration/verification')
        } else {
          history.push('/account/created')
        }
      }
    }
  }

  const headerText = isMigrating ? text('Register.Migrate.Heading') : text('Register.Heading')

  return (
    <Page isScrollable>
      <BackgroundImageSection tabIndex="0" aria-label={headerText}>
        <Container>
          <HeaderText style={{ textAlign: 'left' }}>{headerText}</HeaderText>
          <Form noValidate onSubmit={handleSubmit}>
            {isMigrating && <Paragraph style={{ marginBottom: 20 }}>{text('Register.Migrate.Message')}</Paragraph>}
            <TextInput
              name="firstName"
              required
              placeholder="First Name"
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
              errorMessage={errors.firstName}
            />
            <TextInput
              name="lastName"
              required
              placeholder="Last Name"
              value={lastName}
              onChange={e => setLastName(e.target.value)}
              errorMessage={errors.lastName}
            />
            <TextInput name="email" required placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} errorMessage={errors.email} />
            <TextInput
              name="phone"
              required
              placeholder="Phone Number"
              type="tel"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              value={phone}
              onChange={e => setPhone(e.target.value)}
              errorMessage={errors.phone}
            />
            <TextInput name="referralCode" placeholder="Invite Code" value={referralCode} onChange={e => setReferralCode(e.target.value)} />
            <DateInput
              name="birthday"
              placeholder="Birthday"
              pattern="\d{1,2}/\d{1,2}/\d{4}"
              mask="mm/dd/yyyy"
              value={birthday}
              onChange={e => setBirthday(e.target.value)}
              errorMessage={errors.birthday}
              max={minimumBirthday.format('YYYY-MM-DD')}
            />
            <TextInput
              name="password"
              required
              placeholder="Password"
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              errorMessage={errors.password}
            />
            <TextInput
              name="passwordConfirmation"
              required
              placeholder="Confirm Password"
              type="password"
              value={passwordConfirmation}
              onChange={e => setPasswordConfirmation(e.target.value)}
              errorMessage={errors.passwordConfirmation}
            />
            <ToggleButton
              name="receiveMarketing"
              label={<ReactMarkdown source={text('Register.MarketingOptIn', { brand: text('Brand.Name') })} />}
              checked={receiveMarketing}
              onClick={() => setReceiveMarketing(prev => !prev)}
            />
            <ToggleButton
              name="acceptTerms"
              label={<ReactMarkdown source={text('Register.AcceptTerms', { brand: text('Brand.Name') })} />}
              checked={acceptTerms}
              onClick={() => setAcceptTerms(prev => !prev)}
            />
            <Button variant="primary" label="Create Account" disabled={!acceptTerms} type="submit" loading={loading} />
          </Form>
        </Container>
      </BackgroundImageSection>
    </Page>
  )
}
