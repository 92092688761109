import { useCallback, useMemo } from 'react'

import { PREFERENCES } from '../constants/preferences'
import { useConfig } from '../contexts/appConfig'
import defaultStrings from './default.json'

const interpolate = (string, params = {}) => {
  const names = Object.keys(params)
  const vals = Object.values(params)
  // Disable new function warning since the eval that takes place here should be safe
  // eslint-disable-next-line no-new-func
  return new Function(...names, `return \`${string}\`;`)(...vals)
}

export const useText = () => {
  const config = useConfig()
  const textContent = useMemo(() => config?.getPreference(PREFERENCES.TEXT_CONTENT), [config])

  // if (!textContent) {
  //   throw new Error('No Text Content present.')
  // }

  const strings = useMemo(() => Object.assign({}, defaultStrings, textContent), [textContent])

  const text = useCallback(
    (key, parameters) => {
      const template = strings[key]
      const result = interpolate(template, parameters)
      return result
    },
    [strings],
  )

  return { text }
}
