import React, { useMemo } from 'react'
import { useTheme } from 'styled-components'

import { CCSFCheckout } from './Checkout/CCSFCheckout/CCSFCheckout'
import { DefaultCheckout } from './Checkout/DefaultCheckout/DefaultCheckout'

const componentsFactory = {
  fgc: CCSFCheckout,
  lpq: CCSFCheckout,
  meltshop: CCSFCheckout,
  littlebeet: CCSFCheckout,
}

export const Checkout = props => {
  const theme = useTheme()
  const Component = useMemo(() => componentsFactory[theme.brand] || DefaultCheckout, [theme.brand])
  return <Component {...props} />
}
