import moment from 'moment'
import React, { createContext, useContext, useEffect, useReducer } from 'react'

import { HANDOFF_MODES } from '../constants/handoffModes'
import { useLocalStorage } from '../hooks/useLocalStorage'

export const SessionContext = createContext()

const initialState = {
  basket: null,
  selectedBrand: null,
  handoffMode: HANDOFF_MODES.PICKUP,
  restaurant: null,
  menuFilters: [],
  activeAddress: null,
  pickupTime: null,
  activeModifiers: [],
  user: null,
  savedPaymentMethods: [],
  activePaymentMethod: null,
  contactInfo: null,
  tipTier: null,
  activeOrder: { order: null, dateCreated: null, leadTimeEstimateMinutes: null },
  billingAccounts: [],
  selectedMenuCategory: null,
  datePickerSelection: null,
  menuItem: null,
  useUtensils: null,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_USER':
      return { ...state, user: action.payload }

    case 'SIGN_OUT':
      return { ...state, user: null, savedPaymentMethods: [], activePaymentMethod: null, billingAccounts: [], activeOrder: null }

    case 'SET_PICKUP_TIME':
      return { ...state, pickupTime: action.payload }

    case 'SET_RESTAURANT':
      return { ...state, restaurant: action.payload }

    case 'SET_HANDOFF_MODE':
      return { ...state, handoffMode: action.payload }

    case 'SET_MENU_FILTERS':
      return { ...state, menuFilters: action.payload }

    case 'SET_SELECTED_MENU_CATEGORY':
      return { ...state, selectedMenuCategory: action.payload }

    case 'SET_BASKET':
      return { ...state, basket: action.payload }

    case 'SET_ACTIVE_ORDER':
      return { ...state, activeOrder: action.payload, basket: null, savedPaymentMethods: [], activePaymentMethod: null, pickupTime: null }

    case 'CLEAR_ACTIVE_ORDER':
      return { ...state, activeOrder: { order: null, dateCreated: null } }

    case 'SET_TIP_TIER':
      return { ...state, tipTier: action.payload }

    case 'SET_ACTIVE_ADDRESS':
      return { ...state, activeAddress: action.payload }

    case 'SET_ACTIVE_MODIFIERS':
      return { ...state, activeModifiers: action.payload }

    case 'SET_SELECTED_BRAND':
      return { ...state, selectedBrand: action.payload }

    case 'ADD_NEW_PAYMENT_METHOD':
      // eslint-disable-next-line no-case-declarations
      const savedPaymentMethods = state.savedPaymentMethods || []
      return { ...state, savedPaymentMethods: [action.payload, ...savedPaymentMethods] }

    case 'SET_ACTIVE_PAYMENT_METHOD':
      return { ...state, activePaymentMethod: action.payload }

    case 'SET_SAVED_PAYMENT_METHODS':
      return { ...state, savedPaymentMethods: action.payload || [] }

    case 'SET_CONTACT_INFO':
      return { ...state, contactInfo: action.payload }

    case 'SET_DATE_PICKER_SELECTION':
      return { ...state, datePickerSelection: action.payload }

    case 'SET_MENU_ITEM':
      return { ...state, menuItem: action.payload }

    case 'SET_USE_UTENSILS':
      return { ...state, useUtensils: action.payload }

    case 'REHYDRATE_WITH_INITIAL':
      console.log('Resetting state to initial')
      return { ...initialState }

    case 'RESET':
      return {
        ...state,
        activeAddress: null,
        activeOrder: null,
        basket: null,
        pickupTime: null,
        restaurant: null,
        selectedBrand: null,
        useUtensils: null,
      }

    default:
      return state
  }
}

export const SessionProvider = ({ children }) => {
  const [localStorageState, setLocalStorageState] = useLocalStorage('session', initialState)
  const [state, dispatch] = useReducer(reducer, [], () => {
    const initial = localStorageState

    if (!initial.activeOrder) return initial
    if (moment().diff(moment(initial.activeOrder.dateCreated), 'hours', true) > 3) return { ...initial, activeOrder: null }
    return initial
  })

  useEffect(() => setLocalStorageState(state), [state])

  useEffect(() => {
    const interval = setInterval(() => {
      if (state?.activeOrder?.dateCreated) {
        // Check if this order is more than three hours old
        if (moment().diff(moment(state.activeOrder.dateCreated), 'hours', true) > 3) {
          dispatch({ type: 'CLEAR_ACTIVE_ORDER' })
        }
      }
    }, 60e3) // Run one time per minute
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (state.basket && state.basket.earliestReadytime) {
      const now = moment()
      const basketTime = moment(state.basket.earliestReadytime, 'YYYYMMDD HH:mm')

      if (now.diff(basketTime, 'days', true) > 1) {
        dispatch({ type: 'SET_BASKET', payload: null })
      }
    }
  }, [state.basket])

  return <SessionContext.Provider value={{ state, dispatch }}>{children}</SessionContext.Provider>
}

export const useSession = () => useContext(SessionContext)
