import React, { useMemo } from 'react'

import { Accordion } from '../../components/Accordion/Accordion'
import { useModifierValidation } from '../../hooks/useModifierValidation'
import { ModifierOptionSelector } from '../ModifierOptionSelector/ModifierOptionSelector'
import { ModifierAccordionContentContainer, ModifierAccordionTitleText } from './ModifierAccordion.styles'

export const ModifierAccordion = React.memo(({ modifier }) => {
  const { activeModifiers } = useModifierValidation()

  const isRequired = useMemo(() => modifier.mandatory, [modifier])
  const label = useMemo(() => (modifier.options?.length === 1 ? modifier.options[0].name : modifier.description), [modifier])
  const selection = useMemo(() => activeModifiers?.filter(m => m.modifier.id === modifier.id), [activeModifiers, modifier])

  if (modifier.options?.length === 1) {
    return <ModifierOptionSelector modifier={modifier} option={modifier.options[0]} />
  }

  return (
    <Accordion
      isRequired={isRequired && !selection}
      buttonContent={<ModifierAccordionTitleText>{label}</ModifierAccordionTitleText>}
      buttonStyle={{ padding: '24px 16px' }}
    >
      <ModifierAccordionContentContainer>
        {modifier.options.map(option => (
          <ModifierOptionSelector
            key={option.id}
            style={{ border: 'none', padding: '10px 5px', letterSpacing: 'inherit', textTransform: 'uppercase' }}
            {...{ modifier, option }}
          />
        ))}
      </ModifierAccordionContentContainer>
    </Accordion>
  )
})
