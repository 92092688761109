import { Marker } from '@react-google-maps/api'
import React from 'react'
import { useTheme } from 'styled-components'

import { MapMarkerWithLabel } from '../../components/Map/MapMarker'

export const OrderTrackingCourierMarker = React.memo(({ type, ...props }) => {
  const theme = useTheme()
  const icon = theme.icons.orderTracking.courier[type?.toLowerCase()] || theme.icons.orderTracking.courier.default
  return <Marker icon={icon} {...props} />
})

export const OrderTrackingDestinationMarker = React.memo(props => {
  const theme = useTheme()
  return <MapMarkerWithLabel icon={theme.icons.orderTracking.destination} showInfoBox={theme.orderTracking.map.showInfoBox} {...props} />
})

export const OrderTrackingSourceMarker = React.memo(props => {
  const theme = useTheme()
  return <MapMarkerWithLabel icon={theme.icons.orderTracking.source} showInfoBox={theme.orderTracking.map.showInfoBox} {...props} />
})
