import React from 'react'

import { DoubleCTA } from '../../../../../components/DoubleCTA/DoubleCTA'
import { InputExtraSmall, LabelSmall } from '../../../../../components/Text/Text.styles'
import { useText } from '../../../../../content'
import { Container, Content } from '../DeliveryAddressResults.styles'

export const DeliveryAddressResultsNotFound = React.memo(({ searchNewAddress, brandName, switchToPickup }) => {
  const { text } = useText()
  return (
    <Container>
      <Content>
        <LabelSmall style={{ marginBottom: '32px' }}>{text('Home.Delivery.Results.NoFound.Label')}</LabelSmall>
        <InputExtraSmall>{text('Home.Delivery.Results.NotFound.Description', { brandName })}</InputExtraSmall>
      </Content>
      <DoubleCTA
        buttons={[
          {
            label: 'Switch to pickup',
            onClick: switchToPickup,
            variant: 'primary',
          },
          {
            label: 'Search new address',
            onClick: searchNewAddress,
          },
        ]}
        stacked
      />
    </Container>
  )
})
