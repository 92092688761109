import styled from 'styled-components'

import { isMobile } from '../responsive/Responsive'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  overflow: hidden;
  ${isMobile`
    min-width: auto;
  `}
`
