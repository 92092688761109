// import './meltshop.css' // FIXME:
import './lpq.css'

import Color from 'color'
import { merge } from 'lodash'
import { css } from 'styled-components'

import { isDesktop, isLargeDesktop, isMobile, isTablet } from '../components/responsive/Responsive'
import { theme as defaultTheme } from './default'

const brandFonts = {
  bold: 'Brandon Bold',
  black: 'Brandon Black',
  light: 'Brandon Light',
  medium: 'Brandon Medium',
  regular: 'Brandon Regular',
  thin: 'Brandon Thin',
}

const images = {
  checkoutRewardsSectionCurrencyIcon: require('../assets/images/littlebeet/tlb-rewards-checkout-currency-icon.png'),
  checkoutRewardsSectionButtonIcon: require('../assets/images/littlebeet/tlb-rewards-checkout-icon.svg'),
  rewardHeroBkg: require('../assets/images/lpq/lpq-rewards-hero-bg.png'),
  rewardsIcon: require('../assets/images/meltshop/ms-rewards-icon.png'), // FIXME:
}

const icons = {
  facebookIcon: require('../assets/icons/lpq/facebook.svg'),
  instagramIcon: require('../assets/icons/lpq/instagram.svg'),
  twitterIcon: require('../assets/icons/lpq/twitter.svg'),
  accountWhiteIcon: require('../assets/icons/lpq/account.svg'),
  mapMarkerIcon: require('../assets/icons/lpq/map_marker.svg'),
  myBagWhiteIcon: require('../assets/icons/lpq/my_bag.svg'),
  settings: require('../assets/icons/lpq/settings.svg'),
  search: require('../assets/icons/lpq/search.svg'),
  listItemMarkerIcon: require('../assets/icons/lpq/map_marker.svg'),
  orderTracking: {
    courier: {
      default: require('../assets/icons/lpq/ot-courier-default.svg'),
    },
  },
  size: {
    sm: 16,
    md: 24,
  },
}

const black = Color('#000000')
const red = Color('#BF480D')
const white = Color('#FFFFFF')
const yellow = Color('#FDC400')
const gold = Color('#FDB73E')
const green = Color('#66B245')
const beige = Color('#FBF5EE')

const brandColors = {
  beige,
  black,
  gold10: Color('#FFF5E4'),
  gold20: Color('#FFEDCE'),
  gold30: Color('#FFDFA9'),
  gold40: Color('#FED081'),
  gold50: gold,
  gold60: Color('#F0A526'),
  gold70: Color('#D78E13'),
  gold80: Color('#9D6404'),
  gray10: Color('#F4F3F1'),
  gray20: Color('#DFDEDE'),
  gray30: Color('#D0D0D0'),
  gray40: Color('#B8B8B8'),
  gray50: Color('#A2A2A2'),
  gray60: Color('#808080'),
  gray70: Color('#565656'),
  gray80: Color('#363535'),
  gray90: Color('#2B2929'),
  green,
  red,
  white,
  yellow10: Color('#FFDD68'),
  yellow20: Color('#FCD345'),
  yellow30: Color('#FCCA1F'),
  yellow40: yellow,
  yellow50: yellow,
  yellow60: yellow,
  yellow70: yellow,
  yellow80: yellow,
}

const primary = brandColors.yellow50
const secondary = brandColors.white

const brand = 'lpq'

const colors = {
  brand: brandColors,
  white,
  black,
  primary,
  secondary,
  backgroundRequiredInput: brandColors.gray10,
}

const mapOverlay = {
  activeMarker: {
    fillColor: primary.hex(),
    fillOpacity: 1,
    scale: 1.75,
    path:
      'M5.8752 16C5.71718 15.9251 5.58472 15.8042 5.49467 15.6528C4.02783 13.5488 2.54994 11.4528 1.09099 9.34241C0.535343 8.5325 0.198899 7.58916 0.115198 6.60642C-0.131118 4.04642 1.23467 1.58403 3.55099 0.568027C5.79941 -0.419171 7.92309 -0.118372 9.82415 1.46083C10.3974 1.93309 10.873 2.51541 11.2236 3.17438C11.5742 3.83334 11.793 4.55598 11.8673 5.30082C12.0563 6.79713 11.6695 8.30973 10.7873 9.52481C9.3673 11.5515 7.94625 13.5819 6.52415 15.616C6.4317 15.7826 6.29121 15.9166 6.12151 16H5.8752ZM7.9673 6.02242C7.96881 5.62859 7.85535 5.24311 7.64121 4.91446C7.42706 4.58581 7.12179 4.32867 6.7638 4.17538C6.4058 4.02209 6.01107 3.97949 5.62925 4.05295C5.24742 4.1264 4.89556 4.31263 4.61792 4.58821C4.34027 4.86379 4.14925 5.21642 4.06887 5.60173C3.98849 5.98704 4.02235 6.38783 4.16618 6.7537C4.31002 7.11956 4.5574 7.43415 4.87723 7.65791C5.19705 7.88166 5.57503 8.00458 5.96362 8.01121C6.22417 8.01438 6.48277 7.96541 6.7246 7.86709C6.96643 7.76878 7.18674 7.62307 7.37288 7.43831C7.55903 7.25354 7.70736 7.03337 7.80936 6.7904C7.91136 6.54743 7.96504 6.28645 7.9673 6.02242Z',
  },
  inactiveMarker: {
    fillColor: brandColors.black.hex(),
    fillOpacity: 1,
    scale: 1.75,
    path:
      'M5.8752 16C5.71718 15.9251 5.58472 15.8042 5.49467 15.6528C4.02783 13.5488 2.54994 11.4528 1.09099 9.34241C0.535343 8.5325 0.198899 7.58916 0.115198 6.60642C-0.131118 4.04642 1.23467 1.58403 3.55099 0.568027C5.79941 -0.419171 7.92309 -0.118372 9.82415 1.46083C10.3974 1.93309 10.873 2.51541 11.2236 3.17438C11.5742 3.83334 11.793 4.55598 11.8673 5.30082C12.0563 6.79713 11.6695 8.30973 10.7873 9.52481C9.3673 11.5515 7.94625 13.5819 6.52415 15.616C6.4317 15.7826 6.29121 15.9166 6.12151 16H5.8752ZM7.9673 6.02242C7.96881 5.62859 7.85535 5.24311 7.64121 4.91446C7.42706 4.58581 7.12179 4.32867 6.7638 4.17538C6.4058 4.02209 6.01107 3.97949 5.62925 4.05295C5.24742 4.1264 4.89556 4.31263 4.61792 4.58821C4.34027 4.86379 4.14925 5.21642 4.06887 5.60173C3.98849 5.98704 4.02235 6.38783 4.16618 6.7537C4.31002 7.11956 4.5574 7.43415 4.87723 7.65791C5.19705 7.88166 5.57503 8.00458 5.96362 8.01121C6.22417 8.01438 6.48277 7.96541 6.7246 7.86709C6.96643 7.76878 7.18674 7.62307 7.37288 7.43831C7.55903 7.25354 7.70736 7.03337 7.80936 6.7904C7.91136 6.54743 7.96504 6.28645 7.9673 6.02242Z',
  },
}

const borderRadii = {
  sm: '3px',
}

const borderSizes = {
  xs: '1px',
  sm: '1.5px',
  md: '3px',
}

const borders = {
  light: `${borderSizes.xs} solid ${brandColors.gray30.hex()}`,
  lighter: `${borderSizes.xs} solid ${brandColors.gray10.hex()}`,
  lightHighlight: `${borderSizes.sm} solid ${brandColors.black.hex()}`,
  normal: `${borderSizes.sm} solid ${brandColors.black.hex()}`,
}

const fontSizes = {
  xxs: '12px',
  xs: '14px',
  sm: '16px',
  md: '18px',
  lg: '20px',
  xl: '24px',
  xxl: '36px',
}

const font = {
  styles: {
    regular: brandFonts.regular,
    bold: brandFonts.bold,
    numbers: brandFonts.bold,
    h1: css`
      font-family: ${brandFonts.bold};
      font-size: ${fontSizes.xxl};
      font-weight: normal;
      letter-spacing: 0.03em;
      line-height: 42px;
    `,
    h2: css`
      font-family: ${brandFonts.bold};
      font-size: ${fontSizes.xl};
      font-weight: normal;
      letter-spacing: 0.02em;
    `,
    h3: css`
      font-family: ${brandFonts.bold};
      font-size: ${fontSizes.lg};
      font-weight: normal;
    `,
    h4: css`
      font-family: ${brandFonts.bold};
      font-size: ${fontSizes.md};
      font-weight: normal;
    `,
    description: css`
      font-family: ${brandFonts.regular};
      font-size: ${fontSizes.sm};
      font-weight: 500;
      line-height: 140%;
    `,
    descriptionExtraSmall: css`
      font-family: ${brandFonts.regular};
      font-size: ${fontSizes.xxs};
      font-weight: 500;
      line-height: 143%;
      letter-spacing: 0.02em;
    `,
    descriptionLarge: css`
      font-family: ${brandFonts.regular};
      font-size: ${fontSizes.lg};
      font-weight: 500;
      line-height: 143%;
      letter-spacing: 0.02em;
    `,
    cta: css`
      font-family: ${brandFonts.bold};
      font-size: ${fontSizes.lg};
      font-weight: normal;
      letter-spacing: 0.03em;
      line-height: 120%;
      text-transform: uppercase;
    `,
    ctaSmall: css`
      font-family: ${brandFonts.bold};
      font-size: ${fontSizes.xs};
      font-weight: normal;
      letter-spacing: 0.06em;
      line-height: 120%;
      text-transform: uppercase;
    `,
    label: css`
      font-family: ${brandFonts.bold};
      font-size: ${fontSizes.lg};
      font-weight: normal;
      letter-spacing: 0.06em;
      line-height: 120%;
    `,
    labelInputBold: css`
      font-family: ${brandFonts.bold};
      font-size: ${fontSizes.lg};
      font-weight: 600;
      letter-spacing: 0.02em;
      line-height: 150%;
    `,
    labelSpecial: css`
      font-family: ${brandFonts.bold};
      font-size: 16px;
      font-weight: normal;
      line-height: 19px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
    `,
    labelExtraSmall: css`
      font-family: ${brandFonts.bold};
      font-size: ${fontSizes.xs};
      font-weight: 800;
      letter-spacing: 0.08em;
      line-height: 114%;
      text-transform: uppercase;
    `,
    labelSmall: css`
      font-family: ${brandFonts.bold};
      font-size: ${fontSizes.sm};
      font-weight: normal;
      line-height: 140%;
      text-transform: uppercase;
    `,
    labelMedium: css`
      font-family: ${brandFonts.bold};
      font-size: ${fontSizes.md};
      font-weight: normal;
      letter-spacing: 0.03em;
      line-height: 19px;
      text-transform: uppercase;
    `,
    inputDefault: css`
      font-family: ${brandFonts.regular};
      font-size: ${fontSizes.xs};
      font-weight: normal;
      letter-spacing: 0.08em;
      line-height: 120%;
      text-transform: uppercase;
    `,
    inputExtraSmall: css`
      font-family: ${brandFonts.regular};
      font-size: ${fontSizes.xxs};
      font-weight: normal;
      letter-spacing: 0.08em;
      line-height: 120%;
      text-transform: uppercase;
    `,
    labelInput: css`
      font-family: ${brandFonts.regular};
      font-size: ${fontSizes.sm};
      font-weight: normal;
    `,
    labelInputSmall: css`
      font-family: ${brandFonts.regular};
      font-size: ${fontSizes.xs};
      font-weight: normal;
      line-height: 120%;
      text-transform: uppercase;
    `,
    labelInputExtraSmall: css`
      font-family: ${brandFonts.regular};
      font-size: ${fontSizes.xxs};
      font-weight: normal;
      line-height: 140%;
    `,
    labelInputError: css`
      color: ${brandColors.red};
      font-family: ${brandFonts.regular};
      font-size: ${fontSizes.xxs};
      font-weight: normal;
    `,
    labelCurrency: css`
      font-family: ${brandFonts.bold};
      font-size: ${fontSizes.sm};
      font-weight: normal;
      font-weight: 700;
    `,
    labelCurrencySmall: css`
      color: ${brandColors.gray70};
      font-family: ${brandFonts.regular};
      font-weight: normal;
      letter-spacing: 0.08em;
      text-transform: uppercase;
    `,
    labelCurrencyLarge: css`
      font-family: ${brandFonts.bold};
      font-size: ${fontSizes.lg};
      font-weight: normal;
      font-weight: 800;
    `,
  },
  sizes: fontSizes,
}

const buttons = {
  buttonContainer: {
    primary: css`
      background-color: ${primary.hex()};
      border: ${borderSizes.sm} solid ${primary.hex()};
      color: ${brandColors.black.hex()};
      &:hover {
        background: ${brandColors.yellow60.hex()};
        border-color: ${brandColors.yellow60.hex()};
        color: ${brandColors.black.hex()};
      }
    `,
    secondary: css`
      background-color: ${secondary.hex()};
      border: ${borderSizes.sm} solid ${brandColors.black.hex()};
      color: ${brandColors.black.hex()};
      &:hover {
        background: ${brandColors.yellow60.hex()};
        border-color: ${brandColors.yellow60.hex()};
        color: ${brandColors.black.hex()};
      }
    `,
  },
  roundContainer: {
    primary: css`
      background-color: ${brandColors.white.hex()};
      border: ${borderSizes.xs} solid ${brandColors.black.hex()};
      color: ${brandColors.black.hex()};
      &:hover {
        background-color: ${brandColors.white.hex()};
        border: ${borderSizes.xs} solid ${brandColors.black.hex()};
        color: ${brandColors.black.hex()};
      }
    `,
    secondary: css`
      background-color: ${white.hex()};
      border: ${borderSizes.xs} solid ${brandColors.gray30.hex()};
      color: ${brandColors.black.hex()};
      &:hover {
        background-color: ${white.hex()};
        border: ${borderSizes.xs} solid ${brandColors.gray30.hex()};
        color: ${brandColors.black.hex()};
      }
    `,
  },
  small: css`
    ${font.styles.ctaSmall};
    padding: 10px 16px;
  `,
  modalHeaderCloseButtonVariant: 'secondary',
}

const changeAddressChip = {
  outerContainerStyle: css`
    height: 100%;
    &:before {
      content: '';
      border: 1px solid ${brandColors.black.hex()};
      height: 100%;
      margin: 0 26px;
    }
  `,
  innerContainerStyle: css`
    background: ${white.hex()};
    color: ${brandColors.black.hex()};
    font-family: ${font.styles.bold};
    font-size: ${font.sizes.sm};
    letter-spacing: 0.04em;
    line-height: ${font.sizes.lg};
    padding: 8px 0;
    &:hover {
      color: ${brandColors.black.hex()};
    }
  `,
}
const header = {
  logoAsset: 'LogoOnLight',
  container: css`
    background: ${({ theme }) => theme.header.styles.backgroundColor};
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
    padding: 24px;
    ${isTablet`
      padding-left: 0;
    `}
    ${isMobile`
      padding: 16px;
    `}
  `,
  styles: {
    backgroundColor: brandColors.white,
    linkColor: brandColors.black,
  },
}

const home = {
  heroAsset: 'HomeHero',
  sectionHeaderBackground: brandColors.beige.hex(),
  styles: {
    headerText: css`
      ${font.styles.description}
      color: ${brandColors.black}
    `,
    sectionH1: css`
      ${isMobile`
        font-size: ${fontSizes.xl};
        line-height: 28px;
      `}
      ${isTablet`
        font-size: ${fontSizes.xxl};
        line-height: 42px;
      `}
      ${isDesktop`
        font-size: ${fontSizes.xxl};
        line-height: 42px;
      `}
      ${isLargeDesktop`
        font-size: ${fontSizes.xxl};
        line-height: 42px;
      `}
    `,
    sectionHeaderContainer: css`
      ${isMobile`
        padding: 24px 16px;
      `}
      ${isTablet`
        padding: 28px 24px;
      `}
      ${isDesktop`
        padding: 48px;
      `}
      ${isLargeDesktop`
        padding: 48px;
      `}
    `,
    sectionBody: css`
      gap: 36px;
      ${isMobile`
        padding: 36px 16px;
      `}
      ${isTablet`
        padding: 36px 24px;
      `}
      ${isDesktop`
        padding: 48px;
      `}
      ${isLargeDesktop`
        padding: 48px;
      `}
    `,
    viewLocationsButton: css`
      font-family: ${brandFonts.regular};
      font-size: ${fontSizes.xs};
      font-weight: normal;
      line-height: 120%;
      text-decoration: underline;
      text-transform: unset;
    `,
  },
}

const boxShadow = `-2px 0px 6px 4px ${colors.black.alpha(0.06)}`

const toggles = {
  active: {
    border: `${borderSizes.xs} solid ${brandColors.black.hex()}`,
    backgroundColor: brandColors.black.hex(),
    textColor: brandColors.white.hex(),
  },
  inactive: {
    border: `${borderSizes.xs} solid ${brandColors.gray30.hex()}`,
    backgroundColor: brandColors.white.hex(),
    textColor: brandColors.black.hex(),
  },
  style: css`
    padding: 10px 0;
    max-width: 124px;
  `,
  styles: {
    active: css`
      background-color: ${brandColors.yellow50.hex()};
      color: ${brandColors.black.hex()};
      border: ${borderSizes.sm} solid ${brandColors.yellow50.hex()};
    `,
    inactive: css`
      background-color: ${brandColors.white.hex()};
      color: ${brandColors.yellow50.hex()};
      border: ${borderSizes.sm} solid ${brandColors.yellow50.hex()};
    `,
  },
}

const items = {
  selected: {
    background: brandColors.gray10.hex(),
  },
  highlighted: {
    background: brandColors.gray20.hex(),
  },
  hover: {
    background: brandColors.gray20.hex(),
  },
}

const toast = css`
  .Toastify__toast-body {
    display: flex;
    justify-content: center;

    font-family: ${brandFonts.regular};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
  }

  .Toastify__close-button {
    display: flex;
    margin: auto 0;
  }

  .Toastify__toast--info {
    background: ${brandColors.yellow50};
    color: ${brandColors.black};
  }
  .Toastify__toast--info > .Toastify__close-button {
    color: ${brandColors.black};
  }

  .Toastify__toast--error {
    background: ${brandColors.red};
    color: ${brandColors.white};
  }
  .Toastify__toast--error > .Toastify__close-button {
    color: ${brandColors.white};
  }
`

const autocomplete = {
  container: css``,
}

const card = {
  container: css``,
  restaurant: {
    summaryText: css`
      font-family: ${brandFonts.bold};
      margin-bottom: 11px;
      line-height: 140%;
      letter-spacing: unset;
    `,
  },
}

const input = {
  container: css`
    border-radius: unset;
    border-color: ${brandColors.gray30.hex()};
  `,
  element: css`
    color: ${brandColors.gray80.hex()};
  `,
  label: css`
    font-family: ${brandFonts.bold};
    color: ${brandColors.black.hex()};
    text-transform: unset;
  `,
}

const auth = {
  header: css`
    ${font.styles.h1};
    color: ${black.hex()};
    background-color: ${white.hex()};
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
  `,
}

const baseCheckoutFrameStyleCSS = `
  border-bottom-style: none;
  border-left-style: none;
  border-radius: unset;
  border-right-style: none;
  border-top-style: none;
  padding: 24px 16px;
  font-size: 17px;
  font-weight: normal;
  width: 100%;
`

const checkoutFrame = {
  styles: {
    cardNumber: baseCheckoutFrameStyleCSS,
    cvv: baseCheckoutFrameStyleCSS,
  },
}

export const theme = merge({}, defaultTheme, {
  auth,
  autocomplete,
  borderRadii,
  borders,
  borderSizes,
  brand,
  buttons,
  card,
  changeAddressChip,
  checkoutFrame,
  colors,
  font,
  header,
  home,
  icons,
  input,
  items,
  mapOverlay,
  toast,
  toggles,
  currency: {
    left: {
      fontFamily: brandFonts.bold,
      fontSize: '16px',
    },
    right: {
      fontFamily: brandFonts.bold,
      fontSize: '16px',
    },
  },
  profile: {
    header: css`
      color: ${black};
      background-color: transparent;
      text-transform: uppercase;
    `,
    headerText: css`
      color: ${black};
      background-color: transparent;
      text-transform: uppercase;
    `,
    navBar: {
      container: css`
        background-color: ${brandColors.beige.hex()};
      `,
      navItem: css`
        text-transform: unset;
      `,
      textColor: brandColors.gray60.hex(),
      selectedItemTextDecoration: 'underline',
      selectedItemTextColor: brandColors.black.hex(),
      selectedItemBackgroundColor: 'transparent',
    },
  },
  accountCreated: {
    messageFontStyle: css`
      font-family: ${brandFonts.bold};
      font-size: 28px;
    `,
  },
  categoryLanding: {
    bodyPadding: '0px',
    itemMargin: '10px',
    displayMeta: true,
    displayTriangles: true,
    imageWidth: '55%',
    imagePosition: 'absolute',
    backgroundImageAsset: 'CategoryLandingBackgroundImage',
  },
  menuContext: {
    backgroundColor: Color('#212224'),
    iconBackgroundColor: Color('transparent'),
    iconColor: Color(white),
    iconBorderColor: Color(white),
    categoryBar: {
      backgroundColor: Color(black),
      active: {
        border: 'none',
        background: 'transparent',
        textColor: Color(white),
        fontFamily: brandFonts.bold,
      },
      inactive: {
        border: 'none',
        background: 'transparent',
        textColor: Color(white).alpha(0.5),
        fontFamily: brandFonts.bold,
      },
    },
  },
  handoffSelector: {
    backgroundColor: Color(black),
    color: Color(white),
    border: 'none',
    fontFamily: brandFonts.bold,
    secondary: {
      backgroundColor: brandColors.orange50,
      color: Color(black),
      border: 'none',
      fontFamily: brandFonts.bold,
    },
  },
  menuCategoryBar: css`
    background: ${brandColors.beige.hex()};
  `,
  menuCategoryBarButton: css`
    font-family: ${brandFonts.regular};
    color: ${brandColors.black.hex()};
    text-transform: unset;
    text-align: left;
  `,
  menuCategoryBarButtonSelected: css`
    font-weight: bold;
    color: ${brandColors.black.hex()};
    text-decoration: underline;
  `,
  menuCategoryList: css`
    background: ${brandColors.white.hex()};
  `,
  menuCategoryListAuthenticated: css`
    border-right: ${borderSizes.sm} solid #e9e9e9;
    margin-top: 50px;
    height: ${props => `calc(100% - ${props.$headerHeight}px - 50px)`};
    ${isLargeDesktop`
      padding: 0 48px 16px;
    `}
    ${isDesktop`
      padding: 0 48px 12px;
    `}
    ${isTablet`
      padding: 0 48px 8px;
    `}
  `,
  menuCategoryListUnauthenticated: css`
    background: ${brandColors.beige.hex()};
    height: ${props => `calc(100% - ${props.$headerHeight}px)`};
    ${isLargeDesktop`
      padding: 50px 48px 16px;
    `}
    ${isDesktop`
      padding: 50px 48px 12px;
    `}
    ${isTablet`
      padding: 50px 48px 8px;
    `}
  `,
  menuCategoryListSpacerUnauthenticated: css`
    background: ${brandColors.beige.hex()};
  `,
  menuCategoryListButton: css`
    color: ${brandColors.black.hex()};
    font-family: ${brandFonts.regular};
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
  `,
  menuCategoryListButtonSelected: css`
    font-weight: bold;
    color: ${brandColors.black.hex()};
    text-decoration: underline;
  `,
  menuItems: {
    controlContainerStyle: css`
      border-left: 0px;
      border-right: 0px;
      border-top: 0px;
      margin-bottom: 0px !important;
      border-radius: 0px;
      padding-left: 0px;
      padding-right: 0px;

      & > button {
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        border-radius: 0px;
        padding-left: 0px !important;
        padding-right: 0px !important;

        &[aria-checked='true'] {
          border-top: 0px;
          border-left: 0px;
          border-right: 0px;
          border-radius: 0px;
        }
      }
    `,
    totalMetaStyle: css`
      font-family: ${brandFonts.regular};
      font-size: ${fontSizes.sm};
      line-height: 120%;
      letter-spacing: 0.06em;
      margin-right: 4px;
    `,
    totalTextStyle: css`
      font-family: ${brandFonts.regular};
      font-size: ${fontSizes.sm};
      font-weight: 600;
      line-height: 120%;
    `,
    simpleBuilder: {
      modifierTextColor: primary.hex(),
      infoContainerStyle: css`
        color: ${brandColors.black.hex()};
        /* Desktop, Large Desktop and Tablet */
        padding: 0px 0px 12px 0px;
        ${isMobile`
          padding: 0px 0px 12px 0px;
        `}
      `,
      footerContainerStyle: css`
        background-color: ${brandColors.beige};
      `,
    },
    cards: {
      style: css`
        background: ${props => props.theme.colors.brand.white.hex()};
        border-radius: 3px;
        border: ${props => `1px solid ${props.theme.colors.brand.gray20.hex()}`};
        box-shadow: ${props => `0px 2px 8px ${props.theme.colors.black.alpha(0.05)}`};
        &:hover {
          border-color: ${props => props.theme.colors.primary.hex()};
          box-shadow: ${props => `2px 2px 15px ${props.theme.colors.black.alpha(0.2)}`};
        }
      `,
      descriptionHeaderStyle: css`
        color: ${brandColors.black.hex()};
        font-family: ${brandFonts.bold};
        font-size: 16px;
        font-weight: normal;
        letter-spacing: 0.04em;
        line-height: 20px;
        text-transform: uppercase;
      `,
      descriptionTextStyle: css`
        font-family: ${brandFonts.regular};
        font-weight: normal;
        font-size: 14px;
        line-height: 130%;
        color: ${brandColors.gray70.hex()};
      `,
      imageStyle: css`
        min-height: 175px;
        min-width: 175px;
        height: 175px;
        width: 175px;
        ${isMobile`
          min-height: 125px;
          min-width: 125px;
          height: 125px;
          width: 125px;
        `}
      `,
      imageSize: '175px',
      imageResizeMode: 'cover',
      menuStyle: css`
        ${isLargeDesktop`
          padding: 10px 48px 10px 118px;
        `};
        ${isDesktop`
         padding: 10px 48px 10px 78px;
        `};
        ${isTablet`
          padding: 10px 48px 10px 58px;
        `};
        ${isMobile`
          padding: 0 16px;
        `};
      `,
      menuSectionStyle: css`
        padding-bottom: 40px;
        &:not(:last-child) {
          border-bottom: 1px solid #e9e9e9;
          margin-bottom: 1px;
        }
      `,
      menuHeaderStyle: css`
        color: ${brandColors.black.hex()};
        font-family: ${brandFonts.bold};
        font-weight: normal;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        font-size: 22px;
        line-height: 28px;
        margin-top: 40px;
        width: 100%;
      `,
      numberTextStyle: css`
        color: ${brandColors.black.hex()};
        font-family: ${brandFonts.bold};
        font-size: ${fontSizes.sm};
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0.06em;
        line-height: 150%;
      `,
    },
    categoryHeading: {
      fontFamily: brandFonts.bold,
      fontSize: '34px',
      borderBottomWidth: '0px',
      borderBottomColor: Color('transparent'),
      padding: '39px 0px 28px 0px',
      textAlign: 'center',
      textColor: black.hex(),
    },
  },
  basket: {
    header: css`
      background-color: ${brandColors.white.hex()};
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
      text-transform: uppercase;
    `,
    topBackgroundColor: brandColors.black.hex(),
    topTextColor: brandColors.black.hex(),
    backButtonVariant: 'secondary',
    bagItemsCount: css`
      font-family: ${brandFonts.bold};
      font-size: 8px;
      font-weight: normal;
      letter-spacing: 0.08em;
      line-height: 200%;
      text-transform: uppercase;
    `,
    basketCardItemName: css`
      ${font.styles.labelSmall};
    `,
    summaryContainer: css`
      background: ${brandColors.gray10.hex()};
    `,
    costItemLabel: css`
      border: 0;
    `,
    costsNumberSubtotal: css`
      font-family: ${font.styles.numbers};
    `,
    costsLabel: css`
      ${font.styles.labelSmall};
      font-size: 24px;
    `,
    costsNumber: css`
      font-family: ${font.styles.numbers};
      font-size: 24px;
    `,
    upsellSection: css`
      background-color: ${brandColors.beige.hex()};
      margin: 48px 0 0;
      padding: 32px;
    `,
    upsellCarousel: css`
      background-color: 'transparent';
    `,
    upsellTitle: css`
      ${font.styles.label};
    `,
    upsellCarouselCard: css`
      border-radius: 3px;
      background-color: ${brandColors.gray10};
      border: 1px solid ${brandColors.gray30};
    `,
    upsellCarouselCardItemName: css`
      ${font.styles.labelInput};
    `,
  },
  checkout: {
    topBackgroundColor: brandColors.beige.hex(),
    topTextColor: black.hex(),
    backButtonVariant: 'secondary',
    sectionLabel: css`
      ${font.styles.description};
      font-weight: bold;
      margin: 48px 0 14px 0;
      text-transform: uppercase;
      color: ${brandColors.black.hex()};
    `,
    sectionButton: css`
      border-radius: 0;
      label {
        color: ${brandColors.black.hex()};
        font-weight: bold;
      }
      span {
        color: ${brandColors.black.hex()};
        font-size: ${font.sizes.md};
      }
    `,
    summary: {
      header: css`
        ${font.styles.labelInput};
        background-color: ${brandColors.beige.hex()};
        box-shadow: 0px 2px 8px rgb(0 0 0 / 5%);
        color: ${brandColors.black};
      `,
      container: css`
        box-shadow: 0px 2px 8px rgb(0 0 0 / 5%);
        border: 1px solid #d4d7db;
        box-sizing: border-box;
        border-radius: 0 0 3px 3px;
        border-top: 0;
        hr {
          border: none;
          border-top: 1px solid ${brandColors.gray30.hex()};
        }
      `,
      productName: css`
        ${font.styles.labelInput};
      `,
      costsLabel: css`
        ${font.styles.labelInput};
      `,
      costsNumber: css`
        ${font.styles.labelInput};
        font-size: 24px;
      `,
    },
    heroAsset: 'CheckoutHero',
    rewards: {
      currencyButtonIcon: images.rewardsIcon,
      sectionButtonIcon: images.rewardsIcon,
    },
  },
  modals: {
    header: css`
      background-color: ${brandColors.white};
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
      justify-content: center;
      padding: 24px 20px;
      text-transform: uppercase;
      z-index: 1;
    `,
  },
  rewards: {
    background: css`
      background: ${`url(${images.rewardHeroBkg}) no-repeat center center`};
      background-size: cover;
      width: 100%;
    `,
  },
})
