import React from 'react'
import { FaCheckCircle } from 'react-icons/fa'
import { Checkbox } from 'reakit/Checkbox'

import { Button } from './ToggleButton.styles'

export function ToggleButton({ label, checked = false, onClick, position = 'right', ...props }) {
  return (
    <Checkbox as={Button} checked={checked} onChange={onClick} focusable {...props}>
      {position === 'left' && <FaCheckCircle size={24} style={{ marginRight: '16px' }} />}
      <span>{label}</span>
      {position === 'right' && <FaCheckCircle size={24} />}
    </Checkbox>
  )
}
