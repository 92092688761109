import { Dialog, DialogBackdrop } from 'reakit/Dialog'
import styled from 'styled-components'

import { isMobile } from '../../responsive/Responsive'

export const FullDialogBackdrop = styled(DialogBackdrop)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 250ms ease-in-out;
  &[data-enter] {
    opacity: 1;
  }
  &[data-leave] {
    opacity: 1;
  }
`

export const MainContainer = styled(Dialog)`
  display: flex;
  flex-direction: column;

  background: ${props => props.theme.colors.brand.white};
  max-height: 100vh;
  opacity: 0;

  transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;

  /* slide from bottom transition animation */
  &.slideInFromBottom {
    transform: translateY(100%);
  }

  &[data-enter] {
    opacity: 1;
    transform: translateY(0);
  }

  ${isMobile`
    width: 100%;
  `}
`
