import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDialogState } from 'reakit/Dialog'

import { FullScreenLoading } from '../../components/FullScreenLoading/FullScreenLoading'
import { Map } from '../../components/Map/Map'
import { MapContextProvider, useMapContext } from '../../components/Map/Map.context'
import { OrderConfirmation } from '../../components/OrderConfirmation/OrderConfirmation'
import { Page } from '../../components/Page/Page'
import { SelectHandoffMode } from '../../components/SelectHandoffMode/SelectHandoffMode'
import { useText } from '../../content/index'
import { useConfig } from '../../contexts/appConfig'
import { useSession } from '../../contexts/session'
import { useAsset } from '../../hooks/useAsset'
import { useFeatureFlags } from '../../hooks/useFeatureFlags'
import { useHandoffMode } from '../../hooks/useHandoffMode'
import { useMessaging } from '../../hooks/useMessaging'
import { DeliveryAddressResults } from './components/DeliveryAddressResults/DeliveryAddressResults'
import { RestaurantCardList } from './components/RestaurantCardList'
import { SearchAddressAutocomplete } from './components/SearchAddressAutocomplete'
import { PickupTimeModal } from './components/SelectTimeModal/PickupTimeModal/PickupTimeModal'
import { ViewLocationsButton } from './components/ViewLocationsButton'
import { HomeContextProvider, useHomeContext } from './Home.context'
import {
  Aside,
  HandoffModeTabPanel,
  HeroContainer,
  HeroImage,
  HomeContainer,
  HomeSectionHeader,
  Section,
  SectionBody,
  SectionHeaderContainer,
} from './Home.styles'

const isSnap = navigator.userAgent === 'ReactSnap'

const Hero = () => (
  <HeroContainer>
    <HeroImage src={useAsset('home', 'heroAsset')?.url} />
  </HeroContainer>
)

export const Sections = () => {
  const [scheduleTimeRestaurant, setScheduleTimeRestaurant] = useState(null)
  const { activeHandoffMode, displayMap, pickupRestaurants, setActiveHandoffMode, tabState } = useHomeContext()
  const { dispatch, state } = useSession()
  const { resolveNavigationPayload } = useConfig()
  const { text } = useText()
  const dialogProps = useDialogState({ animated: true })
  const history = useHistory()
  const { PickTimeAtCheckout } = useFeatureFlags()
  const { dispatchMessage } = useMessaging()
  const { displayName: handoffModeName } = useHandoffMode()

  useEffect(() => {
    console.debug('[Home] activeHandoffMode:', activeHandoffMode)
  }, [activeHandoffMode])

  const startOrder = useCallback(
    (restaurant, pickupTime = 'ASAP') => {
      console.debug('[Home] starting order for:', restaurant)
      dispatch({ type: 'RESET' })
      dispatch({ type: 'SET_RESTAURANT', payload: restaurant })
      dispatch({ type: 'SET_PICKUP_TIME', payload: pickupTime })

      const screen = resolveNavigationPayload('StartOrder')
      history.push(screen)
    },
    [dispatch, resolveNavigationPayload, history, dispatchMessage, text],
  )

  const scheduleTime = useCallback(
    restaurant => {
      if (PickTimeAtCheckout) {
        dispatchMessage.info(text('Home.PickTimeAtCheckout.Message', { restaurantName: restaurant.name, handoffModeName }))
        startOrder(restaurant, null)
      } else {
        console.debug('[Home] scheduling time order for:', restaurant)
        setScheduleTimeRestaurant(restaurant)
        dialogProps.show()
      }
    },
    [dialogProps, setScheduleTimeRestaurant, PickTimeAtCheckout, startOrder, dispatchMessage, text],
  )

  const startScheduleOrder = useCallback(
    (restaurant, pickupTime) => {
      console.debug('[Home] starting schedule order for:', restaurant)
      dialogProps.hide()
      dispatch({ type: 'RESET' })
      dispatch({ type: 'SET_PICKUP_TIME', payload: pickupTime })
      dispatch({ type: 'SET_RESTAURANT', payload: restaurant })

      const screen = resolveNavigationPayload('StartOrder')
      history.push(screen)
    },
    [dispatch, dialogProps, resolveNavigationPayload, history],
  )

  return (
    <HomeContainer>
      <Section tabIndex="0" aria-label="Select handoff mode, search for address and select restaurant to start order">
        <SectionHeaderContainer style={{ display: 'block' }}>
          {state.activeOrder?.order ? (
            <OrderConfirmation order={state.activeOrder?.order} />
          ) : (
            <>
              <HomeSectionHeader>{text('Home.Section.Header.Line.1')}</HomeSectionHeader>
              <HomeSectionHeader>{text('Home.Section.Header.Line.2')}</HomeSectionHeader>
            </>
          )}
        </SectionHeaderContainer>
        <SectionBody>
          <SelectHandoffMode activeHandoffMode={activeHandoffMode} changeHandoffMode={setActiveHandoffMode} tabState={tabState} />
          <HandoffModeTabPanel {...tabState}>
            <div>
              <SearchAddressAutocomplete placeholder="Search by store, city, state, or zip" scheduleTime={scheduleTime} startOrder={startOrder} />
              <ViewLocationsButton />
            </div>
            <RestaurantCardList restaurants={pickupRestaurants?.data} scheduleTime={scheduleTime} startOrder={startOrder} />
          </HandoffModeTabPanel>
          <HandoffModeTabPanel {...tabState}>
            <SearchAddressAutocomplete placeholder="Enter your address" />
            <DeliveryAddressResults tabState={tabState} />
          </HandoffModeTabPanel>
        </SectionBody>
      </Section>
      <Aside>{displayMap ? <Map isSnap={isSnap} /> : <Hero />}</Aside>
      {!pickupRestaurants.loading && (
        <PickupTimeModal dialogProps={dialogProps} restaurant={scheduleTimeRestaurant} onSelectScheduleTime={startScheduleOrder} />
      )}
    </HomeContainer>
  )
}

export const Home = () => {
  const { isLoaded, hasError } = useMapContext()
  const { dispatchMessage } = useMessaging()
  console.log(`isLoaded`, isLoaded)
  useEffect(() => {
    if (hasError) {
      dispatchMessage({ err: new Error('Could not load Google Maps API'), type: 'error' })
    }
  }, [hasError])
  return !isLoaded ? (
    <FullScreenLoading />
  ) : (
    <HomeContextProvider>
      <Sections />
    </HomeContextProvider>
  )
}

export const HomePage = () => (
  <Page limitMainMaxHeight>
    {isSnap ? (
      <HomeContextProvider>
        <Sections />
      </HomeContextProvider>
    ) : (
      <MapContextProvider>
        <Home />
      </MapContextProvider>
    )}
  </Page>
)
