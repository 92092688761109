import styled from 'styled-components'

export const ModifierAccordionContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px 7px;
  width: 100%;
`

export const ModifierAccordionTitleText = styled.span`
  ${({ theme }) => theme.font.styles.labelInputSmall}
  text-transform: uppercase;
`
