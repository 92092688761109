import React, { useMemo } from 'react'

import { useFeatureFlags } from '../../../hooks/useFeatureFlags'
import { DefaultSavedAddresses as Default } from './DefaultSavedAddresses/DefaultSavedAddresses'
import { FirebaseSavedAddresses } from './FirebaseSavedAddresses/FirebaseSavedAddresses'

export const SavedAddressesContent = props => {
  const { FirebaseSupport } = useFeatureFlags()
  const Component = useMemo(() => (FirebaseSupport ? FirebaseSavedAddresses : Default), [FirebaseSupport])
  return <Component {...props} />
}
