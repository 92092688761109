import React, { useCallback, useMemo } from 'react'

import { formatCurrency } from '../../../data/utils'
import { useModifierValidation } from '../../../hooks/useModifierValidation'
import { ToggleButton } from '../../ToggleButton/ToggleButton'
import { CostText } from '../ModifierOptionSelector.styles'

export const ModifierOptionToggleSelector = React.memo(({ modifier, option, ...props }) => {
  const { selectOption, activeModifiers, isModifierEnabled } = useModifierValidation()
  const match = useMemo(() => activeModifiers?.find(m => m.option.id === option.id), [activeModifiers, option])
  const isActive = useMemo(() => !!match, [match])
  const cost = useMemo(() => (match ? match.quantity * match.option.cost : 0), [match])

  const handleClick = useCallback(() => {
    console.debug('[ModifierOptionSelector] handleClick', { modifier, option, isEnabled: isModifierEnabled(modifier) })
    selectOption(modifier, option, isModifierEnabled(modifier) && !isActive ? 1 : 0)
  }, [selectOption, modifier, option, isModifierEnabled, isActive])

  return (
    <ToggleButton checked={isActive} onClick={handleClick} {...props}>
      {option.name}
      {isActive && cost > 0 && <CostText>(+{formatCurrency(cost)})</CostText>}
    </ToggleButton>
  )
})
