import React, { useMemo } from 'react'

import { useFeatureFlags } from '../../hooks/useFeatureFlags'
import { DefaultCustomBuilder } from './DefaultCustomBuilder/DefaultCustomBuilder'
import { SinglePageScrollCustomBuilder } from './SinglePageScrollCustomBuilder/SinglePageScrollCustomBuilder'

export const CustomBuilderPage = props => {
  const { SinglePageScrollCustomBuilder: isSinglePage } = useFeatureFlags()
  const Component = useMemo(() => (isSinglePage ? SinglePageScrollCustomBuilder : DefaultCustomBuilder), [isSinglePage])
  return <Component {...props} />
}
