import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const Container = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.primary};
  border-radius: 75%;
  border-top-color: white;
  height: 16px;
  width: 16px;

  transition: opacity 250ms;

  animation: ${rotate} 1s linear;
  animation-iteration-count: infinite;

  span {
    opacity: 0;
  }
`
