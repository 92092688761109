import React, { useCallback, useLayoutEffect } from 'react'

import { useWindowSize } from '../../../hooks/useWindowsSize'
import { isIosDevice } from '../../../utils/device'
import { FullDialogBackdrop, SideBar } from './DefaultDialogWithBackdrop.styles'

export const DefaultDialogWithBackdrop = ({ children, dialogProps, ariaLabel, slideInFrom = 'right', containerStyle, onBackdropClick }) => {
  const slideInFromLeftClass = slideInFrom === 'left' ? 'slideInFromLeft' : ''
  const modal = isIosDevice() ? false : dialogProps.modal
  const [_, windowHeight] = useWindowSize()

  useLayoutEffect(() => {
    if (isIosDevice()) {
      document.body.style.overflow = dialogProps?.visible ? 'hidden' : ''
    }
  }, [dialogProps?.visible])

  const handleDialogClick = useCallback(event => event.stopPropagation(), [])

  const handleBackdropClick = useCallback(
    event => {
      event.stopPropagation()
      onBackdropClick ? onBackdropClick() : dialogProps.hide()
    },
    [dialogProps.hide, onBackdropClick],
  )

  return (
    <FullDialogBackdrop {...dialogProps} onClick={handleBackdropClick}>
      <SideBar
        {...dialogProps}
        aria-label={ariaLabel}
        className={slideInFromLeftClass}
        hideOnClickOutside={false}
        modal={modal}
        onClick={handleDialogClick}
        style={{ ...containerStyle, height: windowHeight }}
        tabIndex={0}
      >
        {children}
      </SideBar>
    </FullDialogBackdrop>
  )
}
