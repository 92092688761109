import { useQuery } from '@apollo/client'
import { useCallback, useEffect } from 'react'

import { useSession } from '../contexts/session'
import { GET_GUEST_BILLING_ACCOUNTS } from '../data/guest/queries'
import firebase from '../services/firebase'
import { useMessaging } from './useMessaging'

const getOrCreateKey = paymentMethod => paymentMethod?.accountId || paymentMethod?.key || `_${Math.random().toString(36).substr(2, 9)}`

const getDescription = paymentMethod => {
  if (!paymentMethod || paymentMethod.accountType !== 'creditcard') {
    return ''
  }
  if (paymentMethod.accountId) {
    return `${paymentMethod.cardType} ending in ${paymentMethod.cardSuffix}`
  } else {
    return `New credit card expiring in ${paymentMethod.expiry}`
  }
}

const hydrate = paymentMethod =>
  paymentMethod && {
    ...paymentMethod,
    key: getOrCreateKey(paymentMethod),
    description: getDescription(paymentMethod),
  }

export const usePaymentMethods = ({ authToken, basketId }) => {
  const { dispatchMessage } = useMessaging()
  const { state, dispatch } = useSession()
  const { activePaymentMethod, savedPaymentMethods } = state

  const { data, loading } = useQuery(GET_GUEST_BILLING_ACCOUNTS, {
    skip: !authToken,
    fetchPolicy: 'no-cache',
    variables: { orderingAuthenticationToken: authToken, basketId },
    onError: useCallback(e => dispatchMessage.error(e.message, e), [dispatchMessage]),
    onCompleted: ({ guestBillingAccounts = [] }) => {
      console.debug('[usePaymentMethods] updating savedPaymentMethods:', guestBillingAccounts, 'for basket', basketId)
      setSavedPaymentMethods(guestBillingAccounts)
    },
  })

  const addNewActivePaymentMethod = useCallback(
    newPaymentMethod => {
      console.debug('[usePaymentMethods] addNewActivePaymentMethod', newPaymentMethod)
      const paymentMethod = hydrate(newPaymentMethod)
      dispatch({ type: 'ADD_NEW_PAYMENT_METHOD', payload: paymentMethod })
      dispatch({ type: 'SET_ACTIVE_PAYMENT_METHOD', payload: paymentMethod })
      firebase.analytics().logEvent('Add_Payment_Successful')
    },
    [dispatch],
  )

  const setActivePaymentMethod = useCallback(
    paymentMethod => {
      console.debug('[usePaymentMethods] setActivePaymentMethod', paymentMethod)
      dispatch({ type: 'SET_ACTIVE_PAYMENT_METHOD', payload: hydrate(paymentMethod) })
    },
    [dispatch],
  )

  const setSavedPaymentMethods = useCallback(
    paymentMethods => {
      console.debug('[usePaymentMethods] setPaymentMethods', paymentMethods)
      dispatch({ type: 'SET_SAVED_PAYMENT_METHODS', payload: paymentMethods?.map(hydrate) })
    },
    [dispatch],
  )

  useEffect(() => {
    if (!activePaymentMethod) {
      const paymentMethod = hydrate(data?.guestBillingAccounts.find(account => account.isDefault) || data?.guestBillingAccounts?.[0])
      console.debug('[usePaymentMethods] setActivePaymentMethod', paymentMethod)
      setActivePaymentMethod(paymentMethod)
    }
  }, [activePaymentMethod, data?.guestBillingAccounts])

  useEffect(() => {
    if (!loading && !authToken) {
      console.warn('[usePaymentMethods] cleaning "activePaymentMethod" and "savedPaymentMethods" due to missing "authToken"')
      setActivePaymentMethod(null)
      setSavedPaymentMethods(null)
    }
  }, [authToken, loading, setActivePaymentMethod, dispatchMessage])

  useEffect(() => {
    console.debug('[usePaymentMethods]', { data, loading, activePaymentMethod, savedPaymentMethods })
  }, [data, loading, activePaymentMethod, savedPaymentMethods])

  return {
    activePaymentMethod,
    addNewActivePaymentMethod,
    loading,
    savedPaymentMethods,
    setActivePaymentMethod,
  }
}
