import { gql } from '@apollo/client'

import { BILLING_ACCOUNT_FIELDS, GUEST_FIELDS, ORDER_FIELDS } from '../../data/types'

// QUERIES

export const GET_GUEST_RECENT_ORDERS = gql`
  query GetRecentOrders($orderingAuthenticationToken: String!) {
    orders: guestPreviousOrders(orderingAuthenticationToken: $orderingAuthenticationToken) {
      ${ORDER_FIELDS}
    }
  }
`

export const GET_GUEST_BILLING_ACCOUNTS = gql`
  query GetGuestBillingAccounts($orderingAuthenticationToken: String!, $basketId: String) {
    guestBillingAccounts(orderingAuthenticationToken: $orderingAuthenticationToken, basketId: $basketId) {
      ${BILLING_ACCOUNT_FIELDS}
    }
  }
`

export const GET_GUEST_ADDRESSES = gql`
  query GetGuestSavedAddresses($token: String!) {
    guestAddresses(orderingAuthenticationToken: $token) {
      id
      streetAddress
      building
      city
      zipCode
      phoneNumber
      specialInstructions
    }
  }
`

// MUTATIONS

// updateGuestBillingAccount(orderingAuthenticationToken: String!, billingAccount: GuestBillingAccountInput!): Boolean
export const UPDATE_GUEST_BILLING_ACCOUNT = gql`
  mutation UpdateGuestBillingAccount(
    $orderingAuthenticationToken: String!
    $billingAccount: GuestBillingAccountInput!
  ) {
    updateGuestBillingAccount(
      orderingAuthenticationToken: $orderingAuthenticationToken
      billingAccount: $billingAccount
    ) {
        ${BILLING_ACCOUNT_FIELDS}
    }
  }
`
export const UPDATE_GUEST = gql`
  mutation UpdateGuest($authenticationToken: String!, $guest: GuestInput!) {
    updateGuest(authenticationToken: $authenticationToken, guest: $guest){
      ${GUEST_FIELDS}
    }
  }
`

// deleteGuestBillingAccount(orderingAuthenticationToken: String!, billingAccountId: String!): Boolean
export const DELETE_GUEST_BILLING_ACCOUNT = gql`
  mutation DeleteGuestBillingAccount($orderingAuthenticationToken: String!, $billingAccountId: String!) {
    deleteGuestBillingAccount(
      orderingAuthenticationToken: $orderingAuthenticationToken
      billingAccountId: $billingAccountId
    ){
        ${BILLING_ACCOUNT_FIELDS}
    }
  }
`
export const DELETE_GUEST_ADDRESS = gql`
  mutation DeleteGuestAddress($orderingAuthenticationToken: String!, $addressId: String!) {
    deleteGuestAddress(orderingAuthenticationToken: $orderingAuthenticationToken, addressId: $addressId)
  }
`
