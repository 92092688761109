import { FiChevronUp } from 'react-icons/fi'
import styled from 'styled-components'

import { Ingredients } from '../Ingredients/Ingredients'

export const CardContainer = styled.div`
  border: ${({ theme }) => theme.borders.light};
  border-radius: ${({ theme }) => theme.borderRadii.md};
  margin-bottom: 16px;
  padding: 16px;
`

export const Meta = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ItemName = styled.p`
  /* ${({ theme }) => theme.font.styles.description} */
  ${({ theme }) => theme.basket.basketCardItemName}
`

export const ItemCost = styled.p`
  ${({ theme }) => theme.font.styles.labelCurrency}
  margin-top: 0px;
`

export const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 24px;
`

export const QuantityLabel = styled.p``

export const ModifyButton = styled.button`
  background-color: transparent;
  border: none;
  text-decoration: underline;
`

export const IngredientsList = styled(Ingredients)`
  display: flex;
  align-items: center;
`

export const ChevronIcon = styled(FiChevronUp).attrs(({ theme }) => ({
  size: 18,
  color: theme.colors.brand.black,
}))``

export const ChevronIconContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: transparent;
  border: ${({ theme }) => theme.borders.light};
  border-radius: 50%;
  padding: 6px;
`
