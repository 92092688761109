import { gql } from '@apollo/client'

import { IMAGE_FIELDS, MENU_CATEGORY_FIELDS, MENU_ITEM_MODIFIER_GROUP_FIELDS } from '../../data/types'

export const GET_MENU = gql`
  query GetMenu ($restaurantId: Float!) {
    menu(restaurantId: $restaurantId) {
      ${MENU_CATEGORY_FIELDS}
    }
  }
`

export const GET_MENU_CATEGORIES = gql`
  query GetMenuCategories ($restaurantId: Float!) {
    menu(restaurantId: $restaurantId) {
      id,
      name,
      images {
        ${IMAGE_FIELDS}
      }
    }
  }
`

export const GET_MODIFIERS = gql`
  query GetMenuItemModifiers ($menuItemId: Float!){
    modifiers(menuItemId: $menuItemId) {
      ${MENU_ITEM_MODIFIER_GROUP_FIELDS}
    }
  }
`

export const GET_MENU_FILTERS = gql`
  query GetMenuFilters {
    dietaryRestrictions {
      id
      name
      oloCode
      violationExplanation
      type
    }
  }
`
