export const DELIVERY_SUPPORT_FIELDS = `
  canDeliver,
  message,
  timeWantedMode,
  calendar {
    start
    end
    weekday
  }
`

export const CONTEXTUAL_PRICING_FIELDS = `
  isPosValidated,
  isSyndicated
`

export const DELIVERY_FEE_TIERS_FIELDS = `
  feeType,
  amount,
  subtotalMinimum
`

export const CUSTOM_FIELD_FIELDS = `
  id,
  label,
  required,
  validationRegex,
  qualificationCriteria
`

export const LABEL_FIELDS = `
  key,
  value
`

export const METADATA_FIELDS = `
  key,
  value
`

export const RESTAURANT_FIELDS = `
    id,
    name,
    brand,
    storeName,
    telephone,
    streetAddress,
    crossStreet,
    city,
    contextualPricing {
      ${CONTEXTUAL_PRICING_FIELDS}
    }
    state,
    zip,
    country,
    latitude,
    longitude,
    utcOffset,
    url,
    mobileUrl,
    distance,
    extref,
    advanceOnly,
    advanceOrderDays,
    supportsCoupons,
    supportsLoyalty,
    supportedCardTypes,
    supportsManualfire,
    canDeliver,
    canPickup,
    supportsCurbside,
    supportsDispatch,
    hasOloPass,
    deliveryArea,
    minimumDeliveryOrder,
    minimumPickupOrder,
    maximumPayInStoreOrder,
    deliveryFee,
    deliveryFeeTiers {
      ${DELIVERY_FEE_TIERS_FIELDS}
    }
    supportsTip,
    supportsSpecialInstructions,
    specialInstructionsMaxLength,
    supportsGuestOrdering,
    requiresPhoneNumber,
    supportsOnlineOrdering,
    supportsNationalMenu,
    supportsFeedback,
    supportsSplitPayments,
    slug,
    isAvailable,
    isCurrentlyOpen,
    supportsGroupOrders,
    supportsProductRecipientNames,
    supportsBasketTransfers,
    allowHandoffChoiceAtManualFire,
    productRecipientNameLabel,
    customerFacingMessage,
    availabilityMessage,
    supportsDriveThru,
    showCalories,
    acceptsOrdersUntilClosing,
    acceptsOrdersBeforeOpening,
    suggestedTipPercentage,
    customFields {
      ${CUSTOM_FIELD_FIELDS}
    }
    labels {
      ${LABEL_FIELDS}
    }
    metadata {
      ${METADATA_FIELDS}
    }
    supportedTimeModes,
    attributes,
    supportedCountries,
    deliverySupport {
      ${DELIVERY_SUPPORT_FIELDS}
    }
`

export const IMAGE_FIELDS = `
  groupName,
  description,
  isDefault,
  filename,
  url
`

export const MENU_ITEM_AVAILABILITY_FIELDS = `
  always,
  now,
  description,
  startDate,
  endDate
`

export const MENU_ITEM_LABEL_FIELDS = `
  name,
  code,
  images {
    ${IMAGE_FIELDS}
  }
`

export const MENU_ITEM_FIELDS = `
  id,
  chainProductId,
  name,
  description,
  cost,
  baseCalories,
  maxCalories,
  caloriesSeparator,
  displayId,
  startHour,
  endHour,
  imageFilename,
  maximumQuantity,
  minimumQuantity,
  maximumBasketQuantity,
  minimumBasketQuantity,
  quantityIncrement,
  shortDescription,
  metadata {
    ${METADATA_FIELDS}
  }
  menuItemLabels {
    ${MENU_ITEM_LABEL_FIELDS}
  }
  images {
    ${IMAGE_FIELDS}
  }
  availability {
    ${MENU_ITEM_AVAILABILITY_FIELDS}
  }
  unavailableHandoffModes
`

export const MENU_CATEGORY_FIELDS = `
  id,
  name,
  description,
  metadata {
    ${METADATA_FIELDS}
  }
  images {
    ${IMAGE_FIELDS}
  }
  products {
    ${MENU_ITEM_FIELDS}
  }
`

export const MODIFIER_FIELD_FIELDS = `
  id,
  isRequired,
  label,
  maxLength
`

// Leaving out the nested modifiers here
//  modifiers {
//    ${MENU_ITEM_NESTED_MODIFIER_GROUP_FIELDS}
//  }
export const MENU_ITEM_NESTED_MODIFIER_FIELDS = `
  id,
  chainOptionId,
  displayId,
  name,
  isDefault,
  cost,
  children,
  fields {
    ${MODIFIER_FIELD_FIELDS}
  }
  menuItemLabels {
    ${MENU_ITEM_LABEL_FIELDS}
  }
  baseCalories,
  maxCalories,
  caloriesSeparator,
  adjustsParentPrice,
  adjustsParentCalories,
  availability {
    ${MENU_ITEM_AVAILABILITY_FIELDS}
  }
  metadata {
    ${METADATA_FIELDS}
  }
`

export const MENU_ITEM_NESTED_MODIFIER_GROUP_FIELDS = `
  id,
  chainModifierId,
  description,
  mandatory,
  options {
    ${MENU_ITEM_NESTED_MODIFIER_FIELDS}
  },
  parentChoiceId,
  minSelects,
  maxSelects,
  minAggregateQuantity,
  maxAggregateQuantity,
  minChoiceQuantity,
  maxChoiceQuantity,
  supportsChoiceQuantities,
  choiceQuantityIncrement,
  availability {
    ${MENU_ITEM_AVAILABILITY_FIELDS}
  }
  metadata {
    ${METADATA_FIELDS}
  }
  explanationText
`

/*
 * modifiers actually have recursive modifiers.
 * Melt Shop does use nested modifiers though so we need
 * to support at least one level
 */
export const MENU_ITEM_MODIFIER_FIELDS = `
  id,
  chainOptionId,
  displayId,
  name,
  isDefault,
  cost,
  children,
  modifiers {
    ${MENU_ITEM_NESTED_MODIFIER_GROUP_FIELDS}
  }
  fields {
    ${MODIFIER_FIELD_FIELDS}
  }
  menuItemLabels {
    ${MENU_ITEM_LABEL_FIELDS}
  }
  baseCalories,
  maxCalories,
  caloriesSeparator,
  adjustsParentPrice,
  adjustsParentCalories,
  availability {
    ${MENU_ITEM_AVAILABILITY_FIELDS}
  }
  metadata {
    ${METADATA_FIELDS}
  }
`

export const MENU_ITEM_MODIFIER_GROUP_FIELDS = `
  id,
  chainModifierId,
  description,
  mandatory,
  options {
    ${MENU_ITEM_MODIFIER_FIELDS}
  },
  parentChoiceId,
  minSelects,
  maxSelects,
  minAggregateQuantity,
  maxAggregateQuantity,
  minChoiceQuantity,
  maxChoiceQuantity,
  supportsChoiceQuantities,
  choiceQuantityIncrement,
  availability {
    ${MENU_ITEM_AVAILABILITY_FIELDS}
  }
  metadata {
    ${METADATA_FIELDS}
  }
  explanationText
`

export const BASKET_CHOICE_CUSTOM_FIELD_VALUE_FIELDS = `
  fieldId,
  value
`

export const BASKET_CHOICE_FIELDS = `
  id,
  optionId,
  name,
  metric,
  indent,
  cost,
  quantity,
  customFields {
    ${BASKET_CHOICE_CUSTOM_FIELD_VALUE_FIELDS}
  }
`

export const BASKET_PRODUCT_FIELDS = `
  id,
  productId,
  name,
  quantity,
  baseCost,
  totalCost,
  specialInstructions,
  customData,
  recipient,
  choices {
    ${BASKET_CHOICE_FIELDS}
  }
`

export const TAX_RESULT_FIELDS = `
  label,
  tax
`

export const BASKET_COUPON_FIELDS = `
  couponCode,
  description
`

export const DELIVERY_ADDRESS_FIELDS = `
  id,
  building,
  streetAddress,
  city,
  zipCode,
  phoneNumber,
  specialInstructions,
  isDefault,
  latitude,
  longitude
`

export const LOYALTY_REWARD_FIELDS = `
  membershipId,
  reference,
  label,
  type,
  value,
  rewardId,
  applied,
  quantityAvailable,
  quantityApplied,
  description,
  finePrint,
  imageUrl,
  validMinutes,
  availableOnline,
  availableOffline,
  expirationDate,
  externalReference,
  vendorRefs,
  items,
  categories
`

export const DELIVERY_RESTAURANT_FIELDS = `
  ${RESTAURANT_FIELDS}
  deliverySupport {
    canDeliver,
    message,
    timeWantedMode,
    calendar {
      start,
      end,
      weekday
    }
  }
`

export const DELIVERY_SEARCH_RESULTS_FIELDS = `
  restaurants {
    ${DELIVERY_RESTAURANT_FIELDS}
  }
  deliveryAddress {
    ${DELIVERY_ADDRESS_FIELDS}
  }
`

export const BASKET_FEE_FIELDS = `
  amount,
  description,
  note
`

export const BASKET_CUSTOM_FIELD_FIELDS = `
  id,
  label,
  value,
  isRequired,
  validationRegex,
  scope
`

export const VALIDATION_MESSAGE_FIELDS = `
  key,
  message,
  category
`

export const DISCOUNT_FIELDS = `
  description,
  amount
`

export const BASKET_FIELDS = `
  id,
  timeWanted,
  earliestReadytime,
  subtotal,
  salesTax,
  taxes {
    ${TAX_RESULT_FIELDS}
  }
  suggestedTipAmount,
  suggestedTipPercentage,
  discount,
  total,
  tip,
  coupon {
    ${BASKET_COUPON_FIELDS}
  }
  couponDiscount,
  vendorId,
  vendorOnline,
  deliveryMode,
  isUpsellEnabled,
  wasUpSold,
  customerHandoffCharge,
  leadTimeEstimateMinutes,
  timeMode,
  mode,
  deliveryAddress {
    ${DELIVERY_ADDRESS_FIELDS}
  },
  appliedRewards {
    ${LOYALTY_REWARD_FIELDS}
  },
  fees {
    ${BASKET_FEE_FIELDS}
  },
  totalFees,
  contactNumber,
  contextualPricing {
    ${CONTEXTUAL_PRICING_FIELDS}
  },
  customFields {
    ${BASKET_CUSTOM_FIELD_FIELDS}
  },
  validationMessages {
    ${VALIDATION_MESSAGE_FIELDS}
  },
  allowsTip,
  discounts {
    ${DISCOUNT_FIELDS}
  },
  products {
    ${BASKET_PRODUCT_FIELDS}
  }
`

export const UPSELL_ITEM_FIELDS = `
  id,
  name,
  cost,
  shortDescription,
  minQuantity,
  maxQuantity
`

export const UPSELL_GROUP_FIELDS = `
  title,
  items {
    ${UPSELL_ITEM_FIELDS}
  }
`

export const BASKET_VALIDATION_RESULT_FIELDS = `
  basketId,
  contextualPricing {
    ${CONTEXTUAL_PRICING_FIELDS}
  },
  tax,
  taxes {
    ${TAX_RESULT_FIELDS}
  },
  customerHandoffCharge,
  fees {
    ${BASKET_FEE_FIELDS}
  },
  subtotal,
  total,
  readyTime,
  posReferenceResponse,
  totalFees,
  upsellGroups {
    ${UPSELL_GROUP_FIELDS}
  }
`

export const ORDER_STATUS_PRODUCT_FIELDS = `
  name
  quantity
  totalCost
  specialInstructions
  customPassthroughData
  choices {
    name
    quantity
  }
`

export const ORDER_STATUS_FIELDS = `
    id
    oloId
    vendorId
    status
    subtotal
    salesTax
  taxes {
    ${TAX_RESULT_FIELDS}
  },
  fees {
    ${BASKET_FEE_FIELDS}
  },

    totalFees
    total
    timeMode
    tip
    mode
    billingAccountId
    billingAccountIds
    contextualPricing {
      ${CONTEXTUAL_PRICING_FIELDS}
    }
    deliveryAddress {
      ${DELIVERY_ADDRESS_FIELDS}
    }
    customFields {
      ${BASKET_CUSTOM_FIELD_FIELDS}
    }
    posReferenceResponse
    isEditable
    discount
    discounts {
      ${DISCOUNT_FIELDS}
    }
    orderRef
    timePlaced
    readyTime
    vendorName
    vendorExtRef
    deliveryMode
    customerHandoffCharge
    arrivalStatus
    hasOloPass
    trackingToken
    products {
      ${ORDER_STATUS_PRODUCT_FIELDS}
    }
    unavailableProducts {
      ${ORDER_STATUS_PRODUCT_FIELDS}
    }
`

export const ORDER_FIELDS = `
  id,
  timeWanted,
  earliestReadyTime,
  subtotal,
  salesTax,
  taxes {
    ${TAX_RESULT_FIELDS}
  },
  suggestedTipAmount,
  suggestedTipPercentage,
  discount,
  total,
  tip,
  coupon {
    ${BASKET_COUPON_FIELDS}
  },
  couponDiscount,
  vendorId,
  vendorOnline,
  deliveryMode,
  isUpsellEnabled,
  wasUpSold,
  customerHandoffCharge,
  leadTimeEstimateMinutes,
  timeMode,
  mode,
  deliveryAddress {
    ${DELIVERY_ADDRESS_FIELDS}
  },
  appliedRewards {
    ${LOYALTY_REWARD_FIELDS}
  },
  fees {
    ${BASKET_FEE_FIELDS}
  },
  totalFees,
  contactNumber,
  contextualPricing {
    ${CONTEXTUAL_PRICING_FIELDS}
  },
  customFields {
    ${BASKET_CUSTOM_FIELD_FIELDS}
  },
  validationMessages {
    ${VALIDATION_MESSAGE_FIELDS}
  },
  allowsTip,
  discounts {
    ${DISCOUNT_FIELDS}
  },
  products {
    ${BASKET_PRODUCT_FIELDS}
  },
`

export const PROMOTIONAL_ITEM_FIELDS = `
  id
  mainImage {
    id
    url
  }
  headline,
  displayOrder,
  startTime,
  endTime,
  startDate,
  endDate,
  navigateTo,
  navigationPayload
`

export const BILLING_ACCOUNT_FIELDS = `
  accountId,
  accountType,
  cardType,
  cardSuffix,
  description,
  removable,
  expiration,
  isDefault
`

export const BILLING_ACCOUNT_BALANCE_FIELDS = `
  balance,
  success,
  message
`

export const APP_ASSET_FIELDS = `
  id
  createdAt
  publishedAt
  image {
    id
    url
  }
  assetType
`

export const BRAND_FIELDS = `
  id,
  brandName,
  oloCategoryCode,
  displayOrder,
  isDefault,
  foregroundColor { hex },
  backgroundColor { hex },
  image { id, url, }
`

export const APP_PREFERENCES_FIELDS = `
  id,
  type,
  content
`

export const MENU_ITEM_IMAGES_FIELDS = `
  id,
  name,
  image {
    id,
    url
  }
`

export const SPOTLIGHT_FIELDS = `
  id
  heading
  description
`

export const GUEST_FIELDS = `
  id
  authenticationToken
  address
  avatarRemoteUrl
  birthday
  communicableEmail
  city
  createdAt
  email
  emailVerified
  facebookSignup
  favoriteLocations
  favoriteStoreNumbers
  fbUid
  firstName
  gender
  lastName
  marketingEmailSubscription
  marketingPnSubscription
  migrateStatus
  passcodeConfiguredForGiftCards
  phone
  privacyPolicy
  referralCode
  referralPath
  secondaryEmail
  state
  smsSubscription
  superuser
  termsAndConditions
  title
  updatedAt
  userAsBarcode
  userAsQrCode
  userCode
  userId
  zipCode
  anniversary
  emailUnsubscribe
  allowPushNotifications
  accessToken
  orderingAuthenticationToken
  firebaseToken
`

export const GUEST_ACCOUNT_BALANCE_FIELDS = `
    rewards {
      becomesAvailableAt
      campaignType
      createdAt
      description
      discountChannel
      discountAmount
      rewardImageUrl
      name
      readAt
      rewardProperties
      storeNumbers
      franchiseeId
      metaData
      redeemableCreatedAt
      rewardId
      expiringAt
      expiringAtTz
      redemption {
        redemptionId
        redemptionTrackingCode
        createdAt
        expiringAt
        status
      }
    }
    accountBalance {
      bankedCurrency
      bankedCurrencyExpiringOn
      checkinsOnCurrentCard
      currentMembershipLevelId
      currentMembershipLevelExpiringOn
      expiringBankedCurrency
      pendingPoints
      redeemedCards
      totalLoyaltyVisits
      unbankedPoints
      unredeemedCards
      currentMembershipLevelName
      lifetimePoints
      redeemablePoints
    }
`

export const LOYALTY_PROGRAM_FIELDS = `
  androidGaCode
  androidGeocodeApiKey
  beaconUuid
  contentLanguage
  earningDescription
  facebookSharing
  iosGaCode
  iosGeocodeApiKey
  marketingImageUrl
  marketingInfo
  marketingMessage
  marketingTitle
  uberClientId
  promotionalCouponsEnabled
  giftCardsEnabled
  socialCauseCampaignsEnabled
  defaultMapSearchRadius
  facebookSignupIncentiveText
  businessTimezone
  decreaseBadgeCountWhenOfferRead
  currentPasswordPolicy {
    minLength
    minLowerCase
    minUpperCase
    minSpecialChars
    minNumberChars
  }
  emailVerificationEnabled
  giftCardMinimumTransactionAmount
  maxGiftCardBalance
  requirePasscodeForGiftCard
  guestMandatoryFields
  supportEmailAddress
  businessSupportAddress
  faqUrl
  instagramPageUrl
  orderNowUrl
  orderUrlForSso
  menusUrl
  misc1
  misc2
  misc3
  misc4
  cateringUrl
  careersUrl
  privacyUrl
  nutritionUrl
  termsAndConditionsUrl
  facebookPage
  twitterHandle
  gamesRulesUrl
  shareInviteCodeMessage
  shareInviteCodeTitle
  shareInviteCodeDescription
  youtubeUrl
  appDisclaimer
  gameDisclaimer
  upgradeDisclaimer
  challengesDisclaimer
  earningDisclaimer
  oloBaseUrl
  oloApiKeyIos
  oloApiKeyAndroid
  oloProviderKey
  oloGroupOrderInviteUrl
  oloEnvironment
  qkrSigningSecret
  pusherApiKey
  pusherCluster
  qkrEndpoint
  businessId
  businessName
  checkinEvidencedBy
  guestIdentityCodeType
  visitsPerCard
  cardRedemptionValue
  migrationAllowed
  minimumVisitAmount
  minimumAgeToSignup
  minimumVisitHours
  earningUnit
  currencyEarned
  pointsConversionThreshold
  redemptionExpiryMinutes
  allowRatingInFeedback
  maximumRatingInFeedback
  checkinsEarn
  locations
    {
      address
      brand
      city
      country
      externalStoreId
      latitude
      locationId
      locEmail
      longitude
      name
      onlineOrderUrl
      phoneNumber
      postCode
      state
      storeNumber
      storeTags
      storeTimes {
      	day
        startTime
        endTime
      }
      timeZone
      updatedAt
      status
      franchiseeId
      ncrStoreId
      enableDailyRedemptionReport
      enableWeeklyRedemptionReport
      checkinEvidencedBy
      guestIdentityCodeType
    }
  membershipLevels
    {
      backgroundImageUrl
      pointsMultiplier
      membershipLevelId
      name
      description
      maximumPoints
      minimumPoints
      currencyEarned
      pointsConversionThreshold
    }
  baseRedeemable{
    name
    description
    imageUrl
    redeemableProperties
    metaData
  }
`

export const REDEMPTION_STATUS_FIELDS = `
  basket {
    ${BASKET_FIELDS}
  }
`
export const WEB_ASSET_FIELDS = `
  id
  createdAt
  publishedAt
  image {
    id
    url
  }
  assetType
`

export const WEB_PREFERENCES_FIELDS = `
  id,
  type,
  content
`

export const FEATURE_FLAGS_FIELDS = `
  type
  enabled
`
