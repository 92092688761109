import styled from 'styled-components'

export const OrderTrackingProgressBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => theme.orderTracking.progressBar.container.style};
`

export const OrderTrackingProgressBarFillContainer = styled.div`
  flex: 1;
  height: 3px;
  margin: 0px -3px;

  background-color: ${({ theme, active }) =>
    active ? theme.orderTracking.progressBar.colors.active : theme.orderTracking.progressBar.colors.inactive};

  ${({ theme }) => theme.orderTracking.progressBar.fillContainer.style};
`

export const OrderTrackingProgressBarIconContainer = styled.div`
  display: flex;
  width: 24px;
  height: 24px;

  z-index: 1;

  border-radius: 12px;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme, active }) =>
    active ? theme.orderTracking.progressBar.colors.active : theme.orderTracking.progressBar.colors.inactive};

  ${({ theme }) => theme.orderTracking.progressBar.iconContainer.style};
`
