import React, { useEffect, useMemo } from 'react'
import { useResizeDetector } from 'react-resize-detector'
import { useLocation } from 'react-router-dom'
import { useDialogState } from 'reakit'

import { PREFERENCES } from '../../constants/preferences'
import { useText } from '../../content'
import { useConfig } from '../../contexts/appConfig'
import { useSession } from '../../contexts/session'
import { useAsset } from '../../hooks/useAsset'
import { useBasket } from '../../hooks/useBasket'
import { useFeatureFlags } from '../../hooks/useFeatureFlags'
import { useStoreHours } from '../../hooks/useStoreHours'
import { BasketPage } from '../Basket/Basket'
import { ChangeAddressChip } from '../ChangeAddressChip/ChangeAddressChip'
import { DialogWithBackdrop } from '../DialogWithBackdrop/DialogWithBackdrop'
import { AccountIcon, MyBagIcon, PrefsIcon } from '../Icons/Icons'
import { useResponsiveness } from '../responsive/Responsive'
import { StoreHoursBanner } from '../StoreHoursBanner/StoreHoursBanner'
import { StoreHoursPill } from '../StoreHoursPill/StoreHoursPill'
import { LabelSpecial } from '../Text/Text.styles'
import {
  BagItemsCount,
  BrandContainer,
  BrandLink,
  BrandLogo,
  ChipAndPillContainer,
  DietaryLink,
  HeaderContainer,
  HeaderSpacer,
  HeaderTopContainer,
  LeftContainer,
  MyBagButton,
  SignInLink,
} from './Header.styles'

export const Header = ({ children, style, onResize = () => null }) => {
  const { brandName } = useConfig()?.getPreference(PREFERENCES.GENERAL) || {}
  const { isMobile } = useResponsiveness()
  const { state } = useSession()
  const { text } = useText()
  const { totalProductQuantity } = useBasket()
  const location = useLocation()
  const { DisplayStoreHours } = useFeatureFlags()
  const { statusText } = useStoreHours({ enabled: DisplayStoreHours })

  const targetRef = React.createRef()

  const { width, height, ref } = useResizeDetector({
    skipOnMount: false,
    refreshMode: 'debounce',
    refreshRate: '150',
    targetRef,
  })

  useEffect(() => {
    console.debug('[Header] onResize', { width, height, ref })
    onResize(width, height)
  }, [width, height])

  const logoAsset = useAsset('header', 'logoAsset')
  const myBagDialogProps = useDialogState({ animated: true })
  const showSelectedAddress = useMemo(() => !!state?.handoffMode && !!state?.restaurant, [state?.handoffMode, state?.restaurant])

  const showHoursBanner = useMemo(() => DisplayStoreHours && isMobile && location.pathname.startsWith('/browse-menu') && statusText, [
    DisplayStoreHours,
    isMobile,
    location,
    statusText,
  ])

  const showHoursPill = useMemo(() => DisplayStoreHours && !isMobile && location.pathname.startsWith('/browse-menu') && statusText, [
    DisplayStoreHours,
    isMobile,
    location,
    statusText,
  ])

  useEffect(() => {
    console.debug('[Header]', { showHoursBanner, showHoursPill })
  }, [showHoursBanner, showHoursPill])

  return (
    <>
      <HeaderContainer ref={targetRef} style={style}>
        <HeaderTopContainer>
          <LeftContainer style={showHoursPill ? { marginBottom: '24px' } : null}>
            <BrandContainer as="div" style={{ display: !isMobile || (isMobile && !showSelectedAddress) ? 'flex' : 'none' }}>
              <BrandLink to="/">
                <BrandLogo alt={brandName} src={logoAsset?.url} />
              </BrandLink>
            </BrandContainer>
            <ChipAndPillContainer>
              {showSelectedAddress && <ChangeAddressChip />}
              {showHoursPill && <StoreHoursPill statusText={statusText} />}
            </ChipAndPillContainer>
          </LeftContainer>

          {state?.user && (
            <DietaryLink to="/profile/dietary" style={{ whiteSpace: 'nowrap' }}>
              <PrefsIcon />
              <LabelSpecial>{text('Header.Nutritional.Preferences')}</LabelSpecial>
            </DietaryLink>
          )}

          <SignInLink to={state?.user ? '/profile' : '/login'}>
            <AccountIcon />
            <LabelSpecial>{state?.user ? 'Account' : 'Sign in / Join'}</LabelSpecial>
          </SignInLink>

          <MyBagButton onClick={myBagDialogProps.show}>
            <MyBagIcon />
            <LabelSpecial style={{ whiteSpace: 'nowrap' }}>My bag</LabelSpecial>
            {totalProductQuantity > 0 && <BagItemsCount>{totalProductQuantity}</BagItemsCount>}
          </MyBagButton>
        </HeaderTopContainer>

        {showHoursBanner && <StoreHoursBanner statusText={statusText} />}
        {children}
      </HeaderContainer>

      <HeaderSpacer style={{ minHeight: height }} />

      <DialogWithBackdrop dialogProps={myBagDialogProps} ariaLabel="My Bag">
        <BasketPage onClose={myBagDialogProps.hide} />
      </DialogWithBackdrop>
    </>
  )
}
