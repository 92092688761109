import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTheme } from 'styled-components'

import { DoubleCTA } from '../../components/DoubleCTA/DoubleCTA'
import { Description } from '../../components/Text/Text.styles'
import { useText } from '../../content'
import { useSession } from '../../contexts/session'
import firebase from '../../services/firebase'
import { FgcRewardsContent } from './fgc/FgcRewards'
import { LittleBeetRewardsContent } from './littlebeet/LittleBeetRewards'
import { LpqRewardsContent } from './lpq/LpqRewards'
import { MeltShopRewardsContent } from './meltshop/MeltShopRewards'
import { ContentContainer, HeaderContainer } from './Profile.styles'
import { CenterContainer } from './Rewards.styles'

const RewardsContentConfig = {
  fgc: FgcRewardsContent,
  meltshop: MeltShopRewardsContent,
  littlebeet: LittleBeetRewardsContent,
  lpq: LpqRewardsContent,
}

export const RewardsContent = () => {
  const theme = useTheme()
  const { state: sessionState } = useSession()

  useEffect(() => {
    firebase.analytics().logEvent('Profile_Rewards_Selected')
  }, [])

  if (!sessionState.user) {
    // For Fgc this will never occur
    return <LoggedOutRewardsInfo />
  }

  const RewardScreen = RewardsContentConfig[theme.brand] || React.Fragment
  return <RewardScreen />
}

const LoggedOutRewardsInfo = () => {
  const history = useHistory()
  const { text } = useText()
  const theme = useTheme()

  const handleSignIn = () => history.push('/migration/start')
  const handleCreateAccount = () => history.push('/register')

  return (
    <>
      <HeaderContainer>{text('Profile.Rewards.LoggedOut.Title', { brand: text('Brand.Name') })}</HeaderContainer>

      <ContentContainer>
        <Description style={{ marginTop: 32, marginBottom: 48 }}>
          {text('Profile.Rewards.LoggedOut.Message', { brand: text('Brand.Name') })}
        </Description>

        <CenterContainer>
          <DoubleCTA
            stacked
            buttons={[
              {
                label: 'Sign In',
                onClick: handleSignIn,
                variant: 'primary',
              },
              {
                label: 'Create Account',
                onClick: handleCreateAccount,
                variant: 'handleCreateAccount',
              },
            ]}
          />
        </CenterContainer>
      </ContentContainer>
    </>
  )
}
