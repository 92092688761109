import debounce from 'lodash.debounce'
import { useCallback, useLayoutEffect } from 'react'

export const useEventListener = ({ element, eventName, callback, debounceDelay = 0 }) => {
  const debouncedEventListener = useCallback(debounce(callback, debounceDelay), [callback])

  useLayoutEffect(() => {
    if (!element) return
    element.addEventListener(eventName, debouncedEventListener)
    return () => element.removeEventListener(eventName, debouncedEventListener)
  }, [debouncedEventListener, element, eventName])
}
