import { makeStyles } from '@material-ui/core'

export const useMapStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    overflow: 'hidden',
  },
  mapContainerStyle: {
    minHeight: '100%',
    minWidth: '100%',
  },
  searchContainer: {
    flexGrow: 1,
    backgroundColor: '#fff',
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}))
