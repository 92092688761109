import './meltshop.css'

import Color from 'color'
import { merge } from 'lodash'
import { css } from 'styled-components'

import { isDesktop, isLargeDesktop, isMobile, isTablet } from '../components/responsive/Responsive'
import { theme as defaultTheme } from './default'

const brandFonts = {
  Melted: 'MeltedDIN',
  DINCondensed: 'MeltedDIN',
  Futwora: 'Futwora-Regular',
}

const images = {
  rewardsIcon: require('../assets/images/meltshop/ms-rewards-icon.png'),
}

const icons = {
  accountWhiteIcon: require('../assets/icons/meltshop/account.svg'),
  mapMarkerIcon: require('../assets/icons/meltshop/map_marker.svg'),
  myBagWhiteIcon: require('../assets/icons/meltshop/my_bag.svg'),
  settings: require('../assets/icons/meltshop/settings.svg'),
  orderTracking: {
    courier: {
      default: require('../assets/icons/meltshop/ot-courier-default.svg'),
      relay: require('../assets/icons/meltshop/ot-courier-relay.svg'),
    },
    destination: require('../assets/icons/meltshop/ot-dest.svg'),
    source: require('../assets/icons/meltshop/ot-src.svg'),
  },
  size: {
    sm: 16,
    md: 24,
  },
}

const black = Color('#090C0C') // "Almost black"
const gray = Color('#A2A2A2')
const orange = Color('#BF411E')
const silver = Color('#C1C7D0')
const white = Color('#FFFFFF')
const yellow = Color('#FDB73E')
const pink = '#F87BAE'

const brandColors = {
  black,
  gray10: Color('#F4F3F1'),
  gray20: Color('#DFDEDE'),
  gray30: Color('#D0D0D0'),
  gray40: Color('#B8B8B8'),
  gray50: gray,
  gray60: Color('#808080'),
  gray70: Color('#565656'),
  gray80: Color('#363535'),
  gray90: Color('#2B2929'),
  orange10: Color('#FFB7A3'),
  orange20: Color('#FF9B7E'),
  orange30: Color('#F27C5B'),
  orange40: Color('#DD5C38'),
  orange50: orange,
  orange60: Color('#AE300C'),
  orange70: Color('#9B2301'),
  orange80: Color('#7E1E02'),
  silver,
  white,
  yellow10: Color('#FFF5E4'),
  yellow20: Color('#FFEDCE'),
  yellow30: Color('#FFDFA9'),
  yellow40: Color('#FED081'),
  yellow50: yellow,
  yellow60: Color('#F0A526'),
  yellow70: Color('#D78E13'),
  yellow80: Color('#9D6404'),
  pink10: Color(pink).lighten(0.4),
  pink20: Color(pink).lighten(0.3),
  pink30: Color('#f6a7c7'),
  pink40: Color(pink).lighten(0.1),
  pink50: Color(pink),
  pink60: Color(pink).darken(0.1),
  pink70: Color(pink).darken(0.2),
  pink80: Color(pink).darken(0.3),
  pink90: Color(pink).darken(0.4),
}

const primary = brandColors.yellow50
const secondary = brandColors.white

const brand = 'meltshop'

const colors = {
  brand: brandColors,
  white,
  black: Color('#000000'),
  primary,
  secondary,
  backgroundRequiredInput: brandColors.gray10,
}

const mapOverlay = {
  activeMarker: {
    fillColor: primary.hex(),
    fillOpacity: 1,
    scale: 1.75,
    path:
      'M5.8752 16C5.71718 15.9251 5.58472 15.8042 5.49467 15.6528C4.02783 13.5488 2.54994 11.4528 1.09099 9.34241C0.535343 8.5325 0.198899 7.58916 0.115198 6.60642C-0.131118 4.04642 1.23467 1.58403 3.55099 0.568027C5.79941 -0.419171 7.92309 -0.118372 9.82415 1.46083C10.3974 1.93309 10.873 2.51541 11.2236 3.17438C11.5742 3.83334 11.793 4.55598 11.8673 5.30082C12.0563 6.79713 11.6695 8.30973 10.7873 9.52481C9.3673 11.5515 7.94625 13.5819 6.52415 15.616C6.4317 15.7826 6.29121 15.9166 6.12151 16H5.8752ZM7.9673 6.02242C7.96881 5.62859 7.85535 5.24311 7.64121 4.91446C7.42706 4.58581 7.12179 4.32867 6.7638 4.17538C6.4058 4.02209 6.01107 3.97949 5.62925 4.05295C5.24742 4.1264 4.89556 4.31263 4.61792 4.58821C4.34027 4.86379 4.14925 5.21642 4.06887 5.60173C3.98849 5.98704 4.02235 6.38783 4.16618 6.7537C4.31002 7.11956 4.5574 7.43415 4.87723 7.65791C5.19705 7.88166 5.57503 8.00458 5.96362 8.01121C6.22417 8.01438 6.48277 7.96541 6.7246 7.86709C6.96643 7.76878 7.18674 7.62307 7.37288 7.43831C7.55903 7.25354 7.70736 7.03337 7.80936 6.7904C7.91136 6.54743 7.96504 6.28645 7.9673 6.02242Z',
  },
  inactiveMarker: {
    fillColor: brandColors.black.hex(),
    fillOpacity: 1,
    scale: 1.75,
    path:
      'M5.8752 16C5.71718 15.9251 5.58472 15.8042 5.49467 15.6528C4.02783 13.5488 2.54994 11.4528 1.09099 9.34241C0.535343 8.5325 0.198899 7.58916 0.115198 6.60642C-0.131118 4.04642 1.23467 1.58403 3.55099 0.568027C5.79941 -0.419171 7.92309 -0.118372 9.82415 1.46083C10.3974 1.93309 10.873 2.51541 11.2236 3.17438C11.5742 3.83334 11.793 4.55598 11.8673 5.30082C12.0563 6.79713 11.6695 8.30973 10.7873 9.52481C9.3673 11.5515 7.94625 13.5819 6.52415 15.616C6.4317 15.7826 6.29121 15.9166 6.12151 16H5.8752ZM7.9673 6.02242C7.96881 5.62859 7.85535 5.24311 7.64121 4.91446C7.42706 4.58581 7.12179 4.32867 6.7638 4.17538C6.4058 4.02209 6.01107 3.97949 5.62925 4.05295C5.24742 4.1264 4.89556 4.31263 4.61792 4.58821C4.34027 4.86379 4.14925 5.21642 4.06887 5.60173C3.98849 5.98704 4.02235 6.38783 4.16618 6.7537C4.31002 7.11956 4.5574 7.43415 4.87723 7.65791C5.19705 7.88166 5.57503 8.00458 5.96362 8.01121C6.22417 8.01438 6.48277 7.96541 6.7246 7.86709C6.96643 7.76878 7.18674 7.62307 7.37288 7.43831C7.55903 7.25354 7.70736 7.03337 7.80936 6.7904C7.91136 6.54743 7.96504 6.28645 7.9673 6.02242Z',
  },
}

const borderRadii = {
  sm: '3px',
}

const borderSizes = {
  xs: '1px',
  sm: '1.5px',
  md: '3px',
}

const borders = {
  light: `${borderSizes.xs} solid ${brandColors.gray30.hex()}`,
  lighter: `${borderSizes.xs} solid ${brandColors.gray10.hex()}`,
  lightHighlight: `${borderSizes.sm} solid ${brandColors.black.hex()}`,
  normal: `${borderSizes.sm} solid ${brandColors.black.hex()}`,
}

const fontSizes = {
  xxs: '12px',
  xs: '14px',
  sm: '16px',
  md: '18px',
  lg: '20px',
  xl: '24px',
  xxl: '36px',
}

const font = {
  styles: {
    regular: brandFonts.Futwora,
    bold: brandFonts.Melted,
    numbers: brandFonts.DINCondensed,
    h1: css`
      font-family: ${brandFonts.Melted};
      font-size: ${fontSizes.xxl};
      font-weight: normal;
      letter-spacing: 0.03em;
      line-height: 42px;
    `,
    h2: css`
      font-family: ${brandFonts.Melted};
      font-size: ${fontSizes.xl};
      font-weight: normal;
      letter-spacing: 0.02em;
    `,
    h3: css`
      font-family: ${brandFonts.Melted};
      font-size: ${fontSizes.lg};
      font-weight: normal;
    `,
    description: css`
      font-family: ${brandFonts.Futwora};
      font-size: ${fontSizes.sm};
      font-weight: 500;
      line-height: 140%;
    `,
    descriptionSmall: css`
      font-family: ${brandFonts.Futwora};
      font-size: ${fontSizes.xs};
      font-weight: 500;
      line-height: 140%;
      letter-spacing: 0.02em;
    `,
    descriptionExtraSmall: css`
      font-family: ${brandFonts.Futwora};
      font-size: ${fontSizes.xxs};
      font-weight: 500;
      line-height: 143%;
      letter-spacing: 0.02em;
    `,
    descriptionLarge: css`
      font-family: ${brandFonts.Futwora};
      font-size: ${fontSizes.lg};
      font-weight: 500;
      line-height: 143%;
      letter-spacing: 0.02em;
    `,
    cta: css`
      font-family: ${brandFonts.Melted};
      font-size: ${fontSizes.lg};
      font-weight: normal;
      letter-spacing: 0.03em;
      line-height: 120%;
      text-transform: uppercase;
    `,
    ctaSmall: css`
      font-family: ${brandFonts.Melted};
      font-size: ${fontSizes.xs};
      font-weight: normal;
      letter-spacing: 0.06em;
      line-height: 120%;
      text-transform: uppercase;
    `,
    label: css`
      font-family: ${brandFonts.Melted};
      font-size: ${fontSizes.lg};
      font-weight: normal;
      letter-spacing: 0.06em;
      line-height: 120%;
    `,
    labelInputBold: css`
      font-family: ${brandFonts.Melted};
      font-size: ${fontSizes.lg};
      font-weight: 600;
      letter-spacing: 0.02em;
      line-height: 150%;
    `,
    labelSpecial: css`
      font-family: ${brandFonts.Melted};
      font-size: 16px;
      font-weight: normal;
      line-height: 19px;
      letter-spacing: 0.03em;
    `,
    labelExtraSmall: css`
      font-family: ${brandFonts.Melted};
      font-size: ${fontSizes.xs};
      font-weight: 800;
      letter-spacing: 0.08em;
      line-height: 114%;
      text-transform: uppercase;
    `,
    labelSmall: css`
      font-family: ${brandFonts.Melted};
      font-size: ${fontSizes.sm};
      font-weight: normal;
      line-height: 140%;
      text-transform: uppercase;
    `,
    labelMedium: css`
      font-family: ${brandFonts.Melted};
      font-size: ${fontSizes.md};
      font-weight: normal;
      letter-spacing: 0.03em;
      line-height: 19px;
      text-transform: uppercase;
    `,
    inputDefault: css`
      font-family: ${brandFonts.Futwora};
      font-size: ${fontSizes.xs};
      font-weight: normal;
      letter-spacing: 0.08em;
      line-height: 120%;
      text-transform: uppercase;
    `,
    inputExtraSmall: css`
      font-family: ${brandFonts.Futwora};
      font-size: ${fontSizes.xxs};
      font-weight: normal;
      letter-spacing: 0.08em;
      line-height: 120%;
      text-transform: uppercase;
    `,
    labelInput: css`
      font-family: ${brandFonts.Futwora};
      font-size: ${fontSizes.sm};
      font-weight: normal;
    `,
    labelInputSmall: css`
      font-family: ${brandFonts.Futwora};
      font-size: ${fontSizes.xs};
      font-weight: normal;
      line-height: 120%;
      text-transform: uppercase;
    `,
    labelInputExtraSmall: css`
      font-family: ${brandFonts.Futwora};
      font-size: ${fontSizes.xxs};
      font-weight: normal;
      line-height: 140%;
    `,
    labelInputError: css`
      color: ${brandColors.orange50};
      font-family: ${brandFonts.Futwora};
      font-size: ${fontSizes.xxs};
      font-weight: normal;
    `,
    labelCurrency: css`
      font-family: ${brandFonts.DINCondensed};
      font-size: ${fontSizes.sm};
      font-weight: normal;
      font-weight: 700;
    `,
    labelCurrencySmall: css`
      color: ${brandColors.gray70};
      font-family: ${brandFonts.Futwora};
      font-weight: normal;
      letter-spacing: 0.08em;
      text-transform: uppercase;
    `,
    labelCurrencyLarge: css`
      font-family: ${brandFonts.DINCondensed};
      font-size: ${fontSizes.lg};
      font-weight: normal;
      font-weight: 800;
    `,
  },
  sizes: fontSizes,
}

const buttons = {
  buttonContainer: {
    primary: css`
      background-color: ${primary.hex()};
      border: ${borderSizes.xs} solid ${primary.hex()};
      border-radius: ${borderRadii.sm};
      color: ${brandColors.black.hex()};
      &:hover {
        background-color: ${brandColors.yellow60.hex()};
        border-color: ${brandColors.yellow60.hex()};
      }
    `,
    secondary: css`
      background-color: ${secondary.hex()};
      border: ${borderSizes.xs} solid ${brandColors.black.hex()};
      border-radius: ${borderRadii.sm};
      color: ${brandColors.black.hex()};
      &:hover {
        background-color: ${brandColors.yellow60.hex()};
        border-color: ${brandColors.yellow60.hex()};
      }
    `,
  },
  roundContainer: {
    primary: css`
      background-color: ${brandColors.black.hex()};
      border: ${borderSizes.xs} solid ${brandColors.gray30.hex()};
      color: ${brandColors.white.hex()};
      &:hover {
        border-color: ${primary.hex()};
        color: ${primary.hex()};
      }
    `,
    secondary: css`
      background-color: ${white.hex()};
      border: ${borderSizes.xs} solid ${brandColors.black.hex()};
      color: ${brandColors.black.hex()};
      &:hover {
        background-color: ${brandColors.black.hex()};
        border-color: ${brandColors.black.hex()};
        color: ${brandColors.white.hex()};
      }
    `,
  },
  small: css`
    ${font.styles.ctaSmall};
    padding: 10px 16px;
  `,
  modalHeaderCloseButtonVariant: 'primary',
}

const changeAddressChip = {
  backButtonVariant: 'primary',
  outerContainerStyle: css`
    height: 100%;
    &:before {
      content: '';
      border: ${props => `1px solid  ${props.theme.colors.brand.gray30.hex()}`};
      height: 100%;
      margin: 0 26px;
    }
  `,
  innerContainerStyle: css`
    background: ${primary.hex()};
    border-radius: 100px;
    color: ${brandColors.black.hex()};
    font-family: ${font.styles.bold};
    font-size: ${font.sizes.sm};
    letter-spacing: 0.06em;
    line-height: ${font.sizes.lg};
    &:hover {
      background-color: ${brandColors.yellow60.hex()};
      color: ${brandColors.white.hex()};
    }
  `,
}

const header = {
  styles: {
    backgroundColor: brandColors.black,
    linkColor: brandColors.white,
  },
}

const home = {
  heroAsset: 'HomeHero',
  sectionHeaderBackground: brandColors.gray10.hex(),
  styles: {
    headerText: css`
      ${font.styles.description}
      color: ${brandColors.black}
    `,
    sectionH1: css`
      ${isMobile`
        font-size: ${fontSizes.xl};
        line-height: 28px;
      `}
      ${isTablet`
        font-size: ${fontSizes.xxl};
        line-height: 42px;
      `}
      ${isDesktop`
        font-size: ${fontSizes.xxl};
        line-height: 42px;
      `}
      ${isLargeDesktop`
        font-size: ${fontSizes.xxl};
        line-height: 42px;
      `}
    `,
    sectionHeaderContainer: css`
      ${isMobile`
        padding: 24px 16px;
      `}
      ${isTablet`
        padding: 28px 24px;
      `}
      ${isDesktop`
        padding: 48px;
      `}
      ${isLargeDesktop`
        padding: 48px;
      `}
    `,
    sectionBody: css`
      ${isMobile`
        padding: 36px 16px;
      `}
      ${isTablet`
        padding: 36px 24px;
      `}
      ${isDesktop`
        padding: 48px;
      `}
      ${isLargeDesktop`
        padding: 48px;
      `}
    `,
    viewLocationsButton: css`
      font-family: ${brandFonts.Futwora};
      font-size: ${fontSizes.xxs};
      font-weight: normal;
      line-height: 120%;
      text-decoration: underline;
      text-transform: uppercase;
    `,
  },
}

const boxShadow = `-2px 0px 6px 4px ${colors.black.alpha(0.06)}`

const toggles = {
  active: {
    border: `${borderSizes.xs} solid ${brandColors.black.hex()}`,
    backgroundColor: brandColors.black.hex(),
    textColor: brandColors.white.hex(),
  },
  inactive: {
    border: `${borderSizes.xs} solid ${brandColors.gray30.hex()}`,
    backgroundColor: brandColors.white.hex(),
    textColor: brandColors.black.hex(),
  },
  styles: {
    active: css`
      background-color: ${brandColors.black.hex()};
      color: ${brandColors.white.hex()};
      border: ${borders.normal};
      box-shadow: ${boxShadow};
    `,
    inactive: css`
      background-color: ${brandColors.white.hex()};
      color: ${brandColors.black.hex()};
      border: ${borders.light};
      box-shadow: ${boxShadow};
    `,
  },
}

const items = {
  selected: {
    background: brandColors.gray10.hex(),
  },
  highlighted: {
    background: brandColors.gray20.hex(),
  },
  hover: {
    background: brandColors.gray20.hex(),
  },
}

const toast = css`
  .Toastify__toast-body {
    display: flex;
    justify-content: center;

    font-family: ${brandFonts.Futwora};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
  }

  .Toastify__close-button {
    display: flex;
    margin: auto 0;
  }

  .Toastify__toast--info {
    background: ${brandColors.yellow50};
    color: ${brandColors.black};
  }
  .Toastify__toast--info > .Toastify__close-button {
    color: ${brandColors.black};
  }

  .Toastify__toast--error {
    background: ${brandColors.orange50};
    color: ${brandColors.white};
  }
  .Toastify__toast--error > .Toastify__close-button {
    color: ${brandColors.white};
  }
`

const baseCheckoutFrameStyleCSS = `
  border-bottom-style: none;
  border-left-style: none;
  border-radius: unset;
  border-right-style: none;
  border-top-style: none;
  padding: 24px 16px;
  font-size: 17px;
  font-weight: normal;
  width: 100%;
`

const checkoutFrame = {
  styles: {
    cardNumber: baseCheckoutFrameStyleCSS,
    cvv: baseCheckoutFrameStyleCSS,
  },
}

const orderTracking = {
  closeButtonVariant: 'secondary',
  foregroundImage: {
    style: `
    `,
  },
  header: {
    container: {
      style: `
        background-color: ${brandColors.black};
        color: ${brandColors.white};
        padding: 32px 16px;
        order: -1;
      `,
    },
    title: {
      style: `
        font-size: 26px;
        margin-bottom: 8px;
      `,
    },
    text: {
      style: `
        font-size: ${fontSizes.xs};
        font-style: normal;
        font-weight: normal;
        line-height: 140%;
        letter-spacing: -0.02em;
      `,
    },
  },
  section: {
    style: `
    `,
    body: {
      style: `
      `,
    },
    title: {
      style: `
        font-size: 26px;
        margin-bottom: 8px;
      `,
    },
  },
  aside: {
    style: `
      background-color: #efefef8c;
    `,
  },
  map: {
    container: {
      style: `
        padding: 0;
      `,
    },
  },
  summary: {
    container: {
      style: `
        border: none;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
        hr {
          border-top: 1px solid #e8e8e8;
        }
      `,
    },
  },
  progressBar: {
    colors: {
      active: primary,
      inactive: '#C4C4C4',
    },
    container: {
      style: `
        margin-bottom: 16px;
      `,
    },
    fillContainer: {
      style: `
      `,
    },
    iconContainer: {
      style: `
      `,
    },
  },
}

export const theme = merge({}, defaultTheme, {
  borderRadii,
  borders,
  borderSizes,
  brand,
  buttons,
  changeAddressChip,
  checkoutFrame,
  colors,
  font,
  header,
  home,
  icons,
  items,
  mapOverlay,
  toast,
  toggles,
  currency: {
    left: {
      fontFamily: brandFonts.DINCondensed,
      fontSize: '16px',
    },
    right: {
      fontFamily: brandFonts.DINCondensed,
      fontSize: '16px',
    },
  },
  profile: {
    header: css`
      color: ${black};
      background-color: transparent;
    `,
    navBar: {
      container: css`
        border: none;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
      `,
      navItem: css`
        color: ${brandColors.gray60.hex()};
        text-transform: unset;
      `,
      selectedItemTextDecoration: 'underline',
      selectedItemTextColor: brandColors.black,
      selectedItemBackgroundColor: 'transparent',
    },
    topTextColor: Color(white),
    topBackgroundColor: Color(black),
  },
  accountCreated: {
    messageFontStyle: css`
      font-family: ${brandFonts.Melted};
      font-size: 28px;
    `,
  },
  categoryLanding: {
    bodyPadding: '0px',
    itemMargin: '10px',
    displayMeta: true,
    displayTriangles: true,
    imageWidth: '55%',
    imagePosition: 'absolute',
    backgroundImageAsset: 'CategoryLandingBackgroundImage',
  },
  menuContext: {
    backgroundColor: Color('#212224'),
    iconBackgroundColor: Color('transparent'),
    iconColor: Color(white),
    iconBorderColor: Color(white),
    categoryBar: {
      backgroundColor: Color(black),
      active: {
        border: 'none',
        background: 'transparent',
        textColor: Color(white),
        fontFamily: brandFonts.Melted,
      },
      inactive: {
        border: 'none',
        background: 'transparent',
        textColor: Color(white).alpha(0.5),
        fontFamily: brandFonts.Melted,
      },
    },
  },
  handoffSelector: {
    backgroundColor: Color(black),
    color: Color(white),
    border: 'none',
    fontFamily: brandFonts.Melted,
    secondary: {
      backgroundColor: brandColors.orange50,
      color: Color(black),
      border: 'none',
      fontFamily: brandFonts.Melted,
    },
  },
  menuCategoryBar: css`
    background: ${brandColors.black};
  `,
  menuCategoryBarButton: css`
    color: ${brandColors.gray40};
    &:hover {
      color: ${brandColors.white};
    }
  `,
  menuCategoryBarButtonSelected: css`
    color: ${brandColors.white};
    text-decoration: underline;
  `,
  menuItems: {
    controlContainerStyle: css``,
    totalMetaStyle: css`
      font-family: ${brandFonts.DINCondensed};
      font-size: ${fontSizes.lg};
      font-weight: bold;
      line-height: 120%;
      letter-spacing: 0.06em;
    `,
    totalTextStyle: css`
      font-family: ${brandFonts.Futwora};
      font-size: ${fontSizes.sm};
      font-weight: 600;
      line-height: 120%;
    `,
    simpleBuilder: {
      modifierTextColor: primary.hex(),
      infoContainerStyle: css`
        background-color: ${brandColors.gray10.hex()};
        color: ${brandColors.black.hex()};
        /* Desktop, Large Desktop and Tablet */
        padding: 24px 32px;
        ${isMobile`
          padding: 24px 16px;
        `}
      `,
    },
    cards: {
      style: css`
        background: ${props => props.theme.colors.brand.white};
        border-radius: 3px;
        border: ${props => `1px solid ${props.theme.colors.brand.gray20}`};
        box-shadow: ${props => `0px 2px 8px ${props.theme.colors.black.alpha(0.05)}`};
        &:hover {
          border-color: ${props => props.theme.colors.primary.hex()};
          box-shadow: ${props => `2px 2px 15px ${props.theme.colors.black.alpha(0.2)}`};
        }
      `,
      descriptionHeaderStyle: css`
        color: ${brandColors.black.hex()};
        font-family: ${brandFonts.Melted};
        font-weight: normal;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        ${isLargeDesktop`
          font-size: 32px;
          line-height: 38px;
        `};
        ${isDesktop`
          font-size: 32px;
          line-height: 38px;
        `};
        ${isTablet`
          font-size: 24px;
          line-height: 28px;
        `};
        ${isMobile`
          font-size: 26px;
          line-height: 32px;
        `};
      `,
      descriptionTextStyle: css`
        font-family: ${brandFonts.Futwora};
        font-weight: normal;
        font-size: 14px;
        line-height: 130%;
        color: ${brandColors.gray70.hex()};
      `,
      imageHeight: '220px',
      imageResizeMode: 'contain',
      menuHeaderStyle: css`
        color: ${brandColors.black.hex()};
        font-family: ${brandFonts.Melted};
        font-weight: normal;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        ${isLargeDesktop`
          font-size: 36px;
          line-height: 42px;
        `};
        ${isDesktop`
          font-size: 36px;
          line-height: 42px;
        `};
        ${isTablet`
          font-size: 24px;
          line-height: 28px;
        `};
        ${isMobile`
          font-size: 34px;
          line-height: 40px;
        `};
      `,
      numberTextStyle: css`
        color: ${brandColors.black.hex()};
        font-family: ${brandFonts.DINCondensed};
        font-size: ${fontSizes.sm};
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0.06em;
        line-height: 150%;
      `,
    },
    categoryHeading: {
      fontFamily: brandFonts.Melted,
      fontSize: '34px',
      borderBottomWidth: '0px',
      borderBottomColor: Color('transparent'),
      padding: '39px 0px 28px 0px',
      textAlign: 'center',
      textColor: Color(black),
    },
  },
  basket: {
    header: css`
      background-color: ${brandColors.black};
      border-bottom: 2px solid ${brandColors.black};
    `,
    topBackgroundColor: brandColors.black,
    topTextColor: brandColors.white,
    backButtonVariant: 'primary',
    bagItemsCount: css`
      font-family: ${brandFonts.DINCondensed};
      font-size: 8px;
      font-weight: normal;
      letter-spacing: 0.08em;
      line-height: 200%;
      text-transform: uppercase;
    `,
    basketCardItemName: css`
      ${font.styles.labelSmall};
    `,
    summaryContainer: css`
      background: ${brandColors.gray10};
    `,
    costItemLabel: css`
      border: 0;
    `,
    costsNumberSubtotal: css`
      font-family: ${font.styles.numbers};
    `,
    costsLabel: css`
      ${font.styles.labelSmall};
      font-size: 24px;
    `,
    costsNumber: css`
      font-family: ${font.styles.numbers};
      font-size: 24px;
    `,
    upsellSection: css`
      background-color: transparent;
      margin: 48px 0 0;
      padding: 32px;
    `,
    upsellCarousel: css`
      background-color: 'transparent';
    `,
    upsellTitle: css`
      ${font.styles.label};
    `,
    upsellCarouselCard: css`
      border-radius: 3px;
      background-color: ${brandColors.gray10};
      border: 1px solid ${brandColors.gray30};
    `,
    upsellCarouselCardItemName: css`
      ${font.styles.labelInput};
    `,
  },
  checkout: {
    topBackgroundColor: Color(black),
    topTextColor: Color(black),
    backButtonVariant: 'secondary',
    sectionLabel: css`
      ${font.styles.description};
      font-weight: 400;
      margin: 48px 0 14px 0;
      text-transform: uppercase;
    `,
    summary: {
      productName: css`
        ${font.styles.labelInput};
      `,
      costsLabel: css`
        ${font.styles.labelInput};
      `,
      costsNumber: css`
        ${font.styles.labelInput};
        font-size: 24px;
      `,
    },
    heroAsset: 'CheckoutHero',
    rewards: {
      currencyButtonIcon: images.rewardsIcon,
      sectionButtonIcon: images.rewardsIcon,
    },
  },
  modals: {
    header: css`
      background-color: ${brandColors.black};
      padding: 24px 20px;
    `,
  },
  orderTracking,
})
