import { useMemo } from 'react'

const APP_BRAND = 'APP_BRAND'

export const useBrandCategories = (hasBrands, selectedBrand, menu) => {
  return useMemo(() => {
    if (!menu) return []
    return menu
      .filter(entry => !hasBrands || entry.metadata.find(m => m.key === APP_BRAND && m.value === selectedBrand))
      .map(entry => ({
        id: entry.id,
        key: `brandCategory-${entry.id}`,
        displayName: entry.name,
      }))
  }, [hasBrands, selectedBrand, menu])
}
