import 'firebase/analytics'
import 'firebase/firestore'
import 'firebase/auth'

import firebase from 'firebase/app'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
}

async function init() {
  // Initialize Firebase core
  if (firebase.apps.length === 0) {
    firebase.initializeApp(firebaseConfig)
    if (!(await firebase.analytics.isSupported())) return
    try {
      // Initialize Firebase analytics
      firebase.analytics()
    } catch (error) {
      console.error(error)
    }
  }
}

init()

export const db = firebase.firestore()

export const auth = firebase.auth()

export const withBatchTransaction = async fn => {
  const batch = db.batch()
  await fn(batch)
  batch.commit()
}

export default firebase
