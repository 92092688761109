import React from 'react'

import { useAsset } from '../../../hooks/useAsset'
import { Container } from './BackgroundImageSection.styles'

export const BackgroundImageSection = ({ children, ...props }) => {
  const src = useAsset('home', 'heroAsset')?.url
  return (
    <Container src={src} {...props}>
      {children}
    </Container>
  )
}
