import { isEqual } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { Button } from '../../components/Button/Button'
import { ToggleButton } from '../../components/ToggleButton/ToggleButton'
import { useText } from '../../content'
import { useSession } from '../../contexts/session'
import { useNutrition } from '../../hooks/useDietaryInformationFromItem'
import { useMessaging } from '../../hooks/useMessaging'
import firebase from '../../services/firebase'
import { ContentContainer, FormContainer, HeaderContainer, Paragraph } from './Profile.styles'

export const DietaryPreferencesContent = () => {
  const { text } = useText()
  const { state, dispatch } = useSession()
  const { dispatchMessage } = useMessaging()
  const { preferences: nutritionalPreferences, loading, restrictions: dietaryRestrictionTypes } = useNutrition()

  const { menuFilters = [] } = state
  const [updatedFilters, setUpdatedFilters] = useState(menuFilters)

  const formNotDirty = useMemo(() => isEqual(updatedFilters, menuFilters), [updatedFilters, menuFilters])

  useEffect(() => {
    firebase.analytics().logEvent('Profile_Dietary_Restrictions_Selected')
  }, [])

  const toggleFilter = restriction => {
    setUpdatedFilters(prevState => {
      const exists = Boolean(prevState.find(f => f.oloCode === restriction.oloCode))

      if (exists) {
        return prevState.filter(f => f.oloCode !== restriction.oloCode)
      }

      return [...prevState, restriction]
    })
  }

  const handleSave = useCallback(() => {
    const filterPromises = updatedFilters.map(filter =>
      firebase.analytics().logEvent('Filter_Saved', {
        filter: filter.name,
      }),
    )
    Promise.all(filterPromises)

    dispatch({ type: 'SET_MENU_FILTERS', payload: updatedFilters })
    dispatchMessage({ payload: { message: 'Dietary preferences saved.' }, type: 'info' })
  }, [dispatch, updatedFilters])

  if (loading) {
    return (
      <>
        <HeaderContainer>{text('DietaryRestrictions.Title')}</HeaderContainer>

        <ContentContainer>
          <Paragraph style={{ textAlign: 'center' }}>Loading...</Paragraph>
        </ContentContainer>
      </>
    )
  }

  return (
    <>
      <HeaderContainer>{text('DietaryRestrictions.Title')}</HeaderContainer>

      <ContentContainer>
        <Paragraph style={{ textAlign: 'center' }}>{text('DietaryRestrictions.PrimaryMessage')}</Paragraph>

        <FormContainer style={{ marginTop: 27 }}>
          {dietaryRestrictionTypes.map((restriction, idx) => (
            <Checkbox
              key={restriction.id}
              style={{ marginTop: idx > 0 ? 12 : 0 }}
              onClick={() => toggleFilter(restriction)}
              checked={Boolean(updatedFilters.find(f => f.oloCode === restriction.oloCode))}
            >
              {restriction.name}
            </Checkbox>
          ))}
        </FormContainer>

        {nutritionalPreferences.length > 0 && (
          <>
            <HeaderContainer style={{ marginTop: 27 }}>Health Goals</HeaderContainer>
            <FormContainer>
              {nutritionalPreferences.map((preference, idx) => (
                <Checkbox
                  key={preference.id}
                  style={{ marginTop: idx > 0 ? 12 : 0 }}
                  onClick={() => toggleFilter(preference)}
                  checked={Boolean(updatedFilters.find(f => f.oloCode === preference.oloCode))}
                >
                  {preference.name}
                </Checkbox>
              ))}
            </FormContainer>
          </>
        )}

        <FormContainer style={{ marginTop: 27 }}>
          <Button disabled={formNotDirty} label="Save" variant="primary" onClick={handleSave} style={{ marginTop: 27 }} />
        </FormContainer>
      </ContentContainer>
    </>
  )
}

const Checkbox = styled(ToggleButton)`
  font-family: ${({ theme }) => theme.font.styles.regular};
`

const DietaryPrefCardContainer = styled.div`
  display: flex;
  padding: 27px 16px;

  border-radius: 3px;
  border: 1px solid #d0d0d0;
`
