import React, { useMemo } from 'react'

import { TextSeparator } from '../../../../components/TextSeparator/TextSeparator'
import { useText } from '../../../../content'
import { useCaloriesInformation } from '../../../../hooks/useCaloriesInformation'
import { Text } from './Calories.styles'

export const Calories = ({ activeModifiers, menuItem, useTextSeparator = true }) => {
  const { baseCalories, maxCalories } = useCaloriesInformation({ activeModifiers, menuItem })
  const { text } = useText()

  const caloriesText = useMemo(() => {
    const calories = [baseCalories, maxCalories].filter(Number).join('-')
    return calories ? `${calories} ${text('Label.Calories')}` : ''
  }, [baseCalories, maxCalories])

  if (!caloriesText) return null

  return (
    <>
      {useTextSeparator && (
        <>
          <TextSeparator />
        </>
      )}
      <Text data-testid="calories-text">{caloriesText}</Text>
    </>
  )
}
