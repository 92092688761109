import React from 'react'

import { PREFERENCES } from '../../../constants/preferences'
import { useConfig } from '../../../contexts/appConfig'
import { useAsset } from '../../../hooks/useAsset'
import { FacebookIcon, InstagramIcon, TwitterIcon } from '../../Icons/Icons'
import { FooterStyle, LinkGroupStyle, LinksGroupsStyle, LinkStyle, SocialGroupsStyle } from './MeltshopFooter.styles'

const BrandLogo = () => {
  return <img alt={useConfig()?.getPreference(PREFERENCES.GENERAL)?.brandName || 'Logo'} src={useAsset('header', 'logoAsset')?.url} />
}

const Link = ({ children, target = '_blank', ...props }) => (
  <LinkStyle target={target} {...props}>
    {children}
  </LinkStyle>
)

const ICONS_BY_TYPE = {
  facebook: FacebookIcon,
  instagram: InstagramIcon,
  twitter: TwitterIcon,
}

const SocialIconLink = ({ url, type }) => {
  const Icon = ICONS_BY_TYPE[type]
  return (
    <Link key={type} href={url}>
      <Icon size={16} />
    </Link>
  )
}

export const MeltshopFooter = () => {
  const { social, links } = useConfig().getPreference('Footer') || {}
  return (
    <FooterStyle as="footer">
      <BrandLogo />
      <LinksGroupsStyle as="div">
        <Link href={links?.site?.url}>{links?.site?.text}</Link>
        <Link href={links?.contact?.url}>{links?.contact?.text}</Link>
        <Link href={links?.terms?.url}>{links?.terms?.text}</Link>
        <Link href={links?.privacy?.url}>{links?.privacy?.text}</Link>
      </LinksGroupsStyle>
      <SocialGroupsStyle as="div">{social?.map(SocialIconLink)}</SocialGroupsStyle>
    </FooterStyle>
  )
}
