import { useQuery } from '@apollo/client'
import { uniqBy } from 'lodash'
import { useCallback, useMemo } from 'react'

import { useSession } from '../contexts/session'
import { GET_GUEST_ADDRESSES } from '../data/guest/queries'

export const useGuestAddresses = () => {
  const { state } = useSession()

  const { data, loading } = useQuery(GET_GUEST_ADDRESSES, {
    skip: !state.user,
    fetchPolicy: 'network-only',
    variables: { token: state.user?.orderingAuthenticationToken },
  })

  const addresses = useMemo(() => {
    const uniqueAddresses = uniqBy(data?.guestAddresses, a => a.streetAddress)
    if (uniqueAddresses.length !== data?.guestAddresses.length) {
      console.debug('[useGuestAddresses] found duplicate addresses:', (data?.guestAddresses?.length || 0) - (uniqueAddresses?.length || 0))
    }
    return uniqueAddresses
  }, [data?.guestAddresses])

  const addAddress = useCallback(() => Promise.resolve(), [])

  const removeAddress = useCallback(() => Promise.reject(new Error('Not yet implemented.')), [])

  const updateAddress = useCallback(() => Promise.reject(new Error('Not yet implemented.')), [])

  return { addresses, loading, addAddress, removeAddress, updateAddress }
}
