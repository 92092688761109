import moment from 'moment-timezone'

import { HANDOFF_MODES } from '../constants/handoffModes'

/**
 * @description Extract `calendarRanges` from `restaurant` depending on the `handoffMode`\
 * @param {string} handoffMode
 * @param {Object} restaurant
 * @returns {Object[]} - `calendarRanges` as { start: `Moment`, end: `Moment`, weekday: 'string'}
 */
export const getCalendarRanges = (handoffMode = '', restaurant) => {
  const isPickup = handoffMode?.toUpperCase() === HANDOFF_MODES.PICKUP
  const calendarRanges = isPickup ? restaurant?.calendar?.ranges : restaurant?.deliverySupport?.calendar
  return calendarRanges?.map(
    ({ start, end, ...range }) =>
      ({
        ...range,
        start: start && moment(start),
        end: end && moment(end),
      } || []),
  )
}

/**
 * @description Determine if restaurant is open based on handoff mode and calendar ranges
 * @param {string} handoffMode
 * @param {Object} restaurant
 * @returns {boolean}
 */
export const isRestaurantOpen = (handoffMode, restaurant) => {
  const calendarRanges = getCalendarRanges(handoffMode, restaurant)
  return (
    restaurant.isAvailable &&
    calendarRanges?.some(range => {
      return !range.closed && moment().isBetween(range.start, range.end, null, '[]')
    })
  )
}
