import React from 'react'

import { PREFERENCES } from '../../../constants/preferences'
import { useConfig } from '../../../contexts/appConfig'
import { useAsset } from '../../../hooks/useAsset'
import { FacebookIcon, InstagramIcon, TwitterIcon } from '../../Icons/Icons'
import { FooterStyle, LinkGroupStyle, LinksGroupsStyle, LinkStyle, SocialGroupsStyle } from './LittleBeetFooter.styles'

const BrandLogo = () => {
  return <img alt={useConfig()?.getPreference(PREFERENCES.GENERAL)?.brandName || 'Logo'} src={useAsset('header', 'logoAsset')?.url} />
}

const Link = ({ children, target = '_blank', ...props }) => (
  <LinkStyle target={target} {...props}>
    {children}
  </LinkStyle>
)

const ICONS_BY_TYPE = {
  facebook: FacebookIcon,
  instagram: InstagramIcon,
  twitter: TwitterIcon,
}

const SocialIconLink = ({ url, type }) => {
  const Icon = ICONS_BY_TYPE[type]
  return (
    <Link key={type} href={url}>
      <Icon size={16} />
    </Link>
  )
}

export const LittleBeetFooter = () => {
  const { social, links } = useConfig().getPreference('Footer') || {}

  return (
    <FooterStyle as="footer">
      <LinksGroupsStyle as="div">
        {links?.map(link => (
          <Link key={link.text} href={link.url}>
            {link.text}
          </Link>
        ))}
      </LinksGroupsStyle>
      <SocialGroupsStyle as="div">{social?.map(SocialIconLink)}</SocialGroupsStyle>
    </FooterStyle>
  )
}
