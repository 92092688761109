import React from 'react'
import { useTheme } from 'styled-components'

import { StyledToastContainer } from './ToastContainer.styles'

/**
 * `react-toastify`
 * How to style: @see {@link https://fkhadra.github.io/react-toastify/how-to-style}
 */
export const ToastContainer = () => {
  const theme = useTheme()
  return <StyledToastContainer autoClose={8000} draggable={false} hideProgressBar pauseOnFocusLoss={false} theme={theme} />
}
