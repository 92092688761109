import React from 'react'

import { DesktopOrTablet } from '../../../components/responsive/Responsive'
import { useConfig } from '../../../contexts/appConfig'
import { ImageBackgroundContainer } from '../ImageBackgroundContainer/ImageBackgroundContainer'
import { Container } from './HeroContainer.styles'

export const HeroContainer = () => (
  <DesktopOrTablet>
    <Container as="div">
      <ImageBackgroundContainer as="div" imageUrl={useConfig()?.getAsset('CheckoutHero')?.url} />
    </Container>
  </DesktopOrTablet>
)
