import { useMutation, useQuery } from '@apollo/client'
import { flatten, uniq } from 'lodash'
import moment from 'moment'
import React, { useMemo, useState } from 'react'
import { useDialogState } from 'reakit'
import { useTheme } from 'styled-components'

import { CircleLoadingIndicator } from '../../../../components/CircleLoadingIndicator/CircleLoadingIndicator'
import { DoubleCTA } from '../../../../components/DoubleCTA/DoubleCTA'
import { AddFavorieItemModal } from '../../../../components/FavoriteItem/AddFavoriteItemModal/AddFavorieItemModal'
import { RemoveFavorieItemModal } from '../../../../components/FavoriteItem/RemoveFavoriteItemModal/RemoveFavoriteItemModal'
import { ToggleFavoriteItemButton } from '../../../../components/FavoriteItem/ToggleFavoriteItemButton/ToggleFavoriteItemButton'
import { IngredientsContainer } from '../../../../components/IngredientsContainer/IngredientsContainer'
import { ModalWithBackdrop } from '../../../../components/ModalWithBackdrop/ModalWithBackdrop'
import { ModifierSelector } from '../../../../components/ModifierSelector/ModifierSelector'
import { NutritionalChips } from '../../../../components/NutritionalChips/NutritionalChips'
import { TextSeparator } from '../../../../components/TextSeparator/TextSeparator'
import { useText } from '../../../../content'
import { useFavoritesForItem } from '../../../../contexts/favorites'
import { useSession } from '../../../../contexts/session'
import { useCacheWithExpiration } from '../../../../data/apollo'
import { ADD_PRODUCT_TO_BASKET, UPDATE_BASKET_PRODUCT } from '../../../../data/basket/queries'
import { GET_MODIFIERS } from '../../../../data/menu/queries'
import { findMetadata, formatCurrency } from '../../../../data/utils'
import { useAnalytics } from '../../../../hooks/useAnalytics'
import { useDietaryInformationFromItem } from '../../../../hooks/useDietaryInformationFromItem'
import { useMessaging } from '../../../../hooks/useMessaging'
import { useSpotlight } from '../../../../hooks/useSpotlight'
import { Calories } from '../../../MenuItem/components/Calories/Calories'
import {
  ActionsContainer,
  Container,
  ControlContainer,
  DietaryInfoContainer,
  ImageContainer,
  InfoContainer,
  LoadingView,
  MetaContainer,
  NameHeader,
  PriceText,
  SelectorContainer,
  SpotlightButton,
  SpotlightCloseButton,
  SpotlightContainer,
  SpotlightDescription,
  SpotlightHeading,
  SpotlightIcon,
  SpotlightMeta,
  SpotlightTitle,
  TotalText,
  WarningIcon,
} from './CustomBuilderConfirmation.styles'

export const CustomBuilderConfirmation = ({ basketProduct, isEditing, menuItem, handleClose, handleModifyOne, handleModifyAll }) => {
  const {
    state: { restaurant, basket, activeModifiers = [], user },
    dispatch,
  } = useSession()
  const { text } = useText()
  const { dispatchMessage } = useMessaging()
  const { track } = useAnalytics()
  const theme = useTheme()

  const { findSpotlight } = useSpotlight()
  const spotlight = findSpotlight(menuItem)
  const spotlightDialogProps = useDialogState({ animated: true })

  const [quantity] = useState(() => (isEditing && basketProduct ? basketProduct.quantity : 1))

  const { sessionIntersection } = useDietaryInformationFromItem(menuItem)

  const { favorite } = useFavoritesForItem(menuItem)
  const addFavoriteDialogProps = useDialogState({ animated: true, visible: false })
  const removeFavoriteDialogProps = useDialogState({ animated: true, visible: false })

  const fetchPolicy = useCacheWithExpiration(moment.duration(30, 'seconds'), `lastFetch_modifiers_${menuItem?.id}`)
  const { data: menuItemModifierData, loading: loadingModifiers } = useQuery(GET_MODIFIERS, {
    fetchPolicy,
    variables: { menuItemId: menuItem?.id },
    onError: err => dispatchMessage.error(text('MenuItem.Errors.LoadingModifiers'), err),
  })

  const [addToBasketMutation, { loading: loadingAddToBasket }] = useMutation(ADD_PRODUCT_TO_BASKET, {
    onError: err => dispatchMessage.error(text('MenuItem.Errors.AddToBasket'), err),
  })

  const [updateBasketProductMutation, { loading: loadingUpdateProduct }] = useMutation(UPDATE_BASKET_PRODUCT, {
    onError: err => dispatchMessage.error(text('MenuItem.Errors.AddToBasket'), err),
  })

  const handleAddToBasket = async () => {
    const selectedOptions = flatten(activeModifiers.map(m => [...Array(m.quantity || 1).keys()].map(() => m.option.id)))

    const variables = {
      basketId: basket?.id,
      productId: menuItem?.id,
      restaurantId: restaurant.id,
      selectedOptions: selectedOptions.map(o => (o || '').toString()),
      quantity,
      authentication: user
        ? {
            loyaltyAccessToken: user?.accessToken,
            loyaltyAuthenticationToken: user?.authenticationToken,
            orderingAuthenticationToken: user?.orderingAuthenticationToken,
          }
        : null,
    }

    try {
      const {
        data: { addToBasket: updatedBasket },
      } = await addToBasketMutation({ variables })

      if (updatedBasket) {
        track('Added_To_Bag', {
          dishName: menuItem.name,
          modifiers: variables.selectedOptions.join(','),
          amount: menuItem.cost,
          quantity: variables.quantity,
        })

        dispatch({ type: 'SET_BASKET', payload: updatedBasket })
        dispatchMessage.info('Item added to your bag')
      }
    } catch (err) {
      console.error(err)
    }

    handleClose(true)
  }

  const handleUpdateBasket = async () => {
    const selectedOptions = flatten(activeModifiers.map(m => [...Array(m.quantity || 1).keys()].map(() => m.option.id)))

    const uniqueSelectedOptions = uniq(selectedOptions)
    const variables = {
      basketId: basket?.id,
      basketProductId: basketProduct.id,
      productId: basketProduct.productId,
      quantity,
      selectedOptions: uniqueSelectedOptions.map(o => (o || '').toString()),
    }

    try {
      const {
        data: { updateBasketProduct: updatedBasket },
      } = await updateBasketProductMutation({ variables })

      if (updatedBasket) {
        dispatch({ type: 'SET_BASKET', payload: updatedBasket })
      }
      dispatchMessage.info('Item update in your bag')
    } catch (err) {
      console.error(err)
    }

    handleClose(true)
  }

  const topLevelModifiers = useMemo(
    () => menuItemModifierData?.modifiers?.filter(m => findMetadata(m.metadata, 'APP_MODIFY_LEVEL') === 'TOP') || [],
    [menuItemModifierData?.modifiers],
  )

  const totalCost = useMemo(() => {
    let total = menuItem?.cost || 0

    activeModifiers.forEach(m => {
      if (m.option.adjustsParentPrice) {
        total += m.quantity * m.option.cost
      }
    })

    total = total * quantity

    return total
  }, [activeModifiers, menuItem?.cost, quantity])

  return (
    <Container>
      <ImageContainer as="div" src={menuItem.images?.[0]?.url}>
        {spotlight && (
          <SpotlightButton onClick={() => spotlightDialogProps.show()}>
            <SpotlightIcon />
          </SpotlightButton>
        )}
      </ImageContainer>
      <InfoContainer>
        <NutritionalChips item={menuItem} />
        <NameHeader>{menuItem?.name}</NameHeader>
        <MetaContainer>
          <PriceText>{formatCurrency(menuItem?.cost)}</PriceText>
          {menuItem?.baseCalories && <Calories count={menuItem?.baseCalories} />}
        </MetaContainer>
        <IngredientsContainer menuItem={menuItem} />
      </InfoContainer>

      {loadingModifiers ? (
        <LoadingView>
          <CircleLoadingIndicator />
        </LoadingView>
      ) : (
        <>
          <ControlContainer>
            {topLevelModifiers?.length > 0 && (
              <SelectorContainer>
                {topLevelModifiers?.map(mod => (
                  <ModifierSelector key={mod.id} modifier={mod} onClick={() => handleModifyOne(mod)} />
                ))}
              </SelectorContainer>
            )}
            <ToggleFavoriteItemButton
              favorite={favorite}
              addFavoriteDialogProps={addFavoriteDialogProps}
              removeFavoriteDialogProps={removeFavoriteDialogProps}
              valid
            />
          </ControlContainer>
          <ActionsContainer>
            <TotalText>
              Total
              <TextSeparator />
              {formatCurrency(totalCost || 0)}
            </TotalText>

            {sessionIntersection?.length > 0 && (
              <DietaryInfoContainer>
                {sessionIntersection?.map((dietaryRestriction, index) => (
                  <div key={`dietaryRestriction-${index}`}>
                    <WarningIcon />
                    <span>{dietaryRestriction.violationExplanation}</span>
                  </div>
                ))}
              </DietaryInfoContainer>
            )}

            <DoubleCTA
              buttons={[
                {
                  label: text('MenuItem.ModifyButton'),
                  onClick: handleModifyAll,
                  variant: 'secondary',
                },
                isEditing
                  ? {
                      label: text('MenuItem.UpdateButton'),
                      loading: loadingUpdateProduct,
                      onClick: handleUpdateBasket,
                      variant: 'primary',
                    }
                  : {
                      label: text('MenuItem.AddButton'),
                      loading: loadingAddToBasket,
                      onClick: handleAddToBasket,
                      variant: 'primary',
                      icon: theme?.icons?.addToBagIcon,
                    },
              ]}
            />
          </ActionsContainer>

          <AddFavorieItemModal dialogProps={addFavoriteDialogProps} menuItem={menuItem} />
          <RemoveFavorieItemModal dialogProps={removeFavoriteDialogProps} favorite={favorite} />

          {spotlightDialogProps.visible && (
            <ModalWithBackdrop
              dialogProps={spotlightDialogProps}
              ariaLabel="Spotlight"
              containerStyle={{ overflow: 'hidden', position: 'relative', height: 'auto' }}
            >
              <SpotlightContainer>
                <SpotlightCloseButton onClick={() => spotlightDialogProps.toggle()} />
                <SpotlightMeta>
                  <SpotlightTitle>{text('Spotlight.Heading')}</SpotlightTitle>
                  <SpotlightHeading>{spotlight?.heading}</SpotlightHeading>
                  <SpotlightDescription>{spotlight?.description}</SpotlightDescription>
                </SpotlightMeta>
              </SpotlightContainer>
            </ModalWithBackdrop>
          )}
        </>
      )}
    </Container>
  )
}
