import React, { useMemo } from 'react'

import { buildAddressLine1, buildAddressLine2 } from '../../utils/address'
import { DoubleCTA } from '../DoubleCTA/DoubleCTA'
import { Description } from '../Text/Text.styles'
import { AddressCardContainer, AddressContentContainer } from './AddressCard.styles'

export const AddressCard = ({ address, buttons, style }) => {
  const addressLine1 = useMemo(() => buildAddressLine1(address), [address])
  const addressLine2 = useMemo(() => buildAddressLine2(address), [address])
  return (
    <AddressCardContainer style={style}>
      <AddressContentContainer>
        {Boolean(address.nickname) && <Description>{address.nickname}</Description>}
        {Boolean(addressLine1) && <Description>{addressLine1}</Description>}
        {Boolean(addressLine2) && <Description>{addressLine2}</Description>}
      </AddressContentContainer>
      <DoubleCTA buttons={buttons} stacked />
    </AddressCardContainer>
  )
}
