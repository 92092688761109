import { Box } from 'reakit/Box'
import { Button } from 'reakit/Button'
import styled from 'styled-components'

import { isMobile, isTablet } from '../../../../../components/responsive/Responsive'

export const Container = styled(Box)`
  display: flex;
  flex-direction: row;
  flex: 1;
  > * {
    &:not(:first-child) {
      margin-left: 16px;
    }
  }
  padding: 0 16px 20px 16px;
  ${isTablet`
    flex-direction: column;
    > * {
      &:not(:first-child) {
        margin-top: 20px;
        margin-left: 0;
      }
    }
    padding-bottom: 20px;
  `}
  ${isMobile`
    flex-direction: column;
    > * {
      &:not(:first-child) {
        margin-top: 16px;
        margin-left: 0;
      }
    }
    padding: 16px 0;
  `}
`

export const InnerContainer = styled(Box)`
  position: relative;
  width: 100%;
  ${isTablet`
    height: 240px;
  `}
  ${isMobile`
    height: 180px;
  `}
`

export const BrandButton = styled(Button)`
  background: none;
  border: 0;
  color: ${props => props.theme.colors.brand.white};
  cursor: pointer;
  font-family: ${props => props.theme.font.styles.bold};
  font-size: 48px;
  text-shadow: ${props => `0px 4px 7px ${props.theme.colors.black.alpha(0.5)}`};

  /* overlap */
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  &:hover {
    background: ${props => `${props.theme.colors.brand.black.alpha(0.7)}`};
    transition: background 0.3s;
  }
  ${isMobile`
    font-size: 50px;
    line-height: 48px;
  `}
`
