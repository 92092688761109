import React, { useState } from 'react'

import { AccordionButton, AccordionContainer, AccordionContentContainer, ArrowDownIcon, ArrowUpIcon } from './Accordion.styles'

export const Accordion = ({ buttonContent, buttonStyle, children, ...props }) => {
  const [expanded, setExpanded] = useState()
  const toggleAccordion = () => setExpanded(!expanded)
  return (
    <AccordionContainer as="div" {...props}>
      <AccordionButton as="button" onClick={toggleAccordion} style={buttonStyle}>
        {buttonContent}
        {expanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </AccordionButton>
      <AccordionContentContainer as="div" $expanded={expanded}>
        {children}
      </AccordionContentContainer>
    </AccordionContainer>
  )
}
