import { gql } from '@apollo/client'

import { BASKET_FIELDS, ORDER_FIELDS } from '../../data/types'

// QUERIES

export const GET_BASKET = gql`
    query GetBasket($basketId: String!) {
        basket(basketId: $basketId) {
            ${BASKET_FIELDS}
        }
    }
`

// MUTATIONS

export const ADD_PRODUCT_TO_BASKET = gql`
    mutation addToBasket($basketId: String, $restaurantId: Float!, $productId: Float!, $quantity: Int!, $selectedOptions: [String], $authToken: String, $authentication: AuthenticationInput) {
          addToBasket(basketId: $basketId, restaurantId: $restaurantId, productId: $productId, quantity: $quantity, selectedOptions: $selectedOptions, authToken: $authToken, authentication: $authentication) {
            ${BASKET_FIELDS}
        }
    }
`

export const UPDATE_BASKET_PRODUCT = gql`
    mutation updateBasketProduct($basketId: String!, $basketProductId: Float!, $productId: Int!, $quantity: Int!, $selectedOptions: [String]) {
          updateBasketProduct(basketId: $basketId, basketProductId: $basketProductId, productId: $productId, quantity: $quantity, selectedOptions: $selectedOptions) {
            ${BASKET_FIELDS}
        }
    }
`

export const UPDATE_BASKET_PRODUCT_BATCH = gql`
    mutation updateBasketProductsBatch($basketId: String!, $products: ProductsInput!) {
        updateBasketProductsBatch(basketId: $basketId, products: $products) {
            ${BASKET_FIELDS}
        }
    }
`

export const REMOVE_BASKET_PRODUCT = gql`
    mutation removeBasketProduct($basketId: String!, $basketProductId: Float!) {
          removeBasketProduct(basketId: $basketId, basketProductId: $basketProductId) {
            ${BASKET_FIELDS}
        }
    }
`

export const UPDATE_BASKET_TIP = gql`
    mutation updateBasketTip($basketId: String!, $tipAmount: Float!) {
          updateBasketTip(basketId: $basketId, tipAmount: $tipAmount) {
            ${BASKET_FIELDS}
        }
    }
`

export const UPDATE_BASKET_HANDOFF_MODE = gql`
    mutation updateBasketHandoffMode($basketId: String!, $handoffMode: HandoffMode) {
          updateBasketHandoffMode(basketId: $basketId, handoffMode: $handoffMode) {
            ${BASKET_FIELDS}
        }
    }
`

export const UPDATE_BASKET_TIME_WANTED = gql`
    mutation updateBasketTimeWanted($basketId: String!, $asap: Boolean!, $wantedDateTime: String) {
          updateBasketTimeWanted(basketId: $basketId, asap: $asap, wantedDateTime: $wantedDateTime) {
            ${BASKET_FIELDS}
        }
    }
`

export const VALIDATE_BASKET = gql`
    mutation validateBasket($basketId: String!) {
        validateBasket(basketId: $basketId) {
            ${BASKET_FIELDS}
        }
    }
`

export const SUBMIT_BASKET = gql`
    mutation submitBasket($basketCheckoutInput: CheckoutInput!) {
        submitBasket(basketCheckoutInput: $basketCheckoutInput) {
            ${ORDER_FIELDS}
        }
    }
`

export const UPDATE_BASKET_COUPON = gql`
    mutation updateBasketCoupon($basketId: String!, $couponCode: String!) {
        updateBasketCoupon(basketId: $basketId, couponCode: $couponCode) {
            ${BASKET_FIELDS}
        }
    }
`

export const REMOVE_BASKET_COUPON = gql`
    mutation removeBasketCoupon($basketId: String!) {
        removeBasketCoupon(basketId: $basketId) {
            ${BASKET_FIELDS}
        }
    }
`

export const UPDATE_BASKET_DELIVERY_ADDRESS = gql`
    mutation updateBasketDeliveryAddress($basketId: String!, $streetAddress: String!, $aptNumber: String, $city: String!, $zipCode: String!, $phoneNumber: String!, $makeDefault: Boolean, $specialInstructions: String) {
        updateBasketDeliveryAddress(basketId: $basketId, streetAddress: $streetAddress, aptNumber: $aptNumber, city: $city, zipCode: $zipCode, phoneNumber: $phoneNumber, makeDefault: $makeDefault, specialInstructions: $specialInstructions) {
            ${BASKET_FIELDS}
        }
    }
`

export const CREATE_BASKET_FROM_PREVIOUS_ORDER = gql`
    mutation createBasketFromPreviousOrder($orderId: String!) {
        createBasketFromPreviousOrder(orderId: $orderId) {
            ${BASKET_FIELDS}
        }
    }
`
