import React, { useCallback, useMemo, useState } from 'react'

import { AddressForm } from '../../../../../components/AddressForm/AddressForm'
import { ModalHeader } from '../../../../../components/ModalHeader/ModalHeader'
import { useText } from '../../../../../content/index'
import { useMessaging } from '../../../../../hooks/useMessaging'
import firebase from '../../../../../services/firebase'
import { SavedAddressModalContainer } from './SavedAddressModal.styles'

export const EditSavedAddressModal = ({ address, onClose, removeAddress, updateAddress }) => {
  const [removeLoading, setRemoveLoading] = useState(false)
  const { dispatchMessage } = useMessaging()
  const { text } = useText()

  const handleRemoveAddress = useCallback(() => {
    console.debug('[EditSavedAddressModal] removing address:', address.id)
    setRemoveLoading(true)
    return removeAddress(address.id)
      .then(() => {
        dispatchMessage.info(`Address ${address.nickname} deleted successfully.`)
        onClose()
      })
      .catch(error => {
        dispatchMessage.error(text('Profile.AddressDetails.Errors.Delete'), error)
      })
      .finally(() => {
        setRemoveLoading(false)
      })
  }, [setRemoveLoading, removeAddress, dispatchMessage, onClose])

  const handleUpdateAddress = useCallback(
    patchAddress => {
      console.debug('[EditSavedAddressModal] updating address with:', patchAddress)
      return updateAddress(address.id, patchAddress)
        .then(() => {
          dispatchMessage.info(`Address ${patchAddress.nickname || patchAddress.streetAddress} saved successfully.`)
          firebase.analytics().logEvent('Save_Address_Successful', {
            zipCode: address.zipCode,
          })
          onClose()
        })
        .catch(error => {
          dispatchMessage.error(`Failed to save address ${patchAddress.nickname || patchAddress.streetAddress}.`, error)
        })
    },
    [updateAddress, dispatchMessage, address, onClose],
  )

  const extraButtons = useMemo(
    () => [{ disabled: removeLoading, loading: removeLoading, label: 'Remove', type: 'button', variant: 'secondary', onClick: handleRemoveAddress }],
    [removeLoading, handleRemoveAddress],
  )

  return (
    <SavedAddressModalContainer key={address?.id}>
      <ModalHeader title="Edit Address" onClose={onClose} />
      <AddressForm address={address} extraButtons={extraButtons} onSubmit={handleUpdateAddress} submitButtonLabel="Save" />
    </SavedAddressModalContainer>
  )
}
