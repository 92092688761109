import React from 'react'

import { useText } from '../../content'
import { RewardCard } from './RewardCard'

export const BankedRewardCard = ({ balance }) => {
  const { text } = useText()
  return (
    <RewardCard
      description={text('Profile.Rewards.BankedCurrency.Description')}
      iconProps={{ variant: 'currency' }}
      name={text('Profile.Rewards.BankedCurrency.Heading', { bankedCurrency: balance?.bankedCurrency })}
    />
  )
}
