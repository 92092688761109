import React, { useMemo } from 'react'

import { useFeatureFlags } from '../../hooks/useFeatureFlags'
import { DefaultOrderConfirmation } from './DefaultOrderConfirmation/DefaultOrderConfirmation'
import { RealtimeTrackingOrderConfirmation } from './RealtimeTrackingOrderConfirmation/RealtimeTrackingOrderConfirmation'

export const OrderConfirmation = props => {
  const { RealtimeTracking } = useFeatureFlags()
  const Component = useMemo(() => (RealtimeTracking ? RealtimeTrackingOrderConfirmation : DefaultOrderConfirmation), [RealtimeTracking])
  return <Component {...props} />
}
