import { useLazyQuery } from '@apollo/client'
import { useCallback, useState } from 'react'

import { useConfig } from '../contexts/appConfig'
import { GET_NEAREST_RESTAURANTS, GET_RESTAURANTS } from '../data/restaurants/queries'
import { useMessaging } from './useMessaging'

export const useNearestRestaurants = () => {
  const { dispatchMessage } = useMessaging()
  const { getPreference } = useConfig()
  const [showAll, setShowAll] = useState(false)

  const { PickupRestaurantsSearchLimit: searchLimit = 40, PickupRestaurantsSearchRadius: restaurantsSearchRadius = 10e3 } = getPreference(
    'OrderingConfiguration',
    {},
  )

  const [fetch, { data: allData, loadingAll }] = useLazyQuery(GET_RESTAURANTS, {
    onError: useCallback(err => dispatchMessage.error('Could not load restaurants', err), [dispatchMessage]),
  })

  const [fetchNearest, { data: nearestData, loadingNearest }] = useLazyQuery(GET_NEAREST_RESTAURANTS, {
    onError: useCallback(err => dispatchMessage.error('Could not load nearest restaurants', err), [dispatchMessage]),
    variables: { limit: searchLimit, radius: restaurantsSearchRadius },
  })

  const fetchAll = useCallback(() => {
    setShowAll(true)
    fetch()
  }, [fetch])

  const fetchNearby = useCallback(
    (latitude, longitude) => {
      setShowAll(false)
      fetchNearest({ variables: { latitude, longitude } })
    },
    [fetchNearest],
  )

  const data = showAll ? allData?.restaurants : nearestData?.restaurantsFindNearest
  const loading = loadingAll && loadingNearest
  return { data, fetchAll, fetchNearby, loading, showAll }
}
