import { gql } from '@apollo/client'

import { BASKET_FIELDS, GUEST_ACCOUNT_BALANCE_FIELDS, GUEST_FIELDS, LOYALTY_PROGRAM_FIELDS, REDEMPTION_STATUS_FIELDS } from '../../data/types'

// QUERIES
export const GET_LOYALTY_PROGRAM_INFO = gql`
    query GetLoyaltyProgramInfo($authentication: AuthenticationInput!) {
      loyaltyProgram(authentication: $authentication){
            ${LOYALTY_PROGRAM_FIELDS}
        }
    }
`
export const GET_LOYALTY_BALANCE = gql`
    query GetGuestLoyaltyBalance($authentication: AuthenticationInput!) {
      loyaltyBalance(authentication: $authentication){
            ${GUEST_ACCOUNT_BALANCE_FIELDS}
        }
    }
`

// MUTATIONS
export const LOGIN = gql`
    mutation LoginGuest($email: String!, $password: String!) {
      loginGuest(email: $email, password: $password){
            ${GUEST_FIELDS}
        }
    }
`

export const REWARD_REDEMPTION = gql`
  mutation RewardRedemption($redeemableId: Float!, $accessToken: String!) {
    redeemable(reedemableId: $redeemableId, accessToken: $accessToken) {
      redemptionTrackingCode
      expiringAt
      redemptionId
      redeemableName
      redemptionStatus
      redemptionMessage
    }
  }
`

export const REGISTER = gql`
    mutation Register($guest: RegisterGuestInput!) {
        signUpGuest(guest: $guest){
            ${GUEST_FIELDS}
        }
    }
`

export const RESET_PASSWORD = gql`
  mutation ResetPassword($email: String!) {
    sendPasswordReset(email: $email)
  }
`

export const UPDATE_GUEST = gql`
    mutation UpdateGuest($authenticationToken: String!, $guest: GuestInput!) {
      updateGuest(authenticationToken: $authenticationToken, guest: $guest){
            ${GUEST_FIELDS}
        }
    }
`

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($authenticationToken: String!, $password: String!, $passwordConfirmation: String!) {
  updateGuestPassword(authenticationToken: $authenticationToken, password: $password, passwordConfirmation: $passwordConfirmation){
      ${GUEST_FIELDS}
    }
  }
`

export const SEND_MIGRATION_VERIFICATION_CODE = gql`
  mutation SendMigrationVerificationCode($authentication: AuthenticationInput!) {
    sendMigrationVerificationCode(authentication: $authentication) {
      success
      messages
    }
  }
`

export const VERIFY_MIGRATION_VERIFICATION_CODE = gql`
  mutation MigrateGuest($authentication: AuthenticationInput!, $migrationVerification: LoyaltyMigrationInput!) {
    migrateGuest(authentication: $authentication, migrationVerification: $migrationVerification) {
      success
      messages
    }
  }
`

export const APPLY_REWARD_TO_BASKET = gql`
  mutation ApplyReward($authentication: AuthenticationInput!, $redemption: LoyaltyRedemption!) {
    applyReward(authentication: $authentication, redemption: $redemption) {
      ${BASKET_FIELDS}
    }
  }
`

export const REMOVE_REWARD = gql`
  mutation RemoveReward($authentication: AuthenticationInput!, $reward: RemoveRewardInput!) {
    removeReward(authentication: $authentication, reward: $reward) {
      ${BASKET_FIELDS}
    }
  }
`
