import { Link } from 'react-router-dom'
import { Box } from 'reakit/Box'
import { Button } from 'reakit/Button'
import styled from 'styled-components'

import { isMobile, isTablet } from '../responsive/Responsive'

export const HeaderContainer = styled.header`
  position: fixed;
  z-index: 10;
  width: 100%;
`

export const HeaderTopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  ${isTablet`
    padding: 24px;
  `}
  ${isMobile`
    padding: 16px;
  `}
  ${({ theme }) => theme.header.container};
`

export const HeaderSpacer = styled.div`
  transition: min-height 250ms ease-in-out;
`

export const LeftContainer = styled.div`
  display: flex;
  margin-right: auto;
  ${isMobile`
    margin-right: 0;
    overflow: hidden;
  `}
`

export const BrandContainer = styled(Box)`
  align-items: center;
  display: flex;
  ${isMobile`
    justify-content: center;
    width: 100%;
  `}
  ${({ theme }) => theme.header.styles.brandLogo.container};
`

export const BrandLink = styled(Link)`
  display: flex;
  margin-left: 5px;
`

export const BrandLogo = styled.img`
  display: flex;
  max-width: 100%;
  max-height: 100%;
`

export const SignInLink = styled(Link)`
  color: ${({ theme }) => theme.header.styles.linkColor};

  display: flex;
  align-items: center;
  flex-direction: row;
  > * {
    &:not(:first-child) {
      margin-left: 12px;
    }
  }

  /* link overrides */
  background: transparent;
  cursor: pointer;

  margin-right: 36px;

  ${isMobile`
    order: -1;
    margin-right: 0;
    > span {
      display: none;
    }
  `}

  ${isTablet`
    order: 0;
    margin-right: 36px;
    > span {
      display: none;
    }
  `}
`

export const DietaryLink = styled(SignInLink)`
  ${isMobile`
    display: none;
  `}
`

export const MyBagButton = styled(Button)`
  color: ${({ theme }) => theme.header.styles.linkColor};

  display: flex;
  align-items: center;
  flex-direction: row;
  > * {
    &:not(:first-child) {
      margin-left: 12px;
    }
  }

  /* button overrides */
  background: transparent;
  border: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }

  ${isMobile`
    > span:first-of-type {
      display: none;
    }
  `}

  ${isTablet`
    > span:first-of-type {
      display: none;
    }
  `}

  position: relative;
`

export const BagItemsCount = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.basket.bagItemsCount};
  background-color: ${({ theme }) => theme.colors.primary};

  border-radius: 50%;
  height: 16px;
  width: 16px;

  position: absolute;
  top: -2px;
  left: -6px;
`
export const ChipAndPillContainer = styled.div`
  position: relative;
`
