import { gql, useQuery } from '@apollo/client'
import { findIndex, flatten } from 'lodash'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { FullScreenLoading } from '../../../components/FullScreenLoading/FullScreenLoading'
import { Page } from '../../../components/Page/Page'
import { Mobile } from '../../../components/responsive/Responsive'
import { useText } from '../../../content'
import { useSession } from '../../../contexts/session'
import { apolloClient, useCacheWithExpiration } from '../../../data/apollo'
import { GET_MODIFIERS } from '../../../data/menu/queries'
import { MENU_ITEM_FIELDS } from '../../../data/types'
import { findMetadata, formatCurrency } from '../../../data/utils'
import { useModifierValidation } from '../../../hooks/useModifierValidation'
import { useNavigation } from '../../../hooks/useNavigation'
import { CustomBuilderConfirmation } from '../components/CustomBuilderConfirmation/CustomBuilderConfirmation'
import { ModifierGroupBar } from '../components/ModifierGroupBar/ModifierGroupBar'
import { ModifierOptionCard } from '../components/ModifierOptionCard/ModifierOptionCard'
import { ModifierSelectedOption } from '../components/ModifierSelectedOption/ModifierSelectedOption'
import {
  Aside,
  AsideBodyContainer,
  AsideButtonContainer,
  DescriptionText,
  Header,
  InnerContainer,
  MetaContainer,
  ModifierOptionCardsContainer,
  ModifierOptionCardWrapperContainer,
  ModifierSelectedContainer,
  NextStepButton,
  OuterContainer,
  PriceAndCaloriesContainer,
  PriceText,
  Section,
} from './DefaultCustomBuilder.styles'

const GetModifierName = mod => {
  return findMetadata(mod?.metadata || [], 'APP_SHORT_NAME') || mod?.description || 'UNKNOWN'
}

export const DefaultCustomBuilder = () => {
  const navigation = useNavigation()
  const { text } = useText()
  const location = useLocation()
  const { item, isEditing, basketProduct } = location?.state
  const { validateModifier } = useModifierValidation()

  const {
    state: { activeModifiers = [] },
    dispatch,
  } = useSession()

  const [activeModifier, setActiveModifier] = useState()
  const [displayConfirmationDialog, setDisplayConfirmationDialog] = useState(false)

  const menuItem = useMemo(() => {
    // if the user is coming from the basket (is editing), get the product info from the cache
    if (isEditing && basketProduct) {
      const cachedItem = apolloClient.readFragment({
        id: `MenuItem:${basketProduct.productId}`,
        fragment: gql`
          fragment menuItem on MenuItem {
            ${MENU_ITEM_FIELDS}
          }
        `,
      })

      console.debug({ menuItem: cachedItem })
      return cachedItem
    }

    console.debug({ menuItem: item })
    return item
  }, [basketProduct, isEditing, item])

  const fetchPolicy = useCacheWithExpiration(moment.duration(30, 'seconds'), `lastFetch_modifiers_${menuItem?.id}`)
  const { data: menuItemModifierData, loading: loadingModifiers } = useQuery(GET_MODIFIERS, {
    fetchPolicy,
    variables: { menuItemId: menuItem?.id },
  })
  const modifiers = useMemo(() => menuItemModifierData?.modifiers || [], [menuItemModifierData?.modifiers])

  useEffect(() => {
    if (isEditing && menuItemModifierData) {
      const modOptions = flatten(modifiers.map(mod => mod.options))

      const selectedModifiers = basketProduct.choices.map(choice => {
        const found = modOptions.find(option => {
          return option.id === choice.optionId
        })

        return { option: found, quantity: choice.quantity }
      })
      // console.debug('===selectedModifiers', JSON.stringify(selectedModifiers, null, 2));
      dispatch({ type: 'SET_ACTIVE_MODIFIERS', payload: selectedModifiers })
    } else {
      // clear out any existing modifiers if it is ADDING a new item to the basket
      console.debug('Clearing out any existing modifiers')
      dispatch({ type: 'SET_ACTIVE_MODIFIERS', payload: [] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItemModifierData])

  const totalCost = useMemo(() => {
    let total = menuItem?.cost || 0

    activeModifiers.forEach(m => {
      if (m.option.adjustsParentPrice) {
        total += m.quantity * m.option.cost
      }
    })

    return total
  }, [activeModifiers, menuItem])

  const calories = useMemo(() => menuItem?.baseCalories, [menuItem?.baseCalories])

  const isValid = useMemo(() => validateModifier(activeModifier).isValid, [activeModifier, activeModifiers])

  const menuItemName = useMemo(() => {
    return findMetadata(menuItem?.metadata, 'APP_SHORT_NAME') || menuItem?.name
  }, [menuItem])

  const nextStepButtonText = useMemo(() => {
    const currentIndex = findIndex(modifiers, activeModifier)
    const nextModifier = modifiers[currentIndex + 1]
    if (nextModifier) {
      return text('MenuItemBuilder.Cta.Next', {
        nextStep: GetModifierName(nextModifier),
      })
    } else {
      return text('MenuItemBuilder.Cta.Complete')
    }
  }, [activeModifier, modifiers, text])

  useEffect(() => {
    if (activeModifier) {
      // make sure that everything before the active modifier is valid
      // and if not, move backwards
      for (const mod of modifiers) {
        if (mod === activeModifier) {
          break
        } else {
          if (!validateModifier(mod)) {
            console.debug(`found an invalid, previous modifier (${GetModifierName(mod)}}). moving backwards.`)
            setActiveModifier(mod)
            break
          }
        }
      }
    }
    if (!activeModifier && modifiers.length > 0) {
      setActiveModifier(modifiers[0])
      console.debug(modifiers[1])
    }
  }, [activeModifier, activeModifiers, modifiers])

  const handleSelectModifierGroup = modifier => {
    // determine if the group we're trying to move to is forward or backwards
    const nextIndex = findIndex(modifiers, modifier)
    const currentIndex = findIndex(modifiers, activeModifier)
    if (nextIndex < currentIndex || isValid) {
      setActiveModifier(modifier)
    }
  }

  const handleNextStep = () => {
    const currentIndex = findIndex(modifiers, activeModifier)
    const nextModifier = modifiers[currentIndex + 1]
    if (nextModifier) {
      handleSelectModifierGroup(nextModifier)
    } else {
      setDisplayConfirmationDialog(true)
    }
  }

  const handleRemoveSelectedModifierOption = option => {
    const selectedOptions = activeModifiers.filter(m => m.option.id !== option.id)
    dispatch({ type: 'SET_ACTIVE_MODIFIERS', payload: selectedOptions })
  }

  const handleClose = success => {
    setDisplayConfirmationDialog(false)
    if (success) {
      navigation.navigate('StartOrder', { item: null })
    }
  }

  const handleModifyOne = modifier => {
    setDisplayConfirmationDialog(false)
    setActiveModifier(modifier)
  }

  const handleModifyAll = () => {
    setDisplayConfirmationDialog(false)
  }

  const modifiersSummary = useMemo(
    () =>
      modifiers?.reduce((obj, modifier, index) => {
        obj[index] = {
          active: activeModifier?.id === modifier.id,
          enabled: index === 0 || (obj[index - 1].enabled && obj[index - 1].valid),
          selected: activeModifiers.filter(m => m.modifier?.id === modifier?.id),
          valid: validateModifier(modifier).isValid,
        }
        return obj
      }, {}),
    [modifiers, activeModifier, activeModifiers, validateModifier],
  )

  useEffect(() => {
    console.debug('[CustomBuilderPage]', { menuItem, activeModifier, modifiers, calories, isValid, menuItemName, totalCost })
  }, [menuItem, activeModifier, modifiers, calories, isValid, menuItemName, totalCost])

  return (
    <Mobile>
      {isMobile => (
        <Page isScrollable>
          <FullScreenLoading isOverlay isVisible={loadingModifiers} />
          <OuterContainer>
            <ModifierGroupBar onClick={handleSelectModifierGroup} modifiers={modifiers} modifiersSummary={modifiersSummary} />
            <InnerContainer>
              <Section>
                <Header>{activeModifier?.description}</Header>
                <DescriptionText style={{ margin: '8px 0px 16px' }}>{activeModifier?.explanationText}</DescriptionText>
                <ModifierOptionCardsContainer>
                  {(activeModifier?.options || []).map(option => (
                    <ModifierOptionCardWrapperContainer key={option.id}>
                      <ModifierOptionCard modifier={activeModifier} option={option} />
                    </ModifierOptionCardWrapperContainer>
                  ))}
                </ModifierOptionCardsContainer>
              </Section>
              {!loadingModifiers && (
                <>
                  <Aside $fixed={displayConfirmationDialog && isMobile}>
                    {displayConfirmationDialog ? (
                      <CustomBuilderConfirmation
                        basketProduct={basketProduct}
                        isEditing={isEditing}
                        menuItem={menuItem}
                        handleClose={handleClose}
                        handleModifyOne={handleModifyOne}
                        handleModifyAll={handleModifyAll}
                      />
                    ) : (
                      <>
                        <AsideButtonContainer>
                          <NextStepButton label={nextStepButtonText} onClick={handleNextStep} disabled={!isValid} />
                        </AsideButtonContainer>
                        <AsideBodyContainer $isVisible={!isMobile}>
                          <MetaContainer>
                            <Header style={{ marginBottom: '24px' }} title={menuItemName}>
                              {menuItemName}
                            </Header>
                            <PriceAndCaloriesContainer>
                              <PriceText>{formatCurrency(totalCost || 0)}</PriceText>
                              {calories && <DescriptionText style={{ marginLeft: '24px' }}>{calories} Cal</DescriptionText>}
                            </PriceAndCaloriesContainer>
                          </MetaContainer>
                          <ModifierSelectedContainer>
                            {modifiers?.map((modifier, index) => (
                              <ModifierSelectedOption
                                key={modifier.id}
                                modifier={modifier}
                                selectedOptions={modifiersSummary[index].selected}
                                handleOnRemove={handleRemoveSelectedModifierOption}
                              />
                            ))}
                          </ModifierSelectedContainer>
                        </AsideBodyContainer>
                      </>
                    )}
                  </Aside>
                </>
              )}
            </InnerContainer>
          </OuterContainer>
        </Page>
      )}
    </Mobile>
  )
}
