import React from 'react'

import { SignInForm } from '../../../components/SignInForm/SignInForm'
import { SignInSectionContainer } from './SignInSection.styles'

export const SignInSection = props => (
  <SignInSectionContainer>
    <SignInForm {...props} />
  </SignInSectionContainer>
)
