import moment from 'moment'

export const TIME_FORMAT = 'h:mma'

export const formatTime = m1 => m1?.format(TIME_FORMAT)

export const getFullDayOfWeek = weekday => moment().day(weekday).format('dddd')

export const isTimeEqual = (m1, m2) => formatTime(m1) === formatTime(m2)

export const isSameWeekday = (m1, m2) => m1?.format('ddd') === m2?.format('ddd')

export const getCurrentWeekdayShort = () => new Date().toLocaleString('default', { weekday: 'short' })
