import { Box } from 'reakit/Box'
import { Input } from 'reakit/Input'
import styled, { css } from 'styled-components'

import { SearchIcon } from '../Icons/Icons'

export const ContainerStyle = styled(Box)`
  ${({ theme }) => theme.autocomplete.container};
  border: ${({ theme }) => theme.borders.light};

  cursor: pointer;
  padding: 18px 14px;
  width: 100%;

  &:hover {
    border: ${({ theme }) => theme.borders.normal};
  }
  &:focus-within {
    border: ${({ theme }) => theme.borders.normal};
  }

  position: relative;
`

export const InputContainerStyle = styled(Box)`
  align-items: center;
  display: flex;
`

export const SearchInput = styled(Input)`
  ${({ theme }) => theme.font.styles.description};

  border: none;
  cursor: pointer;
  width: 100%;
`

export const ItemList = styled.ul`
  background-color: ${({ theme }) => theme.colors.brand.white.hex()};
  position: absolute;
  z-index: 2;
`

export const Item = styled.li`
  ${({ theme }) => theme.font.styles.description};
  background: ${({ theme }) => theme.colors.brand.white.hex()};
  color: ${({ theme }) => theme.colors.brand.gray90.hex()};

  border: ${({ theme }) => theme.borders.light};

  display: flex;
  align-items: center;

  cursor: pointer;
  padding: 24px 16px;

  /* list override */
  list-style: none;

  ${props =>
    props.$selected &&
    css`
      background: ${({ theme }) => theme.items.selected.background};
    `};

  ${props =>
    props.$highlighted &&
    css`
      border: ${({ theme }) => theme.borders.normal};
      background: ${({ theme }) => theme.items.highlighted.background};
    `};

  &:hover {
    border: ${({ theme }) => theme.borders.normal};
    background: ${({ theme }) => theme.items.hover.background};
  }
`

export const Icon = styled(SearchIcon)`
  margin-right: 10px;
`
