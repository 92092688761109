import { MdExpandMore } from 'react-icons/md'
import styled from 'styled-components'

export const ButtonContainer = styled.button`
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background: ${props => (props.isRequired ? props.theme.colors.backgroundRequiredInput : props.theme.colors.white)};
  border: ${props => props.theme.borders.light};
  border-radius: ${props => props.theme.borderRadii.sm};
  cursor: pointer;
  height: 77px;
  padding: 16px 20px;

  label {
    ${({ theme }) => theme.font.styles.labelInputSmall}
    text-transform: uppercase;
  }
`

export const ButtonContainerWithContent = styled.button`
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  background: ${props => props.theme.colors.white};
  border: ${props => props.theme.borders.light};
  border-radius: ${props => props.theme.borderRadii.sm};
  cursor: pointer;
  height: 77px;
  padding: 16px 20px;

  label {
    position: absolute;
    top: 16px;
    left: 20px;

    color: ${props => props.theme.colors.brand.gray80};

    ${({ theme }) => theme.font.styles.labelInputExtraSmall}

    pointer-events: none;
    transition: all 0.2s ease;
  }
`

export const ButtonContent = styled.span`
  ${({ theme }) => theme.font.styles.labelInput}
`

export const ChevronIcon = styled(MdExpandMore).attrs(({ theme }) => ({
  color: theme.colors.black.hex(),
  size: 32,
}))``
