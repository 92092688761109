import React from 'react'
import { Link } from 'react-router-dom'

import { PREFERENCES } from '../../../constants/preferences'
import { useText } from '../../../content'
import { useConfig } from '../../../contexts/appConfig'
import {
  AppLinkStyle,
  BrandLogoStyle,
  FooterContent,
  FooterSection,
  FooterSectionTitle,
  FooterStyle,
  FooterSubSection,
  LinkStyle,
  Paragraph,
} from './FgcFooter.styles'

const BrandLogo = ({ src }) => {
  return <BrandLogoStyle alt={useConfig()?.getPreference(PREFERENCES.GENERAL)?.brandName || 'Logo'} src={src} />
}

const SectionLink = ({ children, target = '_blank', ...props }) => (
  <LinkStyle target={target} {...props}>
    {children}
  </LinkStyle>
)

const AppLink = ({ children, target = '_blank', ...props }) => (
  <AppLinkStyle target={target} {...props}>
    {children}
  </AppLinkStyle>
)

export const FgcFooter = React.forwardRef((_, ref) => {
  const { text } = useText()
  const { getAsset } = useConfig()
  const { social, app, more, support } = useConfig().getPreference('Footer') || {}

  return (
    <FooterStyle as="footer" ref={ref}>
      <FooterContent>
        <FooterSection>
          <Link to="/">
            <BrandLogo src={getAsset('LogoOnDarkFooter')?.url} />
          </Link>
        </FooterSection>
        <FooterSection>
          <FooterSectionTitle>{text('Footer.Section.App.Title')}</FooterSectionTitle>
          <Paragraph style={{ maxWidth: 250 }}>
            {text('Footer.Section.App.Text1')}
            {` `}
            <AppLink href={app?.ios?.url}>{app?.ios?.text}</AppLink>
            {` `}
            {text('Footer.Section.App.Text2')}
            {` `}
            <AppLink href={app?.ios?.url}>{app?.android?.text}</AppLink>
          </Paragraph>
        </FooterSection>
        <FooterSection style={{ flexDirection: 'row' }}>
          <FooterSubSection>
            <FooterSectionTitle>{text('Footer.Section.Connect.Title')}</FooterSectionTitle>
            {social?.map?.(link => (
              <SectionLink key={`${link.text}${link.url}`} href={link.url}>
                {link.text}
              </SectionLink>
            ))}
          </FooterSubSection>
          <FooterSubSection>
            <FooterSectionTitle>{text('Footer.Section.Support.Title')}</FooterSectionTitle>
            {support?.map?.(link => (
              <SectionLink key={`${link.text}${link.url}`} href={link.url}>
                {link.text}
              </SectionLink>
            ))}
          </FooterSubSection>
          <FooterSubSection $isLast>
            <FooterSectionTitle>{text('Footer.Section.More.Title')}</FooterSectionTitle>
            {more?.map?.(link => (
              <SectionLink key={`${link.text}${link.url}`} href={link.url}>
                {link.text}
              </SectionLink>
            ))}
          </FooterSubSection>
        </FooterSection>
      </FooterContent>
    </FooterStyle>
  )
})
