import { useApolloClient } from '@apollo/client'
import { debounce, first, merge } from 'lodash'
import fp from 'lodash/fp'
import { useEffect, useState } from 'react'

import { useSession } from '../contexts/session'
import { GET_ORDER } from '../data/order/queries'
import { GET_RESTAURANT } from '../data/restaurants/queries'
import { auth, db } from '../services/firebase'
import { geocode } from '../services/geocode'

const useOrderTracking = (orderId, listener = () => {}) => {
  const client = useApolloClient()
  const { state: sessionState } = useSession()
  const authenticationToken = sessionState.user?.authenticationToken

  const [state, setState] = useState({ loading: true, order: null, restaurant: null, initialInfo: null, error: null })

  useEffect(() => {
    const init = async () => {
      setState(prev => ({ ...prev, error: null }))

      try {
        const order = await client
          .query({ query: GET_ORDER, variables: { orderId, authenticationToken }, fetchPolicy: 'no-cache' })
          .then(({ data: { order } }) => retrieveDeliveryAddressCoord(order))
          .then(signInWithTrackingToken)

        const restaurant = await client
          .query({ query: GET_RESTAURANT, variables: { restaurantId: order.vendorId }, fetchPolicy: 'no-cache' })
          .then(({ data }) => data.restaurant)

        const trackinCollection = db.collection('dispatch').where('orderId', '==', Number(order.oloId))

        const initialInfo = await trackinCollection.get().then(res => (res.empty ? null : first(res.docs.map(r => r.data()))))

        setState(prev => ({ ...prev, order, restaurant, initialInfo }))

        const dispose = trackinCollection.onSnapshot(
          debounce(
            snapshot => {
              if (snapshot?.empty || !snapshot?.docs) {
                return
              }

              const doc = first(snapshot.docs.map(d => d.data()))

              if (!state.initialInfo) {
                setState(prev => ({ ...prev, initialInfo: doc }))
              }

              listener(doc)
            },
            3e3,
            { trailing: true, leading: true },
          ),
        )

        setState(prev => ({ ...prev, dispose }))
      } catch (error) {
        setState(prev => ({ ...prev, error }))
      } finally {
        setState(prev => ({ ...prev, loading: false }))
      }
    }

    init()
  }, [orderId, authenticationToken])

  useEffect(() => () => state.dispose?.(), [state.dispose])

  return state
}

const signInWithTrackingToken = order => {
  const trackingToken = order?.trackingToken
  return !trackingToken ? Promise.reject(new Error('Not authorized')) : auth.signInWithCustomToken(trackingToken).then(() => order)
}

const retrieveDeliveryAddressCoord = order => {
  const { deliveryAddress } = order

  if (arePropsDefined(['latitude', 'longitude'])(deliveryAddress)) {
    return order
  }

  return geocode(deliveryAddress.streetAddress, deliveryAddress.city).then(coordinates => merge(order, { deliveryAddress: coordinates }))
}

const arePropsDefined = props => fp.flow(fp.pick(props), fp.values, fp.all(fp.identity))

export default useOrderTracking
