import React, { useMemo } from 'react'
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom'

import { Page } from '../../components/Page/Page'
import { useText } from '../../content'
import { useFavorites } from '../../contexts/favorites'
import { ContactInfoContent } from './ContactInfo'
import { DietaryPreferencesContent } from './DietaryPreferences'
import FavoriteItemsPage from './FavoriteItems'
import { OrderHistoryContent } from './OrderHistory'
import { NavBarContainer, NavItem, PageContent } from './Profile.styles'
import { RewardsContent } from './Rewards'
import { SavedAddressesContent } from './SavedAddresses/SavedAddresses'
import { SavedPaymentsContent } from './SavedPayments'

export const ProfilePage = () => {
  const { path, url } = useRouteMatch()
  const { pathname } = useLocation()
  const { text } = useText()
  const [, , { isFavoriteFeatureEnabled }] = useFavorites()

  const navOptions = useMemo(() => {
    const defaultRoutes = [
      { to: url, selected: pathname === url, name: text('Profile.Contact.NavBar.Title') },
      { to: `${url}/rewards`, selected: pathname === `${url}/rewards`, name: text('Profile.Rewards.NavBar.Title') },
      { to: `${url}/history`, selected: pathname === `${url}/history`, name: text('Profile.RecentOrders.NavBar.Title') },
      { to: `${url}/payments`, selected: pathname === `${url}/payments`, name: text('Profile.SavedPayments.NavBar.Title') },
      { to: `${url}/addresses`, selected: pathname === `${url}/addresses`, name: text('Profile.SavedAddresses.NavBar.Title') },
      { to: `${url}/dietary`, selected: pathname === `${url}/dietary`, name: text('Profile.DietaryPreferences.NavBar.Title') },
    ]

    if (isFavoriteFeatureEnabled) {
      defaultRoutes.push({ name: text('Profile.FavoriteItems.NavBar.Title'), to: `${url}/favorites`, selected: pathname === `${url}/favorites` })
    }

    return defaultRoutes
  }, [pathname, url])

  return (
    <Page isScrollable>
      <PageContent>
        <NavBarContainer>
          {navOptions.map(({ name, ...props }) => (
            <NavItem key={name} {...props}>
              {name}
            </NavItem>
          ))}
        </NavBarContainer>

        <Switch>
          <Route exact path={`${path}/rewards`}>
            <RewardsContent />
          </Route>

          <Route exact path={`${path}/history`}>
            <OrderHistoryContent />
          </Route>

          <Route exact path={`${path}/payments`}>
            <SavedPaymentsContent />
          </Route>

          <Route exact path={`${path}/addresses`}>
            <SavedAddressesContent />
          </Route>

          <Route exact path={`${path}/dietary`}>
            <DietaryPreferencesContent />
          </Route>

          <Route exact path={`${path}/favorites`}>
            <FavoriteItemsPage />
          </Route>

          <Route exact path={path}>
            <ContactInfoContent />
          </Route>
        </Switch>
      </PageContent>
    </Page>
  )
}
