import React from 'react'
import { QRCode } from 'react-qr-svg'

import { Button } from '../../../components/Button/Button'
import { ModalHeader } from '../../../components/ModalHeader/ModalHeader'
import { ModalBodyContainer, ModalContainer, ModalFooterContainer, ModalWithBackdrop } from '../../../components/ModalWithBackdrop/ModalWithBackdrop'
import { H4, LabelCurrency } from '../../../components/Text/Text.styles'
import { useText } from '../../../content'
import { QRCodeContainer } from './RedeemableModal.styles'

export const RedeemableModal = ({ dialogProps, onConfirm, onCancel, redeemable }) => {
  const { text } = useText()
  return (
    <ModalWithBackdrop dialogProps={dialogProps} height="70%" style={{ padding: 16, flex: 1 }}>
      <ModalContainer>
        <ModalHeader title={text('Profile.Rewards.RewardRedemption.Title')} onClose={onCancel} />
        <ModalBodyContainer style={{ alignItems: 'center', justifyContent: 'center' }}>
          <QRCodeContainer>
            {redeemable?.redemptionTrackingCode && (
              <QRCode data-testid="redeemable-qrcode" value={redeemable?.redemptionTrackingCode} style={{ width: 150 }} />
            )}
          </QRCodeContainer>
          <LabelCurrency style={{ marginTop: 8 }}>{redeemable?.redemptionTrackingCode}</LabelCurrency>
          <H4 style={{ marginTop: 32, width: '75%', textAlign: 'center' }}>{text('Profile.Rewards.RewardRedemption.Message')}</H4>
        </ModalBodyContainer>
        <ModalFooterContainer>
          <Button label={text('Profile.Rewards.RewardRedemption.Finish')} variant="primary" onClick={onConfirm} />
        </ModalFooterContainer>
      </ModalContainer>
    </ModalWithBackdrop>
  )
}
