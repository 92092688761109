import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react'

import { InputContainer, TextInput } from './InputTextCustomTip.styles'

const InputTextCustomTip = ({ value = '', ...rest }, ref) => {
  const inputRef = useRef(null)

  const [isFocused, setIsFocused] = useState(false)

  useImperativeHandle(ref, () => ({
    focus() {
      inputRef.current.focus()
    },
  }))

  const handleInputFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleInputBlur = useCallback(() => {
    setIsFocused(false)
  }, [])

  return (
    <>
      <InputContainer isFocused={isFocused} isFilled={value !== ''}>
        {value !== '' && <span>$</span>}
        <TextInput type="number" ref={inputRef} onFocus={handleInputFocus} onBlur={handleInputBlur} value={value} {...rest} />
      </InputContainer>
    </>
  )
}

export default forwardRef(InputTextCustomTip)
