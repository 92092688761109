import { useMutation, useQuery } from '@apollo/client'
import React, { useCallback, useState } from 'react'
import { useDialogState } from 'reakit'
import styled from 'styled-components'

import { Button } from '../../components/Button/Button'
import { ModalHeader } from '../../components/ModalHeader/ModalHeader'
import { ModalWithBackdrop } from '../../components/ModalWithBackdrop/ModalWithBackdrop'
import { TextInput } from '../../components/TextInput/TextInput'
import { useText } from '../../content'
import { useSession } from '../../contexts/session'
import { DELETE_GUEST_BILLING_ACCOUNT, GET_GUEST_BILLING_ACCOUNTS } from '../../data/guest/queries'
import { useMessaging } from '../../hooks/useMessaging'
import { ContentContainer, FormContainer, HeaderContainer, Paragraph } from './Profile.styles'

export const SavedPaymentsContent = () => {
  const { state } = useSession()
  const { text } = useText()
  const { dispatchMessage } = useMessaging()
  const [selectedAccount, setSelectedAccount] = useState(null)
  const paymentInfoDialogProps = useDialogState({ animated: true, visible: false })
  const [deleteGuestBillingAccount, { loading: loadingRemove }] = useMutation(DELETE_GUEST_BILLING_ACCOUNT)

  const { data, loading, error, refetch } = useQuery(GET_GUEST_BILLING_ACCOUNTS, {
    fetchPolicy: 'network-only',
    variables: { orderingAuthenticationToken: state.user?.orderingAuthenticationToken },
    onError: e => dispatchMessage({ type: 'error', payload: { message: 'Could not load billing account.', error: e } }),
  })

  const removePaymentAccount = useCallback(async () => {
    await deleteGuestBillingAccount({
      variables: { orderingAuthenticationToken: state.user?.orderingAuthenticationToken, billingAccountId: String(selectedAccount?.accountId) },
    })

    refetch({ orderingAuthenticationToken: state.user?.orderingAuthenticationToken })
    paymentInfoDialogProps.hide()
  }, [state.user?.orderingAuthenticationToken, selectedAccount, paymentInfoDialogProps])

  const handleAccountDetailsClick = useCallback(
    account => {
      setSelectedAccount(account)
      paymentInfoDialogProps.show()
    },
    [setSelectedAccount, paymentInfoDialogProps],
  )

  const handleAccountDetailsClose = useCallback(() => {
    setSelectedAccount(null)
    paymentInfoDialogProps.hide()
  }, [setSelectedAccount, paymentInfoDialogProps])

  // Loading state
  if (loading) {
    return (
      <>
        <HeaderContainer>{text('Profile.SavedPayments.NavBar.Title')}</HeaderContainer>
        <ContentContainer>
          <Paragraph>Loading...</Paragraph>
        </ContentContainer>
      </>
    )
  }

  // Error state
  if (error) {
    return (
      <>
        <HeaderContainer>{text('Profile.SavedPayments.NavBar.Title')}</HeaderContainer>
        <ContentContainer>
          <Paragraph>An error occurred while getting your information.</Paragraph>
        </ContentContainer>
      </>
    )
  }

  const guestBillingAccounts = data?.guestBillingAccounts

  // Empty state
  if (guestBillingAccounts?.length === 0) {
    return (
      <>
        <HeaderContainer>{text('Profile.SavedPayments.NavBar.Title')}</HeaderContainer>
        <ContentContainer>
          <Paragraph>You have no saved payments. Start an order to save a payment method at checkout.</Paragraph>
        </ContentContainer>
      </>
    )
  }

  return (
    <>
      <HeaderContainer>{text('Profile.SavedPayments.NavBar.Title')}</HeaderContainer>
      <ContentContainer>
        <Paragraph style={{ marginBottom: 27 }}>Edit or remove saved payment methods. Add a new payment method at checkout.</Paragraph>

        <FormContainer>
          {guestBillingAccounts?.map((account, idx) => (
            <PaymentInfoCard key={account.accountId} style={{ marginTop: idx > 0 ? 12 : 0 }}>
              <span>
                {`${account.cardType} ${account.cardSuffix}`}
                {account.isDefault && ' (default)'}
              </span>
              <Button variant="link" label="Details" style={{ flex: 0, padding: 0 }} onClick={() => handleAccountDetailsClick(account)} />
            </PaymentInfoCard>
          ))}
        </FormContainer>
      </ContentContainer>

      <ModalWithBackdrop dialogProps={paymentInfoDialogProps} ariaLabel="Payment details" visible={Boolean(selectedAccount)}>
        <ModalHeader onClose={handleAccountDetailsClose} title={`Details ${selectedAccount?.cardType} ${selectedAccount?.cardSuffix}`} />

        <ModalContent>
          <TextInput style={{ width: '100%' }} required placeholder="Card Number" value={`**** **** **** ${selectedAccount?.cardSuffix}`} readOnly />
          <TextInput style={{ width: '100%' }} required placeholder="Exp" value={selectedAccount?.expiration} readOnly />
          <TextInput style={{ width: '100%' }} required placeholder="Cvv" value="***" readOnly />

          <Button
            onClick={removePaymentAccount}
            loading={loadingRemove}
            disabled={loadingRemove}
            style={{ marginTop: 27 }}
            label="Remove"
            variant="primary"
          />
        </ModalContent>
      </ModalWithBackdrop>
    </>
  )
}

const ModalContent = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
`

const PaymentInfoCard = styled.div`
  display: flex;
  padding: 24px 16px;
  align-items: center;
  justify-content: space-between;

  border-radius: 3px;
  border: 1px solid #dfdede;

  span {
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.font.styles.regular};
  }
`
