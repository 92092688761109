import styled from 'styled-components'

export const Header = styled.div`
  display: flex;
  align-items: center;

  background-color: ${({ theme }) => theme.basket.topBackgroundColor};
  padding: 24px 20px;
`

export const CloseButtonContainer = styled.div`
  margin-right: 20px;
`

export const Heading = styled.p`
  ${({ theme }) => theme.font.styles.h1}
  color: ${({ theme }) => theme.basket.topTextColor};
  font-size: 26px;
`

export const Body = styled.div`
  padding: 20px;
  width: 100%;
`
export const SubTitle = styled.p`
  ${({ theme }) => theme.font.styles.labelInput}

  color: ${props => props.theme.colors.black.hex()};
  margin-top: 32px;
`

export const ActionsContainer = styled.div`
  margin-top: 56px;
`
