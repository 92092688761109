import React from 'react'

import { findMetadata } from '../../../../data/utils'
import { ModifierGroupBarButton, ModifierGroupBarContainer, ModifierGroupBarText } from './ModifierGroupBar.styles'

export const ModifierGroupBar = ({ onClick, modifiers, modifiersSummary }) => {
  return (
    <ModifierGroupBarContainer>
      {modifiers?.map((modifier, index) => (
        <ModifierGroupBarButton
          key={modifier.id}
          active={modifiersSummary[index].active}
          disabled={!modifiersSummary[index].enabled}
          onClick={() => onClick(modifier)}
        >
          <ModifierGroupBarText active={modifiersSummary[index].active} disabled={!modifiersSummary[index].enabled}>
            {findMetadata(modifier.metadata, 'APP_SHORT_NAME') || modifier.description}
          </ModifierGroupBarText>
        </ModifierGroupBarButton>
      ))}
    </ModifierGroupBarContainer>
  )
}
