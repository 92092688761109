import { useQuery } from '@apollo/client'
import moment from 'moment'
import { useCallback, useMemo } from 'react'

import { useSession } from '../contexts/session'
import { useCacheWithExpiration } from '../data/apollo'
import { GET_BRANDS } from '../data/content/queries'
import firebase from '../services/firebase'
import { useMessaging } from './useMessaging'

const byDisplayOrder = (a, b) => a.displayOrder - b.displayOrder

export const useBrands = () => {
  const { dispatch } = useSession()
  const { dispatchMessage } = useMessaging()

  const fetchPolicy = useCacheWithExpiration(moment.duration(5, 'minutes'), 'lastFetch_brands')

  const { loading, data } = useQuery(GET_BRANDS, {
    fetchPolicy,
    onError: useCallback(err => dispatchMessage.error('Could not load brands', err), [dispatchMessage]),
  })

  const selectBrand = useCallback(
    brand => {
      console.debug('[useBrands] selectBrand', brand)
      dispatch({ type: 'SET_SELECTED_BRAND', payload: brand?.code })
      firebase.analytics().logEvent('Menu_Selected', {
        menuType: brand.displayName,
      })
    },
    [dispatch],
  )

  const brands = useMemo(
    () =>
      data?.brands
        ?.map(brand => ({
          displayName: brand.brandName,
          displayOrder: brand.displayOrder,
          imageUrl: brand.image.url,
          key: `category-${brand.oloCategoryCode}`,
          code: brand.oloCategoryCode,
        }))
        ?.sort(byDisplayOrder),
    [data?.brands],
  )

  return { loading, brands, selectBrand }
}
