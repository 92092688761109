import { FaExclamationCircle } from 'react-icons/fa'
import { Box } from 'reakit/Box'
import { Button } from 'reakit/Button'
import styled, { css } from 'styled-components'

import { CloseButton } from '../../../../components/Button/Button'
import { isMobile } from '../../../../components/responsive/Responsive'
import { H3 } from '../../../../components/Text/Text.styles'

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`

export const ImageContainer = styled(Box)`
  position: relative;
  background: ${props => `url(${props.src}) no-repeat center center`};
  background-size: cover;
  min-height: 270px;
  width: 100%;
`

export const InfoContainer = styled(Box)`
  background: ${props => props.theme.colors.brand.white};
  color: ${props => props.theme.colors.brand.black};
  font-family: ${props => props.theme.font.styles.bold};

  /* Desktop, Large Desktop and Tablet */
  padding: 24px 32px;
  ${isMobile`
    padding: 24px 16px;
  `}
`

export const NameHeader = styled(H3)`
  margin-bottom: 12px;
`

export const MetaContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`

export const PriceText = styled.span`
  ${props => props.theme.font.styles.labelCurrencyLarge};
  color: ${props => props.theme.colors.brand.black.hex()};
  margin-right: 24px;
`

export const LoadingView = styled(Box)`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`

export const ControlContainer = styled(Box)`
  background: ${props => props.theme.colors.brand.white.hex()};
  height: 100%;
  overflow-y: auto;

  /* Desktop, Large Desktop and Tablet */
  padding: 0 32px 32px;
  ${isMobile`
    padding: 0 16px 24px;
  `}
`

export const ComboContainer = styled(Box)`
  display: flex;
  flex-direction: row;
`

export const ComboButton = styled(Button)`
  background: ${props => props.theme.colors.brand.white.hex()};
  border-radius: 3px;
  cursor: pointer;
  font-family: ${props => props.theme.font.styles.regular};
  text-transform: uppercase;
  width: 100%;

  /* Desktop, Large Desktop and Tablet */
  margin-right: 9px;
  ${isMobile`
    margin-right: 18px;
  `}
  :last-of-type {
    margin-right: 0;
  }

  /* Desktop, Large Desktop and Tablet */
  padding: 28px 0;
  ${isMobile`
    padding: 24px 0;
  `}

  &:hover {
    border: ${props => props.theme.toggles.styles.active.border};
  }

  ${props =>
    props.$isActive
      ? css`
          border: ${props.theme.toggles.styles.active.border};
        `
      : css`
          border: ${props.theme.toggles.styles.inactive.border};
        `}
`

export const SelectorContainer = styled(Box)`
  display: flex;
  flex-direction: column;

  /* Desktop, Large Desktop and Tablet */
  padding: 12px 0;
  ${isMobile`
    padding: 13.5px 0;
  `}

  > * {
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const ActionsContainer = styled(Box)`
  text-align: center;
  margin-top: auto;

  background-color: ${({ theme }) => theme.colors.brand.white.hex()};
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.08);
  z-index: 1;

  /* Desktop, Large Desktop and Tablet */
  padding: 20px 34px 32px;
  ${isMobile`
    padding: 16px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  `}
`

export const TotalText = styled.span`
  ${props => props.theme.font.styles.labelCurrency};
  color: ${props => props.theme.colors.black.hex()};
  display: block;
  margin-bottom: 24px;
`

export const DietaryInfoContainer = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
`

export const WarningIcon = styled(FaExclamationCircle).attrs(props => ({
  color: props.theme.colors.brand.black,
  size: 12,
}))`
  margin: 0 8px;
  :first-of-type {
    margin-left: 0;
  }
`

export const SpotlightButton = styled.button`
  position: absolute;
  right: 16px;
  bottom: -24px;

  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  z-index: 999;
`

export const SpotlightIcon = styled.img.attrs(({ theme }) => ({
  src: theme.menuItems.simpleBuilder?.spotlightIcon,
}))`
  height: 48px;
  width: 48px;

  object-fit: contain;
`

export const SpotlightContainer = styled.div`
  background: ${({ theme }) => `url(${theme.menuItems.simpleBuilder?.spotlightBg}) no-repeat center center`};
  background-size: cover;

  padding: 32px;
  width: 400px;

  ${isMobile`
    width: 100%;
  `}
`

export const SpotlightCloseButton = styled(CloseButton)``

export const SpotlightMeta = styled.div``

export const SpotlightTitle = styled.p`
  ${props => props.theme.font.styles.label};
  color: white;
  margin-top: 32px;
`

export const SpotlightHeading = styled.p`
  color: white;
  font-family: ${({ theme }) => theme.font.styles.spotlightHeading};
  font-size: 32px;
  font-weight: 800;
  margin-top: 8px;
`

export const SpotlightDescription = styled.p`
  ${props => props.theme.font.styles.label};
  color: white;
  font-size: 18px;
  font-weight: 400;
  margin-top: 8px;
  text-transform: none;
`
