import { useMutation } from '@apollo/client'
import { useCallback } from 'react'

import { HANDOFF_MODES } from '../constants/handoffModes'
import { useText } from '../content'
import { useSession } from '../contexts/session'
import { CREATE_BASKET_FROM_PREVIOUS_ORDER } from '../data/basket/queries'
import { useMessaging } from './useMessaging'

export const useCreateBasketFromOrder = () => {
  const { text } = useText()
  const { state, dispatch } = useSession()
  const { dispatchMessage } = useMessaging()

  const displayError = useCallback(
    error =>
      dispatchMessage({
        err: error,
        type: 'error',
        payload: { message: text('Profile.OrderHistory.Errors.CreateBasket'), error },
      }),
    [dispatchMessage, text],
  )

  const [createBasketFromPreviousOrder, { loading }] = useMutation(CREATE_BASKET_FROM_PREVIOUS_ORDER, {
    onError: displayError,
  })

  const handleAddToBag = useCallback(
    async (order, restaurants) => {
      try {
        const {
          data: { createBasketFromPreviousOrder: updatedBasket },
        } = await createBasketFromPreviousOrder({ variables: { orderId: order?.id } })

        if (updatedBasket) {
          if (state?.basket?.id) {
            dispatch({ type: 'SET_BASKET', payload: null })
          }

          const foundRestaurant = restaurants.find(rest => rest?.id === order?.vendorId)
          dispatch({ type: 'SET_RESTAURANT', payload: foundRestaurant })

          const handoffMode = order?.deliveryMode === 'pickup' ? HANDOFF_MODES.PICKUP : HANDOFF_MODES.DELIVERY

          dispatch({ type: 'SET_HANDOFF_MODE', payload: handoffMode })
          dispatch({ type: 'SET_BASKET', payload: updatedBasket })
          dispatchMessage({ type: 'info', payload: { message: 'Items added to bag.' } })
        }
      } catch (e) {
        console.log(e)
      }
    },
    [dispatch, state?.basket?.id, createBasketFromPreviousOrder, dispatchMessage],
  )

  return { loading, addToBag: handleAddToBag }
}
