import './littlebeet.css'

import Color from 'color'
import { merge } from 'lodash'
import { css } from 'styled-components'

import { isDesktop, isLargeDesktop, isMobile, isTablet } from '../components/responsive/Responsive'
import { theme as defaultTheme } from './default'

const brandFonts = {
  SofiaProBold: 'Sofia Pro Bold',
  SofiaProMedium: 'Sofia Pro Medium',
  SofiaProRegular: 'Sofia Pro Regular',
  SofiaProSemiBold: 'Sofia Pro Semi Bold',
  VesterbroExtrabold: 'Vesterbro Extrabold',
}

const images = {
  checkoutRewardsSectionCurrencyIcon: require('../assets/images/littlebeet/tlb-rewards-checkout-currency-icon.png'),
  checkoutRewardsSectionButtonIcon: require('../assets/images/littlebeet/tlb-rewards-checkout-icon.svg'),
  rewardHeroBkg: require('../assets/images/littlebeet/rewards-hero-bkg.png'),
  spotlightIcon: require('../assets/images/littlebeet/littlebeet-spotlight-icon.png'),
  spotlightBg: require('../assets/images/littlebeet/littlebeet-spotlight-bg.png'),
}

const icons = {
  accountWhiteIcon: require('../assets/icons/littlebeet/account.svg'),
  mapMarkerIcon: require('../assets/icons/littlebeet/map_marker.svg'),
  myBagWhiteIcon: require('../assets/icons/littlebeet/my_bag.svg'),
  settings: require('../assets/icons/littlebeet/settings.svg'),
  size: {
    sm: 16,
    md: 24,
  },
}

const white = Color('#FFFFFF')
const black = Color('#0A0D0D') // Almost black
const gray = Color('#A2A8B0')
const orange = Color('#FF9E18')
const yellow = Color('#FCCC31')
const blue = Color('#77BCFD')
const red = Color('#A8123E')
const cream = Color('#F2EEE2')
const darkGreen = Color('#19322F')

const brandColors = {
  cream,
  darkGreen,
  green80: Color('#08403E'),
  white,
  black,
  gray10: Color('#F4F4F4'),
  gray20: Color('#ECECEC'),
  gray30: Color('#D4D7DB'),
  gray40: Color(gray).lighten(0.1),
  gray50: Color(gray),
  gray60: Color(gray).darken(0.1),
  gray70: Color('#5C6167'),
  gray80: Color(gray).darken(0.3),
  gray90: Color('#2B2929'),
  gray100: Color(gray).darken(0.5),
  orange10: Color(orange).lighten(0.4),
  orange20: Color(orange).lighten(0.3),
  orange30: Color(orange).lighten(0.2),
  orange40: Color(orange).lighten(0.1),
  orange50: Color(orange),
  orange60: Color(orange).darken(0.1),
  orange70: Color(orange).darken(0.2),
  orange80: Color(orange).darken(0.3),
  orange90: Color(orange).darken(0.4),
  red10: Color(red).lighten(0.4),
  red20: Color(red).lighten(0.3),
  red30: Color(red).lighten(0.2),
  red40: Color(red).lighten(0.1),
  red50: Color(red),
  red60: Color(red).darken(0.1),
  red70: Color(red).darken(0.2),
  red80: Color(red).darken(0.3),
  red90: Color(red).darken(0.4),
  yellow10: Color(yellow).lighten(0.4),
  yellow20: Color(yellow).lighten(0.3),
  yellow30: Color(yellow).lighten(0.2),
  yellow40: Color(yellow).lighten(0.1),
  yellow50: Color(yellow),
  yellow60: Color(yellow).darken(0.1),
  yellow70: Color(yellow).darken(0.2),
  yellow80: Color(yellow).darken(0.3),
  yellow90: Color(yellow).darken(0.4),
  blue10: Color(blue).lighten(0.4),
  blue20: Color(blue).lighten(0.3),
  blue30: Color(blue).lighten(0.2),
  blue40: Color(blue).lighten(0.1),
  blue50: Color(blue),
  blue60: Color(blue).darken(0.1),
  blue70: Color(blue).darken(0.2),
  blue80: Color(blue).darken(0.3),
  blue90: Color(blue).darken(0.4),
}

const primary = brandColors.red50
const secondary = brandColors.white

const brand = 'littlebeet'

const colors = {
  brand: brandColors,
  gray,
  orange,
  yellow,
  blue,
  red,
  black: Color('#000000'),
  white,
  primary,
  secondary,
  backgroundRequiredInput: brandColors.gray10,
}

const mapOverlay = {
  activeMarker: {
    fillColor: primary.hex(),
    strokeColor: primary.hex(),
    fillOpacity: 1,
    scale: 2,
    path:
      'M5.8752 16C5.71718 15.9251 5.58472 15.8042 5.49467 15.6528C4.02783 13.5488 2.54994 11.4528 1.09099 9.34241C0.535343 8.5325 0.198899 7.58916 0.115198 6.60642C-0.131118 4.04642 1.23467 1.58403 3.55099 0.568027C5.79941 -0.419171 7.92309 -0.118372 9.82415 1.46083C10.3974 1.93309 10.873 2.51541 11.2236 3.17438C11.5742 3.83334 11.793 4.55598 11.8673 5.30082C12.0563 6.79713 11.6695 8.30973 10.7873 9.52481C9.3673 11.5515 7.94625 13.5819 6.52415 15.616C6.4317 15.7826 6.29121 15.9166 6.12151 16H5.8752ZM7.9673 6.02242C7.96881 5.62859 7.85535 5.24311 7.64121 4.91446C7.42706 4.58581 7.12179 4.32867 6.7638 4.17538C6.4058 4.02209 6.01107 3.97949 5.62925 4.05295C5.24742 4.1264 4.89556 4.31263 4.61792 4.58821C4.34027 4.86379 4.14925 5.21642 4.06887 5.60173C3.98849 5.98704 4.02235 6.38783 4.16618 6.7537C4.31002 7.11956 4.5574 7.43415 4.87723 7.65791C5.19705 7.88166 5.57503 8.00458 5.96362 8.01121C6.22417 8.01438 6.48277 7.96541 6.7246 7.86709C6.96643 7.76878 7.18674 7.62307 7.37288 7.43831C7.55903 7.25354 7.70736 7.03337 7.80936 6.7904C7.91136 6.54743 7.96504 6.28645 7.9673 6.02242Z',
  },
  inactiveMarker: {
    fillColor: brandColors.black.hex(),
    fillOpacity: 1,
    scale: 1.5,
    path:
      'M5.8752 16C5.71718 15.9251 5.58472 15.8042 5.49467 15.6528C4.02783 13.5488 2.54994 11.4528 1.09099 9.34241C0.535343 8.5325 0.198899 7.58916 0.115198 6.60642C-0.131118 4.04642 1.23467 1.58403 3.55099 0.568027C5.79941 -0.419171 7.92309 -0.118372 9.82415 1.46083C10.3974 1.93309 10.873 2.51541 11.2236 3.17438C11.5742 3.83334 11.793 4.55598 11.8673 5.30082C12.0563 6.79713 11.6695 8.30973 10.7873 9.52481C9.3673 11.5515 7.94625 13.5819 6.52415 15.616C6.4317 15.7826 6.29121 15.9166 6.12151 16H5.8752ZM7.9673 6.02242C7.96881 5.62859 7.85535 5.24311 7.64121 4.91446C7.42706 4.58581 7.12179 4.32867 6.7638 4.17538C6.4058 4.02209 6.01107 3.97949 5.62925 4.05295C5.24742 4.1264 4.89556 4.31263 4.61792 4.58821C4.34027 4.86379 4.14925 5.21642 4.06887 5.60173C3.98849 5.98704 4.02235 6.38783 4.16618 6.7537C4.31002 7.11956 4.5574 7.43415 4.87723 7.65791C5.19705 7.88166 5.57503 8.00458 5.96362 8.01121C6.22417 8.01438 6.48277 7.96541 6.7246 7.86709C6.96643 7.76878 7.18674 7.62307 7.37288 7.43831C7.55903 7.25354 7.70736 7.03337 7.80936 6.7904C7.91136 6.54743 7.96504 6.28645 7.9673 6.02242Z',
  },
}

const borderRadii = {
  xs: '1px',
  sm: '3px',
  round: '100px',
}

const borderSizes = {
  xs: '1px',
  sm: '1.5px',
  md: '3px',
  lg: '5px',
}

const borders = {
  dashed: `${borderSizes.sm} dashed ${brandColors.gray50.hex()}`,
  light: `${borderSizes.sm} solid ${brandColors.gray30.hex()}`,
  lighter: `${borderSizes.sm} solid ${brandColors.gray10.hex()}`,
  lightHighlight: `${borderSizes.sm} solid ${brandColors.black.hex()}`,
  normal: `${borderSizes.xs} solid ${brandColors.black.hex()}`,
  white: `${borderSizes.sm} solid ${brandColors.white.hex()}`,
}

const primaryHover = Color('#921036') // This one is missing from color palette!

const fontSizes = {
  xs: '14px',
  sm: '16px',
  md: '18px',
  lg: '20px',
  xl: '24px',
  xxl: '32px',
}

const font = {
  styles: {
    spotlightHeading: brandFonts.VesterbroExtrabold,
    regular: brandFonts.SofiaProRegular,
    bold: brandFonts.SofiaProBold,
    numbers: brandFonts.SofiaProRegular,
    h1: css`
      font-family: ${brandFonts.SofiaProBold};
      font-size: ${fontSizes.xxl};
      font-weight: 800;
      letter-spacing: 0.01em;
      line-height: 125%;
    `,
    h2: css`
      font-family: ${brandFonts.SofiaProBold};
      font-size: ${fontSizes.xl};
    `,
    h3: css`
      font-family: ${brandFonts.SofiaProBold};
      font-size: ${fontSizes.lg};
    `,
    description: css`
      font-family: ${brandFonts.SofiaProRegular};
      font-size: ${fontSizes.sm};
      font-weight: 500;
      line-height: 130%;
    `,
    descriptionExtraSmall: css`
      font-family: ${brandFonts.SofiaProRegular};
      font-size: ${fontSizes.xs};
      font-weight: 500;
      line-height: 143%;
      letter-spacing: 0.02em;
    `,
    descriptionLarge: css`
      font-family: ${brandFonts.SofiaProRegular};
      font-size: ${fontSizes.lg};
      font-weight: 800;
      line-height: 120%;
      letter-spacing: 0.02em;
    `,
    cta: css`
      font-family: ${brandFonts.SofiaProBold};
      font-size: ${fontSizes.sm};
      font-weight: bold;
      letter-spacing: 0.06em;
      line-height: 125%;
      text-transform: uppercase;
    `,
    ctaSmall: css`
      font-family: ${brandFonts.SofiaProBold};
      font-size: ${fontSizes.xs};
      font-weight: bold;
      letter-spacing: 0.06em;
      line-height: 143%;
      text-transform: uppercase;
    `,
    label: css`
      font-family: ${brandFonts.SofiaProBold};
      font-size: ${fontSizes.sm};
      font-weight: bold;
      letter-spacing: 0.06em;
      line-height: 150%;
      text-transform: uppercase;
    `,
    labelSpecial: css`
      font-family: ${brandFonts.SofiaProBold};
      font-size: ${fontSizes.xs};
      font-weight: bold;
      letter-spacing: 0.06em;
      line-height: 143%;
      text-transform: uppercase;
    `,
    labelExtraSmall: css`
      font-family: ${brandFonts.SofiaProBold};
      font-size: ${fontSizes.xs};
      font-weight: 800;
      letter-spacing: 0.08em;
      line-height: 114%;
      text-transform: uppercase;
    `,
    labelSmall: css`
      font-family: ${brandFonts.SofiaProBold};
      font-size: ${fontSizes.sm};
      font-weight: 800;
      letter-spacing: 0.08em;
      line-height: 150%;
      /* text-transform: uppercase; */
    `,
    labelMedium: css`
      font-family: ${brandFonts.SofiaProBold};
      font-size: ${fontSizes.md};
      font-weight: bold;
      letter-spacing: 0.06em;
      line-height: 111%;
      text-transform: uppercase;
    `,
    inputDefault: css`
      font-family: ${brandFonts.SofiaProRegular};
      font-size: ${fontSizes.sm};
      font-weight: 500;
      letter-spacing: 0.02em;
      line-height: 125%;
    `,
    inputExtraSmall: css`
      font-family: ${brandFonts.SofiaProRegular};
      font-size: ${fontSizes.sm};
      font-weight: 600;
      letter-spacing: 0.02em;
      line-height: 125%;
    `,
    labelInput: css`
      font-family: ${brandFonts.SofiaProRegular};
      font-size: ${fontSizes.sm};
    `,
    labelInputBold: css`
      font-family: ${brandFonts.SofiaProRegular};
      font-size: ${fontSizes.sm};
      font-weight: 600;
      letter-spacing: 0.02em;
      line-height: 150%;
    `,
    labelInputSmall: css`
      font-family: ${brandFonts.SofiaProBold};
      font-size: ${fontSizes.sm};
    `,
    labelInputExtraSmall: css`
      font-family: ${brandFonts.SofiaProRegular};
      font-size: ${fontSizes.xxs};
      font-weight: 600;
      letter-spacing: 0.02em;
      line-height: 133%;
    `,
    labelInputError: css`
      color: ${primary.hex()};
      font-family: ${brandFonts.SofiaProRegular};
      font-size: ${fontSizes.xxs};
    `,
    labelCurrency: css`
      font-family: ${brandFonts.SofiaProRegular};
      font-size: ${fontSizes.sm};
      font-weight: 600;
      line-height: 120%;
    `,
    labelCurrencySmall: css`
      color: ${brandColors.gray70};
      font-family: ${brandFonts.SofiaProRegular};
      font-size: ${fontSizes.xxs};
    `,
    labelCurrencyLarge: css`
      color: ${brandColors.gray70};
      font-family: ${brandFonts.SofiaProRegular};
      font-size: ${fontSizes.lg};
      font-weight: 800;
      letter-spacing: 0.02em;
      line-height: 120%;
    `,
  },
  sizes: fontSizes,
}

const buttons = {
  buttonContainer: {
    primary: css`
      background-color: ${primary.hex()};
      border: ${borderSizes.xs} solid ${primary.hex()};
      border-radius: ${borderRadii.sm};
      color: ${brandColors.white.hex()};
      &:hover {
        background-color: ${primaryHover.hex()};
        border-color: ${primaryHover.hex()};
      }
    `,
    secondary: css`
      background-color: ${secondary.hex()};
      border: ${borderSizes.xs} solid ${brandColors.black.hex()};
      border-radius: ${borderRadii.sm};
      color: ${brandColors.black.hex()};
      &:hover {
        background-color: ${brandColors.black.hex()};
        color: ${brandColors.white.hex()};
      }
    `,
  },
  roundContainer: {
    primary: css`
      background-color: ${primary.hex()};
      border: ${borderSizes.xs} solid ${primary.hex()};
      color: ${brandColors.white.hex()};
      &:hover {
        background-color: ${primaryHover.hex()};
        border-color: ${primaryHover.hex()};
      }
    `,
    secondary: css`
      background-color: ${brandColors.white.hex()};
      border: ${borderSizes.xs} solid ${brandColors.gray20.hex()};
      color: ${brandColors.black.hex()};
      &:hover {
        background-color: ${brandColors.black.hex()};
        border-color: ${brandColors.black.hex()};
        color: ${brandColors.white.hex()};
      }
    `,
  },
  small: css`
    ${font.styles.ctaSmall};
    padding: 10px 16px;
  `,
  modalHeaderCloseButtonVariant: 'secondary',
}

const changeAddressChip = {
  outerContainerStyle: css`
    height: 100%;
    &:before {
      content: '';
      border: ${props => `1px solid  ${props.theme.colors.brand.black.hex()}`};
      height: 100%;
      margin: 0 26px;
    }
  `,
  innerContainerStyle: css`
    background: ${primary.hex()};
    border-radius: ${borderRadii.round};
    color: ${brandColors.white.hex()};
    font-family: ${brandFonts.SofiaProRegular};
    font-size: ${font.sizes.xs};
    font-style: bold;
    letter-spacing: 0.06em;
    line-height: ${font.sizes.lg};
    text-transform: uppercase;
    &:hover {
      background-color: ${primaryHover.hex()};
      border: ${primaryHover.hex()};
      color: ${brandColors.white.hex()};
    }
  `,
}

const header = {
  styles: {
    backgroundColor: brandColors.white,
    linkColor: brandColors.black,
  },
}

const home = {
  heroAsset: 'HomeHero',
  sectionHeaderBackground: brandColors.green80.hex(),
  styles: {
    headerText: css`
      ${font.styles.description}
      color: ${brandColors.white}
    `,
    sectionH1: css`
      color: ${brandColors.white};
      text-transform: none;

      ${isMobile`
        font-size: ${fontSizes.xl};
        line-height: 28px;
      `}
      ${isTablet`
        font-size: ${fontSizes.xl};
        line-height: 28px;
      `}
      ${isDesktop`
        font-size: ${fontSizes.xxl};
        line-height: 40px;
      `}
      ${isLargeDesktop`
        font-size: ${fontSizes.xxl};
        line-height: 40px;
      `}
    `,
    sectionHeaderContainer: css`
      ${isMobile`
        padding: 36px 16px;
      `}
      ${isTablet`
        padding: 36px 24px;
      `}
      ${isDesktop`
        padding: 48px;
      `}
      ${isLargeDesktop`
        padding: 48px;
      `}
    `,
    sectionBody: css`
      ${isMobile`
        padding: 36px 16px;
      `}
      ${isTablet`
        padding: 36px 24px;
      `}
      ${isDesktop`
        padding: 48px;
      `}
      ${isLargeDesktop`
        padding: 48px;
      `}
    `,
    viewLocationsButton: css`
      font-family: ${brandFonts.SofiaProRegular};
      font-size: ${fontSizes.sm};
      font-weight: 600;
      line-height: 130%;
      text-decoration: underline;
      text-transform: unset;
    `,
  },
}

const toggles = {
  active: {
    border: 'none',
    backgroundColor: colors.primary.hex(),
    textColor: brandColors.white.hex(),
  },
  inactive: {
    border: `1px solid ${brandColors.gray30.hex()}`,
    backgroundColor: brandColors.white.hex(),
    textColor: colors.primary.hex(),
  },
  styles: {
    active: css`
      background-color: ${colors.primary.hex()};
      color: ${brandColors.white.hex()};
      border: none;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    `,
    inactive: css`
      background-color: ${brandColors.white.hex()};
      color: ${colors.primary.hex()};
      border: none;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    `,
  },
}

const items = {
  selected: {
    background: brandColors.gray10.hex(),
  },
  highlighted: {
    background: brandColors.gray20.hex(),
  },
  hover: {
    background: brandColors.gray20.hex(),
  },
}

const toast = css`
  .Toastify__toast-body {
    display: flex;
    justify-content: center;

    font-family: ${brandFonts.SofiaProMedium};
    font-style: normal;
    font-weight: normal;
    font-size: ${fontSizes.xs};
    line-height: 130%;
  }

  .Toastify__close-button {
    display: flex;
    margin: auto 0;
  }

  .Toastify__toast--info {
    background-color: ${brandColors.cream};
    color: ${brandColors.darkGreen};
  }
  .Toastify__toast--info > .Toastify__close-button {
    color: ${brandColors.darkGreen};
  }

  .Toastify__toast--error {
    background: ${brandColors.red50};
    color: ${brandColors.cream};
  }
  .Toastify__toast--error > .Toastify__close-button {
    color: ${brandColors.cream};
  }
`
const baseCheckoutFrameStyleCSS = `
   border-bottom-style: none;
   border-left-style: none;
   border-radius: unset;
   border-right-style: none;
   border-top-style: none;
   padding: 24px 16px;
   font-size: 17px;
   font-weight: normal;
   width: 100%;
 `

const checkoutFrame = {
  styles: {
    cardNumber: baseCheckoutFrameStyleCSS,
    cvv: baseCheckoutFrameStyleCSS,
  },
}

export const theme = merge({}, defaultTheme, {
  borderRadii,
  borders,
  borderSizes,
  brand,
  buttons,
  changeAddressChip,
  colors,
  checkoutFrame,
  font,
  header,
  home,
  icons,
  items,
  mapOverlay,
  toast,
  toggles,
  profile: {
    header: css`
      color: ${black};
      background-color: transparent;
      margin-bottom: 27px;
    `,
    navBar: {
      container: css`
        border: none;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
      `,
      navItem: css`
        font-weight: bold;
      `,
      textColor: black,
      selectedItemTextDecoration: 'none',
      selectedItemTextColor: primary,
      selectedItemBackgroundColor: 'transparent',
    },
    orderHistory: {
      topBackgroundColor: brandColors.white,
    },
    dietaryRestrictions: {
      topBackgroundColor: brandColors.white,
    },
    contactInfo: {
      topBackgroundColor: brandColors.white,
    },
  },
  categoryLanding: {
    bodyPadding: '16px',
    itemMargin: '5px',
    displayMeta: false,
    displayTriangles: false,
    imageWidth: '80%',
    imageHeight: '60%',
    imageResizeMode: 'contain',
    border: `2px solid ${Color(black).hex()}`,
    borderRadius: '3px',
    tileBackgroundColor: brandColors.gray10,
  },
  menuContext: {
    backgroundColor: Color(black),
    iconBackgroundColor: white,
    iconColor: brandColors.gray90,
  },
  handoffSelector: {
    backgroundColor: Color(black),
    color: Color(white),
    border: 'none',
    fontFamily: brandFonts.SofiaProBold,
    textTransform: 'uppercase',
    secondary: {
      backgroundColor: Color(white),
      color: Color(black),
      border: 'none',
      fontFamily: brandFonts.SofiaProBold,
      textTransform: 'uppercase',
    },
  },
  menuCategoryBar: css`
    background-color: ${brandColors.white.hex()};
    box-shadow: 0px 2px 8px ${colors.black.alpha(0.1)};
  `,
  menuCategoryBarButton: css`
    color: ${brandColors.black.hex()};
    font-family: ${brandFonts.SofiaProRegular};
    font-size: ${fontSizes.md};
    font-weight: normal;
    letter-spacing: 0.08em;
    line-height: 125%;
    transition: all 0.2s ease;
    &:hover {
      color: ${primaryHover.hex()};
      font-weight: bold;
    }
  `,
  menuCategoryBarButtonSelected: css`
    color: ${brandColors.red60.hex()};
    font-weight: bold;
  `,
  menuItems: {
    controlContainerStyle: css``,
    dietaryRestrictions: {
      warningColor: brandColors.orange50,
    },
    totalMetaStyle: css`
      font-family: ${brandFonts.SofiaProRegular};
      font-size: ${fontSizes.lg};
      font-weight: bold;
      line-height: 130%;
      letter-spacing: 0.06em;
    `,
    totalTextStyle: css`
      font-family: ${brandFonts.SofiaProBold};
      font-size: ${fontSizes.sm};
      font-weight: 600;
      line-height: 120%;
    `,
    simpleBuilder: {
      modifierTextColor: primary.hex(),
      infoContainerStyle: css`
        /*border-top: ${borderSizes.lg} solid ${brandColors.black.hex()};*/
        color: ${brandColors.black.hex()};
        /* Desktop, Large Desktop and Tablet */
        padding: 0 0 24px 0;
        /* ${isMobile`
          padding: 24px 16px;
        `} */
      `,
      spotlightIcon: images.spotlightIcon,
      spotlightBg: images.spotlightBg,
    },
    cards: {
      style: css`
        background: ${brandColors.white.hex()};
        border: 1px solid ${brandColors.gray20.hex()};
        box-shadow: 0px 1px 6px ${colors.black.alpha(0.08)};
        border-radius: ${borderRadii.xs};
        &:hover {
          border-color: ${props => props.theme.colors.primary.hex()};
        }
      `,
      descriptionHeaderStyle: css`
        color: ${brandColors.gray90};
        font-family: ${brandFonts.SofiaProRegular};
        font-size: 24px;
        font-style: normal;
        font-weight: bold;
        letter-spacing: 0.02em;
        line-height: 28px;
        ${isTablet`
          font-size: 20px;
          line-height: 28px;
        `}
        ${isMobile`
          font-size: 20px;
          line-height: 28px;
        `}
      `,
      descriptionTextStyle: css`
        font-family: ${brandFonts.SofiaProRegular};
        font-weight: normal;
        font-size: 14px;
        line-height: 130%;
        color: ${brandColors.gray70.hex()};
        font-weight: 500;
      `,
      imageHeight: '220px',
      imageResizeMode: 'cover',
      menuHeaderStyle: css`
        font-family: ${brandFonts.SofiaProRegular};
        font-style: normal;
        font-weight: bold;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        border-bottom: ${borderSizes.md} solid #ad0d5a;
        ${isLargeDesktop`
          font-size: 36px;
          line-height: 42px;
        `};
        ${isDesktop`
          font-size: 36px;
          line-height: 42px;
        `};
        ${isTablet`
          font-size: 24px;
          line-height: 28px;
        `};
        ${isMobile`
          font-size: 20px;
          line-height: 24px;
        `};
      `,
      numberTextStyle: css`
        color: ${brandColors.gray70.hex()};
        font-family: ${brandFonts.SofiaProMedium};
        font-size: ${fontSizes.xs};
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0.02em;
        line-height: 130%;
      `,
    },
    customBuilder: {
      asideMetaContainer: css`
        background-color: ${darkGreen.hex()};

        h1,
        * {
          color: white;
          font-weight: 700;
          font-family: ${brandFonts.SofiaProRegular};
        }
      `,
      asideBodyContainer: css`
        border: none;
      `,
      modifierGroupsContainer: css`
        background-color: ${brandColors.white.hex()};
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
      `,
      modifierGroups: {
        active: {
          backgroundColor: 'transparent',
          textColor: brandColors.red40.hex(),
          border: 'none',
          borderRadius: borderRadii.round,
          textTransform: 'uppercase',
          padding: '12px 32px',
          margin: '0 32px 0 0',
        },
        inactive: {
          backgroundColor: 'transparent',
          textColor: brandColors.black.hex(),
          textColorDisabled: brandColors.gray70.hex(),
          border: 'none',
          borderRadius: '0',
          textTransform: 'uppercase',
          padding: '12px 32px',
          margin: '0 32px 0 0',
        },
      },
      optionCards: {
        border: `1px solid ${brandColors.gray30.hex()}`,
        borderActive: `1px solid ${brandColors.black.hex()}`,
        quantityButtonBorder: `1px solid ${brandColors.black.hex()}`,
        quantityButtonBorderDisabled: `1px solid ${brandColors.gray30.hex()}`,
        quantityButtonColor: brandColors.black.hex(),
        quantityButtonColorDisabled: brandColors.gray30.hex(),
      },
    },
  },
  basket: {
    header: css`
      background-color: ${brandColors.white};
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    `,
    topBackgroundColor: brandColors.white,
    topTextColor: black,
    backButtonVariant: 'secondary',
    bagItemsCount: css`
      color: ${brandColors.white};
      font-family: ${brandFonts.SofiaProBold};
      font-size: 8px;
      font-weight: normal;
      letter-spacing: 0.08em;
      line-height: 200%;
      text-transform: uppercase;
    `,
    basketCardItemName: css`
      ${font.styles.labelInput};
      font-weight: 800;
    `,
    summaryContainer: css`
      background: ${brandColors.gray10};
    `,
    costItemLabel: css`
      border: 0;
    `,
    costsNumberSubtotal: css`
      font-family: ${font.styles.numbers};
    `,
    costsLabel: css`
      ${font.styles.labelSmall};
      font-size: 16px;
      text-transform: uppercase;
    `,
    costsNumber: css`
      font-family: ${font.styles.numbers};
      font-size: 16px;
      font-weight: 800;
    `,
    upsellSection: css`
      background-color: transparent;
      margin: 16px 0 0;
      padding: 32px;
    `,
    upsellCarousel: css`
      background-color: 'transparent';
    `,
    upsellTitle: css`
      ${font.styles.label};
    `,
    upsellCarouselCard: css`
      border-radius: 3px;
      background-color: ${brandColors.gray10};
      border: 1px solid ${brandColors.gray30};
    `,
    upsellCarouselCardItemName: css`
      ${font.styles.labelInput};
    `,
  },
  checkout: {
    statusBarStyle: 'light-content',
    topBackgroundColor: brandColors.green80,
    topTextColor: brandColors.black.hex(),
    backButtonVariant: 'secondary',
    sectionLabel: css`
      ${font.styles.description};
      font-weight: 700;
      margin: 48px 0 14px 0;
      text-transform: uppercase;
    `,
    summary: {
      productName: css`
        ${font.styles.description};
        font-weight: 700;
      `,
      costsLabel: css`
        ${font.styles.description};
        font-weight: 700;
      `,
      costsNumber: css`
        ${font.styles.description};
        font-weight: 700;
      `,
    },
    rewards: {
      currencyButtonIcon: images.checkoutRewardsSectionCurrencyIcon,
      sectionButtonIcon: images.checkoutRewardsSectionButtonIcon,
    },
  },
  modals: {
    header: css`
      background-color: ${brandColors.white};
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
      padding: 24px 20px;
    `,
  },
  rewards: {
    background: css`
      background: ${`url(${images.rewardHeroBkg}) no-repeat center center`};
      background-size: cover;
      height: 600px;
      width: 100%;
    `,
  },
})
