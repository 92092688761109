import { useQuery } from '@apollo/client'
import { useCallback, useMemo } from 'react'

import { useText } from '../content'
import { useSession } from '../contexts/session'
import { GET_MENU } from '../data/menu/queries'
import { useMessaging } from './useMessaging'

export const useMenuItems = ({ allowHidden = false } = {}) => {
  const {
    state: { restaurant },
  } = useSession()

  const { dispatchMessage } = useMessaging()
  const { text } = useText()

  const { data, loading: loadingItems } = useQuery(GET_MENU, {
    skip: !restaurant?.id,
    fetchPolicy: 'network-only',
    variables: { restaurantId: restaurant?.id },
    onError: useCallback(error => dispatchMessage.error(text('CategoryLanding.Errors.LoadMenu'), error), [text, dispatchMessage]),
  })

  const items = useMemo(() => {
    if (allowHidden) return data
    return {
      ...data,
      menu: data?.menu?.filter(category => !category?.metadata?.some(metadata => metadata?.key === 'APP_HIDDEN')) || null,
    }
  }, [data])

  const availableProductIds = useMemo(() => items?.menu?.reduce((ids, category) => ids.concat(category.products.map(p => p.id)), []) || [], [items])

  return { items, loadingItems, availableProductIds }
}
