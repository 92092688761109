import { useApolloClient } from '@apollo/client'
import { uniq } from 'lodash'
import { useCallback, useEffect, useState } from 'react'

import { useText } from '../content'
import { GET_NEAREST_RESTAURANT_FOR_DELIVERY } from '../data/restaurants/queries'
import { useMessaging } from './useMessaging'

export const useSearchForDeliveryRestaurant = () => {
  const { text } = useText()
  const apolloClient = useApolloClient()
  const { dispatchMessage } = useMessaging()
  const [searchResults, setSearchResults] = useState()
  const [validatedAddress, setValidatedAddress] = useState()
  const [messages, setMessages] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    console.debug('[useSearchForDeliveryRestaurant]', { validatedAddress, searchResults, messages, loading })
  }, [validatedAddress, searchResults, messages, loading])

  const searchByAddress = useCallback(
    async (street, city) => {
      setLoading(true)

      try {
        const restaurantsFindForDelivery = await apolloClient
          .query({
            fetchPolicy: 'network-only',
            query: GET_NEAREST_RESTAURANT_FOR_DELIVERY,
            variables: { street, city },
          })
          .then(({ data }) => data.restaurantsFindForDelivery)

        const deliveryAddress = restaurantsFindForDelivery.deliveryAddress
        const restaurants = restaurantsFindForDelivery.restaurants || []

        setValidatedAddress(deliveryAddress)
        setMessages(uniq(restaurants.map(r => r?.deliverySupport?.message)))

        const eligibleRestaurants = restaurants.filter(r => r?.deliverySupport?.canDeliver)
        setSearchResults(eligibleRestaurants)

        return eligibleRestaurants
      } catch (error) {
        console.log(error)
        dispatchMessage.error(error.message || text('StartOrder.Delivery.Errors.RestaurantSearch'), error)
      } finally {
        setLoading(false)
      }
    },
    [apolloClient, dispatchMessage, text],
  )

  return { searchByAddress, validatedAddress, searchResults, loading, messages }
}
