import { Box } from 'reakit/Box'
import styled from 'styled-components'

export const AbsoluteBgImageContainer = styled(Box)`
  background-image: ${props => `url(${props.imageUrl})`};
  background-position: center;
  background-size: cover;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`
