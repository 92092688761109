import React, { useMemo } from 'react'
import { useTheme } from 'styled-components'

import { BodyScrollLockDialogWithBackdrop } from './BodyScrollLockDialogWithBackdrop/BodyScrollLockDialogWithBackdrop'
import { DefaultDialogWithBackdrop as Default } from './DefaultDialogWithBackdrop/DefaultDialogWithBackdrop'

const componentsFactory = {
  lpq: BodyScrollLockDialogWithBackdrop,
}

export const DialogWithBackdrop = props => {
  const theme = useTheme()
  const Component = useMemo(() => componentsFactory[theme.brand] || Default, [theme.brand])
  return <Component {...props} />
}
