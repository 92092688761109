import styled from 'styled-components'

export const H1 = styled.h1`
  ${({ theme }) => theme.font.styles.h1};
`

export const H2 = styled.h2`
  ${({ theme }) => theme.font.styles.h2};
`

export const H3 = styled.h3`
  ${({ theme }) => theme.font.styles.h3};
`

export const H4 = styled.h4`
  ${({ theme }) => theme.font.styles.h4};
`

export const Description = styled.span`
  ${({ theme }) => theme.font.styles.description};
`

export const DescriptionSmall = styled.span`
  ${({ theme }) => theme.font.styles.descriptionSmall};
`

export const DescriptionExtraSmall = styled.span`
  ${({ theme }) => theme.font.styles.descriptionExtraSmall};
`

export const DescriptionLarge = styled.span`
  ${({ theme }) => theme.font.styles.descriptionLarge};
`

export const Cta = styled.span`
  ${({ theme }) => theme.font.styles.cta};
`

export const CtaSmall = styled.span`
  ${({ theme }) => theme.font.styles.ctaSmall};
`

export const Label = styled.span`
  ${({ theme }) => theme.font.styles.label};
`

export const LabelSpecial = styled.span`
  ${({ theme }) => theme.font.styles.labelSpecial};
`

export const LabelExtraSmall = styled.span`
  ${({ theme }) => theme.font.styles.labelExtraSmall};
`

export const LabelSmall = styled.span`
  ${({ theme }) => theme.font.styles.labelSmall};
`

export const LabelMedium = styled.span`
  ${({ theme }) => theme.font.styles.labelMedium};
`

export const InputDefault = styled.span`
  ${({ theme }) => theme.font.styles.inputDefault};
`

export const InputExtraSmall = styled.span`
  ${({ theme }) => theme.font.styles.inputExtraSmall};
`

export const LabelInput = styled.span`
  ${({ theme }) => theme.font.styles.labelInput};
`

export const LabelCurrency = styled.span`
  ${({ theme }) => theme.font.styles.labelCurrency};
`
