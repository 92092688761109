import { GoogleMap } from '@react-google-maps/api'
import { pick, random } from 'lodash'
import moment from 'moment'
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import * as Icons from 'react-icons/io'
import styled from 'styled-components'

import { CircleLoadingIndicator } from '../../../components/CircleLoadingIndicator/CircleLoadingIndicator'
import { MAP_OPTIONS } from '../../../components/Map/Map'
import { MapContextProvider, useMapContext } from '../../../components/Map/Map.context'
import { Page } from '../../../components/Page/Page'
import { isMobile } from '../../../components/responsive/Responsive'
import Skeleton from '../../../components/Skeleton'
import { useText } from '../../../content'
import { formatCurrency } from '../../../data/utils'
import useOrderTracking from '../../../hooks/useOrderTracking'
import * as Checkout from '../../checkout/Checkout.styles'
import * as Home from '../../home/Home.styles'
import { OrderTrackingContainer } from '../OrderTracking.styles'
import { OrderTrackingCourierMarker, OrderTrackingDestinationMarker, OrderTrackingSourceMarker } from '../OrderTrackingIcons'

const OrderTracking = ({ orderId }) => {
  const { isLoaded: mapLoaded } = useMapContext()
  const [status, setStatus] = useState()
  const { text } = useText()

  const mapRef = useRef()

  const { loading, order, restaurant, error, initialInfo } = useOrderTracking(orderId, trackingInfo => {
    setStatus({ ...trackingInfo, lat: trackingInfo.latitude, lng: trackingInfo.longitude })
  })

  const restaurantCoordinates = { lat: restaurant?.latitude, lng: restaurant?.longitude }
  const addressCoordinates = { lat: order?.deliveryAddress?.latitude, lng: order?.deliveryAddress?.longitude }
  const courierCoordinates = pick(status, ['lat', 'lng'])

  const hasCourierInfo = Boolean(status?.lat && status?.lng)

  const stateText = useMemo(() => {
    const estimatedArrivalTime = moment.utc((status || initialInfo)?.estimatedArrivalTime).local()
    const isSameDay = moment().isSame(estimatedArrivalTime, 'day')
    const timestampText = estimatedArrivalTime.format(isSameDay ? 'h:mm a' : 'MMMM Do [at] h:mm a')

    return (status || initialInfo)?.status === TrackingStatus.DELIVERED
      ? `Delivered to ${order?.deliveryAddress?.streetAddress}.`
      : `Arriving around ${timestampText}.`
  }, [status, initialInfo, order])

  const orderProducts = useMemo(() => order?.products.filter(p => !p?.metadata?.some(metadata => metadata?.key === 'APP_HIDDEN')), [order?.products])

  useEffect(() => {
    if (!mapRef.current || !mapLoaded) {
      return
    }

    const bounds = new window.google.maps.LatLngBounds()

    if (restaurant?.latitude && restaurant?.longitude) {
      bounds.extend(new window.google.maps.LatLng(restaurant.latitude, restaurant.longitude))
    }

    if (order?.deliveryAddress?.latitude && order?.deliveryAddress?.longitude) {
      bounds.extend(new window.google.maps.LatLng(order.deliveryAddress.latitude, order.deliveryAddress.longitude))
    }

    if (status?.lat && status?.lng) {
      bounds.extend(new window.google.maps.LatLng(status.lat, status.lng))
    }

    mapRef.current.fitBounds(bounds)
  }, [restaurant, order, status, mapRef, mapLoaded])

  const currentStep = useMemo(() => statusReducer(status?.status || initialInfo?.status), [initialInfo, status])

  return (
    <Page>
      <OrderTrackingContainer>
        <Home.Section tabIndex="0" aria-label="Order details">
          <SectionBody style={{ gap: 0 }}>
            <HeaderTitle>{text(`OrderTracking.Header.${(status || initialInfo)?.status || TrackingStatus.PENDING}`)}</HeaderTitle>

            <InfoSkeleton loading={!order && loading} style={{ marginTop: 7, width: random(100, 200, false) }}>
              <OrderText style={{ marginTop: 7, fontWeight: 'bold' }}>Order #{order?.oloId}</OrderText>
            </InfoSkeleton>

            <InfoSkeleton loading={!status && !initialInfo} style={{ marginTop: 7, width: random(100, 200, false) }}>
              <OrderText>{stateText}</OrderText>
            </InfoSkeleton>

            <ProgressBar
              loading={loading}
              currentStep={currentStep}
              style={{ marginTop: 32 }}
              iconNames={['IoIosCheckmark', 'IoMdRestaurant', 'IoIosCar']}
            />

            {order && (
              <SummaryContainer style={{ marginTop: 32, border: 'none' }}>
                <Checkout.SummaryHeader style={{ boxShadow: 'none' }}>
                  <Checkout.SummaryLabel>Your Order </Checkout.SummaryLabel>
                </Checkout.SummaryHeader>

                {orderProducts.map((product, idx) => (
                  <Checkout.ProductContainer key={`${product.name}-${product.totalCost}`} style={{ marginTop: idx > 0 ? 16 : 0, padding: 20 }}>
                    <Checkout.Meta>
                      <Checkout.ProductName style={{ fontWeight: 'bold', fontSize: 18 }}>
                        {product.name} {product.quantity > 1 && <Checkout.ProductQuantity>({product.quantity})</Checkout.ProductQuantity>}
                      </Checkout.ProductName>
                      <Checkout.CostsNumber style={{ fontWeight: 'bold', fontSize: 18 }}>{formatCurrency(product.totalCost)}</Checkout.CostsNumber>
                    </Checkout.Meta>

                    <Checkout.IngredientsList menuItem={product} lightText />
                  </Checkout.ProductContainer>
                ))}

                <Checkout.CostsContainer>
                  <hr />
                  <Checkout.CostItem>
                    <Checkout.CostsLabel>Subtotal + Tax</Checkout.CostsLabel>
                    <Checkout.CostsNumber style={{ fontSize: 18 }}>
                      {formatCurrency(order?.subtotal)} + {formatCurrency(order?.salesTax)}
                    </Checkout.CostsNumber>
                  </Checkout.CostItem>

                  {order?.tip > 0 && (
                    <>
                      <hr />
                      <Checkout.CostItem>
                        <Checkout.CostsLabel>Tip</Checkout.CostsLabel>
                        <Checkout.CostsNumber style={{ fontSize: 18 }}>{formatCurrency(order?.tip)}</Checkout.CostsNumber>
                      </Checkout.CostItem>
                    </>
                  )}

                  {order?.customerHandoffCharge > 0 && (
                    <Checkout.CostItem>
                      <Checkout.CostsLabel>Delivery Fee</Checkout.CostsLabel>
                      <Checkout.CostsNumber style={{ fontSize: 18 }}>{formatCurrency(order?.customerHandoffCharge)}</Checkout.CostsNumber>
                    </Checkout.CostItem>
                  )}

                  {order?.discounts?.length > 0 &&
                    order.discounts.map((discount, index) => (
                      <Checkout.CostItem key={index}>
                        <Checkout.CostsLabel isPromo>Discount</Checkout.CostsLabel>
                        <Checkout.CostsNumber isPromo>{formatCurrency(discount.amount)}</Checkout.CostsNumber>
                      </Checkout.CostItem>
                    ))}

                  <hr />
                  <Checkout.CostItem>
                    <Checkout.CostsLabel style={{ fontWeight: 'bold', fontSize: 18 }}>Total</Checkout.CostsLabel>
                    <Checkout.CostsNumber style={{ fontWeight: 'bold', fontSize: 18 }}>{formatCurrency(order?.total)}</Checkout.CostsNumber>
                  </Checkout.CostItem>
                </Checkout.CostsContainer>
              </SummaryContainer>
            )}
          </SectionBody>
        </Home.Section>
        <Aside>
          {!mapLoaded || !order || !restaurant ? (
            <CircleLoadingIndicator />
          ) : (
            <MapWrapper>
              <GoogleMap
                options={MAP_OPTIONS}
                mapContainerStyle={{ width: '100%', height: '100%' }}
                center={hasCourierInfo ? courierCoordinates : restaurantCoordinates}
                onLoad={map => {
                  if (!mapRef.current) {
                    mapRef.current = map
                  }
                }}
              >
                {order && restaurant && (
                  <>
                    <OrderTrackingSourceMarker key="restaurant" position={restaurantCoordinates} label={restaurant?.name} />
                    <OrderTrackingDestinationMarker key="address" position={addressCoordinates} label={order?.deliveryAddress?.streetAddress} />
                  </>
                )}
                {hasCourierInfo && <OrderTrackingCourierMarker key="courier" position={courierCoordinates} type={initialInfo.deliveryProvider} />}
              </GoogleMap>
            </MapWrapper>
          )}
        </Aside>
      </OrderTrackingContainer>
    </Page>
  )
}

const MapWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;

  height: 100%;
  width: 100%;

  padding: 0;

  ${isMobile`
    height: 350px;
  `}
`

const SummaryContainer = styled(Checkout.SummaryContainer)`
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);

  hr {
    border-top: 1px solid #e8e8e8;
  }
`

const Aside = styled(Home.Aside)`
  align-items: center;
  justify-content: center;
  background-color: #efefef8c;
`

const SectionBody = styled(Home.SectionBody)`
  *:not(:first-child) {
    margin-top: 0;
  }
`

const SummaryTitle = styled.h1`
  font-size: 20px;
  font-weight: bold;
  font-family: ${({ theme }) => theme.font.styles.regular};
`

const ProgressBarFill = styled.div`
  flex: 1;
  height: 3px;
  margin: 0px -3px;
  background-color: ${({ theme, active }) => (active ? theme.colors.primary.toString() : 'grey')};
`

const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const InfoSkeleton = styled(Skeleton)`
  height: 16px;
  border-radius: 4px;
  background-color: grey;
`

const SheetHeader = styled.div`
  background-color: #fff;
  padding: 32px 16px 16px 16px;

  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`

const HeaderTitle = styled.span`
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.font.styles.regular};
`

const OrderText = styled.span`
  font-family: ${({ theme }) => theme.font.styles.regular};
  font-size: 16px;
`

const IconContainer = styled.div`
  display: flex;
  width: 24px;
  height: 24px;

  z-index: 1;

  border-radius: 12px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, active }) => (active ? theme.colors.primary.toString() : 'grey')};
`

const ProgressBar = ({ iconNames = [], currentStep = 0, loading = false, ...props }) => {
  return (
    <ProgressBarContainer {...props}>
      {iconNames.map((name, idx) => {
        const Icon = Icons[name]

        return (
          <Fragment key={name}>
            {idx > 0 && <ProgressBarFill active={currentStep >= idx} />}

            <IconContainer active={currentStep >= idx}>{loading && idx === 0 ? <CircleLoadingIndicator /> : <Icon color="white" />}</IconContainer>
          </Fragment>
        )
      })}
    </ProgressBarContainer>
  )
}

const TrackingStatus = {
  PENDING: 'Pending',
  DELIVERED: 'Delivered',
  PICKUPINPROGRESS: 'PickupInProgress',
  DELIVERYINPROGRESS: 'DeliveryInProgress',
}

const statusReducer = status => {
  const statusToStep = {
    [TrackingStatus.PENDING]: 0,
    [TrackingStatus.DELIVERED]: 2,
    [TrackingStatus.PICKUPINPROGRESS]: 1,
    [TrackingStatus.DELIVERYINPROGRESS]: 1,
  }

  return statusToStep[status] || 0
}

export const LPQOrderTrackingPage = props => (
  <MapContextProvider>
    <OrderTracking {...props} />
  </MapContextProvider>
)
