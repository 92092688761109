import { useQuery } from '@apollo/client'
import fp from 'lodash/fp'
import moment from 'moment'
import { useCallback, useMemo } from 'react'

import { GET_CALENDARS } from '../data/restaurants/queries'

const weekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

const dateRange = (from, to) => {
  return fp.flow(
    fp.range(0),
    fp.map(idx => from.clone().add(idx, 'days')),
  )(to.diff(from, 'days') + 1)
}

const compareTo = (fst, snd) => {
  const first = moment(fst)
  const second = moment(snd)

  if (first.isBefore(second)) {
    return -1
  }

  if (first.isAfter(second)) {
    return 1
  }

  return 0
}

/**
 * @deprecated This hook aggregate several logic inside of it, works better for PICKUP handoff mode
 * Please consider using newer hooks
 * @param restaurants - Array of restaurants
 * @returns
 */
export const useRestaurantsCalendars = (restaurants = [], onlyCurrentWeek = false) => {
  const now = onlyCurrentWeek ? moment().startOf('isoWeek') : moment()
  const [from, to] = useMemo(() => {
    return onlyCurrentWeek ? [now, now.clone().add(6, 'day')] : [now.clone(), now.clone().add(1, 'month')]
  }, [now, onlyCurrentWeek])

  const restaurantIds = useMemo(() => restaurants?.filter(r => !!r).map(r => r.id), [restaurants]) || []

  const { data: restaurantsWithCalendars, loading, called } = useQuery(GET_CALENDARS, {
    fetchPolicy: 'no-cache',
    skip: !restaurantIds || !Array.isArray(restaurantIds) || !restaurantIds.length,
    variables: {
      from: now.format('YYYYMMDD'),
      to: onlyCurrentWeek ? now.clone().add(6, 'day').format('YYYYMMDD') : now.clone().add(1, 'month').format('YYYYMMDD'),
      restaurantIds,
    },
  })

  const data = useMemo(() => {
    const restaurantsMerged = restaurants?.reduce((prev, curr) => {
      const calendar = restaurantsWithCalendars?.calendars?.find(c => c.restaurantId === String(curr.id) && c.type === 'business')

      if (calendar) {
        const ranges = calendar.ranges.map(({ start, end, ...range }) => {
          const parsedStart = moment(start, 'YYYYMMDD HH:mm')
          const parsedEnd = moment(end, 'YYYYMMDD HH:mm')
          return { ...range, start: parsedStart, end: parsedEnd }
        })

        const orderedOrClosed = dateRange(from, to).reduce(
          (accList, date) => [
            ...accList,
            ranges.find(r => date.isSame(r.start, 'day')) || {
              start: date,
              closed: true,
              weekday: date.format('ddd'),
            },
          ],
          [],
        )

        return [...prev, { ...curr, calendar: { ...calendar, ranges: orderedOrClosed } }]
      }

      return prev
    }, [])

    return restaurantsMerged
  }, [restaurants, restaurantsWithCalendars])

  const isRestaurantOpen = useCallback(restaurant => {
    if (!restaurant.isAvailable) {
      return false
    }

    for (const range of restaurant.calendar?.ranges || []) {
      if (moment(range.start).diff(moment(), 'seconds') < 0 && moment(range.end).diff(moment(), 'seconds') > 0) {
        // console.log(restaurant.name, 'is open!');
        return true
      }
    }
    return false
  }, [])

  const calculateNextOpenDays = useCallback(
    (restaurant, basket, limit) => {
      const startDay = moment().isoWeekday()

      console.debug('[useRestaurantsCalendars] calculateNextOpenDays:', { restaurant, basket, limit })

      if (!restaurant) {
        console.debug('[useRestaurantsCalendars] calculateNextOpenDays: no restaurant available yet, returning []')
        return []
      }

      let availableDays = restaurant?.calendar.ranges
        // .map((r, idx) => (r.start || moment()).clone().isoWeekday(startDay + idx)) // next days
        // .map(m => restaurant?.calendar.ranges[m.isoWeekday() - 1]) // map to range info objects. -1 cause our 0 is Monday.
        .filter(it => !it.closed)

      const leadTimeEstimateMinutes = basket?.leadTimeEstimateMinutes
      if (leadTimeEstimateMinutes && leadTimeEstimateMinutes >= 24 * 60) {
        // >= 24h
        const earliestReadyTime = moment().add(leadTimeEstimateMinutes, 'minutes') // now + leadTimeEstimateMinutes;
        availableDays = availableDays.filter(it => it.start.isSameOrAfter(earliestReadyTime, 'day')) // filter considering the earliest ready time
      }

      const first = availableDays[0].start
      let calendarOptions = availableDays.sort((a, b) => compareTo(a.start, b.start))
      if (limit) {
        calendarOptions = calendarOptions.splice(
          isRestaurantOpen(restaurant) || !moment().isSame(first, 'days') || moment().isBefore(first) ? 0 : 1,
          limit,
        )
      } else {
        calendarOptions = calendarOptions.slice(isRestaurantOpen(restaurant) || !moment().isSame(first, 'days') || moment().isBefore(first) ? 0 : 1)
      }

      return calendarOptions.reduce((prev, curr) => [...prev, { info: curr, timeSlots: calculateTimeSlotsForDay(curr, leadTimeEstimateMinutes) }], [])
    },
    [isRestaurantOpen],
  )

  const calculateEarliestReadyTime = useCallback(
    (restaurant, basket) => {
      const restaurantWithCalendar = data?.find(r => r.id === restaurant.id)
      const times = calculateNextOpenDays(restaurantWithCalendar, basket)
      if (times?.length > 0) {
        const firstDay = times[0]
        return firstDay.timeSlots[0].clone()
      }
    },
    [data, calculateNextOpenDays],
  )

  return {
    called,
    data,
    loading,
    isRestaurantOpen,
    calculateNextOpenDays,
    calculateEarliestReadyTime,
  }
}

const calculateTimeSlotsForDay = (rangeInfo, leadTimeEstimateMinutes) => {
  const splitInterval = 30

  if (!rangeInfo) {
    return []
  }

  const { start, end } = rangeInfo

  const startMoment = start.clone()
  const endMoment = end.clone()

  const res = [startMoment.clone()]
  startMoment.add(splitInterval, 'minute')

  while (startMoment.isBefore(endMoment)) {
    res.push(startMoment.clone())
    startMoment.add(splitInterval, 'minute')
  }

  const currentTime = moment()
  if (leadTimeEstimateMinutes >= 24 * 60) {
    // >= 24h
    currentTime.add(leadTimeEstimateMinutes, 'minutes')
  }

  // if this is not today, then we can skip the ASAP checks
  const isToday = currentTime.isSame(start, 'day')
  if (!isToday) {
    return res
  }

  // check if ASAP/currentTime is before opening
  // if (currentTime.diff(start, 'seconds') < 0) {
  return res.filter(startTime => startTime.isAfter(currentTime))
  // }

  // if ASAP is after opening, add an extra chip at the beginning of the array
  // return [currentTime, ...res.filter(startTime => startTime.isAfter(currentTime))]
}
