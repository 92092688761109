import React from 'react'

import { useText } from '../../../content'
import { useLoyalty } from '../../../hooks/useLoyalty'
import {
  RewardsSectionButtonActiveRewardsDescription,
  RewardsSectionButtonActiveRewardsLabel,
  RewardsSectionButtonMeta,
  RewardsSectionIconImage,
  Section,
  SectionItem,
  SectionItemDescription,
  SectionItemIcon,
  SectionItemMeta,
  SectionItemTitle,
  SectionTitle,
} from './FgcRewards.styles'

export const FgcBankedCurrencyInfo = () => {
  const { balance } = useLoyalty()
  const { text } = useText()

  return (
    <Section>
      <SectionTitle>{text('Profile.Rewards.BankedCurrency.Title')}</SectionTitle>
      <SectionItem>
        <SectionItemIcon>
          <RewardsSectionIconImage variant="currency" />
        </SectionItemIcon>
        <SectionItemMeta>
          <SectionItemTitle>{text('Profile.Rewards.BankedCurrency.Heading', { bankedCurrency: balance?.bankedCurrency })}</SectionItemTitle>
          <SectionItemDescription>{text('Profile.Rewards.BankedCurrency.Description')}</SectionItemDescription>
        </SectionItemMeta>
      </SectionItem>
    </Section>
  )
}
