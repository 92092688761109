import React from 'react'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const TextSeparator = styled(props => <span {...props}> • </span>)`
  color: ${props => (props.$lightText ? props.theme.colors.brand.gray60 : props.theme.colors.brand.gray90)};
  font-family: ${props => props.theme.font.styles.bold};
`

export const Name = styled.span`
  ${({ theme }) => theme.font.styles.descriptionExtraSmall}
  color: ${props => (props.$lightText ? props.theme.colors.brand.gray60 : props.theme.colors.brand.gray90)};
  text-transform: none;
`
