import { Grid } from '@material-ui/core'
import { GoogleMap } from '@react-google-maps/api'
import React from 'react'

import { useHomeContext } from '../../scenes/home/Home.context'
import { MapMarker } from './MapMarker'
import { useMapStyles } from './useMapStyles'

export const MAP_OPTIONS = {
  disableDefaultUI: true,
  disableDoubleClickZoom: true,
  maxZoom: 21,
  minZoom: 8,
  scrollwheel: true,
  zoom: 14,
  zoomControl: true,
}

const scrollIntoRestaurantCard = restaurant =>
  document.getElementById(`restaurant-card-${restaurant.id}`).scrollIntoView({
    behavior: 'smooth',
    block: 'center',
  })

export const Map = ({ isSnap }) => {
  const styles = useMapStyles()
  const { location, pickupRestaurants, selectedRestaurant, selectRestaurant } = useHomeContext()
  const center = location ? { lat: location?.latitude, lng: location?.longitude } : { lat: 40.725484907553714, lng: -73.99618391810739 }
  console.debug('[Map] center=', center)

  return isSnap ? null : (
    <Grid container component="main" className={styles.root}>
      <GoogleMap center={center} mapContainerClassName={styles.mapContainerStyle} options={MAP_OPTIONS}>
        {!pickupRestaurants.loading &&
          pickupRestaurants?.data?.map(restaurant => (
            <MapMarker
              key={restaurant.id}
              position={{ lat: restaurant.latitude, lng: restaurant.longitude }}
              selected={selectedRestaurant?.id === restaurant.id}
              onClick={() => {
                selectRestaurant(restaurant)
                scrollIntoRestaurantCard(restaurant)
              }}
            />
          ))}
      </GoogleMap>
    </Grid>
  )
}
