import React from 'react'

import { CategoriesContainer, CategoryButton, Container, LabelText } from './BrandCategoryBar.styles'

export const BrandCategoryBar = ({ selectedBrand, brands, isVisible, onClick }) => {
  if (!isVisible) return null
  return (
    <Container as="div">
      <LabelText>Filter by menu:</LabelText>
      <CategoriesContainer as="div">
        {brands?.map(brand => (
          <CategoryButton as="button" key={brand.key} onClick={() => onClick(brand)} $isSelected={selectedBrand === brand.code}>
            {brand.displayName}
          </CategoryButton>
        ))}
      </CategoriesContainer>
    </Container>
  )
}
