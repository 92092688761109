import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { Button } from '../../../components/Button/Button'
import { Description } from '../../../components/Text/Text.styles'
import { PREFERENCES } from '../../../constants/preferences'
import { useText } from '../../../content'
import { useConfig } from '../../../contexts/appConfig'
import { useBasket } from '../../../hooks/useBasket'
import { useLoyalty } from '../../../hooks/useLoyalty'
import { useMessaging } from '../../../hooks/useMessaging'
import { LittleBeetBankedCurrencyInfo } from './LittleBeetBankedCurrencyInfo'
import {
  CenterContainer,
  CenterPageContent,
  MeterBar,
  MeterCenter,
  MeterContainer,
  MeterContainerSession,
  PointsText,
  RewardButton,
  RewardsCenterContainer,
  RewardsContainer,
  RewardsSectionIconImage,
  Section,
  SectionItem,
  SectionItemDescription,
  SectionItemIcon,
  SectionItemMeta,
  SectionItemTitle,
  SectionTitle,
  YourPointsText,
} from './LittleBeetRewards.styles'

export const LittleBeetRewardsContent = () => {
  const history = useHistory()
  const { dispatchMessage } = useMessaging()
  const { text } = useText()
  const { getAsset, getPreference } = useConfig()

  const { validateBasket, basket } = useBasket()
  const { loading, balance, rewards, program, applyReward, applyRewardLoading, removeReward, removeRewardLoading } = useLoyalty()

  const brandName = useMemo(() => getPreference(PREFERENCES.GENERAL)?.brandName, [getPreference])
  const meterImage = getAsset('RewardsMeterImage')?.url
  const showMigrationInterstitial = getPreference('RewardsConfiguration')?.ShowMigrationInterstitial

  const barProgress = useMemo(() => {
    // points / pointsNeeded * 100
    const progress = Math.trunc(((balance?.unbankedPoints || 0) / (program?.pointsConversionThreshold || 200)) * 100)
    // limiting the progress so if some floating points error occurs, we still get a valid ui
    return Math.min(progress, 100) / 100
  }, [balance?.unbankedPoints, program?.pointsConversionThreshold])

  const pointToNextReward = useMemo(() => (program?.pointsConversionThreshold || 0) - (balance?.unbankedPoints || 0), [
    balance?.unbankedPoints,
    program?.pointsConversionThreshold,
  ])

  const appliedRewards = {}
  if (basket?.appliedRewards?.length > 0) {
    basket.appliedRewards.forEach(item => {
      appliedRewards[item?.externalReference] = true
    })
  }

  const handleRedeem = async reward => {
    const isValid = await applyReward(reward)
    if (!isValid) {
      return
    }
    const { basket: validatedBasket } = await validateBasket()
    if (!validatedBasket) {
      dispatchMessage.error(text('RewardRedemptionModal.Errors.Verify'))
    }
  }

  const handleRemove = async reward => {
    await removeReward(reward.rewardId)
  }

  const handleMigrate = () => {
    history.push('/migration/verification')
  }

  if (loading) {
    return (
      <CenterPageContent>
        <div>Loading ...</div>
      </CenterPageContent>
    )
  }

  const circumference = 2 * Math.PI * 45

  return (
    <>
      <MeterContainerSession>
        <CenterContainer>
          <MeterContainer backgroundImage={meterImage}>
            <MeterBar>
              <svg width="100%" height="100%" viewBox="0 0 100 100">
                <circle stroke="#76003B" strokeWidth="5" fill="none" r="45" cx="50" cy="50" />
                {balance?.unbankedPoints > 0 && (
                  <g transform="rotate(-90)" transform-origin="center center">
                    <circle
                      stroke="#FFF"
                      strokeWidth="5"
                      fill="none"
                      r="45"
                      cx="50"
                      cy="50"
                      strokeLinecap="round"
                      strokeDasharray={[circumference * barProgress, circumference]}
                    />
                  </g>
                )}
              </svg>
            </MeterBar>
            <MeterCenter>
              <PointsText>{balance?.unbankedPoints}</PointsText>
              <YourPointsText>Points</YourPointsText>
              <YourPointsText>out of {program?.pointsConversionThreshold}</YourPointsText>
            </MeterCenter>
          </MeterContainer>
          <Description style={{ textTransform: 'uppercase' }}>
            {text('Profile.Rewards.Points.ToNextReward', {
              amount: pointToNextReward,
              plural: pointToNextReward === 1 ? '' : 's',
            })}
          </Description>
        </CenterContainer>
      </MeterContainerSession>

      <RewardsContainer>
        <RewardsCenterContainer>
          {balance?.bankedCurrency > 0 && <LittleBeetBankedCurrencyInfo />}
          {rewards.length > 0 && (
            <Section>
              <SectionTitle>{text('Profile.Rewards.Rewards.Title')}</SectionTitle>
              {rewards.map(reward => (
                <RewardCard
                  key={String(reward.rewardId)}
                  reward={reward}
                  canRedeem={basket}
                  canRemove={appliedRewards[reward.rewardId]}
                  loading={applyRewardLoading || removeRewardLoading}
                  onRedeem={handleRedeem}
                  onRemove={handleRemove}
                />
              ))}
            </Section>
          )}
          {showMigrationInterstitial && (
            <>
              <Description style={{ display: 'flex', textAlign: 'center', margin: 10 }}>
                {text('Profile.Rewards.Migrate.Description', { brandName })}
              </Description>
              <Button variant="link" label="Migrate Rewards" onClick={handleMigrate} />
            </>
          )}
        </RewardsCenterContainer>
      </RewardsContainer>
    </>
  )
}

const RewardCard = ({ reward, canRedeem, canRemove, loading, onRedeem, onRemove }) => {
  function handleRedeem() {
    onRedeem(reward)
  }

  function handleRemove() {
    onRemove(reward)
  }

  return (
    <SectionItem>
      <SectionItemIcon>
        <RewardsSectionIconImage source={reward.rewardImageUrl} />
      </SectionItemIcon>
      <SectionItemMeta>
        <div style={{ flex: 1 }}>
          <SectionItemTitle>{reward.name}</SectionItemTitle>
          <SectionItemDescription>{reward.description.trim()}</SectionItemDescription>
        </div>
        {canRedeem && !canRemove && <RewardButton isSmall variant="secondary" label="Redeem" loading={loading} onClick={handleRedeem} />}
        {canRemove && <RewardButton isSmall variant="secondary" label="Remove" loading={loading} onClick={handleRemove} />}
      </SectionItemMeta>
    </SectionItem>
  )
}
