import React, { useMemo } from 'react'

import { findMetadata, formatCurrency } from '../../../../data/utils'
import {
  CloseButton,
  CloseIcon,
  Container,
  DisplayValueContainer,
  ModifierSelectedOptionButton,
  ModifierSelectedOptionLabel,
  ModifierSelectedOptionText,
} from './ModifierSelectedOption.styles'

export const ModifierSelectedOption = ({ handleOnRemove, modifier, selectedOptions }) => {
  const shortName = useMemo(() => {
    return findMetadata(modifier.metadata, 'APP_SHORT_NAME') || modifier.description
  })
  return (
    <Container>
      <ModifierSelectedOptionLabel>{shortName}</ModifierSelectedOptionLabel>
      {selectedOptions.length > 0 ? (
        selectedOptions?.map(m => (
          <ModifierSelectedOptionButton key={m.option.id} $hasSelection>
            <DisplayValueContainer>
              <ModifierSelectedOptionText style={{ marginRight: '3px' }} title={m.option?.name} $overflowHidden>
                {m.option?.name}
              </ModifierSelectedOptionText>
              <ModifierSelectedOptionText style={{ marginRight: '3px' }}>{m.quantity > 1 && <>({m.quantity})</>}</ModifierSelectedOptionText>
              <ModifierSelectedOptionText>
                {m.quantity * m.option?.cost > 0 && <> +{formatCurrency(m.quantity * m.option?.cost)}</>}
              </ModifierSelectedOptionText>
            </DisplayValueContainer>
            <CloseButton onClick={() => handleOnRemove(m.option)}>
              <CloseIcon />
            </CloseButton>
          </ModifierSelectedOptionButton>
        ))
      ) : (
        <ModifierSelectedOptionButton key={modifier.id} $hasSelection={selectedOptions.length > 0}>
          <ModifierSelectedOptionText>{shortName}</ModifierSelectedOptionText>
        </ModifierSelectedOptionButton>
      )}
    </Container>
  )
}
