import { reduce } from 'lodash/fp'
import { useMemo } from 'react'

export const useCaloriesInformation = ({ activeModifiers, menuItem }) => {
  return useMemo(() => {
    const caloriesFor = propName => {
      return reduce((acc, { option }) => (option.adjustsParentCalories ? acc + (option?.[propName] || 0) : acc), menuItem?.[propName] || 0)
    }

    const baseCalories = caloriesFor('baseCalories')(activeModifiers)
    const maxCalories = caloriesFor('maxCalories')(activeModifiers)

    return { baseCalories, maxCalories }
  }, [activeModifiers, menuItem])
}
