import styled from 'styled-components'

import { CtaSmall } from '../Text/Text.styles'

export const StoreHoursBannerContainer = styled.div`
  background: ${props => props.theme.colors.primary};
  display: flex;
  padding: 6px 0;
  justify-content: center;
  width: 100%;
`

export const StoreHoursBannerText = styled(CtaSmall)`
  color: ${props => props.theme.colors.secondary};
`
