import React from 'react'
import styled, { useTheme } from 'styled-components'

import { CloseButton } from '../../components/Button/Button'
import { isMobile } from '../responsive/Responsive'

export const ModalHeader = ({ onClose, title, titleStyle }) => {
  const theme = useTheme()

  return (
    <Header>
      <CloseButton data-testid="modal-header-close-button" onClick={onClose} size={32} variant={theme.buttons.modalHeaderCloseButtonVariant} />
      <Heading style={titleStyle}>{title}</Heading>
    </Header>
  )
}

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => theme.modals.header};
`

export const Heading = styled.p`
  width: 100%;
  ${({ theme }) => theme.font.styles.h1}
  color: ${({ theme }) => theme.basket.topTextColor};
  font-size: 26px;
  text-align: center;
  margin-left: 16px;

  ${isMobile`
    font-size: 18px;
    margin-left: 0px;
  `}
`
