import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import * as Sentry from '@sentry/react'
import moment from 'moment'
import { useEffect, useState } from 'react'

const sentryErrorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    Sentry.withScope(scope => {
      if (graphQLErrors) {
        console.error(`Apollo GraphQL Error at "${operation.operationName}":`, JSON.stringify(graphQLErrors))
        scope.setExtra('graphQLErrors', JSON.stringify(graphQLErrors))
      }
      if (networkError) {
        console.error(`Apollo Network Error at "${operation.operationName}":`, JSON.stringify(networkError))
        scope.setExtra('networkError', JSON.stringify(networkError))
      }
      Sentry.captureException(operation.operationName)
    })
  }
})

const logLink = new ApolloLink((operation, forward) => {
  console.debug('APOLLO_START', operation.operationName)
  return forward(operation).map(result => {
    console.debug('APOLLO_END', operation.operationName)
    return result
  })
})

const cache = new InMemoryCache({
  typePolicies: {
    Basket: {
      fields: {
        products: {
          merge(existing, incoming) {
            return { ...existing, ...incoming }
          },
        },
        discounts: {
          merge(existing, incoming) {
            return { ...existing, ...incoming }
          },
        },
      },
    },
    BasketProduct: {
      fields: {
        choices: {
          merge(existing, incoming) {
            return { ...existing, ...incoming }
          },
        },
      },
    },
    Query: {
      fields: {
        guestBillingAccounts: {
          merge(existing, incoming) {
            return { ...existing, ...incoming }
          },
        },
      },
    },
    BillingAccount: {
      keyFields: ['accountId'],
    },
  },
})

export const apolloClient = new ApolloClient({
  // cache: new InMemoryCache(),
  cache,
  link: from([
    sentryErrorLink,
    logLink,
    new HttpLink({
      uri: process.env.REACT_APP_API_URL,
    }),
  ]),
})

apolloClient.clearStore()

export const useCacheWithExpiration = (expiration, key) => {
  const [fetchPolicy, setFetchPolicy] = useState('cache-only')
  const [cacheKeys, setCacheKeys] = useState({})

  useEffect(() => {
    const lastFetch = cacheKeys[key]
    // console.debug(
    //   `looking up cache value for ${key}:`,
    //   lastFetch ? `${moment().diff(moment(lastFetch)) / 1000} seconds (expires at ${expiration.asSeconds()})` : 'not found',
    // )
    if (!lastFetch || moment().diff(moment(lastFetch)) > expiration.asMilliseconds()) {
      const newCacheKeys = { ...cacheKeys, [key]: moment().toISOString() }
      setCacheKeys(newCacheKeys)
      setFetchPolicy('network-only')
    } else {
      setFetchPolicy('cache-first')
    }
  }, [expiration, key, cacheKeys])

  return fetchPolicy
}
