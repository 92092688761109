export const HANDOFF_MODES = Object.freeze({
  PICKUP: 'PICKUP',
  DELIVERY: 'DELIVERY',
})

const NORMALIZED_HANDOFF_MODES = Object.freeze({
  delivery: HANDOFF_MODES.DELIVERY,
  dispatch: HANDOFF_MODES.DELIVERY,
  pickup: HANDOFF_MODES.PICKUP,
  curbside: HANDOFF_MODES.PICKUP,
})

export const normalizeHandoffMode = handoffMode => NORMALIZED_HANDOFF_MODES[handoffMode?.toLowerCase()]

export const isDeliveryMode = handoffMode => normalizeHandoffMode(handoffMode) === HANDOFF_MODES.DELIVERY

export const isSameHandoffMode = (handoffModeA, handoffModeB) => {
  console.debug('isSameHandoffMode?', { handoffModeA, handoffModeB })
  return normalizeHandoffMode(handoffModeA) === normalizeHandoffMode(handoffModeB)
}
