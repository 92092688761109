import React, { useMemo } from 'react'

import { DoubleCTA } from '../../../components/DoubleCTA/DoubleCTA'
import { ModalHeader } from '../../../components/ModalHeader/ModalHeader'
import { ModalBodyContainer, ModalContainer, ModalFooterContainer, ModalWithBackdrop } from '../../../components/ModalWithBackdrop/ModalWithBackdrop'
import { Description, DescriptionLarge, H2 } from '../../../components/Text/Text.styles'
import { useText } from '../../../content'
import { RewardIconImage } from './RedeemRewardModal.styles'

export const RedeemRewardModal = ({ dialogProps, loading, onConfirm, onCancel, reward }) => {
  const { text } = useText()

  const buttons = useMemo(
    () => [
      {
        disabled: loading,
        label: text('Profile.Rewards.RewardRedemption.Cancel'),
        onClick: onCancel,
        variant: 'secondary',
      },
      {
        disabled: loading,
        label: text('Profile.Rewards.RewardRedemption.Confirm'),
        onClick: onConfirm,
        variant: 'primary',
      },
    ],
    [dialogProps.hide, onConfirm, onCancel, text],
  )

  return (
    <ModalWithBackdrop dialogProps={dialogProps}>
      <ModalContainer>
        <ModalHeader title={text('Profile.Rewards.RewardRedemption.Title')} onClose={onCancel} />
        <ModalBodyContainer style={{ alignItems: 'center', justifyContent: 'center' }}>
          <H2 style={{ width: '75%', textAlign: 'center' }}>{text('Profile.Rewards.RewardRedemption.ConfirmationMessage')}</H2>
          <RewardIconImage data-testid="reward-icon-image" source={reward?.rewardImageUrl} style={{ margin: '64px 0 48px' }} />
          <DescriptionLarge style={{ marginBottom: 12 }}>{reward?.name}</DescriptionLarge>
          <Description>{reward?.description}</Description>
        </ModalBodyContainer>
        <ModalFooterContainer>
          <DoubleCTA buttons={buttons} />
        </ModalFooterContainer>
      </ModalContainer>
    </ModalWithBackdrop>
  )
}
