import { useMutation } from '@apollo/client'
import { useCallback, useMemo } from 'react'

import { isDeliveryMode } from '../constants/handoffModes'
import { useText } from '../content'
import { useAddresses } from '../contexts/addresses'
import { useSession } from '../contexts/session'
import { SUBMIT_BASKET, VALIDATE_BASKET } from '../data/basket/queries'
import { useMessaging } from '../hooks/useMessaging'
import { useMenuItems } from './useMenuItems'

export const useBasket = () => {
  const {
    state: { activeAddress, basket },
    dispatch,
  } = useSession()
  const { dispatchMessage } = useMessaging()
  const { text } = useText()
  const { availableProductIds } = useMenuItems()
  const { addAddress } = useAddresses()

  const [validateBasketMutation, { loading }] = useMutation(VALIDATE_BASKET, {
    onError: useCallback(
      err =>
        dispatchMessage({
          type: 'error',
          payload: { message: text('Checkout.Errors.ValidateBasket') },
          err,
        }),
      [text, dispatchMessage],
    ),
  })

  const [submitBasketMutation, { loading: loadingSubmitBasket }] = useMutation(SUBMIT_BASKET, {
    onError: useCallback(
      err =>
        dispatchMessage({
          type: 'error',
          payload: { message: text('Checkout.Errors.PlaceOrder') },
          err,
        }),
      [text, dispatchMessage],
    ),
  })

  const validateBasket = useCallback(async () => {
    const result = await validateBasketMutation({
      variables: {
        basketId: basket?.id,
      },
    })

    const validatedBasket = result?.data?.validateBasket

    if (validatedBasket) {
      dispatch({ type: 'SET_BASKET', payload: validatedBasket })
      return { basket: validatedBasket }
    } else {
      return { basket: null }
    }
  }, [validateBasketMutation, dispatch])

  const submitBasket = useCallback(
    async checkoutInput => {
      const result = await submitBasketMutation({
        variables: {
          basketCheckoutInput: checkoutInput,
        },
      })

      const order = result?.data?.submitBasket

      if (isDeliveryMode(order?.deliveryMode) && addAddress) {
        addAddress(activeAddress).catch(error => {
          dispatchMessage.error('Failed to save address', error)
        })
      }
      return order
    },
    [submitBasketMutation, addAddress, dispatchMessage],
  )

  const products = useMemo(() => basket?.products?.filter(product => availableProductIds?.indexOf(product.productId) > -1) || null, [
    availableProductIds,
    basket,
  ])

  const totalProductQuantity = useMemo(() => {
    return products?.reduce((sum, cur) => {
      return sum + cur.quantity
    }, 0)
  }, [products])

  return { basket, validateBasket, loading, submitBasket, loadingSubmitBasket, products, totalProductQuantity }
}
