import { MdChevronRight } from 'react-icons/md'
import styled from 'styled-components'

import { isMobile } from '../responsive/Responsive'

export const ChangeAddressChipOuterContainer = styled.div`
  ${({ theme }) => theme.changeAddressChip.outerContainerStyle};

  align-items: center;
  display: flex;
  flex-direction: row;

  ${isMobile`
    justify-content: center;
    margin: 0 auto;
    overflow: hidden;
    width: 100%;
    &:before {
      content: none;
    }
    > svg {
      display:none;
    }
  `}
`

export const ChangeAddressChipInnerContainer = styled.div`
  cursor: pointer;

  padding: 8px 20px;
  margin: 0 12px;

  /* text overflow ellipsis */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme }) => theme.changeAddressChip.innerContainerStyle};
`

export const ForwardContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
`

export const ForwardIcon = styled(MdChevronRight).attrs(({ theme }) => ({
  size: theme.icons.size.md,
}))``
