import debounce from 'lodash.debounce'
import { useLayoutEffect, useState } from 'react'

export const useWindowSize = () => {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight])
  useLayoutEffect(() => {
    const updateSize = debounce(() => {
      const { innerWidth, innerHeight } = window
      setSize([innerWidth, innerHeight])
    }, 50)
    updateSize()
    window.addEventListener('resize', updateSize)
    return () => window.removeEventListener('resize', updateSize)
  }, [setSize])
  return size
}
