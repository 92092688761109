import { FiChevronRight } from 'react-icons/fi'
import { Button } from 'reakit/Button'
import styled from 'styled-components'

export const Text = styled.span`
  ${({ theme }) => theme.font.styles.labelInputSmall}
`

export const FloatingText = styled(Text)`
  color: ${props => props.theme.colors.brand.black};
  font-family: ${props => props.theme.font.styles.regular};
  font-size: 16px;
  line-height: 130%;
  text-transform: unset;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  width: 100%;
  text-align: start;
`

export const FloatingLabel = styled.label`
  color: ${props => props.theme.colors.brand.gray80};
  font-family: ${props => props.theme.font.styles.regular};
  font-size: 12px;
  line-height: 140%;

  position: absolute;
  left: 16px;
  top: 12px;
`

export const ForwardIcon = styled(FiChevronRight).attrs(({ theme }) => ({
  color: theme.colors.brand.black,
  size: 24,
}))``

export const ModifierLabel = styled.span`
  font-size: 14px;
  font-family: ${({ theme: { font } }) => font.styles.regular};
`

export const HorizontalScrollView = styled.div`
  display: flex;
  overflow: auto;
  white-space: nowrap;
  background-color: #fbf5ee;
`

export const HorizontalOption = styled(Button)`
  display: flex;
  padding: 16px;
  cursor: pointer;
  min-width: 96px;
  min-height: 64px;
  text-align: center;
  align-items: center;
  transition: 300ms all;
  justify-content: center;
  border: 1px solid transparent;

  background-color: ${({ selected }) => (selected ? '#fdc400' : '#fbf5ee')};

  :hover {
    background-color: #fdc400;
  }

  :focus {
    border-color: #fdc400;
  }
`
