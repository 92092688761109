import Downshift from 'downshift'
import React, { useMemo, useState } from 'react'
import ReactDOM from 'react-dom'
import { useTheme } from 'styled-components'

import { useResponsiveness } from '../responsive/Responsive'
import { ContainerStyle, Icon, InputContainerStyle, Item, ItemList, SearchInput } from './Autocomplete.styles'

const LIST_ITEMS_MARGIN_TOP = 4

const getMobileStyle = containerRect => ({
  left: containerRect.left,
  top: containerRect.top + containerRect.height + window.scrollY + LIST_ITEMS_MARGIN_TOP || 0,
  width: containerRect.width,
})

const getDefaultStyle = () => ({
  left: 0,
  top: `calc(100% + ${LIST_ITEMS_MARGIN_TOP}px)`,
  right: 0,
})

const useStyle = ({ containerRect, isMobile }) =>
  useMemo(() => (isMobile ? getMobileStyle(containerRect, window.scrollY) : getDefaultStyle()), [containerRect, isMobile])

const AutocompleteListItems = ({ containerRect, getMenuProps, children }) => {
  const { isMobile } = useResponsiveness()
  const style = useStyle({ containerRect, isMobile })
  const element = useMemo(
    () => (
      <ItemList {...getMenuProps()} style={style}>
        {children}
      </ItemList>
    ),
    [children, getMenuProps, style],
  )
  return useMemo(() => (isMobile ? ReactDOM.createPortal(element, document.body) : element), [element, isMobile])
}

export const Autocomplete = ({ inputRef, inputValue, items, itemToString, onBlur, onChange, onSelect, placeholder, render }) => {
  const [containerRef, setContainerRef] = useState()
  const theme = useTheme()

  function stateReducer(state, changes) {
    switch (changes.type) {
      case Downshift.stateChangeTypes.changeInput:
        onChange(changes.inputValue)
        return {
          ...changes,
          isOpen: changes.inputValue.length > 0,
        }
      default:
        return changes
    }
  }

  return (
    <Downshift inputValue={inputValue} itemToString={itemToString} stateReducer={stateReducer} onSelect={onSelect}>
      {({ getInputProps, getItemProps, getMenuProps, getRootProps, isOpen, highlightedIndex }) => (
        <ContainerStyle {...getRootProps()} ref={setContainerRef}>
          <InputContainerStyle>
            <Icon style={{ color: theme?.colors?.primary }} />
            <SearchInput {...getInputProps({ onBlur })} placeholder={placeholder} ref={inputRef} />
          </InputContainerStyle>
          <AutocompleteListItems containerRect={containerRef?.getBoundingClientRect() || {}} getMenuProps={getMenuProps}>
            {isOpen &&
              items?.map((item, index) => (
                <Item key={`autocomplete-item-${index}`} $highlighted={highlightedIndex === index} {...getItemProps({ item, index })}>
                  {render ? render(item) : item}
                </Item>
              ))}
          </AutocompleteListItems>
        </ContainerStyle>
      )}
    </Downshift>
  )
}
