import React from 'react'

import { DescriptionExtraSmall } from '../../../components/Text/Text.styles'
import { formatTime, getFullDayOfWeek } from '../../../utils'

const buildAriaLabel = (firstDay, lastDay) => {
  const ariaLabel = `Restaurant is
  ${firstDay?.closed ? 'closed' : 'working'}
  ${lastDay ? `from ${getFullDayOfWeek(firstDay?.weekday)} to ${getFullDayOfWeek(lastDay?.weekday)}` : `at ${getFullDayOfWeek(firstDay?.weekday)}`}
  ${!firstDay?.closed ? `from ${formatTime(firstDay?.start)} to ${formatTime(firstDay?.end)}` : ''}
  `
  return ariaLabel
}

export const WorkingHours = ({ workingHours }) =>
  workingHours?.map(({ firstDay, lastDay }, index) => (
    <div key={`workingHour-${index}`}>
      <DescriptionExtraSmall aria-label={buildAriaLabel(firstDay, lastDay)}>
        {lastDay ? `${firstDay?.weekday} - ${lastDay?.weekday}` : `${firstDay?.weekday}`}
        {' - '}
        {firstDay?.closed ? 'Closed' : `${formatTime(firstDay?.start)} - ${formatTime(firstDay?.end)}`}
      </DescriptionExtraSmall>
    </div>
  ))
