import React from 'react'
import { useHistory } from 'react-router-dom'

import { Button } from '../../components/Button/Button'
import { Page } from '../../components/Page/Page'
import { useText } from '../../content'
import { useConfig } from '../../contexts/appConfig'
import { useSession } from '../../contexts/session'
import { Container, Form, Paragraph } from './Auth.styles'
import { BackgroundImageSection } from './components/BackgroundImageSection'

export const AccountCreatedPage = () => {
  const history = useHistory()
  const { text } = useText()
  const { getAsset } = useConfig()

  const {
    state: { user },
  } = useSession()

  const logoSrc = getAsset('RewardsProgramLogo')?.url
  const welcomeMessage = text('Register.WelcomeMessage', { brand: text('Brand.Name'), user })

  const handleStartOrder = () => {
    history.push('/')
  }

  return (
    <Page isScrollable>
      <BackgroundImageSection tabIndex="0" aria-label={welcomeMessage}>
        <Container>
          <Form noValidate style={{ alignItems: 'center' }}>
            <img style={{ marginBottom: 30 }} width="25%" src={logoSrc} />
            <Paragraph style={{ marginBottom: 20, textAlign: 'center' }}>{welcomeMessage}</Paragraph>
            <Button variant="primary" label="Start An Order" onClick={handleStartOrder} />
          </Form>
        </Container>
      </BackgroundImageSection>
    </Page>
  )
}
