import { IoMdClose } from 'react-icons/io'
import { Button as ReakitButton } from 'reakit/Button'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  margin-bottom: 24px;
  :last-of-type {
    margin-bottom: 0;
  }
`

export const ModifierSelectedOptionLabel = styled.label`
  ${({ theme }) => theme.font.styles.labelInputBold};
  color: ${({ theme }) => theme.colors.brand.black.hex()};
  margin-bottom: 1px;
`

export const ModifierSelectedOptionButton = styled(ReakitButton)`
  display: flex;
  flex-direction: row;
  align-items: center;

  background-color: ${({ theme }) => theme.colors.brand.white.hex()};
  padding: 24px 20px;
  margin-top: 10px;

  border-radius: 3px;
  border: ${({ theme }) => theme.borders.dashed};

  /* button override */
  width: 100%;
  text-align: left;

  ${props =>
    props.$hasSelection &&
    css`
      border: ${({ theme }) => theme.borders.normal};
    `}
`

export const DisplayValueContainer = styled.div`
  display: flex;
  margin-right: auto;
  max-width: calc(100% - 43px);
`

export const ModifierSelectedOptionText = styled.span`
  ${({ theme }) => theme.font.styles.labelInput}
  line-height: 130%;

  ${props =>
    !props.$hasSelection &&
    css`
      color: ${({ theme }) => theme.colors.brand.gray70};
    `}

  ${props =>
    props.$overflowHidden &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`

export const CloseButton = styled.div`
  background: inherit;
  border: none;
  max-height: 20px;
  margin-left: auto;
  margin: 0;
  padding: 0;
  cursor: pointer;
`

export const CloseIcon = styled(IoMdClose).attrs(({ theme }) => ({
  size: 23,
}))``
