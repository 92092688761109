import { useMemo } from 'react'

import { HANDOFF_MODES, normalizeHandoffMode } from '../constants/handoffModes.js'
import { useText } from '../content'
import { capitalize } from '../data/utils.js'

export const useHandoffModes = () => {
  const { text } = useText()
  return useMemo(
    () =>
      Object.values(HANDOFF_MODES).map(handoffMode => {
        const normalizedHandoffMode = normalizeHandoffMode(handoffMode)
        return {
          displayName: text(`HandoffMode.${capitalize(normalizedHandoffMode)}`),
          normalizedHandoffMode,
          value: handoffMode,
        }
      }),
    [text],
  )
}
