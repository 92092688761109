import 'moment-duration-format'

import moment from 'moment-timezone'
import { useMemo } from 'react'

import { useText } from '../content/index'

export const useStoreHoursStatusText = ({ now, closeDate, nextStartDate }) => {
  const { text } = useText()

  return useMemo(() => {
    if (closeDate) {
      const closeMinutesDiff = closeDate.diff(now, 'minutes')

      if (closeMinutesDiff === 60) {
        return moment.duration(closeMinutesDiff, 'minutes').format(text('MenuContext.StatusBanner.Description.Closes.Hours')) // `Closes in X hour`
      } else if (closeMinutesDiff > 0 && closeMinutesDiff < 60) {
        return moment.duration(closeMinutesDiff, 'minutes').format(text('MenuContext.StatusBanner.Description.Closes.Minutes')) // `Closes in X minutes`
      } else if (closeMinutesDiff > 60) {
        return
      }
    }

    if (!nextStartDate) return

    const opensToday = now.isSame(nextStartDate, 'days') && now.isBefore(nextStartDate, 'minutes')
    if (opensToday) {
      const differenceInMinutes = nextStartDate.diff(now, 'minutes')

      if (differenceInMinutes > 60) {
        return nextStartDate.format(text('MenuContext.StatusBanner.Description.Opens.SameDay'))
      }

      return moment
        .duration(differenceInMinutes, 'minutes')
        .format(
          text(
            differenceInMinutes === 60 ? 'MenuContext.StatusBanner.Description.Opens.Hours' : 'MenuContext.StatusBanner.Description.Opens.Minutes',
          ),
        )
    }

    const nextOpenMinutesDiff = nextStartDate.diff(now, 'minutes')
    if (!now.isSame(nextStartDate, 'day')) {
      return nextStartDate.format(text('MenuContext.StatusBanner.Description.Opens.NextDay')) // `Opens on Thu, July 17 @ 10:00am`
    } else if (nextOpenMinutesDiff > 60) {
      return nextStartDate.format(text('MenuContext.StatusBanner.Description.Opens.SameDay')) // `Opens @ 10:00am`
    }
  }, [now, closeDate, nextStartDate, text])
}
