import { gql } from '@apollo/client'

import {
  APP_ASSET_FIELDS,
  APP_PREFERENCES_FIELDS,
  BRAND_FIELDS,
  FEATURE_FLAGS_FIELDS,
  MENU_ITEM_IMAGES_FIELDS,
  PROMOTIONAL_ITEM_FIELDS,
  SPOTLIGHT_FIELDS,
  WEB_ASSET_FIELDS,
  WEB_PREFERENCES_FIELDS,
} from '../../data/types'

export const GET_PROMOTIONAL_ITEMS = gql`
  query GetPromotionalItems {
    promotionalItems {
      ${PROMOTIONAL_ITEM_FIELDS}
    }
  }
`

export const GET_APP_ASSETS = gql`
  query GetAppAssets {
    appAssets {
      ${APP_ASSET_FIELDS}
    }
  }
`

export const GET_APP_PREFERENCES = gql`
  query GetPreferences{
    preferences {
      ${APP_PREFERENCES_FIELDS}
    }
  }
`

export const GET_BRANDS = gql`
  query GetBrands {
    brands {
      ${BRAND_FIELDS}
    }
  }
`

export const GET_MENU_IMAGES = gql`
  query GetMenuImages {
    menuImages {
      ${MENU_ITEM_IMAGES_FIELDS}
    }
  }
`

export const GET_WEB_ASSETS = gql`
  query GetWebAssets {
    webAssets {
      ${WEB_ASSET_FIELDS}
    }
  }
`

export const GET_WEB_PREFERENCES = gql`
  query GetWebPreferences {
    webPreferences {
      ${WEB_PREFERENCES_FIELDS}
    }
  }
`

export const GET_SPOTLIGHTS = gql`
  query GetSpotlights {
    spotlights {
      ${SPOTLIGHT_FIELDS}
    }
  }
`

const ENVIRONMENT_TYPES = Object.freeze({
  development: 'Development',
  sandbox: 'Sandbox',
  production: 'Production',
})

const environmentType = () => {
  const from = process.env.REACT_APP_ENVIRONMENT_TYPE || process.env.NODE_ENV
  return ENVIRONMENT_TYPES[from?.toLowerCase()]
}

export const GET_FEATURE_FLAGS = gql`
  query GetFeatureFlags {
    featureFlags(stage: PUBLISHED, where: { environments_contains_all: ${environmentType()} }) {
      ${FEATURE_FLAGS_FIELDS}
    }
  }
`
