import { Button as ReakitButton } from 'reakit/Button'
import styled from 'styled-components'

export const Button = styled(ReakitButton)`
  display: flex;
  align-items: center;
  padding: 24px 16px;
  text-align: left;
  width: 100%;

  /* button overrides */
  background: none;
  cursor: pointer;
  transition: all 0.2s ease;

  color: ${({ theme }) => theme.colors.brand.black.hex()};
  border: ${({ theme }) => theme.borders.light};
  ${({ theme }) => theme.font.styles.inputDefault};
  ${props => props.theme.input.container};

  &:hover {
    border: ${props => props.theme.toggles.styles.active.border};
  }

  svg {
    border: 1px solid ${({ theme }) => theme.colors.brand.gray40.hex()};
    border-radius: 50%;
    fill: ${({ theme }) => theme.colors.brand.white.hex()};
  }

  &[aria-checked='true'] {
    border: 1.5px solid ${({ theme }) => theme.colors.brand.black.hex()};
    background-color: ${({ theme }) => theme.colors.brand.white.hex()};
  }
  &[aria-checked='true'][aria-disabled='true'] {
    border: 1.5px solid ${({ theme }) => theme.colors.brand.gray30.hex()};
    background-color: ${({ theme }) => theme.colors.brand.white.hex()};
  }

  &[aria-checked='true'] svg {
    border: 1px solid ${({ theme }) => theme.colors.brand.white.hex()};
    fill: ${({ theme }) => theme.colors.brand.black.hex()};
  }
  &[aria-checked='true'][aria-disabled='true'] svg {
    border: 1px solid ${({ theme }) => theme.colors.brand.white.hex()};
    fill: ${({ theme }) => theme.colors.brand.gray60.hex()};
  }
`
