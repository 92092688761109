import React from 'react'
import { useHistory } from 'react-router-dom'

import { DoubleCTA } from '../../components/DoubleCTA/DoubleCTA'
import { Page } from '../../components/Page/Page'
import { useText } from '../../content'
import { useConfig } from '../../contexts/appConfig'
import { useSession } from '../../contexts/session'
import { Container, Form, Header, Paragraph } from './Auth.styles'
import { BackgroundImageSection } from './components/BackgroundImageSection'

export const MigrationCompletedPage = () => {
  const history = useHistory()
  const { text } = useText()
  const { getAsset } = useConfig()

  const {
    state: { user },
  } = useSession()

  const logoSrc = getAsset('RewardsProgramLogo')?.url
  const welcomeMessage = text('MigrateCompleted.Message', { brand: text('Brand.Name'), user })

  const handleStartOrder = () => {
    history.push('/')
  }

  return (
    <Page isScrollable>
      <BackgroundImageSection tabIndex="0" aria-label={welcomeMessage}>
        <Container>
          <Form noValidate style={{ alignItems: 'center' }}>
            <img style={{ marginBottom: 30 }} width="25%" src={logoSrc} />
            <Header style={{ marginBottom: 20 }}>{text('MigrateCompleted.Heading', { brand: text('Brand.Name'), user })}</Header>
            <Paragraph style={{ marginBottom: 32, textAlign: 'center' }}>{welcomeMessage}</Paragraph>

            <div style={{ width: '100%' }}>
              <DoubleCTA
                stacked
                buttons={[
                  {
                    label: 'Start An Order',
                    onClick: handleStartOrder,
                    variant: 'primary',
                  },
                  {
                    label: 'View My Rewards',
                    onClick: handleStartOrder,
                    variant: 'secondary',
                  },
                ]}
              />
            </div>
          </Form>
        </Container>
      </BackgroundImageSection>
    </Page>
  )
}
