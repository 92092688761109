/**
 * @see {@link https://fkhadra.github.io/react-toastify/api/toast#usages}
 */
import { toast } from 'react-toastify'

const ALLOWED_TYPES = ['error', 'info']

/**
 * This function provides legacy compatibility, please do not remove it, yet :)
 */
const legacyDispatchMessage = ({ err, payload: { message }, type = 'default' }) => {
  if (err) console.error(err)
  return ALLOWED_TYPES.indexOf(type) > -1 ? toast[type](message) : toast(message)
}

const proxy = message => (typeof message === 'string' ? toast(message) : legacyDispatchMessage(message))

const dispatchMessage = Object.assign(proxy, {
  error: (message, err) => {
    if (err) console.error(err)
    toast.error(message)
  },
  info: message => toast.info(message),
})

/**
 * @see {@link https://fkhadra.github.io/react-toastify/api/toast#usages}
 * TODO: customize toast notifications style
 */
export const useMessaging = () => ({ dispatchMessage })
