import React, { useCallback, useMemo, useState } from 'react'

import { useWindowSize } from '../../hooks/useWindowsSize'
import { Footer } from '../Footer/Footer'
import { Header } from '../Header/Header'
import { Main } from '../Main/Main'
import { useResponsiveness } from '../responsive/Responsive'
import { PageContainer } from './Page.styles'

export const Page = ({ children, limitMainMaxHeight = false }) => {
  const [_, windowHeight] = useWindowSize()
  const [headerHeight, setHeaderHeight] = useState()
  const { isMobile } = useResponsiveness()

  const mainHeight = useMemo(() => windowHeight - (headerHeight || 0), [headerHeight, windowHeight])
  const style = useMemo(() => ({ minHeight: mainHeight, maxHeight: !isMobile && limitMainMaxHeight ? mainHeight : '' }), [isMobile, mainHeight])

  const handleHeaderResize = useCallback((width, height) => setHeaderHeight(height), [setHeaderHeight])

  return (
    <PageContainer as="div">
      <Header onResize={handleHeaderResize} />
      <Main style={style}>{children}</Main>
      <Footer />
    </PageContainer>
  )
}
