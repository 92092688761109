import React from 'react'

import { Container, ContainerSimple } from './ControlContainer.styles'

export const ControlContainer = ({ children, onClick, ...rest }) => {
  return (
    <Container onClick={onClick} {...rest}>
      {children}
    </Container>
  )
}

export const ControlContainerSimple = ({ children, onClick, ...rest }) => {
  return (
    <ContainerSimple onClick={onClick} {...rest}>
      {children}
    </ContainerSimple>
  )
}
