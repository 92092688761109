import debounce from 'lodash.debounce'
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDialogState } from 'reakit/Dialog'

import { Footer } from '../../../components/Footer/Footer'
import { Header } from '../../../components/Header/Header'
import { Mobile } from '../../../components/responsive/Responsive'
import { useSession } from '../../../contexts/session'
import { useBrandCategories } from '../../../hooks/useBrandCategories'
import { useBrands } from '../../../hooks/useBrands'
import { useMenuItems } from '../../../hooks/useMenuItems'
import { useWindowSize } from '../../../hooks/useWindowsSize'
import { BrowseAllProducts } from './components/BrowseAllProducts/BrowseAllProducts'
import { CategoryBar } from './components/CategoryBar/CategoryBar'
import {
  VerticalBrowseMain,
  VerticalBrowseMenuContainer,
  VerticalBrowseMenuFixedContainer,
  VerticalBrowseMenuSpacer,
} from './VerticalBrowseMenu.styles'

export const VerticalBrowseMenu = () => {
  const headerRef = useRef()
  const [headerHeight, setHeaderHeight] = useState()
  const {
    state: { selectedBrand },
  } = useSession()
  const itemDialogProps = useDialogState({ animated: true })
  const location = useLocation()
  useWindowSize()

  const offsetLimiterRef = useRef(null)

  const { brands, loading: loadingBrands } = useBrands()
  const { items, loadingItems } = useMenuItems()

  const filteredBrands = useMemo(
    () => brands?.filter(category => items?.menu?.some(item => item.metadata.some(metadata => metadata.value === category.code))),
    [brands, items],
  )

  const hasBrands = !loadingBrands && filteredBrands?.length > 0
  const categories = useBrandCategories(hasBrands, selectedBrand, items?.menu)
  const [selectedCategory, setSelectedCategory] = useState()

  const [isSticky, setSticky] = useState(false)
  const [offsetLimit, setOffsetLimit] = useState(null)

  const hasSelectedBrand = !loadingBrands && (!hasBrands || !!selectedBrand)
  const showCategoryBar = !loadingItems && hasSelectedBrand

  const changeCategory = subCategory => {
    console.log(subCategory)
    if (!subCategory) return
    setSelectedCategory(subCategory)
  }

  useEffect(() => {
    if (location?.state?.item) {
      itemDialogProps.show()
    }
  }, [location?.state?.item])

  useEffect(() => {
    setSelectedCategory(categories?.[0])
  }, [categories])

  useLayoutEffect(() => {
    const offsetLimit = offsetLimiterRef?.current?.offsetTop
    setOffsetLimit(offsetLimit)
  }, [])

  useLayoutEffect(() => {
    const handleScroll = debounce(() => {
      const isSticky = (!hasBrands || selectedBrand) && window.scrollY >= offsetLimit
      setSticky(isSticky)
    }, 50)
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [offsetLimit, hasBrands, selectedBrand])

  useEffect(() => {
    setHeaderHeight(headerRef.current.clientHeight)
  })

  return (
    <Mobile>
      {isMobile => (
        <VerticalBrowseMenuContainer>
          <VerticalBrowseMenuFixedContainer ref={headerRef}>
            <Header />
            {isMobile && (
              <CategoryBar selectedCategoryId={selectedCategory?.id} categories={categories} isVisible={showCategoryBar} onClick={changeCategory} />
            )}
          </VerticalBrowseMenuFixedContainer>
          <VerticalBrowseMain>
            <VerticalBrowseMenuSpacer $headerHeight={headerHeight} />
            <BrowseAllProducts headerHeight={headerHeight} isMobile={isMobile} selectedCategory={selectedCategory} />
          </VerticalBrowseMain>
          <Footer style={{ zIndex: 3 }} />
        </VerticalBrowseMenuContainer>
      )}
    </Mobile>
  )
}
