import { Input as ReakitInput } from 'reakit/Input'
import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
`

export const Input = styled(ReakitInput)`
  ${({ theme }) => theme.font.styles.labelInput}
  background-color: ${({ theme }) => theme.colors.brand.white};
  border: ${props => props.theme.borders.light};
  color: ${props => props.theme.colors.brand.black};
  margin-top: 8px;
  outline: 0;
  padding: 24px 16px;
  width: 100%;
  ${props => props.theme.input.container};
  appearance: none;
`
