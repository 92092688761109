import moment from 'moment-timezone'

export const sortCalendarRanges = ranges => ranges?.sort((a, b) => compareTo(a.start, b.start)).sort((a, b) => compareTo(a.end, b.end)) || []

export const findCurrentRange = (ranges, now) => ranges?.find(({ start, end }) => now.isBetween(start, end, 'minutes', '[]'))

export const findNextRange = (ranges, now) => ranges?.find(({ start }) => now.isBefore(start, 'minutes'))

export const compareTo = (fst, snd) => {
  const first = moment(fst)
  const second = moment(snd)

  if (first.isBefore(second)) {
    return -1
  }

  if (first.isAfter(second)) {
    return 1
  }

  return 0
}
