import { Input as ReakitInput } from 'reakit/Input'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  position: relative;

  background-color: ${({ theme }) => theme.colors.brand.white};
  border: 1px solid ${props => props.theme.colors.brand.gray30};
  border-radius: 3px;
  margin-bottom: 10px;
  padding: 24px 16px;

  transition: all 0.2s ease;

  &:focus-within {
    border: 1.5px solid ${props => props.theme.colors.brand.black};
    background-color: ${({ theme }) => theme.colors.brand.white};
  }

  ${props => props.theme.input.container};

  ${props =>
    props.isRequired &&
    !props.isFilled &&
    css`
      background-color: ${({ theme }) => theme.colors.backgroundRequiredInput};
    `}

  label {
    position: absolute;
    left: 16px;
    top: 28px;

    color: ${props => props.theme.colors.brand.black};
    ${({ theme }) => theme.font.styles.labelInput}
    font-size: 14px;
    pointer-events: none;
    text-transform: uppercase;
    transition: all 0.2s ease;

    ${props => props.theme.input.label};
  }
`

export const Input = styled(ReakitInput)`
  border: none;
  color: ${props => props.theme.colors.brand.black};
  ${({ theme }) => theme.font.styles.labelInput}
  background-color: transparent;
  outline: 0;
  width: 100%;

  transition: all 0.2s ease;

  /* hide the placeholder on the input in favor of the animated label, but
    keep it for a11y purposes */
  &::placeholder {
    opacity: 0;
  }

  &:focus::placeholder {
    opacity: 1;
  }

  &[aria-required='true']:placeholder-shown:not(:focus) {
    background-color: ${({ theme }) => theme.colors.backgroundRequiredInput};
  }

  &:focus + label,
  &:not(:placeholder-shown) + label {
    color: ${props => props.theme.colors.brand.gray80};
    font-size: 12px;
    text-transform: unset;
    transform: translateY(-100%);
  }

  &:focus,
  &:not(:placeholder-shown) {
    transform: translateY(45%);
  }

  ${props => props.theme.input.element};
`

export const ErrorLabel = styled.div`
  margin: 0 0 16px 16px;

  ${({ theme }) => theme.font.styles.labelInputError}
`
