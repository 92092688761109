export const isSameMetadata = (key, value) => metadata => metadata?.key === key && metadata?.value === value

export const findByMetadata = (key, value) => entry => entry?.metadata?.some(isSameMetadata(key, value))

export const findMenuItem = (items, key, value) =>
  items?.menu?.reduce((found, category) => found || category?.products?.find(findByMetadata(key, value)), null)

export const findModifierOption = (modifiers, key, value) =>
  modifiers?.reduce((found, modifier) => found || modifier?.options?.find(findByMetadata(key, value)), null)

export const findModifierOptions = (modifiers, key, value) =>
  modifiers?.reduce((found, modifier) => found || modifier?.options?.filter(findByMetadata(key, value)), null)

export const aggreggateQuantityModifiers = (modifiers, minQuantity, maxQuantity) => {
  return modifiers?.map(modifier => {
    return {
      ...modifier,
      minQuantity: Number(minQuantity),
      maxQuantity: Number(maxQuantity),
    }
  })
}
