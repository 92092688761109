import styled from 'styled-components'

import { isMobile } from '../responsive/Responsive'

export const PageContainer = styled.div`
  background: ${props => props.theme.colors.brand.white};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  ${isMobile`
    height: auto;
  `}
`
