import { isSameWeekday, isTimeEqual } from '../utils'

/**
 * @description Extract working hours from calendar ranges
 * @param {Object[]} calendarRanges - normalized calendar ranges using `useRestaurantCalendar`
 * @returns {Object} - Restaurant `workingHours` and `today` (current) range
 */
export const useRestaurantWorkingHours = (currentWeekday, calendarRanges) => {
  const workingHours = []
  let firstDay = null
  let lastDay = null
  let pushNewGroup = false
  let today = {}

  for (const [index, range] of calendarRanges?.entries() || []) {
    if (currentWeekday === range.weekday && range.start && range.end) {
      today = range
    }

    if (!firstDay) {
      firstDay = range
    } else if (range.closed === firstDay?.closed && isTimeEqual(range.start, firstDay?.start) && isTimeEqual(range.end, firstDay?.end)) {
      lastDay = range
      pushNewGroup = index === calendarRanges.length - 1
    } else {
      pushNewGroup = true
    }

    if (pushNewGroup) {
      workingHours.push({ firstDay, lastDay })
      pushNewGroup = !isSameWeekday(range.start, lastDay?.start) && !isTimeEqual(range.end, lastDay?.end)
      firstDay = range
      lastDay = null
    }
  }

  if (pushNewGroup) {
    workingHours.push({ firstDay, lastDay })
  }

  return { workingHours, today }
}
