import { flatten } from 'lodash'
import fp from 'lodash/fp'
import React, { useCallback, useMemo } from 'react'
import { useDialogState } from 'reakit'

import { useText } from '../../../content'
import { useFavoritesForItem } from '../../../contexts/favorites'
import { useSession } from '../../../contexts/session'
import { findImage, formatCurrency } from '../../../data/utils'
import { useMessaging } from '../../../hooks/useMessaging'
import { Button } from '../../Button/Button'
import { ModalHeader } from '../../ModalHeader/ModalHeader'
import { ModalWithBackdrop } from '../../ModalWithBackdrop/ModalWithBackdrop'
import {
  ActionContainer,
  ActionText,
  CardContainer,
  Content,
  DescriptionText,
  FavoriteIcon,
  ItemImage,
  ItemTitle,
  RawActionContainer,
} from './FavoriteItemCard.styles'

const zipOptions = (favOptions, restaurantOptions) =>
  favOptions.reduce((acc, favOption) => {
    const restaurantOption = restaurantOptions.find(o => o.id === favOption.optionId)
    return restaurantOption ? [...acc, [favOption, restaurantOption]] : acc
  }, [])

const computeDescription = fp.flow(
  fp.map(([favOption, optionData]) => (favOption.quantity > 1 ? `${optionData.name} (${favOption.quantity})` : optionData.name)),
  fp.join(', '),
)

const FavoriteItemCard = React.memo(({ favorite, style }) => {
  const { text } = useText()
  const { dispatchMessage } = useMessaging()
  const { dispatch } = useSession()
  const removeFavoriteDialogProps = useDialogState({ animated: true })
  const { addToBasket, removeFromFavorites } = useFavoritesForItem(favorite.menuItem)

  const name = favorite.data.name || favorite.menuItem?.name

  const flattenedOptions = useMemo(() => flatten(favorite.modifiers.map(m => m.options)), [favorite.modifiers])
  const activeOptions = useMemo(() => zipOptions(favorite.data.activeModifiers, flattenedOptions), [favorite.data.activeModifiers, flattenedOptions])

  const handleAddToBasket = useCallback(
    () =>
      addToBasket(favorite)
        .then(({ data }) => {
          console.log('[FavoriteCard] handleAddToBasket', { favorite, data })
          dispatch({ type: 'SET_BASKET', payload: data?.addToBasket })
          dispatchMessage.info(text('MenuItem.Success.AddToBasket'))
        })
        .catch(error => {
          dispatchMessage.error(text('MenuItem.Errors.AddToBasket'), error)
        }),
    [addToBasket, favorite, dispatch, dispatchMessage, text],
  )

  const description = useMemo(() => computeDescription(activeOptions), [activeOptions])

  const totalCost = useMemo(() => {
    return favorite.data.activeModifiers.reduce((acc, favModifier) => {
      const option = flattenedOptions.find(option => option.id === favModifier.optionId)

      return option?.adjustsParentPrice ? acc + favModifier.quantity * option.cost : acc
    }, favorite.menuItem?.cost)
  }, [flattenedOptions, favorite.menuItem?.cost, favorite.data.activeModifiers])

  return (
    <>
      <div style={style}>
        <CardContainer>
          <Content>
            <ItemImage resizeMode="cover" style={{ height: 100, width: 100 }} source={{ uri: findImage(favorite.menuItem, 'mobile-webapp-menu') }} />

            <div style={{ marginLeft: 24, display: 'flex', flexDirection: 'column', flex: 1 }}>
              <ItemTitle style={{ marginBottom: 6 }}>{name}</ItemTitle>
              <DescriptionText>{description}</DescriptionText>
            </div>
          </Content>
        </CardContainer>

        {favorite.menuItem && (
          <div style={{ flex: 1, flexDirection: 'row', display: 'flex' }}>
            <ActionContainer style={{ borderRightWidth: 0 }} onClick={handleAddToBasket}>
              <ActionText>Add To Bag</ActionText>
            </ActionContainer>
            <ActionContainer style={{ borderRightWidth: 0 }} onClick={removeFavoriteDialogProps.show}>
              <FavoriteIcon style={{ marginRight: 8 }} />
              <ActionText>Favorited</ActionText>
            </ActionContainer>
            <RawActionContainer>
              <ActionText style={{ fontWeight: 'bold' }}>{formatCurrency(totalCost)}</ActionText>
            </RawActionContainer>
          </div>
        )}
      </div>

      <ModalWithBackdrop dialogProps={removeFavoriteDialogProps} ariaLabel="Remove from Favorites">
        <ModalHeader title="Remove from Favorites" onClose={removeFavoriteDialogProps.hide} />

        <div style={{ padding: 20, display: 'flex', flexDirection: 'column' }}>
          <span style={{ padding: 20, textAlign: 'center' }}>Are you sure you want to remove this from your favorites?</span>
          <Button label="Remove" variant="primary" onClick={() => removeFromFavorites(favorite?.data).then(() => removeFavoriteDialogProps.hide())} />
          <Button style={{ marginTop: 8 }} variant="secondary" label="Keep" onClick={removeFavoriteDialogProps.hide} />
        </div>
      </ModalWithBackdrop>
    </>
  )
})

export default FavoriteItemCard
