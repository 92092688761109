import React, { useState } from 'react'
import { Checkbox } from 'reakit/Checkbox'
import { useTheme } from 'styled-components'

import { Accordion } from '../../../components/Accordion/Accordion'
import { Button } from '../../../components/Button/Button'
import { useText } from '../../../content'
import { useSession } from '../../../contexts/session'
import { useBasket } from '../../../hooks/useBasket'
import { useLoyalty } from '../../../hooks/useLoyalty'
import { useMessaging } from '../../../hooks/useMessaging'
import {
  ContentContainer,
  DescriptionText,
  ExtraSmallButton,
  IconImage,
  InfoContainer,
  Label,
  RewardButtonContainer,
  RewardToggleButton,
  ToggleCheckIcon,
} from './RewardsSection.styles'

export const RewardsSection = () => {
  const {
    state: { user, basket },
  } = useSession()
  const { rewards, balance, applyReward, applyRewardLoading, removeReward, removeRewardLoading } = useLoyalty()
  const { validateBasket } = useBasket()
  const { text } = useText()
  const { dispatchMessage } = useMessaging()
  const theme = useTheme()

  const [selectedRewardId, setSelectedRewardId] = useState(basket?.appliedRewards[0]?.reference === 'banked' ? 'balance' : undefined)

  const getRewardImage = reward => {
    if (reward?.rewardImageUrl) {
      return {
        uri: reward.rewardImageUrl,
      }
    } else if (reward?.reference === 'banked') {
      return theme.checkout.rewards.currencyButtonIcon
    } else {
      return theme.checkout.rewards.sectionButtonIcon
    }
  }

  const handleRedeem = async () => {
    if (basket?.appliedRewards?.length > 0) {
      await removeReward(basket?.appliedRewards[0].externalReference)
    }
    const isValid = await applyReward({ discountType: 'bankedCurrency', rewardId: 0 })
    if (!isValid) {
      dispatchMessage.error(text('RewardRedemptionModal.Errors.Verify'))
      setSelectedRewardId(null)
    } else {
      setSelectedRewardId('balance')

      const { basket: validatedBasket } = await validateBasket()
      if (!validatedBasket) {
        dispatchMessage.error(text('RewardRedemptionModal.Errors.Verify'))
        setSelectedRewardId(null)
      }
    }
  }

  const handleRemove = async () => {
    if (basket?.appliedRewards?.length > 0) {
      await removeReward()
      setSelectedRewardId(null)
    }
  }

  const handleSelectReward = async reward => {
    setSelectedRewardId(reward.rewardId)
    if (basket?.appliedRewards?.[0]?.externalReference) {
      await removeReward(basket?.appliedRewards[0].externalReference)
    }
    if (reward.rewardId === selectedRewardId) {
      setSelectedRewardId(null)
    } else {
      const isValid = await applyReward(reward)
      if (!isValid) {
        setSelectedRewardId(null)
        return
      }

      const { basket: validatedBasket } = await validateBasket()
      if (!validatedBasket) {
        dispatchMessage.error(text('RewardRedemptionModal.Errors.Verify'))
        setSelectedRewardId(null)
      }
    }
  }

  if (!user || ((rewards?.length || []) === 0 && (balance?.bankedCurrency || 0) === 0)) {
    return null
  }

  return (
    <>
      {(basket?.appliedRewards || []).length === 0 && (
        <>
          {balance?.bankedCurrency > 0 && (
            <RewardButtonContainer>
              <IconImage variant="currency" />
              <InfoContainer>
                <Label>{text('Checkout.Rewards.BankedCurrency.Heading', { bankedCurrency: balance?.bankedCurrency })}</Label>
                <DescriptionText>{text('Checkout.Rewards.BankedCurrency.Description')}</DescriptionText>
              </InfoContainer>
              <Button
                as={ExtraSmallButton}
                variant="secondary"
                isSmall
                label={text('Checkout.Rewards.BankedCurrency.Redeem')}
                onClick={handleRedeem}
                loading={applyRewardLoading || removeRewardLoading}
              />
            </RewardButtonContainer>
          )}
          {(rewards || []).length > 0 && (
            <Accordion
              buttonContent={
                <>
                  <IconImage />
                  <InfoContainer>
                    <Label>{text('Checkout.Rewards.Heading', { rewards })}</Label>
                    <DescriptionText>{text('Checkout.Rewards.Description')}</DescriptionText>
                  </InfoContainer>
                </>
              }
              buttonStyle={{ padding: '16px 20px' }}
            >
              <ContentContainer>
                {rewards?.map(reward => (
                  <Checkbox
                    as={RewardToggleButton}
                    key={reward.rewardId}
                    checked={reward.rewardId === selectedRewardId}
                    onClick={() => handleSelectReward(reward)}
                  >
                    <IconImage source={getRewardImage(reward)} />
                    <InfoContainer>
                      <Label>{reward.name}</Label>
                      <DescriptionText>{reward.description}</DescriptionText>
                    </InfoContainer>
                    <ToggleCheckIcon />
                  </Checkbox>
                ))}
              </ContentContainer>
            </Accordion>
          )}
        </>
      )}
      {basket?.appliedRewards?.length > 0 &&
        basket.appliedRewards.map(reward => (
          <RewardButtonContainer key={reward.rewardId}>
            <IconImage source={getRewardImage(reward)} />
            <InfoContainer>
              <Label>{reward.label}</Label>
              <DescriptionText>{reward.finePrint}</DescriptionText>
            </InfoContainer>
            <Button
              as={ExtraSmallButton}
              variant="secondary"
              isSmall
              label={text('Checkout.Rewards.BankedCurrency.Remove')}
              onClick={handleRemove}
              loading={applyRewardLoading || removeRewardLoading}
            />
          </RewardButtonContainer>
        ))}
    </>
  )
}
