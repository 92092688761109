import { useApolloClient } from '@apollo/client'
import { useCallback, useState } from 'react'

import { useSession } from '../contexts/session'
import { UPDATE_BASKET_DELIVERY_ADDRESS } from '../data/basket/queries'
import { getMessageFromError } from '../data/errors'
import { useMessaging } from './useMessaging'

export const useUpdateBasketDeliveryAddress = () => {
  const [loading, setLoading] = useState(false)
  const { dispatch, state } = useSession()
  const { dispatchMessage } = useMessaging()
  const apolloClient = useApolloClient()
  const [valid, setValid] = useState(true)

  const updateBasketDeliveryAddress = useCallback(
    ({ aptNumber, city, phoneNumber, specialInstructions, streetAddress, zipCode }) => {
      const variables = {
        basketId: state?.basket.id,
        city,
        phoneNumber,
        specialInstructions,
        streetAddress,
        aptNumber,
        zipCode,
      }
      console.debug('[useUpdateBasketDeliveryAddress] updateBasketDeliveryAddress', { variables })
      setLoading(true)
      return apolloClient
        .mutate({
          mutation: UPDATE_BASKET_DELIVERY_ADDRESS,
          variables,
        })
        .catch(error => {
          setValid(false)
          dispatchMessage.error(getMessageFromError(error), error)
          throw error
        })
        .then(response => {
          return response?.data?.updateBasketDeliveryAddress
        })
        .then(updatedBasket => {
          if (!updatedBasket) return
          setValid(true)
          dispatch({ type: 'SET_BASKET', payload: updatedBasket })
        })
        .finally(() => setLoading(false))
    },
    [state?.basket?.id, apolloClient, dispatchMessage],
  )

  return { loading, updateBasketDeliveryAddress, valid }
}
