import { useMutation } from '@apollo/client'
import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { DoubleCTA } from '../../components/DoubleCTA/DoubleCTA'
import { Page } from '../../components/Page/Page'
import { TextInput } from '../../components/TextInput/TextInput'
import { useText } from '../../content'
import { RESET_PASSWORD } from '../../data/loyalty/queries'
import { useMessaging } from '../../hooks/useMessaging'
import { Container, Form, HeaderText, Paragraph } from './Auth.styles'
import { BackgroundImageSection } from './components/BackgroundImageSection'

export const ForgotPasswordPage = props => {
  const history = useHistory()
  const { text } = useText()
  const { dispatchMessage } = useMessaging()

  const [email, setEmail] = useState('')

  const [resetPasswordMutation, { loading }] = useMutation(RESET_PASSWORD, {
    onError: useCallback(
      err =>
        dispatchMessage({
          type: 'error',
          payload: { message: text('ForgotPassword.Errors.Submit') },
          err,
        }),
      [text, dispatchMessage],
    ),
  })

  const handleGoBack = () => {
    history.goBack()
  }

  const handleResetPassword = async event => {
    event.preventDefault()
    if (email) {
      const response = await resetPasswordMutation({ variables: { email } })
      if (response?.data?.sendPasswordReset) {
        dispatchMessage.info(text('ForgotPassword.Success.Submit'))
        handleGoBack()
      } else {
        dispatchMessage({
          type: 'error',
          payload: { message: text('ForgotPassword.Errors.Submit') },
        })
      }
    }
  }

  const headerText = 'Forgot Password'

  return (
    <Page isScrollable>
      <BackgroundImageSection tabIndex="0" aria-label={headerText}>
        <Container>
          <HeaderText>{headerText}</HeaderText>
          <Form noValidate onSubmit={handleResetPassword}>
            <Paragraph>Enter your email below and we’ll send you an email to reset your password.</Paragraph>
            <TextInput
              required
              placeholder="Email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              style={{ marginTop: 32, marginBottom: 32 }}
              name="username"
            />
            <DoubleCTA
              stacked
              buttons={[
                {
                  label: 'Reset',
                  type: 'submit',
                  variant: 'primary',
                  loading,
                },
                {
                  label: 'Go Back',
                  onClick: handleGoBack,
                  variant: 'secondary',
                  loading,
                },
              ]}
            />
          </Form>
        </Container>
      </BackgroundImageSection>
    </Page>
  )
}
