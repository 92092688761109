import { useMutation } from '@apollo/client'
import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDialogState } from 'reakit'

import { useConfig } from '../../../contexts/appConfig'
import { useSession } from '../../../contexts/session'
import { UPDATE_BASKET_PRODUCT } from '../../../data/basket/queries'
import { formatCurrency } from '../../../data/utils'
import { CircleLoadingIndicator } from '../../CircleLoadingIndicator/CircleLoadingIndicator'
import { ModalWithBackdrop } from '../../ModalWithBackdrop/ModalWithBackdrop'
import { QuantityInput } from '../../QuantityInput/QuantityInput'
import { RemoveItemConfirmationModal } from '../RemoveItemConfirmationModal/RemoveItemConfirmationModal'
import {
  CardContainer,
  ChevronIcon,
  ChevronIconContainer,
  Controls,
  IngredientsList,
  ItemCost,
  ItemName,
  Meta,
  ModifyButton,
  QuantityContainer,
} from './Card.styles'

export const BasketCard = ({ basketProduct }) => {
  const removeLastItemDialogProps = useDialogState({ animated: true })
  const { state, dispatch } = useSession()
  const { basket } = state

  const { getPreference } = useConfig()
  const [expanded, setExpanded] = useState(() => {
    const { BasketCardExpandedByDefault } = getPreference('OrderingConfiguration', { BasketCardExpandedByDefault: true })
    return BasketCardExpandedByDefault
  })

  const [updateBasketProductMutation, { loading: updateLoading, error: updateError, data: updateBasketProductData }] = useMutation(
    UPDATE_BASKET_PRODUCT,
  )

  const handleIncrement = useCallback(async () => {
    // UPDATE PRODUCT TO INCREMENT QUANTITY
    const basketChoiceIds = basketProduct.choices.map(choice => choice.optionId)

    const variables = {
      basketId: basket?.id,
      basketProductId: basketProduct.id,
      productId: basketProduct.productId,
      quantity: basketProduct.quantity + 1,
      selectedOptions: basketChoiceIds.map(o => (o || '').toString()),
    }

    const {
      data: { updateBasketProduct: updatedBasket },
    } = await updateBasketProductMutation({ variables })

    if (updatedBasket) {
      dispatch({ type: 'SET_BASKET', payload: updatedBasket })
    }
  }, [basket?.id, basketProduct.choices, basketProduct.id, basketProduct.productId, basketProduct.quantity, dispatch, updateBasketProductMutation])

  const handleDecrement = useCallback(async () => {
    if (basketProduct.quantity - 1 === 0) {
      // CONFIRM BEFORE REMOVING PRODUCT
      removeLastItemDialogProps.show()
    } else {
      // UPDATE PRODUCT TO DECREMENT QUANTITY
      const basketChoiceIds = basketProduct.choices.map(choice => choice.optionId)

      const variables = {
        basketId: basket?.id,
        basketProductId: basketProduct.id,
        productId: basketProduct.productId,
        quantity: basketProduct.quantity - 1,
        selectedOptions: basketChoiceIds.map(o => (o || '').toString()),
      }

      const {
        data: { updateBasketProduct: updatedBasket },
      } = await updateBasketProductMutation({ variables })

      if (updatedBasket) {
        dispatch({ type: 'SET_BASKET', payload: updatedBasket })
      }
    }
  }, [basket?.id, basketProduct, dispatch, updateBasketProductMutation])

  // TODO: add edit item later
  // const handleModifyItem = useCallback(() => {
  //   navigation.push('SimpleBuilder', { basketProduct, isEditing: true })
  // }, [basketProduct, navigation])
  return (
    <>
      <CardContainer>
        <div>
          <Meta>
            <ItemName>{basketProduct.name}</ItemName>
            <ItemCost>{formatCurrency(basketProduct.totalCost)}</ItemCost>
          </Meta>

          {expanded && <IngredientsList lightText menuItem={basketProduct} />}
        </div>

        <Controls style={{ marginTop: expanded ? 32 : 18 }}>
          <div style={{ flexDirection: 'row', alignItems: 'center' }}>
            {expanded ? (
              <ChevronIconContainer style={{ marginRight: 16 }} onClick={() => setExpanded(false)}>
                <ChevronIcon />
              </ChevronIconContainer>
            ) : (
              <ModifyButton style={{ marginRight: 16 }} onClick={() => setExpanded(true)}>
                Details
              </ModifyButton>
            )}
            {/* <ModifyButton onClick={handleModifyItem}>
            <ModifyLink>Edit</ModifyLink>
          </ModifyButton> */}
          </div>

          <QuantityContainer>
            {updateLoading && <CircleLoadingIndicator />}

            <QuantityInput
              variant="simple"
              quantity={basketProduct.quantity}
              onIncrement={handleIncrement}
              onDecrement={handleDecrement}
              isLoading={updateLoading}
              min={0}
            />
          </QuantityContainer>
        </Controls>
      </CardContainer>
      <ModalWithBackdrop dialogProps={removeLastItemDialogProps} ariaLabel="Remove confirmation warning">
        <RemoveItemConfirmationModal onClose={removeLastItemDialogProps.hide} basketProduct={basketProduct} />
      </ModalWithBackdrop>
    </>
  )
}
