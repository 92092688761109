import styled, { css } from 'styled-components'

export const Container = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;

  background-color: ${({ isOverlay, theme }) => (isOverlay ? theme.colors.black.alpha(0.25) : theme.colors.secondary)};
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  z-index: 9999;

  ${props =>
    !props.isVisible &&
    css`
      display: none;
    `}
`

export const Text = styled.div`
  color: ${({ theme }) => theme.colors.brand.black};
  margin-top: 20px;
`
