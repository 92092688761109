import { useApolloClient } from '@apollo/client'
import { every, flatten, isEqual } from 'lodash'
import debounce from 'lodash.debounce'
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { ADD_PRODUCT_TO_BASKET } from '../data/basket/queries'
import { GET_MENU, GET_MODIFIERS } from '../data/menu/queries'
import { useFeatureFlags } from '../hooks/useFeatureFlags'
import { useMessaging } from '../hooks/useMessaging'
import { useFirebaseAuth } from './firebaseAuth'
import { useSession } from './session'

const FavoritesContext = createContext()

const transformDoc = doc => ({ ...doc.data(), docId: doc.id })

const zipOptions = (favOptions, restaurantOptions) =>
  favOptions.reduce((acc, favOption) => {
    const restaurantOption = restaurantOptions.find(o => o.id === favOption.optionId)
    return restaurantOption ? [...acc, [favOption, restaurantOption]] : acc
  }, [])

const FavoritesProvider = ({ children }) => {
  const { isAuthenticated, userDocRef } = useFirebaseAuth()
  const apollo = useApolloClient()
  const { FavoriteItems } = useFeatureFlags()
  const { state: sessionState } = useSession()
  const { dispatchMessage } = useMessaging()
  const [state, setState] = useState({ loading: false, favorites: [] })
  const { activeModifiers = [], restaurant } = sessionState

  const isFavoriteFeatureEnabled = useMemo(() => FavoriteItems && isAuthenticated, [FavoriteItems, isAuthenticated])

  const userFavoritesCollection = useMemo(() => userDocRef?.collection('favorites'), [userDocRef])

  const addToBasket = useCallback(
    favorite => {
      const flattenedOptions = flatten(favorite.modifiers.map(m => m.options))
      const activeOptions = zipOptions(favorite.data.activeModifiers, flattenedOptions)
      const selectedOptions = flatten(
        activeOptions.map(([modifier, option]) => [...Array(modifier.quantity || 1).keys()].map(() => String(option.id))),
      )
      const authentication = sessionState?.user
        ? {
            loyaltyAccessToken: sessionState?.user?.accessToken,
            loyaltyAuthenticationToken: sessionState?.user?.authenticationToken,
            orderingAuthenticationToken: sessionState?.user?.orderingAuthenticationToken,
          }
        : null
      const variables = {
        quantity: 1,
        basketId: sessionState?.basket?.id,
        restaurantId: restaurant?.id,
        productId: favorite.menuItem?.id,
        selectedOptions,
        authentication,
      }
      console.debug('[FavoritesProvider] addToBasket', { favorite, variables })
      return apollo.mutate({
        mutation: ADD_PRODUCT_TO_BASKET,
        variables,
      })
    },
    [apollo, restaurant?.id, sessionState?.basket?.id],
  )

  const addToFavorites = useCallback(
    (menuItem, name) => {
      if (!userFavoritesCollection) throw new Error('User not logged in.')

      setState(prev => ({ ...prev, loading: true }))

      const favItem = {
        id: menuItem.id,
        name: name || menuItem.name,
        activeModifiers: activeModifiers.map(({ modifier, option, quantity }) => ({
          quantity,
          optionId: option.id,
          modifierId: modifier.id,
        })),
      }

      console.debug('[FavoritesProvider] addToFavorites', { menuItem, name, favItem })

      return userFavoritesCollection.add(favItem)
    },
    [userFavoritesCollection, activeModifiers],
  )

  const removeFromFavorites = useCallback(
    favorite => {
      if (!userFavoritesCollection) throw new Error('User not logged in.')
      setState(prev => ({ ...prev, loading: true }))
      console.debug('[FavoritesProvider] removeFromFavorites', { favorite })
      return userFavoritesCollection.doc(favorite.docId).delete()
    },
    [userFavoritesCollection],
  )

  const fetchRestaurantMenuForFavorites = useCallback(
    async favorites => {
      setState(prev => ({ ...prev, loading: true }))

      try {
        const menuItems = await (!restaurant?.id
          ? Promise.resolve([])
          : apollo
              .query({ query: GET_MENU, variables: { restaurantId: restaurant.id }, fetchPolicy: 'no-cache' })
              .then(({ data }) => flatten(data.menu.map(category => category.products))))

        const availableFavorites = favorites.filter(favorite => {
          // eslint-disable-next-line eqeqeq
          const menuItem = menuItems.find(it => it.id == favorite.id)
          if (!menuItem) {
            removeFromFavorites(favorite)
            return false
          }
          return true
        })

        const favoritesWithModifiers = await Promise.all(
          availableFavorites.map(favorite =>
            apollo
              .query({ query: GET_MODIFIERS, variables: { menuItemId: favorite.id }, fetchPolicy: 'no-cache' })
              .then(({ data }) => ({ data: favorite, modifiers: data?.modifiers }))
              .catch(error => undefined),
          ),
        ).then(res => res.filter(Boolean))

        const mergedResult = favoritesWithModifiers.reduce((acc, favorite) => {
          // eslint-disable-next-line eqeqeq
          const menuItem = menuItems.find(it => it.id == favorite.data.id)

          return [...acc, menuItem ? { ...favorite, menuItem } : favorite]
        }, [])

        setState(prev => ({ ...prev, favorites: mergedResult }))
      } catch (error) {
        dispatchMessage.error('An error occurred while getting your favorites list.')
      } finally {
        setState(prev => ({ ...prev, loading: false }))
      }
    },
    [apollo, dispatchMessage, restaurant?.id, setState],
  )

  useEffect(() => {
    setState({ loading: true, favorites: [] })

    if (!isAuthenticated || !userFavoritesCollection) {
      setState({ loading: false, favorites: [] })
      return
    }

    const dispose = userFavoritesCollection.onSnapshot(
      debounce(snapshot => {
        if (!snapshot?.docs?.length) {
          setState({ loading: false, favorites: [] })
          return
        }
        fetchRestaurantMenuForFavorites(snapshot.docs.map(transformDoc))
      }, 1000),
      error => {
        console.error(error)
        setState({ addresses: [], loading: false })
      },
    )
    return dispose
  }, [isAuthenticated, userFavoritesCollection, fetchRestaurantMenuForFavorites, setState])

  return (
    <FavoritesContext.Provider value={[state, { addToBasket, addToFavorites, removeFromFavorites }, { isFavoriteFeatureEnabled }]}>
      {children}
    </FavoritesContext.Provider>
  )
}

export const useFavorites = () => useContext(FavoritesContext)

export const useFavoritesForItem = menuItem => {
  const { state: sessionState } = useSession()
  const [{ favorites }, controller] = useFavorites()

  const addToBasket = useCallback(controller.addToBasket, [controller])

  const addToFavorites = useCallback(name => controller.addToFavorites(menuItem, name), [menuItem, controller])

  const favorite = useMemo(() => {
    return favorites.find(({ data: { id, activeModifiers } }) => {
      const menuItemId = menuItem?.id

      const isSameMenuItem = id === menuItemId

      const hasSameOptions = every(
        activeModifiers.map(modifierOption =>
          sessionState.activeModifiers
            .map(({ option, modifier, quantity }) => ({ modifierId: modifier.id, quantity, optionId: option.id }))
            .find(stateModifier => isEqual(stateModifier, modifierOption)),
        ),
      )

      return isSameMenuItem && hasSameOptions
    })
  }, [favorites, menuItem?.id, sessionState.activeModifiers])

  const removeFromFavorites = useCallback(fav => controller.removeFromFavorites(fav || favorite), [favorite, controller])

  return { favorite, addToBasket, addToFavorites, removeFromFavorites }
}

export default FavoritesProvider
