import moment from 'moment'
import React, { useMemo } from 'react'

import { HANDOFF_MODES, isSameHandoffMode } from '../../../constants/handoffModes'
import { useText } from '../../../content'
import { buildOrderConfirmationAddressLine } from '../../../utils/address'
import { Header, Text } from './DefaultOrderConfirmation.styles'

const useOrderFormattedDate = order =>
  useMemo(() => {
    if (!order || !order.readyTime) {
      return null
    }
    const now = moment()
    const { groups } = /(?<year>\d{2})(?<month>\d{2})(?<date>\d{2})\s+(?<hour>\d{2}):(?<minute>\d{2})/.exec(order?.readyTime)

    const date = moment()
      .set('year', groups.year)
      .set('month', groups.month - 1)
      .set('date', groups.date)
      .set('hour', groups.hour)
      .set('minute', groups.minute)

    return date.get('date') === now.get('date') ? date.format('hh:mm A') : date.format('MM/DD hh:mm A')
  }, [order?.readyTime])

export const DefaultOrderConfirmation = ({ order }) => {
  const { text } = useText()
  const formattedDate = useOrderFormattedDate(order)
  return (
    <>
      <Header>{text('OrderConfirmation.Header')}</Header>
      <Text>{text('OrderConfirmation.Line1')}</Text>
      <Text>
        {isSameHandoffMode(order?.deliveryMode, HANDOFF_MODES.PICKUP)
          ? `Pickup ${order?.vendorName} at ${formattedDate}`
          : `Delivery ${buildOrderConfirmationAddressLine(order?.deliveryAddress)} at ${formattedDate}`}
      </Text>
    </>
  )
}
