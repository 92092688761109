import React from 'react'

import { Container, Input } from './PaymentTextInput.styles'

export const PaymentTextInput = React.forwardRef(({ name, placeholder, value = '', style, ...props }, ref) => {
  return (
    <Container ref={ref}>
      <label htmlFor={name}>{placeholder}</label>
      <Input name={name} value={value} {...props} />
    </Container>
  )
})
