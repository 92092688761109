import styled from 'styled-components'

export const AddressCardContainer = styled.div`
  align-items: center;
  border: ${({ theme }) => theme.borders.light};
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: 24px 18px;
`

export const AddressContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
`
