import { useApolloClient } from '@apollo/client'
import * as Sentry from '@sentry/react'
import moment from 'moment'
import React, { useCallback, useReducer, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useText } from '../../content'
import { useSession } from '../../contexts/session'
import { LOGIN } from '../../data/loyalty/queries'
import { useFeatureFlags } from '../../hooks/useFeatureFlags'
import { useMessaging } from '../../hooks/useMessaging'
import firebase, { auth } from '../../services/firebase'
import { DoubleCTA } from '../DoubleCTA/DoubleCTA'
import { TextInput } from '../TextInput/TextInput'
import { Form, Link, Paragraph } from './SignInForm.styles'

const reducer = (state, action) => ({ ...state, [action.type]: action.payload })

export const SignInForm = ({ headingText, onSignInSuccess }) => {
  const { dispatch: dispatchSession } = useSession()
  const { dispatchMessage } = useMessaging()
  const { FirebaseSupport } = useFeatureFlags()
  const { text } = useText()
  const apolloClient = useApolloClient()
  const history = useHistory()

  const [state, dispatch] = useReducer(reducer, { email: '', password: '' })
  const [loading, setLoading] = useState(false)

  const login = useCallback(
    variables =>
      apolloClient.mutate({
        mutation: LOGIN,
        variables,
      }),
    [apolloClient],
  )

  const handleLogin = useCallback(
    async event => {
      setLoading(true)
      event.preventDefault()
      firebase.analytics().logEvent('Sign_In_Selected')

      await login(state)
        .catch(err => {
          dispatchMessage.error(err.message || text('Login.Errors.Default'), err)
          setLoading(false)
        })
        .then(response => response?.data?.loginGuest)
        .then(async loginGuest => {
          if (loginGuest) {
            const { allowPushNotifications, birthday, gender, marketingEmailSubscription, marketingPnSubscription, zipCode } = loginGuest

            const momentToday = moment()
            const momentBirthday = moment(birthday, 'YYYY-MM-DD')
            const diff = momentToday.diff(momentBirthday)
            const age = moment.duration(diff).years()

            firebase.analytics().logEvent('Sign_in_Successful', {
              age,
              allowPushNotifications,
              gender,
              marketingEmailSubscription,
              marketingPnSubscription,
              zipCode,
            })

            Sentry.setUser(loginGuest)

            if (FirebaseSupport) {
              try {
                const firebaseToken = loginGuest?.firebaseToken
                firebaseToken && (await auth.signInWithCustomToken(firebaseToken))
              } catch (err) {
                dispatchMessage.error(err.message, err)
              }
            }

            dispatchSession({ type: 'SET_USER', payload: loginGuest })
            setLoading(false)
            onSignInSuccess && onSignInSuccess(loginGuest)
          }
        })
    },
    [dispatchSession, dispatchMessage, onSignInSuccess, login, FirebaseSupport, state, setLoading],
  )

  const handleCreateAccount = useCallback(() => {
    firebase.analytics().logEvent('Start_Create_Account_Selected')
    history.push('/register')
  }, [history])

  return (
    <Form noValidate onSubmit={handleLogin}>
      {headingText && <Paragraph style={{ marginBottom: 20 }}>{headingText}</Paragraph>}
      <TextInput
        required
        placeholder="Email"
        value={state.email}
        onChange={e => dispatch({ type: 'email', payload: e.target.value })}
        name="username"
      />
      <TextInput
        required
        placeholder="Password"
        type="password"
        value={state.password}
        onChange={e => dispatch({ type: 'password', payload: e.target.value })}
        name="password"
      />
      <Link to="/forgot-password" style={{ alignSelf: 'flex-end', textTransform: 'none', marginBottom: 32 }}>
        Forgot Password
      </Link>
      <DoubleCTA
        stacked
        buttons={[
          {
            label: 'Sign In',
            type: 'submit',
            variant: 'primary',
            loading,
          },
          {
            label: 'Create Account',
            onClick: handleCreateAccount,
            variant: 'secondary',
            loading,
          },
        ]}
      />
    </Form>
  )
}
