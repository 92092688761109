import moment from 'moment-timezone'
import { useEffect, useMemo, useState } from 'react'

import { useSession } from '../contexts/session'
import { findCurrentRange, findNextRange, sortCalendarRanges } from '../services/useRestaurantCalendars'
import { useRestaurantCalendarRanges } from './useRestaurantCalendarRanges'
import { useStoreHoursStatusText } from './useStoreHoursStatusText'

export const useStoreHours = ({ enabled }) => {
  const [now, setNow] = useState(moment())
  const {
    state: { handoffMode, restaurant },
  } = useSession()
  const calendarRanges = useRestaurantCalendarRanges({ handoffMode, restaurant })

  useEffect(() => {
    if (!enabled) return
    const interval = setInterval(() => setNow(moment()), 60e3)
    return () => clearInterval(interval)
  }, [setInterval, setNow, enabled])

  const { currentRange, nextRange } = useMemo(() => {
    if (!enabled || !calendarRanges || calendarRanges.length === 0) return false
    const filteredCalendarRanges = calendarRanges.filter(range => !!range.start && !!range.end)
    const sortedCalendarRanges = sortCalendarRanges(filteredCalendarRanges)
    const currentRange = findCurrentRange(sortedCalendarRanges, now)
    const nextRange = findNextRange(sortedCalendarRanges, now)
    return { currentRange, nextRange }
  }, [calendarRanges, now, enabled])

  const statusText = useStoreHoursStatusText({
    now,
    closeDate: currentRange?.end,
    nextStartDate: nextRange?.start,
  })
  useEffect(() => {
    console.debug('[useStoreHours]', { statusText, now, currentRange, nextRange, calendarRanges })
  }, [statusText, now, currentRange, nextRange, calendarRanges])

  return { statusText }
}
