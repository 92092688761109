import styled from 'styled-components'

import { H1 } from '../../Text/Text.styles'

export const Header = styled(H1)`
  margin-bottom: 12px;
  text-transform: uppercase;
  ${({ theme }) => theme.home.styles.sectionH1};
`

export const Text = styled.p`
  margin-bottom: 8px;
  ${({ theme }) => theme.home.styles.headerText};
`
