import { useMutation } from '@apollo/client'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTheme } from 'styled-components'

import { useText } from '../../../content'
import { useConfig } from '../../../contexts/appConfig'
import { useSession } from '../../../contexts/session'
import { REMOVE_BASKET_PRODUCT } from '../../../data/basket/queries'
import { CloseButton } from '../../Button/Button'
import { DoubleCTA } from '../../DoubleCTA/DoubleCTA'
import { ActionsContainer, Body, CloseButtonContainer, Header, Heading, SubTitle } from './RemoveItemConfirmationModal.styled'

export const RemoveItemConfirmationModal = ({ onClose, basketProduct }) => {
  const theme = useTheme()
  const history = useHistory()
  const { state, dispatch } = useSession()
  const { basket } = state

  const { text } = useText()
  const { resolveNavigationPayload } = useConfig()

  const [removeBasketProductMutation, { loading, error, data }] = useMutation(REMOVE_BASKET_PRODUCT)

  const actionButtons = [
    {
      label: text('Basket.RemoveItemModal.ConfirmButton'),
      onClick: handleRemoveItem,
      variant: 'primary',
    },
    {
      label: text('Basket.RemoveItemModal.CancelButton'),
      onClick: handleNavigateBack,
      variant: 'link',
    },
  ]

  async function handleRemoveItem() {
    // REMOVE PRODUCT
    const variables = {
      basketId: basket?.id,
      basketProductId: basketProduct.id,
    }

    const {
      data: { removeBasketProduct: updatedBasket },
    } = await removeBasketProductMutation({ variables })

    if (updatedBasket) {
      if (updatedBasket.products?.length === 0) {
        // No products left in the basket, so let's reset everything
        dispatch({ type: 'SET_BASKET', payload: null })
        const screen = resolveNavigationPayload('StartOrder')
        history.push(screen)
        // screens.screen should be either "CategoryLandingPage" or "Browse"
      } else {
        dispatch({ type: 'SET_BASKET', payload: updatedBasket })
        handleNavigateBack()
      }
    }
  }

  function handleNavigateBack() {
    onClose()
  }

  return (
    <>
      <Header>
        <CloseButtonContainer>
          <CloseButton variant={theme.basket.backButtonVariant} onClick={onClose} size={32} />
        </CloseButtonContainer>
        <Heading>{text('Basket.RemoveItemModal.Title')}</Heading>
      </Header>

      <Body>
        <SubTitle>{text('Basket.RemoveItemModal.Message')}</SubTitle>

        <ActionsContainer>
          <DoubleCTA buttons={actionButtons} stacked />
        </ActionsContainer>
      </Body>
    </>
  )
}
