export const ErrorCodes = {
  Unknown: 0,
  Firebase: 70,
}

export const getMessageFromError = error => {
  const errors = error?.graphQLErrors || []
  return errors.map(e => parseErrorMessage(e)).join('')
}

const parseErrorMessage = error => {
  let message = error?.message
  if (error?.extensions?.response?.body?.message) {
    message = error?.extensions?.response?.body?.message
  }

  return message
}
