import { Button as ReakitButton } from 'reakit/Button'
import styled from 'styled-components'

import { Description } from '../Text/Text.styles'

export const SectionItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  ${props => (props.$active ? '' : `color: #777;`)}

  padding: 18px 12px;
  border-radius: 6px;
  border: 0.5px solid ${({ theme }) => theme.colors.gray60};
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
`

SectionItem.defaultProps = {
  $active: true,
}

export const SectionItemButton = styled(ReakitButton)`
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  ${props => (props.$active ? '' : `color: #777;`)}

  padding: 18px 12px;
  border-radius: 6px;
  border: 0.5px solid ${({ theme }) => theme.colors.gray60};

  /* button overrides */
  background: none;
  cursor: pointer;
  width: 100%;
`

SectionItemButton.defaultProps = {
  $active: true,
}

export const SectionItemMeta = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 4px;
`

export const SectionItemIcon = styled.div`
  width: 60px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SectionItemTitle = styled(Description)`
  display: block;
  font-weight: bold;
  margin-bottom: 4px;
`

export const SectionItemDescription = styled(Description)`
  display: block;
  margin-bottom: 4px;
`

export const RewardsSectionIconImage = styled.img.attrs(({ theme, source, variant }) => ({
  src: source || (variant === 'currency' ? theme.checkout?.rewards?.currencyButtonIcon : theme.checkout?.rewards?.sectionButtonIcon),
}))`
  height: 41px;
  width: 41px;
  border-radius: 21px;
`
