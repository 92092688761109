import React, { useEffect } from 'react'
import { ThemeProvider } from 'styled-components'

import { theme as defaultTheme } from './default'
import { theme as fgc } from './fgc'
import { theme as littlebeet } from './littlebeet'
import { theme as lpq } from './lpq'
import { theme as meltshop } from './meltshop'

const themes = {
  default: defaultTheme,
  fgc,
  littlebeet,
  lpq,
  meltshop,
}

const theme = themes[process.env.REACT_APP_BRAND_NAME]

const Theme = ({ children }) => {
  useEffect(() => {
    document.body.classList.add(process.env.REACT_APP_BRAND_NAME)
    return () => {
      document.body.classList.remove(process.env.REACT_APP_BRAND_NAME)
    }
  }, [])

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default Theme
