import styled, { css } from 'styled-components'

export const CostText = styled.span`
  ${props => props.theme.font.styles.labelCurrencySmall}
  margin-left: 10px;
`

export const OptionName = styled.span`
  ${({ theme }) => theme.font.styles.labelInput}
`

export const ModifierDescription = styled(OptionName)`
  color: ${({ theme }) => theme.colors.brand.gray70};
`

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const RowContainer = styled.div`
  display: flex;
  align-items: center;
`

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ theme: { controlContainer, borderRadii } }) => css`
    padding: ${controlContainer.padding};

    border: ${controlContainer.border};
    border-radius: ${borderRadii.sm};
    border-top-width: ${controlContainer.borderTopWidth};
    border-right-width: ${controlContainer.borderRightWidth};
    border-bottom-width: ${controlContainer.borderBottomWidth};
    border-left-width: ${controlContainer.borderLeftWidth};
  `}
`

export const ValueDescription = styled.span`
  padding: 0px 8px;
`
