import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'

import { Description } from '../Text/Text.styles'

export const Link = styled(RouterLink)`
  color: black;
  font-size: 16px;
  text-decoration: underline;
  font-family: ${({ theme }) => theme.font.styles.regular};
`

export const Paragraph = Description

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 30px;
`
