import { find } from 'lodash'
import React, { useMemo } from 'react'

import { useText } from '../../../content'
import { useLoyalty } from '../../../hooks/useLoyalty'
import {
  Section,
  SectionItem,
  SectionItemDescription,
  SectionItemIcon,
  SectionItemIconImage,
  SectionItemMeta,
  SectionItemTitle,
  SectionTitle,
} from './FgcRewards.styles'

export const FgcTiersStatus = () => {
  const { loading, balance, program } = useLoyalty()
  const { text } = useText()

  const currentTier = useMemo(() => {
    if (program?.membershipLevels?.length > 0 && balance?.currentMembershipLevelId) {
      return find(program.membershipLevels, l => l.membershipLevelId === balance.currentMembershipLevelId)
    }
  }, [balance, program])

  return (
    <Section>
      <SectionTitle>{text('Profile.Rewards.Tier.Title')}</SectionTitle>
      {(program?.membershipLevels || []).map(tier => (
        <SectionItem key={tier.membershipLevelId} $active={tier.membershipLevelId === currentTier?.membershipLevelId}>
          <SectionItemIcon>
            <SectionItemIconImage $active={tier.membershipLevelId === currentTier?.membershipLevelId} src={tier.backgroundImageUrl} />
          </SectionItemIcon>
          <SectionItemMeta>
            <SectionItemTitle>{tier.name}</SectionItemTitle>
            <SectionItemDescription>{tier.description}</SectionItemDescription>
          </SectionItemMeta>
        </SectionItem>
      ))}
    </Section>
  )
}
