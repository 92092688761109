import styled from 'styled-components'

import { Button } from '../../../components/Button/Button'
import { Description, H3 } from '../../../components/Text/Text.styles'

export const CenterPageContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const MeterContainerSession = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 27px 0px;
  background: rgb(254, 215, 159);
  background: linear-gradient(180deg, rgba(254, 215, 159, 1) 0%, rgba(252, 172, 68, 1) 100%);
`

export const CenterContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  padding: 0px 26px;
`

export const YourPointsText = styled.h3`
  margin: 0px 6px;
  text-transform: uppercase;
`

export const YourPointsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const PointsText = styled(YourPointsText)`
  font-size: 60px;
`

export const MeterContainer = styled.div`
  position: relative;
  width: 100%;
  height: 40px;
  margin-top: 32px;
`

export const MeterBar = styled.div`
  position: absolute;
  top: 10px;
  left: 0px;
  width: ${({ progress }) => progress || '100%'};
  height: 20px;
  border-radius: 10px;
  background-color: ${({ color, theme }) => color || theme.profile.topBackgroundColor};
`

export const MeterIconContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  position: absolute;
  right: 0px;
`

export const MeterValuesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 8px;
  padding-left: 3px;
  padding-right: 7px;
`

export const RewardsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 27px 0px;
`

export const RewardsCenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  padding: 0px 26px;
`

export const RewardCardContainer = styled.div`
  display: flex;
  width: 100%;
  border-radius: 6px;
  flex-direction: row;
  padding: 18px 12px;
  align-items: center;
  padding: 18px 12px;
  margin-bottom: 10px;

  border-radius: 6px;
  border: 0.5px solid ${({ theme }) => theme.colors.gray60};
`

export const RewardImage = styled.div`
  display: flex;
  height: 42px;
  width: 42px;
  margin-right: 16px;
  border-radius: 21px;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.colors.primary};
`

export const RewardButton = styled(Button)`
  flex: 0;
  width: auto;
`

export const Section = styled.div`
  padding: 0px 16px 0px 16px;
`

export const SectionTitle = styled(H3)`
  text-align: center;
  margin-top: 26px;
  margin-bottom: 20px;
`

export const SectionItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  ${props => (props.$active ? '' : `color: #777;`)}

  padding: 18px 12px;
  border-radius: 6px;
  border: 0.5px solid ${({ theme }) => theme.colors.gray60};
`

SectionItem.defaultProps = {
  $active: true,
}

export const SectionItemMeta = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 4px;
`

export const SectionItemIcon = styled.div`
  width: 60px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SectionItemTitle = styled(Description)`
  display: block;
  font-weight: bold;
  margin-bottom: 4px;
`

export const SectionItemDescription = styled(Description)`
  display: block;
  margin-bottom: 4px;
`

export const RewardsSectionIconImage = styled.img.attrs(({ theme, source, variant }) => ({
  src: source || (variant === 'currency' ? theme.checkout?.rewards?.currencyButtonIcon : theme.checkout?.rewards?.sectionButtonIcon),
}))`
  height: 41px;
  width: 41px;
  border-radius: 21px;
`
