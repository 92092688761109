import { ToastContainer } from 'react-toastify'
import styled from 'styled-components'

import { isMobile } from '../responsive/Responsive'

export const StyledToastContainer = styled(ToastContainer)`
  ${isMobile`
    width: 100%;
  `}
  ${props => props.theme.toast};
`
