import styled from 'styled-components'

export const TextArea = styled.textarea`
  color: ${({ theme }) => theme.colors.brand.black};
  ${({ theme }) => theme.font.styles.labelInput};
  ${({ theme }) => theme.input.container};
  min-height: 100px;
  padding: 16px;
  resize: none;
  width: 100%;
`
