import React from 'react'
import { Clickable } from 'reakit/Clickable'

import { CardBodyStyle, CardContainerStyle, CardFooterStyle, CardHeaderStyle, CardListStyle } from './Card.styles'

export const CardHeader = ({ children, ...props }) => (
  <CardHeaderStyle as="div" {...props}>
    {children}
  </CardHeaderStyle>
)

export const CardBody = ({ children, ...props }) => (
  <CardBodyStyle as="div" {...props}>
    {children}
  </CardBodyStyle>
)

export const CardFooter = ({ children, ...props }) => (
  <CardFooterStyle as="div" {...props}>
    {children}
  </CardFooterStyle>
)

export const Card = ({ children, onClick, selected, ...props }) => (
  <CardContainerStyle as="li" onClick={onClick} selected={selected} {...props}>
    <Clickable style={{ outline: 0 }} as="div">
      {children}
    </Clickable>
  </CardContainerStyle>
)

export const CardList = ({ children }) => <CardListStyle as="ul">{children}</CardListStyle>
