import React from 'react'

export const FgcRewardsMeter = ({ barProgress }) => {
  return (
    <svg width="232" height="460" viewBox="0 0 232 460" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="the-meter">
        <rect x="0" y="0" width="232" height="460" fill="black" />
        <path
          d="M141.227 150.503H83.5345C81.5981 150.503 80.0623 148.967 80.0623 147.03C80.0623 145.094 81.5981 143.558 83.5345 143.558H141.227C143.164 143.558 144.7 145.094 144.7 147.03C144.7 148.967 143.164 150.503 141.227 150.503Z"
          fill="white"
        />
        <path
          d="M135.685 165.794H72.5835C70.6471 165.794 69.1113 164.258 69.1113 162.322C69.1113 160.385 70.6471 158.849 72.5835 158.849H135.685C137.622 158.849 139.157 160.385 139.157 162.322C139.157 164.258 137.622 165.794 135.685 165.794Z"
          fill="white"
        />
        <path
          d="M141.227 150.503H83.5345C81.5981 150.503 80.0623 148.967 80.0623 147.03C80.0623 145.094 81.5981 143.558 83.5345 143.558H141.227C143.164 143.558 144.7 145.094 144.7 147.03C144.7 148.967 143.164 150.503 141.227 150.503Z"
          fill="white"
        />
        <path
          d="M146.836 135.278H94.5523C92.6158 135.278 91.08 133.742 91.08 131.806C91.08 129.869 92.6158 128.334 94.5523 128.334H146.836C148.773 128.334 150.309 129.869 150.309 131.806C150.309 133.742 148.706 135.278 146.836 135.278Z"
          fill="white"
        />
        <path
          d="M146.836 135.278H94.5523C92.6158 135.278 91.08 133.742 91.08 131.806C91.08 129.869 92.6158 128.334 94.5523 128.334H146.836C148.773 128.334 150.309 129.869 150.309 131.806C150.309 133.742 148.706 135.278 146.836 135.278Z"
          fill="white"
        />
        <path
          d="M152.379 119.987H105.503C103.567 119.987 102.031 118.451 102.031 116.515C102.031 114.578 103.567 113.042 105.503 113.042H152.379C154.315 113.042 155.851 114.578 155.851 116.515C155.851 118.451 154.248 119.987 152.379 119.987Z"
          fill="white"
        />
        <path
          d="M130.143 181.018H61.6325C59.6961 181.018 58.1603 179.483 58.1603 177.546C58.1603 175.61 59.6961 174.074 61.6325 174.074H130.143C132.079 174.074 133.615 175.61 133.615 177.546C133.548 179.483 132.013 181.018 130.143 181.018Z"
          fill="white"
        />
        <path
          d="M135.685 165.794H72.5835C70.6471 165.794 69.1113 164.258 69.1113 162.322C69.1113 160.385 70.6471 158.849 72.5835 158.849H135.685C137.622 158.849 139.157 160.385 139.157 162.322C139.157 164.258 137.622 165.794 135.685 165.794Z"
          fill="white"
        />
        <path
          d="M200.189 211.601H39.7307C37.7943 211.601 36.2584 210.065 36.2584 208.129C36.2584 206.192 37.7943 204.656 39.7307 204.656H200.256C202.192 204.656 203.728 206.192 203.728 208.129C203.728 210.065 202.126 211.601 200.189 211.601Z"
          fill="white"
        />
        <path
          d="M189.238 226.825H28.713C26.7765 226.825 25.2407 225.29 25.2407 223.353C25.2407 221.417 26.7765 219.881 28.713 219.881H189.171C191.108 219.881 192.644 221.417 192.644 223.353C192.644 225.29 191.108 226.825 189.238 226.825Z"
          fill="white"
        />
        <path
          d="M124.534 196.31H50.6815C48.7451 196.31 47.2093 194.774 47.2093 192.837C47.2093 190.901 48.7451 189.365 50.6815 189.365H124.601C126.537 189.365 128.073 190.901 128.073 192.837C128.073 194.774 126.47 196.31 124.534 196.31Z"
          fill="white"
        />
        <path
          d="M130.143 181.018H61.6325C59.6961 181.018 58.1603 179.483 58.1603 177.546C58.1603 175.61 59.6961 174.074 61.6325 174.074H130.143C132.079 174.074 133.615 175.61 133.615 177.546C133.548 179.483 132.013 181.018 130.143 181.018Z"
          fill="white"
        />
        <path
          d="M152.379 119.987H105.503C103.567 119.987 102.031 118.451 102.031 116.515C102.031 114.578 103.567 113.042 105.503 113.042H152.379C154.315 113.042 155.851 114.578 155.851 116.515C155.851 118.451 154.248 119.987 152.379 119.987Z"
          fill="white"
        />
        <path
          d="M157.921 104.695H116.521C114.585 104.695 113.049 103.16 113.049 101.223C113.049 99.2868 114.585 97.751 116.521 97.751H157.921C159.857 97.751 161.393 99.2868 161.393 101.223C161.393 103.16 159.857 104.695 157.921 104.695Z"
          fill="white"
        />
        <path
          d="M124.534 196.31H50.6815C48.7451 196.31 47.2093 194.774 47.2093 192.837C47.2093 190.901 48.7451 189.365 50.6815 189.365H124.601C126.537 189.365 128.073 190.901 128.073 192.837C128.073 194.774 126.47 196.31 124.534 196.31Z"
          fill="white"
        />
        <path
          d="M200.189 211.601H39.7307C37.7943 211.601 36.2584 210.065 36.2584 208.129C36.2584 206.192 37.7943 204.656 39.7307 204.656H200.256C202.192 204.656 203.728 206.192 203.728 208.129C203.728 210.065 202.126 211.601 200.189 211.601Z"
          fill="white"
        />
        <path
          d="M180.157 43.6639H160.392C158.455 43.6639 156.919 42.1281 156.919 40.1916C156.919 38.2552 158.455 36.7194 160.392 36.7194H180.157C182.093 36.7194 183.629 38.2552 183.629 40.1916C183.629 42.1281 182.093 43.6639 180.157 43.6639Z"
          fill="white"
        />
        <path
          d="M174.614 58.8884H149.441C147.504 58.8884 145.968 57.3526 145.968 55.4161C145.968 53.4797 147.504 51.9439 149.441 51.9439H174.614C176.551 51.9439 178.087 53.4797 178.087 55.4161C178.087 57.3526 176.551 58.8884 174.614 58.8884Z"
          fill="white"
        />
        <path
          d="M157.921 104.695H116.521C114.585 104.695 113.049 103.16 113.049 101.223C113.049 99.2868 114.585 97.751 116.521 97.751H157.921C159.857 97.751 161.393 99.2868 161.393 101.223C161.393 103.16 159.857 104.695 157.921 104.695Z"
          fill="white"
        />
        <path
          d="M163.53 89.4709H127.539C125.602 89.4709 124.067 87.9351 124.067 85.9987C124.067 84.0622 125.602 82.5264 127.539 82.5264H163.53C165.467 82.5264 167.002 84.0622 167.002 85.9987C166.936 87.9351 165.4 89.4709 163.53 89.4709Z"
          fill="white"
        />
        <path
          d="M185.766 28.3726H171.409C169.473 28.3726 167.937 26.8367 167.937 24.9003C167.937 22.9638 169.473 21.428 171.409 21.428H185.766C187.702 21.428 189.238 22.9638 189.238 24.9003C189.238 26.8367 187.635 28.3726 185.766 28.3726Z"
          fill="white"
        />
        <path
          d="M191.308 13.1481H182.36C180.424 13.1481 178.888 11.6122 178.888 9.6758C178.888 7.73934 180.424 6.20354 182.36 6.20354H191.308C193.245 6.20354 194.78 7.73934 194.78 9.6758C194.78 11.6122 193.245 13.1481 191.308 13.1481Z"
          fill="white"
        />
        <path
          d="M185.766 28.3726H171.409C169.473 28.3726 167.937 26.8367 167.937 24.9003C167.937 22.9638 169.473 21.428 171.409 21.428H185.766C187.702 21.428 189.238 22.9638 189.238 24.9003C189.238 26.8367 187.635 28.3726 185.766 28.3726Z"
          fill="white"
        />
        <path
          d="M178.22 242.117H17.762C15.8255 242.117 14.2897 240.581 14.2897 238.644C14.2897 236.708 15.8255 235.172 17.762 235.172H178.22C180.157 235.172 181.693 236.708 181.693 238.644C181.693 240.581 180.157 242.117 178.22 242.117Z"
          fill="white"
        />
        <path
          d="M189.238 226.825H28.713C26.7765 226.825 25.2407 225.29 25.2407 223.353C25.2407 221.417 26.7765 219.881 28.713 219.881H189.171C191.108 219.881 192.644 221.417 192.644 223.353C192.644 225.29 191.108 226.825 189.238 226.825Z"
          fill="white"
        />
        <path
          d="M169.072 74.1797H138.49C136.553 74.1797 135.017 72.6439 135.017 70.7074C135.017 68.7709 136.553 67.2351 138.49 67.2351H169.072C171.009 67.2351 172.544 68.7709 172.544 70.7074C172.478 72.6439 170.942 74.1797 169.072 74.1797Z"
          fill="white"
        />
        <path
          d="M163.53 89.4709H127.539C125.602 89.4709 124.067 87.9351 124.067 85.9987C124.067 84.0622 125.602 82.5264 127.539 82.5264H163.53C165.467 82.5264 167.002 84.0622 167.002 85.9987C166.936 87.9351 165.4 89.4709 163.53 89.4709Z"
          fill="white"
        />
        <path
          d="M169.072 74.1797H138.49C136.553 74.1797 135.017 72.6439 135.017 70.7074C135.017 68.7709 136.553 67.2351 138.49 67.2351H169.072C171.009 67.2351 172.544 68.7709 172.544 70.7074C172.478 72.6439 170.942 74.1797 169.072 74.1797Z"
          fill="white"
        />
        <path
          d="M24.4394 455.861H15.4249C13.4884 455.861 11.9526 454.325 11.9526 452.389C11.9526 450.452 13.4884 448.916 15.4249 448.916H24.3726C26.3091 448.916 27.8449 450.452 27.8449 452.389C27.8449 454.325 26.3091 455.861 24.4394 455.861Z"
          fill="white"
        />
        <path
          d="M35.3903 440.57H20.967C19.0306 440.57 17.4948 439.034 17.4948 437.097C17.4948 435.161 19.0306 433.625 20.967 433.625H35.3903C37.3267 433.625 38.8625 435.161 38.8625 437.097C38.8625 439.034 37.3267 440.57 35.3903 440.57Z"
          fill="white"
        />
        <path
          d="M46.408 425.345H26.5761C24.6396 425.345 23.1038 423.809 23.1038 421.873C23.1038 419.936 24.6396 418.401 26.5761 418.401H46.408C48.3445 418.401 49.8803 419.936 49.8803 421.873C49.8803 423.809 48.2777 425.345 46.408 425.345Z"
          fill="white"
        />
        <path
          d="M57.3591 410.054H32.1184C30.182 410.054 28.6462 408.518 28.6462 406.582C28.6462 404.645 30.182 403.109 32.1184 403.109H57.3591C59.2955 403.109 60.8313 404.645 60.8313 406.582C60.8313 408.518 59.2955 410.054 57.3591 410.054Z"
          fill="white"
        />
        <path
          d="M57.3591 410.054H32.1184C30.182 410.054 28.6462 408.518 28.6462 406.582C28.6462 404.645 30.182 403.109 32.1184 403.109H57.3591C59.2955 403.109 60.8313 404.645 60.8313 406.582C60.8313 408.518 59.2955 410.054 57.3591 410.054Z"
          fill="white"
        />
        <path
          d="M68.3768 394.763H37.7275C35.791 394.763 34.2552 393.227 34.2552 391.29C34.2552 389.354 35.791 387.818 37.7275 387.818H68.3768C70.3133 387.818 71.8491 389.354 71.8491 391.29C71.8491 393.227 70.2465 394.763 68.3768 394.763Z"
          fill="white"
        />
        <path
          d="M167.269 257.408H6.81096C4.87451 257.408 3.33871 255.872 3.33871 253.936C3.33871 251.999 4.87451 250.464 6.81096 250.464H167.269C169.206 250.464 170.742 251.999 170.742 253.936C170.675 255.805 169.139 257.408 167.269 257.408Z"
          fill="white"
        />
        <path
          d="M178.22 242.117H17.762C15.8255 242.117 14.2897 240.581 14.2897 238.644C14.2897 236.708 15.8255 235.172 17.762 235.172H178.22C180.157 235.172 181.693 236.708 181.693 238.644C181.693 240.581 180.157 242.117 178.22 242.117Z"
          fill="white"
        />
        <path
          d="M79.3277 379.538H43.2696C41.3332 379.538 39.7974 378.002 39.7974 376.066C39.7974 374.129 41.3332 372.594 43.2696 372.594H79.3277C81.2642 372.594 82.8 374.129 82.8 376.066C82.8 378.002 81.2642 379.538 79.3277 379.538Z"
          fill="white"
        />
        <path
          d="M90.3455 364.247H48.8787C46.9423 364.247 45.4064 362.711 45.4064 360.775C45.4064 358.838 46.9423 357.302 48.8787 357.302H90.3455C92.2819 357.302 93.8177 358.838 93.8177 360.775C93.8177 362.711 92.2152 364.247 90.3455 364.247Z"
          fill="white"
        />
        <path
          d="M46.408 425.345H26.5761C24.6396 425.345 23.1038 423.809 23.1038 421.873C23.1038 419.936 24.6396 418.401 26.5761 418.401H46.408C48.3445 418.401 49.8803 419.936 49.8803 421.873C49.8803 423.809 48.2777 425.345 46.408 425.345Z"
          fill="white"
        />
        <path
          d="M35.3903 440.57H20.967C19.0306 440.57 17.4948 439.034 17.4948 437.097C17.4948 435.161 19.0306 433.625 20.967 433.625H35.3903C37.3267 433.625 38.8625 435.161 38.8625 437.097C38.8625 439.034 37.3267 440.57 35.3903 440.57Z"
          fill="white"
        />
        <path
          d="M68.3768 394.763H37.7275C35.791 394.763 34.2552 393.227 34.2552 391.29C34.2552 389.354 35.791 387.818 37.7275 387.818H68.3768C70.3133 387.818 71.8491 389.354 71.8491 391.29C71.8491 393.227 70.2465 394.763 68.3768 394.763Z"
          fill="white"
        />
        <path
          d="M79.3277 379.538H43.2696C41.3332 379.538 39.7974 378.002 39.7974 376.066C39.7974 374.129 41.3332 372.594 43.2696 372.594H79.3277C81.2642 372.594 82.8 374.129 82.8 376.066C82.8 378.002 81.2642 379.538 79.3277 379.538Z"
          fill="white"
        />
        <path
          d="M134.283 303.215H71.1813C69.2449 303.215 67.7091 301.679 67.7091 299.743C67.7091 297.806 69.2449 296.271 71.1813 296.271H134.283C136.219 296.271 137.755 297.806 137.755 299.743C137.755 301.613 136.219 303.215 134.283 303.215Z"
          fill="white"
        />
        <path
          d="M145.301 287.924H76.7235C74.787 287.924 73.2512 286.388 73.2512 284.452C73.2512 282.515 74.787 280.979 76.7235 280.979H145.301C147.237 280.979 148.773 282.515 148.773 284.452C148.706 286.388 147.17 287.924 145.301 287.924Z"
          fill="white"
        />
        <path
          d="M145.301 287.924H76.7235C74.787 287.924 73.2512 286.388 73.2512 284.452C73.2512 282.515 74.787 280.979 76.7235 280.979H145.301C147.237 280.979 148.773 282.515 148.773 284.452C148.706 286.388 147.17 287.924 145.301 287.924Z"
          fill="white"
        />
        <path
          d="M156.252 272.633H82.2658C80.3294 272.633 78.7935 271.097 78.7935 269.16C78.7935 267.224 80.3294 265.688 82.2658 265.688H156.252C158.188 265.688 159.724 267.224 159.724 269.16C159.724 271.097 158.188 272.633 156.252 272.633Z"
          fill="white"
        />
        <path
          d="M167.269 257.408H6.81096C4.87451 257.408 3.33871 255.872 3.33871 253.936C3.33871 251.999 4.87451 250.464 6.81096 250.464H167.269C169.206 250.464 170.742 251.999 170.742 253.936C170.675 255.805 169.139 257.408 167.269 257.408Z"
          fill="white"
        />
        <path
          d="M156.252 272.633H82.2658C80.3294 272.633 78.7935 271.097 78.7935 269.16C78.7935 267.224 80.3294 265.688 82.2658 265.688H156.252C158.188 265.688 159.724 267.224 159.724 269.16C159.724 271.097 158.188 272.633 156.252 272.633Z"
          fill="white"
        />
        <path
          d="M112.314 333.731H60.0299C58.0935 333.731 56.5577 332.195 56.5577 330.259C56.5577 328.322 58.0935 326.786 60.0299 326.786H112.314C114.251 326.786 115.786 328.322 115.786 330.259C115.786 332.195 114.184 333.731 112.314 333.731Z"
          fill="white"
        />
        <path
          d="M123.265 318.44H65.5723C63.6358 318.44 62.1 316.904 62.1 314.967C62.1 313.031 63.6358 311.495 65.5723 311.495H123.265C125.202 311.495 126.737 313.031 126.737 314.967C126.737 316.904 125.202 318.44 123.265 318.44Z"
          fill="white"
        />
        <path
          d="M101.297 348.955H54.421C52.4846 348.955 50.9488 347.42 50.9488 345.483C50.9488 343.547 52.4846 342.011 54.421 342.011H101.297C103.233 342.011 104.769 343.547 104.769 345.483C104.769 347.42 103.233 348.955 101.297 348.955Z"
          fill="white"
        />
        <path
          d="M112.314 333.731H60.0299C58.0935 333.731 56.5577 332.195 56.5577 330.259C56.5577 328.322 58.0935 326.786 60.0299 326.786H112.314C114.251 326.786 115.786 328.322 115.786 330.259C115.786 332.195 114.184 333.731 112.314 333.731Z"
          fill="white"
        />
        <path
          d="M123.265 318.44H65.5723C63.6358 318.44 62.1 316.904 62.1 314.967C62.1 313.031 63.6358 311.495 65.5723 311.495H123.265C125.202 311.495 126.737 313.031 126.737 314.967C126.737 316.904 125.202 318.44 123.265 318.44Z"
          fill="white"
        />
        <path
          d="M134.283 303.215H71.1813C69.2449 303.215 67.7091 301.679 67.7091 299.743C67.7091 297.806 69.2449 296.271 71.1813 296.271H134.283C136.219 296.271 137.755 297.806 137.755 299.743C137.755 301.613 136.219 303.215 134.283 303.215Z"
          fill="white"
        />
        <path
          d="M90.3455 364.247H48.8787C46.9423 364.247 45.4064 362.711 45.4064 360.775C45.4064 358.838 46.9423 357.302 48.8787 357.302H90.3455C92.2819 357.302 93.8177 358.838 93.8177 360.775C93.8177 362.711 92.2152 364.247 90.3455 364.247Z"
          fill="white"
        />
        <path
          d="M101.297 348.955H54.421C52.4846 348.955 50.9488 347.42 50.9488 345.483C50.9488 343.547 52.4846 342.011 54.421 342.011H101.297C103.233 342.011 104.769 343.547 104.769 345.483C104.769 347.42 103.233 348.955 101.297 348.955Z"
          fill="white"
        />
        <path
          d="M206.49 1.824L206.858 3.104L209.242 2.4V12H210.714V0.799999H209.434L206.49 1.824ZM215.297 5.168L215.489 2.192H220.273V0.799999H214.145L213.729 6.608H216.897C218.257 6.608 219.233 7.392 219.233 8.688C219.233 9.984 218.273 10.752 216.865 10.752C215.633 10.752 214.737 10.176 214.433 9.088L213.169 9.808C213.697 11.36 215.153 12.208 216.865 12.208C217.937 12.208 218.833 11.888 219.585 11.264C220.337 10.64 220.705 9.776 220.705 8.688C220.705 7.584 220.337 6.736 219.601 6.112C218.881 5.488 217.985 5.168 216.929 5.168H215.297ZM229.88 10.624C230.632 9.568 231.016 8.16 231.016 6.4C231.016 4.64 230.632 3.232 229.88 2.176C229.128 1.12 228.056 0.591999 226.696 0.591999C225.336 0.591999 224.264 1.12 223.512 2.176C222.76 3.232 222.376 4.64 222.376 6.4C222.376 8.16 222.76 9.568 223.512 10.624C224.264 11.68 225.336 12.208 226.696 12.208C228.056 12.208 229.128 11.68 229.88 10.624ZM223.864 6.4C223.864 3.6 224.888 2.032 226.696 2.032C228.504 2.032 229.544 3.632 229.544 6.4C229.544 9.168 228.504 10.752 226.696 10.752C224.888 10.752 223.864 9.168 223.864 6.4Z"
          fill="white"
        />
        <path
          d="M206.49 229.224H211.898L207.85 239.016H209.402L213.482 229.144V227.816H206.49V229.224ZM216.405 232.184L216.597 229.208H221.381V227.816H215.253L214.837 233.624H218.005C219.365 233.624 220.341 234.408 220.341 235.704C220.341 237 219.381 237.768 217.973 237.768C216.741 237.768 215.845 237.192 215.541 236.104L214.277 236.824C214.805 238.376 216.261 239.224 217.973 239.224C219.045 239.224 219.941 238.904 220.693 238.280C221.445 237.656 221.813 236.792 221.813 235.704C221.813 234.600 221.445 233.752 220.709 233.128C219.989 232.504 219.093 232.184 218.037 232.184H216.405Z"
          fill="white"
        />
        <path
          d="M44.6849 456.624C45.4369 455.568 45.8209 454.16 45.8209 452.4C45.8209 450.64 45.4369 449.232 44.6849 448.176C43.9329 447.12 42.8609 446.592 41.5009 446.592C40.1409 446.592 39.0689 447.12 38.3169 448.176C37.5649 449.232 37.1809 450.64 37.1809 452.4C37.1809 454.16 37.5649 455.568 38.3169 456.624C39.0689 457.68 40.1409 458.208 41.5009 458.208C42.8609 458.208 43.9329 457.68 44.6849 456.624ZM38.6689 452.4C38.6689 449.6 39.6929 448.032 41.5009 448.032C43.3089 448.032 44.3489 449.632 44.3489 452.4C44.3489 455.168 43.3089 456.752 41.5009 456.752C39.6929 456.752 38.6689 455.168 38.6689 452.4Z"
          fill="white"
        />
      </mask>
      <rect x="0" y="0" width="232" height="460" fill="white" mask="url(#the-meter)" />
      <rect x="0" y={`${100 - barProgress}%`} width="232" height={`${barProgress}%`} fill="#FDCE07" mask="url(#the-meter)" />
    </svg>
  )
}
