import React from 'react'

import { RewardsSectionIconImage, SectionItem, SectionItemDescription, SectionItemIcon, SectionItemMeta, SectionItemTitle } from './RewardCard.styles'

export const RewardCard = ({ children, description, iconProps, loading, name, onClick }) => {
  return (
    <SectionItem disabled={loading} onClick={onClick}>
      <SectionItemIcon>
        <RewardsSectionIconImage {...iconProps} />
      </SectionItemIcon>
      <SectionItemMeta>
        <div style={{ flex: 1 }}>
          <SectionItemTitle>{name}</SectionItemTitle>
          <SectionItemDescription>{description.trim()}</SectionItemDescription>
          {children}
        </div>
      </SectionItemMeta>
    </SectionItem>
  )
}
