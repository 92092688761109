import { Box } from 'reakit/Box'
import styled from 'styled-components'

import { isMobile, isTablet } from '../../responsive/Responsive'

const FooterGroupStyle = styled(Box)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  font-size: 14px;

  a:first-child {
    font-weight: 700;
  }
`

export const LinksGroupsStyle = styled(FooterGroupStyle)`
  > * {
    &:not(:first-child) {
      margin-left: 48px;
    }
  }

  ${isMobile`
    flex-direction: column;
    > * {
      &:not(:first-child) {
        margin-left: 0;
        margin-top: 24px;
      }
    }
    margin-bottom: 48px;
  `}

  ${isTablet`
    > * {
      &:not(:first-child) {
        margin-left: 24px;
      }
    }
    margin-bottom: 24px;
  `}
`

export const SocialGroupsStyle = styled(FooterGroupStyle)`
  > * {
    &:not(:first-child) {
      margin-left: 24px;
    }
  }
`

export const FooterStyle = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: ${props => props.theme.colors.brand.beige};
  padding: 48px;
  width: 100%;

  ${isMobile`
    flex-direction: column;
    padding: 48px;
  `}

  ${isTablet`
    flex-direction: column;
    padding: 40px;
  `}
`

export const LinkStyle = styled.a`
  color: ${props => props.theme.colors.brand.black};
  cursor: pointer;
  font-family: ${props => props.theme.font.styles.regular};
  text-decoration: none;
  text-transform: uppercase;
  &:hover {
    text-decoration: underline;
  }
`
