import { useMutation } from '@apollo/client'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FiCheck, FiChevronDown, FiChevronUp, FiPlus } from 'react-icons/fi'
import { useHistory } from 'react-router-dom'
import { useDialogState } from 'reakit'

import { FullScreenLoading } from '../../../../components/FullScreenLoading/FullScreenLoading'
import { Page } from '../../../../components/Page/Page'
import { Mobile } from '../../../../components/responsive/Responsive'
import { TextInput } from '../../../../components/TextInput/TextInput'
import { UpdateBasketDeliveryAddressModal } from '../../../../components/UpdateBasketDeliveryAddressModal/UpdateBasketDeliveryAddressModal'
import { CHECKOUT_HANDOFF_MODES } from '../../../../constants/checkoutHandoffModes'
import { isDeliveryMode, isSameHandoffMode } from '../../../../constants/handoffModes'
import { PICKUP_TYPES } from '../../../../constants/pickupTypes'
import { useText } from '../../../../content'
import { useSession } from '../../../../contexts/session'
import { REMOVE_BASKET_COUPON, UPDATE_BASKET_COUPON, UPDATE_BASKET_HANDOFF_MODE, UPDATE_BASKET_TIP } from '../../../../data/basket/queries'
import { getMessageFromError } from '../../../../data/errors'
import { formatCurrency } from '../../../../data/utils'
import { useBasket } from '../../../../hooks/useBasket'
import { useCCSF } from '../../../../hooks/useCCSF'
import { useFeatureFlags } from '../../../../hooks/useFeatureFlags'
import { useHandoffMode } from '../../../../hooks/useHandoffMode'
import { useLoyalty } from '../../../../hooks/useLoyalty'
import { useMessaging } from '../../../../hooks/useMessaging'
import { usePaymentMethods } from '../../../../hooks/usePaymentMethods'
import { usePickupTime } from '../../../../hooks/usePickupTime/usePickupTime'
import { useUpdateBasketDeliveryAddress } from '../../../../hooks/useUpdateBasketDeliveryAddress'
import firebase from '../../../../services/firebase'
import { DeliveryTimeModal } from '../../../home/components/SelectTimeModal/DeliveryTimeModal/DeliveryTimeModal'
import { PickupTimeModal } from '../../../home/components/SelectTimeModal/PickupTimeModal/PickupTimeModal'
import {
  ActionLinkLabel,
  Body,
  CancellationPolicyContainer,
  CancellationPolicyText,
  CancellationPolicyTitle,
  CheckBoxContainer,
  CheckoutButton,
  Container,
  Content,
  CostItem,
  CostsContainer,
  CostsLabel,
  CostsNumber,
  DeliveryNotesTextarea,
  DeliveryTipButton,
  DeliveryTipButtonScroll,
  DeliveryTipContainer,
  DeliveryTipDescription,
  FixedBottomActions,
  Heading,
  IngredientsList,
  Meta,
  PaymentMethodsContainer,
  PaymentOption,
  PickupTypeButton,
  PickupTypeContainer,
  ProductContainer,
  ProductName,
  ProductQuantity,
  PromoButton,
  PromoContainer,
  SectionButton,
  SectionLabel,
  SummaryContainer,
  SummaryHeader,
  SummaryLabel,
} from '../../Checkout.styles'
import { ContactInfoModal } from '../../ContactInfoModal'
import { HeroContainer } from '../../HeroContainer/HeroContainer'
import InputTextCustomTip from '../../InputTextCustomTip/InputTextCustomTip'
import { CCSFPaymentModal } from '../../PaymentModal/CCSFPaymentModal/CCSFPaymentModal'
import { RewardsSection } from '../../RewardsSection/RewardsSection'
import { SignInSection } from '../../SignInSection/SignInSection'
import { UtensilsSection } from '../../UtensilsSection/UtensilsSection'

const DELIVERY_TIP_TIERS = {
  10: '10',
  15: '15',
  20: '20',
  CUSTOM: 'CUSTOM',
}

export const CCSFCheckout = () => {
  const inputCustomTipRef = useRef()
  const { text } = useText()
  const { dispatchMessage } = useMessaging()
  const { state, dispatch } = useSession()
  const deliveryTimeModalProps = useDialogState({ animated: true, visible: false })
  const { loading: loadingValidateBasket, totalProductQuantity } = useBasket()
  const { rewards } = useLoyalty()
  const { basket, restaurant, contactInfo, activeAddress, tipTier } = state
  const { displayName, handoffMode } = useHandoffMode()
  const { SignInAtCheckout } = useFeatureFlags()
  const { products } = useBasket()

  const [loading, setLoading] = useState(true)
  const history = useHistory()

  const editDialogProps = useDialogState({ animated: true, visible: false })
  const pickupTimeDialogProps = useDialogState({ animated: true, visible: false })

  const { streetAddress, supportsCurbside, supportsCoupons } = restaurant

  const [inputPromo, setInputPromo] = useState(basket?.coupon?.couponCode || '')
  const [inputPromoErrorMsg, setInputPromoErrorMsg] = useState(null)

  const [selectedPickupType, setSelectedPickupType] = useState(PICKUP_TYPES.INSTORE)

  const [inputCustomTip, setInputCustomTip] = useState('')
  const [selectedDeliveryTip, setSelectedDeliveryTip] = useState(() => {
    if (handoffMode === CHECKOUT_HANDOFF_MODES.DELIVERY && basket?.allowsTip) {
      if (tipTier) {
        return DELIVERY_TIP_TIERS[tipTier]
      } else {
        return DELIVERY_TIP_TIERS[15]
      }
    } else {
      return 0
    }
  })

  const { pickupTime, parsedTimeWanted, loading: loadingUpdateBasketTimeWanted, updatePickupTime } = usePickupTime()
  const { authToken, initCCSF, isSubmitting, isReady: isCheckoutFrameReady, submitBasket } = useCCSF()
  const {
    activePaymentMethod,
    addNewActivePaymentMethod,
    loading: loadingPaymentMethods,
    savedPaymentMethods,
    setActivePaymentMethod,
  } = usePaymentMethods({ authToken, basketId: basket?.id })

  const [updateBasketHandoffMode, { loading: loadingUpdateBasketHandoffMode }] = useMutation(UPDATE_BASKET_HANDOFF_MODE, {
    onError: useCallback(e => dispatchMessage.error(text('Checkout.Errors.UpdateHandoffMode'), e), [dispatchMessage]),
  })

  const [deliveryNotes, setDeliveryNotes] = useState(activeAddress?.specialInstructions)
  const { loading: loadingUpdateBasketDeliveryAddress, updateBasketDeliveryAddress, valid: addressValid } = useUpdateBasketDeliveryAddress()

  const [updateBasketTip, { loading: loadingUpdateBasketTip }] = useMutation(UPDATE_BASKET_TIP, {
    onError: useCallback(e => dispatchMessage.error(text('Checkout.Errors.UpdateTip'), e), [dispatchMessage]),
  })

  const [updateBasketCoupon, { loading: loadingAdd }] = useMutation(UPDATE_BASKET_COUPON)

  const [removeBasketCoupon, { loading: loadingRemove }] = useMutation(REMOVE_BASKET_COUPON)

  useEffect(() => {
    dispatch({ type: 'SET_TIP_TIER', payload: selectedDeliveryTip })
  }, [dispatch, selectedDeliveryTip])

  const saveTipToBasket = useCallback(async () => {
    if (selectedDeliveryTip === DELIVERY_TIP_TIERS.CUSTOM && inputCustomTip === '') {
      return
    }

    try {
      let tipAmount
      if (selectedDeliveryTip === DELIVERY_TIP_TIERS.CUSTOM) {
        tipAmount = parseFloat(inputCustomTip)
      } else {
        tipAmount = (parseFloat(selectedDeliveryTip) / 100) * basket?.subtotal
      }

      const variables = {
        basketId: basket?.id,
        tipAmount: parseFloat(tipAmount.toFixed(2)),
      }

      const {
        data: { updateBasketTip: updatedBasket },
      } = await updateBasketTip({ variables })

      if (updatedBasket) {
        dispatch({ type: 'SET_BASKET', payload: updatedBasket })
      }
    } catch (error) {
      dispatchMessage.error('Something went wrong. Could not update the tip on your basket.', error)
    }
  }, [basket?.id, basket?.subtotal, dispatch, dispatchMessage, inputCustomTip, selectedDeliveryTip, updateBasketTip])

  useEffect(() => {
    const isDelivery = handoffMode === CHECKOUT_HANDOFF_MODES.DELIVERY
    const basketDoesntHaveADeliveryAddress = !basket?.deliveryAddress || !basket?.deliveryAddress?.id
    if (basket && activeAddress && basketDoesntHaveADeliveryAddress && isDelivery) {
      updateBasketDeliveryAddress(activeAddress)
    }
  }, [activeAddress, basket, updateBasketDeliveryAddress])

  useEffect(() => {
    // no need to update the basket if the user is inputting a custom value because
    // it will be updated only onBlur for the custom tip input text
    if (
      handoffMode === CHECKOUT_HANDOFF_MODES.DELIVERY &&
      basket?.allowsTip &&
      selectedDeliveryTip &&
      selectedDeliveryTip !== DELIVERY_TIP_TIERS.CUSTOM
    ) {
      saveTipToBasket()
    }
    // eslint-disable-next-line no-use-before-define
  }, [basket?.allowsTip, handoffMode, saveTipToBasket, selectedDeliveryTip])

  useEffect(() => {
    if (selectedDeliveryTip !== DELIVERY_TIP_TIERS.CUSTOM) {
      setInputCustomTip('')
    }
  }, [selectedDeliveryTip])

  useEffect(() => {
    // Clear saved payment info if unmounting the checkout screen.
    // Reason: If someone sets the payment info but does not submit the order, it still persists
    return () => dispatch({ type: 'SET_ACTIVE_PAYMENT_METHOD', payload: null })
  }, [dispatch])

  useEffect(() => {
    async function updateHandoffMode() {
      if (handoffMode === CHECKOUT_HANDOFF_MODES.PICKUP) {
        // update the handoff mode to pickup/curbside on the basket in olo
        try {
          const variables = {
            basketId: basket?.id,
            handoffMode: selectedPickupType === PICKUP_TYPES.INSTORE ? CHECKOUT_HANDOFF_MODES.PICKUP : CHECKOUT_HANDOFF_MODES.CURBSIDE,
          }

          const {
            data: { updateBasketHandoffMode: updatedBasketHandoffMode },
          } = await updateBasketHandoffMode({ variables })

          if (updatedBasketHandoffMode) {
            dispatch({ type: 'SET_BASKET', payload: updatedBasketHandoffMode })
          }
        } catch (error) {
          console.log(error)
        }
      } else {
        await updateBasketDeliveryAddress({ ...activeAddress, specialInstructions: deliveryNotes }).catch(() => {
          dispatchMessage.error(text('Basket.Update.DeliveryAddress.Error'))
        })
      }
    }
    if (basket) {
      updateHandoffMode()
    }
  }, [basket?.id, handoffMode, selectedPickupType])

  useEffect(() => {
    async function _updateBasketHandoffMode(basket, handoffMode) {
      if (!basket) {
        return
      }
      const variables = { basketId: basket.id, handoffMode }
      const {
        data: { updateBasketHandoffMode: updatedBasketHandoffMode },
      } = (await updateBasketHandoffMode({ variables })) || {}

      if (updatedBasketHandoffMode) {
        dispatch({ type: 'SET_BASKET', payload: updatedBasketHandoffMode })
      }
    }

    if (!isSameHandoffMode(basket.deliveryMode, handoffMode)) {
      const basketIsDelivery = isSameHandoffMode(basket.deliveryMode, CHECKOUT_HANDOFF_MODES.DELIVERY)
      const actualModeIsDelivery = isSameHandoffMode(handoffMode, CHECKOUT_HANDOFF_MODES.DELIVERY)
      const basketHasDeliveryAddress = basket.deliveryAddress?.id != null
      if (loadingUpdateBasketHandoffMode) return

      if (!basketIsDelivery && actualModeIsDelivery && basketHasDeliveryAddress) {
        // pickup delivery
        _updateBasketHandoffMode(basket, CHECKOUT_HANDOFF_MODES.DELIVERY)
        console.debug('basket handoff mode updated to delivery')
      } else if (basketIsDelivery && !actualModeIsDelivery) {
        // delivery pickup
        _updateBasketHandoffMode(basket, CHECKOUT_HANDOFF_MODES.PICKUP)
        console.debug('basket handoff mode updated to pickup')
      }
      // dispatchMessage.error(text('Checkout.Errors.InconsistentHandoffMode'))
    }
  }, [basket, handoffMode, dispatchMessage, updateBasketHandoffMode, dispatch, loadingUpdateBasketHandoffMode])

  const handleNavigateToAddress = useCallback(() => history.push('/'), [history])

  const handleChangeAddress = useCallback(() => {
    return isDeliveryMode(handoffMode) ? editDialogProps.show() : history.push('/')
  }, [editDialogProps, handoffMode, history])

  const handleNavigateToTime = useCallback(() => {
    if (handoffMode === CHECKOUT_HANDOFF_MODES.PICKUP) pickupTimeDialogProps.show()
    else deliveryTimeModalProps.show()
  }, [handoffMode, pickupTimeDialogProps, deliveryTimeModalProps])

  const handleChangeCustomTip = useCallback(tipAmount => {
    const re = /(,+|\.)/g
    const formattedAmount = tipAmount.replace(re, '')

    const finalAmount = new Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(formattedAmount / 100)

    setInputCustomTip(finalAmount)
  }, [])

  const handleBlurDeliveryNotes = () => {
    const newAddress = { ...activeAddress, specialInstructions: deliveryNotes }
    dispatch({ type: 'SET_ACTIVE_ADDRESS', payload: newAddress })
    updateBasketDeliveryAddress({ ...activeAddress, specialInstructions: deliveryNotes }).catch(() => {
      dispatchMessage.error(text('Checkout.Errors.DeliveryNotes'))
    })
  }

  async function handleAddPromo() {
    try {
      setInputPromoErrorMsg(null)

      if (inputPromo.trim().length === 0) {
        setInputPromoErrorMsg('Please enter a valid coupon')
        return
      }

      const variables = {
        basketId: basket?.id,
        couponCode: inputPromo,
      }

      const {
        data: { updateBasketCoupon: updatedBasket },
      } = await updateBasketCoupon({ variables })

      if (updatedBasket) {
        dispatch({ type: 'SET_BASKET', payload: updatedBasket })
        dispatchMessage.info('Coupon successfully applied!')
      }
    } catch (error) {
      setInputPromoErrorMsg(getMessageFromError(error) || 'Invalid Promo Code')
    }
  }

  async function handleRemovePromo() {
    try {
      setInputPromo('')
      setInputPromoErrorMsg(null)

      const variables = {
        basketId: basket?.id,
      }

      const {
        data: { removeBasketCoupon: updatedBasket },
      } = await removeBasketCoupon({ variables })

      if (updatedBasket) {
        dispatch({ type: 'SET_BASKET', payload: updatedBasket })
      }
    } catch (error) {
      setInputPromoErrorMsg('Invalid Promo Code')
    }
  }

  const fullContactInfo = useMemo(() => {
    if (!(contactInfo?.firstName && contactInfo?.lastName && contactInfo?.contactNumber)) {
      return ''
    }

    return `${contactInfo.firstName} ${contactInfo.lastName}, ${contactInfo.contactNumber}`
  }, [contactInfo])

  let ctaState = 'submit'
  if (handoffMode === 'PICKUP' && !pickupTime) {
    ctaState = 'pickupTime'
  } else if (!addressValid) {
    ctaState = 'address'
  } else if (!fullContactInfo || fullContactInfo === '') {
    ctaState = 'contactInfo'
  } else if (!activePaymentMethod) {
    ctaState = 'payment'
  }

  if (SignInAtCheckout && !authToken) {
    ctaState = 'signin'
  }

  useEffect(() => {
    setLoading(
      loadingUpdateBasketDeliveryAddress ||
        loadingUpdateBasketHandoffMode ||
        loadingUpdateBasketTimeWanted ||
        loadingUpdateBasketTip ||
        loadingValidateBasket ||
        loadingPaymentMethods ||
        !isCheckoutFrameReady,
    )
  }, [
    loadingUpdateBasketDeliveryAddress,
    loadingUpdateBasketHandoffMode,
    loadingUpdateBasketTimeWanted,
    loadingUpdateBasketTip,
    loadingValidateBasket,
    loadingPaymentMethods,
    isCheckoutFrameReady,
  ])

  const contactInfoModalProps = useDialogState({ animated: true })
  const paymentDetailsModalProps = useDialogState({ animated: true, visible: false })
  const [paymentsExpanded, setPaymentsExpanded] = useState(false)

  const ChevronIcon = paymentsExpanded ? FiChevronUp : FiChevronDown

  const handleSelectScheduleTime = (_, scheduleTime) => {
    console.debug('[Checkout] handleSelectScheduleTime:', scheduleTime)
    updatePickupTime(scheduleTime)
    pickupTimeDialogProps.hide()
    deliveryTimeModalProps.hide()
  }

  function handleAddPayment() {
    firebase.analytics().logEvent('Add_Payment_Selected')
    if (!activePaymentMethod) {
      paymentDetailsModalProps.show()
    } else {
      setPaymentsExpanded(state => !state)
    }
  }

  const handleSignInSuccess = useCallback(loginGuest => dispatchMessage.info(text('Login.Success', { name: loginGuest.firstName })), [
    dispatchMessage,
    text,
  ])

  return (
    <>
      <ContactInfoModal {...contactInfoModalProps} />
      <CCSFPaymentModal
        addNewActivePaymentMethod={addNewActivePaymentMethod}
        ccsfProps={{ isCheckoutFrameReady, initCCSF }}
        dialogProps={paymentDetailsModalProps}
      />
      <Mobile>
        {isMobile => (
          <Page isScrollable>
            <Container>
              <HeroContainer />

              <Content>
                <FullScreenLoading isOverlay isVisible={loading} />
                <Body>
                  <Heading>{text('Checkout.Title')}</Heading>
                  {SignInAtCheckout && !authToken && (
                    <>
                      <SectionLabel>{text('Checkout.SignInAtCheckout.Label')}</SectionLabel>
                      <SignInSection headingText={text('Checkout.SignInAtCheckout.Heading.Text')} onSignInSuccess={handleSignInSuccess} />
                    </>
                  )}
                  <SectionLabel>{text('Checkout.HandoffMode.Location.Label', { displayName })}</SectionLabel>
                  <SectionButton
                    label={text('Checkout.HandoffMode.Address.Label', { displayName })}
                    content={
                      handoffMode === CHECKOUT_HANDOFF_MODES.PICKUP
                        ? streetAddress
                        : activeAddress && activeAddress?.aptNumber
                        ? `${activeAddress?.streetAddress}, ${activeAddress?.aptNumber} `
                        : activeAddress?.streetAddress
                    }
                    actionComponent={<ActionLinkLabel>{text('Checkout.HandoffMode.Change.Label')}</ActionLinkLabel>}
                    onClick={handleChangeAddress}
                  />
                  <SectionButton
                    isRequired
                    label={text('Checkout.HandoffMode.Time.Label')}
                    content={parsedTimeWanted}
                    actionComponent={handoffMode !== CHECKOUT_HANDOFF_MODES.DELIVERY && <ChevronIcon size={28} />}
                    onClick={handleNavigateToTime}
                  />
                  {handoffMode === CHECKOUT_HANDOFF_MODES.PICKUP && supportsCurbside && (
                    <PickupTypeContainer>
                      <PickupTypeButton
                        isSelected={selectedPickupType === PICKUP_TYPES.INSTORE}
                        onClick={() => setSelectedPickupType(PICKUP_TYPES.INSTORE)}
                      >
                        {text('PickupType.InStore.Label')}
                      </PickupTypeButton>

                      <PickupTypeButton
                        isSelected={selectedPickupType === PICKUP_TYPES.CURBSIDE}
                        onClick={() => setSelectedPickupType(PICKUP_TYPES.CURBSIDE)}
                      >
                        {text('PickupType.Curbside.Label')}
                      </PickupTypeButton>
                    </PickupTypeContainer>
                  )}
                  {handoffMode === CHECKOUT_HANDOFF_MODES.DELIVERY && (
                    <DeliveryNotesTextarea
                      placeholder={text('Checkout.HandoffMode.Instructions.Placeholder')}
                      rows="4"
                      onBlur={handleBlurDeliveryNotes}
                      onChange={event => setDeliveryNotes(event.target.value)}
                      value={deliveryNotes}
                    />
                  )}

                  <SectionLabel>{text('Checkout.ContactInfo.Label')}</SectionLabel>
                  <SectionButton
                    label={text('Checkout.ContactInfo.Button.Label')}
                    content={fullContactInfo}
                    actionComponent={<ActionLinkLabel>{text('Checkout.ContactInfo.Change.Label')}</ActionLinkLabel>}
                    onClick={() => {
                      firebase.analytics().logEvent('Add_Contact_Info_Selected')
                      contactInfoModalProps.show()
                    }}
                    isRequired
                  />

                  <SectionLabel>{text('Checkout.Rewards.Label', { count: rewards?.length || 0 })}</SectionLabel>
                  <RewardsSection />

                  <UtensilsSection />

                  <SectionLabel>{text('Checkout.Payment.Label')}</SectionLabel>
                  <SectionButton
                    isRequired
                    label={text('Checkout.Payment.Button.Label')}
                    content={activePaymentMethod?.description}
                    actionComponent={<ChevronIcon size={28} />}
                    onClick={handleAddPayment}
                  />

                  {paymentsExpanded && (
                    <PaymentMethodsContainer>
                      {savedPaymentMethods?.map?.((paymentMethod, idx) => (
                        <PaymentOption
                          key={paymentMethod.key}
                          onClick={() => setActivePaymentMethod(paymentMethod)}
                          style={{ marginTop: idx > 0 ? 12 : 0 }}
                        >
                          {paymentMethod.description}
                          <CheckBoxContainer selected={activePaymentMethod?.key === paymentMethod.key || false}>
                            <FiCheck style={{ color: 'white' }} />
                          </CheckBoxContainer>
                        </PaymentOption>
                      ))}
                      <PaymentOption onClick={paymentDetailsModalProps.show} style={{ marginTop: 12, textTransform: 'uppercase' }}>
                        {text('Checkout.Payment.Option.Label')}
                        <CheckBoxContainer style={{ border: 'none' }}>
                          <FiPlus size={16} />
                        </CheckBoxContainer>
                      </PaymentOption>
                    </PaymentMethodsContainer>
                  )}

                  {supportsCoupons && (
                    <PromoContainer>
                      <TextInput
                        name="promo"
                        placeholder={text('Checkout.Promo.Placeholder')}
                        disabled={!!basket?.coupon?.couponCode}
                        value={inputPromo}
                        onChange={e => setInputPromo(e.target.value)}
                        errorMessage={inputPromoErrorMsg}
                      />

                      {basket?.coupon?.couponCode ? (
                        <PromoButton
                          variant="primary"
                          label={text('Checkout.Promo.Remove.Label')}
                          onClick={handleRemovePromo}
                          loading={loadingRemove}
                        />
                      ) : (
                        <PromoButton variant="primary" label={text('Checkout.Promo.Apply.Label')} onClick={handleAddPromo} loading={loadingAdd} />
                      )}
                    </PromoContainer>
                  )}
                </Body>

                <Body>
                  <SummaryHeader>
                    <SummaryLabel>{text('Checkout.Summary.Label', { totalProductQuantity })}</SummaryLabel>
                  </SummaryHeader>

                  <SummaryContainer>
                    {products?.map(product => (
                      <ProductContainer key={product.id}>
                        <Meta>
                          <ProductName>
                            {product.name} {product.quantity > 1 && <ProductQuantity>({product.quantity})</ProductQuantity>}
                          </ProductName>
                          <CostsNumber>{formatCurrency(product.totalCost)}</CostsNumber>
                        </Meta>

                        <IngredientsList menuItem={product} lightText />
                      </ProductContainer>
                    ))}

                    <CostsContainer>
                      <hr />

                      <CostItem>
                        <CostsLabel>{text('Checkout.Subtotal.Label')}</CostsLabel>
                        <CostsNumber>
                          {formatCurrency(basket?.subtotal)} + {formatCurrency(basket?.salesTax)}
                        </CostsNumber>
                      </CostItem>
                      {basket.fees.map(fee => {
                        return (
                          <CostItem key={fee.description}>
                            <CostsLabel>{fee.description}</CostsLabel>
                            <CostsNumber>{formatCurrency(fee.amount)}</CostsNumber>
                          </CostItem>
                        )
                      })}

                      {basket?.tip > 0 && (
                        <>
                          <hr />
                          <CostItem>
                            <CostsLabel>{text('Checkout.Tip.Label')}</CostsLabel>
                            <CostsNumber>{formatCurrency(basket?.tip)}</CostsNumber>
                          </CostItem>
                        </>
                      )}
                      {handoffMode === CHECKOUT_HANDOFF_MODES.DELIVERY && basket?.allowsTip && (
                        <DeliveryTipContainer>
                          <DeliveryTipButtonScroll pointerEvents={loadingUpdateBasketTip ? 'none' : 'auto'}>
                            <DeliveryTipButton
                              isSelected={selectedDeliveryTip === DELIVERY_TIP_TIERS[10]}
                              onClick={() => setSelectedDeliveryTip(DELIVERY_TIP_TIERS[10])}
                            >
                              {DELIVERY_TIP_TIERS[10]}%
                            </DeliveryTipButton>

                            <DeliveryTipButton
                              isSelected={selectedDeliveryTip === DELIVERY_TIP_TIERS[15]}
                              onClick={() => setSelectedDeliveryTip(DELIVERY_TIP_TIERS[15])}
                            >
                              {DELIVERY_TIP_TIERS[15]}%
                            </DeliveryTipButton>

                            <DeliveryTipButton
                              isSelected={selectedDeliveryTip === DELIVERY_TIP_TIERS[20]}
                              onClick={() => setSelectedDeliveryTip(DELIVERY_TIP_TIERS[20])}
                            >
                              {DELIVERY_TIP_TIERS[20]}%
                            </DeliveryTipButton>

                            <InputTextCustomTip
                              ref={inputCustomTipRef}
                              placeholder={text('Checkout.Tip.Placeholder')}
                              value={inputCustomTip}
                              onFocus={() => setSelectedDeliveryTip(DELIVERY_TIP_TIERS.CUSTOM)}
                              onBlur={saveTipToBasket}
                              onChange={e => handleChangeCustomTip(e.target.value)}
                            />
                          </DeliveryTipButtonScroll>

                          <DeliveryTipDescription>{text('Checkout.Tip.CourierMessage')}</DeliveryTipDescription>
                        </DeliveryTipContainer>
                      )}

                      {basket?.customerHandoffCharge > 0 && (
                        <CostItem>
                          <CostsLabel>{text('Checkout.DeliveryFee.Label')}</CostsLabel>
                          <CostsNumber>{formatCurrency(basket?.customerHandoffCharge)}</CostsNumber>
                        </CostItem>
                      )}

                      {basket?.coupon.couponCode && (
                        <CostItem>
                          <CostsLabel isPromo>{text('Checkout.Promo.Label', { couponCode: basket?.coupon.couponCode })}</CostsLabel>
                          <CostsNumber isPromo>{formatCurrency(basket?.couponDiscount)}</CostsNumber>
                        </CostItem>
                      )}

                      {basket?.discounts?.length > 0 &&
                        basket.discounts.map((discount, index) => (
                          <CostItem key={index}>
                            <CostsLabel isPromo>{text('Checkout.Discount.Label')}</CostsLabel>
                            <CostsNumber isPromo>{formatCurrency(discount.amount)}</CostsNumber>
                          </CostItem>
                        ))}

                      <hr />

                      <CostItem>
                        <CostsLabel>{text('Checkout.Total.Label')}</CostsLabel>
                        <CostsNumber>{formatCurrency(basket?.total)}</CostsNumber>
                      </CostItem>
                    </CostsContainer>

                    <hr />

                    <CancellationPolicyContainer>
                      <CancellationPolicyTitle>{text('Checkout.CancellationPolicy.Label')}</CancellationPolicyTitle>
                      <CancellationPolicyText>{text('Checkout.CancellationPolicy')}</CancellationPolicyText>
                    </CancellationPolicyContainer>

                    <hr />

                    <FixedBottomActions>
                      {ctaState === 'signin' && <CheckoutButton variant="primary" label={text('Checkout.Cta.SignIn')} onClick={() => {}} disabled />}
                      {ctaState === 'address' && (
                        <CheckoutButton variant="primary" label={text('Checkout.Cta.Address')} onClick={handleNavigateToAddress} />
                      )}
                      {ctaState === 'pickupTime' && (
                        <CheckoutButton variant="primary" label={text('Checkout.Cta.SelectTime')} onClick={handleNavigateToTime} />
                      )}
                      {ctaState === 'contactInfo' && (
                        <CheckoutButton variant="primary" label={text('Checkout.Cta.ContactInfo')} onClick={contactInfoModalProps.show} />
                      )}
                      {ctaState === 'payment' && (
                        <CheckoutButton variant="primary" label={text('Checkout.Cta.PaymentInfo')} onClick={paymentDetailsModalProps.show} />
                      )}
                      {ctaState === 'submit' && (
                        <CheckoutButton
                          variant="primary"
                          label={text('Checkout.Cta.PlaceOrder')}
                          onClick={submitBasket}
                          loading={isSubmitting || loadingValidateBasket}
                        />
                      )}
                    </FixedBottomActions>
                  </SummaryContainer>
                </Body>
              </Content>
            </Container>
            <PickupTimeModal dialogProps={pickupTimeDialogProps} restaurant={restaurant} onSelectScheduleTime={handleSelectScheduleTime} />
            <DeliveryTimeModal dialogProps={deliveryTimeModalProps} restaurant={restaurant} onSelectScheduleTime={handleSelectScheduleTime} />
            <UpdateBasketDeliveryAddressModal dialogProps={editDialogProps} address={{ ...activeAddress, phoneNumber: contactInfo?.contactNumber }} />
          </Page>
        )}
      </Mobile>
    </>
  )
}
