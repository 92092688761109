import { Box } from 'reakit/Box'
import { Button } from 'reakit/Button'
import styled from 'styled-components'

import { InputExtraSmall } from '../../../components/Text/Text.styles'

export const Container = styled(Box)`
  color: ${({ theme }) => theme.colors.brand.gray70};
  font-size: 12px;

  display: flex;
  flex-direction: column;
  margin-top: 48px;
`

export const SummaryText = styled(InputExtraSmall)`
  color: ${props => props.theme.colors.brand.black.hex()};
  margin-bottom: 16px;
  ${({ theme }) => theme.card.restaurant.summaryText};
`

export const PillsContainer = styled.span`
  display: flex;
  flex-direction: row;
  > * {
    &:not(:first-child) {
      margin-left: 4px;
    }
  }
`

export const PillText = styled.div`
  ${props => props.theme.font.styles.labelInputExtraSmall};

  text-align: center;
  text-transform: uppercase;

  color: ${props => props.theme.colors.brand.white};
  background: ${props => props.theme.colors.brand.black};
  border-radius: 100px;

  margin-top: 16px;
  padding: 3px 16px;

  /* button overrides */
  cursor: pointer;
  display: block;
  border: none;
`

export const StartOrderContainer = styled(Box)`
  position: absolute;
  bottom: 16px;
  right: 16px;
`

export const SectionTitle = styled.div`
  margin-bottom: 12px;

  font-size: 22px;
  font-family: ${({ theme }) => theme.font.styles.bold};
  color: ${({ theme }) => theme.colors.brand.black.hex()};
`

export const HistoryEntryContainer = styled.div`
  margin-top: 48px;
`
