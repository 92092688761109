import { Box } from 'reakit/Box'
import styled from 'styled-components'

import { ListItemMarkerIcon } from '../../../components/Icons/Icons'
import { Description } from '../../../components/Text/Text.styles'

export const ContainerStyle = styled(Box)`
  display: flex;
  flex-direction: column;
`

export const DescriptionText = styled(Description)`
  /* overflow wrap ellipsis */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: auto;
`

export const Icon = styled(ListItemMarkerIcon)`
  margin-right: 10px;
`
