import { Box } from 'reakit/Box'
import { Button } from 'reakit/Button'
import styled from 'styled-components'

import { isDesktop, isLargeDesktop, isMobile, isTablet } from '../../../../../components/responsive/Responsive'

export const Container = styled(Box)`
  background: ${props => props.theme.colors.brand.white};
  color: ${props => props.theme.colors.brand.black};
  display: flex;
  flex-direction: column;
`

export const Section = styled.section`
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
  ${isLargeDesktop`
    padding-top: 72px;
    :last-of-type {
      padding-bottom: 72px;
    }
  `};
  ${isDesktop`
    padding-top: 40px;
    :last-of-type {
      padding-bottom: 40px;
    }
  `};
  ${isTablet`
    padding-top: 34px;
    :last-of-type {
      padding-bottom: 34px;
    }
  `};
  ${isMobile`
    padding-top: 36px;
    :last-of-type {
      padding-bottom: 36px;
    }
  `};
  :last-of-type {
    margin-bottom: 0;
  }
`

export const MenuHeader = styled.h1`
  ${props => props.theme.menuItems.cards.menuHeaderStyle};
  z-index: 1;

  > img {
    max-width: 100%;
    max-height: 165px;
  }
`

export const ProductsContainer = styled(Box)`
  z-index: 1;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  width: 100%;
  ${isMobile`
    flex-direction: column;
  `}
  ${isLargeDesktop`
    padding-top: 24px;
    padding-left: 48px;
    padding-right: 48px;
  `};
  ${isDesktop`
    padding-top: 24px;
    padding-left: 48px;
    padding-right: 48px;
  `};
  ${isTablet`
    padding-top: 4px;
    padding-left: 24px;
    padding-right: 24px;
  `};
  ${isMobile`
    padding-top: 20px;
    padding-left: 16px;
    padding-right: 16px;
  `};
`

export const ProductOuterContainer = styled(Box)`
  display: flex;
  ${isLargeDesktop`
    flex-basis: 33.33%;
    padding-right: 24px;
    padding-top: 24px;
    &:nth-child(3n) {
      padding-right: 0;
    }
  `};
  ${isDesktop`
    flex-basis: 33.33%;
    padding-right: 24px;
    padding-top: 24px;
    &:nth-child(3n) {
      padding-right: 0;
    }
  `};
  ${isTablet`
    flex-basis: 50%;
    padding-right: 20px;
    padding-top: 20px;
    &:nth-child(2n) {
      padding-right: 0;
    }
  `};
  ${isMobile`
    padding-bottom: 12px;
    &:last-child {
      padding-bottom: 0;
    }
  `};
`

export const ProductButton = styled(Button)`
  ${props => props.theme.menuItems.cards.style};

  display: flex;
  flex-direction: column;

  /* button overrides */
  cursor: pointer;
  width: 100%;
`

export const ProductImageContainer = styled(Box)`
  background: ${props => `url(${props.src}) no-repeat center center`};
  background-size: ${props => props.resizeMode || props.theme.menuItems.cards.imageResizeMode || 'contain'};
  height: ${props => props.theme.menuItems.cards.imageHeight};
  overflow: hidden;
  width: 100%;
`

export const ProductInnerContainer = styled(Box)`
  padding: 20px;
  text-align: left;
  width: 100%;
  ${isMobile`
    padding: 16px;
  `};
`

export const ProductHeader = styled.h3`
  ${props => props.theme.menuItems.cards.descriptionHeaderStyle};
  margin-bottom: 8px;
`

export const ProductDescriptionText = styled(Box)`
  ${props => props.theme.menuItems.cards.descriptionTextStyle};
`

export const NumberText = styled.span`
  ${props => props.theme.menuItems.cards.numberTextStyle};
`

export const ProductFooterContainer = styled(Box)`
  margin-top: auto;
  padding: 0 20px 20px;
  text-align: left;
  width: 100%;
`

export const ProductBackgroundColor = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${props => props.backgroundColor};
  ${isLargeDesktop`
    height: 318px;
  `};
  ${isDesktop`
    height: 318px;
  `};
  ${isTablet`
    height: 263px;
  `};
  ${isMobile`
    height: 535px;
  `};
`
