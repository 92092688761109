import React, { useState } from 'react'

import { useFavoritesForItem } from '../../../contexts/favorites'
import { Button } from '../../Button/Button'
import { ModalHeader } from '../../ModalHeader/ModalHeader'
import { ModalWithBackdrop } from '../../ModalWithBackdrop/ModalWithBackdrop'
import { TextInput } from '../../TextInput/TextInput'

export const AddFavorieItemModal = ({ dialogProps, menuItem }) => {
  const [favoriteName, setFavoriteName] = useState()
  const { addToFavorites } = useFavoritesForItem(menuItem)
  return (
    <ModalWithBackdrop dialogProps={dialogProps} ariaLabel="Add to Favorites">
      <ModalHeader title="Add to Favorites" onClose={dialogProps.close} />

      <div style={{ padding: 20, display: 'flex', flexDirection: 'column' }}>
        <TextInput
          value={favoriteName}
          style={{ width: '100%' }}
          placeholder="Add a Name (Optional)"
          onChange={e => setFavoriteName(e.target.value)}
        />
        <Button
          label="Add to Favorites"
          variant="primary"
          onClick={() => {
            addToFavorites(favoriteName).then(() => {
              dialogProps.hide()
              setFavoriteName('')
            })
          }}
        />
      </div>
    </ModalWithBackdrop>
  )
}
