import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { useConfig } from '../contexts/appConfig'

/**
 * This `hook` is intended for compatibility purpose with `@react-navigation/native`
 */
export const useNavigation = () => {
  const history = useHistory()
  const { resolveNavigationPayload } = useConfig()
  const navigate = (flowName, params) => {
    const route = resolveNavigationPayload(flowName)
    history.push(route, params)
  }
  return useMemo(() => ({ ...history, navigate }), [history, resolveNavigationPayload])
}
