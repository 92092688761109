import React from 'react'

import { PREFERENCES } from '../../../constants/preferences'
import { useConfig } from '../../../contexts/appConfig'
import { useAsset } from '../../../hooks/useAsset'
import { FacebookIcon, InstagramIcon, TwitterIcon } from '../../Icons/Icons'
import { FooterStyle, LinksGroupsStyle, LinkStyle, SocialGroupsStyle } from './LPQFooter.styles'

const Link = ({ children, target = '_blank', ...props }) => (
  <LinkStyle target={target} {...props}>
    {children}
  </LinkStyle>
)

const ICONS_BY_TYPE = {
  facebook: FacebookIcon,
  instagram: InstagramIcon,
  twitter: TwitterIcon,
}

const SocialIconLink = ({ url, type }) => {
  const Icon = ICONS_BY_TYPE[type]
  return (
    <Link key={type} href={url}>
      <Icon size={16} />
    </Link>
  )
}

export const LPQFooter = props => {
  const { social, links } = useConfig().getPreference('Footer') || {}
  return (
    <FooterStyle as="footer" {...props}>
      <LinksGroupsStyle as="div">
        {links?.map?.(link => (
          <Link key={link.text} href={link.url}>
            {link.text}
          </Link>
        ))}
      </LinksGroupsStyle>
      <SocialGroupsStyle as="div">{social?.map(SocialIconLink)}</SocialGroupsStyle>
    </FooterStyle>
  )
}
