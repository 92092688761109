import styled from 'styled-components'

import { isMobile } from '../../responsive/Responsive'

export const FullDialogBackdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.5);

  transition: opacity 250ms ease-in-out, visibility 250ms;
  transition-delay: visibility 250ms;

  opacity: ${props => (props.visible ? '1' : '0')};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`

export const MainContainer = styled.div`
  background: ${props => props.theme.colors.brand.white};
  display: flex;
  flex-direction: column;
  max-height: 100vh;

  overflow-y: auto;
  transition: transform 250ms ease-in-out;

  /* slide from bottom transition animation */
  &.slideInFromBottom {
    transform: ${props => (props.visible ? 'translateY(0)' : 'translateY(100%)')};
  }

  ${isMobile`
    width: 100%;
  `}
`
