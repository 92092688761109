import React, { useCallback, useLayoutEffect, useRef } from 'react'
import ReactDOM from 'react-dom'

import { useWindowSize } from '../../../hooks/useWindowsSize'
import { clearAllBodyScrollLocks, disableBodyScroll } from '../../../utils/bodyScrollLock'
import { FullDialogBackdrop, SideBar } from './BodyScrollLockDialogWithBackdrop.styles'

export const BodyScrollLockDialogWithBackdrop = ({ children, dialogProps, ariaLabel, slideInFrom = 'right', containerStyle, onBackdropClick }) => {
  const [, windowHeight] = useWindowSize()
  const elementToKeepScroll = useRef()
  const slideInFromLeftClass = slideInFrom === 'left' ? 'slideInFromLeft' : ''

  useLayoutEffect(() => {
    if (!elementToKeepScroll.current || !dialogProps.visible) {
      clearAllBodyScrollLocks()
    } else {
      disableBodyScroll(elementToKeepScroll.current)
    }
  }, [dialogProps.visible])

  const handleDialogClick = useCallback(event => event.stopPropagation(), [])

  const handleBackdropClick = useCallback(
    event => {
      event.stopPropagation()
      onBackdropClick ? onBackdropClick() : dialogProps.hide()
    },
    [dialogProps.hide, onBackdropClick],
  )

  return ReactDOM.createPortal(
    <FullDialogBackdrop {...dialogProps} onClick={handleBackdropClick}>
      <SideBar
        aria-label={ariaLabel}
        aria-modal="true"
        className={slideInFromLeftClass}
        data-dialog="true"
        hideOnClickOutside={false}
        onClick={handleDialogClick}
        ref={elementToKeepScroll}
        role="dialog"
        style={{ ...containerStyle, height: windowHeight }}
        tabIndex={0}
        visible={dialogProps.visible}
      >
        {children}
      </SideBar>
    </FullDialogBackdrop>,
    document.body,
  )
}
