import React from 'react'

import { Container, ErrorLabel, Input } from './TextInput.styles'

export function TextInput({ name, placeholder: label, required, errorMessage, style, value = '', mask = '', ...props }) {
  return (
    <>
      <Container isRequired={required} isFilled={Boolean(value)} style={style}>
        <Input name={name} placeholder={mask} required={required} value={value} focusable {...props} />
        <label htmlFor={name}>{label}</label>
      </Container>
      {errorMessage && <ErrorLabel>{errorMessage}</ErrorLabel>}
    </>
  )
}
