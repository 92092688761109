import React from 'react'
import { useTheme } from 'styled-components'

import { FgcFooter } from './fgc/FgcFooter'
import { LittleBeetFooter } from './littlebeet/LittleBeetFooter'
import { LPQFooter } from './lpq/LPQFooter'
import { MeltshopFooter } from './meltshop/MeltshopFooter'

const FooterConfig = {
  fgc: FgcFooter,
  meltshop: MeltshopFooter,
  littlebeet: LittleBeetFooter,
  lpq: LPQFooter,
}

export const Footer = React.forwardRef((props, ref) => {
  const theme = useTheme()
  const FooterComponent = FooterConfig[theme.brand]
  return <FooterComponent ref={ref} {...props} />
})
