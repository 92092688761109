import React, { useState } from 'react'
import { useDialogState } from 'reakit'

import { AddressCard } from '../../../../components/AddressCard/AddressCard'
import { Button } from '../../../../components/Button/Button'
import { ModalWithBackdrop } from '../../../../components/ModalWithBackdrop/ModalWithBackdrop'
import { useText } from '../../../../content'
import { useAddresses } from '../../../../contexts/addresses'
import { ContentContainer, FormContainer, HeaderContainer, Paragraph } from '../../Profile.styles'
import { AddAddressModal } from './components/AddAddressModal'
import { EditSavedAddressModal } from './components/EditSavedAddressModal'

export const FirebaseSavedAddresses = () => {
  const { addresses, loading, removeAddress, updateAddress, addAddress } = useAddresses()
  const { text } = useText()
  const editDialogProps = useDialogState({ animated: true, visible: false })
  const addDialogProps = useDialogState({ animated: true, visible: false })
  const [editingAddress, setEditingAddress] = useState()

  const handleAddClose = () => {
    addDialogProps.hide()
  }

  const handleEditClose = () => {
    setEditingAddress(null)
    editDialogProps.hide()
  }

  const handleEdit = address => {
    setEditingAddress(address)
    editDialogProps.show()
  }

  return (
    <>
      <HeaderContainer>{text('Profile.SavedAddresses.NavBar.Title')}</HeaderContainer>
      <ContentContainer>
        {loading ? (
          <Paragraph>Loading...</Paragraph>
        ) : (
          <>
            {!addresses?.length && <Paragraph style={{ textAlign: 'center' }}>{text('Profile.SavedAddresses.EmptyMessage')}</Paragraph>}
            <>
              <FormContainer>
                {addresses.map((address, idx) => (
                  <AddressCard
                    address={address}
                    buttons={[{ variant: 'link', label: 'Edit', onClick: () => handleEdit(address), isSmall: false }]}
                    key={address.id}
                    style={{ marginTop: idx > 0 ? 12 : 0 }}
                  />
                ))}

                <Button label="Add new address" variant="primary" style={{ marginTop: 12 }} onClick={addDialogProps.show} />
              </FormContainer>

              <ModalWithBackdrop dialogProps={editDialogProps} ariaLabel="Edit saved address">
                <EditSavedAddressModal
                  address={editingAddress}
                  onClose={handleEditClose}
                  removeAddress={removeAddress}
                  updateAddress={updateAddress}
                />
              </ModalWithBackdrop>

              <ModalWithBackdrop dialogProps={addDialogProps} ariaLabel="Add new address">
                <AddAddressModal addAddress={addAddress} onClose={handleAddClose} />
              </ModalWithBackdrop>
            </>
          </>
        )}
      </ContentContainer>
    </>
  )
}
