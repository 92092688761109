import React, { useMemo } from 'react'

import { NutritionalChips } from '../../../../../components/NutritionalChips/NutritionalChips'
import { TextSeparator } from '../../../../../components/TextSeparator/TextSeparator'
import { findImage, findMetadata } from '../../../../../data/utils'
import {
  Container,
  MenuHeader,
  NumberText,
  ProductBackgroundColor,
  ProductButton,
  ProductDescriptionText,
  ProductFooterContainer,
  ProductHeader,
  ProductImageContainer,
  ProductInnerContainer,
  ProductOuterContainer,
  ProductsContainer,
  Section,
} from './MenuContainer.styles'

export const MenuContainer = ({ items, isVisible, onSelectMenuItem, showCategoryImage = true }) => {
  if (!isVisible) return null
  return (
    <Container as="div">
      {items?.menu?.map(menu => {
        const headerImage = showCategoryImage ? findImage(menu) : null
        const backgroundColor = menu.metadata?.find?.(item => item.key === 'APP_CATEGORY_LISTING_BACKGROUND_COLOR')?.value
        return (
          <Section key={`menu-${menu.id}`} id={`menu-${menu.id}`}>
            <MenuHeader>{headerImage ? <img src={headerImage} alt={menu.name} /> : menu.name}</MenuHeader>
            <ProductsContainer>
              {menu.products?.map(product => (
                <Product key={product.id} product={product} onSelectMenuItem={onSelectMenuItem} />
              ))}
            </ProductsContainer>
            {backgroundColor && <ProductBackgroundColor backgroundColor={backgroundColor} />}
          </Section>
        )
      })}
    </Container>
  )
}

const Product = ({ product, onSelectMenuItem }) => {
  const basePriceMetdataValue = findMetadata(product.metadata, 'APP_BASE_PRICE')
  let price = Number(basePriceMetdataValue || product.cost).toFixed(2)
  price = price + (basePriceMetdataValue ? '+' : '')

  const resizeMode = useMemo(() => findMetadata(product.metadata, 'APP_IMAGE_RESIZE_MODE'), [findMetadata, product.metadata])

  return (
    <ProductOuterContainer>
      <ProductButton as="button" onClick={() => onSelectMenuItem(product)}>
        <ProductImageContainer as="div" src={findImage(product)} resizeMode={resizeMode} />
        <ProductInnerContainer>
          <NutritionalChips item={product} />
          <ProductHeader>{product.name}</ProductHeader>
          <ProductDescriptionText>{product.description}</ProductDescriptionText>
        </ProductInnerContainer>
        <ProductFooterContainer>
          <NumberText>${price}</NumberText>
          {!!product.baseCalories && (
            <>
              <TextSeparator />
              <NumberText>{product.baseCalories} Cal</NumberText>
            </>
          )}
        </ProductFooterContainer>
      </ProductButton>
    </ProductOuterContainer>
  )
}
