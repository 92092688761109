import { gql, useQuery } from '@apollo/client'
import classNames from 'classnames'
import { flatten } from 'lodash'
import debounce from 'lodash.debounce'
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useResizeDetector } from 'react-resize-detector'
import { useLocation } from 'react-router-dom'
import { useDialogState } from 'reakit'

import { Footer } from '../../../components/Footer/Footer'
import { FullScreenLoading } from '../../../components/FullScreenLoading/FullScreenLoading'
import { Header } from '../../../components/Header/Header'
import { Main } from '../../../components/Main/Main'
import { PageContainer } from '../../../components/Page/Page.styles'
import { useResponsiveness } from '../../../components/responsive/Responsive'
import { useText } from '../../../content'
import { useSession } from '../../../contexts/session'
import { apolloClient } from '../../../data/apollo'
import { GET_MODIFIERS } from '../../../data/menu/queries'
import { MENU_ITEM_FIELDS } from '../../../data/types'
import { findMetadata, formatCurrency } from '../../../data/utils'
import { useMessaging } from '../../../hooks/useMessaging'
import { useModifierValidation } from '../../../hooks/useModifierValidation'
import { CustomBuilderConfirmationModal } from '../components/CustomBuilderConfirmationModal/CustomBuilderConfimationModal'
import { ModifierGroupBar } from '../components/ModifierGroupBar/ModifierGroupBar'
import { ModifierOptionCard } from '../components/ModifierOptionCard/ModifierOptionCard'
import { ModifierSummary } from '../components/ModifierSummary/ModifierSummary'
import {
  Aside,
  AsideBodyContainer,
  AsideButtonContainer,
  AsideSpacer,
  DescriptionText,
  Heading,
  InnerContainer,
  MetaContainer,
  ModiferSectionsContainer,
  ModifierOptionCardsContainer,
  ModifierOptionCardWrapperContainer,
  ModifierSelectedContainer,
  NextStepButton,
  OuterContainer,
  PriceAndCaloriesContainer,
  PriceText,
  Section,
} from './SinglePageScrollCustomBuilder.styles'

const DEFAULT_RESIZE_DETECTOR_OPTIONS = {
  skipOnMount: false,
  refreshMode: 'debounce',
  refreshRate: '150',
}

export const SinglePageScrollCustomBuilder = () => {
  const { text } = useText()
  const location = useLocation()
  const { item, isEditing, basketProduct } = location?.state
  const { validateModifier } = useModifierValidation()
  const { isMobile } = useResponsiveness()
  const { dispatchMessage } = useMessaging()

  const {
    state: { activeModifiers = [] },
    dispatch,
  } = useSession()

  const [activeModifier, setActiveModifier] = useState()
  const confirmationDialogProps = useDialogState({ visible: false, animated: true })

  const menuItem = useMemo(() => {
    // if the user is coming from the basket (is editing), get the product info from the cache
    if (isEditing && basketProduct) {
      return apolloClient.readFragment({
        id: `MenuItem:${basketProduct.productId}`,
        fragment: gql`
          fragment menuItem on MenuItem {
            ${MENU_ITEM_FIELDS}
          }
        `,
      })
    }
    return item
  }, [basketProduct, isEditing, item])

  const { data: menuItemModifierData, loading: loadingModifiers } = useQuery(GET_MODIFIERS, {
    fetchPolicy: 'network-only',
    skip: !menuItem?.id,
    variables: { menuItemId: menuItem?.id },
  })

  const modifiers = useMemo(() => menuItemModifierData?.modifiers || [], [menuItemModifierData?.modifiers])

  useEffect(() => {
    if (!activeModifier) setActiveModifier(modifiers?.[0])
  }, [activeModifier, setActiveModifier, modifiers])

  useEffect(() => {
    if (isEditing && menuItemModifierData) {
      const modOptions = flatten(modifiers.map(mod => mod.options))

      const selectedModifiers = basketProduct?.choices.map(choice => {
        const found = modOptions.find(option => {
          return option.id === choice.optionId
        })

        return { option: found, quantity: choice.quantity }
      })
      console.debug('[CustomBuilderPage] Updating activeModifiers for editing.', { isEditing, menuItem, menuItemModifierData, selectedModifiers })
      dispatch({ type: 'SET_ACTIVE_MODIFIERS', payload: selectedModifiers })
    } else {
      console.debug('[CustomBuilderPage] Clearing out any existing modifiers.', { isEditing, menuItem, menuItemModifierData })
      dispatch({ type: 'SET_ACTIVE_MODIFIERS', payload: [] })
    }
  }, [])

  const totalCost = useMemo(
    () =>
      activeModifiers.reduce((total, modifier) => {
        if (modifier.option.adjustsParentPrice) {
          total += modifier.quantity * modifier.option.cost
        }
        return total
      }, menuItem?.cost || 0),
    [activeModifiers, menuItem],
  )

  const modifiersSummary = useMemo(
    () =>
      modifiers?.reduce((obj, modifier, index) => {
        obj[index] = {
          active: activeModifier?.id === modifier.id,
          enabled: true,
          selected: activeModifiers.filter(m => m.modifier?.id === modifier?.id),
          validation: validateModifier(modifier),
        }
        return obj
      }, {}),
    [modifiers, activeModifier, activeModifiers, validateModifier],
  )

  const calories = useMemo(() => menuItem?.baseCalories, [menuItem?.baseCalories])

  const isValid = useMemo(() => Object.values(modifiersSummary).every(modifierSummary => modifierSummary.validation.isValid), [modifiersSummary])

  const menuItemName = useMemo(() => findMetadata(menuItem?.metadata, 'APP_SHORT_NAME') || menuItem?.name, [menuItem])

  const handleNextStep = useCallback(() => {
    if (isValid) {
      confirmationDialogProps.show()
    } else {
      const firstFailedValidation = Object.values(modifiersSummary).find(ms => !ms?.validation?.isValid)
      dispatchMessage.error(firstFailedValidation?.validation?.message || 'Invalid modifier selection.')
    }
  }, [isValid, confirmationDialogProps, modifiersSummary])

  const changeActiveModifier = useCallback(
    modifier => {
      setActiveModifier(modifier)
      if (!modifier.id) return
      const elementOffsetTop = document.getElementById(`modifier-section-${modifier.id}`)?.offsetTop
      window.scrollTo({ top: elementOffsetTop, behavior: 'smooth' })
    },
    [setActiveModifier, dispatch],
  )

  const handleModifyOne = useCallback(
    modifier => {
      confirmationDialogProps.hide()
      const elementOffsetTop = document.getElementById(`modifier-section-${modifier.id}`)?.offsetTop
      window.scrollTo({ top: elementOffsetTop, behavior: 'smooth' })
    },
    [confirmationDialogProps],
  )

  const handleModifyAll = useCallback(() => {
    confirmationDialogProps.hide()
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [confirmationDialogProps])

  useEffect(() => {
    console.debug('[CustomBuilderPage]', { menuItem, activeModifier, modifiers, calories, isValid, menuItemName, totalCost })
  }, [menuItem, activeModifier, modifiers, calories, isValid, menuItemName, totalCost])

  const [headerHeigh, setHeaderHeight] = useState(0)
  const [asideFixed, setAsideFixed] = useState(true)
  const { width: asideWidth, ref: asideRef } = useResizeDetector(DEFAULT_RESIZE_DETECTOR_OPTIONS)
  const onHeaderResize = useCallback((_, height) => setHeaderHeight(height), [setHeaderHeight])
  const footerRef = useRef(null)

  useLayoutEffect(() => {
    if (isMobile || loadingModifiers) return
    const handleScroll = debounce(() => {
      const { innerHeight, scrollY } = window
      const footerOffsetTop = footerRef?.current?.offsetTop
      setAsideFixed(footerOffsetTop > innerHeight + scrollY)
    }, 50)
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [loadingModifiers, isMobile, footerRef, setAsideFixed])

  return (
    <PageContainer as="div">
      <Header onResize={onHeaderResize}>
        <ModifierGroupBar onClick={changeActiveModifier} modifiers={modifiers} modifiersSummary={modifiersSummary} />
      </Header>
      <FullScreenLoading isOverlay isVisible={loadingModifiers} />
      <Main>
        <OuterContainer>
          <InnerContainer>
            <ModiferSectionsContainer>
              {modifiers?.map(modifier => (
                <Section key={modifier.id} id={`modifier-section-${modifier.id}`}>
                  <Heading>{modifier?.description}</Heading>
                  <DescriptionText style={{ margin: '8px 0px 16px' }}>{modifier?.explanationText}</DescriptionText>
                  <ModifierOptionCardsContainer>
                    {(modifier?.options || []).map(option => (
                      <ModifierOptionCardWrapperContainer key={option.id}>
                        <ModifierOptionCard modifier={modifier} option={option} />
                      </ModifierOptionCardWrapperContainer>
                    ))}
                  </ModifierOptionCardsContainer>
                </Section>
              ))}
            </ModiferSectionsContainer>
            {!loadingModifiers && (
              <>
                <AsideSpacer style={{ minWidth: asideWidth }} />
                <Aside ref={asideRef} $fixed={isMobile || asideFixed} style={{ height: `calc(100vh - ${headerHeigh}px)` }}>
                  <AsideBodyContainer $isVisible={!isMobile}>
                    <MetaContainer>
                      <Heading style={{ marginBottom: '24px' }} title={menuItemName}>
                        {menuItemName}
                      </Heading>
                      <PriceAndCaloriesContainer>
                        <PriceText>{formatCurrency(totalCost || 0)}</PriceText>
                        {calories && <DescriptionText style={{ marginLeft: '24px' }}>{calories} Cal</DescriptionText>}
                      </PriceAndCaloriesContainer>
                    </MetaContainer>
                    <ModifierSelectedContainer>
                      {modifiers?.map((modifier, index) => (
                        <ModifierSummary key={modifier.id} modifier={modifier} selectedOptions={modifiersSummary[index].selected} />
                      ))}
                    </ModifierSelectedContainer>
                  </AsideBodyContainer>
                  <AsideButtonContainer>
                    <NextStepButton
                      label={text('MenuItemBuilder.Cta.Complete')}
                      onClick={handleNextStep}
                      className={classNames({ disabled: !isValid })}
                    />
                  </AsideButtonContainer>
                </Aside>
              </>
            )}
          </InnerContainer>
        </OuterContainer>
      </Main>
      <Footer ref={footerRef} />
      <CustomBuilderConfirmationModal
        key={basketProduct?.id}
        dialogProps={confirmationDialogProps}
        basketProduct={basketProduct}
        isEditing={isEditing}
        menuItem={menuItem}
        handleModifyOne={handleModifyOne}
        handleModifyAll={handleModifyAll}
      />
    </PageContainer>
  )
}
