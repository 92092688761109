import React from 'react'

import { useFavoritesForItem } from '../../../contexts/favorites'
import { Button } from '../../Button/Button'
import { ModalHeader } from '../../ModalHeader/ModalHeader'
import { ModalWithBackdrop } from '../../ModalWithBackdrop/ModalWithBackdrop'

export const RemoveFavorieItemModal = ({ dialogProps, favorite }) => {
  const { removeFromFavorites } = useFavoritesForItem(favorite?.menuItem)
  return (
    <ModalWithBackdrop dialogProps={dialogProps} ariaLabel="Remove from Favorites">
      <ModalHeader title="Remove from Favorites" onClose={dialogProps.close} />
      <div style={{ padding: 20, display: 'flex', flexDirection: 'column' }}>
        <span style={{ padding: 20, textAlign: 'center' }}>Are you sure you want to remove this from your favorites?</span>
        <Button label="Remove" variant="primary" onClick={() => removeFromFavorites(favorite?.data).then(() => dialogProps.hide())} />
        <Button style={{ marginTop: 8 }} variant="secondary" label="Keep" onClick={dialogProps.hide} />
      </div>
    </ModalWithBackdrop>
  )
}
