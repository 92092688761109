import React, { useCallback, useLayoutEffect } from 'react'

import { useWindowSize } from '../../../hooks/useWindowsSize'
import { isIosDevice } from '../../../utils/device'
import { Mobile } from '../../responsive/Responsive'
import { FullDialogBackdrop, MainContainer } from './DefaultModalWithBackdrop.styles'

export const DefaultModalWithBackdrop = ({ children, dialogProps, ariaLabel, slideInFrom = 'bottom', containerStyle, onBackdropClick }) => {
  const slideInFromBottomClass = slideInFrom === 'bottom' ? 'slideInFromBottom' : ''
  const modal = isIosDevice() ? false : dialogProps.modal
  const [_, windowHeight] = useWindowSize()

  useLayoutEffect(() => {
    if (isIosDevice()) {
      document.body.style.overflow = dialogProps?.visible ? 'hidden' : ''
    }
  }, [dialogProps?.visible])

  const handleDialogClick = useCallback(event => {
    event.stopPropagation()
  }, [])

  const handleBackdropClick = useCallback(
    event => {
      event.stopPropagation()
      onBackdropClick ? onBackdropClick() : dialogProps.hide()
    },
    [dialogProps.hide, onBackdropClick],
  )

  return (
    <FullDialogBackdrop {...dialogProps} onClick={handleBackdropClick}>
      <Mobile>
        {isMobile => (
          <MainContainer
            {...dialogProps}
            aria-label={ariaLabel}
            className={slideInFromBottomClass}
            hideOnClickOutside={false}
            modal={modal}
            onClick={handleDialogClick}
            style={{ ...containerStyle, height: isMobile ? windowHeight : 'auto' }}
            tabIndex={0}
          >
            {children}
          </MainContainer>
        )}
      </Mobile>
    </FullDialogBackdrop>
  )
}
