import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'

import { useFeatureFlags } from '../hooks/useFeatureFlags'
import { auth, db } from '../services/firebase'
import { useSession } from './session'

const FirebaseAuthContext = createContext()

export const useFirebaseAuth = () => useContext(FirebaseAuthContext)

const FirebaseAuthProvider = ({ children }) => {
  const [isFirebaseAuthenticated, setFirebaseAuthenticated] = useState(Boolean(auth.currentUser))
  const { FirebaseSupport } = useFeatureFlags()
  const { state: session } = useSession()

  const userId = useMemo(() => session?.user?.id, [session?.user])

  const isAuthenticated = useMemo(() => !!userId, [userId])

  const userDocRef = useMemo(() => {
    return isAuthenticated ? db.doc(`users/${userId}`) : null
  }, [userId, isAuthenticated])

  useEffect(() => {
    console.debug('[useFirebaseAuth] FirebaseAuthProvider', {
      isAuthenticated,
      isFirebaseAuthenticated,
      FirebaseSupport,
      hasUserDocRef: !!userDocRef,
      userId,
    })
  }, [isAuthenticated, isFirebaseAuthenticated, FirebaseSupport, userDocRef, userId])

  return <FirebaseAuthContext.Provider value={{ isAuthenticated, isFirebaseAuthenticated, userDocRef }}>{children}</FirebaseAuthContext.Provider>
}

export default FirebaseAuthProvider
