import styled, { css } from 'styled-components'

import { Button } from '../../../components/Button/Button'
import { isDesktop, isLargeDesktop, isMobile, isTablet } from '../../../components/responsive/Responsive'
import { H1, H3 } from '../../../components/Text/Text.styles'
import { Container as ModifierOptionCardContainer } from '../components/ModifierOptionCard/ModifierOptionCard.styles'

export const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;

  /* Desktop, Large Desktop and Tablet */
  padding: 32px 32px 32px 48px;
  ${isMobile`
    padding: 20px 16px;
    margin-bottom: 104px;
  `};
`

export const ModifierOptionCardsContainer = styled.div`
  display: grid;
  width: 100%;
  ${isLargeDesktop`
    grid-template-columns: 1fr 1fr;
  `};
  ${isDesktop`
    grid-template-columns: 1fr 1fr;
  `};
  ${isTablet`
    grid-template-columns: 1fr;
  `};
  ${isMobile`
    grid-template-columns: 1fr;
  `};
`

export const ModifierOptionCardWrapperContainer = styled.div`
  /* margin-right: 16px; */
  overflow: hidden;
  ${ModifierOptionCardContainer} {
    height: 100%;
  }

  /* Desktop, Large Desktop */
  padding-top: 16px;
  &:nth-child(odd) {
    padding-right: 16px;
  }
  ${isTablet`
    padding-top: 16px;
    &:nth-child(odd) {
      padding-right: 0;
    }
  `};
  ${isMobile`
    padding-top: 16px;
    &:nth-child(odd) {
      padding-right: 0;
    }
  `};
`

export const Aside = styled.aside`
  ${({ theme }) => theme.menuItems.customBuilder.asideContainer}
  display: flex;
  flex-direction: column;

  background-color: ${({ theme }) => theme.colors.brand.white.hex()};
  box-shadow: ${({ theme }) => `0px 2px 4px  ${theme.colors.black.alpha(0.08)}`};

  margin-left: auto;

  ${isLargeDesktop`
    height: 100%;
    max-width: 454px;
    min-width: 454px;
  `};

  ${isDesktop`
    height: 100%;
    max-width: 454px;
    min-width: 454px;
  `};

  ${isTablet`
    height: 100%;
    max-width: 343px;
    min-width: 343px;
  `};

  ${props =>
    props.$fixed &&
    css`
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
    `}
`

export const AsideBodyContainer = styled.div`
  display: flex;
  flex-direction: column;

  background-color: ${({ theme }) => theme.colors.brand.white.hex()};
  border: ${({ theme }) => theme.borders.normal};
  box-sizing: border-box;
  border-radius: 0px 0px 0px 30px;

  ${({ theme }) => theme.menuItems.customBuilder.asideBodyContainer}

  ${props =>
    !props.$isVisible &&
    css`
      display: none;
    `}
`

export const AsideButtonContainer = styled.div`
  text-align: center;

  background-color: ${({ theme }) => theme.colors.brand.white.hex()};

  /* Desktop, Large Desktop */
  padding: 34px 32px;
  ${isTablet`
    padding: 18px 16px 26px;
  `}

  ${isMobile`
    box-shadow: ${({ theme }) => `0px -2px 4px  ${theme.colors.black.alpha(0.08)}`};
    padding: 18px 16px 20px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  `}
`

export const MetaContainer = styled.div`
  ${({ theme }) => theme.menuItems.customBuilder.asideMetaContainer}
  box-sizing: border-box;

  padding: 20px;
`

export const ModifierSelectedContainer = styled.div`
  padding: 20px 20px 60px 20px;
`

export const PriceAndCaloriesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Header = styled(H1)`
  color: ${({ theme }) => theme.colors.brand.black.hex()};

  font-size: 30px;
  letter-spacing: 0.01em;
  line-height: 133%;
`

export const DescriptionText = styled.span`
  ${({ theme }) => theme.font.styles.description};
`

export const PriceText = styled(H3)`
  font-size: ${({ theme }) => theme.font.sizes.lg};
  line-height: 120%;
`

export const NextStepButton = styled(Button).attrs({
  variant: 'primary',
})`
  flex: 0;
`
