import React, { useState } from 'react'
import { FaChevronDown } from 'react-icons/fa'

import { Mobile } from '../../../../../components/responsive/Responsive'
import { CategoryButton, CategoryNavButton, Container } from './CategoryBar.styles'

export const CategoryBar = ({ selectedCategoryId, categories, isVisible, onClick }) => {
  const [mobileOpen, setMobileOpen] = useState(false)

  if (!isVisible) return null
  const activeCategory = categories?.find(category => selectedCategoryId === category.id)

  return (
    <Container as="div">
      <Mobile>
        {isMobile =>
          isMobile ? (
            <>
              {activeCategory && (
                <CategoryNavButton as="div" key={activeCategory.key} onClick={() => setMobileOpen(!mobileOpen)}>
                  {activeCategory.displayName}
                  <div style={{ width: 10 }} />
                  <FaChevronDown size={18} />
                </CategoryNavButton>
              )}
              {mobileOpen &&
                categories
                  ?.filter(category => selectedCategoryId !== category.id)
                  .map(category => (
                    <CategoryButton
                      as="div"
                      key={category.key}
                      onClick={() => {
                        setMobileOpen(false)
                        onClick(category)
                      }}
                      $isSelected={selectedCategoryId === category.id}
                    >
                      {category.displayName}
                    </CategoryButton>
                  ))}
            </>
          ) : (
            <>
              {categories?.map(category => (
                <CategoryButton as="div" key={category.key} onClick={() => onClick(category)} $isSelected={selectedCategoryId === category.id}>
                  {category.displayName}
                </CategoryButton>
              ))}
            </>
          )
        }
      </Mobile>
    </Container>
  )
}
