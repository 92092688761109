import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { Button as ReakitButton } from 'reakit/Button'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  position: relative;
  width: 100%;
`

export const HiddenLabel = styled.label`
  display: none;
`

export const Button = styled(ReakitButton)`
  align-items: center;
  display: flex;
  flex-direction: row;

  background: ${props => props.theme.colors.brand.white};
  border-radius: ${props => props.theme.borderRadii.sm};
  border: ${props => props.theme.borders.light};

  font-family: ${props => props.theme.font.styles.regular};
  font-size: ${props => props.theme.font.sizes.xs};

  padding: 20px 16px;

  cursor: pointer;
  width: inherit;

  &:hover {
    border: ${props => props.theme.toggles.styles.active.border};
  }

  ${props =>
    props.$isRequired &&
    !props.$isFilled &&
    css`
      background-color: ${({ theme }) => theme.colors.backgroundRequiredInput};
    `}

  ${props =>
    props.$isOpen &&
    css`
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    `}
`

export const ArrowUpIcon = styled(FiChevronUp).attrs(({ theme }) => ({
  color: theme.colors.brand.black,
  size: 24,
}))`
  margin-left: auto;
`

export const ArrowDownIcon = styled(FiChevronDown).attrs(({ theme }) => ({
  color: theme.colors.brand.black,
  size: 24,
}))`
  margin-left: auto;
`

export const ListContainer = styled.ul`
  background: ${({ theme }) => theme.colors.brand.white};
  border-bottom-left-radius: ${props => props.theme.borderRadii.sm};
  border-bottom-right-radius: ${props => props.theme.borderRadii.sm};
  border: ${props => props.theme.borders.light};

  position: absolute;
  z-index: 1;

  max-height: 250px;
  min-width: 100%;
  overflow-y: auto;

  ${props =>
    !props.$isOpen &&
    css`
      display: none;
    `};
`

export const ListItem = styled.li`
  font-family: ${props => props.theme.font.styles.regular};
  font-size: ${props => props.theme.font.sizes.xs};

  cursor: pointer;
  padding: 20px 16px;

  /* overflow wrap ellipsis */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${props =>
    props.$selected &&
    css`
      background: ${({ theme }) => theme.items.selected.background};
    `};

  ${props =>
    props.$highlighted &&
    css`
      border: ${({ theme }) => theme.borders.normal};
      background: ${({ theme }) => theme.items.highlighted.background};
    `};

  &:hover {
    border: ${({ theme }) => theme.borders.normal};
    background: ${({ theme }) => theme.items.hover.background};
  }
`

export const ErrorLabel = styled.div`
  margin: 16px 0 0 16px;
  ${({ theme }) => theme.font.styles.labelInputError}
`
