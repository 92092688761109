import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'

import { isMobile } from '../../components/responsive/Responsive'

export const Section = styled.section`
  color: ${props => props.theme.colors.brand.black};
  display: flex;
  flex-direction: column;
  flex: 0;
  padding: 48px;
  width: 100%;
`

export const Link = styled(RouterLink)`
  color: black;
  font-size: 16px;
  text-decoration: underline;
  font-family: ${({ theme }) => theme.font.styles.regular};
`

export const Header = styled.h1`
  ${({ theme }) => theme.font.styles.h1};
`

export const Paragraph = styled.p`
  ${({ theme }) => theme.font.styles.description};
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 30px;
`

export const HeaderText = styled.h1`
  width: 100%;
  border-radius: 3px 3px 0px 0px;

  text-align: center;
  padding: 22px 30px;

  ${isMobile`
    border-radius: 0px;
  `}

  ${({ theme }) => theme.auth.header};
`

export const Container = styled.div`
  width: 40%;
  min-height: 630px;
  max-width: 500px;

  background-color: white;
  border-radius: 3px;

  ${isMobile`
    width: 100%;
    min-height: 720px;
  `}
`
