import React, { createContext, useContext } from 'react'

import { useManagedState } from './Home.state'

export const HomeContext = createContext()

export const HomeContextProvider = ({ children }) => {
  const value = useManagedState()
  return <HomeContext.Provider value={value}>{children}</HomeContext.Provider>
}

export const useHomeContext = () => useContext(HomeContext)
