import { Box } from 'reakit/Box'
import { Button } from 'reakit/Button'
import styled from 'styled-components'

import { isDesktop, isLargeDesktop, isMobile, isTablet } from '../../../../../components/responsive/Responsive'

export const Container = styled(Box)`
  ${props => props.theme.menuItems.cards.menuStyle};
  background: ${props => props.theme.colors.brand.white};
  color: ${props => props.theme.colors.brand.black};
  display: flex;
  flex-direction: column;
`

export const Section = styled.section`
  ${props => props.theme.menuItems.cards.menuSectionStyle};
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
`

export const MenuHeader = styled.h1`
  ${props => props.theme.menuItems.cards.menuHeaderStyle};
  z-index: 1;

  > img {
    max-width: 100%;
    max-height: 165px;
  }
`

export const ProductsContainer = styled(Box)`
  ${props => props.theme.menuItems.cards.menuProductsContainerStyle};
  z-index: 1;
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  width: 100%;

  ${isMobile`
    flex-flow: column wrap;
  `}
`

export const ProductOuterContainer = styled(Box)`
  display: flex;
  ${isLargeDesktop`
    flex-basis: 50%;
    padding-right: 15px;
    padding-top: 40px;
    &:nth-child(2n) {
      padding-right: 0;
    }
  `};
  ${isDesktop`
    flex-basis: 100%;
    padding-top: 40px;
  `};
  ${isTablet`
    flex-basis: 100%;
    padding-top: 35px;
  `};
  ${isMobile`
    padding-top: 24px;
  `};
`

export const ProductButton = styled(Button)`
  ${props => props.theme.menuProductButtonStyle};

  display: flex;
  flex-direction: row;

  /* button overrides */
  background: none;
  border: none;
  cursor: pointer;
  width: 100%;
`

export const ProductImage = styled.img`
  border-radius: 50%;
  object-fit: ${props => props.resizeMode || props.theme.menuItems.cards.imageResizeMode || 'cover'};
  ${({ theme }) => theme.menuItems.cards.imageStyle}
`

export const ProductInfoContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  height: 100%;
  margin-left: 20px;
`

export const ProductNameHeader = styled.h3`
  ${props => props.theme.menuItems.cards.descriptionHeaderStyle};
  margin-bottom: 8px;
`

export const ProductCaloriesText = styled.span`
  ${props => props.theme.menuItems.cards.descriptionTextStyle};
`

export const ProductPriceText = styled.span`
  ${props => props.theme.menuItems.cards.numberTextStyle};
`
