import { useApolloClient } from '@apollo/client'
import moment from 'moment-timezone'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { isSameHandoffMode } from '../../constants/handoffModes'
import { TIME_WANTED_MODES } from '../../constants/timeWantedModes'
import { useText } from '../../content'
import { useSession } from '../../contexts/session'
import { UPDATE_BASKET_TIME_WANTED } from '../../data/basket/queries'
import { useFeatureFlags } from '../useFeatureFlags'
import { useMessaging } from '../useMessaging'
import { useRestaurantCalendarRanges } from '../useRestaurantCalendarRanges'
import { useRestaurantsCalendars } from '../useRestaurantCalendars'
import { formatDate, syncPickupTime, WRITE_FORMAT } from './usePickupTimeHelper'

export const usePickupTime = () => {
  const [loading, setLoading] = useState(false)
  const { dispatchMessage } = useMessaging()
  const { state, dispatch } = useSession()
  const { text } = useText()
  const apolloClient = useApolloClient()
  const calendarRanges = useRestaurantCalendarRanges({ handoffMode: state.basket.deliveryMode, restaurant: state.restaurant })
  const { calculateEarliestReadyTime } = useRestaurantsCalendars([state.restaurant])
  const { PickTimeAtCheckout } = useFeatureFlags()

  /**
   * @description This is the Apollo GraphQL mutation,
   * which will update the `basket.timeWanted`.
   */
  const updateBasketTimeWanted = useCallback(
    pickupTime => {
      const variables = {
        basketId: state.basket.id,
        asap: pickupTime === TIME_WANTED_MODES.ASAP,
        wantedDateTime: pickupTime === TIME_WANTED_MODES.ASAP ? null : formatDate(pickupTime, WRITE_FORMAT, state.restaurant.utcOffset),
      }
      console.debug('[usePickupTime] updating basket.timeWanted with=', variables)
      setLoading(true)
      return apolloClient
        .mutate({
          mutation: UPDATE_BASKET_TIME_WANTED,
          variables,
        })
        .then(response => response.data.updateBasketTimeWanted)
        .finally(() => setLoading(false))
    },
    [apolloClient, setLoading, state.basket.id, state.restaurant.utcOffset],
  )

  /**
   * @description This is the callback function which will be called by `syncPickupTime` when the
   * (session) `state.pickupTime` and the `basket.timeWanted` needs synchronization.
   */
  const syncFn = useCallback(
    pickupTime => {
      console.debug('[usePickupTime] updating basket time wanted from:', { pickupTime })
      return updateBasketTimeWanted(pickupTime)
        .then(basket => {
          dispatch({ type: 'SET_BASKET', payload: basket })
          dispatch({ type: 'SET_PICKUP_TIME', payload: pickupTime })
        })
        .catch(response => {
          dispatchMessage.error(
            response?.graphQLErrors?.[0].extensions?.response?.body?.message || text('Checkout.Errors.UpdateTimeWanted'),
            response?.graphQLErrors?.[0].extensions?.response || response,
          )
        })
    },
    [dispatch, dispatchMessage, updateBasketTimeWanted],
  )

  /**
   * @description This is the core `useEffect` which will sync the
   * (session) `state.pickupTime` with the `basket.timeWanted`.
   * This function also needs to wait for the basket handoff mode
   * synchronization, otherwise it won't work.
   * Basket handoff mode synchronization happens in parallel, but
   * we need to wait for it first.
   * _PS: updates only when needed._
   */
  useEffect(() => {
    const canUpdate = isSameHandoffMode(state.handoffMode, state.basket.deliveryMode)
    console.debug('[usePickupTime] canUpdate:', canUpdate, { localHandoffMode: state.handoffMode, basketHandoffMode: state.basket.deliveryMode })
    if (!canUpdate) return
    syncPickupTime({
      basket: state.basket,
      calendarRanges,
      now: moment().startOf('minute'),
      pickupTime: state.pickupTime,
      syncFn,
      utcOffset: state.restaurant.utcOffset,
    })
  }, [state.basket.deliveryMode, state.handoffMode, state.pickupTime, state.restaurant.utcOffset])

  /**
   * @description Returns an user friendly formatted `pickupTime`.
   */
  const parsedTimeWanted = useMemo(() => {
    const now = moment()
    const isAsapMode = state.basket?.timeMode === 'asap' || state.pickupTime === TIME_WANTED_MODES.ASAP || !state.pickupTime

    if (isAsapMode) {
      const earliestReadyTime = calculateEarliestReadyTime(state.restaurant, state.basket)
      if (earliestReadyTime) {
        const isToday = now.isSame(earliestReadyTime, 'days')
        const isTomorrow = now.clone().add(1, 'days').isSame(earliestReadyTime, 'days')
        const dateString = (isToday && 'Today') || (isTomorrow && 'Tomorrow') || earliestReadyTime.format('MMM D')

        return `${dateString}, ${earliestReadyTime.format('h:mm a')} (Quickest)`
      }

      const leadTimeEstimateMinutes = state.basket?.leadTimeEstimateMinutes
      if (leadTimeEstimateMinutes) {
        return `ASAP (about ${leadTimeEstimateMinutes} minutes)`
      } else {
        return 'ASAP'
      }
    } else if (state.basket?.timeWanted) {
      const timeWantedMoment = moment(state.basket?.timeWanted, 'YYYYMMDD HH:mm')
      const isToday = now.isSame(timeWantedMoment, 'days')
      const isTomorrow = now.clone().add(1, 'days').isSame(timeWantedMoment, 'days')
      const dateString = (isToday && 'Today') || (isTomorrow && 'Tomorrow') || timeWantedMoment.format('MMM D')
      return `${dateString}, ${timeWantedMoment.format('h:mm a')} ${state.pickupTime === 'ASAP' ? '(Quickest)' : ''}`
    } else if (state.pickupTime) {
      const pickupTime = moment(state.pickupTime)
      const isToday = moment().startOf('day').isSame(pickupTime, 'days')
      const isTomorrow = moment().add(1, 'days').isSame(pickupTime, 'days')
      return `${isToday ? 'Today' : isTomorrow ? 'Tomorrow' : pickupTime.format('MMM D')}, ${pickupTime.format('h:mm a')}`
    } else {
      const nextAvailableDate = now.add(state.basket?.leadTimeEstimateMinutes, 'minutes')
      return `${nextAvailableDate.format('MMM D')}, ${nextAvailableDate.format('h:mm a')}`
    }
  }, [
    state.basket.leadTimeEstimateMinutes,
    state.basket.timeMode,
    state.basket.timeWanted,
    state.pickupTime,
    state.restaurant,
    calculateEarliestReadyTime,
  ])

  /**
   * @description Use this function to update (session) `state.pickupTime`.
   * @param {string} pickupTime - formatted moment instance
   */
  const updatePickupTime = useCallback(
    pickupTime => {
      dispatch({ type: 'SET_PICKUP_TIME', payload: pickupTime })
    },
    [dispatch],
  )

  useEffect(() => {
    console.debug('[usePickupTime] pickupTime changed to=', state.pickupTime)
  }, [state.pickupTime])

  useEffect(() => {
    if (!PickTimeAtCheckout && !state.pickupTime) {
      dispatch({ type: 'SET_PICKUP_TIME', payload: TIME_WANTED_MODES.ASAP })
    }
  }, [state.pickupTime, PickTimeAtCheckout])

  return { loading, pickupTime: state?.pickupTime, parsedTimeWanted, updatePickupTime }
}
