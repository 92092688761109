import { useMemo } from 'react'

import { getCalendarRanges } from '../utils/restaurant'

/**
 * @description Extract `calendarRanges` from `restaurant` depending on the `handoffMode`
 * @param {string} handoffMode
 * @param {Object} restaurant
 * @returns {Object[]} - `calendarRanges` as { start: `Moment`, end: `Moment`, weekday: 'string'}
 */
export const useRestaurantCalendarRanges = ({ handoffMode, restaurant }) => {
  return useMemo(() => {
    return getCalendarRanges(handoffMode, restaurant)
  }, [handoffMode, restaurant])
}
