import { useQuery } from '@apollo/client'
import moment from 'moment'
import React, { createContext, useCallback, useContext, useEffect, useReducer, useState } from 'react'

import { FullScreenLoading } from '../components/FullScreenLoading/FullScreenLoading'
import { PREFERENCES } from '../constants/preferences'
import { useCacheWithExpiration } from '../data/apollo'
import { GET_APP_ASSETS, GET_APP_PREFERENCES, GET_FEATURE_FLAGS, GET_MENU_IMAGES, GET_WEB_ASSETS, GET_WEB_PREFERENCES } from '../data/content/queries'
import { useLocalStorage } from '../hooks/useLocalStorage'
import { useMessaging } from '../hooks/useMessaging'

const graphCMSCacheInterval = process.env.REACT_APP_GRAPHCMS_CACHE_INTERVAL || 30

export const ConfigContext = createContext()

const initialState = {
  appAssets: [],
  appPreferences: [],
  assets: [],
  preferences: [],
  webAssets: [],
  webPreferences: [],
  menuImages: [],
  featureFlags: [],
}

const mergeAssets = (assets = [], currentAssets = {}) => {
  const newAssets = assets.reduce((instance, { assetType, image: { url } }) => {
    instance[assetType] = { url }
    return instance
  }, {})
  return Object.assign({}, currentAssets, newAssets)
}

const mergePreferences = (preferences = [], currentPreferences = {}) => {
  const newPreferences = preferences.reduce((instance, { content, type }) => {
    instance[type] = { ...content }
    return instance
  }, {})
  return Object.assign({}, currentPreferences, newPreferences)
}

const mergeFeatureFlags = (featureFlags = [], currentFeatureFlags = {}) => {
  const newFeatureFlags = featureFlags.reduce((instance, { enabled, type }) => {
    instance[type] = enabled
    return instance
  }, {})
  return Object.assign({}, currentFeatureFlags, newFeatureFlags)
}

const reducer = (state, action) => {
  console.debug('[appConfig] reducer:', state, action)
  switch (action.type) {
    case 'SET_APP_ASSETS':
      return {
        ...state,
        assets: mergeAssets(action.payload, state.assets) || [],
        appAssets: action.payload || [],
      }
    case 'SET_APP_PREFERENCES':
      return {
        ...state,
        preferences: mergePreferences(action.payload, state.preferences) || [],
        appPreferences: action.payload || [],
      }
    case 'SET_WEB_ASSETS':
      return {
        ...state,
        assets: mergeAssets(action.payload, state.assets),
        webAssets: action.payload || [],
      }
    case 'SET_WEB_PREFERENCES':
      return {
        ...state,
        preferences: mergePreferences(action.payload, state.preferences) || [],
        webPreferences: action.payload || [],
      }
    case 'SET_MENU_IMAGES':
      return {
        ...state,
        menuImages: action.payload || [],
      }
    case 'SET_FEATURE_FLAGS':
      return {
        ...state,
        featureFlags: mergeFeatureFlags(action.payload, state.featureFlags) || [],
      }
    default:
      return state
  }
}

export const ConfigProvider = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const { dispatchMessage } = useMessaging()
  const [localStorageState, setLocalStorageState] = useLocalStorage('config', initialState)
  const [state, dispatch] = useReducer(reducer, localStorageState)

  const fetchPolicy = useCacheWithExpiration(moment.duration(graphCMSCacheInterval, 'minutes'), 'lastFetch_appConfig')

  const { data: appAssetsData, loading: appAssetsLoading } = useQuery(GET_APP_ASSETS, {
    fetchPolicy,
    onError: useCallback(
      err =>
        dispatchMessage({
          type: 'error',
          payload: { message: 'Could not load app assets.' },
          err,
        }),
      [dispatchMessage],
    ),
  })
  const { data: appPreferencesData, loading: appPreferencesLoading } = useQuery(GET_APP_PREFERENCES, {
    fetchPolicy,
    onError: useCallback(
      err =>
        dispatchMessage({
          type: 'error',
          payload: { message: 'Could not load preferences.' },
          err,
        }),
      [dispatchMessage],
    ),
  })
  const { data: webAssetsData, loading: webAssetsLoading } = useQuery(GET_WEB_ASSETS, {
    fetchPolicy,
    onError: useCallback(
      err =>
        dispatchMessage({
          type: 'error',
          payload: { message: 'Could not load web assets.' },
          err,
        }),
      [dispatchMessage],
    ),
  })
  const { data: webPreferencesData, loading: webPreferencesLoading } = useQuery(GET_WEB_PREFERENCES, {
    fetchPolicy,
    onError: useCallback(
      err =>
        dispatchMessage({
          type: 'error',
          payload: { message: 'Could not load web preferences.' },
          err,
        }),
      [dispatchMessage],
    ),
  })

  const { data: menuImagesData, loading: menuImagesLoading } = useQuery(GET_MENU_IMAGES, {
    fetchPolicy,
    onError: useCallback(
      err =>
        dispatchMessage({
          type: 'error',
          payload: { message: 'Could not load menu images.', error: err },
        }),
      [dispatchMessage],
    ),
  })

  const { data: featureFlagsData, loading: featureFlagsLoading } = useQuery(GET_FEATURE_FLAGS, {
    fetchPolicy,
    onError: useCallback(
      err =>
        dispatchMessage({
          type: 'error',
          payload: { message: 'Could not load feature flags.', error: err },
        }),
      [dispatchMessage],
    ),
  })

  useEffect(() => {
    if (
      appAssetsData?.appAssets &&
      appPreferencesData?.preferences &&
      webAssetsData?.webAssets &&
      webPreferencesData?.webPreferences &&
      featureFlagsData?.featureFlags
    ) {
      dispatch({ type: 'SET_APP_ASSETS', payload: appAssetsData.appAssets })
      dispatch({ type: 'SET_APP_PREFERENCES', payload: appPreferencesData.preferences })
      dispatch({ type: 'SET_WEB_PREFERENCES', payload: webPreferencesData.webPreferences })
      dispatch({ type: 'SET_WEB_ASSETS', payload: webAssetsData.webAssets })
      dispatch({ type: 'SET_FEATURE_FLAGS', payload: featureFlagsData.featureFlags })
    }
  }, [appAssetsData, appPreferencesData, webAssetsData, webPreferencesData, featureFlagsData])

  useEffect(() => {
    if (menuImagesData?.menuImages) {
      dispatch({ type: 'SET_MENU_IMAGES', payload: menuImagesData.menuImages })
    }
  }, [dispatch, menuImagesData])

  useEffect(() => {
    if (!appAssetsLoading && !appPreferencesLoading && !webAssetsLoading && !webPreferencesLoading && !menuImagesLoading && !featureFlagsLoading) {
      setLocalStorageState(state)
      setLoading(false)
    }
  }, [
    appAssetsLoading,
    appPreferencesLoading,
    webAssetsLoading,
    webPreferencesLoading,
    menuImagesLoading,
    featureFlagsLoading,
    setLocalStorageState,
    setLoading,
  ])

  useEffect(() => setLocalStorageState(state), [state, setLocalStorageState])

  const getAsset = useCallback(type => state?.assets[type], [state?.assets])

  const getMenuImage = useCallback(
    id => {
      return state?.menuImages?.find(item => item?.id === id)?.image?.url
    },
    [state?.menuImages],
  )

  const getPreference = useCallback(
    (type, defaultValue = {}) => {
      const value = state?.preferences?.[type] || {}
      return { ...defaultValue, ...value }
    },
    [state?.preferences],
  )

  const resolveNavigationPayload = useCallback(
    flowName => {
      const route = getPreference(PREFERENCES.NAVIGATION_RESOLVER)?.[flowName]
      console.debug('[appConfig] resolveNavigationPayload', { preference: PREFERENCES.NAVIGATION_RESOLVER, flowName, route })
      if (!route) {
        throw new Error(`Could not resolve flow: ${flowName}`)
      }
      return route
    },
    [getPreference],
  )

  const value = {
    state,
    dispatch,
    getAsset,
    getMenuImage,
    getPreference,
    loading,
    resolveNavigationPayload,
  }

  return <ConfigContext.Provider value={value}>{!loading ? children : <FullScreenLoading />}</ConfigContext.Provider>
}

export const useConfig = () => useContext(ConfigContext)
