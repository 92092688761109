import React from 'react'

import { parseIngredients } from '../../../data/utils'
import { Container, Name, TextSeparator } from './Ingredients.styles'

export const Ingredients = ({ menuItem, lightText = false, ...props }) => {
  const ingredients = parseIngredients(menuItem)
  const modifiers = menuItem?.choices

  if (!ingredients) {
    return (
      <Container {...props}>
        <Name>{menuItem?.description}</Name>
        <p>
          {modifiers.map((mod, idx) => (
            <React.Fragment key={idx}>
              {idx > 0 && <TextSeparator $lightText={lightText} />}
              <Name key={mod.optionId} $lightText={lightText}>
                {mod.name}
              </Name>
            </React.Fragment>
          ))}
        </p>
      </Container>
    )
  }

  return (
    <Container>
      {ingredients.map((i, index) => (
        <React.Fragment key={index}>
          <Ingredient ingredient={i} />
          {index < ingredients.length - 1 && <TextSeparator />}
        </React.Fragment>
      ))}
    </Container>
  )
}

const Ingredient = ({ ingredient }) => {
  const { name } = ingredient

  return <Name>{name}</Name>
}
