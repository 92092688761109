import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { useConfig } from '../contexts/appConfig'
import { findMetadata } from '../data/utils'
import firebase from '../services/firebase'

export const useMenuItemNavigation = () => {
  const { resolveNavigationPayload } = useConfig()
  const history = useHistory()

  const isCustomBuilder = useCallback(item => findMetadata(item.metadata, 'APP_BUILDER') === 'CUSTOM', [])

  const goToItem = useCallback(
    item => {
      firebase.analytics().logEvent('Dish_Selected', {
        dishName: item.name,
        amount: item.cost,
      })
      const pathname = resolveNavigationPayload(isCustomBuilder(item) ? 'CustomMenuItemBuilder' : 'SimpleBuilder')
      history.push({ pathname, state: { item } })
    },
    [history, resolveNavigationPayload],
  )

  return { goToItem }
}
