import React, { useMemo } from 'react'
import { useTheme } from 'styled-components'

import { BodyScrollLockModalWithBackdrop } from './BodyScrollLockModalWithBackdrop/BodyScrollLockModalWithBackdrop'
import { DefaultModalWithBackdrop as Default } from './DefaultModalWithBackdrop/DefaultModalWithBackdrop'

export { ModalContainer, ModalBodyContainer, ModalFooterContainer } from './ModalWithBackdrop.styles'

const componentsFactory = {
  lpq: BodyScrollLockModalWithBackdrop,
}

export const ModalWithBackdrop = props => {
  const theme = useTheme()
  const Component = useMemo(() => componentsFactory[theme.brand] || Default, [theme.brand])
  return <Component {...props} />
}
