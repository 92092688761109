import React from 'react'

import { useText } from '../../../content'
import { useLoyalty } from '../../../hooks/useLoyalty'
import {
  Section,
  SectionItem,
  SectionItemDescription,
  SectionItemIcon,
  SectionItemIconImage,
  SectionItemMeta,
  SectionItemTitle,
  SectionTitle,
} from './FgcRewards.styles'

export const FgcRewardsList = () => {
  const { loading, rewards } = useLoyalty()
  const { text } = useText()

  if (rewards.length === 0) {
    return null
  }

  return (
    <Section>
      <SectionTitle>{text('Profile.Rewards.Rewards.Title')}</SectionTitle>
      {rewards.map(reward => (
        <SectionItem key={reward.rewardId}>
          <SectionItemIcon>
            <SectionItemIconImage src={reward.rewardImageUrl} />
          </SectionItemIcon>
          <SectionItemMeta>
            <SectionItemTitle>{reward.name}</SectionItemTitle>
            <SectionItemDescription>{reward.description}</SectionItemDescription>
          </SectionItemMeta>
        </SectionItem>
      ))}
    </Section>
  )
}
