import React, { useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTheme } from 'styled-components'

import { HANDOFF_MODES } from '../../constants/handoffModes'
import { useText } from '../../content'
import { useConfig } from '../../contexts/appConfig'
import { useSession } from '../../contexts/session'
import { useHandoffMode } from '../../hooks/useHandoffMode'
import { BackButton } from '../Button/Button'
import { Mobile } from '../responsive/Responsive'
import { ChangeAddressChipInnerContainer, ChangeAddressChipOuterContainer, ForwardContainer, ForwardIcon } from './ChangeAddressChip.styles'

export const ChangeAddressChip = () => {
  const { resolveNavigationPayload } = useConfig()
  const { state } = useSession()
  const history = useHistory()
  const location = useLocation()
  const { text } = useText()
  const theme = useTheme()

  const { displayName, handoffMode } = useHandoffMode()
  const startOrderPathname = useMemo(() => resolveNavigationPayload('StartOrder'), [resolveNavigationPayload])
  const streetAddress = handoffMode === HANDOFF_MODES.PICKUP ? state?.restaurant?.streetAddress : state?.activeAddress?.streetAddress

  const isBrowseMenuPage = useMemo(() => location.pathname.match(startOrderPathname), [location.pathname, startOrderPathname])
  const isHomePage = useMemo(() => location.pathname === '/', [location.pathname])

  const redirectToHome = () => history.push('/')
  const redirectToBrowseMenu = () => history.push('/browse-menu')
  const handleBackButton = () => (isBrowseMenuPage ? redirectToHome() : history.push(startOrderPathname))

  return (
    <Mobile>
      {isMobile => (
        <ChangeAddressChipOuterContainer title={text('Header.Chip.Title')}>
          {isHomePage ? (
            <ChangeAddressChipInnerContainer onClick={redirectToBrowseMenu}>
              <ForwardContainer onClick={handleBackButton}>
                <span style={{ marginRight: '5px', marginLeft: '10px' }}>{text('Browse.Menu')}</span>
                <ForwardIcon />
              </ForwardContainer>
            </ChangeAddressChipInnerContainer>
          ) : (
            <>
              {!isMobile && <BackButton variant={theme.changeAddressChip.backButtonVariant} size={24} />}
              <ChangeAddressChipInnerContainer onClick={redirectToHome}>
                <span>
                  {displayName} {streetAddress && ` | ${streetAddress}`}
                </span>
              </ChangeAddressChipInnerContainer>
            </>
          )}
        </ChangeAddressChipOuterContainer>
      )}
    </Mobile>
  )
}
