import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { CircleLoadingIndicator } from '../CircleLoadingIndicator/CircleLoadingIndicator'
import {
  BackIcon,
  ButtonContainer,
  ButtonIconImage,
  ButtonLoadingContainer,
  ButtonWithIconContentContainer,
  CloseIcon,
  RoundContainer,
  TYPES,
} from './Button.styles'

const ButtonLoading = () => (
  <ButtonLoadingContainer>
    <CircleLoadingIndicator />
  </ButtonLoadingContainer>
)

export const Button = ({ label = 'label me', loading = false, variant = 'default', isSmall = false, icon = null, ...props }) => {
  const content = useMemo(
    () =>
      icon ? (
        <ButtonWithIconContentContainer>
          {label}
          <ButtonIconImage src={icon} />
        </ButtonWithIconContentContainer>
      ) : (
        label
      ),
    [label, icon],
  )
  return (
    <ButtonContainer variant={variant} small={isSmall ? 'small' : ''} {...props}>
      {loading ? <ButtonLoading /> : content}
    </ButtonContainer>
  )
}

export const BackButton = ({ onClick, size, variant = TYPES.SECONDARY, ...rest }) => {
  const history = useHistory()
  const handleNavigateBack = () => (onClick ? onClick() : history.goBack())
  return (
    <RoundContainer {...rest} onClick={handleNavigateBack} variant={variant} aria-label="Go Back">
      <BackIcon size={size} variant={variant} />
    </RoundContainer>
  )
}

export const CloseButton = ({ onClick, size, variant = TYPES.SECONDARY, ...rest }) => {
  const history = useHistory()
  const handleNavigateBack = () => (onClick ? onClick() : history.goBack())
  return (
    <RoundContainer {...rest} variant={variant} onClick={handleNavigateBack} aria-label="Close">
      <CloseIcon size={size} variant={variant} />
    </RoundContainer>
  )
}
