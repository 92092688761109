import { useQuery } from '@apollo/client'
import { find } from 'lodash'
import { useCallback, useMemo } from 'react'

import { useText } from '../content'
import { GET_SPOTLIGHTS } from '../data/content/queries'
import { findMetadata } from '../data/utils'
import { useMessaging } from '../hooks/useMessaging'

export const useSpotlight = () => {
  const { text } = useText()
  const { dispatchMessage } = useMessaging()

  const { data, loading } = useQuery(GET_SPOTLIGHTS, {
    onError: useCallback(
      e =>
        dispatchMessage({
          type: 'ADD_MESSAGE',
          payload: { message: text('Spotlight.Error'), error: e },
          e,
        }),
      [text, dispatchMessage],
    ),
  })

  const findSpotlight = useCallback(
    menuItem => {
      const id = findMetadata(menuItem?.metadata, 'APP_SPOTLIGHT_ID')
      if (id) {
        return find(data?.spotlights || [], s => s.id === id)
      }
    },
    [data],
  )

  return { findSpotlight, loading }
}
