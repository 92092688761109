import axios from 'axios'

export const geocode = async (streetAddress, city) => {
  const { data } = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
    params: { address: `${streetAddress}, ${city}`, key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY },
  })

  if (data?.results?.length > 0) {
    const match = data.results[0]
    return { latitude: match?.geometry?.location?.lat, longitude: match?.geometry?.location?.lng }
  } else {
    console.warn('Could not geocode address', streetAddress, city)
  }

  return null
}
