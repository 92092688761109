import { Button } from 'reakit'
import styled from 'styled-components'

import { Button as AurifyButton } from '../Button/Button'

export const ProductListContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`

export const ProductMetaContainer = styled(ProductListContainer)`
  padding: 14px 0px;
`

export const ProductName = styled.span`
  font-size: 12px;
  font-family: ${({ theme }) => theme.font.styles.regular};
  margin-right: auto;
`

export const ProductMeta = styled(ProductName)`
  width: 70%;
  color: #565656;
`

export const DetailsButton = styled(Button)`
  border: none;
  font-size: 12px;
  text-decoration: underline;
  background-color: transparent;
  font-family: ${({ theme }) => theme.font.styles.regular};
`

export const ChevronContainer = styled(Button)`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  border-radius: 18px;
  justify-content: center;
  border: 1px solid #d0d0d0;
  background-color: transparent;
`

export const AddToBagButton = styled(AurifyButton).attrs(() => ({ variant: 'secondary' }))`
  flex: none;
  width: auto;

  font-size: 14px;
  padding: 10px 16px;
`

export const Row = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`

export const OrderCardContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  position: relative;

  border-radius: 4px;
  border: 1px solid #d0d0d0;

  flex-direction: column;
`

export const OrderHeader = styled.div`
  display: flex;
  flex-direction: row;
`

export const OrderTitle = styled.span`
  font-size: 16px;
  font-family: ${({ theme }) => theme.font.styles.numbers};
  margin-right: auto;
`

export const OrderMetaText = styled(OrderTitle)`
  color: #565656;
  font-size: 12px;
  font-family: ${({ theme }) => theme.font.styles.regular};
`

export const Price = styled.span`
  font-family: ${({ theme }) => theme.font.styles.numbers};
  white-space: nowrap;
`
