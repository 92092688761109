import { FaCheckCircle } from 'react-icons/fa'
import { Button } from 'reakit/Button'
import styled from 'styled-components'

export const RewardButtonContainer = styled.div`
  background: ${props => props.theme.colors.brand.white};
  border-radius: ${props => props.theme.borderRadii.sm};
  border: ${props => props.theme.borders.light};

  display: flex;
  flex-direction: row;

  align-items: center;
  padding: 16px 20px;
  margin-bottom: 16px;

  cursor: pointer;
  &:hover {
    border: ${props => props.theme.toggles.styles.active.border};
  }
`

export const IconImage = styled.img.attrs(({ theme, src, variant }) => ({
  src: src || (variant === 'currency' ? theme.checkout?.rewards?.currencyButtonIcon : theme.checkout?.rewards?.sectionButtonIcon),
}))`
  width: 41px;
  height: 41px;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 14px;
  text-align: left;
`

export const Label = styled.label`
  ${({ theme }) => theme.font.styles.description}
  letter-spacing: 0.08em;
  line-height: 120%;
  text-transform: uppercase;
`

export const DescriptionText = styled.span`
  ${({ theme }) => theme.font.styles.description}
  line-height: 130%;
  text-transform: unset;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px 0;
  width: 100%;
`

export const RewardToggleButton = styled(Button)`
  background: inherit;
  border: unset;
  cursor: pointer;

  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;

  padding: 12px 16px;

  transition: all 0.2s ease;

  &:hover {
    background: ${props => props.theme.colors.brand.gray20};
  }

  svg {
    border: 1px solid ${({ theme }) => theme.colors.brand.gray40};
    border-radius: 50%;
    fill: ${({ theme }) => theme.colors.brand.white};
    min-width: 24px;
  }

  &[aria-checked='true'] {
    background-color: ${({ theme }) => theme.colors.brand.white};
  }
  &[aria-checked='true'][aria-disabled='true'] {
    background-color: ${({ theme }) => theme.colors.brand.white};
  }

  &[aria-checked='true'] svg {
    fill: ${({ theme }) => theme.colors.brand.black};
  }
  &[aria-checked='true'][aria-disabled='true'] svg {
    fill: ${({ theme }) => theme.colors.brand.gray60};
  }
`

export const ToggleCheckIcon = styled(FaCheckCircle).attrs(({ theme }) => ({
  color: theme.colors.brand.black,
  size: 24,
}))`
  margin-left: auto;
`

export const ExtraSmallButton = styled.button`
  font-size: ${({ theme }) => theme.font.sizes.xs};
  padding: 10px 16px;
  flex: 0;
  margin-left: auto;
`
