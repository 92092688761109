import React from 'react'

import { useText } from '../../../content'
import { useLoyalty } from '../../../hooks/useLoyalty'
import {
  RewardsSectionIconImage,
  Section,
  SectionItem,
  SectionItemDescription,
  SectionItemIcon,
  SectionItemMeta,
  SectionItemTitle,
  SectionTitle,
} from './LittleBeetRewards.styles'

export const LittleBeetBankedCurrencyInfo = () => {
  const { balance } = useLoyalty()
  const { text } = useText()

  return (
    <Section>
      <SectionTitle>{text('Profile.Rewards.BankedCurrency.Title')}</SectionTitle>
      <SectionItem>
        <SectionItemIcon>
          <RewardsSectionIconImage variant="currency" />
        </SectionItemIcon>
        <SectionItemMeta>
          <div style={{ flex: 1 }}>
            <SectionItemTitle>{text('Profile.Rewards.BankedCurrency.Heading', { bankedCurrency: balance?.bankedCurrency })}</SectionItemTitle>
            <SectionItemDescription>{text('Profile.Rewards.BankedCurrency.Description')}</SectionItemDescription>
          </div>
        </SectionItemMeta>
      </SectionItem>
    </Section>
  )
}
