import { useQuery } from '@apollo/client'
import { useEffect, useMemo } from 'react'

import { useSession } from '../contexts/session'
import { GET_GUEST_RECENT_ORDERS } from '../data/guest/queries'
import { useNearestRestaurants } from './useNearestRestaurants'

export const useUserHistory = () => {
  const { state } = useSession()
  const { data: restaurants, fetchAll, loading: loadingRestaurants } = useNearestRestaurants()

  const { data: recentOrdersResponse, loading: loadingRecentOrders } = useQuery(GET_GUEST_RECENT_ORDERS, {
    skip: !state.user,
    fetchPolicy: 'network-only',
    variables: { orderingAuthenticationToken: state.user?.orderingAuthenticationToken },
  })

  useEffect(() => fetchAll(), [fetchAll])

  return useMemo(() => {
    if (!state.user) {
      return null
    }

    if (loadingRestaurants || !restaurants || !recentOrdersResponse || loadingRecentOrders) {
      return null
    }

    const [lastOrder] = recentOrdersResponse?.orders || []

    return { lastOrder, lastRestaurant: lastOrder ? restaurants.find(r => r.id === lastOrder.vendorId) : null, restaurants }
  }, [loadingRestaurants, restaurants, recentOrdersResponse, loadingRecentOrders, state.user])
}
