import styled from 'styled-components'

import { isDesktop, isMobile } from '../../../components/responsive/Responsive'

export const Container = styled.section`
  color: ${props => props.theme.colors.brand.black};
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px 0px;
  background: ${props => `url(${props.src}) no-repeat center center`};
  background-size: cover;
  justify-content: center;
  align-items: center;

  ${isDesktop`
    padding: 10px 0px;
  `}

  ${isMobile`
    padding: 0;
  `}
`
