import styled from 'styled-components'

export const VerticalBrowseMenuContainer = styled.div`
  background: ${props => props.theme.colors.brand.white};
  display: flex;
  flex-direction: column;
`

export const VerticalBrowseMenuFixedContainer = styled.div`
  position: fixed;
  z-index: 2;
  width: 100vw;
`

export const VerticalBrowseMain = styled.main`
  min-height: 100vh;
`

export const VerticalBrowseMenuSpacer = styled.div`
  height: ${props => `${props.$headerHeight}px`};
  width: 100%;
`
