import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const PageContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

export const NavBarContainer = styled.nav`
  display: flex;
  flex-wrap: wrap;
  min-height: 72px;
  align-items: center;
  background-color: white;
  justify-content: space-evenly;

  padding: 12px;
  ${({ theme }) => theme.profile.navBar.container};
`

export const NavItem = styled(Link)`
  font-size: 18px;
  padding: 10px 20px;
  text-align: center;

  ${({ theme }) => theme.profile.navBar.navItem};

  ${({ selected, theme }) =>
    selected &&
    css`
      font-weight: bolder;
      color: ${theme.profile.navBar.selectedItemTextColor};
      background-color: ${selected && theme.profile.navBar.selectedItemBackgroundColor};

      a {
        text-decoration: ${theme.profile.navBar.selectedItemTextDecoration};
      }
    `}
`

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 24px 24px 24px;

  justify-content: center;
`

export const HeaderContainer = styled.h1`
  font-family: ${({ theme }) => theme.font.styles.bold};
  font-size: 26px;

  width: 100%;
  display: flex;
  padding: 27px 0px;
  justify-content: center;
  position: relative;

  ${({ theme }) => theme.profile.header};

  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `}
`

export const Paragraph = styled.p`
  ${({ theme }) => theme.font.styles.description}
`

export const FormContainer = styled.div`
  width: 100%;
  max-width: 500px;
`
