import { useLoadScript } from '@react-google-maps/api'
import React, { createContext, useContext } from 'react'

const libraries = ['places']

export const MapContext = createContext()

export const MapContextProvider = ({ children }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  })
  return <MapContext.Provider value={{ isLoaded, loadError }}>{children}</MapContext.Provider>
}

export const useMapContext = () => useContext(MapContext)
