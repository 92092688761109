import styled from 'styled-components'

import { isMobile } from '../../../../components/responsive/Responsive'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const Body = styled.div`
  position: relative;
  padding: 24px;
  min-width: 390px;
  flex: 1;

  ${isMobile`
    padding: 16px;
    min-width: 100%;
  `}
`

export const Footer = styled.div`
  padding: 16px 24px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
`

export const ContentView = styled.div`
  background: ${props => props.theme.colors.brand.white};
  align-items: center;
  bottom: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
`

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  > * {
    &:not(:first-child) {
      margin-left: 12px;
    }
  }
`

export const CreditCardContainer = styled.div`
  overflow: hidden;
  width: 100%;
  border-bottom: ${props => props.theme.borders.light};
`

export const CVVContainer = styled.div`
  overflow: hidden;
  width: 100%;
  border-bottom: ${props => props.theme.borders.light};
`

export const CCSFInputContainer = styled.div`
  border: ${props => props.theme.borders.light};
  margin-top: 8px;
`

export const ExpiryContainer = styled.div`
  width: 100%;
`

export const ZipCodeContainer = styled.div`
  margin-top: 24px;
`

export const MessageContainer = styled.div``
