import React from 'react'

import { RewardCard } from './RewardCard'

export const RedeemableRewardCard = ({ reward, loading, onRedeem }) => (
  <RewardCard
    description={reward.description.trim()}
    expiringAt={reward.redemption?.expiringAt}
    iconProps={{ source: reward.rewardImageUrl }}
    loading={loading}
    name={reward.name}
    onClick={() => onRedeem(reward)}
  />
)
