import React, { useMemo } from 'react'

import { useText } from '../../../content'
import { useHomeContext } from '../Home.context'
import { ViewLocationsButton as Button } from './ViewLocationsButton.styles.js'

export const ViewLocationsButton = () => {
  const { viewLocations, showAll } = useHomeContext()
  const { text } = useText()
  const label = useMemo(() => (showAll ? text('Home.Restaurants.Locations.ShowNearest.Button') : text('Home.Restaurants.Locations.ShowAll.Button')), [
    showAll,
  ])
  return <Button label={label} onClick={viewLocations} />
}
