import { useMutation } from '@apollo/client'
import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Button } from '../../components/Button/Button'
import { Page } from '../../components/Page/Page'
import { TextInput } from '../../components/TextInput/TextInput'
import { useText } from '../../content'
import { useSession } from '../../contexts/session'
import { SEND_MIGRATION_VERIFICATION_CODE, VERIFY_MIGRATION_VERIFICATION_CODE } from '../../data/loyalty/queries'
import { useMessaging } from '../../hooks/useMessaging'
import { Container, Form, HeaderText, Paragraph } from './Auth.styles'
import { BackgroundImageSection } from './components/BackgroundImageSection'

export const MigrationVerificationPage = () => {
  const history = useHistory()
  const { text } = useText()
  const { dispatchMessage } = useMessaging()

  const {
    state: { user },
  } = useSession()

  const [code, setCode] = useState('')

  const [sendVerificationCodeMutation, { sendLoading }] = useMutation(SEND_MIGRATION_VERIFICATION_CODE, {
    onError: useCallback(err => dispatchMessage({ type: 'error', payload: { message: text('MigrateVerification.Errors.VerifyCode'), err } }), [
      text,
      dispatchMessage,
    ]),
  })

  const [verifyMutation, { verifyLoading }] = useMutation(VERIFY_MIGRATION_VERIFICATION_CODE, {
    onError: useCallback(err => dispatchMessage({ type: 'error', payload: { message: text('MigrateVerification.Errors.VerifyCode'), err } }), [
      text,
      dispatchMessage,
    ]),
  })

  const handleSendCode = async event => {
    event.preventDefault()
    const response = await sendVerificationCodeMutation({
      variables: {
        authentication: {
          loyaltyAuthenticationToken: user.authenticationToken,
          loyaltyAccessToken: user.accessToken,
          orderingAuthenticationToken: user.orderingAuthenticationToken,
        },
      },
    })
    if (response?.data?.sendMigrationVerificationCode?.success) {
      dispatchMessage({ type: 'info', payload: { message: 'Verification Code Sent' } })
    }
  }

  const handleVerifyCode = async () => {
    if (code) {
      const response = await verifyMutation({
        variables: {
          authentication: {
            loyaltyAuthenticationToken: user.authenticationToken,
            loyaltyAccessToken: user.accessToken,
            orderingAuthenticationToken: user.orderingAuthenticationToken,
          },
          migrationVerification: {
            token: code,
          },
        },
      })
      if (response?.data?.migrateGuest?.success) {
        history.push('/migration/completed')
      }
    }
  }

  const headerText = text('MigrateVerification.Heading', { brand: text('Brand.Name') })

  return (
    <Page isScrollable>
      <BackgroundImageSection tabIndex="0" aria-label={headerText}>
        <Container>
          <HeaderText style={{ textAlign: 'left' }}>{headerText}</HeaderText>
          <Form noValidate onSubmit={handleSendCode}>
            <Paragraph style={{ marginBottom: 20 }}>{text('MigrateVerification.Message', { brand: text('Brand.Name'), user })}</Paragraph>
            <Button variant="primary" label="Send Code" style={{ marginBottom: 32 }} type="submit" loading={sendLoading} />
            <Paragraph style={{ marginBottom: 20 }}>Enter verification code below.</Paragraph>
            <TextInput required placeholder="Verification Code" value={code} onChange={e => setCode(e.target.value)} />
            <Button disabled={!code} variant="primary" label="Confirm" style={{ marginTop: 32 }} onClick={handleVerifyCode} loading={verifyLoading} />
          </Form>
        </Container>
      </BackgroundImageSection>
    </Page>
  )
}
