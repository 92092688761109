import { filter } from 'lodash'
import React from 'react'

import { ControlContainer } from '../..//components/ControlContainer/ControlContainer'
import { Container } from '../..//components/ControlContainer/ControlContainer.styles'
import { useModifierValidation } from '../../hooks/useModifierValidation'
import { ModifierOptionToggleSelector } from '../ModifierOptionSelector/ModifierOptionToggleSelector/ModifierOptionToggleSelector'
import { FloatingLabel, FloatingText, ForwardIcon, HorizontalOption, HorizontalScrollView, ModifierLabel, Text } from './ModifierSelector.styles'

export const ModifierSelector = ({ horizontal = false, ...props }) => {
  const { modifier } = props

  if (modifier.options?.length === 1) {
    return <ModifierOptionToggleSelector key={modifier.id} modifier={modifier} option={modifier.options?.[0]} />
  }

  if (horizontal) {
    return <HorizontalModifierSelector {...props} />
  }

  return <DefaultModifierSelector {...props} />
}

const HorizontalModifierSelector = ({ modifier }) => {
  const { activeModifiers, selectOption } = useModifierValidation()
  const label = modifier.options?.length === 1 ? modifier.options[0].name : modifier.description

  return (
    <Container style={{ display: 'block', cursor: 'default' }}>
      <ModifierLabel>{label}</ModifierLabel>

      <HorizontalScrollView style={{ marginTop: 4 }}>
        {modifier.options.map(option => {
          const selected = activeModifiers.find(({ option: it }) => it.id === option.id)

          return (
            <HorizontalOption key={option.id} selected={selected} onClick={() => selectOption(modifier, option)}>
              <ModifierLabel>{option.name}</ModifierLabel>
            </HorizontalOption>
          )
        })}
      </HorizontalScrollView>
    </Container>
  )
}

const DefaultModifierSelector = ({ modifier, onClick }) => {
  const { activeModifiers } = useModifierValidation()

  let label = modifier.description
  if (modifier.options?.length === 1) {
    label = modifier.options[0].name
  }

  if (modifier.options?.length === 1) {
    return <ModifierOptionToggleSelector modifier={modifier} option={modifier.options[0]} />
  }

  const isRequired = modifier.mandatory
  const selection = filter(activeModifiers, m => m.modifier.id === modifier.id)
  const hasSelection = !!selection.length

  return (
    <ControlContainer
      as="button"
      onClick={onClick}
      isRequired={isRequired && !selection}
      style={{ padding: 16, paddingTop: hasSelection ? 34 : 24, paddingBottom: hasSelection ? 12 : 24 }}
    >
      {selection.length === 0 ? (
        <Text>{label}</Text>
      ) : (
        <>
          &nbsp;
          <FloatingLabel>{label}</FloatingLabel>
          <FloatingText>{selection.map(s => s.option?.name).join(', ')}</FloatingText>
        </>
      )}
      <ForwardIcon />
    </ControlContainer>
  )
}
