import React, { memo } from 'react'

import { StoreHoursBannerContainer, StoreHoursBannerText } from './StoreHoursBanner.styles'

export const StoreHoursBanner = memo(({ statusText }) => {
  if (!statusText) return
  return (
    <StoreHoursBannerContainer>
      <StoreHoursBannerText>{statusText}</StoreHoursBannerText>
    </StoreHoursBannerContainer>
  )
})
