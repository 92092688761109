/**
 * @param {function[]} componentsArray - array of React JSX components
 * @param {function} componentSeparator - React JSX component separator for each item in the array
 * @returns React JSX ready to be rendered
 */
export const join = (componentsArray, componentSeparator) =>
  componentsArray?.reduce((prev, curr) => (prev ? [...prev, componentSeparator(), curr] : [curr]), null)

/**
 * @param {Object[]} array - Array of items to be transformed as React JSX components
 * @param {function} mapFn - Mapping function to be used as the transformer (mapper) (Should result in an React JSX component)
 * @param {function} componentSeparator - React JSX component separator for each item in the array (optional)
 * @returns React JSX ready to be rendered
 */
export const transform = (array, mapFn, componentSeparator) => {
  const componentsArray = array?.map(mapFn)
  return componentSeparator ? join(componentsArray, componentSeparator) : componentsArray
}
