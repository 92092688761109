import React from 'react'
import { Redirect, Route } from 'react-router-dom'

const sanitizeChecks = checks => (Array.isArray(checks) ? checks : checks ? [checks] : [])

const hasAllChecksValid = checks => !sanitizeChecks(checks).some(check => !check())

export const CustomRoute = ({ checks, children, onFailedRedirectTo, ...rest }) => {
  checks = Array.isArray(checks) ? checks : checks ? [checks] : null
  return (
    <Route
      {...rest}
      render={({ location }) => {
        return hasAllChecksValid(checks) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: onFailedRedirectTo || location?.state?.from || '/',
              state: { from: location },
            }}
          />
        )
      }}
    />
  )
}
