import { GoogleMap } from '@react-google-maps/api'
import { pick, random } from 'lodash'
import moment from 'moment'
import React, { useEffect, useMemo, useRef, useState } from 'react'

import { CircleLoadingIndicator } from '../../../components/CircleLoadingIndicator/CircleLoadingIndicator'
import { MAP_OPTIONS } from '../../../components/Map/Map'
import { MapContextProvider, useMapContext } from '../../../components/Map/Map.context'
import { OrderTrackingProgressBar } from '../../../components/OrderTrackingProgressBar/OrderTrackingProgressBar'
import { Page } from '../../../components/Page/Page'
import { DesktopOrTablet, Mobile } from '../../../components/responsive/Responsive'
import { useText } from '../../../content/index'
import { useConfig } from '../../../contexts/appConfig'
import { formatCurrency } from '../../../data/utils'
import useOrderTracking from '../../../hooks/useOrderTracking'
import { buildOrderConfirmationAddressLine } from '../../../utils/address'
import * as Checkout from '../../checkout/Checkout.styles'
import {
  InfoSkeleton,
  OrderTrackingAside,
  OrderTrackingCloseButton,
  OrderTrackingContainer,
  OrderTrackingForegroundImage,
  OrderTrackingHeaderContainer,
  OrderTrackingHeaderText,
  OrderTrackingHeaderTitle,
  OrderTrackingMapContainer,
  OrderTrackingSection,
  OrderTrackingSectionBody,
  OrderTrackingSectionTitle,
  OrderTrackingSummaryContainer,
} from '../OrderTracking.styles'
import { OrderTrackingCourierMarker, OrderTrackingDestinationMarker, OrderTrackingSourceMarker } from '../OrderTrackingIcons'

const OrderTracking = ({ orderId, onClose }) => {
  const { isLoaded: mapLoaded } = useMapContext()
  const [status, setStatus] = useState()
  const { text } = useText()
  const { getAsset } = useConfig()

  const mapRef = useRef()

  const { loading, order, restaurant, error, initialInfo } = useOrderTracking(orderId, trackingInfo => {
    setStatus({ ...trackingInfo, lat: trackingInfo.latitude, lng: trackingInfo.longitude })
  })

  const restaurantCoordinates = { lat: restaurant?.latitude, lng: restaurant?.longitude }
  const addressCoordinates = { lat: order?.deliveryAddress?.latitude, lng: order?.deliveryAddress?.longitude }
  const courierCoordinates = pick(status, ['lat', 'lng'])

  const hasCourierInfo = Boolean(status?.lat && status?.lng)

  const stateText = useMemo(() => {
    const estimatedArrivalTime = moment.utc((status || initialInfo)?.estimatedArrivalTime).local()
    const isSameDay = moment().isSame(estimatedArrivalTime, 'day')
    const timestampText = estimatedArrivalTime.format(isSameDay ? 'h:mm a' : 'MMMM Do [at] h:mm a')

    return (status || initialInfo)?.status === TrackingStatus.DELIVERED
      ? `Delivered to ${buildOrderConfirmationAddressLine(order?.deliveryAddress)}.`
      : `Arriving around ${timestampText}.`
  }, [status, initialInfo, order])

  const orderProducts = useMemo(() => order?.products.filter(p => !p?.metadata?.some(metadata => metadata?.key === 'APP_HIDDEN')), [order?.products])

  useEffect(() => {
    if (!mapRef.current || !mapLoaded) {
      return
    }

    const bounds = new window.google.maps.LatLngBounds()

    if (restaurant?.latitude && restaurant?.longitude) {
      bounds.extend(new window.google.maps.LatLng(restaurant.latitude, restaurant.longitude))
    }

    if (order?.deliveryAddress?.latitude && order?.deliveryAddress?.longitude) {
      bounds.extend(new window.google.maps.LatLng(order.deliveryAddress.latitude, order.deliveryAddress.longitude))
    }

    if (status?.lat && status?.lng) {
      bounds.extend(new window.google.maps.LatLng(status.lat, status.lng))
    }

    mapRef.current.fitBounds(bounds)
  }, [restaurant, order, status, mapRef, mapLoaded])

  const currentStep = useMemo(() => statusReducer(status?.status || initialInfo?.status), [initialInfo, status])

  const foregroundImageUrl = useMemo(() => getAsset('OrderTrackingForeground'), [getAsset])

  const renderOrderTrackingHeader = (
    <OrderTrackingHeaderContainer>
      <OrderTrackingCloseButton onClick={onClose} variant="secondary" />
      <OrderTrackingForegroundImage src={foregroundImageUrl?.url} />
      <OrderTrackingHeaderTitle>{text('OrderConfirmation.Header.Title')}</OrderTrackingHeaderTitle>
      <InfoSkeleton loading={!order && loading} style={{ marginTop: 7, width: random(100, 200, false) }}>
        <OrderTrackingHeaderText>{text('OrderConfirmation.Header.Text', { number: order?.oloId })}</OrderTrackingHeaderText>
      </InfoSkeleton>
      <InfoSkeleton loading={!status && !initialInfo} style={{ marginTop: 7, width: random(100, 200, false) }}>
        <OrderTrackingHeaderText>{stateText}</OrderTrackingHeaderText>
      </InfoSkeleton>
    </OrderTrackingHeaderContainer>
  )

  return (
    <Page>
      <OrderTrackingContainer>
        <Mobile>{renderOrderTrackingHeader}</Mobile>
        <OrderTrackingSection tabIndex="0" aria-label="Order confirmation details">
          <DesktopOrTablet>{renderOrderTrackingHeader}</DesktopOrTablet>
          <OrderTrackingSectionBody style={{ gap: 0 }}>
            <OrderTrackingHeaderTitle>
              {text(`OrderTracking.Header.${(status || initialInfo)?.status || TrackingStatus.PENDING}`)}
            </OrderTrackingHeaderTitle>
            <OrderTrackingProgressBar loading={loading} currentStep={currentStep} iconNames={['IoIosCheckmark', 'IoMdRestaurant', 'IoIosCar']} />
            {order && (
              <OrderTrackingSummaryContainer>
                {orderProducts.map((product, idx) => (
                  <Checkout.ProductContainer key={`${product.name}-${product.totalCost}`} style={{ marginTop: idx > 0 ? 16 : 0, padding: 20 }}>
                    <Checkout.Meta>
                      <Checkout.ProductName style={{ fontWeight: 'bold', fontSize: 18 }}>
                        {product.name} {product.quantity > 1 && <Checkout.ProductQuantity>({product.quantity})</Checkout.ProductQuantity>}
                      </Checkout.ProductName>
                      <Checkout.CostsNumber style={{ fontWeight: 'bold', fontSize: 18 }}>{formatCurrency(product.totalCost)}</Checkout.CostsNumber>
                    </Checkout.Meta>

                    <Checkout.IngredientsList menuItem={product} lightText />
                  </Checkout.ProductContainer>
                ))}

                <Checkout.CostsContainer>
                  <hr />
                  <Checkout.CostItem>
                    <Checkout.CostsLabel>{text('Checkout.Subtotal.Label')}</Checkout.CostsLabel>
                    <Checkout.CostsNumber style={{ fontSize: 18 }}>
                      {formatCurrency(order?.subtotal)} + {formatCurrency(order?.salesTax)}
                    </Checkout.CostsNumber>
                  </Checkout.CostItem>

                  {order?.tip > 0 && (
                    <>
                      <hr />
                      <Checkout.CostItem>
                        <Checkout.CostsLabel>{text('Checkout.Tip.Label')}</Checkout.CostsLabel>
                        <Checkout.CostsNumber style={{ fontSize: 18 }}>{formatCurrency(order?.tip)}</Checkout.CostsNumber>
                      </Checkout.CostItem>
                    </>
                  )}

                  {order?.customerHandoffCharge > 0 && (
                    <Checkout.CostItem>
                      <Checkout.CostsLabel>{text('Checkout.DeliveryFee.Label')}</Checkout.CostsLabel>
                      <Checkout.CostsNumber style={{ fontSize: 18 }}>{formatCurrency(order?.customerHandoffCharge)}</Checkout.CostsNumber>
                    </Checkout.CostItem>
                  )}

                  {order?.discounts?.length > 0 &&
                    order.discounts.map((discount, index) => (
                      <Checkout.CostItem key={index}>
                        <Checkout.CostsLabel isPromo>{text('Checkout.Discount.Label')}</Checkout.CostsLabel>
                        <Checkout.CostsNumber isPromo>{formatCurrency(discount.amount)}</Checkout.CostsNumber>
                      </Checkout.CostItem>
                    ))}

                  <hr />
                  <Checkout.CostItem>
                    <Checkout.CostsLabel style={{ fontWeight: 'bold', fontSize: 18 }}>Total</Checkout.CostsLabel>
                    <Checkout.CostsNumber style={{ fontWeight: 'bold', fontSize: 18 }}>{formatCurrency(order?.total)}</Checkout.CostsNumber>
                  </Checkout.CostItem>
                </Checkout.CostsContainer>
              </OrderTrackingSummaryContainer>
            )}
          </OrderTrackingSectionBody>
        </OrderTrackingSection>
        <OrderTrackingAside>
          {!mapLoaded || !order || !restaurant ? (
            <CircleLoadingIndicator />
          ) : (
            <OrderTrackingMapContainer>
              <GoogleMap
                options={MAP_OPTIONS}
                mapContainerStyle={{ width: '100%', height: '100%' }}
                center={hasCourierInfo ? courierCoordinates : restaurantCoordinates}
                onLoad={map => {
                  if (!mapRef.current) {
                    mapRef.current = map
                  }
                }}
              >
                {order && restaurant && (
                  <>
                    <OrderTrackingSourceMarker key="restaurant" position={restaurantCoordinates} label={restaurant?.name} />
                    <OrderTrackingDestinationMarker key="address" position={addressCoordinates} label={order?.deliveryAddress?.streetAddress} />
                  </>
                )}
                {hasCourierInfo && <OrderTrackingCourierMarker key="courier" position={courierCoordinates} type={initialInfo.deliveryProvider} />}
              </GoogleMap>
            </OrderTrackingMapContainer>
          )}
        </OrderTrackingAside>
      </OrderTrackingContainer>
    </Page>
  )
}

const TrackingStatus = {
  PENDING: 'Pending',
  DELIVERED: 'Delivered',
  PICKUPINPROGRESS: 'PickupInProgress',
  DELIVERYINPROGRESS: 'DeliveryInProgress',
}

const statusReducer = status => {
  const statusToStep = {
    [TrackingStatus.PENDING]: 0,
    [TrackingStatus.DELIVERED]: 2,
    [TrackingStatus.PICKUPINPROGRESS]: 1,
    [TrackingStatus.DELIVERYINPROGRESS]: 1,
  }

  return statusToStep[status] || 0
}

export const DefaultOrderTracking = props => (
  <MapContextProvider>
    <OrderTracking {...props} />
  </MapContextProvider>
)
