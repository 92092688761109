import { useTheme } from 'styled-components'

import { useConfig } from '../contexts/appConfig'

const getValue = (ref, paths) => {
  let obj = ref

  for (const key of paths) {
    obj = obj?.[key]
  }

  return obj
}

export const useAsset = (...paths) => {
  let url

  const { getAsset } = useConfig()
  const theme = useTheme()

  const value = getValue(theme, paths)

  if (value) {
    const asset = getAsset(value)
    if (asset) {
      url = asset.url
    }
  }

  return { url }
}
