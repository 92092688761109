import { useMemo } from 'react'

import { useConfig } from '../contexts/appConfig'

export const useFeatureFlags = () => {
  const { state } = useConfig()
  return useMemo(
    () => ({
      ...state?.featureFlags,
      ...(process.env.REACT_APP_FEATURE_FLAGS ? JSON.parse(process.env.REACT_APP_FEATURE_FLAGS) : {}),
    }),
    [state?.featureFlags],
  )
}
