import { find } from 'lodash'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'

import { Button } from '../../../../../components/Button/Button'
import { ModalHeader } from '../../../../../components/ModalHeader/ModalHeader'
import { ModalWithBackdrop } from '../../../../../components/ModalWithBackdrop/ModalWithBackdrop'
import { SelectInput } from '../../../../../components/SelectInput/SelectInput'
import { TIME_WANTED_MODES } from '../../../../../constants/timeWantedModes'
import { useText } from '../../../../../content'
import { useConfig } from '../../../../../contexts/appConfig'
import { useSession } from '../../../../../contexts/session'
import { useMessaging } from '../../../../../hooks/useMessaging'
import { useRestaurantsCalendars } from '../../../../../hooks/useRestaurantCalendars'
import { BlockContainer, ButtonContainer, Container, DateButton, Form, InputContainer, TimeButton } from '../SelectTimeModal.styles'

export const PickupTimeModal = ({ dialogProps, restaurant, onSelectScheduleTime }) => {
  const { dispatchMessage } = useMessaging()
  const { text } = useText()
  const {
    state: { basket, pickupTime },
  } = useSession()

  const { data: restaurantsWithCalendars, calculateNextOpenDays } = useRestaurantsCalendars([restaurant])
  const { getPreference } = useConfig()
  const { AdvanceOrderingDayLimitPickup = 3 } = getPreference('OrderingConfiguration') || {}

  const today = moment()
  const tomorrow = today.clone().add(1, 'days')

  const nextOpenDays = useMemo(() => {
    if (restaurantsWithCalendars?.length > 0) {
      const rest = find(restaurantsWithCalendars, r => r?.id === restaurant?.id)
      if (rest) {
        const days = calculateNextOpenDays(rest, basket, AdvanceOrderingDayLimitPickup)
        return days
      } else {
        dispatchMessage.error("For some reason we couldn't load the calendar for the restaurant")
      }
    }
  }, [dispatchMessage, restaurant, restaurantsWithCalendars, basket, AdvanceOrderingDayLimitPickup])

  const [selectedDate, setSelectedDate] = useState()
  const [selectedTime, setSelectedTime] = useState()

  const handleConfirm = e => {
    e.preventDefault()
    console.debug('[PickupTimeModal] onSelectScheduleTime:', selectedTime)
    onSelectScheduleTime(restaurant, selectedTime?.item?.toISOString())
  }

  useEffect(() => {
    if (!pickupTime || selectedDate || selectedTime) return
    const pickupMoment = moment(pickupTime)

    const date = nextOpenDays?.find(item => item.info.start.format('YYYY-MM-DD') === pickupMoment.format('YYYY-MM-DD'))

    if (!date) return
    setSelectedDate(mapDateEntry(date, today, tomorrow))

    const index = date?.timeSlots?.findIndex((item, idx) => {
      return idx === 0 ? pickupMoment.diff(item) <= 0 : pickupMoment.diff(item) === 0
    })
    if (index < 0) return

    const time = date?.timeSlots?.[index]
    setSelectedTime(mapTimeEntry(pickupMoment, today, time, index))
  }, [pickupTime, nextOpenDays, today, tomorrow])

  const dates = useMemo(
    () =>
      nextOpenDays
        ?.sort((a, b) => {
          return a.info?.start?.diff(b.info.start) || b.info?.start?.diff(a.info.start)
        })
        ?.map(item => {
          return mapDateEntry(item, today, tomorrow)
        }) || [],
    [nextOpenDays, today, tomorrow],
  )

  const times = useMemo(
    () =>
      selectedDate?.item?.timeSlots?.map((item, index) => {
        return mapTimeEntry(selectedDate?.item?.info?.start, today, item, index)
      }) || [],
    [selectedDate?.value, today],
  )

  return (
    <ModalWithBackdrop dialogProps={dialogProps} ariaLabel={text('SelectTime.Pickup.Heading')}>
      <ModalHeader title={text('SelectTime.Pickup.Heading')} onClose={dialogProps.hide} />
      <Container>
        <Form onSubmit={handleConfirm}>
          <InputContainer>
            <SelectInput
              as={DateButton}
              name="scheduleDate"
              placeholder={text('SelectTime.Date.Placeholder')}
              value={selectedDate}
              onChange={e => {
                setSelectedDate(e.selectedItem)
                setSelectedTime(undefined)
              }}
              items={dates}
              itemToString={item => item?.dateLabel}
              required
              selected={item => item?.value === selectedDate?.value}
            />
            <SelectInput
              as={TimeButton}
              name="selectedTime"
              placeholder={text('SelectTime.Time.Placeholder')}
              value={selectedTime}
              onChange={e => setSelectedTime(e.selectedItem)}
              items={times}
              itemToString={item => item?.timeLabel}
              required
              selected={item => item?.value === selectedTime?.value}
              disabled={!selectedDate}
            />
          </InputContainer>
          <ButtonContainer>
            <BlockContainer>
              <Button label={text('SelectTime.Button')} onClick={handleConfirm} variant="primary" disabled={!selectedTime} />
            </BlockContainer>
          </ButtonContainer>
        </Form>
      </Container>
    </ModalWithBackdrop>
  )
}

const mapDateEntry = (item, today, tomorrow) => {
  const isToday = today.isSame(item.info.start, 'days')
  const isTomorrow = tomorrow.isSame(item.info.start, 'days')
  const value = item.info.start.format('YYYY-MM-DD')
  const weekday = item.info.start.format('dddd').substring(0, 3)
  const dateLabel = isToday ? 'Today' : isTomorrow ? 'Tomorrow' : `${weekday}. ${item.info.start.format('MMM DD')}`
  return { dateLabel, item, value }
}

const mapTimeEntry = (moment, today, item, index) => {
  const isAsap = moment.isSame(today, 'day') && index === 0
  const value = isAsap ? TIME_WANTED_MODES.ASAP : item.format('h:mm a')
  const timeLabel = isAsap ? `${item.format('h:mm a')} (asap)` : item.format('h:mm a')
  return { timeLabel, item, value }
}
