import { useMutation, useQuery } from '@apollo/client'
import React, { useCallback } from 'react'
import styled from 'styled-components'

import { Button } from '../../../../components/Button/Button'
import { useText } from '../../../../content'
import { useSession } from '../../../../contexts/session'
import { DELETE_GUEST_ADDRESS, GET_GUEST_ADDRESSES } from '../../../../data/guest/queries'
import { useMessaging } from '../../../../hooks/useMessaging'
import { buildAddressLine1, buildAddressLine2 } from '../../../../utils/address'
import { ContentContainer, FormContainer, HeaderContainer, Paragraph } from '../../Profile.styles'

export const DefaultSavedAddresses = () => {
  const { state } = useSession()
  const { text } = useText()

  const { data, loading } = useQuery(GET_GUEST_ADDRESSES, {
    fetchPolicy: 'network-only',
    variables: { token: state.user?.orderingAuthenticationToken },
  })

  if (loading) {
    return (
      <>
        <HeaderContainer>{text('Profile.SavedAddresses.NavBar.Title')}</HeaderContainer>
        <ContentContainer>
          <Paragraph>Loading...</Paragraph>
        </ContentContainer>
      </>
    )
  }

  if (!data?.guestAddresses?.length) {
    return (
      <>
        <HeaderContainer>{text('Profile.SavedAddresses.NavBar.Title')}</HeaderContainer>
        <ContentContainer>
          <Paragraph style={{ textAlign: 'center' }}>{text('Profile.SavedAddresses.EmptyMessage')}</Paragraph>
        </ContentContainer>
      </>
    )
  }

  return (
    <>
      <HeaderContainer>{text('Profile.SavedAddresses.NavBar.Title')}</HeaderContainer>

      <ContentContainer>
        <Paragraph style={{ textAlign: 'center' }}>Edit or remove your saved addresses. Add a new address by starting a delivery order.</Paragraph>

        <FormContainer style={{ marginTop: 48 }}>
          {data?.guestAddresses.map((address, idx) => (
            <AddressCard key={idx} style={{ marginTop: idx > 0 ? 12 : 0 }} {...address} />
          ))}
        </FormContainer>
      </ContentContainer>
    </>
  )
}

const AddressCard = ({ style, ...address }) => {
  const { text } = useText()
  const { state } = useSession()
  const { dispatchMessage } = useMessaging()
  const [deleteAddressMutation, { loading }] = useMutation(DELETE_GUEST_ADDRESS, {
    onError: e =>
      dispatchMessage({
        type: 'error',
        payload: { message: text('Profile.AddressDetails.Errors.Delete'), error: e },
      }),

    onCompleted: () =>
      dispatchMessage({
        type: 'info',
        payload: { message: 'Address deleted.' },
      }),
  })

  const handleRemove = useCallback(
    () =>
      deleteAddressMutation({
        variables: { addressId: `${address.id}`, orderingAuthenticationToken: state.user?.orderingAuthenticationToken },
        refetchQueries: [{ query: GET_GUEST_ADDRESSES, variables: { token: state.user?.orderingAuthenticationToken } }],
      }),
    [address.id, state.user?.orderingAuthenticationToken],
  )

  return (
    <AddressCardContainer style={style}>
      <AddressMetaContainer>
        <span>{buildAddressLine1(address)}</span>
        <span>{buildAddressLine2(address)}</span>
      </AddressMetaContainer>
      <Button label="remove" style={{ flex: 0, padding: 0 }} loading={loading} disabled={loading} onClick={handleRemove} />
    </AddressCardContainer>
  )
}

const AddressMetaContainer = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-family: ${({ theme }) => theme.font.styles.regular};
  }
`

const AddressCardContainer = styled.div`
  display: flex;
  padding: 24px;
  align-items: center;
  justify-content: space-between;

  border-radius: 3px;
  border: 1.5px solid #d3d3d3;

  flex-wrap: wrap;

  font-family: ${({ theme }) => theme.font.styles.regular};
`
