import { Marker } from '@react-google-maps/api'
import React, { memo } from 'react'
import { useTheme } from 'styled-components'

import { MapMarkerTextLabel } from './MapMarkerTextLabel'

const inactive = require('../../assets/icons/lpq/map_marker_inactive.svg')
const active = require('../../assets/icons/lpq/map_marker.svg')

export const MapMarker = memo(({ selected, label, ...markerProps }) => {
  const { mapOverlay, brand } = useTheme()
  const { activeMarker, inactiveMarker } = mapOverlay

  if (brand === 'lpq') {
    return <Marker {...markerProps} icon={selected ? active : inactive} />
  }

  return <Marker {...markerProps} icon={selected ? activeMarker : inactiveMarker} />
})

export const MapMarkerWithLabel = memo(({ icon, label, position, showInfoBox, ...props }) => (
  <>
    {icon ? <Marker icon={icon} position={position} {...props} /> : <MapMarker selected {...props} />}
    {showInfoBox && label && <MapMarkerTextLabel position={position} textLabel={label} />}
  </>
))
