import { FaCheckCircle } from 'react-icons/fa'
import { FiMinus, FiPlus } from 'react-icons/fi'
import { Button as ReakitButton } from 'reakit/Button'
import styled, { css } from 'styled-components'

import { DescriptionSmall } from '../../../../components/Text/Text.styles'

export const Container = styled.div`
  display: flex;
  flex-direction: row;

  border-radius: 3px;
  border: ${({ theme, active }) =>
    active ? theme.menuItems.customBuilder.optionCards.borderActive : theme.menuItems.customBuilder.optionCards.border};

  min-height: 127px;
  width: 100%;
`

export const Image = styled.div`
  background: ${props => `url(${props.src}) no-repeat center center`};
  background-position: center;
  background-size: cover;
  overflow: hidden;

  min-width: 163px;

  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
`

export const ContentContainer = styled.div`
  display: flex;
  padding: 12px 11px 12px 8px;
  flex-direction: column;
  width: 100%;
`

export const NameText = styled.p`
  ${({ theme }) => theme.font.styles.labelInput};
  color: ${({ theme }) => theme.colors.brand.black.hex()};
  margin-bottom: 12px;
`

export const DescriptionText = styled.p`
  ${({ theme }) => theme.font.styles.labelInput};
  color: ${({ theme }) => theme.colors.brand.gray70};
  font-size: ${props => props.theme.font.sizes.xs};
  font-weight: 500;
`

export const DietaryContainer = styled.div`
  margin-top: 6px;
`

export const DietaryRestrictionContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 6px;
  align-items: flex-start;
`

export const DietaryText = styled(DescriptionSmall)`
  color: ${({ theme }) => theme.menuItems.dietaryRestrictions.warningColor};
  letter-spacing: 0.02em;
`

export const ControlContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-top: auto;
`

export const Price = styled.span`
  ${({ theme }) => theme.font.styles.description}
`

export const QuantityControls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
`

export const IconButton = styled(ReakitButton)`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  border-radius: 12px;
  height: 24px;
  width: 24px;

  background: ${({ theme }) => theme.colors.brand.white.hex()};

  ${({ theme }) => css`
    border: ${theme.menuItems.customBuilder.optionCards.quantityButtonBorder};
  `};

  :disabled {
    ${({ theme }) => css`
      border: ${theme.menuItems.customBuilder.optionCards.quantityButtonBorderDisabled};
    `};
  }

  > svg {
    color: ${({ theme }) => theme.menuItems.customBuilder.optionCards.quantityButtonColor};
  }

  :disabled > svg {
    ${({ theme }) => css`
      color: ${theme.menuItems.customBuilder.optionCards.quantityButtonColorDisabled};
    `};
  }
`

export const AddIcon = styled(FiPlus).attrs(({ theme, disabled }) => ({
  size: 14,
}))``

export const SubtractIcon = styled(FiMinus).attrs(({ theme }) => ({
  size: 14,
  color: theme.colors.black.hex(),
}))``

export const QuantityLabel = styled.span`
  ${({ theme }) => theme.font.styles.labelInput}
  font-size: ${({ theme }) => theme.font.sizes.lg};
  margin: 0 12px;
`

export const ToggleContainer = styled.div`
  border-radius: 50px;
  border: ${({ theme, active }) => (active ? theme.toggles.active.border : theme.toggles.inactive.border)};
  height: 24px;
  width: 24px;
  justify-content: center;
  align-items: center;

  display: flex;
  cursor: pointer;

  ${({ theme, active }) =>
    active
      ? css`
          background-color: ${theme.toggles.active.backgroundColor};
        `
      : ''}
`

export const CheckIcon = styled(FaCheckCircle).attrs(props => ({
  size: 22,
  color: props.theme.toggles.active.textColor,
}))``
