import React from 'react'

import { Label } from '../../components/Text/Text.styles'
import { useHandoffModes } from '../../hooks/useHandoffModes'
import { TabButton, TabContainer } from './SelectHandoffMode.styles'

/**
 * Please keep the `$active` prop with the `$` prefix to prevent it to consumed by the `styled-component`.
 * This avoid the following React warning: `Warning: Received `true` for a non-boolean attribute `active`.
 * If you want to write it to the DOM, pass a string instead: active="true" or active={value.toString()}.`
 * @see {@link https://styled-components.com/docs/api#transient-props}
 */
export const SelectHandoffMode = ({ activeHandoffMode, changeHandoffMode, tabState }) => {
  const handoffModes = useHandoffModes()
  return (
    <TabContainer {...tabState} aria-label="Select handoff mode">
      {handoffModes.map(handoffMode => (
        <TabButton
          {...tabState}
          $active={activeHandoffMode === handoffMode.value}
          id={`handoffmode-${handoffMode.value}`}
          key={handoffMode.value}
          onClick={() => changeHandoffMode(handoffMode.value)}
        >
          <Label>{handoffMode.displayName}</Label>
        </TabButton>
      ))}
    </TabContainer>
  )
}
