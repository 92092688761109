import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react'

import { useFeatureFlags } from '../../../hooks/useFeatureFlags'
import { buildAddress } from '../../../utils/address'
import { DoubleCTA } from '../../DoubleCTA/DoubleCTA'
import { ModalBodyContainer, ModalFooterContainer } from '../../ModalWithBackdrop/ModalWithBackdrop.styles'
import { TextInput } from '../../TextInput/TextInput'
import { ToggleButton } from '../../ToggleButton/ToggleButton'
import { Form } from '../AddressForm.styles'

const reducer = (state, action) => ({ ...state, [action.type]: action.payload })

export const UpdateAddressForm = ({ address, onSubmit, submitButtonLabel }) => {
  const [submitLoading, setSubmitLoading] = useState(false)
  const [state, dispatch] = useReducer(reducer, buildAddress({ address }))
  const { FirebaseSupport } = useFeatureFlags()

  const isValid = useMemo(() => {
    return state.streetAddress?.trim().length > 0
  }, [state])

  const buttons = useMemo(
    () => [{ disabled: !isValid || submitLoading, loading: submitLoading, label: submitButtonLabel, type: 'submit', variant: 'primary' }],
    [isValid, submitLoading],
  )

  const handleSubmit = useCallback(
    event => {
      event.preventDefault()
      setSubmitLoading(true)
      return onSubmit(state).finally(cb => {
        setSubmitLoading(false)
        return cb?.()
      })
    },
    [state, onSubmit, setSubmitLoading],
  )

  useEffect(() => {
    console.debug('[ChangeCheckoutAddressForm] state changed:', { state, isValid, submitLoading })
  }, [state, isValid, submitLoading])

  return (
    <Form onSubmit={handleSubmit}>
      <ModalBodyContainer>
        {FirebaseSupport && (
          <TextInput
            name="nickname"
            placeholder="Nickname (optional)"
            value={state.nickname}
            onChange={e => dispatch({ type: 'nickname', payload: e.target.value })}
          />
        )}
        <TextInput
          name="streetAddress"
          placeholder="Street Address"
          value={state.streetAddress}
          onChange={e => dispatch({ type: 'streetAddress', payload: e.target.value })}
          required
          readOnly
        />
        <TextInput
          name="aptNumber"
          value={state.aptNumber}
          placeholder="Apt Number (optional)"
          onChange={e => dispatch({ type: 'aptNumber', payload: e.target.value })}
        />
        {FirebaseSupport ? (
          <ToggleButton
            name="defaultAddress"
            checked={state.isDefault}
            onClick={e => dispatch({ type: 'isDefault', payload: e.target.checked })}
            style={{ marginBottom: '10px' }}
          >
            Set as default address
          </ToggleButton>
        ) : (
          <ToggleButton name="defaultAddress" checked={state.shouldSaveAddress} onClick={state.toggleSaveAddress} style={{ marginBottom: '10px' }}>
            Save Address
          </ToggleButton>
        )}
      </ModalBodyContainer>
      <ModalFooterContainer>
        <DoubleCTA buttons={buttons} stacked={false} />
      </ModalFooterContainer>
    </Form>
  )
}
