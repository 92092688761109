import debounce from 'lodash.debounce'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { db } from '../services/firebase'
import { pickActiveAddressFields } from '../utils/address'

export const transformDoc = doc => ({ ...doc.data(), id: doc.id })

export const useSavedAddresses = ({ isAuthenticated, userDocRef }) => {
  const [state, setState] = useState({ loading: true, addresses: [] })

  const userAddressesCollection = useMemo(() => userDocRef?.collection('addresses'), [userDocRef])

  const addAddress = useCallback(
    async newAddress => {
      const address = pickActiveAddressFields(newAddress)
      console.debug('[useSavedAddresses] adding new address=', { address })

      if (!userAddressesCollection) return

      setState(prev => ({ ...prev, loading: true }))

      if (address?.docId) {
        // if this is an existing saved address, just update it
        return await updateAddress(address.docId, address)
      }

      const existingAddresses = await userAddressesCollection
        .where('aptNumber', '==', address.aptNumber)
        .where('city', '==', address.city)
        .where('phoneNumber', '==', address.phoneNumber)
        .where('streetAddress', '==', address.streetAddress)
        .where('zipCode', '==', address.zipCode)
        .get()

      if (!existingAddresses.empty) {
        setState(prev => ({ ...prev, loading: false }))
        return {
          ...existingAddresses.docs[0].data(),
          docId: existingAddresses.docs[0].id,
        }
      }

      const batch = db.batch()

      if (address.isDefault) {
        await userAddressesCollection.get().then(snapshot => {
          snapshot.docs.forEach(doc => batch.update(doc.ref, { isDefault: false }))
        })
      }

      batch.set(userAddressesCollection.doc(), { isDefault: false, ...address })

      return batch.commit().finally(() => setState(prev => ({ ...prev, loading: false })))
    },
    [userAddressesCollection, setState],
  )

  const removeAddress = useCallback(
    addressId => {
      console.debug('[useSavedAddresses] with id=', addressId)
      if (!userAddressesCollection) {
        throw new Error('User not logged in.')
      }
      return userAddressesCollection.doc(addressId).delete()
    },
    [userAddressesCollection],
  )

  const updateAddress = useCallback(
    async (docId, address) => {
      console.debug('[useSavedAddresses] updating address with id=', docId, address)
      if (!userAddressesCollection) throw new Error('User not logged in.')

      setState(prev => ({ ...prev, loading: true }))

      const batch = db.batch()

      if (address.isDefault) {
        await userAddressesCollection.get().then(snapshot => {
          snapshot.docs.forEach(doc => batch.update(doc.ref, { isDefault: false }))
        })
      }

      batch.set(userAddressesCollection.doc(docId), address, { merge: true })

      return batch.commit().finally(() => setState(prev => ({ ...prev, loading: false })))
    },
    [userAddressesCollection, setState],
  )

  useEffect(() => {
    setState(prev => ({ ...prev, loading: true }))
    if (!isAuthenticated || !userAddressesCollection) {
      setState(prev => ({ ...prev, loading: false }))
      return
    }
    const dispose = userAddressesCollection.onSnapshot(
      debounce(snapshot => {
        const addresses = snapshot.docs?.map(transformDoc) || []
        setState(prev => ({ ...prev, addresses, loading: false }))
      }, 1000),
      error => {
        console.error(error)
        setState({ addresses: [], loading: false })
      },
    )
    return dispose
  }, [isAuthenticated, userAddressesCollection, setState])

  return { ...state, addAddress, removeAddress, updateAddress }
}
