import moment from 'moment-timezone'
import React, { useEffect, useMemo, useState } from 'react'
import { Group } from 'reakit/Group'

import { Button } from '../../../components/Button/Button'
import { Card, CardBody, CardFooter, CardHeader, CardList } from '../../../components/Card/Card'
import { OrderHistoryEntryCard } from '../../../components/OrderHistoryEntryCard/OrderHistoryEntryCard'
import { DescriptionExtraSmall, LabelSmall } from '../../../components/Text/Text.styles'
import { HANDOFF_MODES } from '../../../constants/handoffModes'
import { useText } from '../../../content'
import { useRestaurantCalendarRanges } from '../../../hooks/useRestaurantCalendarRanges'
import { useRestaurantWorkingHours } from '../../../hooks/useRestaurantWorkingHours'
import { useUserHistory } from '../../../hooks/useUserHistory'
import firebase from '../../../services/firebase'
import { formatTime, getCurrentWeekdayShort } from '../../../utils'
import { useHomeContext } from '../Home.context'
import {} from '../Home.styles'
import {
  Container,
  HistoryEntryContainer,
  PillsContainer,
  PillText,
  SectionTitle,
  StartOrderContainer,
  SummaryText,
} from './RestaurantCardList.styles'
import { WorkingHours } from './WorkingHours'

const RESTAURANT_CARD_UPDATE_INTERVAL = 10000

const RestaurantCard = ({ handoffMode, restaurant, selected, selectRestaurant, scheduleTime, startOrder, ...props }) => {
  const calendarRanges = useRestaurantCalendarRanges({ handoffMode, restaurant })
  const currentWeekday = useMemo(() => getCurrentWeekdayShort(), [])
  const {
    today: { end: todayEnd },
    workingHours,
  } = useRestaurantWorkingHours(currentWeekday, calendarRanges)

  const { text } = useText()
  const [now, setNow] = useState(moment())

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(moment())
    }, RESTAURANT_CARD_UPDATE_INTERVAL)
    return () => clearInterval(interval)
  }, [setNow])

  const isAvailable = useMemo(() => restaurant?.isAvailable, [restaurant?.isAvailable])

  const isOpen = useMemo(() => {
    return (
      isAvailable &&
      calendarRanges?.some(range => {
        return !range.closed && now.isBetween(range.start, range.end, null, '[]')
      })
    )
  }, [calendarRanges, isAvailable, now])

  return (
    <Card id={`restaurant-card-${restaurant.id}`} onClick={selectRestaurant} selected={selected} {...props}>
      <CardHeader>
        <LabelSmall>{restaurant?.name}</LabelSmall>
        {restaurant?.distance > 0 && <DescriptionExtraSmall>{restaurant?.distance} miles</DescriptionExtraSmall>}
      </CardHeader>
      <CardBody>
        <DescriptionExtraSmall aria-label={`Restaurant address is ${restaurant?.streetAddress}, ${restaurant?.city}, ${restaurant?.state}`}>
          {restaurant?.streetAddress}, {restaurant?.city}, {restaurant?.state}
        </DescriptionExtraSmall>
        <WorkingHours workingHours={workingHours} />
      </CardBody>
      <CardFooter>
        <Group>
          <DescriptionExtraSmall aria-label={`Restaurant phone is ${restaurant?.telephone}`}>{restaurant?.telephone}</DescriptionExtraSmall>
        </Group>
        <PillsContainer>
          {isAvailable && !isOpen && (
            <PillText aria-label={text('RestaurantCard.Labels.Closed.Aria')}>{text('RestaurantCard.Labels.Closed')}</PillText>
          )}
          {!isAvailable && (
            <PillText aria-label={text('RestaurantCard.Labels.Unavailable.Aria')}>{text('RestaurantCard.Labels.Unavailable')}</PillText>
          )}
        </PillsContainer>
        {isAvailable && isOpen && (
          <DescriptionExtraSmall aria-label={`Restaurant is Open until: ${formatTime(todayEnd)}`}>
            Open until: {formatTime(todayEnd)}
          </DescriptionExtraSmall>
        )}
        <StartOrderContainer as="div">
          <Button disabled={!isAvailable} isSmall label="Start order" onClick={isOpen ? startOrder : scheduleTime} variant="primary" />
        </StartOrderContainer>
      </CardFooter>
    </Card>
  )
}

export const RestaurantCardList = ({ restaurants, scheduleTime, startOrder }) => {
  const { activeHandoffMode, selectedRestaurant, selectRestaurant, showAll } = useHomeContext()
  const historyInfo = useUserHistory()
  const { text } = useText()

  const summaryText = useMemo(
    () =>
      showAll
        ? text('Home.Restaurants.Locations.ShowAll.Text', { count: restaurants?.length })
        : text('Home.Restaurants.Locations.ShowNearest.Text', { count: restaurants?.length }),
    [showAll, restaurants?.length],
  )

  function handleSelectRestaurant(restaurant) {
    firebase.analytics().logEvent('Pickup_Location_Selected', {
      locationSelected: restaurant.name,
    })

    selectRestaurant(restaurant)
  }

  function handleStartOrder(restaurant) {
    firebase.analytics().logEvent('Pickup_Location_Saved', {
      locationSelected: restaurant.name,
    })

    startOrder(restaurant)
  }

  function handleScheduleTime(restaurant) {
    firebase.analytics().logEvent('Pickup_Location_Saved', {
      locationSelected: restaurant.name,
    })

    scheduleTime(restaurant)
  }

  const lastRestaurant = useMemo(() => (historyInfo?.lastRestaurant ? restaurants?.find(r => r.id === historyInfo.lastRestaurant.id) : null), [
    restaurants,
    historyInfo?.lastRestaurant,
  ])

  return (
    <Container as="div">
      {restaurants?.length > 0 && <SummaryText>{summaryText}</SummaryText>}

      <CardList>
        {restaurants?.map(restaurant => (
          <RestaurantCard
            key={restaurant?.id}
            handoffMode={activeHandoffMode}
            restaurant={restaurant}
            selected={selectedRestaurant?.id === restaurant?.id}
            selectRestaurant={() => handleSelectRestaurant(restaurant)}
            scheduleTime={() => handleScheduleTime(restaurant)}
            startOrder={() => handleStartOrder(restaurant)}
          />
        ))}
      </CardList>

      {activeHandoffMode === HANDOFF_MODES.PICKUP && (
        <>
          {lastRestaurant && (
            <HistoryEntryContainer>
              <SectionTitle>{text('Home.Section.RecentRestaurant.Title')}</SectionTitle>
              <RestaurantCard
                style={{ listStyle: 'none' }}
                handoffMode={activeHandoffMode}
                restaurant={lastRestaurant}
                selected={selectedRestaurant?.id === lastRestaurant?.id}
                selectRestaurant={() => selectRestaurant(lastRestaurant)}
                startOrder={() => startOrder(lastRestaurant)}
              />
            </HistoryEntryContainer>
          )}

          {historyInfo?.lastOrder && (
            <HistoryEntryContainer>
              <SectionTitle>{text('Home.Section.RecentOrder.Title')}</SectionTitle>
              <OrderHistoryEntryCard restaurants={historyInfo.restaurants} {...historyInfo.lastOrder} />
            </HistoryEntryContainer>
          )}
        </>
      )}
    </Container>
  )
}
