import { gql } from '@apollo/client'

import { ORDER_FIELDS, ORDER_STATUS_FIELDS } from '../../data/types'

// QUERIES

export const GET_ORDER = gql`
    query GetOrder($orderId: String!, $authenticationToken: String) {
        order: orderStatus(orderId: $orderId, authenticationToken: $authenticationToken) {
            ${ORDER_STATUS_FIELDS}
        }
    }
`

export const ACCESS_TOKEN = gql`
  query($basketId: String!, $authToken: String) {
    accessToken(basketId: $basketId, authToken: $authToken) {
      accesstoken
    }
  }
`

// MUTATIONS

export const CANCEL_ORDER = gql`
    mutation($orderId: String!) {
        cancelOrder(orderId: $orderId) {
            ${ORDER_FIELDS}
        }
    }
`

export const EDIT_ORDER = gql`
    mutation($orderId: String!) {
        editOrder(orderId: $orderId) {
            ${ORDER_FIELDS}
        }
    }
`
