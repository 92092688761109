import { MdAdd, MdRemove } from 'react-icons/md'
import styled from 'styled-components'

export const Label = styled.p`
  ${({ theme }) => theme.font.styles.labelInput}
`

export const Controls = styled.div`
  display: flex;
`

export const IconContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: transparent;
  border-radius: 50px;
  border: ${props => props.theme.borders.lighter};
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.04);
  height: 24px;
  width: 24px;
`

export const SubtractIcon = styled(MdRemove).attrs(props => ({
  color: props.theme.colors.brand.black,
  size: props.theme.icons.size.sm,
}))`
  opacity: ${props => (props.disabled ? 0.3 : 1)};
`

export const AddIcon = styled(MdAdd).attrs(props => ({
  color: props.theme.colors.brand.black,
  size: props.theme.icons.size.sm,
}))`
  opacity: ${props => (props.disabled ? 0.3 : 1)};
`

export const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 24px;
`

export const CurrentValue = styled.p`
  ${({ theme }) => theme.font.styles.labelInput}
`
