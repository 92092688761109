import { useApolloClient } from '@apollo/client'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDialogState } from 'reakit'
import { CircleLoadingIndicator } from '../../../../components//CircleLoadingIndicator/CircleLoadingIndicator'
import { HANDOFF_MODES } from '../../../../constants/handoffModes'
import { useAddresses } from '../../../../contexts/addresses'
import { useConfig } from '../../../../contexts/appConfig'
import { useSession } from '../../../../contexts/session'
import { GET_NEAREST_RESTAURANT_FOR_DELIVERY } from '../../../../data/restaurants/queries'
import { contains } from '../../../../data/utils'
import { useFeatureFlags } from '../../../../hooks/useFeatureFlags'
import { useMessaging } from '../../../../hooks/useMessaging'
import firebase from '../../../../services/firebase'
import { useHomeContext } from '../../Home.context'
import { SEARCH_ADDRESS_EMPTY_STATES, SEARCH_ADDRESS_LOADING_STATES, SEARCH_ADDRESS_STATE } from '../../Home.state'
import { DeliveryAddressDetailsModal } from '../DeliveryAddressDetailsModal'
import { Container, LoadingContainer } from './DeliveryAddressResults.styles'
import { RestaurantCardList } from '../RestaurantCardList'
import { DeliveryTimeModal } from '../SelectTimeModal/DeliveryTimeModal/DeliveryTimeModal'
import { ALLOWED_TIME_WANTED_MODES } from '../../../../constants/timeWantedModes'
import { DeliveryAddresResultsEmptyState } from './components/DeliveryAddresResultsEmptyState'
import { DeliveryAddressResultsFound } from './components/DeliveryAddressResultsFound'
import { DeliveryAddressResultsNotFound } from './components/DeliveryAddressResultsNotFound'

const filterAllowedDeliveryRestaurants = restaurant => ALLOWED_TIME_WANTED_MODES.indexOf(restaurant?.deliverySupport?.timeWantedMode > -1) || false

export const DeliveryAddressResults = () => {
  const { dispatch, state: sessionState } = useSession()
  const { deliveryRestaurants, searchNewAddress, switchToPickup, selectedAddress, addressSearchState } = useHomeContext()
  const deliveryAddressDetailsDialogProps = useDialogState({ animated: true })
  const deliveryTimeDialogProps = useDialogState({ animated: true })
  const history = useHistory()
  const { resolveNavigationPayload } = useConfig()
  const apolloClient = useApolloClient()
  const [targetRestaurant, setTargetRestaurant] = useState(null)
  const [selectedTime, setSelectedTime] = useState(sessionState.pickupTime)
  const { dispatchMessage } = useMessaging()
  const { addresses: firebaseSavedAddresses, loading: firebaseSavedAddressesLoading } = useAddresses()
  const { AllowDeliveryRestaurantSelection, PickTimeAtCheckout } = useFeatureFlags()

  const handleAddDeliveryDetails = () => {
    firebase.analytics().logEvent('Add_A_New_Address_Selected')
    deliveryAddressDetailsDialogProps.show()
  }

  const startOrderFromSavedAddress = useCallback(
    async savedAddress => {
      try {
        const restaurant = await apolloClient
          .query({ query: GET_NEAREST_RESTAURANT_FOR_DELIVERY, variables: { street: savedAddress.streetAddress, city: savedAddress.city } })
          .then(({ data }) => data?.restaurantsFindForDelivery?.restaurants?.[0])

        if (!restaurant) {
          throw new Error('No restaurants for given address are available')
        }

        dispatch({ type: 'RESET' })
        dispatch({ type: 'SET_ACTIVE_ADDRESS', payload: savedAddress })
        dispatch({
          type: 'SET_CONTACT_INFO',
          payload: {
            ...sessionState?.contactInfo,
            contactNumber: savedAddress.phoneNumber,
          },
        })
        dispatch({ type: 'SET_RESTAURANT', payload: restaurant })
        dispatch({ type: 'SET_HANDOFF_MODE', payload: HANDOFF_MODES.DELIVERY })
        dispatch({ type: 'SET_PICKUP_TIME', payload: 'ASAP' })

        const screen = resolveNavigationPayload('StartOrder')
        history.push(screen)
      } catch (error) {
        dispatchMessage.error(`An error occurred while setting your address\n${error}`)
      }
    },
    [history, dispatch, apolloClient, dispatchMessage],
  )

  const scheduleTime = restaurant => {
    if (PickTimeAtCheckout) {
      startOrder(restaurant, null)
    } else {
      console.debug('[DeliveryAddressResults] scheduling delivery order for:', restaurant)
      setTargetRestaurant(restaurant)
      deliveryTimeDialogProps.show()
    }
  }

  const startOrder = (restaurant, selectedTime = 'ASAP') => {
    console.debug('[DeliveryAddressResults] starting delivery order for:', { restaurant, selectedTime })
    setTargetRestaurant(restaurant)
    setSelectedTime(selectedTime)
    setSelectedTime(selectedTime)
    deliveryAddressDetailsDialogProps.show()
  }

  const onSelectScheduleTime = (_, selectedTime) => {
    console.debug('[DeliveryAddressResults] onSelectScheduleTime:', selectedTime)
    deliveryTimeDialogProps.hide()
    firebase.analytics().logEvent('Add_A_New_Address_Selected')
    setSelectedTime(selectedTime)
    deliveryAddressDetailsDialogProps.show()
  }

  const restaurants = useMemo(() => {
    return deliveryRestaurants?.searchResults?.filter(filterAllowedDeliveryRestaurants) || []
  }, [deliveryRestaurants?.searchResults])

  const allowDeliveryRestaurantsSelection = useMemo(() => AllowDeliveryRestaurantSelection && restaurants.length > 1, [
    AllowDeliveryRestaurantSelection,
    restaurants?.length,
  ])

  useEffect(() => {
    setTargetRestaurant(!AllowDeliveryRestaurantSelection || restaurants.length === 1 ? restaurants[0] : null)
  }, [AllowDeliveryRestaurantSelection, restaurants?.length])

  useEffect(() => {
    console.debug('[DeliveryAddressResults]', {
      addressSearchState,
      addressesLoading: firebaseSavedAddressesLoading,
      deliveryRestaurantsLoading: deliveryRestaurants?.loading,
      hasSelectedAddress: !!selectedAddress,
    })
  }, [addressSearchState, firebaseSavedAddressesLoading, deliveryRestaurants, selectedAddress])

  const renderEmptyState = useMemo(() => contains(addressSearchState, SEARCH_ADDRESS_EMPTY_STATES), [addressSearchState])
  const renderLoading = useMemo(() => contains(addressSearchState, SEARCH_ADDRESS_LOADING_STATES), [addressSearchState])

  if (renderEmptyState) {
    return (
      <Container>
        <LoadingContainer visible={renderLoading}>
          <CircleLoadingIndicator />
        </LoadingContainer>
        <DeliveryAddresResultsEmptyState firebaseSavedAddresses={firebaseSavedAddresses} onStartOrder={startOrderFromSavedAddress} />
      </Container>
    )
  }

  return (
    <Container>
      {addressSearchState === SEARCH_ADDRESS_STATE.FOUND && (
        <>
          {allowDeliveryRestaurantsSelection ? (
            <RestaurantCardList restaurants={deliveryRestaurants?.searchResults} scheduleTime={scheduleTime} startOrder={startOrder} />
          ) : (
            <DeliveryAddressResultsFound
              switchToPickup={switchToPickup}
              deliverySupport={targetRestaurant?.deliverySupport}
              addDeliveryDetails={handleAddDeliveryDetails}
              scheduleDeliveryAction={deliveryTimeDialogProps.show}
            />
          )}
        </>
      )}
      {addressSearchState === SEARCH_ADDRESS_STATE.NOT_FOUND && (
        <DeliveryAddressResultsNotFound searchNewAddress={searchNewAddress} switchToPickup={switchToPickup} />
      )}
      <DeliveryAddressDetailsModal
        address={deliveryRestaurants?.validatedAddress}
        dialogProps={deliveryAddressDetailsDialogProps}
        pickupTime={selectedTime}
        restaurant={targetRestaurant}
      />
      <DeliveryTimeModal dialogProps={deliveryTimeDialogProps} restaurant={targetRestaurant} onSelectScheduleTime={onSelectScheduleTime} />
    </Container>
  )
}
