import AMX from './card-AMX.svg'
import CUP from './card-CUP.svg'
import DIN from './card-DIN.svg'
import DIS from './card-DIS.svg'
import DNK from './card-DNK.svg'
import ECA from './card-ECA.svg'
import ELO from './card-ELO.svg'
import Empty from './card-empty.svg'
import Error from './card-error.svg'
import JCB from './card-JCB.svg'
import MAU from './card-MAU.svg'
import Recognized from './card-recognized.svg'
import UAP from './card-UAP.svg'
import VIS from './card-VIS.svg'
import VPY from './card-VPY.svg'
import CvcEmpty from './cvc-empty.svg'
import CvcError from './cvc-error.svg'

const CARD_ICONS = {
  AMX,
  CUP,
  DIN,
  DIS,
  DNK,
  ECA,
  ELO,
  JCB,
  MAU,
  UAP,
  VIS,
  VPY,
  Empty,
  Error,
  Recognized,
}

const CVV_ICONS = {
  Empty: CvcEmpty,
  Error: CvcError,
}

export const getCardIcon = cardType => {
  return CARD_ICONS[cardType]
}

export const getCvvIcon = type => {
  return CVV_ICONS[type]
}
