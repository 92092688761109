import React from 'react'
import { HiCheckCircle } from 'react-icons/hi'
import styled from 'styled-components'

import { useNutritionalPreferences } from '../../hooks/useDietaryInformationFromItem'

export const NutritionalChips = ({ item, style }) => {
  const { sessionIntersection } = useNutritionalPreferences(item)

  if (sessionIntersection.length <= 0) {
    return null
  }

  return (
    <ChipsContainer style={style}>
      {sessionIntersection.map(({ name, oloCode }) => (
        <ChipContainer key={oloCode}>
          <CheckIcon />
          {name}
        </ChipContainer>
      ))}
    </ChipsContainer>
  )
}

const ChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const CheckIcon = styled(HiCheckCircle).attrs(() => ({ size: 12 }))`
  margin-right: 4px;
`

const ChipContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;
  margin-bottom: 12px;
  padding: 6px 16px;

  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.primary};

  font-size: 12px;
  font-weight: 600;
  border-radius: 100px;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.font.styles.regular};
`
