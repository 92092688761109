import React from 'react'

import { DoubleCTA } from '../../../../../components/DoubleCTA/DoubleCTA'
import { InputExtraSmall, LabelSmall } from '../../../../../components/Text/Text.styles'
import { PREFERENCES } from '../../../../../constants/preferences'
import { TIME_WANTED_MODES } from '../../../../../constants/timeWantedModes'
import { useText } from '../../../../../content'
import { useConfig } from '../../../../../contexts/appConfig'
import { Container, Content } from '../DeliveryAddressResults.styles'

export const DeliveryAddressResultsFound = React.memo(({ addDeliveryDetails, scheduleDeliveryAction, switchToPickup, deliverySupport }) => {
  const config = useConfig()
  const { brandName } = config.getPreference(PREFERENCES.GENERAL) || {}
  const { text } = useText()
  const { timeWantedMode } = deliverySupport

  if (timeWantedMode === TIME_WANTED_MODES.ADVANCE) {
    return (
      <Container>
        <Content>
          <LabelSmall style={{ marginBottom: '32px' }}>{text('Home.Delivery.Results.Found.Label')}</LabelSmall>
          <InputExtraSmall>{text('Home.Delivery.Results.Found.Description', { brandName })}</InputExtraSmall>
        </Content>

        <DoubleCTA
          stacked
          buttons={[
            {
              label: 'Schedule delivery',
              onClick: scheduleDeliveryAction,
              variant: 'primary',
            },
            {
              label: 'Switch to pickup',
              onClick: switchToPickup,
            },
          ]}
        />
      </Container>
    )
  }

  return (
    <Container>
      <Content>
        <LabelSmall style={{ marginBottom: '32px' }}>{text('Home.Delivery.Results.Found.Label')}</LabelSmall>
        <InputExtraSmall>{text('Home.Delivery.Results.Found.Description', { brandName })}</InputExtraSmall>
      </Content>

      <DoubleCTA
        stacked
        buttons={[
          {
            label: 'Add delivery details',
            onClick: addDeliveryDetails,
            variant: 'primary',
          },
          {
            label: 'Switch to pickup',
            onClick: switchToPickup,
          },
        ]}
      />
    </Container>
  )
})
