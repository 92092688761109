import React from 'react'
import { FaCheckCircle } from 'react-icons/fa'
import { Checkbox } from 'reakit/Checkbox'

import { Button } from './ToggleButton.styles'

export const ToggleButton = ({ name, children, checked = false, onClick, ...props }) => {
  return (
    <Checkbox as={Button} checked={checked} onChange={onClick} focusable {...props}>
      {children}
      <FaCheckCircle size={24} style={{ marginLeft: 'auto' }} />
    </Checkbox>
  )
}
