import React, { useRef, useState } from 'react'

import { Button } from '../../../../components/Button/Button'
import { ModalHeader } from '../../../../components/ModalHeader/ModalHeader'
import { ModalWithBackdrop } from '../../../../components/ModalWithBackdrop/ModalWithBackdrop'
import { useText } from '../../../../content'
import { useSession } from '../../../../contexts/session'
import { useMessaging } from '../../../../hooks/useMessaging'
import firebase from '../../../../services/firebase'
import { ModalFixedBottomAction, ModalFormContainer } from '../../Checkout.styles'
import SecureFields from '../../SecureFields/SecureFields'

export const AddPaymentModal = ({ billingAccounts, ...addPaymentModalProps }) => {
  const { state, dispatch } = useSession()
  const { dispatchMessage } = useMessaging()
  const { text } = useText()

  const secureFieldsRef = useRef()
  const [shouldSavePayment, setShouldSavePayment] = useState(true)

  function handleCardConfirmed(tokenInfo) {
    try {
      const card = {
        transactionId: tokenInfo.transactionId,
        cardType: tokenInfo.cardInfo.brand,
        expiration: tokenInfo.expiration,
        billingZipcode: tokenInfo.billingZipcode,
        shouldSavePayment,
      }

      // const billingExists = billingAccounts.find((method) => method.cardSuffix === card.cardSuffix);
      // const savedPaymentExists = savedPaymentMethods.find((method) => method.cardSuffix === card.cardSuffix);
      // if (billingExists || savedPaymentExists) {
      //   dispatchMessage({
      //     type: 'ADD_MESSAGE',
      //     payload: { message: text('Checkout.Errors.AddDuplicatedCard') },
      //   });

      //   return;
      // }

      // const newPayment = {
      //   ...card,
      //   shouldSavePayment,
      // };

      dispatch({ type: 'ADD_NEW_PAYMENT_METHOD', payload: card })
      dispatch({ type: 'SET_ACTIVE_PAYMENT_METHOD', payload: card })

      firebase.analytics().logEvent('Add_Payment_Successful')

      addPaymentModalProps.hide()
    } catch (error) {
      console.log(error)
      dispatchMessage.error(text('Checkout.Errors.AddPayment'))
    }
  }

  return (
    <ModalWithBackdrop dialogProps={addPaymentModalProps} ariaLabel="Add Payment" containerStyle={{ overflow: 'hidden', position: 'relative' }}>
      <ModalHeader title="Add Payment" onClose={addPaymentModalProps.hide} />

      <ModalFormContainer>
        <SecureFields
          buttonText="Pay Now"
          ref={secureFieldsRef}
          onCardConfirmed={handleCardConfirmed}
          // disabled={loading}
          production={process.env.REACT_APP_PCI_PROXY_MODE === 'production'}
          config={{
            merchantID: process.env.REACT_APP_PCI_PROXY_MERCHANT_ID,
            fields: {
              cardNumber: {
                placeholderElementId: 'card-number',
                inputType: 'tel',
              },
              cvv: {
                placeholderElementId: 'cvv-number',
                inputType: 'tel',
              },
            },
            options: {},
          }}
        />
      </ModalFormContainer>

      <ModalFixedBottomAction>
        <Button variant="primary" label="Save" onClick={() => secureFieldsRef.current.submit()} />
      </ModalFixedBottomAction>
    </ModalWithBackdrop>
  )
}
