import { Box } from 'reakit/Box'
import styled, { css } from 'styled-components'

export const Container = styled(Box)`
  display: flex;
  ${props =>
    props.$stacked
      ? css`
          flex-direction: column;
          > * {
            &:not(:first-child) {
              margin-top: 6px;
            }
          }
        `
      : css`
          flex-direction: row;
          > * {
            &:not(:first-child) {
              margin-left: 9px;
            }
          }
        `};
`
