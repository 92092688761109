import styled, { css } from 'styled-components'

export const Container = styled.div`
  background: ${props => props.theme.colors.brand.white};
  border-radius: ${props => props.theme.borderRadii.sm};
  border: ${props => props.theme.borders.light};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 16px;
  width: 100%;

  position: relative;

  cursor: pointer;
  &:hover {
    border: ${props => props.theme.toggles.styles.active.border};
  }

  ${props =>
    props.isRequired &&
    css`
      background-color: ${({ theme }) => theme.colors.backgroundRequiredInput};
    `}

  ${({ theme }) => theme.menuItems.controlContainerStyle}
`

export const ContainerSimple = styled.div`
  border: 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
