import { find, without } from 'lodash'
import React, { useEffect, useMemo } from 'react'

import { WarningIcon } from '../../../../components/Icons/Icons'
import { useText } from '../../../../content'
import { useConfig } from '../../../../contexts/appConfig'
import { useSession } from '../../../../contexts/session'
import { findMetadata, formatCurrency } from '../../../../data/utils'
import { useDietaryInformationFromItem } from '../../../../hooks/useDietaryInformationFromItem'
import { useMessaging } from '../../../../hooks/useMessaging'
import {
  AddIcon,
  CheckIcon,
  Container,
  ContentContainer,
  ControlContainer,
  DescriptionText,
  DietaryContainer,
  DietaryRestrictionContainer,
  DietaryText,
  IconButton,
  Image,
  NameText,
  Price,
  QuantityControls,
  QuantityLabel,
  SubtractIcon,
  ToggleContainer,
} from './ModifierOptionCard.styles'

export const ModifierOptionCard = ({ modifier, option }) => {
  const { getMenuImage } = useConfig()
  const { dispatchMessage } = useMessaging()
  const { text } = useText()
  const {
    dispatch,
    state: { activeModifiers },
  } = useSession()

  const { sessionIntersection, itemRestrictions } = useDietaryInformationFromItem(option)

  const variant = modifier.supportsChoiceQuantities ? 'quantity' : 'single'

  const quantity = useMemo(() => {
    const thisOption = find(activeModifiers, m => m.option.id === option.id)
    if (thisOption) {
      return thisOption.quantity || 0
    }
    return 0
  }, [activeModifiers, option])

  const imageUrl = useMemo(() => {
    const id = findMetadata(option.metadata, 'APP_MENU_IMAGE_ID')
    const url = getMenuImage(id)
    console.debug('ModifierOption.imageUrl', { id, url })
    return url
  }, [getMenuImage, option.metadata])

  const isEnabled = useMemo(() => isModifierEnabled(modifier, activeModifiers), [modifier, activeModifiers])

  const handleChangeQuantity = newQuantity => {
    if (!isEnabled && newQuantity > quantity) {
      console.debug(modifier)
      dispatchMessage.error(text('MenuItemBuilder.Errors.Modifier.Disabled.Message'))
    } else {
      console.debug('toggling modifier option', modifier.description, option.name, `quantity=${newQuantity}`)
      if (newQuantity > 0) {
        let thisOption = find(activeModifiers, m => m.option.id === option.id)
        if (!thisOption) {
          thisOption = {
            modifier: modifier,
            option,
            quantity: 0,
          }
        }
        thisOption.quantity = newQuantity
        const selectedOptions = [...without(activeModifiers, thisOption), thisOption]
        dispatch({ type: 'SET_ACTIVE_MODIFIERS', payload: selectedOptions })
      } else {
        const selectedOptions = activeModifiers.filter(m => m.option.id !== option.id)
        dispatch({ type: 'SET_ACTIVE_MODIFIERS', payload: selectedOptions })
      }
    }
  }

  const cost = quantity * option.cost

  const name = findMetadata(option.metadata, 'APP_SHORT_NAME') || option.name
  const description = findMetadata(option.metadata, 'APP_DESCRIPTION')

  useEffect(() => {
    console.debug('[ModifierOptionCard]', {
      variant,
      imageUrl,
      isEnabled,
      cost,
      name,
      description,
    })
  }, [variant, imageUrl, isEnabled, cost, name, description])

  return (
    <Container active={quantity > 0}>
      <Image src={imageUrl} />
      <ContentContainer>
        <NameText>{name}</NameText>
        <DescriptionText>{description}</DescriptionText>
        {sessionIntersection?.length > 0 && (
          <DietaryContainer>
            {sessionIntersection.map(restriction => (
              <DietaryRestrictionContainer key={restriction.violationExplanation}>
                <WarningIcon style={{ marginRight: 6 }} />
                <DietaryText>{restriction.violationExplanation}</DietaryText>
              </DietaryRestrictionContainer>
            ))}
          </DietaryContainer>
        )}
        <ControlContainer>
          <Price>{cost > 0 && `+${formatCurrency(cost)}`}</Price>
          {variant === 'quantity' && (
            <QuantityControls>
              {quantity > 0 && (
                <>
                  <IconButton onClick={() => handleChangeQuantity(quantity - 1)}>
                    <SubtractIcon />
                  </IconButton>
                  <QuantityLabel>{quantity}</QuantityLabel>
                </>
              )}
              <IconButton onClick={() => handleChangeQuantity(quantity + 1)} disabled={!isEnabled}>
                <AddIcon />
              </IconButton>
            </QuantityControls>
          )}
          {variant === 'single' && (
            <ToggleContainer onClick={() => handleChangeQuantity(quantity === 0 ? 1 : 0)} active={quantity > 0}>
              {quantity > 0 && <CheckIcon />}
            </ToggleContainer>
          )}
        </ControlContainer>
      </ContentContainer>
    </Container>
  )
}

const isModifierEnabled = (modifier, activeOptions) => {
  let enabled = true
  const modifierName = findMetadata(modifier?.metadata, 'APP_SHORT_NAME') || modifier?.description || 'Unknown Modifier'
  if (modifier && activeOptions.length > 0) {
    // determine if active modifier is valid
    const selections = activeOptions.filter(m => m.modifier?.id === modifier?.id)
    const totalQuantity = selections.reduce((counter, curr) => counter + curr.quantity, 0)
    console.debug(`[ModifierOptionCard] ${modifierName}: (selected options count=${selections.length}) total qty=${totalQuantity}`)

    // check rules
    if (modifier.maxAggregateQuantity > 0 && totalQuantity >= modifier.maxAggregateQuantity) {
      console.debug(`[ModifierOptionCard] ${modifierName}: Reached maxAggregateQuantity so disabling quantity addition`)
      enabled = false
    }

    if (modifier.maxSelects > 0 && selections.length >= modifier.maxSelects) {
      console.debug(`[ModifierOptionCard] ${modifierName}: Reached maxSelects so disabling quantity addition`)
      enabled = false
    }

    console.debug(`[ModifierOptionCard] ${modifierName}: After update, enabled=`, enabled)
  }

  return enabled
}
