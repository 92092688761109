import styled, { css } from 'styled-components'

import { isMobile } from '../../../components/responsive/Responsive'

export const InputContainer = styled.div`
  display: flex;
  align-items: center;

  background-color: ${props => props.theme.colors.brand.gray10};
  border-radius: 3px;
  font-size: 24px;
  height: 59px;
  overflow: hidden;
  padding: 16px 0 16px 16px;
  max-width: 110px;

  ${props =>
    props.isFocused &&
    css`
      background-color: ${({ theme }) => theme.colors.primary};
    `}

  ${props =>
    props.isFocused ||
    (props.isFilled &&
      css`
        background-color: ${({ theme }) => theme.colors.primary};
      `)};

  span {
    color: ${props => props.theme.colors.black};
    font-family: ${({ theme }) => theme.font.styles.numbers};

    ${isMobile`
      font-size: 16px;
  `}
  }
`

export const TextInput = styled.input`
  background-color: transparent;
  border: none;
  color: ${props => props.theme.colors.black};
  font-family: ${({ theme }) => theme.font.styles.numbers};
  font-size: 24px;
  outline: 0;

  ::placeholder {
    color: ${props => props.theme.colors.black};
  }

  ${isMobile`
      font-size: 16px;
  `}
`

// export const Text = styled.Text`
//   color: ${props => props.theme.colors.black};
//   font-family: ${({ theme }) => theme.font.styles.numbers};
//   font-size: 16px;
//   margin-right: 1px;
//   padding-top: 4px; /* needed to center align the DINCondensed font*/
// `
