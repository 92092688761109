import { gql, useMutation, useQuery } from '@apollo/client'
import { filter, find, flatten, uniq } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDialogState } from 'reakit'
import { useTheme } from 'styled-components'

import { BackButton, Button, CloseButton } from '../../components/Button/Button'
import { CircleLoadingIndicator } from '../../components/CircleLoadingIndicator/CircleLoadingIndicator'
import { DoubleCTA } from '../../components/DoubleCTA/DoubleCTA'
import { AddFavorieItemModal } from '../../components/FavoriteItem/AddFavoriteItemModal/AddFavorieItemModal'
import { RemoveFavorieItemModal } from '../../components/FavoriteItem/RemoveFavoriteItemModal/RemoveFavoriteItemModal'
import { ToggleFavoriteItemButton } from '../../components/FavoriteItem/ToggleFavoriteItemButton/ToggleFavoriteItemButton'
import { IngredientsContainer as ItemIngredients } from '../../components/IngredientsContainer/IngredientsContainer'
import { ModalWithBackdrop } from '../../components/ModalWithBackdrop/ModalWithBackdrop'
import { ModifierAccordion } from '../../components/ModifierAccordion/ModifierAccordion'
import { ModifierOptionSelector } from '../../components/ModifierOptionSelector/ModifierOptionSelector'
import { ModifierSelector } from '../../components/ModifierSelector/ModifierSelector'
import { NutritionalChips } from '../../components/NutritionalChips/NutritionalChips'
import { QuantityInput } from '../../components/QuantityInput/QuantityInput'
import { TextSeparator } from '../../components/TextSeparator/TextSeparator'
import { useText } from '../../content'
import { useConfig } from '../../contexts/appConfig'
import { useFavoritesForItem } from '../../contexts/favorites'
import { useSession } from '../../contexts/session'
import { apolloClient } from '../../data/apollo'
import { ADD_PRODUCT_TO_BASKET, UPDATE_BASKET_PRODUCT } from '../../data/basket/queries'
import { GET_MODIFIERS } from '../../data/menu/queries'
import { MENU_ITEM_FIELDS } from '../../data/types'
import { findImage, findMetadata, formatCurrency } from '../../data/utils'
import { useAnalytics } from '../../hooks/useAnalytics'
import { useDietaryInformationFromItem } from '../../hooks/useDietaryInformationFromItem'
import { useMessaging } from '../../hooks/useMessaging'
import { useModifierValidation } from '../../hooks/useModifierValidation'
import { useSpotlight } from '../../hooks/useSpotlight'
import { useWindowSize } from '../../hooks/useWindowsSize'
import { Calories as ItemCalories } from './components/Calories/Calories'
import {
  Body,
  ComboButton,
  ComboContainer,
  Container,
  DietaryInfoContainer,
  Footer,
  Header,
  ImageContainer,
  Item,
  ItemMeta,
  ItemName,
  ItemPrice,
  LoadingView,
  SelectorContainer,
  SpotlightButton,
  SpotlightCloseButton,
  SpotlightContainer,
  SpotlightDescription,
  SpotlightHeading,
  SpotlightIcon,
  SpotlightMeta,
  SpotlightTitle,
  Title,
  TotalText,
  WarningIcon,
} from './SimpleBuilder.styles'

const INITIAL = 'INITIAL'
const MODIFY = 'MODIFY'
const MODIFY_ALL = 'MODIFY_ALL'

const STEP = Object.freeze({
  [INITIAL]: INITIAL,
  [MODIFY]: MODIFY,
  [MODIFY_ALL]: MODIFY_ALL,
})

const floatingButtonProps = {
  style: {
    margin: '20px 0 0 20px',
    position: 'absolute',
    zIndex: 2,
  },
  size: 32,
}

export const SimpleBuilderPage = ({ onClose }) => {
  const location = useLocation()
  const { item, isEditing, basketProduct } = location?.state

  const [step, setStep] = useState(STEP.INITIAL)
  const [modifier, setModifier] = useState(null)
  const { track } = useAnalytics()

  const {
    state: { restaurant, basket, activeModifiers = [], user },
    dispatch,
  } = useSession()
  const { text } = useText()
  const { dispatchMessage } = useMessaging()
  const { selectModifiersFromBasketProduct, validateModifier } = useModifierValidation()
  const [nextRequiredModifier, setNextRequiredModifier] = useState(null)
  const [valid, setValid] = useState(false)
  const [quantity, setQuantity] = useState(() => (isEditing && basketProduct ? basketProduct.quantity : 1))
  const [_, windowHeight] = useWindowSize()
  const { getPreference } = useConfig()
  const theme = useTheme()

  const OrderingConfiguration = useMemo(() => getPreference?.('OrderingConfiguration'), [getPreference])
  const showQuantity = useMemo(() => OrderingConfiguration?.SimpleBuilder?.ShowQuantity || false, [OrderingConfiguration])

  const menuItem = useMemo(() => {
    // if the user is coming from the basket (is editing), get the product info from the cache
    // TODO: this fails if the item is not already in the cache
    if (isEditing && basketProduct) {
      const cachedItem = apolloClient.readFragment({
        id: `MenuItem:${basketProduct?.productId}`,
        fragment: gql`
          fragment menuItem on MenuItem {
            ${MENU_ITEM_FIELDS}
          }
        `,
      })

      if (!cachedItem) {
        throw new Error('Editing item from basket but item does not exist in the cache.')
      }

      return cachedItem
    }

    console.debug('[SimpleBuilderPage] loaded with menuItem=', item)
    return item
  }, [basketProduct, isEditing, item])

  const itemPrice = useMemo(() => {
    const basePriceMetadata = findMetadata(menuItem?.metadata, 'APP_BASE_PRICE')
    return basePriceMetadata ? `${formatCurrency(basePriceMetadata)}+` : formatCurrency(menuItem?.cost)
  }, [menuItem])

  const { favorite } = useFavoritesForItem(menuItem)
  const { findSpotlight } = useSpotlight()
  const spotlight = findSpotlight(menuItem)
  const spotlightDialogProps = useDialogState({ animated: true })
  const addFavoriteDialogProps = useDialogState({ animated: true, visible: false })
  const removeFavoriteDialogProps = useDialogState({ animated: true, visible: false })

  const { sessionIntersection } = useDietaryInformationFromItem(menuItem)

  const { data: menuItemModifierData, loading: loadingModifiers } = useQuery(GET_MODIFIERS, {
    fetchPolicy: 'no-cache',
    variables: { menuItemId: menuItem?.id },
    onError: err =>
      dispatchMessage({
        type: 'error',
        payload: { message: text('MenuItem.Errors.LoadingModifiers') },
        err,
      }),
  })

  const [addToBasketMutation, { loading: loadingAddToBasket }] = useMutation(ADD_PRODUCT_TO_BASKET, {
    onError: err =>
      dispatchMessage({
        type: 'error',
        payload: { message: text('MenuItem.Errors.AddToBasket') },
        err,
      }),
  })

  const [updateBasketProductMutation, { loading: loadingUpdateProduct }] = useMutation(UPDATE_BASKET_PRODUCT, {
    onError: err =>
      dispatchMessage({
        type: 'error',
        payload: { message: text('MenuItem.Errors.AddToBasket') },
        err,
      }),
  })

  useEffect(() => {
    if (isEditing && basketProduct) {
      if (menuItemModifierData?.modifiers) {
        console.log('Editing an item from the bag...')
        // this should be the very first time the screen comes into focus with an item we're editing
        // match all the modifiers selected in the basket and stick them into session state
        selectModifiersFromBasketProduct(menuItemModifierData.modifiers, basketProduct)
      }
    } else {
      const defaultOptions = (menuItemModifierData?.modifiers || []).reduce((acc, modifier) => {
        const defaultGroupOptions = modifier.options.filter(option => option.isDefault).map(option => ({ modifier, option, quantity: 1 }))

        return [...acc, ...defaultGroupOptions]
      }, [])

      dispatch({ type: 'SET_ACTIVE_MODIFIERS', payload: defaultOptions })
    }
  }, [basketProduct, dispatch, isEditing, menuItemModifierData?.modifiers, selectModifiersFromBasketProduct])

  useEffect(() => {
    // determine if there are required modifiers that need to be selected before the user can add to their bag
    if (!loadingModifiers) {
      console.log('Configuring required modifiers...')
      const modifiers = menuItemModifierData?.modifiers || []
      if (modifiers.length === 0) {
        // no modifiers at all for this item, so just let them pass
        setValid(true)
      } else {
        let isValid = true
        let nextMod
        // loop through all the modifiers and see if we have mandatory modifiers that are not selected.
        for (const mod of modifiers) {
          if (isValid && mod.mandatory) {
            const match = find(activeModifiers, m => m.modifier.id === mod.id)
            if (!match) {
              isValid = false
              nextMod = mod
            }
          }
        }

        setValid(isValid)
        setNextRequiredModifier(nextMod)
      }
    }
    console.debug('[SimpleBuilderPage] nextRequiredModifier=', nextRequiredModifier)
  }, [activeModifiers, loadingModifiers, menuItemModifierData?.modifiers])

  const handleAddToBasket = async () => {
    const selectedOptions = flatten(activeModifiers.map(m => [...Array(m.quantity || 1).keys()].map(() => m.option.id)))

    const variables = {
      basketId: basket?.id,
      productId: menuItem?.id,
      restaurantId: restaurant.id,
      selectedOptions: selectedOptions.map(o => (o || '').toString()),
      quantity,
      authentication: user
        ? {
            loyaltyAccessToken: user?.accessToken,
            loyaltyAuthenticationToken: user?.authenticationToken,
            orderingAuthenticationToken: user?.orderingAuthenticationToken,
          }
        : null,
    }

    try {
      const {
        data: { addToBasket: updatedBasket },
      } = await addToBasketMutation({ variables })

      if (updatedBasket) {
        track('Added_To_Bag', {
          dishName: menuItem.name,
          modifiers: variables.selectedOptions.join(','),
          amount: menuItem.cost,
          quantity: variables.quantity,
        })

        dispatch({ type: 'SET_BASKET', payload: updatedBasket })
        dispatchMessage.info(`${menuItem?.name} added to your bag`)
      }
    } catch (err) {
      console.error(err)
    }

    onClose()
  }

  const handleUpdateBasket = async () => {
    const selectedOptions = flatten(activeModifiers.map(m => [...Array(m.quantity || 1).keys()].map(() => m.option.id)))

    const uniqueSelectedOptions = uniq(selectedOptions)
    const variables = {
      basketId: basket?.id,
      basketProductId: basketProduct.id,
      productId: basketProduct.productId,
      quantity,
      selectedOptions: uniqueSelectedOptions.map(o => (o || '').toString()),
    }

    const {
      data: { updateBasketProduct: updatedBasket },
    } = await updateBasketProductMutation({ variables })

    if (updatedBasket) {
      dispatch({ type: 'SET_BASKET', payload: updatedBasket })
    }

    dispatchMessage.info('Item update in your bag')
    onClose()
  }

  const modifiers = menuItemModifierData?.modifiers || []
  const topLevelModifiers = filter(modifiers, m => findMetadata(m.metadata, 'APP_MODIFY_LEVEL') === 'TOP')
  const comboModifier = find(modifiers, m => findMetadata(m.metadata, 'APP_BUILDER_MODE') === 'COMBO')
  const remainingModifiers = filter(modifiers, m => m !== comboModifier && topLevelModifiers.indexOf(m) < 0)

  useEffect(() => {
    console.debug('[SimpleBuilderPage] allModifiers=', { modifiers, topLevelModifiers, comboModifier, remainingModifiers })
  }, [modifiers, topLevelModifiers, comboModifier, remainingModifiers])

  const comboNestedModifiers = useMemo(() => {
    if (comboModifier) {
      // console.log('Found a combo modifier....', activeModifiers);
      const existingComboModifierSelection = find(activeModifiers, m => m.modifier.id === comboModifier.id)
      if (existingComboModifierSelection) {
        console.log('we have a combo selected, does it have nested options', existingComboModifierSelection.option.modifiers?.length)
        // there is a combo option selected, see if it has nested modifiers (sides, drinks, etc.) and display those
        return existingComboModifierSelection.option.modifiers || []
      }
    }
    return []
  }, [activeModifiers, comboModifier])

  const totalCost = useMemo(() => {
    let total = menuItem?.cost || 0

    activeModifiers.forEach(m => {
      if (m.option.adjustsParentPrice) {
        total += m.quantity * m.option.cost
      }
    })

    total = total * quantity

    return total
  }, [activeModifiers, menuItem?.cost, quantity])

  const handleSelectModifier = useCallback(
    (modifier, option) => {
      // I believe this is only used for selecting combos with melt shop
      // console.log(JSON.stringify(option.modifiers));
      const existingModifier = find(activeModifiers, m => m.modifier.id === modifier.id)
      if (existingModifier) {
        if (existingModifier.option.id === option.id) {
          // do nothing
          console.log('selecting what we already have selected... do nothing....')
        } else {
          console.log('existing option was selected, but it was different, so switching')
          let selectedModifiers = activeModifiers.filter(m => m.modifier.id !== modifier.id)
          const newOption = {
            modifier: modifier,
            option,
            quantity: 1,
          }
          selectedModifiers = [...selectedModifiers, newOption]
          dispatch({ type: 'SET_ACTIVE_MODIFIERS', payload: selectedModifiers })
        }
      } else {
        console.log('no existing selection so adding a new one. this shouldnt happen for combos, except on first load.')
        const newOption = {
          modifier: modifier,
          option,
          quantity: 1,
        }
        const selectedOptions = [...activeModifiers, newOption]
        dispatch({ type: 'SET_ACTIVE_MODIFIERS', payload: selectedOptions })
      }
    },
    [activeModifiers, dispatch],
  )

  useEffect(() => {
    // check if there's a combo modifier present and default the selection to the first option
    // this is only used for Melt Shop right now, but might be useful to other brands later
    if (comboModifier && !isEditing) {
      const existingComboModifierSelection = find(activeModifiers, m => m.modifier.id === comboModifier.id)
      console.log('looking for existing combo modifier selection...', isEditing, existingComboModifierSelection)
      if (!existingComboModifierSelection && comboModifier.options.length > 0) {
        handleSelectModifier(comboModifier, comboModifier.options[0])
      }
    }
  }, [activeModifiers, comboModifier, handleSelectModifier, isEditing])

  const isModifierOptionActive = (modifier, option) => {
    return find(activeModifiers, m => m.modifier.id === modifier.id && m.option.id === option.id)
  }

  const setStepToInitial = () => setStep(STEP.INITIAL)

  const stepToModify = modifier => {
    if (modifier.options.length > 1) {
      setModifier(modifier)
      setStep(STEP.MODIFY)
    }
  }

  const handleIncrementQuantity = () => {
    setQuantity(q => q + 1)
  }

  const handleDecrementQuantity = () => {
    if (quantity > 1) {
      setQuantity(q => q - 1)
    }
  }

  const stepToModifyAll = () => setStep(STEP.MODIFY_ALL)

  const handleNextModifier = () => {
    console.debug('[SimpleBuilderPage] handleNextModifier=', nextRequiredModifier)
    setModifier(nextRequiredModifier)
    setStep(STEP.MODIFY)
  }

  const buttons = useMemo(
    () => ({
      modify: {
        label: text('MenuItem.Modify.ConfirmButton'),
        onClick: setStepToInitial,
        variant: 'primary',
      },
      modifyAll: {
        label: text('MenuItem.ModifyAll.ConfirmButton'),
        onClick: setStepToInitial,
        variant: 'primary',
      },
      modifyNext: {
        label: text('MenuItem.ModifyButton'),
        onClick: stepToModifyAll,
        variant: 'secondary',
      },
      update: {
        label: text('MenuItem.UpdateButton'),
        loading: loadingUpdateProduct,
        onClick: handleUpdateBasket,
        variant: 'primary',
      },
      add: {
        label: text('MenuItem.AddButton'),
        loading: loadingAddToBasket,
        onClick: handleAddToBasket,
        variant: 'primary',
        icon: theme?.icons?.addToBagIcon,
      },
    }),
    [setStepToInitial],
  )

  if (step === STEP.MODIFY) {
    return (
      <Container style={{ height: windowHeight }}>
        <BackButton onClick={setStepToInitial} {...floatingButtonProps} />
        <Header>
          <Title>{modifier?.description}</Title>
        </Header>
        <Body>
          {modifier?.options?.map(option => (
            <ModifierOptionSelector key={option.id} {...{ modifier, option }} />
          ))}
        </Body>
        <Footer>
          <DoubleCTA buttons={[{ ...buttons.modify, disabled: modifier && !validateModifier(modifier).isValid }]} />
        </Footer>
      </Container>
    )
  }

  if (step === STEP.MODIFY_ALL) {
    return (
      <Container style={{ height: windowHeight }}>
        <BackButton onClick={setStepToInitial} {...floatingButtonProps} />
        <Header>
          <Title>Modify</Title>
        </Header>
        <Body>
          {remainingModifiers?.map(mod => (
            <ModifierAccordion key={mod.id} modifier={mod} />
          ))}
        </Body>
        <Footer>
          <DoubleCTA buttons={[buttons.modifyAll]} />
        </Footer>
      </Container>
    )
  }

  const selectorModifiers = comboNestedModifiers?.concat(topLevelModifiers)

  return (
    <Container style={{ height: windowHeight }}>
      <CloseButton onClick={onClose} {...floatingButtonProps} />

      {loadingModifiers ? (
        <LoadingView>
          <CircleLoadingIndicator />
        </LoadingView>
      ) : (
        <>
          <Body>
            <ImageContainer as="div" src={findImage(menuItem)}>
              {spotlight && (
                <SpotlightButton onClick={() => spotlightDialogProps.show()}>
                  <SpotlightIcon />
                </SpotlightButton>
              )}
            </ImageContainer>
            <NutritionalChips item={menuItem} />
            <Item>
              <ItemName>{menuItem?.name}</ItemName>
              <ItemIngredients menuItem={menuItem} />
              <ItemMeta>
                <ItemPrice>{itemPrice}</ItemPrice>
                <ItemCalories activeModifiers={activeModifiers} menuItem={menuItem} />
              </ItemMeta>
            </Item>

            {showQuantity && <QuantityInput quantity={quantity} onIncrement={handleIncrementQuantity} onDecrement={handleDecrementQuantity} />}

            {comboModifier && (
              <ComboContainer>
                {comboModifier?.options?.map(comboOption => (
                  <ComboButton
                    key={comboOption.id}
                    onClick={() => handleSelectModifier(comboModifier, comboOption)}
                    $isActive={isModifierOptionActive(comboModifier, comboOption)}
                  >
                    {comboOption.name}
                  </ComboButton>
                ))}
              </ComboContainer>
            )}

            {selectorModifiers?.length > 0 && (
              <SelectorContainer>
                {selectorModifiers?.map(mod => (
                  <ModifierSelector
                    key={mod.id}
                    modifier={mod}
                    onClick={() => stepToModify(mod)}
                    horizontal={findMetadata(mod.metadata, 'APP_MODIFY_CONTROL') === 'HORIZONTAL'}
                  />
                ))}
              </SelectorContainer>
            )}

            <ToggleFavoriteItemButton
              favorite={favorite}
              addFavoriteDialogProps={addFavoriteDialogProps}
              removeFavoriteDialogProps={removeFavoriteDialogProps}
              valid={valid}
            />
          </Body>

          <Footer>
            <TotalText>
              Total
              <TextSeparator />
              {formatCurrency(totalCost || 0)}
            </TotalText>

            {sessionIntersection && (
              <DietaryInfoContainer>
                {sessionIntersection?.map((dietaryRestriction, index) => (
                  <div key={`dietaryRestriction-${index}`}>
                    <WarningIcon />
                    <span>{dietaryRestriction.violationExplanation}</span>
                  </div>
                ))}
              </DietaryInfoContainer>
            )}

            {!valid && <Button variant="primary" label={nextRequiredModifier?.description} onClick={handleNextModifier} />}

            {valid && (
              <DoubleCTA buttons={(remainingModifiers?.length > 0 ? [buttons.modifyNext] : []).concat([isEditing ? buttons.update : buttons.add])} />
            )}
          </Footer>

          <AddFavorieItemModal dialogProps={addFavoriteDialogProps} menuItem={menuItem} />
          <RemoveFavorieItemModal dialogProps={removeFavoriteDialogProps} favorite={favorite} />

          {spotlightDialogProps.visible && (
            <ModalWithBackdrop
              dialogProps={spotlightDialogProps}
              ariaLabel="Spotlight"
              containerStyle={{ overflow: 'hidden', position: 'relative', height: 'auto' }}
            >
              <SpotlightContainer>
                <SpotlightCloseButton onClick={() => spotlightDialogProps.toggle()} />
                <SpotlightMeta>
                  <SpotlightTitle>{text('Spotlight.Heading')}</SpotlightTitle>
                  <SpotlightHeading>{spotlight?.heading}</SpotlightHeading>
                  <SpotlightDescription>{spotlight?.description}</SpotlightDescription>
                </SpotlightMeta>
              </SpotlightContainer>
            </ModalWithBackdrop>
          )}
        </>
      )}
    </Container>
  )
}
