import React, { useContext, useEffect, useState } from 'react'

const defaultRegion = {
  latitude: parseFloat(process.env.REACT_APP_DEFAULT_LOCATION_LATITUDE) || null,
  longitude: parseFloat(process.env.REACT_APP_DEFAULT_LOCATION_LONGITUDE) || null,
}

const GeoLocation = navigator.geolocation
const GeoLocationContext = React.createContext()
const GeoLocationOptions = { useSignificantChanges: true }

export const useGeoLocation = () => useContext(GeoLocationContext)

export const GeoLocationProvider = ({ children }) => {
  const [locationInfo, setLocationInfo] = useState({
    location: defaultRegion,
    error: null,
  })

  console.debug('[GeoLocationProvider] locationInfo:', locationInfo)

  const handleNewLocation = newLocation => {
    /**
     * Using all available fields from `GeolocationCoordinates`
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/GeolocationCoordinates}
     */
    const { latitude, longitude, altitude, accuracy, altitudeAccuracy, heading, speed } = newLocation?.coords
    console.debug(`[GeoLocationProvider] Received new location: LAT: ${latitude} LONG: ${longitude}`)
    setLocationInfo({ location: { latitude, longitude, altitude, accuracy, altitudeAccuracy, heading, speed } })
  }

  const handleLocationError = locationError => {
    setLocationInfo({ location: defaultRegion, error: locationError })
    console.error(locationError)
  }

  useEffect(() => {
    const watchId = GeoLocation.watchPosition(handleNewLocation, handleLocationError, GeoLocationOptions)
    return () => GeoLocation.clearWatch(watchId)
  }, [])

  return <GeoLocationContext.Provider value={locationInfo}>{children}</GeoLocationContext.Provider>
}
