import moment from 'moment-timezone'

export const ASAP = 'ASAP'

export const READ_FORMAT = 'YYYYMMDD HH:mm'

export const WRITE_FORMAT = 'YYYY-MM-DD HH:mm'

export const formatDate = (date, format, utcOffset) => (utcOffset ? moment(date).utcOffset(utcOffset).format(format) : moment(date).format(format))

export const isInCalendarRange = ({ calendarRanges, leadTimeEstimateMinutes, now, target }) => {
  const sanitizedNow = now.clone().add(leadTimeEstimateMinutes, 'minutes')
  const sanitizedTarget = target.clone().add(leadTimeEstimateMinutes, 'minutes')
  return (
    calendarRanges?.some(range => {
      const rangeStart = moment(range.start).add(leadTimeEstimateMinutes, 'minutes')
      const start = moment.max([sanitizedNow, rangeStart])
      const end = moment(range.end).add(leadTimeEstimateMinutes, 'minutes')
      const isBetween = sanitizedTarget.isBetween(start, end, undefined, '[)')
      return isBetween
    }) || false
  )
}

export const getFirstAvailableScheduleTime = ({ calendarRanges, leadTimeEstimateMinutes, now, target }) => {
  let firstAvailableScheduleTime = null
  const sanitizedNow = now.clone().add(leadTimeEstimateMinutes, 'minutes')
  for (const range of calendarRanges || []) {
    const start = moment(range.start).add(leadTimeEstimateMinutes, 'minutes')
    const end = moment(range.end).add(leadTimeEstimateMinutes, 'minutes')
    if (sanitizedNow.isBefore(start) && target.isSameOrBefore(start)) {
      firstAvailableScheduleTime = start
      break
    }
    if (sanitizedNow.isBefore(end) && target.isSameOrBefore(end)) {
      firstAvailableScheduleTime = moment.max([sanitizedNow, target])
      break
    }
  }
  return firstAvailableScheduleTime
}

export const syncPickupTime = ({ basket, calendarRanges, now, pickupTime, syncFn, utcOffset }) => {
  let target = !pickupTime || pickupTime === ASAP ? now.clone().startOf('minute') : moment(pickupTime).startOf('minute')

  const isInRange = isInCalendarRange({ calendarRanges, leadTimeEstimateMinutes: basket?.leadTimeEstimateMinutes, now, target })
  const isSameTimeWanted = formatDate(target, READ_FORMAT, utcOffset) === basket.timeWanted

  if (!isInRange) {
    target = getFirstAvailableScheduleTime({ calendarRanges, leadTimeEstimateMinutes: basket?.leadTimeEstimateMinutes, now, target })
  }

  // this is intent to interpret the first selection option from TimeModal's as ASAP
  const asapThreshold = now.clone().startOf('minute').add(basket?.leadTimeEstimateMinutes, 'minutes')
  if (target && target.isSameOrBefore(asapThreshold)) {
    target = ASAP
  }

  const isAsapTimeMode = target === ASAP
  const isBasketAsapTimeMode = ASAP === basket.timeMode.toUpperCase()

  console.debug('[usePickupTime]', {
    basketTimeMode: basket?.timeMode,
    basketTimeWanted: basket?.timeWanted,
    calendarRanges,
    formattedTargetTime: !target || target === ASAP ? target : formatDate(target, READ_FORMAT, utcOffset),
    isAsapTimeMode,
    isBasketAsapTimeMode,
    isInRange,
    isSameTimeWanted,
    now: now?.toISOString(),
    pickupTime,
    targetTime: !target || target === ASAP ? target : target?.toISOString(),
    utcOffset,
  })

  if (!target) {
    console.info('[usePickupTime] skipping time wanted updating since there is no time available.')
    return
  }

  if ((isAsapTimeMode && !isInRange) || (!isAsapTimeMode && !isSameTimeWanted) || isAsapTimeMode ^ isBasketAsapTimeMode) {
    return syncFn?.(target)
  }
}
