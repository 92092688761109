import { some } from 'lodash'
import React, { useCallback, useState } from 'react'

import { Button } from '../../components/Button/Button'
import { ModalHeader } from '../../components/ModalHeader/ModalHeader'
import { ModalWithBackdrop } from '../../components/ModalWithBackdrop/ModalWithBackdrop'
import { TextInput } from '../../components/TextInput/TextInput'
import { useSession } from '../../contexts/session'
import firebase from '../../services/firebase'
import { ModalFixedBottomAction, ModalFormContainer } from './Checkout.styles'

export const ContactInfoModal = contactInfoModalProps => {
  const { state, dispatch } = useSession()
  const { contactInfo, activeAddress } = state

  const [firstName, setFirstName] = useState(contactInfo?.firstName || '')
  const [lastName, setLastName] = useState(contactInfo?.lastName || '')
  const [email, setEmail] = useState(contactInfo?.email || '')
  const [contactNumber, setContactNumber] = useState(activeAddress?.phoneNumber || contactInfo?.contactNumber || '')

  const [inputFirstNameErrorMsg, setInputFirstNameErrorMsg] = useState(null)
  const [inputLastNameErrorMsg, setInputLastNameErrorMsg] = useState(null)
  const [inputEmailErrorMsg, setInputEmailErrorMsg] = useState(null)
  const [inputContactNumberErrorMsg, setInputContactNumberErrorMsg] = useState(null)

  const handleSaveContactInfo = useCallback(() => {
    setInputFirstNameErrorMsg(null)
    setInputLastNameErrorMsg(null)
    setInputEmailErrorMsg(null)
    setInputContactNumberErrorMsg(null)

    const emailTestExpression = /\S+@\S+/

    if (firstName.trim().length === 0) {
      setInputFirstNameErrorMsg('First Name is Required')
    }

    if (lastName.trim().length === 0) {
      setInputLastNameErrorMsg('Last Name is Required')
    }

    if (email.trim().length === 0) {
      setInputEmailErrorMsg('Email is Required')
    }

    if (!emailTestExpression.test(String(email).toLowerCase())) {
      setInputEmailErrorMsg('Email is Invalid')
    }

    if (contactNumber.trim().length === 0) {
      setInputContactNumberErrorMsg('Contact Number is Required')
    }

    if (contactNumber.trim().length < 10) {
      // Phone number in an 11 digit format. If specified, this contact number will be applied
      // to the user or guest. Orders with a handoff mode of "dispatch" require a valid phone number.
      // If the user does not have a phone number on file or this is a guest order, this field is
      // required.
      setInputContactNumberErrorMsg('Please enter a valid, 10-digit phone number.')
    }

    if (some([inputFirstNameErrorMsg, inputLastNameErrorMsg, inputEmailErrorMsg, inputContactNumberErrorMsg])) {
      return
    }

    firebase.analytics().logEvent('Add_Contact_Info_Successful')

    dispatch({ type: 'SET_CONTACT_INFO', payload: { ...contactInfo, firstName, lastName, email, contactNumber } })
    contactInfoModalProps.hide()
  }, [
    dispatch,
    inputFirstNameErrorMsg,
    inputLastNameErrorMsg,
    inputEmailErrorMsg,
    inputContactNumberErrorMsg,
    firstName,
    lastName,
    email,
    contactNumber,
    contactInfoModalProps.hide,
  ])

  return (
    <ModalWithBackdrop dialogProps={contactInfoModalProps} ariaLabel="Contact Info" containerStyle={{ overflow: 'hidden', position: 'relative' }}>
      <ModalHeader title="Contact Info" onClose={contactInfoModalProps.hide} />

      <ModalFormContainer>
        <TextInput
          required
          value={firstName}
          placeholder="First Name"
          style={{ width: '100%' }}
          errorMessage={inputFirstNameErrorMsg}
          onChange={e => setFirstName(e.target.value)}
        />
        <TextInput
          required
          value={lastName}
          placeholder="Last Name"
          style={{ width: '100%' }}
          errorMessage={inputLastNameErrorMsg}
          onChange={e => setLastName(e.target.value)}
        />
        <TextInput
          required
          value={email}
          placeholder="Email"
          style={{ width: '100%' }}
          errorMessage={inputEmailErrorMsg}
          onChange={e => setEmail(e.target.value)}
        />

        <TextInput
          required
          value={contactNumber}
          style={{ width: '100%' }}
          placeholder="Phone Number"
          errorMessage={inputContactNumberErrorMsg}
          onChange={e => setContactNumber(e.target.value)}
        />
      </ModalFormContainer>

      <ModalFixedBottomAction>
        <Button variant="primary" label="Save" onClick={handleSaveContactInfo} />
      </ModalFixedBottomAction>
    </ModalWithBackdrop>
  )
}
