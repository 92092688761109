import './default.css'

import Color from 'color'
import { css } from 'styled-components'

import { isDesktop, isLargeDesktop, isMobile, isTablet } from '../components/responsive/Responsive'

const fonts = {
  regular: 'sans-serif',
  bold: 'sans-serif-medium',
}

const icons = {
  facebookIcon: require('../assets/icons/facebook.svg'),
  instagramIcon: require('../assets/icons/instagram.svg'),
  listItemMarkerIcon: require('../assets/icons/list_item_map_marker.svg'),
  twitterIcon: require('../assets/icons/twitter.svg'),
  types: {
    drawer: {
      package: 'Ion',
      name: 'menu',
    },
    back: {
      package: 'Ion',
      name: 'chevron-back-circle-outline',
    },
  },
  size: {
    sm: 16,
    md: 18,
  },
}

const white = Color('#FFFFFF')
const black = Color('#000000')
const gray = Color('#A2A8B0')
const orange = Color('#ED5D2C')
const yellow = Color('#FCCC31')
const red = Color('#BF411E')

const brandColors = {
  black,
  gray,
  gray10: Color('#FBFBFB'),
  gray20: Color('#DEE0E4'),
  gray30: Color('#D4D7DB'),
  gray40: Color('#C3C6CA'),
  gray50: Color('#A2A8B0'),
  gray60: Color('#828891'),
  gray70: Color('#565656'),
  gray80: Color('#3A3E43'),
  gray90: Color('#212224'),
  orange,
  orange10: Color(orange).lighten(0.4),
  orange20: Color(orange).lighten(0.3),
  orange30: Color(orange).lighten(0.2),
  orange40: Color(orange).lighten(0.1),
  orange50: Color(orange),
  orange60: Color(orange).darken(0.1),
  orange70: Color(orange).darken(0.2),
  orange80: Color(orange).darken(0.3),
  orange90: Color(orange).darken(0.4),
  red,
  white,
  yellow,
}

const primary = orange
const secondary = yellow

const brand = 'default'

const colors = {
  brand: brandColors,
  white,
  black: Color('#000000'),
  primary,
  secondary,
  backgroundRequiredInput: '#F4F3F1', // FIXME:
}

const mapOverlay = {
  activeMarker: {
    path:
      'M5.8752 16C5.71718 15.9251 5.58472 15.8042 5.49467 15.6528C4.02783 13.5488 2.54994 11.4528 1.09099 9.34241C0.535343 8.5325 0.198899 7.58916 0.115198 6.60642C-0.131118 4.04642 1.23467 1.58403 3.55099 0.568027C5.79941 -0.419171 7.92309 -0.118372 9.82415 1.46083C10.3974 1.93309 10.873 2.51541 11.2236 3.17438C11.5742 3.83334 11.793 4.55598 11.8673 5.30082C12.0563 6.79713 11.6695 8.30973 10.7873 9.52481C9.3673 11.5515 7.94625 13.5819 6.52415 15.616C6.4317 15.7826 6.29121 15.9166 6.12151 16H5.8752ZM7.9673 6.02242C7.96881 5.62859 7.85535 5.24311 7.64121 4.91446C7.42706 4.58581 7.12179 4.32867 6.7638 4.17538C6.4058 4.02209 6.01107 3.97949 5.62925 4.05295C5.24742 4.1264 4.89556 4.31263 4.61792 4.58821C4.34027 4.86379 4.14925 5.21642 4.06887 5.60173C3.98849 5.98704 4.02235 6.38783 4.16618 6.7537C4.31002 7.11956 4.5574 7.43415 4.87723 7.65791C5.19705 7.88166 5.57503 8.00458 5.96362 8.01121C6.22417 8.01438 6.48277 7.96541 6.7246 7.86709C6.96643 7.76878 7.18674 7.62307 7.37288 7.43831C7.55903 7.25354 7.70736 7.03337 7.80936 6.7904C7.91136 6.54743 7.96504 6.28645 7.9673 6.02242Z',
  },
  inactiveMarker: {
    path:
      'M5.8752 16C5.71718 15.9251 5.58472 15.8042 5.49467 15.6528C4.02783 13.5488 2.54994 11.4528 1.09099 9.34241C0.535343 8.5325 0.198899 7.58916 0.115198 6.60642C-0.131118 4.04642 1.23467 1.58403 3.55099 0.568027C5.79941 -0.419171 7.92309 -0.118372 9.82415 1.46083C10.3974 1.93309 10.873 2.51541 11.2236 3.17438C11.5742 3.83334 11.793 4.55598 11.8673 5.30082C12.0563 6.79713 11.6695 8.30973 10.7873 9.52481C9.3673 11.5515 7.94625 13.5819 6.52415 15.616C6.4317 15.7826 6.29121 15.9166 6.12151 16H5.8752ZM7.9673 6.02242C7.96881 5.62859 7.85535 5.24311 7.64121 4.91446C7.42706 4.58581 7.12179 4.32867 6.7638 4.17538C6.4058 4.02209 6.01107 3.97949 5.62925 4.05295C5.24742 4.1264 4.89556 4.31263 4.61792 4.58821C4.34027 4.86379 4.14925 5.21642 4.06887 5.60173C3.98849 5.98704 4.02235 6.38783 4.16618 6.7537C4.31002 7.11956 4.5574 7.43415 4.87723 7.65791C5.19705 7.88166 5.57503 8.00458 5.96362 8.01121C6.22417 8.01438 6.48277 7.96541 6.7246 7.86709C6.96643 7.76878 7.18674 7.62307 7.37288 7.43831C7.55903 7.25354 7.70736 7.03337 7.80936 6.7904C7.91136 6.54743 7.96504 6.28645 7.9673 6.02242Z',
  },
}

const borderRadii = {
  sm: '3px',
}

const borderSizes = {
  xs: '1px',
  sm: '2px',
  md: '3px',
}

const borders = {
  light: `${borderSizes.xs} solid ${brandColors.gray50.hex()}`,
  lightHighlight: `${borderSizes.xs} solid ${primary.hex()}`,
  normal: `${borderSizes.sm} solid ${black}`,
  normalHighlight: `${borderSizes.sm} solid ${primary.hex()}`,
}

const fontSizes = {
  xs: '14px',
  sm: '16px',
  md: '18px',
  lg: '20px',
  xl: '24px',
  xxl: '32px',
}

const font = {
  styles: {
    regular: fonts.regular,
    bold: fonts.bold,
    numbers: fonts.regular,
    h1: css`
      font-family: ${fonts.bold};
      font-size: 32px;
    `,
    h2: css`
      font-family: ${fonts.bold};
      font-size: 28px;
    `,
    h3: css`
      font-family: ${fonts.bold};
      font-size: 24px;
    `,
    body: css`
      font-family: ${fonts.regular};
      font-size: 20px;
    `,
    description: css`
      font-family: ${fonts.regular};
      font-size: 14px;
    `,
    descriptionLarge: css`
      font-family: ${fonts.regular};
      font-size: 24px;
    `,
    metadata: css`
      font-family: ${fonts.regular};
      font-size: 12px;
      text-transform: uppercase;
    `,
    cta: css`
      font-family: ${fonts.regular};
      font-size: 20px;
    `,
    ctaSmall: css`
      font-family: ${fonts.regular};
      font-size: 16px;
    `,
    label: css`
      font-family: ${fonts.regular};
      font-size: 16px;
    `,
    labelInput: css`
      font-family: ${fonts.regular};
      font-size: 16px;
    `,
    labelInputSmall: css`
      font-family: ${fonts.regular};
      font-size: 12px;
    `,
    labelInputError: css`
      color: ${brandColors.red};
      font-family: ${fonts.regular};
      font-size: 12px;
    `,
    labelCurrency: css`
      font-family: ${fonts.regular};
      font-size: 16px;
      margin-top: 4px;
    `,
    labelCurrencyLeft: css`
      font-family: ${fonts.regular};
      font-size: 16px;
      margin-top: 4px;
    `,
    labelCurrencyRight: css`
      font-family: ${fonts.regular};
      font-size: 16px;
      margin-top: 4px;
    `,
    labelLarge: css`
      font-family: ${fonts.regular};
      font-size: 20px;
    `,
    navItem: css`
      font-family: ${fonts.regular};
      font-size: 20px;
      text-transform: uppercase;
    `,
  },
  sizes: fontSizes,
}

const buttons = {
  buttonContainer: {
    default: css`
      background: transparent;
      &:hover {
        text-decoration: underline;
      }
    `,
    primary: css`
      background-color: ${primary.hex()};
      border: ${borderSizes.xs} solid ${brandColors.gray60.hex()};
      border-radius: ${borderRadii.sm};
      color: ${brandColors.black.hex()};
      &:hover {
        border-color: ${brandColors.gray60.hex()}; // FIXME:
      }
    `,
    secondary: css`
      background-color: ${secondary.hex()};
      border: ${borderSizes.xs} solid ${brandColors.black.hex()};
      border-radius: ${borderRadii.sm};
      color: ${brandColors.black.hex()};
      &:hover {
        background-color: ${brandColors.gray60.hex()};
        border-color: ${brandColors.gray60.hex()};
      }
    `,
    link: css`
      background-color: transparent;
      border: none;
      &:hover {
        text-decoration: underline;
      }
    `,
  },
  roundContainer: {
    primary: css`
      background-color: ${brandColors.white.hex()};
      border: ${borderSizes.xs} solid ${brandColors.gray30.hex()};
      color: ${brandColors.black.hex()};
      &:hover {
        border-color: ${brandColors.black.hex()};
        color: ${brandColors.black.hex()};
      }
    `,
    secondary: css`
      background-color: ${brandColors.white.hex()};
      border: ${borderSizes.xs} solid ${brandColors.gray20.hex()};
      color: ${brandColors.black.hex()};
      &:hover {
        background-color: ${brandColors.black.hex()};
        border-color: ${brandColors.black.hex()};
        color: ${brandColors.white.hex()};
      }
    `,
  },
  icon: {
    container: ``,
    image: `
      max-height: 32px;
      margin-left: 10px;
    `,
  },
  small: css`
    ${font.styles.ctaSmall};
    padding: 10px 16px;
  `,
  modalHeaderCloseButtonVariant: 'primary',
}

const home = {
  heroAsset: 'HomeHero',
  sectionHeaderBackground: brandColors.white.hex(),
  styles: {
    sectionH1: css`
      ${isMobile`
        font-size: ${fontSizes.xl};
        line-height: 28px;
      `}
      ${isTablet`
        font-size: ${fontSizes.xxl};
        line-height: 42px;
      `}
      ${isDesktop`
        font-size: ${fontSizes.xxl};
        line-height: 42px;
      `}
      ${isLargeDesktop`
        font-size: ${fontSizes.xxl};
        line-height: 42px;
      `}
    `,
    sectionHeaderContainer: css`
      ${isMobile`
        padding: 24px 16px;
      `}
      ${isTablet`
        padding: 28px 24px;
      `}
      ${isDesktop`
        padding: 48px;
      `}
      ${isLargeDesktop`
        padding: 48px;
      `}
    `,
    sectionBody: css`
      ${isMobile`
        padding: 36px 16px;
      `}
      ${isTablet`
        padding: 36px 24px;
      `}
      ${isDesktop`
        padding: 48px;
      `}
      ${isLargeDesktop`
        padding: 48px;
      `}
    `,
    viewLocationsButton: css`
      font-size: ${fontSizes.sm};
      font-weight: 500;
      line-height: 130%;
      text-decoration: underline;
      text-transform: unset;
    `,
  },
}

const changeAddressChip = {
  outerContainerStyle: css`
    height: 100%;
    &:before {
      content: '';
      border: ${props => `1px solid  ${props.theme.colors.brand.gray30.hex()}`};
      height: 100%;
      margin: 0 26px;
    }
  `,
  innerContainerStyle: css`
    background: ${primary.hex()};
    border-radius: 100px;
    color: ${brandColors.black.hex()};
    font-family: ${font.styles.bold};
    font-size: ${font.sizes.sm};
    letter-spacing: 0.06em;
    line-height: ${font.sizes.lg};
    &:hover {
      background-color: ${colors.black.hex()};
      color: ${brandColors.white.hex()};
    }
  `,
}

const toggles = {
  active: {
    border: `${borderSizes.xs} solid ${brandColors.black.hex()}`,
    backgroundColor: brandColors.black.hex(),
    textColor: brandColors.white.hex(),
  },
  inactive: {
    border: `${borderSizes.xs} solid ${brandColors.gray30.hex()}`,
    backgroundColor: brandColors.white.hex(),
    textColor: brandColors.black.hex(),
  },
  style: css`
    padding: 10px 0;
    max-width: 124px;
    :first-of-type {
      border-bottom-left-radius: 100px;
      border-top-left-radius: 100px;
    }
    :last-of-type {
      border-bottom-right-radius: 100px;
      border-top-right-radius: 100px;
    }
  `,
  styles: {
    active: css`
      background-color: ${brandColors.black.hex()};
      color: ${brandColors.white.hex()};
      border: ${borders.normal};
    `,
    inactive: css`
      background-color: ${brandColors.white.hex()};
      color: ${brandColors.black.hex()};
      border: ${borders.normal};
    `,
  },
}

const items = {
  selected: {
    background: brandColors.gray10.hex(),
  },
  highlighted: {
    background: brandColors.gray20.hex(),
  },
  hover: {
    background: brandColors.gray20.hex(),
  },
}

const autocomplete = {
  container: css`
    border-radius: ${borderRadii.sm};
  `,
}

const card = {
  container: css`
    border-radius: ${borderRadii.sm};
  `,
  restaurant: {
    summaryText: css``,
  },
}

const input = {
  container: css``,
  element: css``,
  label: css``,
}

const auth = {
  header: css`
    ${props => props.theme.font.styles.h1};
    color: ${props => props.theme.colors.brand.white.hex()};
    background-color: ${props => props.theme.colors.brand.black.hex()};
  `,
}

const orderTracking = {
  closeButtonVariant: 'secondary',
  foregroundImage: {
    style: `
    `,
  },
  header: {
    container: {
      style: `
        background-color: ${brandColors.black};
        color: ${brandColors.white};
        padding: 32px 16px;
        order: -1;
      `,
    },
    title: {
      style: `
        font-size: 26px;
        margin-bottom: 8px;
      `,
    },
    text: {
      style: `
        font-size: ${fontSizes.xs};
        font-style: normal;
        font-weight: normal;
        line-height: 140%;
        letter-spacing: -0.02em;
      `,
    },
  },
  section: {
    style: `
    `,
    body: {
      style: `
      `,
    },
    title: {
      style: `
        font-size: 26px;
        margin-bottom: 8px;
      `,
    },
  },
  aside: {
    style: `
      background-color: #efefef8c;
    `,
  },
  map: {
    container: {
      style: `
        padding: 0;
      `,
    },
    showInfoBox: false,
  },
  summary: {
    container: {
      style: `
        border: none;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
        hr {
          border-top: 1px solid #e8e8e8;
        }
      `,
    },
  },
  progressBar: {
    colors: {
      active: primary,
      inactive: '#C4C4C4',
    },
    container: {
      style: `
        margin-bottom: 16px;
      `,
    },
    fillContainer: {
      style: `
      `,
    },
    iconContainer: {
      style: `
      `,
    },
  },
}

const map = {
  infoBox: {
    container: {
      style: `
        background-color: ${colors.primary};
        border-radius: 2px;
        border: ${borders.white};
        box-sizing: border-box;
        color: ${colors.secondary};
        font-family: ${font.styles.bold};
        font-size: ${font.sizes.sm};
        padding: 4px 6px;
        text-transform: uppercase;
        white-space: nowrap;
      `,
    },
  },
}

export const theme = {
  auth,
  autocomplete,
  borderRadii,
  borders,
  borderSizes,
  brand,
  buttons,
  card,
  changeAddressChip,
  colors,
  font,
  home,
  icons,
  input,
  items,
  map,
  mapOverlay,
  toggles,
  spacing: {
    xs: '4px',
    sm: '8px',
    md: '12px',
    lg: '16px',
    xl: '24px',
  },
  statusBar: {
    style: 'light-content',
  },
  pills: {
    height: '40px',
    borderRadius: '20px',
  },
  currency: {
    left: {
      fontFamily: fonts.regular,
      fontSize: '16px',
    },
    right: {
      fontFamily: fonts.regular,
      fontSize: '16px',
    },
  },
  header: {
    background: black,
    logoAsset: 'LogoOnDark',
    container: css`
      background: ${({ theme }) => theme.header.styles.backgroundColor};
      padding: 24px 48px;
      ${isTablet`
        padding: 24px;
      `}
      ${isMobile`
        padding: 16px;
      `}
    `,
    styles: {
      brandLogo: {
        container: `
        `,
      },
    },
  },
  footer: {
    background: black,
  },
  profile: {
    header: css`
      color: ${black};
      background-color: 'transparent';
    `,
    navBar: {
      container: css`
        border: none;
        box-shadow: none;
      `,
      navItem: css`
        font-family: ${({ theme }) => theme.font.styles.bold};
        color: ${brandColors.black};
      `,
      textColor: black,
      selectedItemTextColor: black,
      selectedItemTextDecoration: 'none',
      selectedItemBackgroundColor: 'transparent',
    },
    orderHistory: {
      topBackgroundColor: white,
    },
    dietaryRestrictions: {
      topBackgroundColor: white,
    },
    contactInfo: {
      topBackgroundColor: white,
    },
  },
  accountCreated: {
    messageFontStyle: css`
      font-family: ${fonts.bold};
      font-size: 28px;
    `,
  },
  menuContext: {
    brandFilter: { backgroundColor: primary, foregroundColor: Color(white) },
    backgroundColor: primary,
    iconBackgroundColor: secondary,
    iconColor: Color(black),
    iconBorderColor: Color('transparent'),
    categoryBar: {
      backgroundColor: primary,
      active: {
        padding: '5px 5px',
        border: `1px solid ${brandColors.gray90}`,
        background: primary,
        textColor: Color(black),
        fontFamily: fonts.regular,
      },
      inactive: {
        border: 'none',
        background: secondary,
        textColor: Color(white),
        fontFamily: fonts.regular,
      },
    },
  },
  handoffSelector: {
    backgroundColor: primary,
    color: secondary,
    border: `1px solid ${primary.hex()}`,
    fontFamily: fonts.regular,
    textTransform: 'none',
    secondary: {
      backgroundColor: secondary,
      color: primary,
      border: `1px solid ${primary.hex()}`,
      fontFamily: fonts.regular,
      textTransform: 'none',
    },
  },
  deliveryAddress: {
    statusBar: 'dark-content',
  },
  categoryLanding: {
    bodyPadding: '30px',
    itemMargin: '10px',
    itemPadding: '0px',
    displayMeta: true,
    imageWidth: '50%',
    imageHeight: '123px',
    imageResizeMode: 'cover',
    imagePosition: 'relative',
    border: `1px solid ${brandColors.gray50.hex()}`,
    backgroundImageAsset: undefined,
  },
  menuCategoryBar: css``,
  menuCategoryBarButton: css``,
  menuCategoryBarButtonSelected: css``,
  menuItems: {
    dietaryRestrictions: {
      warningColor: brandColors.orange50,
    },
    simpleBuilder: {
      modifierTextColor: primary,
      statusBarStyle: 'dark-content',
      spotlightIcon: null,
      spotlightBg: null,
    },
    cards: {
      vertical: {
        height: '326px',
        border: `1px solid ${primary.hex()}`,
        borderRadius: '1px',
        borderBottomLeftRadius: '0px',
        imageHeight: '150px',
        backgroundColor: 'white',
        title: {
          fontFamily: fonts.regular,
          fontSize: '20px',
        },
        description: {
          fontFamily: fonts.regular,
          fontSize: '16px',
          color: primary,
        },
      },
    },
    categoryHeading: {
      height: '100px',
      fontFamily: fonts.regular,
      fontSize: '20px',
      borderBottomWidth: 0,
      borderBottomColor: primary,
      padding: '10px 0px',
      textAlign: 'center',
      textColor: primary,
    },
    customBuilder: {
      asideContainer: css``,
      asideBodyContainer: css``,
      asideMetaContainer: css``,
      modifierGroupsContainer: css`
        background-color: ${brandColors.white.hex()};
      `,
      modifierGroups: {
        active: {
          backgroundColor: primary,
          textColor: secondary,
          borderRadius: '20px',
          textTransform: 'uppercase',
          padding: '6px 16px',
          margin: '0px 4px',
        },
        inactive: {
          backgroundColor: secondary,
          textColor: primary,
          borderRadius: '20px',
          textTransform: 'uppercase',
          padding: '6px 16px',
          margin: '0px 4px',
          textColorDisabled: 'red',
        },
      },
      optionCards: {
        border: `1px solid ${brandColors.gray50.hex()}`,
        borderActive: `1px solid ${black}`,
        quantityButtonBorder: `1px solid ${black}`,
        quantityButtonBorderDisabled: `1px solid ${brandColors.gray50}`,
        quantityButtonColor: Color(black),
        quantityButtonColorDisabled: brandColors.gray50,
      },
    },
  },
  basket: {
    statusBarStyle: 'dark-content',
    topBackgroundColor: primary,
    topTextColor: secondary,
    backButtonVariant: 'secondary',
    upsellCarousel: {
      padding: 16,
      paddingTop: 0,
      backgroundColor: 'transparent',
    },
    upsellMeta: {
      backgroundColor: brandColors.gray10.rgb().string(),
    },
    upsellCarouselCard: {
      borderWidth: 1,
      borderRadius: 3,
      backgroundColor: white,
      borderColor: brandColors.gray20.rgb().string(),
    },
  },
  controlContainer: {
    border: `1px solid ${brandColors.gray50.hex()}`,
    borderTopWidth: '0px',
    borderRightWidth: '0px',
    borderBottomWidth: '1px',
    borderLeftWidth: '0px',
    padding: '16px 0px 16px 0px',
  },
  checkout: {
    statusBarStyle: 'dark-content',
    topBackgroundColor: primary,
    topTextColor: secondary,
    backButtonVariant: 'secondary',
    summary: {
      header: css`
        ${({ theme }) => theme.font.styles.labelInput};
        background-color: ${props => props.theme.checkout.topBackgroundColor};
        color: ${props => props.theme.colors.white};
      `,
      container: css`
        border: ${({ theme }) => theme.borders.light};
        border-radius: 0 0 3px 3px;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
        hr {
          border: none;
          border-top: 1px solid ${({ theme }) => theme.colors.brand.gray20};
        }
      `,
    },
  },
  modals: {
    header: css`
      background-color: ${brandColors.white};
      border-bottom: 1px solid ${({ theme }) => theme.colors.brand.gray20};
      justify-content: center;
      padding: 24px 20px;
    `,
  },
  orderTracking,
}
