import styled from 'styled-components'

import { isMobile } from '../responsive/Responsive'

export const ModalBodyContainer = styled.div`
  background: ${props => props.theme.colors.brand.white};
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;

  padding: 24px;
  ${isMobile`
    padding: 16px;
  `}
`

export const ModalFooterContainer = styled.div`
  box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.1);
  z-index: 1;

  padding: 16px 24px;
  ${isMobile`
    padding: 16px;
  `}
`

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 480px;
  ${isMobile`
    height: 100vh;
    min-width: auto;
  `}
  overflow-y: auto;
`
