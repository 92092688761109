import { Box } from 'reakit/Box'
import styled from 'styled-components'

import { isDesktop, isLargeDesktop, isMobile, isTablet } from '../../../components/responsive/Responsive'

export const Container = styled(Box)`
  background: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 166px;
  position: relative;
  text-align: center;
  ${isLargeDesktop`
    min-height: 288px;
  `}
  ${isDesktop`
    min-height: 200px;
  `}
  ${isTablet`
    min-height: 200px;
  `}
  ${isMobile`
    min-height: auto;
    padding: 11px 16px 26px;
  `}
`
