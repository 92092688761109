import styled from 'styled-components'

import { isMobile, isTablet } from '../../../../components/responsive/Responsive'

export const Container = styled.div`
  display: flex;
  flex-direction: row;

  /* Desktop, Large Desktop */
  padding: 50px 40px 150px;

  ${isTablet`
    flex-direction: column;
    height: 100%;
    padding: 36px 36px 30px;
  `}

  ${isMobile`
    flex-direction: column;
    height: 100%;
    padding: 24px;
  `}
`

export const Form = styled.form`
  display: flex;
  flex-direction: row;
  height: 100%;

  /* Desktop, Large Desktop */
  align-items: center;

  ${isTablet`
    align-items: unset;
    flex-direction: column;
  `}

  ${isMobile`
    align-items: unset;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-evenly;
  `}
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;

  ${isTablet`
    flex-direction: column;
    margin-right: 0;
    margin-bottom: 70px;
  `}

  ${isMobile`
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-evenly;
    margin-right: 0;

    button {
      margin-bottom: 16px;
     }
  `}
`

export const DateButton = styled.div`
  margin-right: 22px;
  min-width: 220px;
  ${isTablet`
    margin-bottom: 23px;
    min-width: 350px;
  `}
`

export const TimeButton = styled.div`
  min-width: 163px;
  margin-right: 22px;
  ${isTablet`
    min-width: 350px;
  `}
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-grow: 2;
  * > {
    display: block;
  }
`

export const BlockContainer = styled.div`
  display: block;
  min-width: 272px;

  ${isTablet`
    width: 100%;
  `}

  ${isMobile`
    width: 100%;
  `}
`
