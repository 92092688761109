import styled from 'styled-components'

import { isMobile } from '../../../../../components/responsive/Responsive'

export const SavedAddressModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 480px;
  ${isMobile`
    height: 100vh;
    min-width: auto;
  `}
  overflow-y: auto;
`
