import React from 'react'

import { getCardIcon, getCvvIcon } from '../../../images/secure'

export const SecureFieldIcon = ({ fieldType, iconType }) => {
  return (
    <img
      alt={fieldType + '-' + iconType}
      className={'secure-field--card-icon secure-field--card-icon-' + iconType}
      src={fieldType === 'card' ? getCardIcon(iconType) : getCvvIcon(iconType)}
    />
  )
}
