import styled from 'styled-components'

import { Description, H3, Label } from '../../../components/Text/Text.styles'

export const RewardsProgramLogoAltContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
`

export const RewardsProgramLogoAlt = styled.img`
  z-index: 10;
  height: 128px;
`

export const MeterBackground = styled.div`
  width: 100%;
  display: flex;
  padding-top: 128px;
  padding-bottom: 40px;
  justify-content: center;
  background-color: ${props => props.theme.colors.brand.green60};
`

export const MeterContainer = styled.div`
  position: relative;
  width: 375px;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const PointsInfo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 150px;
  display: flex;
  font-size: 16px;
  text-align: center;
  align-items: center;
  flex-direction: column;
`

export const PointsHeader = styled.div`
  font-weight: bold;
`

export const CurrentPoints = styled.div`
  font-size: 38px;
`

export const PointsDetails = styled.div`
  font-size: 15px;
`

export const CenterContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${props => props.theme.colors.white};
`

export const Sections = styled.div`
  max-width: 500px;
`

export const Section = styled.div`
  padding: 0px 16px 0px 16px;
`

export const SectionTitle = styled(H3)`
  margin-top: 26px;
  margin-bottom: 20px;
`

export const SectionItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  ${props => (props.$active ? '' : `color: #777;`)}
`

SectionItem.defaultProps = {
  $active: true,
}

export const SectionItemIcon = styled.div`
  width: 60px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SectionItemIconImage = styled.img`
  height: 41px;
  width: 41px;
  border-radius: 21px;
  ${props =>
    props.$active
      ? ''
      : `
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  `}
`

SectionItemIconImage.defaultProps = {
  $active: true,
}

export const SectionItemMeta = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 4px;
`

export const SectionItemTitle = styled(Label)`
  font-weight: bold;
  margin-bottom: 4px;
`

export const SectionItemDescription = styled(Description)`
  line-height: 22px;
  margin-bottom: 4px;
`

export const RewardsSectionIconImage = styled.img.attrs(({ theme, source, variant }) => ({
  src: source || (variant === 'currency' ? theme.checkout?.rewards?.currencyButtonIcon : theme.checkout?.rewards?.sectionButtonIcon),
}))`
  height: 41px;
  width: 41px;
  border-radius: 21px;
`
