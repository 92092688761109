import { MdExpandMore } from 'react-icons/md'
import { Button as ReakitButton } from 'reakit'
import styled, { css } from 'styled-components'

import { Ingredients } from '../../components/Basket/Ingredients/Ingredients'
import { Button } from '../../components/Button/Button'
import { isMobile } from '../../components/responsive/Responsive'
import { InfoButton } from './InfoButton/InfoButton'

export const Container = styled.div`
  width: 100%;
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;

  margin: 48px;

  ${isMobile`
      flex-direction: column;
      margin: 24px 16px;
      padding-bottom: 82px;
  `}
`

export const Body = styled.div`
  flex: 1;
  max-width: 600px;

  & + & {
    margin-left: 16px;
  }

  ${isMobile`
      & + & {
        margin: 32px 0 0 0;
      }
  `}
`

export const Heading = styled.h2`
  ${({ theme }) => theme.font.styles.h2};

  color: ${({ theme }) => theme.checkout.topTextColor};
  font-size: 32px;
  text-transform: uppercase;
  /* margin: 0 0 22px 16px; */
`

export const SectionLabel = styled.h3`
  ${({ theme }) => theme.checkout.sectionLabel};
  display: flex;
  justify-content: space-between;
`

export const SectionButton = styled(InfoButton)`
  margin-bottom: 12px;
  width: 100%;
  ${({ theme }) => theme.checkout.sectionButton};
`

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
`

export const ChevronIcon = styled(MdExpandMore).attrs(({ theme }) => ({
  color: theme.colors.black.hex(),
  size: 32,
}))``

export const ActionLinkLabel = styled.span`
  ${({ theme }) => theme.font.styles.labelInput};
  text-decoration: underline;
`

export const PromoContainer = styled.div`
  position: relative;
`

export const PromoButton = styled(Button)`
  position: absolute;
  right: 20px;
  top: 16px;
  flex: 0;

  font-size: 16px;
  padding: 10px 16px;
  margin-left: 4px;
  width: auto;
`

export const Number = styled.p`
  font-family: ${({ theme }) => theme.font.styles.numbers};
`

export const NumberTotal = styled.p`
  font-family: ${({ theme }) => theme.font.styles.numbers};
  font-size: 24px;
  color: ${({ theme }) => theme.checkout.topTextColor};
`

export const FixedBottomActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  background: ${props => props.theme.colors.white};
  padding: 40px 80px;

  ${isMobile`
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;

      box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.08);
      padding: 16px;
  `}
`

export const CheckoutButton = styled(Button)`
  /* margin: 16px 16px 0 16px; */
`

export const PickupTypeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const PickupTypeButton = styled.button`
  /* button overrides */
  border: none;
  cursor: pointer;
  width: 100%;

  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  background: transparent;
  height: 65px;

  & + & {
    margin-left: 14px;
  }

  ${({ theme }) => theme.font.styles.cta};
  ${props => (props.isSelected ? props.theme.buttons.buttonContainer.primary : props.theme.buttons.buttonContainer.secondary)}
  ${props => props.theme.buttons.small}
`

export const DeliveryNotesTextarea = styled.textarea`
  color: ${({ theme }) => theme.colors.black};
  ${({ theme }) => theme.font.styles.labelInput}

  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray40};
  border-radius: 3px;
  margin-bottom: 32px;
  padding: 16px;
  resize: none;
  width: 100%;
`

export const ApplyButton = styled(Button)`
  /* margin: 32px 16px; */
`

export const DeliveryTipContainer = styled.div`
  padding: 0 20px;
`

export const DeliveryTipLabel = styled.p`
  color: ${props => props.theme.colors.white};
  ${({ theme }) => theme.font.styles.labelInput}
  margin-bottom: 14px;
`

export const DeliveryTipButtonScroll = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  width: 100%;
`

export const DeliveryTipButton = styled.button`
  flex: 1;

  ${({ theme }) => theme.font.styles.number}
  background-color: ${props => props.theme.colors.brand.gray10};
  border: none;
  border-radius: 3px;
  font-size: 24px;
  height: 59px;
  margin-right: 14px;
  padding: 16px;

  ${props =>
    props.isSelected &&
    css`
      background-color: ${props.theme.colors.primary};
    `}

  ${isMobile`
      font-size: 16px;
  `}
`

// export const DeliveryTipButtonText = styled.p`
//   color: ${props => props.theme.colors.black};
//   font-family: ${({ theme }) => theme.font.styles.numbers};
//   font-size: 16px;
//   padding-top: 4px; /* needed to center align the DINCondensed font*/
// `

export const DeliveryTipDescription = styled.p`
  color: ${props => props.theme.colors.black};
  font-size: 12px;
  margin-top: 12px;
`

export const SummaryHeader = styled.div`
  border-radius: 3px 3px 0 0;
  padding: 24px 20px;
  ${({ theme }) => theme.checkout.summary.header};
`

export const SummaryLabel = styled.h3`
  ${({ theme }) => theme.font.styles.label};
  font-size: 24px;
`

export const SummaryContainer = styled.div`
  position: relative;

  ${isMobile`
      position: unset;
  `}

  ${({ theme }) => theme.checkout.summary.container};
`

export const ProductContainer = styled.div`
  padding: 20px 20px 32px 20px;
`

export const Meta = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`

export const ProductQuantity = styled.span`
  ${({ theme }) => theme.font.styles.labelInput};
`

export const ProductName = styled.p`
  ${({ theme }) => theme.checkout.summary.productName};
  /* ${({ theme }) => theme.font.styles.labelInput} */
`

export const IngredientsList = styled(Ingredients)`
  flex: 1;
  align-items: center;

  margin: 8px 0;
`

export const CostsContainer = styled.div`
  /* border-color: ${props => props.theme.colors.brand.gray10.hex()};
  border-top-width: 1px; */
`

export const CostItem = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  padding: 20px;
`

export const CostsLabel = styled.p`
  color: ${({ isPromo, theme }) => (isPromo ? theme.colors.brand.orange80 : theme.colors.black)};
  /* ${({ theme }) => theme.font.styles.labelInput} */
  ${({ theme }) => theme.checkout.summary.costsLabel}
`

export const CostsNumber = styled.p`
  color: ${({ isPromo, theme }) => (isPromo ? theme.colors.brand.orange80 : theme.colors.black)};
  /* font-family: ${({ theme }) => theme.font.styles.numbers};
  font-size: 24px; */
  ${({ theme }) => theme.checkout.summary.costsNumber}
`

export const CancellationPolicyContainer = styled.div`
  margin: 24px 20px;
`

export const CancellationPolicyTitle = styled.p`
  color: ${props => props.theme.colors.black};
  ${({ theme }) => theme.font.styles.labelInput}
  margin-bottom: 10px;
`

export const CancellationPolicyText = styled.p`
  color: ${props => props.theme.colors.gray70};
  ${({ theme }) => theme.font.styles.labelInputSmall}/* margin: 0 0 16px 16px; */
`

export const ModalFormContainer = styled.div`
  padding: 24px;
  min-width: 390px;

  ${isMobile`
    padding: 16px;
    min-width: 100%;
  `}
`
export const ModalFixedBottomAction = styled.div`
  padding: 16px 24px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);

  ${isMobile`
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
  `}
`

export const PaymentMethodsContainer = styled.div`
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  border-radius: 3px;

  margin-bottom: 12px;
`

export const PaymentOption = styled(ReakitButton)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: none;
  border: none;
  font-family: ${({ theme }) => theme.font.styles.regular} !important;
  padding: 20px;
  width: 100%;
`

export const CheckBoxContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  border-radius: 12px;
  align-items: center;
  justify-content: center;

  ${({ theme, selected }) =>
    selected &&
    css`
      background-color: ${theme.colors.black.rgb().string()};
    `}

  ${({ theme, selected }) =>
    !selected &&
    css`
      border: 0.75px solid #b8b8b8;
    `}
`
