import React, { useCallback } from 'react'

import { CloseButton } from '../../../../components/Button/Button'
import { DialogWithBackdrop } from '../../../../components/DialogWithBackdrop/DialogWithBackdrop'
import { useNavigation } from '../../../../hooks/useNavigation'
import { CustomBuilderConfirmation } from '../CustomBuilderConfirmation/CustomBuilderConfirmation'

const floatingButtonProps = {
  style: {
    margin: '20px 0 0 20px',
    position: 'absolute',
    zIndex: 2,
  },
  size: 32,
}

export const CustomBuilderConfirmationModal = ({ dialogProps, ...props }) => {
  const navigation = useNavigation()

  const handleClose = useCallback(success => {
    dialogProps.hide()
    if (success) {
      navigation.navigate('StartOrder', { item: null })
    }
  }, [])

  return (
    <DialogWithBackdrop dialogProps={dialogProps} ariaLabel="Confirm item" containerStyle={{ overflow: 'hidden', position: 'relative' }}>
      <CloseButton onClick={dialogProps.hide} {...floatingButtonProps} />
      <CustomBuilderConfirmation handleClose={handleClose} {...props} />
    </DialogWithBackdrop>
  )
}
