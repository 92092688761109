import { IoIosClose } from 'react-icons/io'
import { MdChevronLeft } from 'react-icons/md'
import { Button as ReakitButton } from 'reakit/Button'
import styled from 'styled-components'

export const TYPES = {
  DEFAULT: 'default',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  LINK: 'link',
  SMALL: 'small',
}

export const ButtonContainer = styled(ReakitButton)`
  ${({ theme }) => theme.font.styles.cta};
  color: ${({ theme }) => theme.colors.brand.black};

  /* button overrides */
  border: none;
  cursor: pointer;
  width: 100%;

  flex: 1;

  padding: 18px 16px;

  transition: all 0.2s;

  ${props => props.small === TYPES.SMALL && props.theme.buttons.small}
  ${({ theme, variant = TYPES.default }) => theme.buttons.buttonContainer[variant]}

  :disabled,&.disabled {
    background: ${({ theme }) => theme.colors.brand.gray20};
    border-color: ${({ theme }) => theme.colors.brand.gray20};
    color: ${({ theme }) => theme.colors.brand.gray70};
  }
`

export const ButtonWithIconContentContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${({ theme }) => theme.buttons.icon.container};
`

export const ButtonIconImage = styled.img`
  ${({ theme }) => theme.buttons.icon.image};
`

export const ButtonLoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const RoundContainer = styled(ReakitButton)`
  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  border-radius: 16px;
  height: 32px;
  width: 32px;
  /* margin: 0px 8px 0px 15px; */

  /* button overrides */
  border: none;
  cursor: pointer;

  ${({ theme, variant = TYPES.default }) => theme.buttons.roundContainer[variant]}
`

export const BackIcon = styled(MdChevronLeft).attrs(({ size, theme }) => ({
  size: size || theme.icons.size.md,
}))``

export const CloseIcon = styled(IoIosClose).attrs(({ size, theme }) => ({
  size: size || theme.icons.size.md,
}))``
