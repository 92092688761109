import { Box } from 'reakit/Box'
import styled from 'styled-components'

import { isDesktop, isLargeDesktop, isMobile, isTablet } from '../../../../../components/responsive/Responsive'

export const Container = styled(Box)`
  background: ${props => props.theme.colors.brand.black};
  color: ${props => props.theme.colors.brand.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 288px;
  position: relative;
  text-align: center;
  ${isLargeDesktop`
    min-height: 288px;
  `}
  ${isDesktop`
    min-height: 200px;
  `}
  ${isTablet`
    min-height: 200px;
  `}
  ${isMobile`
    min-height: auto;
    padding: 11px 16px 26px;
  `}
  transition: min-height 250ms ease-in-out, visibility 250ms;
  transition-delay: visibility 250ms;
  overflow: hidden;
`

export const HeroContentContainer = styled.div`
  background: ${props => `${props.theme.colors.black.alpha(0.7)}`};
  display: flex;
  flex-direction: column;
  justify-content: center;

  /* overlap */
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  ${isMobile`
    position: relative;
  `}

  opacity: ${props => (props.visible ? '1' : '0')};
  transition: opacity 250ms ease-in-out;
`

export const HeroTitleHeader = styled.h1`
  ${props => props.theme.font.styles.h1};
  padding-bottom: 20px;
  ${isMobile`
    padding-bottom: 4px;
  `}
`

export const HeroDescriptionParagraph = styled.p`
  ${props => props.theme.font.styles.description};
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
`
