import { Box } from 'reakit/Box'
import styled from 'styled-components'

import { isDesktop, isLargeDesktop, isMobile, isTablet } from '../../responsive/Responsive'

export const BrandLogoStyle = styled.img`
  ${isTablet`
    max-width: 50px
  `}
`

export const FooterStyle = styled(Box)`
  background: ${props => props.theme.colors.brand.black};
  ${isLargeDesktop`
    padding: 40px 48px;
  `}
  ${isDesktop`
    padding: 30px 38px;
  `}
  ${isTablet`
    padding: 10px 12px;
  `}
  ${isMobile`
    display: none;
  `}
`

export const FooterContent = styled.div`
  border: ${props => `${props.theme.borderSizes.sm} solid ${props.theme.colors.brand.white}`};
  border-radius: 0px 30px;

  display: flex;
  flex-direction: row;
  align-items: stretch;

  > * {
    &:not(:first-child) {
      border-left: ${props => `${props.theme.borderSizes.sm} solid ${props.theme.colors.brand.white}`};
    }
  }
`

export const FooterSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
`

export const FooterSectionTitle = styled.div`
  ${props => props.theme.font.styles.cta}
  color: ${props => props.theme.colors.brand.white};

  padding-bottom: 14px;

  ${isLargeDesktop`
    font-size: 16px;
  `}
  ${isDesktop`
    font-size: 16px;
  `}
  ${isTablet`
    font-size: 12px;
  `}
`

export const FooterSubSection = styled.div`
  display: flex;
  flex-direction: column;

  ${isLargeDesktop`
    padding-right: ${props => (props.$isLast ? 0 : '103px')};
  `}
  ${isDesktop`
    padding-right: ${props => (props.$isLast ? 0 : '103px')};
  `}
  ${isTablet`
    padding-right: ${props => (props.$isLast ? 0 : '53px')};
  `}
`

export const Paragraph = styled.p`
  ${({ theme }) => theme.font.styles.descriptionExtraSmall};
  color: ${props => props.theme.colors.brand.white};

  ${isTablet`
    font-size: 10px;
  `}
`

export const AppLinkStyle = styled.a`
  ${({ theme }) => theme.font.styles.descriptionExtraSmall};
  color: ${props => props.theme.colors.brand.white};

  /* link overrides */
  cursor: pointer;
  text-decoration: underline;

  /* font overrides */
  text-transform: none;
`

export const LinkStyle = styled.a`
  ${({ theme }) => theme.font.styles.descriptionExtraSmall};
  color: ${props => props.theme.colors.brand.white};

  padding-bottom: 10px;

  /* link overrides */
  cursor: pointer;
  display: block;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }

  /* font overrides */
  text-transform: none;
`
