import { pick, uniqBy } from 'lodash'

export const buildAddress = ({ address, defaultPhoneNumber }) => {
  return {
    aptNumber: address?.aptNumber || '',
    city: address?.city || '',
    isDefault: address?.isDefault || false,
    nickname: address?.nickname || '',
    phoneNumber: address?.phoneNumber || defaultPhoneNumber || '',
    specialInstructions: address?.specialInstructions || '',
    state: address?.state || '',
    streetAddress: address?.streetAddress || '',
    zipCode: address?.zipCode || '',
  }
}

const OLO_GUEST_ADDRESS_FIELDS = [
  'building',
  'city',
  'latitude',
  'longitude',
  'isDefault',
  'phoneNumber',
  'state',
  'streetAddress',
  'specialInstructions',
  'zipCode',
]

export const filterUniqueAddresses = addresses => uniqBy(addresses, address => pick(address, OLO_GUEST_ADDRESS_FIELDS))

const ACTIVE_ADDRESS_FIELDS = [
  'aptNumber',
  'city',
  'latitude',
  'longitude',
  'isDefault',
  'phoneNumber',
  'state',
  'streetAddress',
  'specialInstructions',
  'zipCode',
]

export const pickActiveAddressFields = address => pick(address, ACTIVE_ADDRESS_FIELDS)

export const mapGuestAddressToFirebaseAddress = address => ({
  aptNumber: address.building || null,
  city: address.city || null,
  isDefault: address.isDefault || false,
  latitude: address.longitude || null,
  longitude: address.longitude || null,
  nickname: address.nickname || 'OTHER',
  phoneNumber: address.phoneNumber || null,
  state: address.state || null,
  streetAddress: address.streetAddress || null,
  specialInstructions: address.specialInstructions || null,
  zipCode: address.zipCode || null,
})

const concatJoining = (array, separator = '') => array?.filter(Boolean).join(separator)

export const buildAddressLine1 = address => concatJoining([address?.streetAddress, address?.aptNumber || address?.building], ', ')

export const buildAddressLine2 = address => concatJoining([address?.city, address?.state, address?.zipCode], ', ')

export const buildLastOrderAddressLine = address => concatJoining([address?.streetAddress, address?.building, address?.city], ', ')

export const buildOrderConfirmationAddressLine = address => concatJoining([address?.streetAddress, address?.building], ', ')
