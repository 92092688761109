import { useCallback } from 'react'

import firebase from '../services/firebase'
import { useFeatureFlags } from './useFeatureFlags'

export const useAnalytics = () => {
  const { FacebookPixelTracking } = useFeatureFlags()

  const track = useCallback((eventName, metadata) => {
    firebase.analytics().logEvent(eventName, metadata)
    facebook(eventName, metadata)
    google(eventName, metadata)
  })

  // log native Google Analytics events
  const google = useCallback((eventName, metadata) => {
    switch (eventName) {
      case 'Place_Order_Successful': {
        // Prepare ecommerce bundle
        firebase.analytics().logEvent(firebase.analytics.EventName.PURCHASE, {
          currency: 'USD',
          value: metadata?.amount, // Total Revenue
          coupon: metadata?.promoCode,
        })
        break
      }
    }
  })

  // log native Facebook events
  const facebook = useCallback((eventName, metadata) => {
    if (FacebookPixelTracking && window?.fbq) {
      switch (eventName) {
        case 'Added_To_Bag': {
          console.log('[useAnalytics] Sending AddToCart event to Facebook')
          window.fbq('track', 'AddToCart', {
            content_name: metadata?.dishName,
            value: metadata?.amount * (metadata?.quantity || 1),
            currency: 'USD',
          })
          break
        }
        case 'Place_Order_Successful': {
          console.log('[useAnalytics] Sending Purchase event to Facebook')
          window.fbq('track', 'Purchase', {
            content_name: metadata?.handoffMode,
            value: metadata?.amount,
            currency: 'USD',
          })
          break
        }
      }
    }
  })

  return { track }
}
