import React from 'react'
import * as Ionicons from 'react-icons/io'
import * as RiIcons from 'react-icons/ri'
import styled, { useTheme } from 'styled-components'

import { IconImage, IoniContainer } from './Icons.styles'

const Icon = ({ alt = '', src, ...props }) => {
  return <IconImage src={src} alt={alt} {...props} />
}

const buildDefaultIcon = (WrappedComponent = React.Fragment, props) => (
  <IoniContainer as="div" {...props}>
    <WrappedComponent />
  </IoniContainer>
)

const buildIcon = (WrappedComponent, { name, defaultIcon, ...defaultProps }) => overrideProps => {
  const theme = useTheme()
  const props = Object.assign({}, defaultProps, overrideProps)
  const src = theme.icons[name]
  return src ? <WrappedComponent src={src} {...props} /> : buildDefaultIcon(defaultIcon, props)
}

export const AccountIcon = buildIcon(Icon, { name: 'accountWhiteIcon' })
export const PrefsIcon = buildIcon(Icon, { name: 'settings' })
export const FacebookIcon = buildIcon(Icon, { name: 'facebookIcon', alt: 'Facebook' })
export const InstagramIcon = buildIcon(Icon, { name: 'instagramIcon', alt: 'Instagram' })
export const ListItemMarkerIcon = buildIcon(Icon, { name: 'listItemMarkerIcon', size: '1.1em' })
export const MapMarkerIcon = buildIcon(Icon, { name: 'mapMarkerIcon' })
export const MyBagIcon = buildIcon(Icon, { name: 'myBagWhiteIcon' })
export const SearchIcon = buildIcon(Icon, { name: 'search', defaultIcon: Ionicons.IoMdSearch, size: '1.3em' }) // FIXME: 2em
export const TwitterIcon = buildIcon(Icon, { name: 'twitterIcon', alt: 'Twitter' })
export const WarningIcon = styled(buildIcon(Icon, { name: 'warningIcon', defaultIcon: RiIcons.RiErrorWarningLine, alt: 'Warning' }))`
  color: ${({ theme }) => theme.menuItems.dietaryRestrictions.warningColor};
`
