import { Box } from 'reakit/Box'
import { TabPanel } from 'reakit/Tab'
import styled from 'styled-components'

import { isDesktop, isLargeDesktop, isMobile, isTablet } from '../../components/responsive/Responsive'
import { H1 } from '../../components/Text/Text.styles'

export const HomeContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  ${isMobile`
    flex-direction: column;
  `}
  overflow: hidden;
`

export const Aside = styled.aside`
  display: flex;
  overflow: hidden;
  width: 100%;
  ${isMobile`
    order: -1;
    min-height: 300px;
  `}
`

export const Section = styled.section`
  background: ${({ theme }) => theme.colors.brand.white};
  color: ${({ theme }) => theme.colors.brand.black};
  display: flex;
  flex: 1;

  display: flex;
  flex-direction: column;

  ${isTablet`
    min-width: 392px;
    overflow-y: auto;
  `}
  ${isDesktop`
    min-width: 485px;
    overflow-y: auto;
  `}
  ${isLargeDesktop`
    min-width: 485px;
    overflow-y: auto;
  `}
`

export const SectionHeaderContainer = styled.div`
  background: ${({ theme }) => theme.home.sectionHeaderBackground};
  ${({ theme }) => theme.home.styles.sectionHeaderContainer};

  display: flex;
  flex-direction: column;
  width: 100%;
`

export const HomeSectionHeader = styled(H1)`
  margin-bottom: 12px;
  text-transform: uppercase;
  ${({ theme }) => theme.home.styles.sectionH1};
`

export const HeaderText = styled.p`
  margin-bottom: 8px;
  ${({ theme }) => theme.home.styles.headerText};
`

export const SectionBody = styled(Box)`
  ${({ theme }) => theme.home.styles.sectionBody};
  display: flex;
  flex: 1;
  flex-direction: column;
  > * {
    &:not(:first-child) {
      margin-top: 36px;
    }
  }
`
export const HandoffModeTabPanel = styled(TabPanel)`
  display: flex;
  flex: 1;
  flex-direction: column;
`

export const HeroContainer = styled.div`
  display: flex;
  width: 100%;
`

export const HeroImage = styled.img`
  object-fit: cover;
  width: 100%;
  ${isMobile`
    max-height: 310px;
  `}
`
