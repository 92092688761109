import { useQuery } from '@apollo/client'
import { useEffect, useMemo } from 'react'

import { GET_MODIFIERS } from '../data/menu/queries'
import { aggreggateQuantityModifiers, findMenuItem, findModifierOption, findModifierOptions } from '../utils/item'

export const useUtensils = ({ menuItems, products }) => {
  const utensilItem = useMemo(() => findMenuItem(menuItems, 'APP_SPECIAL_PURPOSE', 'UTENSILS'), [menuItems])

  const basketProduct = useMemo(() => products?.find(item => item.productId === utensilItem?.id) || null, [products, utensilItem])

  const { data: itemModifiers } = useQuery(GET_MODIFIERS, {
    fetchPolicy: 'network-only',
    skip: !utensilItem,
    variables: { menuItemId: utensilItem?.id },
  })

  const includeUtensilModifierOption = useMemo(() => findModifierOption(itemModifiers?.modifiers, 'APP_INCLUDE_UTENSILS', 'true'), [itemModifiers])

  const excludeUtensilModifierOption = useMemo(() => findModifierOption(itemModifiers?.modifiers, 'APP_INCLUDE_UTENSILS', 'false'), [itemModifiers])

  const includeMultiUtensilsModifierOptions = useMemo(
    () =>
      aggreggateQuantityModifiers(
        findModifierOptions(itemModifiers?.modifiers, 'APP_INCLUDE_UTENSILS', 'multi'),
        itemModifiers?.modifiers?.[0]?.minChoiceQuantity ?? 0,
        itemModifiers?.modifiers?.[0]?.maxChoiceQuantity ?? 999,
      ),
    [itemModifiers],
  )

  useEffect(() => {
    console.debug('[useUtensils]', {
      includeUtensilModifierOption,
      excludeUtensilModifierOption,
      includeMultiUtensilsModifierOptions,
      utensilItem,
    })
  }, [includeUtensilModifierOption, excludeUtensilModifierOption, utensilItem, includeMultiUtensilsModifierOptions])

  return { basketProduct, includeUtensilModifierOption, excludeUtensilModifierOption, utensilItem, includeMultiUtensilsModifierOptions }
}
