import styled from 'styled-components'

import { CtaSmall } from '../Text/Text.styles'

export const StoreHoursPillFloatingContainer = styled.div`
  position: absolute;
  right: 0;
  transform: translate(25%, -10px);
`

export const StoreHoursPillContentContainer = styled.div`
  background: ${props => props.theme.colors.primary};
  border-radius: 2px;
  display: flex;
  justify-content: center;
  padding: 7px 14px;
  transform: skewX(-15deg);
`

export const StoreHoursPillText = styled(CtaSmall)`
  color: ${props => props.theme.colors.secondary};
  transform: skewX(+15deg);
`
