import { useApolloClient } from '@apollo/client'
import { useCallback } from 'react'

import { useText } from '../content'
import { VALIDATE_BASKET } from '../data/basket/queries'
import { getMessageFromError } from '../data/errors'
import { useMessaging } from './useMessaging'

export const useValidateBasket = () => {
  const apolloClient = useApolloClient()
  const { dispatchMessage } = useMessaging()
  const { text } = useText()
  const validateBasket = useCallback(
    basketId =>
      apolloClient
        .mutate({ mutation: VALIDATE_BASKET, variables: { basketId } })
        .then(response => response.data.validateBasket)
        .catch(error => {
          dispatchMessage.error(getMessageFromError(error) || text('Checkout.Errors.ValidateBasket'), error)
          throw error
        }),
    [apolloClient, dispatchMessage],
  )
  return { validateBasket }
}
