import moment from 'moment-timezone'
import React, { useEffect, useRef, useState } from 'react'

import { Button } from '../../../../components/Button/Button'
import { CircleLoadingIndicator } from '../../../../components/CircleLoadingIndicator/CircleLoadingIndicator'
import { ModalHeader } from '../../../../components/ModalHeader/ModalHeader'
import { ModalWithBackdrop } from '../../../../components/ModalWithBackdrop/ModalWithBackdrop'
import { PaymentTextInput } from '../../../../components/PaymentTextInput/PaymentTextInput'
import { useText } from '../../../../content'
import { CCSF_CREDIT_CARD_CVV_CLASS_NAME, CCSF_CREDIT_CARD_NUMBER_CLASS_NAME } from '../../../../hooks/useCCSF'
import { useMessaging } from '../../../../hooks/useMessaging'
import {
  Body,
  CCSFInputContainer,
  Container,
  ContentView,
  CreditCardContainer,
  CVVContainer,
  ExpiryContainer,
  Footer,
  MessageContainer,
  RowContainer,
  ZipCodeContainer,
} from './CCSFPaymentModal.styles'

const expiryRegex = /^(?<expiryMonth>\d{0,2})(?:\/?(?<expiryYear>\d{0,2}))?$/

const zipCodeRegex = /^\d{0,5}([\s-]\d{0,4})?$/

/**
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/replace#specifying_a_function_as_a_parameter}
 */
const formatExpiry = (match, expiryMonth, expiryYear) => (match.length > 2 ? `${expiryMonth}/${expiryYear}` : expiryMonth)

export const CCSFPaymentModal = ({ addNewActivePaymentMethod, ccsfProps, dialogProps }) => {
  const { isCheckoutFrameReady, initCCSF } = ccsfProps
  const { dispatchMessage } = useMessaging()
  const { text } = useText()

  const [expiry, setExpiry] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [message, setMessage] = useState(null) // TODO: validation
  const zipCodeRef = useRef()

  useEffect(() => {
    if (!isCheckoutFrameReady) initCCSF()
  }, [isCheckoutFrameReady, initCCSF])

  const handleAddCard = () => {
    try {
      const expiryDate = moment(`01/${expiry}`, 'DDMMYY')
      const paymentMethod = {
        accountType: 'creditcard',
        expiry,
        expiryMonth: expiryDate.format('MM'),
        expiryYear: expiryDate.format('YYYY'),
        shouldSavePayment: true,
        zipCode,
      }
      addNewActivePaymentMethod(paymentMethod)
      dialogProps.hide()
    } catch (error) {
      console.log(error)
      dispatchMessage.error(text('Checkout.Errors.AddPayment'))
    }
  }

  const handleExpiryValueChange = event => {
    const value = event.target.value
    if (expiryRegex.test(value)) {
      setExpiry(value.replace(expiryRegex, formatExpiry))
    }
  }

  const handleZipCodeValueChange = event => {
    const value = event.target.value
    if (zipCodeRegex.test(value)) {
      setZipCode(value)
    }
  }

  return (
    <ModalWithBackdrop dialogProps={dialogProps} ariaLabel="Add Payment" containerStyle={{ overflow: 'hidden', position: 'relative' }}>
      <ModalHeader title="Add Payment" onClose={dialogProps.hide} />
      <Container>
        <Body>
          {!isCheckoutFrameReady && (
            <ContentView>
              <CircleLoadingIndicator />
            </ContentView>
          )}
          <CreditCardContainer style={{ height: zipCodeRef?.current?.clientHeight }}>
            <label>Card Number</label>
            <CCSFInputContainer className={CCSF_CREDIT_CARD_NUMBER_CLASS_NAME} />
          </CreditCardContainer>
          <RowContainer>
            <CVVContainer style={{ height: zipCodeRef?.current?.clientHeight }}>
              <label>CVV</label>
              <CCSFInputContainer className={CCSF_CREDIT_CARD_CVV_CLASS_NAME} />
            </CVVContainer>
            <ExpiryContainer>
              <PaymentTextInput name="expiry" placeholder="Exp (MM/YY)" onChange={handleExpiryValueChange} value={expiry} />
            </ExpiryContainer>
          </RowContainer>
          <ZipCodeContainer>
            <PaymentTextInput name="zipCode" placeholder="Billing Zip Code" onChange={handleZipCodeValueChange} value={zipCode} ref={zipCodeRef} />
          </ZipCodeContainer>
          <MessageContainer>{message}</MessageContainer>
        </Body>
        <Footer>
          <Button variant="primary" label="Save" onClick={handleAddCard} disabled={!isCheckoutFrameReady} />
        </Footer>
      </Container>
    </ModalWithBackdrop>
  )
}
