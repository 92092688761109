import React from 'react'

import { Button } from '../Button/Button'
import { Container } from './DoubleCTA.styles'

export const DoubleCTA = ({ buttons, stacked = false }) => (
  <Container as="div" $stacked={stacked}>
    {buttons?.map((buttonProps, index) => (
      <Button key={`button-${index}`} {...buttonProps} />
    ))}
  </Container>
)
