import React, { useMemo } from 'react'
import styled from 'styled-components'

import { CircleLoadingIndicator } from '../../components/CircleLoadingIndicator/CircleLoadingIndicator'
import FavoriteItemCard from '../../components/FavoriteItem/FavoriteItemCard/FavoriteItemCard'
import { useText } from '../../content'
import { useFavorites } from '../../contexts/favorites'
import { ContentContainer, FormContainer, HeaderContainer } from './Profile.styles'

const FavoriteItemsPage = () => {
  const [{ favorites, loading }] = useFavorites()
  const { text } = useText()

  const sectionsData = useMemo(() => {
    return {
      available: favorites.filter(fav => Boolean(fav.menuItem)),
      unavailable: favorites.filter(fav => !fav.menuItem),
    }
  }, [favorites])

  if (loading) {
    return (
      <>
        <HeaderContainer>{text('Profile.FavoriteItems.NavBar.Title')}</HeaderContainer>
        <ContentContainer>
          <FormContainer>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
              <CircleLoadingIndicator />
            </div>
          </FormContainer>
        </ContentContainer>
      </>
    )
  }

  if (!favorites.length) {
    return (
      <>
        <HeaderContainer>{text('Profile.FavoriteItems.NavBar.Title')}</HeaderContainer>
        <ContentContainer>
          <FormContainer style={{ textAlign: 'center' }}>{text('Favorites.List.Empty')}</FormContainer>
        </ContentContainer>
      </>
    )
  }

  return (
    <>
      <HeaderContainer>{text('Profile.FavoriteItems.NavBar.Title')}</HeaderContainer>

      <ContentContainer>
        <FormContainer>
          {sectionsData.available.length > 0 && (
            <>
              <H2>Available</H2>
              {sectionsData.available.map((favorite, idx) => (
                <FavoriteItemCard key={favorite.data.docId} {...{ favorite }} style={{ marginTop: idx > 0 ? 16 : 0 }} />
              ))}
            </>
          )}

          {sectionsData.unavailable.length > 0 && (
            <>
              <H2>Unavailable</H2>
              {sectionsData.unavailable.map((favorite, idx) => (
                <FavoriteItemCard key={favorite.data.docId} {...{ favorite }} style={{ marginTop: idx > 0 ? 16 : 0 }} />
              ))}
            </>
          )}
        </FormContainer>
      </ContentContainer>
    </>
  )
}

const H2 = styled.h2`
  ${({ theme }) => theme.font.styles.h2};
`

export default FavoriteItemsPage
