import { useCallback, useEffect, useReducer, useState } from 'react'
import { useTabState } from 'reakit/Tab'
import Immutable from 'seamless-immutable'
import { getGeocode } from 'use-places-autocomplete'

import { HANDOFF_MODES } from '../../constants/handoffModes'
import { useGeoLocation } from '../../contexts/geolocation'
import { useSession } from '../../contexts/session'
import { useNearestRestaurants } from '../../hooks/useNearestRestaurants'
import { useRestaurantsCalendars } from '../../hooks/useRestaurantCalendars'
import { useSearchForDeliveryRestaurant } from '../../hooks/useSearchForDeliveryRestaurant'

export const SEARCH_ADDRESS_STATE = Object.freeze({
  EMPTY: 'EMPTY',
  FETCHING: 'FETCHING',
  FOUND: 'FOUND',
  NOT_FOUND: 'NOT_FOUND',
  SEARCHING: 'SEARCHING',
})

export const SEARCH_ADDRESS_EMPTY_STATES = [SEARCH_ADDRESS_STATE.EMPTY, SEARCH_ADDRESS_STATE.FETCHING, SEARCH_ADDRESS_STATE.SEARCHING]

export const SEARCH_ADDRESS_LOADING_STATES = [SEARCH_ADDRESS_STATE.FETCHING, SEARCH_ADDRESS_STATE.SEARCHING]

export const initialState = ({ location }) =>
  Immutable({
    addressSearchState: SEARCH_ADDRESS_STATE.EMPTY,
    addressName: '',
    displayMap: false,
    location,
    nearestRestaurants: [],
    savedAddresses: [],
    selectedAddress: null,
    selectedRestaurant: null,
  })

const ADDRESS_SEARCH_STATE = 'ADDRESS_SEARCH_STATE'
const CLEAR_ADDRESS_NAME = 'CLEAR_ADDRESS_NAME'
const SELECT_ADDRESS = 'SELECT_ADDRESS'
const SELECT_RESTAURANT = 'SELECT_RESTAURANT'
const SET_ADDRESS_NAME = 'SET_ADDRESS_NAME'
const SET_DISPLAY_MAP = 'SET_DISPLAY_MAP'
const SET_LOCATION = 'SET_LOCATION'
const SET_SAVED_ADDRESSES = 'SET_SAVED_ADDRESSES'

const REDUCERS = {
  [CLEAR_ADDRESS_NAME]: (state, _) => state.set('addressName', ''),
  [SELECT_ADDRESS]: (state, action) => state.set('addressName', action?.payload?.description || '').set('selectedAddress', action?.payload),
  [SELECT_RESTAURANT]: (state, action) =>
    state
      .set('selectedRestaurant', action?.payload)
      .set('location', {
        latitude: action?.payload?.latitude,
        longitude: action?.payload?.longitude,
      })
      .set('displayMap', true),
  [SET_ADDRESS_NAME]: (state, action) => state.set('addressName', action?.payload),
  [SET_DISPLAY_MAP]: (state, action) => state.set('displayMap', action?.payload),
  [ADDRESS_SEARCH_STATE]: (state, action) => state.set('addressSearchState', action?.payload),
  [SET_LOCATION]: (state, action) => state.set('location', action?.payload),
  [SET_SAVED_ADDRESSES]: (state, action) => state.set('savedAddresses', action?.payload),
}

export const reducer = (state, action) => {
  console.debug('[Home.state] reducer:', { action, state: state?.asMutable() })
  return REDUCERS[action.type](state, action)
}

/**
 * FIXME: useSession instead of this
 */
export const useManagedState = () => {
  const { dispatch: sessionDispatch, state: sessionState } = useSession()
  const { location } = useGeoLocation()
  const [state, homeDispatch] = useReducer(reducer, initialState({ location }))
  const nearestRestaurants = useNearestRestaurants()
  const pickupRestaurants = useRestaurantsCalendars(nearestRestaurants?.data, true)
  const deliveryRestaurants = useSearchForDeliveryRestaurant()

  useEffect(() => {
    console.debug('[Home.state] deliveryRestaurants:', deliveryRestaurants)
  }, [deliveryRestaurants])

  useEffect(() => {
    console.debug('[Home.state] pickupRestaurants:', pickupRestaurants)
  }, [pickupRestaurants])

  const activeHandoffMode = sessionState?.handoffMode
  const tabState = useTabState({ selectedId: `handoffmode-${activeHandoffMode}`, manual: true })
  const [searchAddressInputRef, setSearchAddressInputRef] = useState(null)

  useEffect(() => nearestRestaurants.fetchNearby(location?.latitude, location?.longitude), [nearestRestaurants.fetchNearby])

  const setAddressSearchState = payload => homeDispatch({ type: ADDRESS_SEARCH_STATE, payload })

  /**
   * If it's still `SEARCHING` then clear address and return to empty state, otherwise keep the current state.
   * Remember: `SEARCHING` will be triggered only when address value changes.
   */
  const handleAddressSearchBlur = useCallback(() => {
    console.debug('[Home.state] handleAddressSearchBlur', state?.addressSearchState)
    if (state?.addressSearchState === SEARCH_ADDRESS_STATE.SEARCHING) {
      setAddressSearchState(SEARCH_ADDRESS_STATE.EMPTY)
      clearAddressName()
    }
  }, [state?.addressSearchState])

  const setAddressName = value => {
    homeDispatch({ type: SET_ADDRESS_NAME, payload: value })
    setAddressSearchState(value ? SEARCH_ADDRESS_STATE.SEARCHING : SEARCH_ADDRESS_STATE.EMPTY)
  }

  const clearAddressName = () => homeDispatch({ type: CLEAR_ADDRESS_NAME })

  const searchNewAddress = () => {
    clearAddressName()
    searchAddressInputRef && searchAddressInputRef.focus()
  }

  const selectAddress = async address => {
    homeDispatch({ type: SELECT_ADDRESS, payload: address })

    setAddressSearchState(SEARCH_ADDRESS_STATE.FETCHING)
    setImmediate(() => {
      searchAddressInputRef && searchAddressInputRef.blur()
    })

    const [firstGeocodeResult] = await getGeocode({ placeId: address?.place_id })
    const geocodeLocation = firstGeocodeResult?.geometry?.location

    const [latitude, longitude] = [geocodeLocation?.lat(), geocodeLocation?.lng()]
    homeDispatch({ type: SET_LOCATION, payload: { latitude, longitude } })

    if (sessionState?.handoffMode === HANDOFF_MODES.PICKUP) {
      nearestRestaurants.fetchNearby(latitude, longitude)
    } else {
      const city = address?.terms?.[2]?.value
      const streetAddress = address?.structured_formatting?.main_text

      if (city) {
        const results = await deliveryRestaurants.searchByAddress(streetAddress, city)
        return setAddressSearchState(results?.length > 0 ? SEARCH_ADDRESS_STATE.FOUND : SEARCH_ADDRESS_STATE.NOT_FOUND)
      }
    }

    return setAddressSearchState(SEARCH_ADDRESS_STATE.NOT_FOUND)
  }

  const selectRestaurant = payload => homeDispatch({ type: SELECT_RESTAURANT, payload })

  const setActiveHandoffMode = payload => {
    clearAddressName()
    setAddressSearchState(SEARCH_ADDRESS_STATE.EMPTY)
    sessionDispatch({ type: 'SET_HANDOFF_MODE', payload: payload })
    if (payload === HANDOFF_MODES.PICKUP) {
      sessionDispatch({ type: 'SET_ACTIVE_ADDRESS', payload: null })
    }
  }

  const setSaveAddresses = payload => homeDispatch({ type: SET_SAVED_ADDRESSES, payload })

  const switchToPickup = () => {
    setActiveHandoffMode(HANDOFF_MODES.PICKUP)
    tabState.select(`handoffmode-${HANDOFF_MODES.PICKUP}`)
  }

  const viewLocations = () => {
    homeDispatch({ type: SET_DISPLAY_MAP, payload: true })
    nearestRestaurants.showAll ? nearestRestaurants.fetchNearby(location?.latitude, location?.longitude) : nearestRestaurants.fetchAll()
  }

  return {
    ...state.asMutable(),
    activeHandoffMode,
    clearAddressName,
    deliveryRestaurants,
    pickupRestaurants,
    searchNewAddress,
    selectAddress,
    selectRestaurant,
    setActiveHandoffMode,
    setAddressName,
    setSaveAddresses,
    setSearchAddressInputRef,
    showAll: nearestRestaurants.showAll,
    switchToPickup,
    tabState,
    viewLocations,
    homeDispatch,
    handleAddressSearchBlur,
  }
}
