import { uniq } from 'lodash'
import { useMemo } from 'react'

import { useSession } from '../contexts/session'
import { findMetadata } from '../data/utils'
import { useMenuItems } from './useMenuItems'

const useUpsellProducts = () => {
  const { state: sessionState } = useSession()
  const { basket } = sessionState

  const { items, loadingItems } = useMenuItems()

  const menuProductItems = useMemo(() => (items?.menu || []).map(m => m.products).reduce((acc, curr) => [...acc, ...curr], []), [items?.menu])

  return useMemo(() => {
    if (!basket?.products || !items?.menu || loadingItems) {
      return []
    }

    const cachedProductsInBag = basket?.products.map(basketProduct => menuProductItems.find(p => p.id === basketProduct.productId))

    const upsellCandidateIds = cachedProductsInBag
      .filter(p => p?.metadata?.length) // only products with metadata
      .map(p => findMetadata(p.metadata, 'APP_CROSSSELLING_PRODUCTS')) // extract metadata info
      .filter(Boolean) // filter out if undefined
      .reduce((acc, curr) => [...acc, ...curr.split(',')], []) // "XXXXX,YYYYY,ZZZZZ" -> ["XXXXX", "YYYYY", "ZZZZZ"]
      .map(id => id.trim())
      .map(Number) // ids are Float!

    const unique = uniq(upsellCandidateIds) // remove duplicated ids
    const upsellProducts = unique
      .map(id => menuProductItems.find(p => p.chainProductId === id))
      .filter(p => !!p) // remove falsy values
      .filter(product => !basket?.products.find(p => p.productId === product.id))

    return [upsellProducts, loadingItems]
  }, [basket, loadingItems, menuProductItems])
}

export default useUpsellProducts
