import React from 'react'

import { useFavorites } from '../../../contexts/favorites'
import { Button } from '../../Button/Button'

export const ToggleFavoriteItemButton = ({ favorite, addFavoriteDialogProps, removeFavoriteDialogProps, valid }) => {
  const [favoriteState, , { isFavoriteFeatureEnabled }] = useFavorites()
  if (!isFavoriteFeatureEnabled) return null
  return (
    <Button
      loading={favoriteState.loading}
      label={`${favorite ? 'Remove from' : 'Add to'} Favorites`}
      onClick={favorite ? removeFavoriteDialogProps.show : addFavoriteDialogProps.show}
      style={{
        flexGrow: 0,
        fontSize: 14,
        opacity: !valid || favoriteState.loading ? 0.2 : 1,
        pointerEvents: !valid || favoriteState.loading ? 'none' : 'auto',
      }}
    />
  )
}
