import React, { useCallback, useLayoutEffect, useRef } from 'react'
import ReactDOM from 'react-dom'

import { useWindowSize } from '../../../hooks/useWindowsSize'
import { clearAllBodyScrollLocks, disableBodyScroll } from '../../../utils/bodyScrollLock'
import { Mobile } from '../../responsive/Responsive'
import { FullDialogBackdrop, MainContainer } from './BodyScrollLockModalWithBackdrop.styles'

export const BodyScrollLockModalWithBackdrop = ({ children, dialogProps, ariaLabel, slideInFrom = 'bottom', containerStyle, onBackdropClick }) => {
  const slideInFromBottomClass = slideInFrom === 'bottom' ? 'slideInFromBottom' : ''
  const [, windowHeight] = useWindowSize()
  const elementToKeepScroll = useRef()

  useLayoutEffect(() => {
    if (!elementToKeepScroll.current || !dialogProps.visible) {
      clearAllBodyScrollLocks()
    } else {
      disableBodyScroll(elementToKeepScroll.current)
    }
  }, [dialogProps.visible])

  const handleDialogClick = useCallback(event => {
    event.stopPropagation()
  }, [])

  const handleBackdropClick = useCallback(
    event => {
      event.stopPropagation()
      onBackdropClick ? onBackdropClick() : dialogProps.hide()
    },
    [dialogProps.hide, onBackdropClick],
  )

  return ReactDOM.createPortal(
    <FullDialogBackdrop {...dialogProps} onClick={handleBackdropClick}>
      <Mobile>
        {isMobile => (
          <MainContainer
            aria-label={ariaLabel}
            aria-modal="true"
            className={slideInFromBottomClass}
            data-dialog="true"
            onClick={handleDialogClick}
            hideOnClickOutside={false}
            ref={elementToKeepScroll}
            role="dialog"
            style={{ ...containerStyle, height: isMobile ? windowHeight : 'auto' }}
            tabIndex={0}
            visible={dialogProps.visible}
          >
            {children}
          </MainContainer>
        )}
      </Mobile>
    </FullDialogBackdrop>,
    document.body,
  )
}
