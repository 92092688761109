import { useMemo } from 'react'

import { useSession } from '../contexts/session'

export const useBasketAuthentication = () => {
  const {
    state: { user },
  } = useSession()

  const authentication = useMemo(
    () =>
      user
        ? {
            loyaltyAccessToken: user?.accessToken,
            loyaltyAuthenticationToken: user?.authenticationToken,
            orderingAuthenticationToken: user?.orderingAuthenticationToken,
          }
        : null,
    [user],
  )

  return { authentication }
}
