import React, { createContext, useContext, useEffect, useMemo } from 'react'

import { useFeatureFlags } from '../hooks/useFeatureFlags'
import { useGuestAddresses } from '../hooks/useGuestAddresses'
import { useSavedAddresses } from '../hooks/useSavedAddresses'
import { useSavedAddressesMigration } from '../hooks/useSavedAddressesMigration'
import { useFirebaseAuth } from './firebaseAuth'
import { useSession } from './session'

const AddressesContext = createContext()

export const useAddresses = () => useContext(AddressesContext)

const FirebaseAddressesProvider = ({ children }) => {
  const { isAuthenticated, userDocRef } = useFirebaseAuth()
  const { state: session } = useSession()
  const { addresses, loading, addAddress, removeAddress, updateAddress } = useSavedAddresses({ isAuthenticated, userDocRef })

  useSavedAddressesMigration({ isAuthenticated, token: session.user?.orderingAuthenticationToken, userDocRef })

  useEffect(() => {
    console.debug('[useSavedAddresses] FirebaseAddressesProvider', { addresses })
  }, [addresses])

  return <AddressesContext.Provider value={{ addresses, loading, addAddress, removeAddress, updateAddress }}>{children}</AddressesContext.Provider>
}

const DefaultAddressesProvider = ({ children }) => {
  const { addresses, loading, addAddress, removeAddress, updateAddress } = useGuestAddresses()
  useEffect(() => {
    console.debug('[useSavedAddresses] DefaultAddressesProvider', { addresses })
  }, [addresses])
  return <AddressesContext.Provider value={{ addresses, loading, addAddress, removeAddress, updateAddress }}>{children}</AddressesContext.Provider>
}

export const AddressesProvider = props => {
  const { FirebaseSupport } = useFeatureFlags()
  const Component = useMemo(() => (FirebaseSupport ? FirebaseAddressesProvider : DefaultAddressesProvider), [FirebaseSupport])
  return <Component {...props} />
}

export default AddressesProvider
