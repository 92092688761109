import React, { useCallback } from 'react'

import { CircleLoadingIndicator } from '../../../components/CircleLoadingIndicator/CircleLoadingIndicator'
import { QuantityInput } from '../../../components/QuantityInput/QuantityInput'
import { useText } from '../../../content'
import { useCheckoutUtensils } from '../../../hooks/useCheckoutUtensils'
import { ToggleButton } from '../../auth/components/ToggleButton'
import { SectionLabel } from '../Checkout.styles'

export const UtensilsSection = () => {
  const { isEnabled, utensilItem, selectUtensil, selected, multiItems = [], includeUtensilModifierOption, loading } = useCheckoutUtensils()
  const { text } = useText()

  const toggleChecked = useCallback(() => selectUtensil(!selected), [selected, selectUtensil])

  const onQuantityChange = useCallback((id, quantity) => {
    const newSelection = selected ? { ...selected, [id]: quantity } : { [id]: quantity }
    return selectUtensil(newSelection)
  })

  if (!isEnabled) return null

  const content = () => {
    if (multiItems.length > 0) {
      return multiItems.map(item => {
        return (
          <QuantityInput
            key={item.id}
            label={item.name}
            quantity={selected?.[item.id] ?? item.minQuantity}
            min={item.minQuantity}
            max={item.maxQuantity}
            onIncrement={newQuantity => onQuantityChange(item.id, newQuantity)}
            onDecrement={newQuantity => onQuantityChange(item.id, newQuantity)}
            isLoading={loading}
          />
        )
      })
    } else if (includeUtensilModifierOption !== undefined) {
      return (
        <ToggleButton
          label={utensilItem?.description || text('Checkout.Utensils.ToggleOption.Label')}
          position="left"
          checked={selected}
          onClick={toggleChecked}
          style={{ padding: '24px 0' }}
        />
      )
    }
  }

  return (
    <>
      <SectionLabel>
        {text('Checkout.Utensils.Label')} {loading && <CircleLoadingIndicator />}
      </SectionLabel>
      {content()}
    </>
  )
}
