import { Box } from 'reakit/Box'
import styled from 'styled-components'

export const CardListStyle = styled(Box)`
  display: flex;
  flex-direction: column;
  list-style: none;
  > * {
    &:not(:first-child) {
      margin-top: 12px;
    }
  }
`

export const CardContainerStyle = styled(Box)`
  ${({ theme }) => theme.card.container};
  border: ${props => (props.selected ? `1.5px solid ${props.theme.colors.brand.black}` : `1px solid ${props.theme.colors.brand.gray30}`)};

  &:hover {
    border: ${props => props.theme.borders.normal};
  }

  cursor: pointer;
  padding: 16px;
  position: relative;
`

export const CardHeaderStyle = styled(Box)`
  color: ${props => props.theme.colors.brand.black};

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 12px;
`

export const CardBodyStyle = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;
`

export const CardFooterStyle = styled(Box)`
  display: inline-block;
`
