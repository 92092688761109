import { useQuery } from '@apollo/client'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { FullScreenLoading } from '../../components/FullScreenLoading/FullScreenLoading'
import { HANDOFF_MODES } from '../../constants/handoffModes'
import { useConfig } from '../../contexts/appConfig'
import { useSession } from '../../contexts/session'
import { GET_RESTAURANTS } from '../../data/restaurants/queries'

export const LandingPage = () => {
  const history = useHistory()
  const { dispatch } = useSession()
  const { restaurantSlug } = useParams()
  const { resolveNavigationPayload } = useConfig()
  const { data, loading } = useQuery(GET_RESTAURANTS)

  useEffect(() => {
    if (restaurantSlug && data?.restaurants && !loading) {
      const [selected] = data.restaurants.filter(restaurant => {
        return restaurant.slug?.toLowerCase() === restaurantSlug.toLowerCase()
      })
      if (selected) {
        dispatch({ type: 'RESET' })
        dispatch({ type: 'SET_HANDOFF_MODE', payload: HANDOFF_MODES.PICKUP })
        dispatch({ type: 'SET_ACTIVE_ADDRESS', payload: null })
        dispatch({ type: 'SET_RESTAURANT', payload: selected })
        const screen = resolveNavigationPayload('StartOrder')
        history.push('/' + screen)
      } else {
        history.push('/')
      }
    }
  }, [data?.restaurants, loading])

  if (!restaurantSlug) {
    history.push('/')
  }

  return <FullScreenLoading />
}
