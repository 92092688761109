import styled from 'styled-components'

import { Button } from '../../../components/Button/Button'

export const ViewLocationsButton = styled(Button)`
  ${({ theme }) => theme.home.styles.viewLocationsButton};

  margin-top: 16px;
  padding: 0;
  text-align: left;
`
