import React, { memo } from 'react'

import { StoreHoursPillContentContainer, StoreHoursPillFloatingContainer, StoreHoursPillText } from './StoreHoursPill.styles'

export const StoreHoursPill = memo(({ statusText }) => {
  if (!statusText) return
  return (
    <StoreHoursPillFloatingContainer>
      <StoreHoursPillContentContainer>
        <StoreHoursPillText>{statusText}</StoreHoursPillText>
      </StoreHoursPillContentContainer>
    </StoreHoursPillFloatingContainer>
  )
})
