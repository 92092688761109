import styled from 'styled-components'

import { CloseButton } from '../../components/Button/Button'
import { isMobile } from '../../components/responsive/Responsive'
import Skeleton from '../../components/Skeleton'
import { DescriptionSmall, H2 } from '../../components/Text/Text.styles'
import * as Checkout from '../checkout/Checkout.styles'
import * as Home from '../home/Home.styles'

export const OrderTrackingContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  ${isMobile`
    flex-direction: column;
  `}
  overflow: hidden;
`

export const OrderTrackingHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.orderTracking.header.container.style};
`

export const OrderTrackingForegroundImage = styled.img`
  ${({ theme }) => theme.orderTracking.foregroundImage.style};
`

export const OrderTrackingHeaderTitle = styled(H2)`
  ${({ theme }) => theme.orderTracking.header.title.style};
`

export const OrderTrackingHeaderText = styled(DescriptionSmall)`
  ${({ theme }) => theme.orderTracking.header.text.style};
`

export const OrderTrackingSection = styled(Home.Section)`
  ${({ theme }) => theme.orderTracking.section.style};
`

export const OrderTrackingSectionBody = styled(Home.SectionBody)`
  *:not(:first-child) {
    margin-top: 0;
  }
  ${({ theme }) => theme.orderTracking.section.body.style};
`

export const OrderTrackingSectionTitle = styled(H2)`
  ${({ theme }) => theme.orderTracking.section.title.style};
`

export const OrderTrackingAside = styled(Home.Aside)`
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.orderTracking.aside.style};
`

export const OrderTrackingMapContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;

  height: 100%;
  width: 100%;

  ${isMobile`
    height: 350px;
  `}

  ${({ theme }) => theme.orderTracking.map.container.style};
`

export const OrderTrackingSummaryContainer = styled(Checkout.SummaryContainer)`
  ${({ theme }) => theme.orderTracking.summary.container.style};
`

export const OrderTrackingCloseButton = styled(CloseButton).attrs(({ theme }) => ({
  variant: theme.orderTracking.closeButtonVariant,
}))`
  margin-bottom: 16px;
`

export const InfoSkeleton = styled(Skeleton)`
  height: 16px;
  border-radius: 4px;
  background-color: grey;
`
