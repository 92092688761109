import React, { useMemo } from 'react'
import { useHistory } from 'react-router'
import { useTheme } from 'styled-components'

import { Button } from '../../../components/Button/Button'
import { Description } from '../../../components/Text/Text.styles'
import { PREFERENCES } from '../../../constants/preferences'
import { useText } from '../../../content'
import { useConfig } from '../../../contexts/appConfig'
import { useLoyalty } from '../../../hooks/useLoyalty'
import { HeaderContainer } from '../Profile.styles'
import { FgcBankedCurrencyInfo } from './FgcBankedCurrencyInfo'
import {
  CenterContent,
  CurrentPoints,
  MeterBackground,
  MeterContainer,
  PointsDetails,
  PointsHeader,
  PointsInfo,
  RewardsProgramLogoAlt,
  RewardsProgramLogoAltContainer,
  Section,
  Sections,
} from './FgcRewards.styles'
import { FgcRewardsList } from './FgcRewardsList'
import { FgcRewardsMeter } from './FgcRewardsMeter'
import { FgcTiersStatus } from './FgcTiersStatus'

export const FgcRewardsContent = () => {
  const history = useHistory()
  const { text } = useText()
  const { getAsset, getPreference } = useConfig()
  const { balance, program } = useLoyalty()
  const theme = useTheme()

  const brandName = useMemo(() => getPreference(PREFERENCES.GENERAL)?.brandName, [getPreference])
  const rewardsProgramLogoAlt = getAsset('RewardsProgramLogoAlt')
  const showMigrationInterstitial = getPreference('RewardsConfiguration')?.ShowMigrationInterstitial

  const barProgress = useMemo(() => {
    // points / pointsNeeded * 100
    const progress = Math.trunc(((balance?.unbankedPoints || 0) / (program?.pointsConversionThreshold || 200)) * 100)
    // limiting the progress so if some floating points error occurs, we still get a valid ui
    return Math.min(progress, 100)
  }, [balance?.unbankedPoints, program?.pointsConversionThreshold])

  const { pointsRemaining, currencyEarned } = useMemo(() => {
    return {
      pointsRemaining: (program?.pointsConversionThreshold || 0) - (balance?.unbankedPoints || 0),
      currencyEarned: program?.currencyEarned || 0,
    }
  }, [balance, program])

  const handleMigrate = () => {
    history.push('/migration/verification')
  }

  return (
    <>
      <HeaderContainer backgroundColor={theme?.colors?.brand?.yellow50} style={{ marginBottom: 0 }}>
        <span>&nbsp;</span>
        <RewardsProgramLogoAltContainer>
          <RewardsProgramLogoAlt src={rewardsProgramLogoAlt.url} />
        </RewardsProgramLogoAltContainer>
      </HeaderContainer>
      <MeterBackground>
        <MeterContainer>
          <FgcRewardsMeter barProgress={barProgress} />
          <PointsInfo>
            <PointsHeader>{text('Profile.Rewards.Points.Heading')}</PointsHeader>
            <CurrentPoints>{balance?.unbankedPoints}</CurrentPoints>
            <PointsDetails>{text('Profile.Rewards.Points.Description', { pointsRemaining, currencyEarned })}</PointsDetails>
          </PointsInfo>
        </MeterContainer>
      </MeterBackground>
      <CenterContent>
        <Sections>
          {balance?.bankedCurrency > 0 && <FgcBankedCurrencyInfo />}
          <FgcRewardsList />
          <FgcTiersStatus />
          {showMigrationInterstitial && (
            <Section style={{ margin: '50px 0' }}>
              <Description style={{ display: 'flex', textAlign: 'center', margin: 10 }}>
                {text('Profile.Rewards.Migrate.Description', { brandName })}
              </Description>
              <Button variant="link" label="Migrate Rewards" onClick={handleMigrate} />
            </Section>
          )}
        </Sections>
      </CenterContent>
    </>
  )
}
