import React from 'react'

import { CircleLoadingIndicator } from '../CircleLoadingIndicator/CircleLoadingIndicator'
import { Container, Text } from './FullScreenLoading.styles'

export const FullScreenLoading = ({ isOverlay = false, isVisible = true, showLabel = true, ...props }) => (
  <Container isOverlay={isOverlay} isVisible={isVisible} {...props}>
    <CircleLoadingIndicator />
    {showLabel && <Text>Loading...</Text>}
  </Container>
)
