import { Box } from 'reakit/Box'
import styled from 'styled-components'

export const Container = styled(Box)`
  ${props => props.theme.font.styles.description};
  margin: 12px 0;
`

export const ModifierText = styled.span`
  color: ${({ theme }) => theme.menuItems.simpleBuilder.modifierTextColor};
`
