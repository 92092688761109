import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { Page } from '../../components/Page/Page'
import { SignInForm } from '../../components/SignInForm/SignInForm'
import { useText } from '../../content'
import { useConfig } from '../../contexts/appConfig'
import { Container, HeaderText, Link } from './Auth.styles'
import { BackgroundImageSection } from './components/BackgroundImageSection'

export const LoginPage = () => {
  const { getPreference } = useConfig()
  const { text } = useText()
  const history = useHistory()

  const headerText = `Sign in to ${text('Brand.Name')}`

  const handleSignInSuccess = useCallback(() => history.push('/'), [history])

  const showMigrationInterstitial = useMemo(() => getPreference('RewardsConfiguration')?.ShowMigrationInterstitial, [getPreference])

  return (
    <Page isScrollable>
      <BackgroundImageSection tabIndex="0" aria-label={headerText}>
        <Container>
          <HeaderText>{headerText}</HeaderText>
          <SignInForm headingText={`Sign into your ${text('Brand.Name')} account or create a new account.`} onSignInSuccess={handleSignInSuccess} />
          {showMigrationInterstitial && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Link to="/migration/start" style={{ alignSelf: 'center' }}>
                {text('MigrationInterstitial.Heading')}
              </Link>
            </div>
          )}
        </Container>
      </BackgroundImageSection>
    </Page>
  )
}
