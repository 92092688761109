import styled from 'styled-components'

import { H1 } from '../../Text/Text.styles'

export const Header = styled(H1)`
  margin-bottom: 12px;
  text-transform: uppercase;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
`

export const Text = styled.p`
  margin-bottom: 8px;
  ${({ theme }) => theme.home.styles.headerText};
  font-size: 18px;
  line-height: 140%;
  /* or 25px */

  letter-spacing: 0.02em;
`
