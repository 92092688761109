import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { useCallback } from 'react'

import { useText } from '../content'
import { useSession } from '../contexts/session'
import { APPLY_REWARD_TO_BASKET, GET_LOYALTY_BALANCE, GET_LOYALTY_PROGRAM_INFO, REMOVE_REWARD, REWARD_REDEMPTION } from '../data/loyalty/queries'
import { useMessaging } from './useMessaging'

export const useLoyalty = () => {
  const apolloClient = useApolloClient()
  const {
    state: { user, basket },
    dispatch,
  } = useSession()
  const { dispatchMessage } = useMessaging()
  const { text } = useText()

  const { data: loyaltyProgramInfoData, loading: loyaltyProgramInfoLoading } = useQuery(GET_LOYALTY_PROGRAM_INFO, {
    fetchPolicy: 'network-only',
    skip: !user,
    variables: {
      authentication: {
        loyaltyAuthenticationToken: user?.authenticationToken,
        loyaltyAccessToken: user?.accessToken,
        orderingAuthenticationToken: user?.orderingAuthenticationToken,
      },
    },
    onError: useCallback(
      err =>
        dispatchMessage({
          type: 'error',
          payload: { message: text('Profile.Rewards.Errors.LoadingProgram') },
          err,
        }),
      [text, dispatchMessage],
    ),
  })

  const { data: loyaltyBalanceData, loading: loyaltyBalanceLoading } = useQuery(GET_LOYALTY_BALANCE, {
    fetchPolicy: 'network-only',
    skip: !user,
    variables: {
      authentication: {
        loyaltyAuthenticationToken: user?.authenticationToken,
        loyaltyAccessToken: user?.accessToken,
        orderingAuthenticationToken: user?.orderingAuthenticationToken,
      },
    },
    onError: useCallback(
      err =>
        dispatchMessage({
          type: 'error',
          payload: { message: text('Profile.Rewards.Errors.LoadingBalance') },
          err,
        }),
      [text, dispatchMessage],
    ),
  })

  const [applyRewardToBasketMutation, { loading: applyRewardLoading }] = useMutation(APPLY_REWARD_TO_BASKET, {
    onError: useCallback(
      err =>
        dispatchMessage({
          type: 'error',
          payload: { message: text('RewardRedemptionModal.Errors.Verify') },
          err,
        }),
      [text, dispatchMessage],
    ),
  })

  const [removeRewardMutation, { loading: removeRewardLoading }] = useMutation(REMOVE_REWARD, {
    onError: useCallback(
      err =>
        dispatchMessage({
          type: 'error',
          payload: { message: text('RewardRedemptionModal.Errors.Remove') },
          err,
        }),
      [text, dispatchMessage],
    ),
  })

  const applyReward = async reward => {
    if (reward && basket) {
      const result = await applyRewardToBasketMutation({
        variables: {
          authentication: {
            loyaltyAuthenticationToken: user?.authenticationToken,
            loyaltyAccessToken: user?.accessToken,
            orderingAuthenticationToken: user?.orderingAuthenticationToken,
          },
          redemption: {
            basketId: basket.id,
            discountType: reward.discountType || 'reward',
            rewardId: reward.rewardId,
            /*
            receiptAmount: basket.total,
            menuItems: (basket.products || []).map((product) => ({
              itemName: product.name,
              itemQty: product.quantity,
              itemAmount: product.baseCost,
              menuItemId: `${product.productId}`, // gQL doesn't support 64-bit integers
            })),
            subtotalAmount: basket.subtotal,
            */
          },
        },
      })

      if (result?.data?.applyReward) {
        dispatch({ type: 'SET_BASKET', payload: result.data.applyReward })
        return true
      } else {
        dispatchMessage({
          type: 'error',
          payload: { message: text('RewardRedemptionModal.Errors.Verify') },
        })
        return false
      }
    }
  }

  const removeReward = async () => {
    if (basket?.appliedRewards?.length > 0) {
      // const appliedOloReward = find(basket.appliedRewards, (r) => Number(r.externalReference) === Number(rewardId));
      const appliedOloReward = basket.appliedRewards[0]

      if (appliedOloReward) {
        const result = await removeRewardMutation({
          variables: {
            authentication: {
              loyaltyAuthenticationToken: user?.authenticationToken,
              loyaltyAccessToken: user?.accessToken,
              orderingAuthenticationToken: user?.orderingAuthenticationToken,
            },
            reward: {
              basketId: basket.id,
              oloRewardId: `${appliedOloReward.rewardId}`,
            },
          },
        })

        console.log('==========finishedRemove', JSON.stringify(result))

        if (result?.data?.removeReward) {
          // dispatch({type: 'SET_REDEMPTION', payload: {reward, validationResult: result?.data?.validateReward}});
          dispatch({ type: 'SET_BASKET', payload: result.data.removeReward })
          return true
        } else {
          dispatchMessage({
            type: 'error',
            payload: { message: text('RewardRedemptionModal.Errors.Remove') },
          })
          return false
        }
      } else {
        dispatchMessage({
          type: 'error',
          payload: { message: text('RewardRedemptionModal.Errors.RewardNotFound') },
        })
      }
    }
  }

  const redeemReward = useCallback(
    rewardId =>
      apolloClient
        .mutate({
          mutation: REWARD_REDEMPTION,
          variables: {
            redeemableId: rewardId,
            accessToken: user.accessToken,
          },
          refetchQueries:
            process.env.NODE_ENV === 'test'
              ? null
              : [
                  {
                    query: GET_LOYALTY_BALANCE,
                    variables: {
                      authentication: {
                        loyaltyAccessToken: user?.accessToken,
                        loyaltyAuthenticationToken: user?.authenticationToken,
                        orderingAuthenticationToken: user?.orderingAuthenticationToken,
                      },
                    },
                  },
                ],
          awaitRefetchQueries: true,
        })
        .then(response => response?.data?.redeemable),
    [apolloClient, user],
  )

  return {
    loading: loyaltyBalanceLoading || loyaltyProgramInfoLoading,
    program: loyaltyProgramInfoData?.loyaltyProgram,
    rewards: loyaltyBalanceData?.loyaltyBalance?.rewards || [],
    balance: loyaltyBalanceData?.loyaltyBalance?.accountBalance,
    applyReward,
    applyRewardLoading,
    removeReward,
    removeRewardLoading,
    redeemReward,
  }
}
