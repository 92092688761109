import React, { useCallback, useMemo } from 'react'
import usePlacesAutocomplete from 'use-places-autocomplete'

import { Autocomplete } from '../../../components/Autocomplete/Autocomplete'
import { Button } from '../../../components/Button/Button'
import { HANDOFF_MODES } from '../../../constants/handoffModes'
import { useSession } from '../../../contexts/session'
import { useFeatureFlags } from '../../../hooks/useFeatureFlags'
import firebase from '../../../services/firebase'
import { isRestaurantOpen } from '../../../utils/restaurant'
import { useHomeContext } from '../Home.context'
import { ContainerStyle as Container, DescriptionText, Icon } from './SearchAddressAutocomplete.styles'

const DEBOUNCE_TIMEOUT_MS = 600

const buildRequestOptions = location => {
  const requestOptions = {
    types: ['street_address', 'administrative_area_level_3', 'premise', 'locality', 'postal_code'],
    componentRestrictions: {
      country: 'us',
    },
  }

  if (location.latitude && location.longitude && window?.google?.maps?.LatLng) {
    Object.assign(requestOptions, {
      radius: 10e3,
      location: new window.google.maps.LatLng(location.latitude, location.longitude),
    })
  }
  return requestOptions
}

export const SearchAddressAutocomplete = ({ placeholder, startOrder, scheduleTime }) => {
  const { state: sessionState } = useSession()
  const { SearchByStore } = useFeatureFlags()
  const {
    addressName: inputValue,
    setSearchAddressInputRef,
    selectAddress,
    setAddressName,
    handleAddressSearchBlur,
    location,
    pickupRestaurants,
  } = useHomeContext()

  const { setValue, suggestions } = usePlacesAutocomplete({
    callbackName: 'initMap',
    debounce: DEBOUNCE_TIMEOUT_MS,
    requestOptions: buildRequestOptions(location),
  })

  const handleStartOrder = restaurant => {
    firebase.analytics().logEvent('Pickup_Location_Saved', {
      locationSelected: restaurant.name,
    })

    startOrder(restaurant)
  }

  const handleScheduleTime = restaurant => {
    firebase.analytics().logEvent('Pickup_Location_Saved', {
      locationSelected: restaurant.name,
    })

    scheduleTime(restaurant)
  }

  const filteredSuggestions = useMemo(() => {
    const data = suggestions?.data || []

    return sessionState?.handoffMode === HANDOFF_MODES.PICKUP
      ? data
      : data.filter(pred => pred.types.includes('street_address') || pred.types.includes('premise'))
  }, [suggestions.data, sessionState?.handoffMode])

  const filteredRestaurants = useMemo(() => {
    if (!SearchByStore || sessionState?.handoffMode === HANDOFF_MODES.DELIVERY) {
      return []
    }
    return inputValue.trim().length > 0
      ? pickupRestaurants?.data?.filter(({ name = '' }) => name.trim().toLowerCase().includes(inputValue.trim().toLowerCase()))
      : []
  }, [pickupRestaurants?.data, sessionState?.handoffMode, inputValue])

  const geoCodeSuggestionsWithNameMatches = useMemo(() => {
    return filteredRestaurants?.concat(filteredSuggestions)
  })

  const updateInputValue = useCallback(
    (value = '') => {
      setValue(value)
      setAddressName(value)
    },
    [setValue, setAddressName],
  )

  const onSelect = useCallback(
    async address => {
      console.debug('[SearchAddressAutocomplete] onSelect:', address)
      await selectAddress(address)
    },
    [selectAddress],
  )

  const onBlur = useCallback(
    (value = '') => {
      console.debug('[SearchAddressAutocomplete] onBlur:', value)
      handleAddressSearchBlur()
    },
    [handleAddressSearchBlur],
  )

  return (
    <Container as="div">
      <Autocomplete
        placeholder={placeholder}
        itemToString={item => item?.description}
        onSelect={onSelect}
        render={item => {
          return item.storeName && SearchByStore ? (
            <>
              <Icon />
              <DescriptionText>{item?.name}</DescriptionText>
              <div>
                <Button
                  disabled={!item.isAvailable}
                  isSmall
                  label="Start order"
                  onClick={e => {
                    e.stopPropagation()
                    isRestaurantOpen(sessionState?.handoffMode, item) ? handleStartOrder(item) : handleScheduleTime(item)
                  }}
                  variant="primary"
                />
              </div>
            </>
          ) : (
            <>
              <Icon />
              <DescriptionText>{item?.description}</DescriptionText>
            </>
          )
        }}
        inputValue={inputValue}
        items={SearchByStore ? geoCodeSuggestionsWithNameMatches : filteredSuggestions}
        inputRef={setSearchAddressInputRef}
        onBlur={onBlur}
        onChange={updateInputValue}
      />
    </Container>
  )
}
