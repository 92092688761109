import { find, isEmpty } from 'lodash'
import fp from 'lodash/fp'
import { useCallback } from 'react'

import { useSession } from '../contexts/session'
import { findMetadata } from '../data/utils'

const getModifierName = modifier => {
  return findMetadata(modifier?.metadata, 'APP_SHORT_NAME') || modifier?.description || 'Unknown Modifier'
}

const isDefined = value => {
  return ![null, '', undefined].includes(value)
}

const isToggle = ({ mandatory, ...modifier }) => {
  const controlProps = ['minSelects', 'maxSelects', 'minChoiceQuantity', 'maxChoiceQuantity', 'minAggregateQuantity', 'maxAggregateQuantity']

  const havePropsDefined = props =>
    fp.flow(
      // Check props defined
      fp.flow(fp.pick(props), fp.values, fp.map(isDefined)),
      fp.some(fp.identity),
    )

  return mandatory && !havePropsDefined(controlProps)(modifier)
}

export const useModifierValidation = () => {
  const { state, dispatch } = useSession()
  const { activeModifiers = [] } = state

  const selectOption = useCallback(
    (modifier, option, quantity = 1) => {
      const newOption = { modifier, option, quantity }

      if (isToggle(modifier)) {
        // Modifier must act as toggle, so, removing any other selections for this modifier...
        const alreadySelected = activeModifiers.find(m => m.option.id === option.id)
        const activeSelectionsFromOtherModifiers = activeModifiers.filter(m => m.modifier.id !== modifier.id)
        const payload = alreadySelected ? activeSelectionsFromOtherModifiers : [...activeSelectionsFromOtherModifiers, newOption]

        dispatch({ type: 'SET_ACTIVE_MODIFIERS', payload })
        return
      }

      const withoutOption = activeModifiers.filter(m => m.option.id !== option.id)

      if (quantity === 0) {
        dispatch({ type: 'SET_ACTIVE_MODIFIERS', payload: withoutOption })
        return
      }

      dispatch({ type: 'SET_ACTIVE_MODIFIERS', payload: [...withoutOption, newOption] })
    },
    [activeModifiers, dispatch],
  )

  const selectModifiersFromBasketProduct = useCallback(
    (menuItemModifiers, basketProduct) => {
      // take all modifiers and the modifiers from the first nested level
      // and try to find matches for what's selected in the basket

      const modifierGroups = menuItemModifiers || []

      console.log('basket choices....', basketProduct.choices)
      // console.log(modifierGroups);

      // throw new Error('asdfasdf');
      const selectedModifiers = basketProduct.choices.map(choice => {
        let selectedModifier
        console.log('finding match for choice', choice.name, choice.id)
        for (const group of modifierGroups) {
          let found = find(group.options, o => o.id === choice.optionId)
          console.log('looking in', group.description, 'and found', found?.name, found?.id)

          if (!found) {
            for (const option of group.options || []) {
              for (const nestedGroup of option.modifiers || []) {
                found = find(nestedGroup.options, o => o.id === choice.optionId)
                console.log('looking in [nested]', nestedGroup.description, 'and found', found?.name, found?.id)
                if (found) {
                  selectedModifier = {
                    modifier: nestedGroup,
                    option: found,
                    quantity: choice.quantity,
                  }
                  break
                }
              }
              if (selectedModifier) {
                break
              }
            }

            if (selectedModifier) {
              break
            }
          } else {
            selectedModifier = {
              modifier: group,
              option: found,
              quantity: choice.quantity,
            }
            break
          }
        }

        if (!selectedModifier) {
          console.error('Could not find the selected option')
        } else {
          return selectedModifier
        }
      })
      // console.log('===selectedModifiers', JSON.stringify(selectedModifiers, null, 2));
      dispatch({ type: 'SET_ACTIVE_MODIFIERS', payload: selectedModifiers })
    },
    [dispatch],
  )

  const validateModifier = useCallback(
    modifier => {
      const modifierName = getModifierName(modifier)

      if (modifier && activeModifiers.length > 0) {
        // determine if active modifier is valid
        const selections = activeModifiers.filter(m => m.modifier?.id === modifier?.id)
        const totalQuantity = selections.reduce((counter, curr) => counter + curr.quantity, 0)

        if (selections.length === 0 && modifier.mandatory) {
          return { isValid: false, message: `${modifierName} is mandatory. ${modifier?.explanationText}.` }
        }

        if (modifier.minSelects > 0 && selections.length < modifier.minSelects) {
          return { isValid: false, message: `${modifierName} has too few options selected.` }
        }

        if (modifier.maxSelects > 0 && selections.length > modifier.maxSelects) {
          return { isValid: false, message: `${modifierName} has too many options selected.` }
        }

        if (
          (modifier.minAggregateQuantity > 0 && totalQuantity < modifier.minAggregateQuantity) ||
          (modifier.maxAggregateQuantity > 0 && totalQuantity > modifier.maxAggregateQuantity)
        ) {
          return {
            isValid: false,
            message: `${modifierName} selected quantity should be between ${modifier.minAggregateQuantity} (min) and ${modifier.maxAggregateQuantity} (max)`,
          }
        }
      } else if (modifier) {
        return {
          isValid: !modifier.mandatory && modifier.minAggregateQuantity === 0,
          message: `${modifierName} is mandatory. ${modifier?.explanationText}.`,
        }
      }

      return { isValid: true, message: undefined }
    },
    [activeModifiers],
  )

  const isModifierEnabled = useCallback(
    modifier => {
      const selections = activeModifiers.filter(m => m.modifier.id === modifier.id)

      if (isEmpty(selections)) {
        return true
      }

      const totalQuantity = selections.reduce((counter, curr) => counter + curr.quantity, 0)

      if (modifier.maxAggregateQuantity > 0 && totalQuantity >= modifier.maxAggregateQuantity) {
        return false
      }

      if (modifier.maxSelects > 0 && selections.length >= modifier.maxSelects) {
        return false
      }

      return true
    },
    [activeModifiers],
  )

  return { isModifierEnabled, validateModifier, selectOption, selectModifiersFromBasketProduct, activeModifiers }
}
