import { compose, get } from 'lodash/fp'
import React from 'react'

export const findImage = (menuItem, imageType = 'mobile-app') => {
  return (menuItem?.images || []).find(i => i.groupName === imageType)?.url
}

export const findMetadata = (metadataArray, key) => {
  return (metadataArray || []).find(i => i.key === key)?.value
}

export const parseIngredients = (menuItem = {}) => {
  const { description = '' } = menuItem
  if (description.indexOf('>>') === 0) {
    const ingredients = description
      .substring(2)
      .split(',')
      .map(i => i.trim())
      .map(i => {
        const matches = [...i.matchAll(/(.*)(\{.*\})/g)][0]

        if (matches?.length === 3) {
          return { name: matches[1], attributes: [matches[2].split(';')] }
        }

        return { name: i, attributes: [] }
      })

    return ingredients
  }

  return null
}

export const formatCurrency = value => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value || 0)

/**
 * Injects a new script into the DOM with the give options
 * @param {Object} options - new scrip option attributes
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/script#attributes}
 * @returns {Promise}
 */
export const injectHeadScript = options =>
  new Promise((resolve, reject) => {
    try {
      const script = document.createElement('script')
      script.onload = resolve
      Object.assign(script, options)
      document.getElementsByTagName('head')[0].appendChild(script)
    } catch (error) {
      reject(error)
    }
  })

export const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()

const asHOC = Component => WrappedComponent => props => (
  <Component>
    <WrappedComponent {...props} />
  </Component>
)

export const composeComponent = (components = []) => compose(...components.map(asHOC))(get('children'))

export const contains = (value, array = []) => array?.indexOf(value) > -1
