/* eslint-disable eqeqeq */
import pluralize from 'pluralize'
import React, { useCallback, useMemo } from 'react'

import { findMetadata, formatCurrency } from '../../../data/utils'
import { useModifierValidation } from '../../../hooks/useModifierValidation'
import { Add, Subtract } from '../../QuantityInput/QuantityInput'
import { ColumnContainer, ModifierDescription, OptionContainer, OptionName, RowContainer, ValueDescription } from '../ModifierOptionSelector.styles'

export const ModifierOptionQuantitySelector = React.memo(({ modifier, option, ...props }) => {
  const { selectOption, activeModifiers } = useModifierValidation()
  const match = useMemo(() => activeModifiers?.find(m => m.option.id === option.id), [activeModifiers, option])
  const isActive = Boolean(match)

  const cost = useMemo(() => {
    if (!match) {
      return
    }

    return match.quantity * match.option.cost
  }, [match])

  const modifierTotalOptions = useMemo(
    () => activeModifiers.filter(mod => mod.modifier.id === modifier.id).reduce((acc, mod) => acc + mod.quantity, 0),
    [activeModifiers, modifier],
  )

  const increment = useCallback(() => {
    selectOption(modifier, option, (match?.quantity || 0) + 1)
  }, [match, modifier, option, selectOption])

  const decrement = useCallback(() => {
    selectOption(modifier, option, Math.max((match?.quantity || 0) - 1, 0))
  }, [match, modifier, option, selectOption])

  const name = findMetadata(option.metadata, 'APP_SHORT_NAME') || option.name
  const description = findMetadata(option.metadata, 'APP_DESCRIPTION')

  const maxReached = modifier.maxAggregateQuantity == modifierTotalOptions || match?.quantity == modifier.maxChoiceQuantity
  const minReached = modifier.minAggregateQuantity == modifierTotalOptions || match?.quantity == modifier.minChoiceQuantity

  const quantityType = findMetadata(option.metadata, 'APP_MODIFY_QUANTITY_TYPE')
  const quantity = match?.quantity || 0

  return (
    <OptionContainer style={{ justifyContent: 'space-between' }} {...props}>
      <ColumnContainer>
        <OptionName>
          {name} {isActive && cost > 0 && <OptionName>(+{formatCurrency(cost)})</OptionName>}
        </OptionName>
        {description && <ModifierDescription>{description}</ModifierDescription>}
      </ColumnContainer>

      <RowContainer>
        {match && (
          <>
            <Subtract onClick={decrement} disabled={minReached} />
            <ValueDescription>{quantityType ? `${quantity} ${pluralize(quantityType, quantity)}` : quantity}</ValueDescription>
          </>
        )}

        <Add onClick={increment} disabled={maxReached} />
      </RowContainer>
    </OptionContainer>
  )
})
