import React, { useMemo } from 'react'

import { useSession } from '../../contexts/session'
import { parseIngredients } from '../../data/utils'
import * as componentUtils from '../../utils/components'
import { TextSeparator } from '../TextSeparator/TextSeparator'
import { Container, ModifierText } from './IngredientsContainer.styles'

const mapModifierToIngredientComponent = modifier => (
  <ModifierText key={modifier.option.id}>
    {modifier.option.name}
    {modifier.quantity > 1 && <> ({modifier.quantity})</>}
  </ModifierText>
)

export const IngredientsContainer = ({ menuItem, ...props }) => {
  const ingredients = parseIngredients(menuItem)
  const { activeModifiers = [] } = useSession()?.state
  const renderIngredients = useMemo(() => componentUtils.transform(activeModifiers, mapModifierToIngredientComponent, TextSeparator), [
    activeModifiers,
  ])

  if (!ingredients) {
    return (
      <Container {...props}>
        <span>
          {menuItem?.description && <span>{menuItem?.description}</span>}
          {menuItem?.description && renderIngredients && <TextSeparator />}
          {renderIngredients}
        </span>
      </Container>
    )
  }

  return (
    <Container>
      {ingredients.map((ingredient, index) => (
        <>
          <span>{ingredient?.name}</span>
          {index < ingredients.length - 1 && <TextSeparator />}
        </>
      ))}
    </Container>
  )
}
