import { useApolloClient } from '@apollo/client'
import { useCallback, useEffect, useState } from 'react'

import { ACCESS_TOKEN } from '../data/order/queries'

export const useAccessToken = ({ authToken, basketId }) => {
  const [accessToken, setAccessToken] = useState(null)
  const apolloClient = useApolloClient()

  const fetchAccessToken = useCallback(() => {
    console.debug('[useAccessToken] fetching access token')
    return apolloClient
      .query({
        skip: !basketId,
        fetchPolicy: 'network-only',
        query: ACCESS_TOKEN,
        variables: { authToken, basketId },
      })
      .then(response => {
        setAccessToken(response?.data?.accessToken?.accesstoken)
      })
      .catch(console.error)
  }, [apolloClient, authToken, basketId])

  useEffect(() => {
    fetchAccessToken()
  }, [fetchAccessToken])

  return { accessToken }
}
