import React from 'react'

import { ImageBackgroundContainer } from '../ImageBackgroundContainer/ImageBackgroundContainer'
import { BrandButton, Container, InnerContainer } from './BrandContainer.styles'

export const BrandContainer = ({ brands, isVisible, onClick, ...props }) => {
  if (!isVisible) return null
  return (
    <Container as="div" {...props}>
      {brands?.map(brand => (
        <InnerContainer as="div" key={brand.key}>
          <ImageBackgroundContainer imageUrl={brand.imageUrl} />
          <BrandButton as="button" onClick={() => onClick(brand)}>
            {brand.displayName}
          </BrandButton>
        </InnerContainer>
      ))}
    </Container>
  )
}
