import React, { useCallback, useEffect } from 'react'

import { ADDRESS_FORM_MODES, AddressForm } from '../../../components/AddressForm/AddressForm'
import { DialogWithBackdrop } from '../../../components/DialogWithBackdrop/DialogWithBackdrop'
import { ModalHeader } from '../../../components/ModalHeader/ModalHeader'
import { HANDOFF_MODES } from '../../../constants/handoffModes'
import { useSession } from '../../../contexts/session'
import { useMessaging } from '../../../hooks/useMessaging'
import { useNavigation } from '../../../hooks/useNavigation'
import firebase from '../../../services/firebase'

export const DeliveryAddressDetailsModal = ({ address, dialogProps, restaurant, pickupTime }) => {
  const { dispatch, state } = useSession()
  const { dispatchMessage } = useMessaging()
  const { navigate } = useNavigation()

  useEffect(() => {
    console.debug('[DeliveryAddressDetailsModal]', { address, basketId: state?.basket?.id, defaultPhoneNumber: state?.contactInfo?.contactNumber })
  }, [address, state])

  const handleUpdateAddress = useCallback(
    async newAddress => {
      console.debug('[DeliveryAddressDetailsModal] handleUpdateAddress', { address, newAddress })

      try {
        firebase.analytics().logEvent('Save_Address_Successful', {
          zipCode: address.zipCode,
        })

        dispatch({ type: 'RESET' })
        dispatch({ type: 'SET_ACTIVE_ADDRESS', payload: newAddress })
        dispatch({
          type: 'SET_CONTACT_INFO',
          payload: {
            ...state?.contactInfo,
            contactNumber: newAddress.phoneNumber,
          },
        })
        dispatch({ type: 'SET_HANDOFF_MODE', payload: HANDOFF_MODES.DELIVERY })
        dispatch({ type: 'SET_RESTAURANT', payload: restaurant })

        if (pickupTime) {
          dispatch({ type: 'SET_PICKUP_TIME', payload: pickupTime })
        }

        dialogProps.hide()
        navigate('StartOrder')
      } catch (error) {
        dispatchMessage.error(`Failed to add address ${newAddress.nickname || newAddress.streetAddress}.`, error)
      }
    },
    [address, dispatch, state, restaurant, pickupTime, dialogProps, dispatchMessage],
  )

  return (
    <DialogWithBackdrop key={address?.streetName} dialogProps={dialogProps} ariaLabel="Add Delivery Address Details" slideInFrom="left">
      <ModalHeader title="Add Delivery Address" onClose={dialogProps?.hide} />
      <AddressForm
        address={address}
        defaultPhoneNumber={state?.contactInfo?.contactNumber}
        onSubmit={handleUpdateAddress}
        submitButtonLabel="Start Delivery Order"
        mode={ADDRESS_FORM_MODES.DELIVERY_DETAILS}
      />
    </DialogWithBackdrop>
  )
}
