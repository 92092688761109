import { useApolloClient } from '@apollo/client'
import moment from 'moment-timezone'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTheme } from 'styled-components'

import { isDeliveryMode, isSameHandoffMode } from '../constants/handoffModes'
import { useText } from '../content'
import { useAddresses } from '../contexts/addresses'
import { useSession } from '../contexts/session'
import { GET_ORDER } from '../data/order/queries'
import { injectHeadScript } from '../data/utils'
import { useAccessToken } from '../hooks/useAccessToken'
import { useValidateBasket } from '../hooks/useValidateBasket'
import { useAnalytics } from './useAnalytics'
import { useHandoffMode } from './useHandoffMode'
import { useMessaging } from './useMessaging'

export const CCSF_CREDIT_CARD_NUMBER_CLASS_NAME = 'ccsf-credit-card-number'
export const CCSF_CREDIT_CARD_CVV_CLASS_NAME = 'ccsf-credit-card-cvv'

const checkoutFrameInitOptions = theme => ({
  styles: {
    cardNumber: theme.checkoutFrame.styles.cardNumber,
    cvv: theme.checkoutFrame.styles.cvv,
  },
})

const buildCCSFSubmitInput = ({ basket, activePaymentMethod, contactInfo, accessToken, isAuthenticated }) => {
  // figure out how much they need to pay
  const fees = basket.fees.reduce((sum, fee) => sum + fee.amount, 0)
  const basketTotal = (basket.subtotal || 0) + (basket.tip || 0) + (basket.customerHandoffCharge || 0) + (basket.salesTax || 0) + fees
  const amount = basketTotal - (basket.discount || 0)
  const billingAccounts = []

  if (activePaymentMethod?.accountId) {
    billingAccounts.push({
      amount,
      billingMethod: 'billingaccount',
      billingAccountId: String(activePaymentMethod.accountId),
      tipPortion: basket.tip || 0,
    })
  } else if (activePaymentMethod.accountType === 'creditcard') {
    billingAccounts.push({
      amount,
      billingMethod: 'creditcard',
      expiryMonth: activePaymentMethod.expiryMonth,
      expiryYear: activePaymentMethod.expiryYear,
      zip: activePaymentMethod.zipCode,
      saveOnFile: !!isAuthenticated && activePaymentMethod.shouldSavePayment ? 'true' : 'false', // If a logged in user wants to save the card on file.
      tipPortion: basket.tip || 0,
    })
  } else {
    throw new Error(`Unexpected payment method ${activePaymentMethod.accountType}`)
  }

  const submitInput = {
    id: basket.id,
    accessToken,
    billingAccounts,
    firstName: contactInfo?.firstName,
    lastName: contactInfo?.lastName,
    emailAddress: contactInfo?.email,
    contactNumber: contactInfo?.contactNumber.length === 10 ? `1${contactInfo?.contactNumber}` : contactInfo?.contactNumber,
    zip: activePaymentMethod.zipCode,
  }

  if (isAuthenticated) {
    submitInput.userType = 'user'
  } else {
    submitInput.userType = 'guest'
    submitInput.guestOptIn = false
  }

  return submitInput
}

const loadCCSF = () => injectHeadScript({ src: process.env.REACT_APP_OLO_CCSF_JS_SCRIPT_SRC, type: 'text/javascript' })

export const useCCSF = () => {
  const [checkoutFrame, setCheckoutFrame] = useState(null)
  const [isReady, setReady] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)
  const { addAddress } = useAddresses()
  const { dispatch, state } = useSession()
  const { dispatchMessage } = useMessaging()
  const { text } = useText()
  const authToken = useMemo(() => state?.user?.orderingAuthenticationToken, [state?.user?.orderingAuthenticationToken])
  const basketId = useMemo(() => state?.basket?.id, [state?.basket?.id])
  const brandAccessId = useMemo(() => process.env.REACT_APP_BRAND_ACCESS_ID, [])
  const theme = useTheme()
  const { track } = useAnalytics()
  const { handoffMode } = useHandoffMode()
  const apolloClient = useApolloClient()

  const { accessToken } = useAccessToken({ authToken, basketId })
  const { validateBasket } = useValidateBasket()

  const initCCSF = useCallback(() => {
    if (!checkoutFrame) return
    checkoutFrame.initialize({
      brandAccessId,
      ...checkoutFrameInitOptions(theme),
    })
  }, [checkoutFrame, brandAccessId])

  const submitBasket = () => {
    track('Place_Order_Selected')
    setSubmitting(true)
    return validateBasket(basketId)
      .then(basket => {
        console.debug('[useCCSF] basket validated successfully:', basket)
        if (!isSameHandoffMode(basket.deliveryMode, handoffMode)) {
          dispatchMessage.error(text('Checkout.Errors.InconsistentHandoffMode'))
          return
        }
        const submitInput = buildCCSFSubmitInput({
          basket: basket,
          activePaymentMethod: state.activePaymentMethod,
          contactInfo: state.contactInfo,
          accessToken,
          isAuthenticated: !!authToken,
        })
        console.debug('[useCCSF] submitInput:', { submitInput })
        checkoutFrame.submit(submitInput)
      })
      .catch(() => setSubmitting(false))
  }

  const handleSubmitOrderError = error => {
    console.error('[useCCSF] handleSubmitOrderError:', error)
    setSubmitting(false)
    dispatchMessage.error(error?.[0]?.description || error?.message || text('Checkout.Errors.PlaceOrder'))
  }

  const handleSubmitOrderSuccess = async order => {
    console.debug('[useCCSF] handleSubmitOrderSuccess:', order)

    const { data } = await apolloClient.query({
      query: GET_ORDER,
      variables: { orderId: order.id, authenticationToken: state?.user?.authenticationToken },
      fetchPolicy: 'no-cache',
    })
    const apiOrder = data.order

    const normalizedOrder = { order: apiOrder, dateCreated: moment().format(), leadTimeEstimateMinutes: state?.basket?.leadTimeEstimateMinutes }
    dispatch({ type: 'SET_ACTIVE_ORDER', payload: normalizedOrder })

    dispatchMessage.info('Your order was submitted successfully!')
    setSubmitting(false)

    const totalProductQuantity = order?.products.reduce((sum, cur) => {
      return sum + cur.quantity
    }, 0)

    track('Place_Order_Successful', {
      promoCode: state?.basket?.coupon?.couponCode,
      amount: order?.total,
      discounts: order?.discounts?.reduce((sum, dis) => (sum += dis.amount), 0),
      quantity: totalProductQuantity,
      handoffMode: order?.deliverymode?.toUpperCase?.(),
    })

    if (isDeliveryMode(order?.deliverymode)) {
      addAddress(state.activeAddress).catch(error => {
        dispatchMessage.error(`Failed to save address`, error)
      })
    }
  }

  useEffect(() => {
    loadCCSF().then(() => {
      const checkoutFrame = new window.Olo.CheckoutFrame({
        cardElement: 'ccsf-credit-card-number',
        cvvElement: 'ccsf-credit-card-cvv',
      })

      checkoutFrame.on('success', handleSubmitOrderSuccess)
      checkoutFrame.on('error', handleSubmitOrderError)
      checkoutFrame.on('ready', () => setReady(true))

      setCheckoutFrame(checkoutFrame)
      console.debug('[useCCSF]', { authToken, checkoutFrame })
    })
  }, [])

  return { accessToken, authToken, initCCSF, isSubmitting, isReady, submitBasket }
}
