import { every } from 'lodash'
import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react'

import { useFeatureFlags } from '../../hooks/useFeatureFlags'
import { buildAddress } from '../../utils/address'
import { DoubleCTA } from '../DoubleCTA/DoubleCTA'
import { ModalBodyContainer, ModalFooterContainer } from '../ModalWithBackdrop/ModalWithBackdrop.styles'
import { TextArea } from '../TextArea/TextArea.styles'
import { TextInput } from '../TextInput/TextInput'
import { ToggleButton } from '../ToggleButton/ToggleButton'
import { Form } from './AddressForm.styles'

export const ADDRESS_FORM_MODES = Object.freeze({
  DEFAULT: 'DEFAULT',
  DELIVERY_DETAILS: 'DELIVERY_DETAILS',
})

const reducer = (state, action) => ({ ...state, [action.type]: action.payload })

export const AddressForm = ({ address, defaultPhoneNumber, extraButtons, onSubmit, submitButtonLabel, mode = ADDRESS_FORM_MODES.DEFAULT }) => {
  const [submitLoading, setSubmitLoading] = useState(false)
  const { FirebaseSupport } = useFeatureFlags()
  const [state, dispatch] = useReducer(reducer, buildAddress({ address, defaultPhoneNumber, shouldSaveAddress: !FirebaseSupport }))

  const isValid = useMemo(() => {
    const streetAddressNotEmpty = state.streetAddress?.trim().length > 0
    const phoneNumberRequired = state.phoneNumber?.trim().replace(/\D/g, '').length >= 10
    const cityRequired = state.city?.trim().length > 0
    const stateRequired = state.state?.trim().length > 0
    const zipCodeRequired = state.zipCode?.trim().length > 0
    return mode === ADDRESS_FORM_MODES.DEFAULT
      ? every([streetAddressNotEmpty, phoneNumberRequired, cityRequired, stateRequired, zipCodeRequired])
      : every([streetAddressNotEmpty, phoneNumberRequired])
  }, [state])

  const buttons = useMemo(
    () => [
      ...(extraButtons || []),
      { disabled: !isValid || submitLoading, loading: submitLoading, label: submitButtonLabel, type: 'submit', variant: 'primary' },
    ],
    [extraButtons, isValid, submitLoading],
  )

  const handleSubmit = useCallback(
    event => {
      event.preventDefault()
      setSubmitLoading(true)
      return onSubmit(state)
    },
    [state, onSubmit, setSubmitLoading],
  )

  useEffect(() => {
    console.debug('[FirebaseAddressForm] state changed:', { state, isValid, submitLoading })
  }, [state, isValid, submitLoading])

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <ModalBodyContainer>
        {FirebaseSupport && (
          <TextInput
            name="nickname"
            placeholder="Nickname (optional)"
            value={state.nickname}
            onChange={e => dispatch({ type: 'nickname', payload: e.target.value })}
          />
        )}
        <TextInput
          name="streetAddress"
          placeholder="Street Address"
          value={state.streetAddress}
          onChange={e => dispatch({ type: 'streetAddress', payload: e.target.value })}
          required
          readOnly={mode === ADDRESS_FORM_MODES.DELIVERY_DETAILS}
        />
        <TextInput
          name="aptNumber"
          value={state.aptNumber}
          placeholder="Apt Number (optional)"
          onChange={e => dispatch({ type: 'aptNumber', payload: e.target.value })}
        />
        {FirebaseSupport && mode === ADDRESS_FORM_MODES.DEFAULT && (
          <>
            <TextInput
              name="city"
              placeholder="City"
              value={state.city}
              onChange={e => dispatch({ type: 'city', payload: e.target.value })}
              required
            />
            <div style={{ display: 'flex' }}>
              <TextInput
                name="state"
                value={state.state}
                placeholder="State"
                style={{ flex: 1, marginRight: 10 }}
                onChange={e => dispatch({ type: 'state', payload: e.target.value })}
                required
              />
              <TextInput
                required
                name="zipCode"
                value={state.zipCode}
                placeholder="Postal Code"
                style={{ flex: 1.5 }}
                onChange={e => dispatch({ type: 'zipCode', payload: e.target.value })}
              />
            </div>
          </>
        )}
        <TextInput
          name="phoneNumber"
          placeholder="Phone Number"
          type="tel"
          required
          aria-required
          value={state.phoneNumber}
          onChange={e => dispatch({ type: 'phoneNumber', payload: e.target.value })}
        />
        <TextArea
          placeholder="Add note to courier here (optional)"
          rows="4"
          value={state.specialInstructions}
          onChange={e => dispatch({ type: 'specialInstructions', payload: e.target.value })}
          style={{ marginBottom: '10px' }}
        />
        {FirebaseSupport ? (
          <ToggleButton
            name="defaultAddress"
            checked={state.isDefault}
            onClick={e => dispatch({ type: 'isDefault', payload: e.target.checked })}
            style={{ marginBottom: '10px' }}
          >
            Set as default address
          </ToggleButton>
        ) : (
          <ToggleButton name="defaultAddress" checked={state.shouldSaveAddress} onClick={state.toggleSaveAddress} style={{ marginBottom: '10px' }}>
            Save Address
          </ToggleButton>
        )}
      </ModalBodyContainer>
      <ModalFooterContainer>
        <DoubleCTA buttons={buttons} stacked={false} />
      </ModalFooterContainer>
    </Form>
  )
}
