import { Dialog, DialogBackdrop } from 'reakit/Dialog'
import styled from 'styled-components'

export const FullDialogBackdrop = styled(DialogBackdrop)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;

  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 250ms ease-in-out;
  &[data-enter] {
    opacity: 1;
  }
`

export const SideBar = styled(Dialog)`
  display: flex;
  flex-direction: column;

  background: ${props => props.theme.colors.brand.white};
  max-width: 453px;
  opacity: 0;

  transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;

  /* default transition animation */
  margin: 0 0 0 auto;
  transform: translateX(100%);

  /* slide from left transition animation */
  &.slideInFromLeft {
    margin: 0 auto 0 0;
    transform: translateX(-200%);
  }

  &[data-enter] {
    opacity: 1;
    transform: translateX(0);
  }
`
