import { useCallback, useMemo } from 'react'

import { isDeliveryMode, normalizeHandoffMode } from '..//constants/handoffModes.js'
import { useText } from '../content'
import { useSession } from '../contexts/session'
import { capitalize } from '../data/utils.js'

export const useHandoffMode = () => {
  const { text } = useText()
  const {
    state: { handoffMode },
  } = useSession()

  const normalizedHandoffMode = useMemo(() => normalizeHandoffMode(handoffMode), [handoffMode])

  const displayName = useMemo(() => text(`HandoffMode.${capitalize(normalizedHandoffMode)}`), [normalizedHandoffMode])

  const isDelivery = useCallback(() => isDeliveryMode(handoffMode), [handoffMode])

  return { displayName, handoffMode, normalizedHandoffMode, isDelivery }
}
