import { ApolloProvider } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import React, { useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import { ToastContainer } from './components/ToastContainer/ToastContainer'
import AddressesProvider from './contexts/addresses'
import { ConfigProvider } from './contexts/appConfig'
import FavoritesProvider from './contexts/favorites'
import FirebaseAuthProvider from './contexts/firebaseAuth'
import { GeoLocationProvider } from './contexts/geolocation'
import { SessionProvider } from './contexts/session'
import { apolloClient } from './data/apollo'
import { composeComponent } from './data/utils'
import { Routes } from './routes/Routes'
import firebase from './services/firebase'
import Theme from './theme'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN || 'https://263704bc13ab4b678ac6698bf38dc49f@o559556.ingest.sentry.io/5781048',
  environment: process.env.REACT_APP_ENVIRONMENT_TYPE || 'development',
  integrations: [new Integrations.BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE || 1.0,
})

const WithProviders = composeComponent([
  ConfigProvider,
  GeoLocationProvider,
  SessionProvider,
  FirebaseAuthProvider,
  AddressesProvider,
  FavoritesProvider,
])

const App = () => {
  useEffect(() => {
    firebase.analytics().logEvent('Open_App')
  }, [])

  return (
    <Theme>
      <ApolloProvider client={apolloClient}>
        <WithProviders>
          <Router>
            <Routes />
          </Router>
          <ToastContainer />
        </WithProviders>
      </ApolloProvider>
    </Theme>
  )
}

export default App
