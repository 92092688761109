import { useQuery } from '@apollo/client'
import React, { useEffect } from 'react'

import { OrderHistoryEntryCard } from '../../components/OrderHistoryEntryCard/OrderHistoryEntryCard'
import { useText } from '../../content'
import { useSession } from '../../contexts/session'
import { GET_GUEST_RECENT_ORDERS } from '../../data/guest/queries'
import { GET_RESTAURANTS } from '../../data/restaurants/queries'
import firebase from '../../services/firebase'
import { ContentContainer, FormContainer, HeaderContainer, Paragraph } from './Profile.styles'

export const OrderHistoryContent = () => {
  const { text } = useText()
  const { state } = useSession()

  const { data, loading } = useQuery(GET_GUEST_RECENT_ORDERS, {
    fetchPolicy: 'network-only',
    variables: { orderingAuthenticationToken: state.user?.orderingAuthenticationToken },
  })

  const { data: restaurants, loading: loadingRestaurants } = useQuery(GET_RESTAURANTS)

  useEffect(() => {
    async function sendAnalytics() {
      const numberOfOrders = data?.orders?.length || 0

      firebase.analytics().logEvent('Profile_Order_History_Selected', {
        numberOfOrders,
      })
    }

    if (!loading && data) {
      sendAnalytics()
    }
  }, [loading, data])

  if (loading || loadingRestaurants) {
    return (
      <>
        <HeaderContainer>{text('Profile.RecentOrders.NavBar.Title')}</HeaderContainer>
        <ContentContainer>
          <Paragraph>Loading...</Paragraph>
        </ContentContainer>
      </>
    )
  }

  if (!data?.orders?.length) {
    return (
      <>
        <HeaderContainer>{text('Profile.RecentOrders.NavBar.Title')}</HeaderContainer>
        <ContentContainer>
          <Paragraph style={{ textAlign: 'center' }}>{text('Profile.OrderHistory.Empty.Message')}</Paragraph>
        </ContentContainer>
      </>
    )
  }

  return (
    <>
      <HeaderContainer>{text('Profile.RecentOrders.NavBar.Title')}</HeaderContainer>
      <ContentContainer>
        <Paragraph style={{ textAlign: 'center', marginBottom: 27 }}>Easily reorder previous items.</Paragraph>
        <FormContainer>
          {data?.orders.map(order => (
            <OrderHistoryEntryCard key={order.id} restaurants={restaurants?.restaurants} {...order} style={{ marginBottom: 12 }} />
          ))}
        </FormContainer>
      </ContentContainer>
    </>
  )
}
