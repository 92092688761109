import React, { useCallback } from 'react'

import { useText } from '../../content/index'
import { useSession } from '../../contexts/session'
import { useMessaging } from '../../hooks/useMessaging'
import { useUpdateBasketDeliveryAddress } from '../../hooks/useUpdateBasketDeliveryAddress'
import { SavedAddressModalContainer } from '../../scenes/profile/SavedAddresses/FirebaseSavedAddresses/components/SavedAddressModal.styles'
import { UpdateAddressForm } from '../AddressForm/UpdateAddressForm/UpdateAddressForm'
import { ModalHeader } from '../ModalHeader/ModalHeader'
import { ModalWithBackdrop } from '../ModalWithBackdrop/ModalWithBackdrop'

export const UpdateBasketDeliveryAddressModal = ({ address, dialogProps }) => {
  const { state, dispatch } = useSession()
  const { dispatchMessage } = useMessaging()
  const { text } = useText()

  const { updateBasketDeliveryAddress } = useUpdateBasketDeliveryAddress()

  const handleSaveAddress = useCallback(
    address => {
      console.debug('[UpdateBasketDeliveryAddressModal] updating address:', address.id)
      return updateBasketDeliveryAddress({ ...address })
        .then(() => {
          dispatch({ type: 'SET_ACTIVE_ADDRESS', payload: address })
          dispatch({
            type: 'SET_CONTACT_INFO',
            payload: {
              ...state?.contactInfo,
              contactNumber: address.phoneNumber,
            },
          })
          dispatchMessage.info('Delivery address updated successfully.')
          dialogProps.hide()
        })
        .catch(() => {
          dispatchMessage.error(text('Basket.Update.DeliveryAddress.Error'))
        })
    },
    [updateBasketDeliveryAddress, dispatch, dispatchMessage, state],
  )

  if (!dialogProps.visible) return null

  return (
    <ModalWithBackdrop dialogProps={dialogProps} ariaLabel="Change address">
      <SavedAddressModalContainer key={address?.id}>
        <ModalHeader title="Change Address" onClose={dialogProps.hide} />
        <UpdateAddressForm address={address} onSubmit={handleSaveAddress} submitButtonLabel="Update" />
      </SavedAddressModalContainer>
    </ModalWithBackdrop>
  )
}
