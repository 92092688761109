import './fgc.css'

import Color from 'color'
import { merge } from 'lodash'
import { css } from 'styled-components'

import { isDesktop, isLargeDesktop, isMobile, isTablet } from '../components/responsive/Responsive'
import { theme as defaultTheme } from './default'

const brandFonts = {
  GilroyExtraBold: 'Gilroy-Extrabold',
  GilroyBold: 'Gilroy-Bold',
  GilroySemiBold: 'Gilroy-Semibold',
  GilroyMedium: 'Gilroy-Medium',
  GTPressura: 'GT-Pressura-Pro-Mono-Bold',
}

const images = {
  checkoutRewardsSectionCurrencyIcon: require('../assets/images/fgc/fgc-rewards-checkout-currency-icon.png'),
  checkoutRewardsSectionButtonIcon: require('../assets/images/fgc/fgc-rewards-checkout-icon.png'),
}

const icons = {
  accountWhiteIcon: require('../assets/icons/fgc/account.svg'),
  addToBagIcon: require('../assets/icons/fgc/lighting.svg'),
  checkoutIcon: require('../assets/icons/fgc/lighting.svg'),
  mapMarkerIcon: require('../assets/icons/fgc/map_marker.svg'),
  myBagWhiteIcon: require('../assets/icons/fgc/my_bag.svg'),
  settings: require('../assets/icons/fgc/settings.svg'),
  orderTracking: {
    courier: {
      default: require('../assets/icons/fgc/ot-courier-default.svg'),
    },
    destination: require('../assets/icons/fgc/ot-dest.svg'),
    source: require('../assets/icons/fgc/ot-src.svg'),
  },
  size: {
    sm: 16,
    md: 24,
  },
}

const black = Color('#0A0D0D') // Almost black
const blue = Color('#34B0DF')
const gray = Color('#A2A8B0')
const green = Color('#2ED0A5')
const orange = Color('#ED5D2C')
const pink = Color('#F2A9C6')
const white = Color('#FFFFFF')
const yellow = Color('#FCCC31')

const brandColors = {
  black,
  blue,
  blue10: Color('#DEEFFF'),
  blue20: Color('#CAE5FF'),
  blue30: Color('#B8DDFF'),
  blue40: Color('#9FD1FF'),
  blue50: Color('#77BCFD'),
  blue60: Color('#489DEE'),
  blue70: Color('#2C7AC2'),
  blue80: Color('#0D5292'),
  gray,
  gray10: Color('#F4F4F4'),
  gray20: Color('#E1E1E1'),
  gray30: Color('#D3D3D3'),
  gray40: Color('#C1C1C1'),
  gray50: Color('#A8A8A8'),
  gray60: Color('#898A8A'),
  gray70: Color('#646565'),
  gray80: Color('#3B4242'),
  gray90: Color('#2B2929'), // Missing color from palette (copied from MS theme!)
  green10: Color('#EBF8F5'),
  green20: Color('#CFEFE6'),
  green30: Color('#AEE9DA'),
  green40: Color('#6ECEB5'),
  green50: green,
  green60: Color('#01845A'),
  green70: Color('#166D56'),
  green80: Color('#0F483A'),
  orange,
  orange10: Color('#FFE8E0'),
  orange20: Color('#FFBFAA'),
  orange30: Color('#FBA386'),
  orange40: Color('#F5825B'),
  orange50: Color('#ED5C2C'),
  orange60: Color('#D74514'),
  orange70: Color('#BC3609'),
  orange80: Color('#8E2906'),
  pink,
  pink10: Color('#FFF3F8'),
  pink20: Color('#FFCFE2'),
  pink30: Color('#FFBFDA'),
  pink40: Color('#F6A7C7'),
  pink50: Color('#F87BAE'),
  pink60: Color('#FC5495'),
  pink70: Color('#F34D64'),
  pink80: Color('#98023C'),
  white,
  yellow,
  yellow10: Color('#FFFBEF'),
  yellow20: Color('#FFF8E0'),
  yellow30: Color('#FFEBAB'),
  yellow40: Color('#FFDF78'),
  yellow50: Color('#FDCE07'),
  yellow60: Color('#EBB712'),
  yellow70: Color('#C59707'),
  yellow80: Color('#8C6B00'),
}

const primary = brandColors.orange50
const secondary = brandColors.white

const brand = 'fgc'

const colors = {
  brand: brandColors,
  white,
  black: Color('#000000'),
  primary,
  secondary,
  backgroundRequiredInput: brandColors.gray10,
}

const mapOverlay = {
  activeMarker: {
    fillColor: primary.hex(),
    fillOpacity: 1,
    scale: 1.75,
    // FIXME:
    path:
      'M5.8752 16C5.71718 15.9251 5.58472 15.8042 5.49467 15.6528C4.02783 13.5488 2.54994 11.4528 1.09099 9.34241C0.535343 8.5325 0.198899 7.58916 0.115198 6.60642C-0.131118 4.04642 1.23467 1.58403 3.55099 0.568027C5.79941 -0.419171 7.92309 -0.118372 9.82415 1.46083C10.3974 1.93309 10.873 2.51541 11.2236 3.17438C11.5742 3.83334 11.793 4.55598 11.8673 5.30082C12.0563 6.79713 11.6695 8.30973 10.7873 9.52481C9.3673 11.5515 7.94625 13.5819 6.52415 15.616C6.4317 15.7826 6.29121 15.9166 6.12151 16H5.8752ZM7.9673 6.02242C7.96881 5.62859 7.85535 5.24311 7.64121 4.91446C7.42706 4.58581 7.12179 4.32867 6.7638 4.17538C6.4058 4.02209 6.01107 3.97949 5.62925 4.05295C5.24742 4.1264 4.89556 4.31263 4.61792 4.58821C4.34027 4.86379 4.14925 5.21642 4.06887 5.60173C3.98849 5.98704 4.02235 6.38783 4.16618 6.7537C4.31002 7.11956 4.5574 7.43415 4.87723 7.65791C5.19705 7.88166 5.57503 8.00458 5.96362 8.01121C6.22417 8.01438 6.48277 7.96541 6.7246 7.86709C6.96643 7.76878 7.18674 7.62307 7.37288 7.43831C7.55903 7.25354 7.70736 7.03337 7.80936 6.7904C7.91136 6.54743 7.96504 6.28645 7.9673 6.02242Z',
  },
  inactiveMarker: {
    fillColor: brandColors.black.hex(),
    fillOpacity: 1,
    scale: 1.75,
    // FIXME:
    path:
      'M5.8752 16C5.71718 15.9251 5.58472 15.8042 5.49467 15.6528C4.02783 13.5488 2.54994 11.4528 1.09099 9.34241C0.535343 8.5325 0.198899 7.58916 0.115198 6.60642C-0.131118 4.04642 1.23467 1.58403 3.55099 0.568027C5.79941 -0.419171 7.92309 -0.118372 9.82415 1.46083C10.3974 1.93309 10.873 2.51541 11.2236 3.17438C11.5742 3.83334 11.793 4.55598 11.8673 5.30082C12.0563 6.79713 11.6695 8.30973 10.7873 9.52481C9.3673 11.5515 7.94625 13.5819 6.52415 15.616C6.4317 15.7826 6.29121 15.9166 6.12151 16H5.8752ZM7.9673 6.02242C7.96881 5.62859 7.85535 5.24311 7.64121 4.91446C7.42706 4.58581 7.12179 4.32867 6.7638 4.17538C6.4058 4.02209 6.01107 3.97949 5.62925 4.05295C5.24742 4.1264 4.89556 4.31263 4.61792 4.58821C4.34027 4.86379 4.14925 5.21642 4.06887 5.60173C3.98849 5.98704 4.02235 6.38783 4.16618 6.7537C4.31002 7.11956 4.5574 7.43415 4.87723 7.65791C5.19705 7.88166 5.57503 8.00458 5.96362 8.01121C6.22417 8.01438 6.48277 7.96541 6.7246 7.86709C6.96643 7.76878 7.18674 7.62307 7.37288 7.43831C7.55903 7.25354 7.70736 7.03337 7.80936 6.7904C7.91136 6.54743 7.96504 6.28645 7.9673 6.02242Z',
  },
}

const borderRadii = {
  sm: '3px',
  round: '100px',
}

const borderSizes = {
  xs: '1px',
  sm: '1.5px',
  md: '3px',
  lg: '5px',
}

const borders = {
  dashed: `${borderSizes.sm} dashed ${brandColors.gray50.hex()}`,
  light: `${borderSizes.sm} solid ${brandColors.gray30.hex()}`,
  lighter: `${borderSizes.sm} solid ${brandColors.gray10.hex()}`,
  lightHighlight: `${borderSizes.sm} solid ${brandColors.black.hex()}`,
  normal: `${borderSizes.xs} solid ${brandColors.black.hex()}`,
  white: `${borderSizes.sm} solid ${brandColors.white.hex()}`,
}

const primaryHover = Color('#DC6C53') // This one is missing from color palette!

const fontSizes = {
  xxs: '12px',
  xs: '14px',
  sm: '16px',
  md: '18px',
  lg: '20px',
  xl: '24px',
  xxl: '32px',
}

const font = {
  styles: {
    regular: brandFonts.GilroyMedium,
    bold: brandFonts.GilroyExtraBold,
    numbers: brandFonts.GilroyMedium,
    h1: css`
      font-family: ${brandFonts.GilroyBold};
      font-size: ${fontSizes.xxl};
      font-weight: 800;
      letter-spacing: 0.01em;
      line-height: 125%;
    `,
    h2: css`
      font-family: ${brandFonts.GilroyBold};
      font-size: ${fontSizes.xl};
    `,
    h3: css`
      font-family: ${brandFonts.GilroyBold};
      font-size: ${fontSizes.lg};
    `,
    description: css`
      font-family: ${brandFonts.GilroyMedium};
      font-size: ${fontSizes.sm};
      font-weight: 500;
      line-height: 130%;
    `,
    descriptionExtraSmall: css`
      font-family: ${brandFonts.GilroyMedium};
      font-size: ${fontSizes.xs};
      font-weight: 500;
      line-height: 143%;
      letter-spacing: 0.02em;
    `,
    descriptionLarge: css`
      font-family: ${brandFonts.GilroyMedium};
      font-size: ${fontSizes.lg};
      font-weight: 800;
      line-height: 120%;
      letter-spacing: 0.02em;
    `,
    cta: css`
      font-family: ${brandFonts.GTPressura};
      font-size: ${fontSizes.sm};
      font-weight: bold;
      letter-spacing: 0.06em;
      line-height: 125%;
      text-transform: uppercase;
    `,
    ctaSmall: css`
      font-family: ${brandFonts.GTPressura};
      font-size: ${fontSizes.xs};
      font-weight: bold;
      letter-spacing: 0.06em;
      line-height: 143%;
      text-transform: uppercase;
    `,
    label: css`
      font-family: ${brandFonts.GTPressura};
      font-size: ${fontSizes.sm};
      font-weight: bold;
      letter-spacing: 0.06em;
      line-height: 150%;
      text-transform: uppercase;
    `,
    labelSpecial: css`
      font-family: ${brandFonts.GTPressura};
      font-size: ${fontSizes.xs};
      font-weight: bold;
      letter-spacing: 0.06em;
      line-height: 143%;
      text-transform: uppercase;
    `,
    labelExtraSmall: css`
      font-family: ${brandFonts.GilroyBold};
      font-size: ${fontSizes.xs};
      font-weight: 800;
      letter-spacing: 0.08em;
      line-height: 114%;
      text-transform: uppercase;
    `,
    labelSmall: css`
      font-family: ${brandFonts.GilroyBold};
      font-size: ${fontSizes.sm};
      font-weight: 800;
      letter-spacing: 0.08em;
      line-height: 150%;
      text-transform: uppercase;
    `,
    labelMedium: css`
      font-family: ${brandFonts.GTPressura};
      font-size: ${fontSizes.md};
      font-weight: bold;
      letter-spacing: 0.06em;
      line-height: 111%;
      text-transform: uppercase;
    `,
    inputDefault: css`
      font-family: ${brandFonts.GilroyMedium};
      font-size: ${fontSizes.sm};
      font-weight: 500;
      letter-spacing: 0.02em;
      line-height: 125%;
    `,
    inputExtraSmall: css`
      font-family: ${brandFonts.GilroyMedium};
      font-size: ${fontSizes.sm};
      font-weight: 500;
      letter-spacing: 0.02em;
      line-height: 125%;
    `,
    labelInput: css`
      font-family: ${brandFonts.GilroyMedium};
      font-size: ${fontSizes.sm};
    `,
    labelInputBold: css`
      font-family: ${brandFonts.GilroyMedium};
      font-size: ${fontSizes.sm};
      font-weight: 600;
      letter-spacing: 0.02em;
      line-height: 150%;
    `,
    labelInputSmall: css`
      font-family: ${brandFonts.GilroyMedium};
      font-size: ${fontSizes.xs};
    `,
    labelInputExtraSmall: css`
      font-family: ${brandFonts.GilroyMedium};
      font-size: ${fontSizes.xxs};
      font-weight: 600;
      letter-spacing: 0.02em;
      line-height: 133%;
    `,
    labelInputError: css`
      color: ${brandColors.orange50};
      font-family: ${brandFonts.GilroyMedium};
      font-size: ${fontSizes.xxs};
    `,
    labelCurrency: css`
      font-family: ${brandFonts.GilroyMedium};
      font-size: ${fontSizes.sm};
      font-weight: 600;
      line-height: 120%;
    `,
    labelCurrencySmall: css`
      color: ${brandColors.gray70};
      font-family: ${brandFonts.GilroyMedium};
      font-size: ${fontSizes.xxs};
    `,
    labelCurrencyLarge: css`
      color: ${brandColors.gray70};
      font-family: ${brandFonts.GilroyMedium};
      font-size: ${fontSizes.lg};
      font-weight: 800;
      letter-spacing: 0.02em;
      line-height: 120%;
    `,
  },
  sizes: fontSizes,
}

const buttons = {
  buttonContainer: {
    primary: css`
      background-color: ${primary.hex()};
      border: ${borderSizes.xs} solid ${primary.hex()};
      border-radius: ${borderRadii.sm};
      color: ${brandColors.white.hex()};
      &:hover {
        background-color: ${primaryHover.hex()};
        border-color: ${primaryHover.hex()};
      }
    `,
    secondary: css`
      background-color: ${secondary.hex()};
      border: ${borderSizes.xs} solid ${brandColors.black.hex()};
      border-radius: ${borderRadii.sm};
      color: ${brandColors.black.hex()};
      &:hover {
        background-color: ${brandColors.black.hex()};
        border-color: ${brandColors.black.hex()};
        color: ${brandColors.white.hex()};
      }
    `,
  },
  roundContainer: {
    primary: css`
      background-color: ${brandColors.white.hex()};
      border: ${borderSizes.xs} solid ${brandColors.gray30.hex()};
      color: ${brandColors.black.hex()};
      &:hover {
        border-color: ${primaryHover.hex()};
        color: ${primaryHover.hex()};
      }
    `,
    secondary: css`
      background-color: ${brandColors.white.hex()};
      border: ${borderSizes.xs} solid ${brandColors.black.hex()};
      color: ${brandColors.black.hex()};
      &:hover {
        background-color: ${brandColors.black.hex()};
        border-color: ${brandColors.white.hex()};
        color: ${brandColors.white.hex()};
      }
    `,
  },
  small: css`
    ${font.styles.ctaSmall};
    padding: 10px 16px;
  `,
  modalHeaderCloseButtonVariant: 'secondary',
}

const changeAddressChip = {
  outerContainerStyle: css``,
  innerContainerStyle: css`
    background: ${brandColors.black.hex()};
    border: 2px solid ${brandColors.white.hex()};
    border-radius: ${borderRadii.round};
    color: ${brandColors.white.hex()};
    font-family: ${brandFonts.GTPressura};
    font-size: ${font.sizes.xs};
    font-style: bold;
    letter-spacing: 0.06em;
    margin: 0 16px;
    line-height: ${font.sizes.lg};
    text-transform: uppercase;
    &:hover {
      background-color: ${brandColors.black.hex()};
      border: ${borders.white};
      color: ${brandColors.white.hex()};
    }
  `,
}

const header = {
  styles: {
    backgroundColor: brandColors.black,
    linkColor: brandColors.white,
    brandLogo: {
      container: `
        &:after {
          content: '';
          border: 1px solid  ${brandColors.white.hex()};
          height: 100%;
          margin-left: 26px;
        }
      `,
    },
  },
}

const home = {
  heroAsset: 'HomeHero',
  sectionHeaderBackground: brandColors.white.hex(),
  styles: {
    headerText: css`
      ${font.styles.description}
      color: ${brandColors.black}
    `,
    sectionH1: css`
      ${isMobile`
        font-size: ${fontSizes.xl};
        line-height: 28px;
      `}
      ${isTablet`
        font-size: ${fontSizes.xl};
        line-height: 28px;
      `}
      ${isDesktop`
        font-size: ${fontSizes.xxl};
        line-height: 40px;
      `}
      ${isLargeDesktop`
        font-size: ${fontSizes.xxl};
        line-height: 40px;
      `}
    `,
    sectionHeaderContainer: css`
      ${isMobile`
        padding: 36px 16px;
      `}
      ${isTablet`
        padding: 36px 24px;
      `}
      ${isDesktop`
        padding: 48px 48px 12px;
      `}
      ${isLargeDesktop`
        padding: 48px 48px 12px;
      `}
    `,
    sectionBody: css`
      ${isMobile`
        padding: 36px 16px;
      `}
      ${isTablet`
        padding: 36px 24px;
      `}
      ${isDesktop`
        padding: 12px 48px 48px;
      `}
      ${isLargeDesktop`
        padding: 12px 48px 48px;
      `}
    `,
    viewLocationsButton: css`
      font-family: ${brandFonts.GilroyMedium};
      font-size: ${fontSizes.sm};
      font-weight: 500;
      line-height: 130%;
      text-decoration: underline;
      text-transform: unset;
    `,
  },
}

const toggles = {
  active: {
    border: `${borderSizes.xs} solid ${brandColors.black.hex()}`,
    backgroundColor: brandColors.black.hex(),
    textColor: brandColors.white.hex(),
  },
  inactive: {
    border: `${borderSizes.xs} solid ${brandColors.gray30.hex()}`,
    backgroundColor: brandColors.white.hex(),
    textColor: brandColors.black.hex(),
  },
  styles: {
    active: css`
      background-color: ${brandColors.black.hex()};
      color: ${brandColors.white.hex()};
      border: ${borders.normal};
    `,
    inactive: css`
      background-color: ${brandColors.white.hex()};
      color: ${brandColors.black.hex()};
      border: ${borders.normal};
    `,
  },
}

const items = {
  selected: {
    background: brandColors.gray10.hex(),
  },
  highlighted: {
    background: brandColors.gray20.hex(),
  },
  hover: {
    background: brandColors.gray20.hex(),
  },
}

const toast = css`
  .Toastify__toast-body {
    display: flex;
    justify-content: center;

    font-family: ${brandFonts.GilroyMedium};
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.02em;
  }

  .Toastify__close-button {
    display: flex;
    margin: auto 0;
  }

  .Toastify__toast--info {
    background: ${brandColors.pink40};
    color: ${brandColors.black};
  }
  .Toastify__toast--info > .Toastify__close-button {
    color: ${brandColors.black};
  }

  .Toastify__toast--error {
    background: ${brandColors.orange60};
    color: ${brandColors.white};
  }
  .Toastify__toast--error > .Toastify__close-button {
    color: ${brandColors.white};
  }
`
const baseCheckoutFrameStyleCSS = `
  border-bottom-style: none;
  border-left-style: none;
  border-radius: unset;
  border-right-style: none;
  border-top-style: none;
  padding: 24px 16px;
  font-size: 17px;
  font-weight: normal;
  width: 100%;
`

const checkoutFrame = {
  styles: {
    cardNumber: baseCheckoutFrameStyleCSS,
    cvv: baseCheckoutFrameStyleCSS,
  },
}

const orderTracking = {
  closeButtonVariant: 'secondary',
  foregroundImage: {
    style: `
      position: absolute;
      right: 0;
      bottom: -4px;
    `,
  },
  header: {
    container: {
      style: `
        background-color: ${brandColors.pink};
        border-bottom: 4px solid black;
        color: ${brandColors.black};
        padding: 32px 16px;
        position: relative;
        order: -1;
      `,
    },
    title: {
      style: `
        font-family: ${brandFonts.GilroyExtraBold};
        font-size: ${fontSizes.xl};
        letter-spacing: 0.02em;
        line-height: 117%;
        margin-bottom: 8px;
      `,
    },
    text: {
      style: `
        font-size: ${fontSizes.sm};
        line-height: 120%;
      `,
    },
  },
  section: {
    style: `
      font-family: ${brandFonts.GilroyExtraBold};
      font-size: ${fontSizes.xl};
      letter-spacing: 0.02em;
      line-height: 117%;
    `,
    body: {
      style: `
      `,
    },
    title: {
      style: `
        font-size: 26px;
        margin-bottom: 8px;
      `,
    },
  },
  aside: {
    style: `
      background-color: #efefef8c;
    `,
  },
  map: {
    container: {
      style: `
        padding: 0;
      `,
    },
    showInfoBox: true,
  },
  summary: {
    container: {
      style: `
        border: none;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
        hr {
          border-top: 1px solid #e8e8e8;
        }
      `,
    },
  },
  progressBar: {
    colors: {
      active: primary,
      inactive: '#C4C4C4',
    },
    container: {
      style: `
        margin-bottom: 16px;
      `,
    },
    fillContainer: {
      style: `
      `,
    },
    iconContainer: {
      style: `
      `,
    },
  },
}

const map = {
  infoBox: {
    container: {
      style: `
          background-color: ${colors.primary};
          border-radius: 2px;
          border: ${borders.white};
          box-sizing: border-box;
          color: ${colors.secondary};
          font-family: ${font.styles.bold};
          font-size: ${font.sizes.sm};
          padding: 4px 6px;
          text-transform: uppercase;
          white-space: nowrap;
        `,
    },
  },
}

export const theme = merge({}, defaultTheme, {
  borderRadii,
  borders,
  borderSizes,
  brand,
  buttons,
  changeAddressChip,
  checkoutFrame,
  colors,
  font,
  header,
  home,
  icons,
  items,
  map,
  mapOverlay,
  toast,
  toggles,
  profile: {
    header: css`
      color: ${black};
      margin-bottom: 27px;
      border-top: 3px solid black;
      border-bottom: 3px solid black;
      background-color: #2cb2e4;
    `,
    navBar: {
      navItem: css`
        border-radius: 100px;
      `,
      textColor: black,
      selectedItemTextDecoration: 'none',
      selectedItemTextColor: white,
      selectedItemBackgroundColor: 'black',
    },
    orderHistory: {
      topBackgroundColor: brandColors.pink30,
    },
    dietaryRestrictions: {
      topBackgroundColor: brandColors.blue50,
    },
    contactInfo: {
      topBackgroundColor: brandColors.blue50,
    },
  },
  categoryLanding: {
    bodyPadding: '16px',
    itemMargin: '5px',
    displayMeta: false,
    displayTriangles: false,
    imageWidth: '80%',
    imageHeight: '60%',
    imageResizeMode: 'contain',
    border: `2px solid ${Color(black).hex()}`,
    borderRadius: '3px',
    tileBackgroundColor: brandColors.gray10,
  },
  menuContext: {
    backgroundColor: Color(black),
    iconBackgroundColor: white,
    iconColor: brandColors.gray90,
  },
  handoffSelector: {
    backgroundColor: Color(black),
    color: Color(white),
    border: 'none',
    fontFamily: brandFonts.GTPressura,
    textTransform: 'uppercase',
    secondary: {
      backgroundColor: Color(white),
      color: Color(black),
      border: 'none',
      fontFamily: brandFonts.GTPressura,
      textTransform: 'uppercase',
    },
  },
  menuCategoryBar: css`
    color: ${black};
    border-top: 3px solid black;
    border-bottom: 3px solid black;
    background-color: ${brandColors.white};
  `,
  menuCategoryBarButton: css`
    margin-top: 4px;
    margin-bottom: 4px;
    padding: 10px 20px;
    border-radius: 100px;
    color: ${brandColors.black};
    background-color: ${brandColors.white};
    &:hover {
      color: ${brandColors.white};
      background-color: ${brandColors.black};
    }
  `,
  menuCategoryBarButtonSelected: css`
    color: ${brandColors.white};
    background-color: ${brandColors.black};
  `,
  menuItems: {
    controlContainerStyle: css``,
    dietaryRestrictions: {
      warningColor: brandColors.orange50,
    },
    totalMetaStyle: css`
      font-family: ${brandFonts.GilroyMedium};
      font-size: ${fontSizes.lg};
      font-weight: bold;
      line-height: 130%;
      letter-spacing: 0.06em;
    `,
    totalTextStyle: css`
      font-family: ${brandFonts.GilroyBold};
      font-size: ${fontSizes.sm};
      font-weight: 600;
      line-height: 120%;
    `,
    simpleBuilder: {
      modifierTextColor: primary.hex(),
      infoContainerStyle: css`
        /*border-top: ${borderSizes.lg} solid ${brandColors.black.hex()};*/
        color: ${brandColors.black.hex()};
        /* Desktop, Large Desktop and Tablet */
        padding: 24px 0;
        /* ${isMobile`
          padding: 24px 16px;
        `} */
      `,
    },
    cards: {
      style: css`
        background: ${brandColors.white};
        border: 2.5px solid ${brandColors.black};
        border-radius: 2px 2px 2px 20px;
        &:hover {
          border-color: ${props => props.theme.colors.primary.hex()};
        }
      `,
      descriptionHeaderStyle: css`
        color: ${brandColors.gray90};
        font-family: ${brandFonts.GilroyMedium};
        font-size: '16px';
        font-size: 20px;
        font-weight: 800;
        letter-spacing: 0.02em;
        line-height: 24px;
      `,
      descriptionTextStyle: css`
        font-family: ${brandFonts.GilroyMedium};
        font-weight: normal;
        font-size: 14px;
        line-height: 130%;
        color: ${brandColors.gray70.hex()};
        font-weight: 500;
      `,
      imageHeight: '220px',
      imageResizeMode: 'cover',
      numberTextStyle: css`
        color: ${brandColors.black.hex()};
        font-family: ${brandFonts.GilroyMedium};
        font-size: ${fontSizes.xs};
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
      `,
    },
    customBuilder: {
      asideContainer: css`
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
      `,
      asideBodyContainer: css`
        /* Desktop, Large Desktop */
        margin: 32px 40px 0;

        ${isTablet`
          margin: 32px 16px 0;
        `}
      `,
      asideMetaContainer: css`
        border-bottom: 1.5px solid ${brandColors.black.hex()};
      `,
      modifierGroupsContainer: css`
        background-color: ${pink.hex()};
      `,
      modifierGroups: {
        active: {
          backgroundColor: brandColors.black.hex(),
          textColor: brandColors.white.hex(),
          border: 'none',
          borderRadius: '100px',
          textTransform: 'uppercase',
          padding: '12px 32px',
          margin: '0 32px 0 0',
        },
        inactive: {
          backgroundColor: 'transparent',
          textColor: brandColors.black.hex(),
          textColorDisabled: brandColors.gray70.hex(),
          border: 'none',
          borderRadius: '0',
          textTransform: 'uppercase',
          padding: '12px 32px',
          margin: '0 32px 0 0',
        },
      },
      optionCards: {
        border: `1.5px solid ${brandColors.gray30.hex()}`,
        borderActive: `1.5px solid ${brandColors.black.hex()}`,
        quantityButtonBorder: `1px solid ${brandColors.black.hex()}`,
        quantityButtonBorderDisabled: `1px solid ${brandColors.gray30.hex()}`,
        quantityButtonColor: brandColors.black.hex(),
        quantityButtonColorDisabled: brandColors.gray30.hex(),
      },
    },
  },
  basket: {
    header: css`
      background-color: #2cb2e4;
      border-bottom: 3px solid ${brandColors.black};
    `,
    topBackgroundColor: '#2CB2E4',
    topTextColor: brandColors.black,
    backButtonVariant: 'secondary',
    bagItemsCount: css`
      font-family: ${brandFonts.GilroyBold};
      font-size: 8px;
      font-weight: normal;
      letter-spacing: 0.08em;
      line-height: 200%;
      text-transform: uppercase;
    `,
    basketCardItemName: css`
      ${font.styles.labelInput};
      font-weight: 800;
    `,
    summaryContainer: css`
      background: ${brandColors.transparent};
    `,
    costItemLabel: css`
      border-bottom: 1px solid ${brandColors.gray30};
    `,
    costsNumberSubtotal: css`
      ${font.styles.description};
    `,
    costsLabel: css`
      ${font.styles.labelSmall};
    `,
    costsNumber: css`
      ${font.styles.labelSmall};
    `,
    upsellSection: css`
      background-color: ${brandColors.gray10};
      margin: 48px 0 0;
      padding: 32px;
    `,
    upsellCarousel: css`
      background-color: 'transparent';
    `,
    upsellTitle: css`
      ${font.styles.labelSmall};
    `,
    upsellCarouselCard: css`
      border-radius: 3px;
      background-color: ${brandColors.white};
      border: 1.5px solid ${brandColors.black};
    `,
    upsellCarouselCardItemName: css`
      ${font.styles.labelInput};
      font-weight: 800;
    `,
  },
  checkout: {
    statusBarStyle: 'light-content',
    topBackgroundColor: brandColors.black,
    topTextColor: Color(black),
    backButtonVariant: 'secondary',
    sectionLabel: css`
      ${font.styles.description};
      font-weight: 400;
      margin: 48px 0 14px 0;
      text-transform: uppercase;
    `,
    summary: {
      productName: css`
        ${font.styles.labelInput};
      `,
      costsLabel: css`
        ${font.styles.labelInput};
      `,
      costsNumber: css`
        ${font.styles.labelInput};
        font-size: 24px;
      `,
    },
    rewards: {
      currencyButtonIcon: images.checkoutRewardsSectionCurrencyIcon,
      sectionButtonIcon: images.checkoutRewardsSectionButtonIcon,
    },
  },
  modals: {
    header: css`
      background-color: #2cb2e4;
      border-bottom: 3px solid ${brandColors.black};
      padding: 24px 20px;
    `,
  },
  orderTracking,
})
