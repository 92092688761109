import styled from 'styled-components'

import { isMobile } from '../responsive/Responsive'

export const Page = styled.div`
  background: ${props => props.theme.colors.brand.white};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  z-index: 999;
`
export const Content = styled.div`
  background-color: ${({ theme }) => theme.basket.upsellCarousel.backgroundColor};
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const Header = styled.div`
  ${({ theme }) => theme.basket.header};

  display: flex;
  align-items: center;

  /* background-color: ${({ theme }) => theme.basket.topBackgroundColor};
  border-bottom: ${({ theme }) => theme.basket.topBorder}; */
  padding: 24px 32px;
`

export const CloseButtonContainer = styled.div`
  margin-right: 20px;
`

export const Body = styled.ul`
  list-style: none;
  margin: 16px 32px 0 32px;
`

export const Heading = styled.p`
  ${({ theme }) => theme.font.styles.h2}
  color: ${({ theme }) => theme.basket.topTextColor};
  /* font-size: 26px; */
`

export const SelectedItemsLabel = styled.h2`
  ${({ theme }) => theme.font.styles.labelInput};
  color: ${({ theme }) => theme.colors.brand.black};
  font-size: 14px;
  margin: 32px 0 0 32px;
  text-transform: uppercase;
`
export const Number = styled.span`
  font-family: ${({ theme }) => theme.font.styles.numbers};
`

export const NumberTotal = styled.p`
  font-family: ${({ theme }) => theme.font.styles.numbers};
  color: ${({ theme }) => theme.basket.topTextColor};
  font-size: 24px;
`

export const FixedBottomActions = styled.div`
  background-color: white;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.08);
  padding: 32px;

  ${isMobile`
    padding: 16px;
  `}
`

export const SummaryContainer = styled.div`
  ${({ theme }) => theme.basket.summaryContainer}
`
export const CostItem = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  margin: 16px 32px;
  padding: 16px 0;

  ${({ theme }) => theme.basket.costItemLabel};

  & + & {
    padding-top: 0;
  }
`

export const CostsLabelSubtotal = styled.p`
  ${({ theme }) => theme.font.styles.description}
  /* color: ${({ isPromo, theme }) => (isPromo ? theme.colors.brand.orange80 : theme.colors.brand.black)}; */
  color: ${({ theme }) => theme.colors.brand.gray70};
`

export const CostsNumberSubtotal = styled.p`
  ${({ theme }) => theme.basket.costsNumberSubtotal};
  /* color: ${({ isPromo, theme }) => (isPromo ? theme.colors.brand.orange80 : theme.colors.brand.black)}; */
  color: ${({ theme }) => theme.colors.brand.gray70};
  /* font-family: ${({ theme }) => theme.font.styles.numbers}; */
  font-size: 16px;
`

export const CostsLabel = styled.p`
  ${({ theme }) => theme.basket.costsLabel};
  /* color: ${({ isPromo, theme }) => (isPromo ? theme.colors.brand.orange80 : theme.colors.brand.black)}; */
  /* ${({ theme }) => theme.font.styles.label}
  color: ${({ theme }) => theme.colors.brand.black}; */
  /* font-size: 20px; */
`

export const CostsNumber = styled.p`
  ${({ theme }) => theme.basket.costsNumber};
  /* color: ${({ isPromo, theme }) => (isPromo ? theme.colors.brand.orange80 : theme.colors.brand.black)}; */
  /* color: ${({ theme }) => theme.colors.brand.black};
  font-family: ${({ theme }) => theme.font.styles.numbers};
  font-size: 24px; */
`

export const EmptyStateContainer = styled.div`
  height: 100%;
`

export const EmptyStateContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;
`

export const EmptyStateTitle = styled.p`
  ${({ theme }) => theme.font.styles.label}
  color: ${({ theme }) => theme.colors.brand.black};
  font-size: 32px;
  text-transform: uppercase;
`

export const UpsellSection = styled.section`
  ${({ theme }) => theme.basket.upsellSection};
`

export const UpsellCarouselContainer = styled.div`
  ${({ theme }) => theme.basket.upsellCarousel};
`

export const UpsellTitle = styled.h3`
  ${({ theme }) => theme.basket.upsellTitle};
  /* ${({ theme }) => theme.font.styles.h3}
  font-size: 20px; */
  margin-bottom: 16px;
  text-transform: uppercase;
`

export const EmptyStateSubTitle = styled.p`
  ${({ theme }) => theme.font.styles.labelInput}
  color: ${({ theme }) => theme.colors.brand.black};
  margin-top: 16px;
`

export const EmptyStateButtonContainer = styled.div`
  margin-top: 48px;
  padding: 0 32px;
  width: 100%;
`
