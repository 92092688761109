import styled, { css } from 'styled-components'

export const IconImage = styled.img`
  cursor: pointer;
  ${props =>
    props.size &&
    css`
      height: ${props => props.size};
      width: ${props => props.size};
    `}
`

export const IoniContainer = styled.div`
  color: ${props => props.theme.colors.brand.yellow50};
  display: flex;
  ${props =>
    props.size &&
    css`
      > svg {
        height: ${props => props.size};
        width: ${props => props.size};
      }
    `}
`
