import { useApolloClient } from '@apollo/client'
import { isEqual, pick } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Button } from '../../components/Button/Button'
import { TextInput } from '../../components/TextInput/TextInput'
import { useText } from '../../content'
import { useSession } from '../../contexts/session'
import { UPDATE_GUEST } from '../../data/guest/queries'
import { CHANGE_PASSWORD } from '../../data/loyalty/queries'
import { useMessaging } from '../../hooks/useMessaging'
import firebase, { auth } from '../../services/firebase'
import { ToggleButton } from '../auth/components/ToggleButton'
import { ContentContainer, FormContainer, HeaderContainer, Paragraph } from './Profile.styles'

export const ContactInfoContent = () => {
  const { state: sessionState, dispatch } = useSession()
  const { user } = sessionState
  const { text } = useText()

  const { dispatchMessage } = useMessaging()
  const apolloClient = useApolloClient()

  const [loading, setLoading] = useState(false)
  const [firstName, setFirstName] = useState(user.firstName)
  const [lastName, setLastName] = useState(user.lastName)
  const [email, setEmail] = useState(user.email)
  const [phone, setPhone] = useState(user.phone)
  const [marketingEmailSubscription, setMarketingEmailSubscription] = useState(user.marketingEmailSubscription)

  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')

  useEffect(() => {
    firebase.analytics().logEvent('Save_Contact_Info_Selected')
  }, [])

  const save = useCallback(async () => {
    setLoading(true)

    try {
      const guest = {
        firstName,
        lastName,
        email,
        phone,
        birthday: user.birthday,
        receiveMarketingEmails: marketingEmailSubscription,
        pushNotificationToken: null,
      }

      const response = await apolloClient.mutate({
        mutation: UPDATE_GUEST,
        variables: { authenticationToken: user.authenticationToken, guest },
      })

      dispatch({ type: 'SET_USER', payload: response?.data?.updateGuest })

      if (password) {
        await apolloClient.mutate({
          mutation: CHANGE_PASSWORD,
          variables: { authenticationToken: user.authenticationToken, password, passwordConfirmation },
        })
      }

      firebase.analytics().logEvent('Save_Contact_Info_Successful')

      dispatchMessage({ type: 'info', payload: { message: 'Contact info updated.' } })
    } catch (error) {
      dispatchMessage({ err: error, type: 'error', payload: { message: `An error ocurred while updating your contact info.\n${error.message}` } })
    } finally {
      setLoading(false)
    }
  }, [firstName, lastName, email, phone, user.birthday, marketingEmailSubscription, user.authenticationToken, passwordConfirmation, password])

  const formNotDirty = useMemo(() => {
    const formFields = { firstName, lastName, email, phone, marketingEmailSubscription, password, passwordConfirmation }
    return isEqual(formFields, { ...pick(user, Object.keys(formFields)), password: '', passwordConfirmation: '' })
  }, [firstName, lastName, email, phone, marketingEmailSubscription, password, passwordConfirmation])

  const logout = useCallback(() => {
    if (auth?.currentUser) {
      auth.signOut()
    }
    dispatch({ type: 'SIGN_OUT' })
  }, [auth?.currentUser, dispatch])

  return (
    <>
      <HeaderContainer>{text('Profile.Contact.NavBar.Title')}</HeaderContainer>

      <ContentContainer>
        <Paragraph style={{ marginTop: 32, marginBottom: 48, textAlign: 'center' }}>
          Hey {user.firstName}, manage your contact info and password.
        </Paragraph>

        <FormContainer>
          <TextInput style={{ width: '100%' }} required placeholder="First Name" value={firstName} onChange={e => setFirstName(e.target.value)} />
          <TextInput style={{ width: '100%' }} required placeholder="Last Name" value={lastName} onChange={e => setLastName(e.target.value)} />
          <TextInput style={{ width: '100%' }} required placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />
          <TextInput style={{ width: '100%' }} required placeholder="Contact Number" value={phone} onChange={e => setPhone(e.target.value)} />
          <TextInput style={{ width: '100%' }} required placeholder="Birthday" type="date" disabled value={user.birthday} readOnly />

          <TextInput
            required
            type="password"
            value={password}
            placeholder="New Password"
            style={{ width: '100%', marginTop: 48 }}
            onChange={e => setPassword(e.target.value)}
          />
          <TextInput
            required
            type="password"
            style={{ width: '100%' }}
            value={passwordConfirmation}
            placeholder="Confirm Password"
            onChange={e => setPasswordConfirmation(e.target.value)}
          />

          <ToggleButton
            style={{ marginTop: 48 }}
            checked={marketingEmailSubscription}
            label="Receive promotional emails"
            onClick={() => setMarketingEmailSubscription(prev => !prev)}
          />

          <Button
            label={text('Profile.Contact.Buttons.Update')}
            onClick={save}
            variant="primary"
            loading={loading}
            disabled={loading || formNotDirty}
            style={{ marginTop: 48, marginBottom: 10 }}
          />
          <Button variant="link" label={text('Profile.Contact.Buttons.SignOut')} onClick={logout} />
        </FormContainer>
      </ContentContainer>
    </>
  )
}
