import styled from 'styled-components'

export const FullDialogBackdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;

  background-color: rgba(0, 0, 0, 0.5);

  transition: opacity 250ms ease-in-out, visibility 250ms;
  transition-delay: visibility 250ms;

  opacity: ${props => (props.visible ? '1' : '0')};
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`

export const SideBar = styled.div`
  background: ${props => props.theme.colors.brand.white};
  display: flex;
  flex-direction: column;
  max-width: 453px;

  overflow-y: auto;
  transform: ${props => (props.visible ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 250ms ease-in-out;

  /* default transition animation */
  margin: 0 0 0 auto;

  /* slide from left transition animation */
  &.slideInFromLeft {
    margin: 0 auto 0 0;
    transform: ${props => (props.visible ? 'translateX(0)' : 'translateX(-200%)')};
  }
`
