import React from 'react'

import { TextInput } from './TextInput'

const birthdayRegex = /^(?<month>\d{0,2})(?:\/?(?<day>\d{0,2}))?(?:\/?(?<year>\d{0,4}))?$/

/**
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/replace#specifying_a_function_as_a_parameter}
 */
const formatBirthday = (match, month = '', day = '', year = '') => {
  return match.length > 5 ? `${month}/${day}/${year}` : match.length > 2 ? `${month}/${day}` : month
}

const handleTextChange = onChange => event => {
  const value = event.target.value
  if (birthdayRegex.test(value)) {
    onChange({ target: { value: value.replace(birthdayRegex, formatBirthday) } })
  }
}

const DEFAULT_PROPS = { type: 'date' }

const getSafariDesktopProps = props => ({ type: 'text', onChange: handleTextChange(props.onChange) })

const isSafariDesktop = () => /^((?!chrome|android).)*safari/i.test(navigator?.userAgent) && !/iP(ad|hone|od)/.test(navigator?.platform)

const getOverrideProps = props => Object.assign({}, props, isSafariDesktop() ? getSafariDesktopProps(props) : DEFAULT_PROPS)

/**
 * This workaround intent to bring a better user experience
 * when using **datetime** inputs in Safari Desktop.
 * Other browser and devices should NOT be affected!
 * @see {@link http://caniuse.com/#feat=input-datetime}
 * @see {@link https://stackoverflow.com/a/35682391}
 */
const SafariDesktopWrapper = WrappedComponent => props => <WrappedComponent {...getOverrideProps(props)} />

export const DateInput = SafariDesktopWrapper(TextInput)
