import { Button } from 'reakit/Button'
import styled, { css } from 'styled-components'

import { isDesktop, isLargeDesktop, isMobile, isTablet } from '../../../../../components/responsive/Responsive'

export const CategoryMenuListContainer = styled.div`
  ${props => props.theme.menuCategoryList}
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  position: fixed;
  z-index: 1;

  ${props =>
    props.$isAuthenticated
      ? css`
          ${props => props.theme.menuCategoryListAuthenticated};
        `
      : css`
          ${props => props.theme.menuCategoryListUnauthenticated};
        `}
`

export const CategoryMenuListSpacer = styled.div``

export const CategoryMenuListButton = styled(Button)`
  ${props => props.theme.font.styles.cta};
  ${props => props.theme.menuCategoryListButton}

  align-items: center;
  cursor: pointer;
  text-align: left;
  transition: all 0.3s;

  ${isLargeDesktop`
    font-size: ${props => props.theme.font.sizes.md};
    line-height: ${props => props.theme.font.sizes.xl};
    margin: 20px 0;
  `}
  ${isDesktop`
    font-size: ${props => props.theme.font.sizes.md};
    line-height: ${props => props.theme.font.sizes.xl};
    margin: 20px 0;
  `}
  ${isTablet`
    font-size: ${props => props.theme.font.sizes.xs};
    line-height: ${props => props.theme.font.sizes.sm};
    margin: 18px 0;
  `}
  ${isMobile`
    font-size: ${props => props.theme.font.sizes.xs};
    line-height: ${props => props.theme.font.sizes.sm};
    margin: 8px 0;
  `}

  &:first-child {
    margin-top: 0;
  }

  ${props =>
    props.$isSelected &&
    css`
      ${props => props.theme.menuCategoryListButtonSelected};
    `}
`

export const CategoryNavButton = styled(Button)`
  ${props => props.theme.font.styles.cta};
  ${props => props.theme.menuCategoryBarButton}

  cursor: pointer;
  text-align: center;
  transition: all 0.3s;
  display: flex;
  flex-direction: row;

  font-size: ${props => props.theme.font.sizes.xs};
  line-height: ${props => props.theme.font.sizes.sm};
  margin: 8px;
`
