import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'

import { Button } from '../../../components/Button/Button'
import { findImage, findMetadata, formatCurrency } from '../../../data/utils'

export const UpsellProductCard = ({ menuItem, onClick }) => {
  const imgSrc = useMemo(() => findImage(menuItem), [menuItem?.images])

  const itemPrice = useMemo(() => {
    const basePriceMetadata = findMetadata(menuItem?.metadata, 'APP_BASE_PRICE')
    return basePriceMetadata ? `${formatCurrency(basePriceMetadata)}+` : formatCurrency(menuItem?.cost)
  }, [menuItem])

  return (
    <UpsellCardContainer>
      <ProductImage src={imgSrc} alt={menuItem.name} />
      <MetaContainer>
        <ItemName>{menuItem.name}</ItemName>
        <MetaRow>
          <Metadata>{itemPrice}</Metadata>
          <AddButton label="add" variant="secondary" onClick={onClick} />
        </MetaRow>
      </MetaContainer>
    </UpsellCardContainer>
  )
}

const UpsellCardContainer = styled.div`
  display: flex;

  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  margin-bottom: 12px;
  width: 100%;

  ${({ theme }) => theme.basket.upsellCarouselCard};
  /* ${({
    theme: {
      basket: { upsellCarouselCard },
    },
  }) => css`
    border-radius: ${upsellCarouselCard.borderRadius}px;
    background-color: ${upsellCarouselCard.backgroundColor};
    border: ${upsellCarouselCard.borderWidth}px solid ${upsellCarouselCard.borderColor};
  `} */
`

const ProductImage = styled.img`
  object-fit: cover;
  height: 130px;
  width: 130px;
`

const MetaContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  /* background-color: ${({ theme }) => theme.basket.upsellMeta.backgroundColor}; */
  padding: 18px;
`

const ItemName = styled.p`
  ${({ theme }) => theme.basket.upsellCarouselCardItemName};
  /* ${({ theme }) => theme.font.styles.labelInput} */
`

const MetaRow = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  margin-top: 40px;
`

export const Metadata = styled.p`
  /* ${({ theme }) => theme.font.styles.labelInput} */
  ${({ theme }) => theme.basket.upsellCarouselCardItemMeta}
`

export const AddButton = styled(Button)`
  font-size: 14px;
  padding: 8px 16px;
  max-width: 90px;
`

export default UpsellProductCard
