import { useApolloClient } from '@apollo/client'
import { useCallback, useEffect } from 'react'

import { GET_GUEST_ADDRESSES } from '../data/guest/queries'
import { withBatchTransaction } from '../services/firebase'
import { filterUniqueAddresses, mapGuestAddressToFirebaseAddress } from '../utils/address'

/**
 * @description Migrate user/guest saved addresses from OLO API to Firebase/Firestore.
 *
 * Works when:
 * - use/guest saved addresses collection doens't exist;
 * - `addressMigrated` flag is set to `false`;
 *
 * PS: You can force a new migration by setting `addressMigrated` flag is set to `false`.
 */
export const useSavedAddressesMigration = ({ isAuthenticated, token, userDocRef }) => {
  const apolloClient = useApolloClient()

  const getGuestAddresses = useCallback(
    () =>
      apolloClient
        .query({
          fetchPolicy: 'no-cache',
          query: GET_GUEST_ADDRESSES,
          variables: { token },
        })
        .then(resp => resp.data?.guestAddresses || []),
    [apolloClient, token],
  )

  useEffect(() => {
    if (!isAuthenticated || !userDocRef) return

    // TODO: find a way to verify if `userDocRef` exists before calling `get()`
    userDocRef.get().then(async snapshot => {
      if (snapshot.exists && snapshot.data().addressMigrated) {
        console.log('[useSavedAddressesMigration] skipping user/guest addresses migration.')
        return
      }

      const guestAddresses = await getGuestAddresses()

      const uniqueAddresses = filterUniqueAddresses(guestAddresses)
      console.log('[useSavedAddressesMigration] migration existing unique user/guest addresses.', uniqueAddresses)

      if (uniqueAddresses.length === 0) return

      const addressesCollection = userDocRef.collection('addresses')
      withBatchTransaction(batch => {
        uniqueAddresses.map(mapGuestAddressToFirebaseAddress).forEach(address => {
          batch.set(addressesCollection.doc(), address)
        })
        batch.set(userDocRef, { addressMigrated: true })
      })
    })
  }, [isAuthenticated, userDocRef, getGuestAddresses])
}
