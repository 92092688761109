import React, { useCallback } from 'react'

import { AddressForm } from '../../../../../components/AddressForm/AddressForm'
import { ModalHeader } from '../../../../../components/ModalHeader/ModalHeader'
import { useMessaging } from '../../../../../hooks/useMessaging'
import firebase from '../../../../../services/firebase'
import { SavedAddressModalContainer } from './SavedAddressModal.styles'

export const AddAddressModal = ({ addAddress, onClose }) => {
  const { dispatchMessage } = useMessaging()

  const handleAddAddress = useCallback(
    newAddress => {
      console.debug('[AddAddressModal] adding new address with:', newAddress)
      return addAddress(newAddress)
        .then(() => {
          dispatchMessage.info(`Address ${newAddress.nickname || newAddress.streetAddress} saved successfully.`)
          firebase.analytics().logEvent('Save_Address_Successful', { zipCode: newAddress.zipCode })
          onClose()
        })
        .catch(error => {
          dispatchMessage.error(`Failed to save address ${newAddress.nickname || newAddress.streetAddress}.`, error)
        })
    },
    [addAddress, dispatchMessage, onClose],
  )

  return (
    <SavedAddressModalContainer>
      <ModalHeader title="Add New Address" onClose={onClose} />
      <AddressForm onSubmit={handleAddAddress} submitButtonLabel="Save" />
    </SavedAddressModalContainer>
  )
}
