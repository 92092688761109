import React from 'react'
import Responsive, { useMediaQuery } from 'react-responsive'
import media from 'styled-media-query'

export const MAX_MOBILE_WIDTH = 480
export const MIN_TABLET_WIDTH = MAX_MOBILE_WIDTH + 1
export const MIN_DESKTOP_WIDTH = 1024
export const MAX_TABLET_WIDTH = MIN_DESKTOP_WIDTH - 1
export const MIN_LARGE_DESKTOP_WIDTH = 1440
export const MIX_DESKTOP_WIDTH = MIN_LARGE_DESKTOP_WIDTH - 1

export const Desktop = ({ values, ...props }) => (
  <Responsive {...props} minWidth={MIN_DESKTOP_WIDTH} values={{ ...values, ...window.testMediaQueryValues }} />
)

export const DesktopOrTablet = ({ values, ...props }) => (
  <Responsive {...props} minWidth={MIN_TABLET_WIDTH} values={{ ...values, ...window.testMediaQueryValues }} />
)

export const Tablet = ({ values, ...props }) => (
  <Responsive {...props} minWidth={MIN_TABLET_WIDTH} maxWidth={MAX_TABLET_WIDTH} values={{ ...values, ...window.testMediaQueryValues }} />
)

export const TabletOrMobile = ({ values, ...props }) => (
  <Responsive {...props} maxWidth={MAX_TABLET_WIDTH} values={{ ...values, ...window.testMediaQueryValues }} />
)

export const Mobile = ({ values, ...props }) => (
  <Responsive {...props} maxWidth={MAX_MOBILE_WIDTH} values={{ ...values, ...window.testMediaQueryValues }} />
)

export const isLargeDesktop = media.greaterThan(`${MIN_LARGE_DESKTOP_WIDTH}px`)
export const isDesktop = media.between(`${MIN_DESKTOP_WIDTH}px`, `${MIX_DESKTOP_WIDTH}px`)
export const isTablet = media.between(`${MIN_TABLET_WIDTH}px`, `${MAX_TABLET_WIDTH}px`)
export const isMobile = media.lessThan(`${MIN_TABLET_WIDTH}px`)

export const useResponsiveness = () => {
  const isLargeDesktop = useMediaQuery({ query: `(min-device-width: ${MIN_LARGE_DESKTOP_WIDTH}px)` })
  const isDesktop = useMediaQuery({ query: `(min-device-width: ${MIN_DESKTOP_WIDTH}px, max-device-width: ${MIX_DESKTOP_WIDTH}px)` })
  const isTablet = useMediaQuery({ query: `(min-device-width: ${MIN_TABLET_WIDTH}px, max-device-width: ${MAX_TABLET_WIDTH}px)` })
  const isMobile = useMediaQuery({ query: `(max-device-width: ${MIN_TABLET_WIDTH}px)` })
  return {
    isLargeDesktop,
    isDesktop,
    isTablet,
    isMobile,
  }
}
