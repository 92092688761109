import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDialogState } from 'reakit/Dialog'

import { DialogWithBackdrop } from '../../../../../components/DialogWithBackdrop/DialogWithBackdrop'
import { FullScreenLoading } from '../../../../../components/FullScreenLoading/FullScreenLoading'
import { PREFERENCES } from '../../../../../constants/preferences'
import { useConfig } from '../../../../../contexts/appConfig'
import { useBrandCategories } from '../../../../../hooks/useBrandCategories'
import { useMenuItemNavigation } from '../../../../../hooks/useMenuItemNavigation'
import { useMenuItems } from '../../../../../hooks/useMenuItems'
import firebase from '../../../../../services/firebase'
import { SimpleBuilderPage } from '../../../../MenuItem/SimpleBuilder'
import { CategoryMenuList } from '../CategoryMenuList/CategoryMenuList'
import { MenuContainer } from '../MenuContainer/MenuContainer'
import { BrowseAllProductsContainer } from './BrowseAllProducts.styles'

export const BrowseAllProducts = ({ headerHeight, isMobile, selectedCategory }) => {
  const { getPreference } = useConfig()
  const { goToItem } = useMenuItemNavigation()
  const history = useHistory()
  const itemDialogProps = useDialogState({ animated: true })
  const location = useLocation()

  const { items, loadingItems } = useMenuItems()

  const hasPagedMenu = getPreference(PREFERENCES.GENERAL)?.pagedMenu
  const categories = useBrandCategories(false, null, items?.menu)
  const [selectedCategoryId, setSelectedCategory] = useState(categories?.[0]?.id)

  const changeCategory = subCategory => {
    if (!subCategory || categories.length === 0) return
    setSelectedCategory(subCategory.id)

    if (hasPagedMenu) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    } else {
      const elementOffsetTop = document.getElementById(`menu-${subCategory.id}`)?.offsetTop

      const category = categories.find(categ => categ.id === selectedCategoryId)
      firebase.analytics().logEvent('Category_Selected', {
        categoryType: category.displayName,
      })

      window.scrollTo({ top: elementOffsetTop, behavior: 'smooth' })
    }
  }

  useEffect(() => {
    console.log('changed category', selectedCategory)
    if (selectedCategory) {
      setSelectedCategory(selectedCategory.id)
      changeCategory(selectedCategory)
    }
  }, [selectedCategory])

  const onCloseDialog = () => {
    history.replace({ ...history.location, state: { item: null } })
    itemDialogProps.hide()
  }

  useEffect(() => {
    if (location?.state?.item) {
      itemDialogProps.show()
    }
  }, [location?.state?.item])

  useEffect(() => {
    setSelectedCategory(categories?.[0]?.id)
  }, [categories])

  return (
    <>
      <BrowseAllProductsContainer style={{ minHeight: `calc(100vh - ${headerHeight}px)` }}>
        <CategoryMenuList
          selectedCategoryId={selectedCategoryId}
          categories={categories}
          isVisible={!loadingItems}
          onClick={changeCategory}
          isMobile={isMobile}
          headerHeight={headerHeight}
        />
        <MenuContainer items={items} isVisible={!loadingItems} onSelectMenuItem={goToItem} showCategoryImage={false} />
        <FullScreenLoading isOverlay isVisible={loadingItems} showLabel={false} />
      </BrowseAllProductsContainer>
      <DialogWithBackdrop dialogProps={itemDialogProps} ariaLabel="Choose and customize" onBackdropClick={onCloseDialog}>
        {location?.state?.item && <SimpleBuilderPage onClose={onCloseDialog} />}
      </DialogWithBackdrop>
    </>
  )
}
