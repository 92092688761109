import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDialogState } from 'reakit/Dialog'

import { DialogWithBackdrop } from '../../../components/DialogWithBackdrop/DialogWithBackdrop'
import { Footer } from '../../../components/Footer/Footer'
import { Header } from '../../../components/Header/Header'
import { Main } from '../../../components/Main/Main'
import { PageContainer } from '../../../components/Page/Page.styles'
import { DesktopOrTablet, useResponsiveness } from '../../../components/responsive/Responsive'
import { PREFERENCES } from '../../../constants/preferences'
import { useConfig } from '../../../contexts/appConfig'
import { useSession } from '../../../contexts/session'
import { useBrandCategories } from '../../../hooks/useBrandCategories'
import { useBrands } from '../../../hooks/useBrands'
import { useEventListener } from '../../../hooks/useEventListener'
import { useMenuItemNavigation } from '../../../hooks/useMenuItemNavigation'
import { useMenuItems } from '../../../hooks/useMenuItems'
import { useWindowSize } from '../../../hooks/useWindowsSize'
import firebase from '../../../services/firebase'
import { SimpleBuilderPage } from '../../MenuItem/SimpleBuilder'
import { BrandCategoryBar } from './components/BrandCategoryBar/BrandCategoryBar'
import { BrandContainer } from './components/BrandContainer/BrandContainer'
import { BrowseMenuHeroContainer } from './components/BrowseMenuHeroContainer/BrowseMenuHeroContainer'
import { CategoryBar } from './components/CategoryBar/CategoryBar'
import { MenuContainer } from './components/MenuContainer/MenuContainer'

const MIN_OFFSET_TOP_THRESHOLD = 10

export const HorizontalBrowseMenu = () => {
  const {
    state: { selectedBrand },
  } = useSession()
  const { getPreference, getAsset } = useConfig()
  const history = useHistory()
  const location = useLocation()
  const { isMobile } = useResponsiveness()

  const { goToItem } = useMenuItemNavigation()
  const itemDialogProps = useDialogState({ animated: true })

  const { brands, loading: loadingBrands, selectBrand } = useBrands()
  const handleSelectBrand = useCallback(brand => selectBrand(brand), [selectBrand])

  const backgroundImageUrl = useMemo(() => getAsset?.('BrowseMenuHero')?.url, [getAsset])

  const [_, windowHeight] = useWindowSize()
  const [headerHeight, setHeaderHeight] = useState()
  const mainHeight = useMemo(() => windowHeight - (headerHeight || 0), [windowHeight, headerHeight])
  const handleHeaderResize = useCallback((_, height) => setHeaderHeight(height), [setHeaderHeight])

  const { items, loadingItems } = useMenuItems()

  const filteredBrands = useMemo(
    () => brands?.filter(category => items?.menu?.some(item => item.metadata.some(metadata => metadata.value === category.code))),
    [brands, items],
  )

  const hasBrands = !loadingBrands && filteredBrands?.length > 0
  const hasPagedMenu = getPreference(PREFERENCES.GENERAL)?.pagedMenu
  const categories = useBrandCategories(hasBrands, selectedBrand, items?.menu)
  const [selectedCategoryId, setSelectedCategory] = useState(categories?.[0]?.id)

  const filteredItems = useMemo(() => {
    if (hasPagedMenu) {
      return {
        menu: items?.menu?.filter(item => item.id === selectedCategoryId),
      }
    }
    if (hasBrands) {
      return {
        menu: items?.menu?.filter(item => item.metadata.some(metadata => metadata.value === selectedBrand)),
      }
    }
    return items
  }, [items, hasBrands, selectedBrand, selectedCategoryId])

  const hasSelectedBrand = !loadingBrands && (!hasBrands || !!selectedBrand)
  const showBrowseMenuHeroText = hasBrands && !selectedBrand
  const showCategoryBar = !loadingItems && hasSelectedBrand
  const showBrandContainer = hasBrands && !selectedBrand
  const showMenuContainer = !loadingItems && hasSelectedBrand

  const [showBrowseMenuHero, setShowBrowseMenuHero] = useState(!isMobile)

  const changeCategory = useCallback(
    subCategory => {
      if (!subCategory) return
      setSelectedCategory(subCategory.id)

      if (hasPagedMenu) {
        window.scrollTo({ top: MIN_OFFSET_TOP_THRESHOLD, behavior: 'smooth' })
      } else {
        setShowBrowseMenuHero(false)
        setTimeout(() => {
          const elementOffsetTop = document.getElementById(`menu-${subCategory.id}`)?.offsetTop
          window.scrollTo({ top: elementOffsetTop, behavior: 'smooth' })
          const category = categories?.find(categ => categ.id === selectedCategoryId)
          firebase.analytics().logEvent('Category_Selected', {
            categoryType: category?.displayName,
          })
        }, 0)
      }
    },
    [setShowBrowseMenuHero, setSelectedCategory, categories, hasPagedMenu, selectedCategoryId],
  )

  const onCloseDialog = useCallback(() => {
    history.replace({ ...history.location, state: { item: null } })
  }, [history, itemDialogProps])

  const onSelectItem = useCallback(
    item => {
      window.scrollTo({ top: MIN_OFFSET_TOP_THRESHOLD })
      goToItem(item)
    },
    [goToItem],
  )

  const [lastScrollY, setLastScrollY] = useState(window.scrollY)

  const updateLastScrollY = useCallback(() => {
    console.debug('[HorizontalBrowseMenu] updateLastScrollY', { scrollY: window.scrollY })
    setLastScrollY(window.scrollY)
  }, [setLastScrollY])

  useEventListener({ element: window, eventName: 'mousewheel', callback: updateLastScrollY, debounceDelay: 50 })

  const handleWindowScroll = useCallback(
    event => {
      const scrollY = window.scrollY
      console.debug('[HorizontalBrowseMenu] handleWindowScroll', { scrollY, lastScrollY, isMobile, hasSelectedBrand })
      setShowBrowseMenuHero(!isMobile && (!hasSelectedBrand || (scrollY === 0 && lastScrollY === 0)))
    },
    [setShowBrowseMenuHero, isMobile, hasSelectedBrand, headerHeight, lastScrollY],
  )

  useEventListener({ element: window, eventName: 'mousewheel', callback: handleWindowScroll, debounceDelay: 15 })

  useEffect(() => {
    console.debug({ item: location?.state?.item })
    if (location?.state?.item) {
      itemDialogProps.show()
    } else {
      itemDialogProps.hide()
    }
  }, [location?.state?.item])

  useEffect(() => {
    setSelectedCategory(categories?.[0]?.id)
  }, [categories])

  return (
    <PageContainer as="div">
      <Header onResize={handleHeaderResize}>
        <DesktopOrTablet>
          <BrowseMenuHeroContainer backgroundImageUrl={backgroundImageUrl} isVisible={showBrowseMenuHero} isVisibleText={showBrowseMenuHeroText} />
        </DesktopOrTablet>
        <BrandCategoryBar
          brands={filteredBrands}
          selectedBrand={selectedBrand}
          isVisible={hasBrands && !(isMobile && !selectedBrand)}
          onClick={handleSelectBrand}
        />
        <CategoryBar selectedCategoryId={selectedCategoryId} categories={categories} isVisible={showCategoryBar} onClick={changeCategory} />
      </Header>
      <Main style={{ minHeight: showBrandContainer ? mainHeight : '' }}>
        <BrandContainer brands={filteredBrands} isVisible={showBrandContainer} onClick={handleSelectBrand} />
        <MenuContainer items={filteredItems} isVisible={showMenuContainer} onSelectMenuItem={onSelectItem} />
        <DialogWithBackdrop dialogProps={itemDialogProps} ariaLabel="Choose and customize" onBackdropClick={onCloseDialog}>
          {location?.state?.item && <SimpleBuilderPage onClose={onCloseDialog} />}
        </DialogWithBackdrop>
      </Main>
      <Footer />
    </PageContainer>
  )
}
