import { Button as ReakitButton } from 'reakit/Button'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  margin-bottom: 24px;
  :last-of-type {
    margin-bottom: 0;
  }
`

export const ModifierSummaryLabel = styled.label`
  ${({ theme }) => theme.font.styles.labelInputBold};
  color: ${({ theme }) => theme.colors.brand.black.hex()};
  margin-bottom: 1px;
`

export const Image = styled.div`
  background: ${props => `url(${props.src}) no-repeat center center`};
  background-position: center;
  background-size: cover;
  overflow: hidden;

  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;

  height: 100px;
  width: 100%;
`

export const ModifierSummaryGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 24px;
`

export const ModifierSummaryButton = styled(ReakitButton)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 150px;
  text-align: left;

  background-color: ${({ theme }) => theme.colors.brand.white.hex()};
  margin-top: 10px;

  border-radius: 3px;
  border: ${({ theme }) => theme.borders.dashed};
  border-color: ${({ theme }) => theme.colors.brand.gray40.hex()};

  /* button override */
  overflow: hidden;

  ${props =>
    props.$isMandatory &&
    css`
      border-color: ${({ theme }) => theme.colors.brand.black.hex()};
    `}

  ${props =>
    props.$hasSelection &&
    css`
      border: ${({ theme }) => theme.borders.normal};
    `}
`

export const ModifierSummaryOptionTextContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  width: 100%;
  padding: 8px 8px;
`

export const ModifierSummaryText = styled.span`
  ${({ theme }) => theme.font.styles.labelInputExtraSmall};
  font-weight: normal;
  max-height: 30px;
  margin-right: 3px;
  font-size: 10px;

  ${props =>
    !props.$hasSelection &&
    css`
      color: ${({ theme }) => theme.colors.brand.black};
    `}

  ${props =>
    props.$overflowHidden &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
  
  ${props =>
    props.$breakAll &&
    css`
      text-overflow: ellipsis;
      overflow-wrap: ellipsis;
      text-align: center;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    `}
`
