import React from 'react'
import styled from 'styled-components'

const Skeleton = ({ loading, children, ...props }) => {
  return loading ? <SkeletonContainer {...props} /> : children
}

const SkeletonContainer = styled.div`
  animation: opacity 0.6s infinite;

  @keyframes opacity {
    0% {
      opacity: 0.2;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
`

export default Skeleton
