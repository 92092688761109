import { flatten } from 'lodash'
import React, { useEffect, useMemo } from 'react'

import { useConfig } from '../../../../contexts/appConfig'
import { findMetadata } from '../../../../data/utils'
import {
  Container,
  Image,
  ModifierSummaryButton,
  ModifierSummaryGridContainer,
  ModifierSummaryLabel,
  ModifierSummaryOptionTextContainer,
  ModifierSummaryText,
} from './ModifierSummary.styles.js'

const arrayOf = n => [...Array(n).keys()]

export const ModifierSummary = React.memo(({ modifier, selectedOptions }) => {
  const { getMenuImage } = useConfig()

  const shortName = useMemo(() => {
    return [
      findMetadata(modifier.metadata, 'APP_SHORT_NAME') || modifier.description,
      modifier?.explanationText && `(${modifier?.explanationText})`,
    ].join(' ')
  })

  const minSize = useMemo(
    () => Math.max(parseInt(modifier.minChoiceQuantity, 10), parseInt(modifier.minAggregateQuantity, 10), parseInt(modifier.minSelects, 10)) || 0,
    [modifier],
  )

  const maxSize = useMemo(
    () => Math.max(parseInt(modifier.maxChoiceQuantity, 10), parseInt(modifier.maxAggregateQuantity, 10), parseInt(modifier.maxSelects, 10)) || 0,
    [modifier],
  )

  const selectedSize = useMemo(() => selectedOptions.reduce((total, m) => (total += m.quantity), 0), [selectedOptions])

  const flattenedSelectedOptions = useMemo(() => {
    return flatten(
      selectedOptions?.map(m =>
        arrayOf(m.quantity || 0).map(i => ({
          key: `${m.option.id}-${i}`,
          name: m.option.name,
          imageUrl: getMenuImage(findMetadata(m.option.metadata, 'APP_MENU_IMAGE_ID')),
        })),
      ),
    )
  }, [selectedOptions, getMenuImage])

  const missingMandatoryOptions = useMemo(() => arrayOf(Math.max(minSize - selectedSize, 0)), [minSize, selectedSize])

  const missingOptionalOptions = useMemo(() => {
    if (!maxSize) {
      const missingOptionalSize = modifier.options.length - selectedSize - missingMandatoryOptions.length
      return arrayOf(Math.min(missingOptionalSize, 1))
    }
    const missingOptionalSize = maxSize - selectedSize - missingMandatoryOptions.length
    if (minSize) {
      return arrayOf(Math.max(missingOptionalSize, 0))
    } else {
      return arrayOf(Math.min(missingOptionalSize, 1))
    }
  }, [minSize, maxSize, selectedSize, missingMandatoryOptions])

  useEffect(() => {
    console.debug('[ModifierSummary]', {
      shortName,
      minSize,
      maxSize,
      missingMandatoryOptions,
      missingOptionalOptions,
      selectedSize,
      flattenedSelectedOptions,
    })
  }, [shortName, minSize, maxSize, missingMandatoryOptions, missingOptionalOptions, selectedSize, flattenedSelectedOptions])

  return (
    <Container>
      <ModifierSummaryLabel>{shortName}</ModifierSummaryLabel>
      <ModifierSummaryGridContainer>
        {flattenedSelectedOptions?.map(selectedOption => (
          <ModifierSummaryButton key={selectedOption.key} $hasSelection>
            <Image src={selectedOption.imageUrl} />
            <ModifierSummaryOptionTextContainer>
              <ModifierSummaryText title={selectedOption.name} $breakAll>
                {selectedOption.name}
              </ModifierSummaryText>
            </ModifierSummaryOptionTextContainer>
          </ModifierSummaryButton>
        ))}
        {missingMandatoryOptions?.map(i => (
          <ModifierSummaryButton key={i} $isMandatory />
        ))}
        {missingOptionalOptions?.map(i => (
          <ModifierSummaryButton key={i} />
        ))}
      </ModifierSummaryGridContainer>
    </Container>
  )
})
