import { useApolloClient } from '@apollo/client'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { useText } from '../content'
import { useSession } from '../contexts/session'
import { ADD_PRODUCT_TO_BASKET, REMOVE_BASKET_PRODUCT, UPDATE_BASKET_PRODUCT, UPDATE_BASKET_PRODUCT_BATCH } from '../data/basket/queries'
import { useBasket } from './useBasket'
import { useBasketAuthentication } from './useBasketAuthentication'
import { useMenuItems } from './useMenuItems'
import { useMessaging } from './useMessaging'
import { useUtensils } from './useUtensils'

export const useCheckoutUtensils = () => {
  const {
    dispatch,
    state: { restaurant, useUtensils: selected },
  } = useSession()
  const [addingToBasket, setAddingToBasket] = useState(false)
  const { authentication } = useBasketAuthentication()
  const { basket } = useBasket()
  const { dispatchMessage } = useMessaging()
  const { text } = useText()
  const apolloClient = useApolloClient()
  const setSelected = useCallback(payload => dispatch({ type: 'SET_USE_UTENSILS', payload }), [dispatch])

  const { items: menuItems } = useMenuItems({ allowHidden: true })
  const { basketProduct, includeUtensilModifierOption, includeMultiUtensilsModifierOptions, excludeUtensilModifierOption, utensilItem } = useUtensils(
    {
      menuItems,
      products: basket?.products,
    },
  )

  const isEnabled = useMemo(() => !!utensilItem, [utensilItem])

  const addToBasket = useCallback(
    selectedOptions => {
      setAddingToBasket(true)
      return apolloClient
        .mutate({
          mutation: ADD_PRODUCT_TO_BASKET,
          variables: {
            authentication,
            basketId: basket?.id,
            productId: utensilItem?.id,
            quantity: 1,
            restaurantId: restaurant.id,
            selectedOptions,
          },
        })
        .then(response => {
          setAddingToBasket(false)
          return response?.data?.addToBasket
        })
    },
    [apolloClient, basket, utensilItem, restaurant],
  )

  const updateBasket = useCallback(
    selectedOptions =>
      apolloClient
        .mutate({
          mutation: UPDATE_BASKET_PRODUCT,
          variables: {
            basketId: basket?.id,
            basketProductId: basketProduct?.id,
            productId: utensilItem?.id,
            quantity: 1,
            selectedOptions,
          },
        })
        .then(response => response?.data?.updateBasketProduct),
    [apolloClient, basket, basketProduct, utensilItem],
  )

  const updateBasketBatch = useCallback(
    products =>
      apolloClient
        .mutate({
          mutation: UPDATE_BASKET_PRODUCT_BATCH,
          variables: {
            basketId: basket?.id,
            products: {
              products,
            },
          },
        })
        .then(response => response?.data?.updateBasketProductsBatch),
    [apolloClient, basket, basketProduct, utensilItem],
  )

  const removeBasketProduct = useCallback(
    () =>
      apolloClient
        .mutate({
          mutation: REMOVE_BASKET_PRODUCT,
          variables: {
            basketId: basket?.id,
            basketProductId: basketProduct?.id,
          },
        })
        .then(response => response?.data?.removeBasketProduct),
    [apolloClient, basket, basketProduct],
  )

  const selectUtensil = useCallback(
    value => {
      const previousValue = selected
      setSelected(value)

      let fn
      if (basketProduct) {
        if (includeMultiUtensilsModifierOptions?.length > 0) {
          fn = updateBasketBatch
        } else {
          fn = updateBasket
        }
      } else {
        fn = addToBasket
      }
      let selectedOptions
      let products = null

      if (includeMultiUtensilsModifierOptions?.length > 0) {
        selectedOptions = Object.keys(value)
          .filter(key => value[key] > 0)
          .map(k => String(k))
        const choices = Object.keys(value)
          .filter(key => value[key] > 0)
          .map(k => {
            return {
              choiceid: parseInt(k),
              quantity: value[k],
            }
          })
        products = [
          {
            productid: basketProduct ? basketProduct.id : utensilItem?.id,
            quantity: 1,
            choices,
          },
        ]
      } else {
        selectedOptions = [String(value ? includeUtensilModifierOption?.id : excludeUtensilModifierOption?.id)]
      }

      console.debug('[useUtensils#Checkout] selectUtensil', { value, previousValue, basketProduct, selectedOptions })

      let fnCall

      if (selectedOptions.length === 0 && includeMultiUtensilsModifierOptions?.length > 0) {
        fnCall = removeBasketProduct()
      } else {
        fnCall = includeMultiUtensilsModifierOptions?.length > 0 && basketProduct ? fn(products) : fn(selectedOptions)
      }

      return fnCall
        .then(updatedBasket => {
          updatedBasket && dispatch({ type: 'SET_BASKET', payload: updatedBasket })
        })
        .catch(error => {
          dispatchMessage.error(text('Checkout.Utensils.ToggleOption.Error'), error)
          setSelected(previousValue)
        })
    },
    [
      selected,
      basketProduct,
      setSelected,
      addToBasket,
      updateBasket,
      includeUtensilModifierOption,
      excludeUtensilModifierOption,
      includeMultiUtensilsModifierOptions,
      dispatch,
      dispatchMessage,
    ],
  )

  useEffect(() => {
    if (!isEnabled) return
    if (!basketProduct) {
      if (includeUtensilModifierOption?.isDefault && !includeMultiUtensilsModifierOptions?.length > 0) {
        selectUtensil(true)
      } else if (excludeUtensilModifierOption?.isDefault && !includeMultiUtensilsModifierOptions?.length > 0) {
        selectUtensil(false)
      }
    }
  }, [isEnabled, basketProduct, includeUtensilModifierOption, excludeUtensilModifierOption, includeMultiUtensilsModifierOptions])

  useEffect(() => {
    console.debug('[useUtensils#Checkout]', {
      isEnabled,
      selected,
      utensilItem,
    })
  }, [isEnabled, selected, utensilItem])

  return {
    isEnabled,
    selectUtensil,
    selected,
    utensilItem,
    multiItems: includeMultiUtensilsModifierOptions,
    includeUtensilModifierOption,
    loading: addingToBasket,
  }
}
