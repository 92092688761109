import React from 'react'
import * as Icons from 'react-icons/io'

import { CircleLoadingIndicator } from '../CircleLoadingIndicator/CircleLoadingIndicator'
import {
  OrderTrackingProgressBarContainer,
  OrderTrackingProgressBarFillContainer,
  OrderTrackingProgressBarIconContainer,
} from './OrderTrackingProgressBar.styles'

export const OrderTrackingProgressBar = ({ iconNames = [], currentStep = 0, loading = false, ...props }) => (
  <OrderTrackingProgressBarContainer {...props}>
    {iconNames.map((name, idx) => {
      const Icon = Icons[name]
      return (
        <React.Fragment key={name}>
          {idx > 0 && <OrderTrackingProgressBarFillContainer active={currentStep >= idx} />}
          <OrderTrackingProgressBarIconContainer active={currentStep >= idx}>
            {loading && idx === 0 ? <CircleLoadingIndicator /> : <Icon color="white" />}
          </OrderTrackingProgressBarIconContainer>
        </React.Fragment>
      )
    })}
  </OrderTrackingProgressBarContainer>
)
