import React, { useEffect } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'

import { useSession } from '../contexts/session'
import { AccountCreatedPage } from '../scenes/auth/AccountCreated'
import { ForgotPasswordPage } from '../scenes/auth/ForgotPassword'
import { LoginPage } from '../scenes/auth/Login'
import { MigrationCompletedPage } from '../scenes/auth/MigrationCompleted'
import { MigrationInterstitialPage } from '../scenes/auth/MigrationInterstitial'
import { MigrationVerificationPage } from '../scenes/auth/MigrationVerification'
import { RegisterPage } from '../scenes/auth/Register'
import { Checkout } from '../scenes/checkout/Checkout'
import { CustomBuilderPage } from '../scenes/CustomBuilder/CustomBuilder'
import { HomePage } from '../scenes/home/Home'
import { LandingPage } from '../scenes/menu/Landing'
import { MenuPage } from '../scenes/menu/Menu'
import { ProfilePage } from '../scenes/profile/Profile'
import { OrderTrackingPage } from '../scenes/tracking/OrderTracking'
import { CustomRoute } from './CustomRoute'

export const Routes = () => {
  const location = useLocation()
  const { state, dispatch } = useSession()
  console.debug('### [Routes]:', state)

  const isAuthenticated = () => !!state?.user
  const hasBasket = () => !!state?.basket
  const hasHandoffMode = () => !!state?.handoffMode
  const hasRestaurant = () => !!state?.restaurant
  const hasMenuItem = () => !!location?.state?.item

  useEffect(() => {
    // pull contact info from the user obj, and if it exists populate the local contactInfo
    // when the user edits and saves later on, just update a different contactInfo obj, not the remote account
    const user = state.user

    if (user) {
      const payload = { firstName: user.firstName, lastName: user.lastName, email: user.email, contactNumber: user.phone }
      dispatch({ type: 'SET_CONTACT_INFO', payload })
    }
  }, [state.user])

  return (
    <>
      <Switch>
        <Route path="/" exact>
          <HomePage />
        </Route>
        <Route path="/tracking/:orderId" exact>
          <OrderTrackingPage />
        </Route>
        <Route path="/menu/:restaurantSlug">
          <LandingPage />
        </Route>
        <CustomRoute path="/browse-menu" exact checks={[hasHandoffMode, hasRestaurant]}>
          <MenuPage />
        </CustomRoute>
        <CustomRoute path="/custom-menu-item" exact checks={[hasHandoffMode, hasRestaurant, hasMenuItem]}>
          <CustomBuilderPage />
        </CustomRoute>
        <CustomRoute path="/CustomMenuItemBuilder" exact checks={[hasHandoffMode, hasRestaurant, hasMenuItem]}>
          <CustomBuilderPage />
        </CustomRoute>
        <Route path="/login" exact>
          <LoginPage />
        </Route>
        <Route path="/forgot-password" exact>
          <ForgotPasswordPage />
        </Route>
        <Route path="/register/:isMigrating?" exact>
          <RegisterPage />
        </Route>
        <CustomRoute path="/join" exact checks={() => !isAuthenticated()} onFailedRedirectTo="/">
          <RegisterPage />
        </CustomRoute>
        <Route path="/migration/start" exact>
          <MigrationInterstitialPage />
        </Route>
        <CustomRoute path="/migration/verification" exact checks={isAuthenticated} onFailedRedirectTo="/migration/start">
          <MigrationVerificationPage />
        </CustomRoute>
        <CustomRoute path="/migration/completed" exact checks={isAuthenticated} onFailedRedirectTo="/migration/start">
          <MigrationCompletedPage />
        </CustomRoute>
        <CustomRoute path="/account/created" exact checks={isAuthenticated} onFailedRedirectTo="/login">
          <AccountCreatedPage />
        </CustomRoute>
        <CustomRoute path="/checkout" exact checks={[hasHandoffMode, hasRestaurant, hasBasket]}>
          <Checkout />
        </CustomRoute>
        <CustomRoute path="/profile" checks={isAuthenticated} onFailedRedirectTo="/login">
          <ProfilePage />
        </CustomRoute>
        <Redirect from="*" to="/" />
      </Switch>
    </>
  )
}
