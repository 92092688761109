import { Box } from 'reakit/Box'
import { Button } from 'reakit/Button'
import styled, { css } from 'styled-components'

import { isDesktop, isLargeDesktop, isMobile, isTablet } from '../../../../../components/responsive/Responsive'

export const Container = styled(Box)`
  align-items: center;
  background: ${props => props.theme.colors.brand.white};
  display: flex;
  flex-direction: row;
  ${isLargeDesktop`
    padding: 24px 48px;
  `}
  ${isDesktop`
    padding: 24px 48px;
  `}
  ${isTablet`
    padding: 20px 24px;
  `}
  ${isMobile`
    padding: 20px 16px;
  `}
`

export const LabelText = styled.span`
  ${props => props.theme.font.styles.cta};
  color: ${props => props.theme.colors.brand.black};
  ${isLargeDesktop`
    font-size: ${props => props.theme.font.sizes.lg};
    margin-right: 98px;
  `}
  ${isDesktop`
    font-size: ${props => props.theme.font.sizes.md};
    margin-right: 98px;
  `}
  ${isTablet`
    font-size: ${props => props.theme.font.sizes.sm};
    margin-right: 54px;
  `}
  ${isMobile`
    display: none;
  `}
`

export const CategoriesContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${isMobile`
    flex-wrap: none;
    justify-content: space-evenly;
  `}
`

export const CategoryButton = styled(Button)`
  ${props => props.theme.font.styles.cta};
  background: none;
  border: none;
  color: ${props => props.theme.colors.brand.gray40};
  cursor: pointer;
  padding: 2px 6px;
  transition: all 0.3s;
  ${props =>
    props.$isSelected &&
    css`
      color: ${props => props.theme.colors.brand.white};
      background: ${props => props.theme.colors.brand.black};
    `}
  &:hover {
    color: ${props => props.theme.colors.brand.black};
    background: ${props => props.theme.colors.brand.yellow50};
  }
  ${isLargeDesktop`
    font-size: ${props => props.theme.font.sizes.lg};
    line-height: ${props => props.theme.font.sizes.xl};
    margin-right: 54px;
    &:hover {
      font-size: ${props => props.theme.font.sizes.xl};
    }
  `}
  ${isDesktop`
    font-size: ${props => props.theme.font.sizes.md};
    line-height: ${props => props.theme.font.sizes.lg};
    margin-right: 70px;
    &:hover {
      font-size: ${props => props.theme.font.sizes.lg};
    }
  `}
  ${isTablet`
    font-size: ${props => props.theme.font.sizes.sm};
    line-height: ${props => props.theme.font.sizes.md};
    margin-right: 54px;
    &:hover {
      font-size: ${props => props.theme.font.sizes.md};
    }
  `}
  ${isMobile`
    font-size: ${props => props.theme.font.sizes.xs};
    line-height: ${props => props.theme.font.sizes.sm};
    margin-right: 24px;
    &:hover {
      font-size: ${props => props.theme.font.sizes.sm};
    }
  `}
  :last-of-type {
    margin-right: 0;
  }
`
