import React from 'react'

import { findImage, findMetadata } from '../../../../../data/utils'
import {
  Container,
  MenuHeader,
  ProductButton,
  ProductCaloriesText,
  ProductImage,
  ProductInfoContainer,
  ProductNameHeader,
  ProductOuterContainer,
  ProductPriceText,
  ProductsContainer,
  Section,
} from './MenuContainer.styles'

export const MenuContainer = ({ items, isVisible, onSelectMenuItem, showCategoryImage = true }) => {
  if (!isVisible) return null
  return (
    <Container as="div">
      {items?.menu?.map(menu => {
        const headerImage = showCategoryImage ? findImage(menu) : null
        return (
          <Section key={`menu-${menu.id}`} id={`menu-${menu.id}`}>
            <MenuHeader>{headerImage ? <img src={headerImage} alt={menu.name} /> : menu.name}</MenuHeader>
            <ProductsContainer>
              {menu.products?.map(product => (
                <Product key={product.id} product={product} onSelectMenuItem={onSelectMenuItem} />
              ))}
            </ProductsContainer>
          </Section>
        )
      })}
    </Container>
  )
}

const Product = ({ product, onSelectMenuItem }) => {
  const basePriceMetdataValue = findMetadata(product.metadata, 'APP_BASE_PRICE')
  let price = Number(basePriceMetdataValue || product.cost).toFixed(2)
  price = price + (basePriceMetdataValue ? '+' : '')

  const resizeMode = findMetadata(product.metadata, 'APP_IMAGE_RESIZE_MODE')

  return (
    <ProductOuterContainer>
      <ProductButton as="button" onClick={() => onSelectMenuItem(product)}>
        <ProductImage src={findImage(product)} resizeMode={resizeMode} />
        <ProductInfoContainer>
          <ProductNameHeader>{product.name}</ProductNameHeader>
          {!!product.baseCalories && <ProductCaloriesText>({product.baseCalories} cals)</ProductCaloriesText>}
          <ProductPriceText>${price}</ProductPriceText>
        </ProductInfoContainer>
      </ProductButton>
    </ProductOuterContainer>
  )
}
