import { Box } from 'reakit/Box'
import styled from 'styled-components'

export const MainStyle = styled(Box)`
  display: flex;
  flex: 1;
  overflow: auto;
  position: relative;
  flex-direction: column;
`
