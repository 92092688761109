import { Tab, TabList } from 'reakit/Tab'
import styled, { css } from 'styled-components'

export const TabContainer = styled(TabList)`
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
`

export const TabButton = styled(Tab)`
  ${({ theme }) => theme.toggles.style};
  ${({ theme, $active }) => ($active ? theme.toggles.styles.active : theme.toggles.styles.inactive)};

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  width: 50%;
`
