import { useQuery } from '@apollo/client'
import { intersectionBy } from 'lodash'
import { useMemo } from 'react'

import { useSession } from '../contexts/session'
import { GET_MENU_FILTERS } from '../data/menu/queries'
import { findMetadata } from '../data/utils'

export const useNutrition = () => {
  const { data, loading } = useQuery(GET_MENU_FILTERS, { fetchPolicy: 'network-only' })

  const restrictions = (data?.dietaryRestrictions || []).filter(d => d.type === 'Restriction')
  const preferences = (data?.dietaryRestrictions || []).filter(d => d.type === 'Preference')

  return { restrictions, preferences, loading }
}

export const useDietaryInformationFromItem = item => {
  const { state } = useSession()
  const { menuFilters = [] } = state

  return useMemo(() => {
    const metadata = findMetadata(item?.metadata, 'APP_DIETARY_RESTRICTIONS')

    if (!metadata) {
      return { itemRestrictions: [], sessionIntersection: [] }
    }

    const itemRestrictions = metadata
      .trim()
      .split(',')
      .map(oloCode => ({ oloCode }))

    return { itemRestrictions, sessionIntersection: intersectionBy(menuFilters, itemRestrictions, 'oloCode') }
  }, [item?.metadata, menuFilters])
}

export const useNutritionalPreferences = item => {
  const { state } = useSession()
  const { menuFilters = [] } = state

  return useMemo(() => {
    const metadata = findMetadata(item?.metadata, 'APP_NUTRITIONAL_PREFERENCES')

    if (!metadata) {
      return { itemRestrictions: [], sessionIntersection: [] }
    }

    const itemRestrictions = metadata
      .trim()
      .split(',')
      .map(oloCode => ({ oloCode }))

    return { itemRestrictions, sessionIntersection: intersectionBy(menuFilters, itemRestrictions, 'oloCode') }
  }, [item?.metadata, menuFilters])
}
