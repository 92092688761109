import { Box } from 'reakit/Box'
import styled from 'styled-components'

import { isMobile } from '../../responsive/Responsive'

const FooterGroupStyle = styled(Box)`
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 10px;
`

export const LinksGroupsStyle = styled(FooterGroupStyle)`
  > * {
    &:not(:first-child) {
      margin-left: 28px;
    }
  }
  ${isMobile`
    flex-direction: column;
    > * {
      &:not(:first-child) {
        margin-left: 0;
        margin-top: 18px;
      }
    }
  `}
`

export const SocialGroupsStyle = styled(FooterGroupStyle)`
  > * {
    &:not(:first-child) {
      margin-left: 24px;
    }
  }
`

export const FooterStyle = styled(Box)`
  background: ${props => props.theme.colors.brand.black};
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 28px 0;
  width: 100%;
  > * {
    &:not(:first-child) {
      margin-top: 28px;
    }
  }
`

export const LinkStyle = styled.a`
  color: ${props => props.theme.colors.brand.white};
  cursor: pointer;
  font-family: ${props => props.theme.font.styles.regular};
  text-decoration: none;
  text-transform: uppercase;
  &:hover {
    text-decoration: underline;
  }
`
