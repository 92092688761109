import { gql } from '@apollo/client'

import { DELIVERY_SEARCH_RESULTS_FIELDS, RESTAURANT_FIELDS } from '../../data/types'

export const GET_RESTAURANT = gql`
  query GetRestaurant($restaurantId: Float!) {
    restaurant(restaurantId: $restaurantId) {
      ${RESTAURANT_FIELDS}
    }
  }
`

export const GET_RESTAURANTS = gql`
  query GetRestaurants {
    restaurants {
      ${RESTAURANT_FIELDS}
    }
  }
`

export const GET_RESTAURANTS_MAP_INFO = gql`
  query GetRestaurantsMapInfo {
    restaurants {
      id
      name
      brand
      storeName
      telephone
      streetAddress
      city
      state
      zip
      latitude
      longitude
      isCurrentlyOpen
    }
  }
`

export const GET_NEAREST_RESTAURANTS = gql`
  query GetNearestRestaurants(
    $latitude: Float!,
    $longitude: Float!,
    $radius: Float,
    $limit: Int) {
      restaurantsFindNearest(
        latitude: $latitude,
        longitude: $longitude,
        radius: $radius,
        limit: $limit) {
        ${RESTAURANT_FIELDS}
    }
  }
`

export const GET_CALENDARS = gql`
  query GetCalendars($restaurantIds: [Float!]!, $from: String!, $to: String!) {
    calendars(restaurantIds: $restaurantIds, from: $from, to: $to) {
      id
      restaurantId
      type
      label
      ranges {
        start
        end
        weekday
      }
    }
  }
`

export const GET_NEAREST_RESTAURANT_FOR_DELIVERY = gql`
    query GetNearestRestaurantForDelivery(
      $street: String!,
      $city: String!,
      $postalCode: String
      ) {
        restaurantsFindForDelivery(
          street: $street,
          city: $city
          postalCode: $postalCode
          ) {
          ${DELIVERY_SEARCH_RESULTS_FIELDS}
        }
    }
`
