import { Button as ReakitButton } from 'reakit/Button'
import styled from 'styled-components'

export const Button = styled(ReakitButton)`
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: space-between;

  background-color: ${({ theme }) => theme.colors.brand.white};
  border: 1px solid transparent;
  border-radius: 3px;
  color: ${({ theme }) => theme.colors.brand.black};
  ${({ theme }) => theme.font.styles.labelInput}
  font-size: 14px;
  padding: 24px 16px;
  width: 100%;

  transition: all 0.2s ease;

  &:hover {
    cursor: pointer;
  }

  span {
    padding-right: 10px;
    flex: 1;
  }

  svg {
    flex-shrink: 0;
    border: 1px solid ${({ theme }) => theme.colors.brand.gray40};
    border-radius: 50%;
    fill: ${({ theme }) => theme.colors.brand.white};
  }

  &[aria-checked='true'] {
    background-color: ${({ theme }) => theme.colors.brand.white};
  }
  &[aria-checked='true'][aria-disabled='true'] {
    background-color: ${({ theme }) => theme.colors.brand.white};
  }

  &[aria-checked='true'] svg {
    border: 1px solid ${({ theme }) => theme.colors.brand.white};
    fill: ${({ theme }) => theme.colors.brand.black};
  }
  &[aria-checked='true'][aria-disabled='true'] svg {
    border: 1px solid ${({ theme }) => theme.colors.brand.white};
    fill: ${({ theme }) => theme.colors.brand.gray60};
  }
`
