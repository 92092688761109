import { Button as ReakitButton } from 'reakit/Button'
import styled, { css } from 'styled-components'

import { isMobile } from '../../../../components/responsive/Responsive'

export const ModifierGroupBarContainer = styled.div`
  ${({ theme }) => theme.menuItems.customBuilder.modifierGroupsContainer}
  display: flex;
  flex-direction: row;
  width: 100%;

  /* Desktop, Large Desktop and Tablet */
  padding: 16px 48px;

  ${isMobile`
    padding: 16px;
    overflow-x: auto;
  `};
`

export const ModifierGroupBarButton = styled(ReakitButton).attrs(() => ({
  as: 'button',
}))`
  ${({ active, theme }) =>
    active
      ? css`
          background-color: ${theme.menuItems.customBuilder.modifierGroups.active.backgroundColor};
          border: ${theme.menuItems.customBuilder.modifierGroups.active.border};
          border-radius: ${theme.menuItems.customBuilder.modifierGroups.active.borderRadius};
          padding: ${theme.menuItems.customBuilder.modifierGroups.active.padding};
          margin: ${theme.menuItems.customBuilder.modifierGroups.active.margin};
          ${isMobile`
            margin: 0 24px 0 0;
          `};
        `
      : css`
          background-color: ${theme.menuItems.customBuilder.modifierGroups.inactive.backgroundColor};
          border: ${theme.menuItems.customBuilder.modifierGroups.inactive.border};
          border-radius: ${theme.menuItems.customBuilder.modifierGroups.inactive.borderRadius};
          padding: ${theme.menuItems.customBuilder.modifierGroups.inactive.padding};
          margin: ${theme.menuItems.customBuilder.modifierGroups.inactive.margin};
          ${isMobile`
            padding: 0;
            margin: 0 24px 0 0;
          `};
        `}
  cursor: pointer;

  :disabled {
    background-color: ${({ theme }) => theme.menuItems.customBuilder.modifierGroups.inactive.backgroundColor};
    border: ${({ theme }) => theme.menuItems.customBuilder.modifierGroups.inactive.border};
    border-radius: ${({ theme }) => theme.menuItems.customBuilder.modifierGroups.inactive.borderRadius};
    padding: ${({ theme }) => theme.menuItems.customBuilder.modifierGroups.inactive.padding};
    margin: ${({ theme }) => theme.menuItems.customBuilder.modifierGroups.inactive.margin};
    cursor: default;
    ${isMobile`
      padding: 0;
      margin: 0 24px 0 0;
    `};
  }

  justify-content: center;
  align-items: center;
`

export const ModifierGroupBarText = styled.span`
  ${({ theme }) => theme.font.styles.label};
  font-size: ${({ theme }) => theme.font.sizes.xs};
  font-weight: 800;
  letter-spacing: 0.08em;
  line-height: 120%;

  ${({ active, disabled }) =>
    active
      ? css`
          color: ${({ theme }) => theme.menuItems.customBuilder.modifierGroups.active.textColor};
          text-transform: ${({ theme }) => theme.menuItems.customBuilder.modifierGroups.active.textTransform};
        `
      : disabled
      ? css`
          color: ${({ theme }) => theme.menuItems.customBuilder.modifierGroups.inactive.textColorDisabled};
          text-transform: ${({ theme }) => theme.menuItems.customBuilder.modifierGroups.inactive.textTransform};
        `
      : css`
          color: ${({ theme }) => theme.menuItems.customBuilder.modifierGroups.inactive.textColor};
          text-transform: ${({ theme }) => theme.menuItems.customBuilder.modifierGroups.inactive.textTransform};
        `}
`
