import moment from 'moment'
import React, { useMemo, useState } from 'react'

import { Button } from '../../../../../components/Button/Button'
import { ModalHeader } from '../../../../../components/ModalHeader/ModalHeader'
import { ModalWithBackdrop } from '../../../../../components/ModalWithBackdrop/ModalWithBackdrop'
import { SelectInput } from '../../../../../components/SelectInput/SelectInput'
import { TIME_WANTED_MODES } from '../../../../../constants/timeWantedModes'
import { useText } from '../../../../../content'
import { useConfig } from '../../../../../contexts/appConfig'
import { useSession } from '../../../../../contexts/session'
import { BlockContainer, ButtonContainer, Container, DateButton, Form, InputContainer, TimeButton } from '../SelectTimeModal.styles'

export const DeliveryTimeModal = ({ dialogProps, restaurant, onSelectScheduleTime }) => {
  const { text } = useText()
  const {
    state: { basket },
  } = useSession()

  const { getPreference } = useConfig()
  const { AdvanceOrderingDayLimitDelivery = 7 } = getPreference?.('OrderingConfiguration') || {}

  const asap = moment()
    .add(basket?.leadTimeEstimateMinutes || 30, 'minutes')
    .startOf('minute')
  const today = asap.clone().add(30 - (asap.minute() % 30), 'minutes')
  const tomorrow = today.clone().add(1, 'days')

  const nextOpenDays = useMemo(() => {
    const calendars = restaurant?.deliverySupport?.calendar
    console.debug('[DeliveryTimeModal] calendars:', calendars)
    return (
      calendars?.slice(0, AdvanceOrderingDayLimitDelivery)?.map(calendar => ({
        start: moment(calendar.start),
        end: moment(calendar.end),
      })) || []
    )
  }, [restaurant?.deliverySupport?.calendar, AdvanceOrderingDayLimitDelivery])

  const [selectedDate, setSelectedDate] = useState()
  const [selectedTime, setSelectedTime] = useState()

  const handleConfirm = e => {
    e.preventDefault()
    console.debug('[DeliveryTimeModal] onSelectScheduleTime:', selectedTime)
    onSelectScheduleTime(restaurant, selectedTime.timeSlot.toISOString())
  }

  const dates = useMemo(
    () =>
      nextOpenDays?.map(item => {
        return mapDateEntry(item, today, tomorrow)
      }) || [],
    [nextOpenDays, today, tomorrow],
  )

  const times = useMemo(() => {
    return buildTimeSlots(selectedDate?.item, asap, today)
  }, [selectedDate?.value, today])

  return (
    <ModalWithBackdrop dialogProps={dialogProps} ariaLabel={text('SelectTime.Delivery.Heading')}>
      <ModalHeader title={text('SelectTime.Delivery.Heading')} onClose={dialogProps.hide} />
      <Container>
        <Form onSubmit={handleConfirm}>
          <InputContainer>
            <SelectInput
              as={DateButton}
              name="scheduleDate"
              placeholder={text('SelectTime.Date.Placeholder')}
              value={selectedDate}
              onChange={e => {
                setSelectedDate(e.selectedItem)
                setSelectedTime(undefined)
              }}
              items={dates}
              itemToString={item => item?.dateLabel}
              required
              selected={item => item?.value === selectedDate?.value}
            />
            <SelectInput
              as={TimeButton}
              name="selectedTime"
              placeholder={text('SelectTime.Time.Placeholder')}
              value={selectedTime}
              onChange={e => setSelectedTime(e.selectedItem)}
              items={times}
              itemToString={item => item?.timeLabel}
              required
              selected={item => item?.value === selectedTime?.value}
              disabled={!selectedDate}
            />
          </InputContainer>
          <ButtonContainer>
            <BlockContainer>
              <Button label={text('SelectDeliveryTime.Button')} onClick={handleConfirm} variant="primary" disabled={!selectedTime} />
            </BlockContainer>
          </ButtonContainer>
        </Form>
      </Container>
    </ModalWithBackdrop>
  )
}

const buildTimeSlots = (item, asap, today) => {
  console.debug('[DeliveryTimeModal] selectedDate.item:', item, asap, today)
  if (!item) return []

  const timeSlots = today.isSame(item.start, 'days') ? [asap] : []
  const timeSlot = moment.max([item.start, today]).clone()

  while (timeSlot.isBefore(item.end)) {
    timeSlots.push(timeSlot.clone())
    timeSlot.add(30, 'minutes')
  }

  return timeSlots.map((timeSlot, index) => {
    return mapTimeEntry(item, today, timeSlot, index)
  })
}

const mapDateEntry = (item, today, tomorrow) => {
  const isToday = today.isSame(item.start, 'days')
  const isTomorrow = tomorrow.isSame(item.start, 'days')
  const value = item.start.format('YYYY-MM-DD')
  const weekday = item.start.format('dddd').substring(0, 3)
  const dateLabel = isToday ? 'Today' : isTomorrow ? 'Tomorrow' : `${weekday}. ${item.start.format('MMM DD')}`
  return { dateLabel, item, value }
}

const mapTimeEntry = (item, today, timeSlot, index) => {
  const isAsap = timeSlot.isSame(today, 'day') && index === 0
  const value = isAsap ? TIME_WANTED_MODES.ASAP : timeSlot.format('h:mm a')
  const timeLabel = isAsap ? `${timeSlot.format('h:mm a')} (asap)` : timeSlot.format('h:mm a')
  return { timeLabel, item, value, timeSlot }
}
