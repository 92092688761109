import React, { useCallback } from 'react'

import { ControlContainer, ControlContainerSimple } from '../ControlContainer/ControlContainer'
import { AddIcon, Controls, CurrentValue, IconContainer, Label, SubtractIcon, ValueContainer } from './QuantityInput.styles'

export const QuantityInput = ({ onIncrement, onDecrement, isLoading, quantity = 1, min = 0, max, variant = 'normal', label = 'Quantity' }) => {
  const increment = useCallback(() => {
    if (!max || quantity < max) {
      onIncrement(quantity + 1)
    }
  }, [max, onIncrement, quantity])

  const decrement = useCallback(() => {
    if (min === undefined || min === null || quantity > min) {
      onDecrement(quantity - 1)
    }
  }, [min, onDecrement, quantity])

  if (variant === 'normal') {
    return (
      <ControlContainer>
        <Label>{label}</Label>
        <Controls>
          <Subtract disabled={quantity === 0 || isLoading} onClick={decrement} />
          <ValueContainer>
            <CurrentValue>{quantity}</CurrentValue>
          </ValueContainer>
          <Add disabled={isLoading} onClick={increment} />
        </Controls>
      </ControlContainer>
    )
  } else if (variant === 'simple') {
    return (
      <ControlContainerSimple>
        <Controls>
          <Subtract disabled={quantity === 0 || isLoading} onClick={decrement} />
          <ValueContainer>
            <CurrentValue>{quantity}</CurrentValue>
          </ValueContainer>
          <Add disabled={isLoading} onClick={increment} />
        </Controls>
      </ControlContainerSimple>
    )
  }
}

export const Subtract = props => (
  <IconContainer {...props} activeOpacity={0.5}>
    <SubtractIcon disabled={props.disabled} />
  </IconContainer>
)

export const Add = props => (
  <IconContainer {...props}>
    <AddIcon disabled={props.disabled} />
  </IconContainer>
)
