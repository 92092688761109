import React from 'react'

import { useText } from '../../../../../content'
import { ImageBackgroundContainer } from '../ImageBackgroundContainer/ImageBackgroundContainer'
import { Container, HeroContentContainer, HeroDescriptionParagraph, HeroTitleHeader } from './BrowseMenuHeroContainer.styles'

export const BrowseMenuHeroContainer = ({ backgroundImageUrl, isVisible, isVisibleText }) => {
  const { text } = useText()
  return (
    <Container as="div" style={{ minHeight: isVisible ? '' : '0', visibility: isVisible ? 'visible' : 'hidden' }} className="x">
      {backgroundImageUrl && <ImageBackgroundContainer as="div" imageUrl={backgroundImageUrl} />}
      <HeroContentContainer visible={isVisibleText}>
        <HeroTitleHeader>{text('Browse.Menu.Header.Hero.Title')}</HeroTitleHeader>
        <HeroDescriptionParagraph>{text('Browse.Menu.Header.Hero.Description')}</HeroDescriptionParagraph>
      </HeroContentContainer>
    </Container>
  )
}
