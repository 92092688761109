import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTheme } from 'styled-components'

import { HANDOFF_MODES } from '../../../constants/handoffModes'
import { useConfig } from '../../../contexts/appConfig'
import { useSession } from '../../../contexts/session'
import { CloseButton } from '../../Button/Button'
import { DoubleCTA } from '../../DoubleCTA/DoubleCTA'
import { ActionsContainer, Body, CloseButtonContainer, Header, Heading, SubTitle } from './TotalMinimumWarningModal.styled'

export const TotalMinimumWarningModal = ({ onClose }) => {
  const history = useHistory()
  const theme = useTheme()
  const { resolveNavigationPayload } = useConfig()
  const { state } = useSession()

  const { restaurant, handoffMode } = state

  function handleAddMore() {
    const screen = resolveNavigationPayload('StartOrder')
    history.push(screen)
    onClose()
  }

  function handleSwitch() {
    if (handoffMode === HANDOFF_MODES.PICKUP) {
      history.push('/', { activeTabParam: 0 })
    } else {
      history.push('/', { activeTabParam: 1 })
    }
  }

  const actionButtons = [
    {
      label: 'ADD MORE',
      onClick: handleAddMore,
      variant: 'primary',
    },
    {
      label: handoffMode === HANDOFF_MODES.PICKUP ? 'SWITCH TO DELIVERY' : 'SWITCH TO PICKUP',
      onClick: handleSwitch,
      variant: 'link',
    },
  ]

  return (
    <>
      <Header>
        <CloseButtonContainer>
          <CloseButton variant={theme.basket.backButtonVariant} onClick={onClose} size={32} />
        </CloseButtonContainer>
        <Heading>UH OH</Heading>
      </Header>

      {handoffMode === HANDOFF_MODES.PICKUP ? (
        <Body>
          <SubTitle>The order pickup minimum is ${restaurant?.minimumPickupOrder}</SubTitle>

          <ActionsContainer>
            <DoubleCTA buttons={actionButtons} stacked />
          </ActionsContainer>
        </Body>
      ) : (
        <Body>
          <SubTitle>The order delivery minimum is ${restaurant?.minimumDeliveryOrder}</SubTitle>

          <ActionsContainer>
            <DoubleCTA buttons={actionButtons} stacked />
          </ActionsContainer>
        </Body>
      )}
    </>
  )
}
