import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { Box } from 'reakit/Box'
import { Button } from 'reakit/Button'
import styled, { css } from 'styled-components'

export const AccordionContainer = styled(Box)`
  background: ${props => props.theme.colors.brand.white};
  border-radius: ${props => props.theme.borderRadii.sm};
  border: ${props => props.theme.borders.light};

  position: relative;

  cursor: pointer;
  &:hover {
    border: ${props => props.theme.toggles.styles.active.border};
  }

  ${props =>
    props.isRequired &&
    css`
      background-color: ${({ theme }) => theme.colors.backgroundRequiredInput};
    `}
`

export const AccordionButton = styled(Button)`
  align-items: center;
  background: inherit;
  border-radius: ${props => props.theme.borderRadii.sm};
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  width: 100%;
`

export const AccordionContentContainer = styled(Box)`
  display: ${props => (props.$expanded ? 'flex' : 'none')};
  width: 100%;
`

export const ArrowUpIcon = styled(FiChevronUp).attrs(({ theme }) => ({
  color: theme.colors.brand.black,
  size: 24,
}))`
  margin-left: auto;
`

export const ArrowDownIcon = styled(FiChevronDown).attrs(({ theme }) => ({
  color: theme.colors.brand.black,
  size: 24,
}))`
  margin-left: auto;
`
